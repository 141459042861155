<template>
	<div>
		<v-container>
			<v-row>
				<v-col cols="12">
					<v-row style="margin-top: 4px;">
						<v-col cols="12" lg="12" md="12">
							<template>
								<s-crud
									:config="config"
									:filter="filter"
									edit
									remove
									add
									@save="save($event)"
									search-input
									@addEvent="addEvent()"
									title="Configuracion de Precios"
									@rowSelected="rowSelected($event)"
									no-full
								>
									<template v-slot:filter>
										<v-container>
											
											<v-row justify="center">
												<v-col
													lg="4"
													md="4"
													cols="12">
													<s-select-definition
														v-model="filter.TypeCultive"
														label="Cultivo"
														:def="1173">
													</s-select-definition>
												</v-col>

												<v-col
													lg="4"
													md="4"
													cols="12">
													<s-select-generic
														label="Zona"
														itemtext="ZonName"
														itemvalue="ZonID"
														id="ZonName"
														full
														autocomplete
														:config="configZon"
														v-model="filter.ZonID">
													</s-select-generic>
												</v-col>

											</v-row>
											<v-row>
												<v-col cols="12">
													<b style="color: red; font-size: 11px">Advertencia.. Si en algun momento cambian los parametros de los Precios para el pago de transporte.. Comuniquese con el area de TECNOLOGÍA DE LA INFORMACIÓN.</b>
												</v-col>
											</v-row>
										</v-container>
									</template>

									<template slot-scope="props">
										<v-container>
											<v-row >
												<v-col
													lg="3"
													md="4"
													cols="12">
													<s-select-generic
														label="Zona"
														itemtext="ZonName"
														itemvalue="ZonID"
														id="ZonName"
														full
														:config="configZon"
														v-model="props.item.ZonID">
													</s-select-generic>
												</v-col>

												<v-col lg="3" md="3" cols="12">
													<s-select-definition
														v-model="props.item.TypeCultive"
														label="Cultivo"
														:def="1173">
													</s-select-definition>
												</v-col>

												<v-col lg="2" md="2" cols="12">
													<s-select-definition
														v-model="props.item.TypeCoste"
														:def="1229"
														label="Costo">
													</s-select-definition>
												</v-col>

												<v-col lg="2" md="2" cols="6">
													<v-checkbox
														label="Precio adicional"
														v-model="priceAditional"
													></v-checkbox>
												</v-col>

												<v-col lg="2" md="2" cols="6">
													<v-checkbox
														label="Aplica ruta"
														v-model="checkRoute"
													></v-checkbox>
												</v-col>

												<v-col lg="2" md="2" cols="12" v-if="checkRoute == true">
													<s-select-definition
														label="Ruta"
														:def="1197"
														return-object
														v-model="objRoute">
													</s-select-definition>
												</v-col>

												<v-col lg="2" md="2" cols="12" v-if="checkRoute == true">
													<s-text
														label="valor"
														number
														:min="0"
														v-model="props.item.TrpValueRoute">
													</s-text>
												</v-col>

												<v-col lg="2" md="2" cols="12">
													<s-text 
														label="Capacidad Inicio (Jabas)"
														v-model="props.item.TrpRankInitial"
														number
														:min="0">
													</s-text>
												</v-col>

												<v-col lg="2" md="2" cols="12">
													<s-text 
														label="Capacidad Fin (Jabas)"
														v-model="props.item.TrpRankFinal"
														number
														:min="0">

													</s-text>
												</v-col>
												<v-col lg="2" md="2" cols="12">
													<s-text 
														label="Precio (S/.)"
														v-model="props.item.TrpPrice"
														decimal
														:min="0">
													</s-text>
												</v-col>

												<v-col lg="2" md="2" cols="12" v-if="priceAditional == true">
													<s-text 
														label="Precio adicional (S/.)"
														v-model="props.item.TrpPriceAditional"
														decimal
														:min="0">
													</s-text>
												</v-col>

											</v-row>
										</v-container>
									</template>
								</s-crud>
							</template>
						</v-col>
					</v-row>
					<!-- </v-card> -->
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
	//Service
	import _sTransportPriceService from "../../../services/Technicalassistance/TransportPriceService";

	export default {
		components: {},
		data: () => ({

			priceAditional: true,
			checkRoute: true,
			objRoute: {},
			selected: {},
			filter: { TypeCultive: 0, ZonID: 0 },
			config: {
				service: _sTransportPriceService,
				model: {
					TrpID: "ID",
				},
				headers: [
					{ text: "Zona", value: "ZonName", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 20 },
					{ text: "Costo", value: "TypeCosteName", width: 20 },
					{ text: "Ruta", value: "TypeRouteName", width: 20 },
					{ text: "Capacidad", value: "TrpRankFull", width: 20 },
					{ text: "Precio", value: "TrpPrice", width: 20 },
					{ text: "Precio adicional", value: "TrpPriceAditional", width: 20 },

					/* { text: "Estado", value: "SecStatus", width: 30 }, */
				],
			},
		}),

		methods: {

			addEvent(){
				this.priceAditional = true
				this.checkRoute = true
			},

			rowSelected(item) {
				if(item.length > 0){
					if(item !== undefined){
						this.selected = item[0];

						this.priceAditional = this.selected.TrpCheckPriceAditional 
						this.checkRoute = this.selected.TrpCheckRoute
						this.objRoute.DedValue = this.selected.TypeRoute
						console.log(this.selected);
					}
				}
								
			},

			isValidated(val){
				
				var isValidated = true;
				if(this.checkRoute == true){
					if(val.TypeRoute == undefined){
						this.$fun.alert("Seleccione ruta", "warning")
						isValidated = false;
					}
				}

				if(this.priceAditional == true){
					if(val.TrpPriceAditional <= 0){
						this.$fun.alert("Precio adicional debe de mayor a 0", "warning")
						isValidated = false;
					}
				}

				if(val.TrpPrice <= 0){
					this.$fun.alert("Precio debe de mayor a 0", "warning")
					isValidated = false;
				}

				if(val.TrpRankInitial < 0){
					this.$fun.alert("Rango inicio debe de mayor o igual a 0", "warning")
					isValidated = false;
				}

				if(val.TrpRankFinal <= 0){
					this.$fun.alert("Rango fin debe de mayor a 0", "warning")
					isValidated = false;
				}

				if(val.TrpRankFinal <= val.TrpRankInitial){
					this.$fun.alert("Rango inicio debe de menor a rango fin", "warning")
					isValidated = false;
				}
				return isValidated
			},

			save(val){
				
				if(this.checkRoute){
					val.TypeRoute = this.objRoute.DedValue;
					val.TrpOperator = this.objRoute.DedAbbreviation;
					//val.TrpValueRoute = this.objRoute.DedHelper;
				}else{
					val.TypeRoute = null;
					val.TrpOperator = null
					val.TrpValueRoute = null
				}

				if(!this.priceAditional){
					val.TrpPriceAditional = null
				}

				val.TrpCheckPriceAditional = this.priceAditional;
				val.TrpCheckRoute = this.checkRoute;

				val.SecStatus = 1;
				val.UsrCreateID = this.$fun.getUserID();
				console.log("Guardar", val);

				if(!this.isValidated(val)){
					return;
				}

				val.save();
				console.log("Guardar", val);
			}
		},

		created() {
			this.configZon = {
			url: _sTransportPriceService.GetZon(),
			title: this.label,
			params: { search: "", requestID: this.$fun.getUserID() },
			};
		},
	};
</script>

<template>
	<div>
		<v-card class="mt-0 pt-0" lg="6">
			<!-- Inicio Campos de Alerta -->

			<v-alert
				v-model="alert"
				border="right"
				:color="'#9EB8F6'"
				dark
				dense
				shaped
				close-icon="fas fa-times-circle"
				style="border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 5%; margin-top: -22px;}"
			>
				<div>
					<v-row justify="center" style="margin-top: -10px;">
						<v-col lg="5">
							<b>Productor:</b> {{ viewItemsAll.PrdCardName }}
						</v-col>
						<v-col lg="4">
							<b>Lote: </b>{{ viewItemsAll.FltName }}
						</v-col>
					</v-row>

					<v-row
						justify="center"
						style="margin-top: -10px; font-size: smaller"
					>
						<v-col lg="3">
							<b>Tipo Cultivo:</b> {{ viewItemsAll.TypeCropName }}
						</v-col>
						<v-col lg="3">
							<b>Cultivo:</b> {{ viewItemsAll.TypeCultiveName }}
						</v-col>
						<v-col lg="3">
							<b>Variedad: </b>{{ viewItemsAll.VrtName }}
						</v-col>
					</v-row>
                    
				</div>
			</v-alert>
			<v-row justify="center">
                <!-- <div class="col-md-12 col-sm-12">
                    <b align="center" style="color: red; font-family: Courier"
                        ><h2>
                        {{
                            viewItemsAll.QuantityJabas == sumTotalJaba
                            ? "Atención !!! Cantidad de  Pesadas es igual a Jabas recepcionadas"
                            : ""
                        }}
                        </h2></b
                    >
                </div> -->
				<v-col
						cols="12"
						v-if="viewItemsAll.QuantityJabas == sumTotalJaba"
						align="center"
					>

						 <v-alert
							v-model="viewItemsAll.QuantityJabas == sumTotalJaba ? true: false"
							border="right"
							:color="'warning'"
							dark
							dense
							dismissible
							shaped
							close-icon="fas fa-times-circle"
							style="border-radius: 10px 10px 10px 10px; overflow: auto; z-index: 9999999; position: fixed; width: 40%; right: 30%; top: 40%;"
							>
								<b style="color: white; font-family: Courier"
									><h2>
										{{
											viewItemsAll.QuantityJabas ==
											sumTotalJaba
												? "Atención !!! Cantidad de jabas Pesadas es igual a Jabas recepcionadas"
												: ""
											
										}}
									</h2></b
								>
							</v-alert>
						
					</v-col>
				<div class="col-md-7 col-sm-12">
					<v-row class="ml-2 mr-2">
						<!-- <v-col cols="12" lg="3" style="margin-bottom: inherit">
							<s-select-definition
								full
								label="Seleccione Puerta"
								:def="1251"
								v-model="items.TypeDoor"
								@input="inputDoor($event)"
							>
							</s-select-definition>
						</v-col> -->
					
						<!-- <v-col cols="12" lg="4">
							<s-select
								:items="itemsNumberGuide"
								item-value="RcfID"
								full
								autocomplete
								item-text="RcfNumberHarvestGuide"
								label="Seleccione Nro. Guia de cosecha"
								v-model="objNumberGuide"
                                return-object
								@input="inputNumberGuide($event)"
							>
							</s-select><span>
                                    Cant. Jabas Recepcionadas:
                                    <b style="font-size: 16px">{{
                                    viewItemsAll.QuantityJabas
                                    }}</b>
                                </span>
						</v-col> -->

						<v-col cols="12" lg="4">
							<s-select
								
								:items="itemsNumberGuide"
								item-value="RcfID"
								full
								autocomplete
								item-text="RcfNumberHarvestGuide"
								label="Seleccione Nro. Guia de cosecha"
								v-model="RcfID"
								@input="inputNumberGuide($event)"
								:slotTmp="true"
							>
								<template v-slot:item="data">
									{{ data.item.RcfNumberHarvestGuide }}
									<v-chip
										x-small
										style="margin-left: 5px"
										:color="
											data.item.inProcessTerminated > 0
												? 'info'
												: data.item.inProcess > 0
												? 'warning'
												: 'error'
										"
										>{{
											data.item.inProcessTerminated > 0
												? "Pesado: " + data.item.TypeDoorName
												: data.item.inProcess > 0
												? "En Proceso: " + data.item.TypeDoorName
												: "Sin Pesar"
										}}</v-chip
									>
								</template>
								
							</s-select><span>
                                    Cant. {{typeChargeName}} Recepcionadas:
                                    <b style="font-size: 16px">{{
                                    viewItemsAll.QuantityJabas
                                    }}</b>
                                </span>
						</v-col>
						<v-col cols="12" lg="4">
							<v-select
								:items="itemsDoorAll"
								item-value="TypeDoor"
								item-text="TypeDoorName"
								label="Seleccione Puerta"
								v-model="items.TypeDoor"
								@input="inputDoor($event)"
								:disabled="isDoorDisabled" 
							>
								<template v-slot:item="{ item }">
									{{ item.TypeDoorName }}
									<v-chip
										x-small
										:color="
											item.inProcess == 0
												? 'info'
												: 'error'
										"
										style="margin-left: 5px"
										>{{
											item.inProcess == 0
												? "Libre"
												: "Ocupada"
										}}</v-chip
									>
								</template>
							</v-select>
						</v-col>

						<v-col cols="12" lg="5" style="margin-top: 0px">
							<v-text-field
								label=" Peso Prom. Jaba.:"
								v-model="RcfWeightProm.toFixed(2)"
								readonly
								:error="
									RcfWeightProm < valParamMin ||
										RcfWeightProm > valParamMax
								"
								:messages="
									RcfWeightProm < valParamMin ||
									RcfWeightProm > valParamMax
										? 'Peso Promedio fuera de Param. establecidos. (Min: ' +
										  valParamMin +
										  ', Max: ' +
										  valParamMax +
										  ')'
										: ''
								"
							>
							</v-text-field>
						</v-col>

						<v-col cols="12" lg="12">
							<v-row style="border-top: groove">
								<v-col cols="6" lg="6" md="6">
									<s-text
										style="width: 95%"
										label="Cant. a Pesar (Jabas / Bins)"
										number
										v-model="items.WpbJabasQuantity"
										ref="focusJabasQuantity"
									></s-text>
								</v-col>

								<v-col cols="6" lg="6" md="6" style="display: flex">
									<s-text
										style="width: inherit"
										:label="'Peso ' + typeChargeName"
										decimal
										v-model="items.WpbWeightJaba"
									/>
									<span style="height: fit-content; margin-top: 21px"
										>KG</span
									>
								</v-col>

								<v-col
									cols="6"
									lg="6"
									md="6"
									style="margin-top: -15px; display: flex"
								>
									<s-text
										style="width: inherit"
										label="Peso Bruto"
										decimal
										ref="focusWeightGross"
										v-model="items.WpbWeightGross"
									/>
									<span style="height: fit-content; margin-top: 21px"
										>KG</span
									>
								</v-col>

								<v-col
									cols="6"
									lg="6"
									md="6"
									style="margin-top: -15px; display: flex"
								>
									<s-text
										style="width: inherit"
										label="Peso Parihuela"
										decimal
										v-model="items.WpbWeightPallet"
										ref="focusWeightPallet"
									/>
									<span style="height: fit-content; margin-top: 21px"
										>KG</span
									>
								</v-col>
							</v-row>

							<v-row justify="center">
								<v-col
									cols="12"
									lg="6"
									md="6"
									style="margin-top: -15px; display: flex"
								>
									<s-text
										style="width: inherit"
										label="Peso Neto"
										decimal
										v-model="GetWeightNetPallet"
										readonly
									></s-text>
									<span style="height: fit-content; margin-top: 21px"
										>KG</span
									>
								</v-col>

								<v-col cols="6" lg="3" md="3" style="margin-top: 0px">
									<v-btn
										:disabled="disabledBtn"
										style="width: 100%"
										color="primary"
										@click="Grabar()"
										>Grabar
									</v-btn>
								</v-col>
								<v-col cols="6" lg="3" md="3" style="margin-top: 0px">
									<v-btn
										style="width: 100%"
										color="warning"
										@click="clearAll()"
										>Cancelar
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</div>
				<div class="col-md-5 col-sm-12">
					<v-col cols="12">
						<v-card outlined>
							<v-row style="margin:auto">
								<v-col cols="6">
									<s-text readonly label="Cant. Jabas" v-model="sumTotalJaba"></s-text>
								</v-col>
								<v-col cols="6">
									<s-text v-model="sumTotalParihuela" readonly label="Peso Parihuela (KG)"></s-text>
								</v-col>
								<v-col cols="6">
									<s-text v-model="sumTotalWeightGross.toFixed(2)" readonly label="Peso Bruto (KG)"></s-text>
								</v-col>
								<v-col cols="6">
									<s-text v-model="sumTotalWeightNet.toFixed(2)" readonly label="Peso Neto (KG)"></s-text>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
					<v-col cols="12">
						<v-card outlined>
							<v-row style="margin:auto">
								<v-col cols="12">
									<h3 style="color: red">Control de Peso Lote</h3>
								</v-col>
								<v-col cols="6">
									<s-text v-model="sumWeightAverage" readonly label="Peso Promedio Jaba con Fruta (KG)"></s-text>
								</v-col>
								<v-col cols="6">
									<s-text v-model="sumTotalWeightNet" readonly label="Total Peso Neto (KG)"></s-text>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
					
				</div>
			</v-row>

			<!-- Inicio Listado de Pesos -->
			<v-row style="margin-top: -15px">
				<div class="col-md-12 col-sm-12">
					<s-toolbar
						label="Listado de Pesos"
						dark
						:color="'#BAB6B5'"
						class="mr-4 ml-2"
						style="display: flex"
						edit
						remove
						@edit="edit()"
						@removed="remove()"
						print
						@print="printQR()"
					></s-toolbar>
					<v-row class="ml-4">
						<v-col cols="12" lg="12">
							<v-data-table
								item-key="WpbID"
								:headers="headersWeighed"
								:items="itemsWeighed"
								v-model="selectedWeighed"
								dense
								hide-default-footer
								:items-per-page="-1"
								@click:row="rowClickWeighed"
							>
							</v-data-table>
						</v-col>
						<v-col>
							<p>
								Cantidad Recepcionada: <b> {{ sumTotalJaba }}</b>
							</p>
						</v-col>
					</v-row>
				</div>
			</v-row>
			<!-- Fin Listado de pesoso -->
			<!-- {{textQR}}
			<v-col cols="12">
				<qr-code :text="textQR" error-level="H"> </qr-code>
			</v-col> -->
			<v-col cols="12" style="display: none">
				<qr-code :text="textQR" error-level="H" hidden :size="360"> </qr-code>
			</v-col>
		</v-card>
	</div>
</template>

<script>
	import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService";
	import _sWeighedByBatch from "../../../services/FreshProduction/WeighedByBatchService";
	import _sGenParamService from "../../../services/General/ParameterService";
	import qrCode from "vue-qr-generator";
	import _sParameter from "@/services/General/ParameterService.js";

	export default {
		props: {
			ship: {
				type: Number,
				required: true,
			},
		},
		components: {
			qrCode,
		},
		data: () => ({

			itemsDoorAll: [],
			viewQR: false,
			isDoorDisabled:true,


			headersGang: [
				{ text: "Capataz", value: "FrmName", width: 250 },
				{ text: "Cant. Jabas Cosechadas", value: "FrmQuantityReal" },
			],

			headersWeighed: [
				{ text: "Fecha/Hora", value: "WpbDate", width: 150 },
				{ text: "Nro. Palet", value: "WpbNumberPallet", width: 80 },
				{ text: "Cant. Jabas", value: "WpbJabasQuantity", width: 100 },
				{ text: "Peso Jaba", value: "WpbWeightJaba", width: 100 },
				{ text: "Peso Parihuela", value: "WpbWeightPallet", width: 100 },
				{ text: "Peso Bruto", value: "WpbWeightGross", width: 150 },
				{ text: "Peso Neto", value: "WpbWeightNet", width: 100 },
				{
					text: "Peso Prom. Jaba",
					value: "WpbWeightAverageJaba",
					width: 100,
				},
			],

			items: {
				WpbID: 0,
				WpbJabasQuantity: 40,
				WpbWeightGross: 0,
				WpbWeightPallet: 37,
			},
			RcfID: 0,
            objNumberGuide :[],
			itemsGang: [],
			selectedWeighed: [],
			itemsNumberGuide: [],
            viewItemsAll: {},
			/* viewItemsGang: {}, */

			itemsWeighed: [],
			alert: false,
			RcfWeightProm: 0,
			clickEdit: 0,
			valParamMin: 0,
			valParamMax: 0,
			textQR: "Sin datos",
			objPriceJaba: {},
			typeChargeName: "",
			disabledBtn:false,
			isDelete : false,
			isPrintQrMain : true,

			sumTotalJaba: 0,
			sumTotalParihuela: 0,
			sumTotalWeightGross: 0,
			sumTotalWeightNet: 0,
			sumWeightAverage: 0
		}),

		methods: {
			//Cargar Parametros
			loadData() {
				
				//Cargar las Guia  de cosecha
				_sWeighedByBatch
					.getHarvestGuide(
						{ xPrint: 0, TypeShip: this.ship },
						this.$fun.getUserID()
					)
					.then(r => {
						//if (r.data.length > 0) {
						this.itemsNumberGuide = r.data;
						console.log('datos que me vienen con el numero de guia', this.itemsNumberGuide)
						//}
					});

				_sWeighedByBatch.getDoorAll({TypeShip: this.ship}, this.$fun.getUserID()).then(r => {
					this.itemsDoorAll = r.data;
					/* if(this.itemsDoorAll.length > 0) {
							this.items.TypeDoor = this.itemsDoorAll[0];
						} */
				});
			},


			loadParameter() {
				/* _sGenParamService
					.search(
						{ PrmName: "PesoPromedioJabaMax" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						if (resp.status == 200) {
							this.valParamMax = resp.data.PrmValue;
						}
					});

				_sGenParamService
					.search(
						{ PrmName: "PesoPromedioJabaMin" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						if (resp.status == 200) {
							this.valParamMin = resp.data.PrmValue;
						}
					}); */

				/* _sGenParamService
					.search({ PrmName: "PesoJabaFrescos" }, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {
							this.items.WpbWeightJaba = resp.data.PrmValue;
						}
					}); */
			},

			//Limpair todo
			clearAll() {
				this.items.WpbID = 0;
				//this.items.WpbJabasQuantity = 40;
				this.items.WpbWeightGross = 0;
				this.items.WpbWeightJaba = this.items.WpbWeightJaba;
				this.items.WpbWeightPallet = this.items.WpbWeightPallet;
				this.clickEdit = 0;
				this.getWeighedByBatch(this.RcfID);
				this.getParametersBatch();
			},

			//Seleccionar Fila de Pesada
			rowClickWeighed: function(item, row) {
				this.selectedWeighed = [item];
				console.log("this.selectedWeighed", this.selectedWeighed);
				this.sumTotalJaba = this.GetSumQuantityJabas();
				this.clickEdit = 0;
				//console.log("rowClickWeighed", this.selectedWeighed);

				/* this.selectedWeighed[0].WpbID.toString() +  */
				var cadTextQR =
					this.selectedWeighed[0].RcfID.toString() +
					", " +
					this.selectedWeighed[0].WpbID.toString() +
					", " +
					this.selectedWeighed[0].WpbJabasQuantity +
					", " +
					this.selectedWeighed[0].WpbYuliano +
					", LP-" +
					this.selectedWeighed[0].RcfID.toString() +
					", Cant. Jabas: " +
					this.selectedWeighed[0].WpbJabasQuantity +
					", PP. Jaba: " +
					this.selectedWeighed[0].WpbWeightAverageJaba +
					", PN: " +
					this.selectedWeighed[0].WpbWeightNet +
					", PB: " +
					this.selectedWeighed[0].WpbWeightGross +
					", " +
					this.selectedWeighed[0].WpbDate +
					", " +
					this.selectedWeighed[0].TypeDoorName;

				this.textQR = cadTextQR;
                
			},

			//Al seleccionar registro en el combo de Puerta, Recibimos el Id de la Puerta
			inputDoor(TypeDoor) {
				if (TypeDoor !== undefined) {
					console.log("TypeDoor", TypeDoor, this.itemsNumberGuide);
					//Obtener los registros que estan siendo pesados en la puerta selecioanda
					//Si aun esta en proceso,
					/* if (this.itemsNumberGuide.length > 0) { */
						_sWeighedByBatch
							.getDoor(
								{ TypeDoor: TypeDoor, TypeShip: this.ship },
								this.$fun.getUserID(),
							)
							.then(r => {
								if (r.data.length > 0) {
									//this.RcfID = r.data[0].RcfID; //Seleccionamos por Defecto la Guia de Cosecha
								} else {
									//this.RcfID = 0;
								}
							});
					/* } */

					if (this.itemsNumberGuide.length == 0) {
						this.RcfID = 0;
					}
				}
			},

			//Al seleccionar registro en el combo Guia de Cosecha
			inputNumberGuide(obj) {
				console.log('arandano ', this.itemsNumberGuide, obj);
                
				if (obj !== 0 || obj !== undefined) {

					this.objPriceJaba = this.itemsNumberGuide.find(
						x => x.RcfID == obj
					);

					console.log("eststttt ", this.objPriceJaba);

					if (this.objPriceJaba !== undefined) {
						this.items.WpbWeightJaba = this.objPriceJaba.TypeChargePrice;
						this.items.WpbWeightPallet = this.objPriceJaba.TypeChargeWeighedPal;
						this.valParamMin = this.objPriceJaba.WpcMin;
						this.valParamMax = this.objPriceJaba.WpcMax;
						this.typeChargeName = this.objPriceJaba.TypeChargeName;
						if (this.valParamMin == null || this.valParamMax == null) {
							this.$fun.alert(
								"Advertencia, no se ha configurado parametros Peso Promedio " +
									this.typeChargeName +
									" (Min - Max)",
								"warning"
							);
							this.disabledBtn = true;
						} else {
							this.disabledBtn = false;
						}


						this.viewItemsAll = this.objPriceJaba
						console.log(this.viewItemsAll);
						this.alert = true; //Mostramos la alerta
						this.getWeighedByBatch(obj);
						this.items.TypeDoor = this.objPriceJaba.TypeDoor;		
						if(this.objPriceJaba.TypeDoor==null){
							this.isDoorDisabled = false;  
						}else{
							this.isDoorDisabled = true;  
						}				
					}

					



					/* this.items.WpbWeightJaba = obj.TypeChargePrice
					this.items.WpbWeightPallet = obj.TypeChargeWeighedPal
					this.valParamMin = obj.WpcMin
					this.valParamMax = obj.WpcMax
					this.typeChargeName = obj.TypeChargeName */

					/* if(this.valParamMin == null || this.valParamMax == null){
						this.$fun.alert("Advertencia, no se ha configurado parametros Peso Promedio " +obj.TypeChargeName +   " (Min - Max)", "warning")
						this.disabledBtn = true
					}else{
						this.disabledBtn = false
					} */
					this.getParametersBatch(this.objPriceJaba);

                    
				}
			},

			//Obtener los parametros como cantidad de jabas
			getParametersBatch(obj)
			{
				if(obj != null){
					if (obj['TypeCharge'] == 1) {
						let a = {
							PrmName: "CantidadJabasPesado"
						};
						
						_sParameter.search(a, this.$fun.getUserID()).then((r) => {
							this.items.WpbJabasQuantity = r.data.PrmValue;
						});
					}
					if(obj['TypeCharge'] == 2)
					{
						let a = {
							PrmName: "CantidadBinesPesado"
						};
						_sParameter.search(a, this.$fun.getUserID()).then((r) => {
							this.items.WpbJabasQuantity = r.data.PrmValue;
						});
					}
				}
				
			},

			//Obtener Pesadas
			getWeighedByBatch(RcfID) {
				_sWeighedByBatch
					.getWeighedBatch(
						{
							RcfID: RcfID, //this.viewItemsGang.RcfID,
							RcfGngID: 0,
							TypeDoor: this.items.TypeDoor,
						},
						this.$fun.getUserID(),
					)
					.then(r => {
						this.itemsWeighed = r.data; //Registros de Pesada
						this.itemsWeighed.forEach(element => {
							element.WpbDate = this.$moment(element.WpbDate).format(
								this.$const.FormatDateTimeDB,
							);
						});
							if(this.itemsWeighed.length > 0){
								let ob = this.itemsWeighed[0];
								this.selectedWeighed = [ob];
								this.rowClickWeighed(ob, ob);
								setTimeout(() => {
									if(this.isDelete == false && this.isPrintQrMain == false) {
										this.printQR();
									}
								}, 1);
								//this.printQR();
								
							}
					

					});
			},

			//***************************** CALCULOS ******************************************* */

			//Calcular el peso promedio de Jaba
			GetWeightAverageJaba() {
				if (this.items !== undefined) {
					var WeightAverageJaba = 0;
					WeightAverageJaba =
						this.GetWeightNetPallet / this.items.WpbJabasQuantity;
				}

				return WeightAverageJaba;
			},

			//Sumar todas la Cantidad de jabas de la Grilla, Retorna la suma
			GetSumQuantityJabas() {
				var QuantityJabas = 0;
				this.itemsWeighed.forEach(element => {
					QuantityJabas =
						QuantityJabas + parseInt(element.WpbJabasQuantity);
				});

				return QuantityJabas;
			},

			//Calcular las Jabas restantes que aun faltan
			isDifferenceJabas(Amount) {
				var isValidated = true;
				var restante =
					this.viewItemsAll.QuantityJabas - this.sumTotalJaba;

				if (restante < Amount) {
					this.$fun.alert(
						"Esta superando la cantidad de jabas recepcionadas",
						"warning",
					);
					isValidated = false;
					return;
				}
				return isValidated;
			},

			//***************************** FIN CALCULOS ******************************************* */

			//*****************************  GUARDADO Y VALIDACIONES ******************************************* */

			//Validacion General
			isValidatedAll() {
				var isValidated = true;

				if (this.RcfID == 0 || this.RcfID == undefined) {
					this.$fun.alert("Seleccione un Nro. Guia de cosecha", "warning");
					return;
				}

				//Validamos las jabas restantes
				if (!this.isDifferenceJabas(this.items.WpbJabasQuantity)) {
					return;
				}

				if (
					this.items.WpbJabasQuantity <= 0 ||
					this.items.WpbJabasQuantity.length == 0
				) {
					this.$refs.focusJabasQuantity.error(
						"Debe ingresar Cantidad de Jabas valido",
					);
					isValidated = false;
					return;
				}

				if (this.items.TypeDoor == undefined) {
					this.$fun.alert("Seleccione una puerta..", "warning");
					isValidated = false;
					return;
				}

				if (
					this.items.WpbWeightGross <= 0 ||
					this.items.WpbWeightGross.length == 0
				) {
					this.$refs.focusWeightGross.error(
						"Debe ingresar un Peso Bruto válido",
					);
					isValidated = false;
					return;
				}

				if (
					this.items.WpbWeightJaba <= 0 ||
					this.items.WpbWeightJaba.length == 0 ||
					this.items.WpbWeightJaba == undefined
				) {
					this.$fun.alert(
						"Debe Configurar el Precio por Jaba, Contacte con el Area de Producción",
						"warning",
					);
					isValidated = false;
					return;
				}

				if (
					this.items.WpbWeightPallet < 0 ||
					this.items.WpbWeightPallet.length == 0
				) {
					this.$refs.focusWeightPallet.error(
						"Debe ingresar un Peso de Parihuela válido",
					);
					isValidated = false;
					return;
				}

				if (
					this.GetWeightNetPallet <= 0 ||
					this.GetWeightNetPallet.length == 0
				) {
					this.$fun.alert(
						"El Peso neto Calculado es incorrecto",
						"warning",
					);
					isValidated = false;
					return;
				}

				//Margenes de Peso Promedio de Jaba
				var marginPriceAverage = {
					Min: this.valParamMin,
					Max: this.valParamMax,
				};
				var WeightAverage = this.GetWeightAverageJaba();

				if (
					WeightAverage < marginPriceAverage.Min ||
					WeightAverage > marginPriceAverage.Max
				) {
					this.$fun.alert(
						"El Peso Promedio Jaba Calculado, No esta dentro del rango establecido ==> Min = " +
							marginPriceAverage.Min +
							", Max = " +
							marginPriceAverage.Max +
							", Peso Promedio Calculado = " +
							WeightAverage,
						"question",
					).then((r)=>{
						if(r.value) {
							isValidated = true;
							// return isValidated;
						} else {
							isValidated = false;
							return;
						}
					});
				}

				return isValidated;
			},

			//Grabar Pesada
			Grabar() {
				if (!this.isValidatedAll()) {
					return;
				}

				this.isDelete = false;
				this.isPrintQrMain = false;

				this.items.RcfID = this.RcfID;
				this.items.RcfGngID = null,
        this.items.SecStatus = 1;
				this.items.UsrCreateID = this.$fun.getUserID();
				this.items.WpbWeightNet = this.GetWeightNetPallet;
				this.items.WpbWeightAverageJaba = this.GetWeightAverageJaba();
				this.items.TypeShip = this.ship;

        console.log("save", this.items);

				_sWeighedByBatch.save(this.items, this.$fun.getUserID()).then(r => {
					if (r.status == 200) {
						this.$fun.alert("Registrado correctamente", "success");
						//this.getWeighedByBatch(this.RcfID);
						this.clearAll();
						this.$refs.focusWeightGross.focus();
					}
				});
			},

			//Editar una pesada
			edit() {
				if (this.selectedWeighed.length > 0) {
					this.items = this.selectedWeighed[0];
					this.items.WpbID = this.selectedWeighed[0].WpbID;
					//Disminuimos a la Cantidad Total la cantidad que vamos a editar
					if (this.clickEdit == 0) {
						this.sumTotalJaba =
							this.sumTotalJaba -
							this.selectedWeighed[0].WpbJabasQuantity;
						this.clickEdit += 1;
					}
				} else {
					this.$fun.alert("No existen registros", "warning");
				}
			},

			//Eliminar una pesada
			remove() {
				if (this.itemsWeighed.length > 0) {
					var items = this.itemsWeighed[0];

                    console.log("remove", items);

					items.SecStatus = 0;
					items.UsrCreateID = this.$fun.getUserID();

					this.$fun
						.alert("Esta seguro de eliminar el registro ? ", "question")
						.then(val => {
							if (val.value) {
								_sWeighedByBatch
									.save(items, this.$fun.getUserID())
									.then(r => {
										
										if (r.status == 200) {
											this.$fun.alert(
												"Eliminado correctamente",
												"success",
											);
											 this.isDelete = true;
											 this.getWeighedByBatch(items.RcfID);
										}
									});
							}
						});
				}
			},

			printQR() {
				console.log('imprimorrrr ', this.textQR);
				//console.log("printQR", this.selectedWeighed[0], this.RcfGngID);
				var img = document.querySelector("img");

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 2);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'> table tr td{ padding: 4px; }  @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
						"<div style='margin-top:100px '  class='col col-12 justify-center'>",
				);

				// pwa.document.write(
				// 	"<div style='margin: 20px 20px 20px 0px;'><img alt='Scan me!' style='display: block;' src='" +
				// 		img.currentSrc +
				// 		"' /></div>",
				// );
				pwa.document.write("<br><div ><table border='1' style='border-collapse: collapse;'>");

					pwa.document.write("<tr><td colspan='2' style='text-align:center;'><img  width='200px'src='https://www.sunshineperu.com/assets/img/logo.svg'alt='User Image'ref='userImage'/></td></tr>");
					pwa.document.write("<tr><td colspan='2'> ");
					pwa.document.write("<img alt='Scan me!' style='display: block;' src='" + img.currentSrc + "'/>");
					pwa.document.write(" </td></tr>");

				pwa.document.write("<tr><td style='text-align:center;'>AP-FR-213<br> Paleticket de identificación <br> de pallet</td> ");
				pwa.document.write(" <td style='text-align:center;'>VERSION: 04<br> F. Creación: 20-09-2021 <br>F.U. Revisión: 27-09-2024 </td></tr>");

				pwa.document.write(
					"<tr><td>N° Pallet Ingreso: </td><td style='text-align:center;'>" +
						this.selectedWeighed[0].WpbNumberPallet + 
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Lote de Materia Prima: </td><td style='text-align:center;'>" +
						this.selectedWeighed[0].WpbYuliano +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Proveedor: </td><td style='text-align:center;'>" +
						this.viewItemsAll.PrdCardName +
						" </td></tr>",
				);
				/* pwa.document.write(
					"<tr><td>Lote: </td><td>" +
						this.objForeman.RcfGngID.FltName +
						" </td></tr>",
				); */
				/* pwa.document.write(
					"<tr><td>Fundo: </td><td>" +
						this.viewItemsAll.FltName +
						" </td></tr>",
				); */
				pwa.document.write(
					"<tr><td>Materia Prima: </td><td style='text-align:center;'>" +
						this.viewItemsAll.TypeCultiveName +
						 
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Variedad: </td><td style='text-align:center;'>" +
						this.viewItemsAll.VrtName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Tipo Cultivo: </td><td style='text-align:center;'>" +
						this.viewItemsAll.TypeCropName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Tipo de Envase: </td><td style='text-align:center;'>" +
						this.selectedWeighed[0].TypeChargeName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Cantidad: </td><td style='text-align:center;'>" +
						this.selectedWeighed[0].WpbJabasQuantity +
						" </td></tr>",
				);
				
				pwa.document.write(
					/* "<tr><td>Peso Bruto: " +
						this.selectedWeighed[0].WpbWeightGross + */
						" </td><td> Peso Recepcion (KG): </td><td style='text-align:center;'>" +
						this.selectedWeighed[0].WpbWeightNet +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Peso Promedio (KG): </td><td style='text-align:center;'>" +
						this.selectedWeighed[0].WpbWeightAverageJaba +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Procedencia: </td><td style='text-align:center;'>" +
						this.selectedWeighed[0].TypeZonLocationName +
						" </td></tr>",
				);
				/* pwa.document.write(
					"<tr><td>Puerta: </td><td>" +
						this.selectedWeighed[0].TypeDoorName +
						" </td></tr>",
				); */
				/* pwa.document.write(
					"<tr><td>Certificaciones: </td><td>" +
						this.objForeman.RcfGngID.TcDescription +
						" </td></tr>",
				); */

				pwa.document.write("</table></div>");
				pwa.document.write("</div></body></html>");
				pwa.document.close();
			},

			sumTotalWeight(){

				this.sumTotalParihuela = 0
				this.sumTotalWeightGross = 0
				this.sumTotalWeightNet = 0
				this.sumWeightAverage = 0
				this.itemsWeighed.forEach(element => {
					this.sumTotalParihuela += parseFloat(element.WpbWeightPallet)
					this.sumTotalWeightGross += parseFloat(element.WpbWeightGross)
					this.sumTotalWeightNet += parseFloat(element.WpbWeightNet)

					

					console.log("vamos a sumar", element);
				});

				this.sumWeightAverage = (this.sumTotalWeightNet / this.sumTotalJaba).toFixed(2)
				this.sumWeightAverage = this.sumWeightAverage.toFixed(2)
			},
		},

		watch: {
			itemsWeighed() {
				this.sumTotalJaba = this.GetSumQuantityJabas();

				if(this.itemsWeighed.length == 1 || this.itemsWeighed.length == 0) {
					this.loadData();
				}

				this.sumTotalWeight()
			},
			GetWeightNetPallet() {
				this.RcfWeightProm = this.GetWeightAverageJaba();
			},

			sumTotalJaba(){
				if(this.viewItemsAll.QuantityJabas == this.sumTotalJaba){
					this.loadData();
				}
			}
		},

		computed: {
			//Calcular el peso Neto
			GetWeightNetPallet() {
				if (this.items !== undefined) {
					var QuantityJabas = 0,
						WeightGross = 0,
						WeightPallet = 0,
						WeightJaba = 0,
						WeightNet = 0;
					QuantityJabas = this.items.WpbJabasQuantity;
					WeightGross = this.items.WpbWeightGross;
					WeightPallet = this.items.WpbWeightPallet;
					WeightJaba = this.items.WpbWeightJaba;
					
					WeightNet =
						WeightGross - WeightPallet - WeightJaba * QuantityJabas;

					console.log(WeightGross + ' ' + WeightPallet + ' ' + WeightJaba + ' ' + QuantityJabas);	
				}
				WeightNet = WeightNet.toFixed(2);
                
				return WeightNet;
			},
		},

		created() {

			this.loadData();
			//Cargar las Guia  de cosecha
			/* _sWeighedByBatch
				.getHarvestGuide(
					{ xPrint: 0, TypeShip: this.ship },
					this.$fun.getUserID(),
				)
				.then(r => {
					if (r.data.length > 0) {
						this.itemsNumberGuide = r.data;
						console.log("Guias de Cosecha", this.itemsNumberGuide);
					}
				}); */

			/* this.loadParameter(); */
		},
	};
</script>

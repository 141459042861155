<template>
	<div>
		<s-crud
			:config="config"
			add
			@save="save($event)"
			edit
			remove
			title="Programa Venta"
			height="250"
			@rowSelected="rowSelected($event)"
			@EditEvent="EditEvent($event)"
		>
		<template v-slot:filter="{row}">
	<v-container >
	<v-row justify="center">
	<v-col lg="3">
	<s-select-definition
							full
							label="Seleccione Semana"
							:def="1310"
							v-model="SpwWeek"
						>
						</s-select-definition>
	</v-col>
	</v-row>
	</v-container>
		</template>
			<template scope="props">
				<v-container>
					<v-row justify="center">
						<v-col cols="12" lg="6" md="12" sm="12">
							<v-row>
								<v-col cols="12" class="s-col-form" lg="8" >
									<sselect-client-sap 
										clearable  :text="props.item.SpgCustomerName"
										label="Cliente"
										v-model="props.item.SpgCustomerID"
									></sselect-client-sap>
								</v-col>
								<v-col cols="12" class="s-col-form" lg="4" md="6" sm="12" xs="12">
									<s-select-definition
										:def="1181"
										label="Destino"
										v-model="props.item.TypeDestinity"
									/>
								</v-col>
								
							</v-row>
							<v-row>
							<v-col cols="12" class="s-col-form" lg="4" md="6" sm="12" xs="12">
									<s-select-definition
										v-model="props.item.TypeCultive"
										label="Cultivo"
										:def="1173"
									></s-select-definition>
								</v-col>
								<v-col cols="12" class="s-col-form" lg="4" md="6" sm="12" xs="12">
									<s-select-variety
										v-model="props.item.VrtID"
										label="Variedad"
										:cultiveID="props.item.TypeCultive"
									></s-select-variety>
								</v-col>
								<v-col cols="12" class="s-col-form" lg="4" md="6" sm="12" xs="12">
									<s-date
										label="Inicio Semana"
										v-model="props.item.SpgDateBegin"
									></s-date>
								</v-col>
							</v-row>
						</v-col>
						</v-row>
						<v-row justify="center">
						<v-col cols="12" lg="6" md="12" sm="12">
							<v-row>
								<v-col cols="12" class="" lg="12" md="6" sm="12" xs="12">
									<v-row>
							<h2 class="mt-6 ml-3">Calibres</h2>
							<v-spacer> </v-spacer>
							<v-btn
								@click="addCaliber()"
								class="mt-3 mr-3"
								small
								fab
								color="primary"
								><i style="font-size:16px;" class="fas fa-plus"></i
							></v-btn>
						</v-row>

								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="s-col-form" lg="6" md="6" sm="12" xs="12">
									<s-select-definition
										v-model="objCaliber"
										return-object
										label="Calibre"
										:def="1266"
										ref="selectTypeCaliber"
									></s-select-definition>
								</v-col>
								<v-col cols="12" class="s-col-form" lg="6" md="6" sm="12" xs="12">
									<s-text
										label="Cantidad (Kg)" decimal
										v-model="SpdAmount" ref="quantityCaliber"
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="s-col-form" lg="12" md="12" sm="12" xs="12">
									<v-data-table dense
										:headers="headersDetail"
										:items="items"
									>
										<template v-slot:item.Actions="{ item }">
											<v-btn
												@click="clickDeleteCaliber(item)"
												x-small
												elevation="0"
												block
												color="error"
											>
												<v-icon style="font-size: 16px !important">
													mdi-minus-circle-outline
													</v-icon>
												</v-btn>
										</template>
									</v-data-table>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
			</template>
			
		</s-crud>
	</div>
</template>

<script>
	import SselectClientSap from '@/components/Sale/SselectClientSap.vue'
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety.vue";
	import _sSleSaleProgramService from "@/services/Sale/SleSaleProgramService.js";
	import _sSleSaleProgramDetailService from "@/services/Sale/SleSaleProgramDetailService.js";

	export default {
		components: {
			SselectClientSap,
			SSelectVariety
		},

		data() {
			return {
				config: {
					service: _sSleSaleProgramService,
					model: {
						SpgID: "ID",
						SpgDateBegin: "date",
						SpgDateEnd: "date"
					},
					headers: [
						{ text: "ID", value: "SpgID" },
						{ text: "Semana N°", value: "SpgWeek" },
						{ text: "Fecha Inicio", value: "SpgDateBegin" },
						{ text: "Fecha Fin°", value: "SpgDateEnd" },
						{ text: "Cliente", value: "SpgCustomerName" },
						{ text: "Destino", value: "TypeDestinityName" },
						{ text: "Cultivo", value: "TypeCultiveName" },
						{ text: "Variedad", value: "VrtDescription" },
					],
				},
				objCaliber: null,
				SpdAmount: '',
				headersDetail: [
					{text: "Calibre", value: "TypeCaliberName"},
					{text: "Cantidad", value: "SpdAmount"},
					{text: "Acción", value: "Actions"},
				],
				items: [],
				isEdition: false,
				itemsDelete: [],
				SpgID: 0,
			}
		},

		methods: {
			rowSelected(rows)
			{
				this.$emit("rowSelected", rows[0]);
			},

			addCaliber()
			{
				
				if(this.objCaliber==null){ 
					this.$fun.alert('Ingresar Calibre','warning');
					return;
				}
				if(!this.SpdAmount>0){
					this.$refs.quantityCaliber.error('Ingrese Cantidad mayor a Cero');
					return;
				}
				let exist = false;

				this.items.forEach(element => {
					if(element.TypeCaliber == this.objCaliber.DedValue){
						exist = true;
					}
				})

				if(!exist){
					let obj = {
						SpdID: 0,
						SpgID: this.SpgID,
						TypeCaliber : this.objCaliber.DedValue,
						TypeCaliberName : this.objCaliber.DedDescription,
						SpdAmount : this.SpdAmount,
						SecStatus : 1,
						UsrCreateID : this.$fun.getUserID(),
						SecCreate: new Date(),
						UsrUpdateID : this.$fun.getUserID(),
						SecUpdate: new Date()
					};
					this.items.push(obj);
					this.SpdAmount='';
					this.objCaliber=null;
					this.$refs.quantityCaliber.focus();
				}
				else{
					this.$fun.alert("Calibre ya registrado", "warning");
					this.$refs.selectTypeCaliber.focus();
				}
			},

			save(item){
if(item.SpgCustomerID==0 || item.SpgCustomerID==null){
	this.$fun.alert('Ingrese Cliente','warning');
	return;
}
				if(this.isEdition){
					let items2 = [
						...this.items,
						...this.itemsDelete
					]
					item.SleSaleProgramDetails = items2;
				}else{
					item.SleSaleProgramDetails = this.items;
				}

				
				item.save()
				.then(resp => {
					this.items = [];
					this.itemsDelete =[];
				})
			},

			clickDeleteCaliber(item) {
				this.items = this.items.filter(
					(x) => x.TypeCaliber != item.TypeCaliber
				);

				if(this.isEdition&& item.SpdID!=null && item.SpdID!=0){
					item.SecStatus = 0;
					this.itemsDelete.push(item);
				}
			},

			EditEvent(item)
			{
				this.isEdition = true;
				this.SpgID = item[0].SpgID;
				_sSleSaleProgramDetailService.list(this.SpgID, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.items = resp.data;
						console.log(this.items);
					}
				})
			}
		},
	}
</script>
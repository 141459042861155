<template>
  <v-container>
    <div>
      <!-- :single-row="false" -->
      <s-crud
        title="Equipo Biométrico "
        :filter="filter"
        :config="config"
        add
        edit
        no-full
        remove
        ref="crudBioEqui"
        @rowSelected="rowSelected($event)"
        @save="save($event)"
        search-input
      >
        <template v-slot:filter>
          <v-container>
            <v-row style="margin-left: 5px" justify="center">
              <v-col cols="6" md="3" lg="3">
                <s-text
                  label="Nombre del Equipo Biométrico"
                  v-model="filter.BieEquipment"
                ></s-text>
              </v-col>
              <v-col cols="6" md="3" lg="3">
                <s-text
                  label="IP del Equipo Biométrico"
                  v-model="filter.BieIp"
                ></s-text>
              </v-col>
              <v-col cols="6" md="3" lg="3">
                <s-select-definition
                  label="Estado del Equipo"
                  :def="1516"
                  v-model="filter.BieModel"
                  clearable
                  no-default
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template scope="props">
          <v-container>
            <v-row>
              <v-col cols="4" md="3" lg="3">
                <s-text
                  ref="BieEquipment"
                  label="Nombre del Equipo *"
                  v-model="props.item.BieEquipment"
                  :rules="rules"
                ></s-text>
              </v-col>

              <v-col cols="4" md="3" lg="3">
                <s-text
                  ref="BieIP"
                  label="IP del Equipo *"
                  v-model="props.item.BieIP"
                  :rules="rules"
                >
                </s-text>
              </v-col>
              <v-col cols="4" md="3" lg="3">
                <s-text number label="COM" v-model="props.item.BieCom">
                </s-text>
              </v-col>
              <v-col cols="4" md="3" lg="3">
                <s-text
                  ref="BiePortIP"
                  label="Puerto IP *"
                  v-model="props.item.BiePortIP"
                  :rules="rules"
                >
                </s-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" md="3" lg="3">
                <s-text
                  number
                  label="Transmisión FH"
                  v-model="props.item.BieTpTransmissionFH"
                ></s-text>
              </v-col>
              <!-- modelo -->
              <v-col cols="3" md="3" lg="3">
                <s-select-definition
                  ref="BieModel"
                  :def="1516"
                  label="Modelo del Equipo *"
                  v-model="props.item.BieModel"
                ></s-select-definition>
              </v-col>
              <!-- modelo -->
              <v-col cols="3" md="3" lg="3">
                <s-text label="Serie del Equipo" v-model="props.item.BieSerie">
                </s-text>
              </v-col>
              <v-col cols="3" md="3" lg="3">
                <s-text
                  label="Texto de la Pantalla"
                  v-model="props.item.BieTxtDisplay"
                >
                </s-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="3" lg="3">
                <s-date
                  label="Fecha del Registro"
                  v-model="props.item.BieRegistrationDate"
                ></s-date>
              </v-col>

              <v-col cols="4" md="3" lg="3">
                <s-select-definition
                  label="Estado del Equipo"
                  :add="$fun.isAdmin()"
                  :def="1497"
                  v-model="props.item.BieStatus"
                />
              </v-col>

              <v-col cols="4" md="3" lg="3">
                <s-select-definition
                  label="Tipo de Comunicación"
                  :add="$fun.isAdmin()"
                  :def="1496"
                  v-model="props.item.TypeComunication"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </s-crud>
    </div>
  </v-container>
</template>

<script>
//Service
import _sBiometricEquipmentService from "@/services/HumanResource/BiometricEquipmentService";

export default {
  components: {},
  props: {},
  data() {
    return {
      selected: [],
      filter: {},

      config: {
        service: _sBiometricEquipmentService,
        model: {
          BieID: "ID",
          BieRegistrationDate: "date",
          BieModel: "int",
        },
        headers: [
          { text: "ID", value: "BieID", width: 10 },
          { text: "Equipo", value: "BieEquipment", width: 100 },
          { text: "IP", value: "BieIP", width: 30 },
          { text: "Puerto IP", value: "BiePortIP", width: 30 },
          { text: "Modelo", value: "BieModelName", width: 40 },
          { text: "Serie ", value: "BieSerie", width: 50 },
          { text: "Txt de la Pantalla", value: "BieTxtDisplay", width: 50 },
          {
            text: "Tipo de Comunicación",
            value: "TypeComunicationName",
            width: 50,
          },
          {
            text: "Fecha de Registro",
            value: "BieRegistrationDate",
            width: 80,
          },
          { text: "Estado", value: "BieStatusName", width: 50 },
        ],
      },
      validationErrors: {
        BieEquipment: false,
        BieIP: false,
        BiePortIP: false,
      },

      rules: [(value) => !!value || "El campo es obligatorio."],
    };
  },

  watch: {},

  methods: {
    rowSelected(items) {
      if (items.length > 0) {
        this.selected = items[0];
      }
    },
    validateFields(item) {
      console.log("Pru");
      this.$refs.BieEquipment.touched = true;
      this.$refs.BieIP.touched = true;
      this.$refs.BiePortIP.touched = true;

      // Inicializa las validaciones
      this.validationErrors = {
        BieEquipment: !item.BieEquipment,
        BieIP: !item.BieIP,
        BiePortIP: !item.BiePortIP,
      };

      // Comprueba si hay errores
      const hasErrors = Object.values(this.validationErrors).some((val) => val);

      if (hasErrors) {
        this.$fun.alert(
          "Por favor, complete todos los campos obligatorios.",
          "warning"
        );
        return false;
      }

      return true;
    },

    save(item) {
      console.log("item", item);
      if (this.validateFields(item)) {
        // Lógica para guardar los datos
        item.save();
      }
    },
  },
};
</script>
<style scoped>
</style>
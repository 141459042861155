import Service from "../Service";

const resource = "hmnAgreement/";

export default {
    pagination(parameters, requestID) {
        console.log("pagination", parameters);
        return Service.post(resource + "pagination", parameters, {
            params: {
                requestID: requestID,
            },
        });
    },
    workerPerson(WkrID, requestID) {
        return Service.post(
            resource + "workerPerson", {}, {
                params: { WkrID: WkrID, requestID: requestID },
            }
        );
    },
    create(ntp, requestID) {
        return Service.post(resource + "create", ntp, {
            params: { usrID: requestID },
        });
    },
    Positionlist(parameters,requestID) {
        return Service.post(resource + "Positionlist", parameters, {
            params: { requestID: requestID },
        });
    },
    captureFingerprint() {
        return Service.post(resource + "startfingerprintcapture")
            .then(response => {

                return response.data; 
            })
            .catch(error => {
                console.error("Error capturing fingerprint:", error);
                throw error;
            });
    },
    InitialFingerprint(parameters,requestID) {
        return Service.post(resource + "initializefingerprint", parameters, {
            params: { requestID: requestID },
        });
    },
    closeDevice() {
        return Service.post(resource + "close")
            .then(response => {
                return response.data; 
            })
            .catch(error => {
                console.error("Error closing the device:", error);
                throw error;
            });
    }

};
<template>
	<div>
		<s-crud
			:transfer="!disableButtonSampling "
			@transfer="transfer()"
			:filter="filter"
			:config="config"
			search-input
			@rowSelected="rowSelected($event)"
			title="Registro de Daños"
			height="auto"
			no-full
			ref="crudLotDiscart"
			:single-row="false"
		>
			<template v-slot:filter>
				<v-container>
					<v-row justify="center">
						<v-col cols="6" md="4" lg="3">
							<s-date
								label="Fecha Inicio"
								v-model="filter.cDateInitial"
							></s-date>
						</v-col>
						<v-col cols="6" md="4" lg="3">
							<s-date
								label="Fecha Fin"
								v-model="filter.cDateFin"
							></s-date>
						</v-col>
						<v-col cols="12" md="4" lg="3">
							<s-select-definition
								:def="1230"
								label="Planta Procesadora"
								v-model="filter.TypeProcessingPlant"
								clearable
							/>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="6" md="4" lg="3">
							<s-select-definition
								clearable
								:def="1172"
								label="Tipo Cultivo"
								v-model="filter.TypeCrop"
							/>
						</v-col>
						<v-col cols="6" md="4" lg="3">
							<s-select-definition
								clearable
								:def="1173"
								label="Cultivo"
								v-model="filter.TypeCultive"
							/>
						</v-col>

						<v-col cols="12" md="4" lg="3">
							<s-select-variety
								clearable
								:cultiveID="filter.TypeCultive"
								label="Variedad"
								full
								v-model="filter.VrtID"
							/>
						</v-col>
					</v-row>

					<v-row style="margin-left: 5px">
						<v-col cols="6" sm="2">
							<v-btn
							   v-if="$fun.isAdmin()"
								@click="showDamage"
								style="color: white"
								small
								color="info"
								rounded
								block
								:disabled="disableButtonSampling"
							>
								Daños
							</v-btn>
						</v-col>
						<!-- <v-col cols="6" sm="2">
							<v-btn
								@click="showBrix"
								style="color: white"
								small
								rounded
								block
								color="success"
								:disabled="disableButtonSampling"
							>
							Muestreo
							</v-btn>
						</v-col> -->
					</v-row>
				</v-container>
			</template>

			<template v-slot:RcfState="{ row }">
				<v-chip x-small :color="row.TypeStateColor">
					{{ row.TypeStateName }}
				</v-chip>
			</template>

			<template v-slot:TypeAlertName="{ row }">
				<v-chip x-small :color="row.TypeAlertColor">
					{{ row.TypeAlertName }}
				</v-chip>
			</template>

			<template v-slot:ViewCert="{ row }">
				<v-btn x-small color="info" block outlined @click="clickView(row)">
					<v-icon style="font-size: 16px !important">fas fa-eye</v-icon>
				</v-btn>
			</template>

			<template v-slot:LdhWeightExportable="{ row }">
				<v-chip small color="info">
					{{ row.LdhWeightExportable }}
				</v-chip>
			</template>

			<template v-slot:LdhTransfer="{ row }">
				<v-chip x-small :color="row.LdhTransfer == 0  ? 'warning': 'info'">
					{{ row.LdhTransfer == 0  ? 'No': 'Si' }}
				</v-chip>
			</template>

			<template v-slot:LdhSampled="{ row }">
				<v-chip x-small :color="row.LdhWeightExportable == null  ? 'error': 'success'">
					{{ row.LdhWeightExportable == null  ? 'No': 'Si' }}
				</v-chip>
			</template>
			<template v-slot:RcfID="{ row }">
				{{ row.RcfID + "-" + row.RcfCode}}
			</template>	
			
		</s-crud>

		<v-dialog
			v-if="openDialogView"
			v-model="openDialogView"
			persistent
			style="display: contents"
			width="70%"
		>
			<s-certification-lot :FltID="FltID" @close="closeCert()" />
		</v-dialog>

		<v-dialog
			v-model="dialogDamage"
			v-if="dialogDamage"
			scrollable
			width="unset"
			persistent
			fullscreen
			transition="dialog-bottom-transition"
		>
				<lot-damage
					:lotProduction="lotProduction"
					@close="closeDamage"
				></lot-damage>
		</v-dialog>

		<v-dialog persistent v-model="openDerive" v-if="openDerive" width="500">
			<v-card>
				<v-col>
					<v-row>
						<h2 class="mt-6 ml-3">Derivar a congelado</h2>
						<v-spacer></v-spacer>
						<v-btn
							class="mt-3 mr-3"
							@click="openDerive = false"
							small
							fab
							color="error"
							><i style="font-size:16px;" class="fas fa-times"></i
						></v-btn>
					</v-row>
					<v-row>
						<v-col>
							<s-text
								label="Transpaso de"
								v-model="lotCalibratedData.FltName"
								readonly
							></s-text>
						</v-col>
						<v-col>
							<s-date
								label="Fecha"
								v-model="lotProduction.TfhDateGuide"
							></s-date>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<s-text label="N° Guia interna" v-model="lotProduction.LdhNumberGuideInternal"></s-text>
						</v-col>

						<!-- <v-col cols="6">
							<s-text label="Fecha" v-model="lotProduction.LdhNumberGuideInternal"></s-text>
						</v-col> -->

						<v-col cols="6">
							<s-text label="Cant. Jabas" number v-model="lotProduction.LdhQuantityJabas"> </s-text>
						</v-col>

						<v-col cols="6">
							<s-text label="Peso Descarte" decimal v-model="lotProduction.LdhDiscartTotal"> </s-text>
						</v-col>
					</v-row>
					<v-col>
						<v-row justify="center" class="pt-3">
							<v-spacer></v-spacer>
							<v-btn class="ml-3" dark color="primary" @click="sendFrozen()"
								>Transferir</v-btn
							>
						</v-row>
					</v-col>
				</v-col>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	/* import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService"; */

	//Service
	import _sDiscartDamage from "../../../services/FreshProduction/PrfLotDiscartDamageService.js";
	import _sFarmLot from '@/services/Technicalassistance/FarmLotService';

	//Components
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	import SCertificationLot from "@/components/TecnicalAssistence/sCertificationLot.vue";

	/* import LotBrixGrade from './LotBrixGrade.vue'; */
	import LotDamage from "./LotDamage.vue";

	export default {
		components: {
			LotDamage,
			SSelectVariety,
			SCertificationLot,
		},

		data() {
			return {
				lotCalibratedData: {},
				lotProductionSelection: [],
				RcfIdsArray : [],
				RcfIdsArrayString : "",
				openDerive: false,
				disableButtonSampling: true,
				lotProduction: [],
				dialogDamage: false,
				FltID: 0,
				openDialogView: false,
				filter: {},
				config: {
					service: _sDiscartDamage,
					model: {
						RcfID: "ID",
						RcfDateReceptionSurveillance: "date",
						RcfState: "",
						ViewCert: "",
						TypeAlertName: "",
						LdhWeightExportable: "",
						LdhTransfer: "0",
						LdhSampled: "",
					},
					headers: [
						{ text: "View", value: "ViewCert", width: 70 },
						{ text: "Estado", value: "RcfState", width: 20 },
						{ text: "Transferido", value: "LdhTransfer", width: 20 },
						{ text: "Muestreo daños", value: "LdhSampled", width: 100, align: "center"},
						{ text: "Nro. Lote.", value: "RcfID", width: 100 },
						{
							text: "Fec. Recep. Vigilancia.",
							value: "RcfDateReceptionSurveillance",
							width: 140,
						},
						{
							text: "Peso Promedio Fruta (KG)",
							value: "WbbWeightAverage",
							width: 160,
						},
						{
							text: "Cant. Fruta",
							value: "AmountJabasWeigh",
							width: 160,
						},
						{
							text: "Peso Exportable (KG)",
							value: "LdhWeightExportable",
							width: 160,
						},

						{
							text: "Peso Descarte (KG)",
							value: "LdhDiscartTotal",
							width: 160,
						},
						
						{ text: "Productor", value: "PrdCardName", width: 260 },
						{ text: "Fundo", value: "FagName", width: 260 },
						{ text: "Lote", value: "FltName", width: 100 },
						{ text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
						{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
						{ text: "Variedad", value: "VrtName", width: 160 },
						{ text: "Cod. Senasa", value: "FltCodSenasa", width: 160 },
						{
							text: "Tipo Servicio",
							value: "TypeServiceName",
							width: 100,
						},
						{
							text: "Guia Cosecha",
							value: "RcfNumberHarvestGuide",
							width: 160,
						},
						

						{
							text: "Peso Muestreado (KG)",
							value: "WbbGrossWeight",
							width: 160,
						},

						{
							text: "Peso Total Lote (KG)",
							value: "WbbNetWeight",
							width: 160,
						},
						

						{
							text: "Planta Procesdora",
							value: "TypeProcessingPlantName",
							width: 160,
						},
						{ text: "Empacadora", value: "TypeBalerName", width: 160 },
						{
							text: "Zona Ubicacion",
							value: "TypeZonLocationName",
							width: 160,
						},
						{ text: "Alerta", value: "TypeAlertName", width: 100 },
						{
							text: "Observaciones",
							value: "RcfObservations",
							width: 260,
						},
					],
				},
			};
		},

		methods: {
			showDamage() {
				this.dialogDamage = true;
			},

			closeDamage() {
				this.dialogDamage = false;
				this.$refs.crudLotDiscart.refresh();
			},

			clickView(val) {
				this.FltID = val.FltID;
				this.openDialogView = true;
			},

			closeCert() {
				this.openDialogView = false;
			},

			rowSelected(item) {
				if(item.length > 0){
					this.RcfIdsArray = [];
					this.lotProductionSelection = [];
					if(item !== undefined){
						
						this.lotProduction = item[0];
						this.disableButtonSampling = false;
						item.forEach(element => {
							this.lotProductionSelection.push(element);
							if(this.RcfIdsArray.indexOf(element.RcfID) == -1 && element.LdhWeightExportable > 0)
							{
								this.RcfIdsArray.push(element.RcfID);
							}
							
						});
					}
				}
				
				
			},

			getFarmLotTransfer()
			{
				let obj = {
					OriginTransfer : 0
				}
				_sFarmLot
				.searchlottransfer(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.lotCalibratedData = resp.data;
					}
				})
			},

			transfer(){
				var countError = 0;
				this.lotProductionSelection.forEach(element => {
					if(element.LdhWeightExportable == null){
						this.$fun.alert("Advertencia, registro seleccionado, aun no se ha muestreado => " + element.RcfID + "-"+element.RcfCode, "warning");
						countError ++;
					}
				});
				this.lotProductionSelection.forEach(element => {
					if(element.LdhTransfer == 1){
						this.$fun.alert("Advertencia, lote seleccionado ya fue transferido => " + element.RcfID + "-"+element.RcfCode, "warning");
						countError ++;
					}
				});
				if(countError == 0){
					this.getFarmLotTransfer();
					this.openDerive = true;
					this.RcfIdsArrayString = this.RcfIdsArray.join(",");
				}
				/* if(this.lotProduction.LdhWeightExportable > 0){
					this.getFarmLotTransfer();
					this.openDerive = true;
				}else{
					this.$fun.alert("Error, registro seleccionado, aun no se ha muestreado", "warning")
				} */
			},


			sendFrozen(){
				this.transfer();

				if(this.lotProduction.LdhNumberGuideInternal.length == 0 || this.lotProduction.LdhNumberGuideInternal == null){
					this.$fun.alert(
						"Error, Ingrese numero de guia interna",
						"warning",
					);
					return;
				}

				if(this.lotProduction.LdhQuantityJabas <= 0){
					this.$fun.alert("Error, Ingrese una cantidad de jabas", "warning")
					return
				}

				if(this.lotProduction.LdhDiscartTotal <= 0){
					this.$fun.alert("Error, Ingrese un Peso de Guia", "warning")
					return
				}

				this.lotProduction.LdhGrossWeight = this.lotProduction.LdhDiscartTotal
				this.lotProduction.UsrCreateID = this.$fun.getUserID();
				this.lotProduction.Rcfids = this.RcfIdsArrayString; 
				this.lotProduction.xState = 1;
				this.lotProduction.TfhDateGuide = this.lotProduction.TfhDateGuide;

				console.log("Guardar", this.lotProduction);

					this.$fun.alert("¿Seguro de transferir Lote descarte a congelado?", "question").then(val => {
						if(val.value){
							_sDiscartDamage.transferDamage(this.lotProduction, this.$fun.getUserID()).then(resp => {
								if(resp.status == 200){
									this.$fun.alert("Transferido correctamente", "success");
									this.openDerive = false
									this.$refs.crudLotDiscart.refresh();

								}
							})
						}
					})

				
			},
		},
	};
</script>

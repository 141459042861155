<template>
  <v-container class="pr-0 pl-0">
    <div v-if="dialogOrder">
      <v-dialog v-model="dialogOrder" persistent width="100%">
        <v-card>
          <order-purchase-edit @onClose="refresh()" :value="reqID" :order="{}" />
        </v-card>
      </v-dialog>
    </div>

    <s-crud
      :height="$vuetify.breakpoint.xs ? 'auto' : '200'"
      :config="configCrud"
      :filter="filter"
      sortable
      searchInput
      title="Aprobación de Ordenes de Compra"
      :single-row="this.isMobile"
      @rowSelected="rowSelected($event)"
      ref="sCrudApproval"
      :rowDefault="false"
     
    >
     <!--  <template v-slot:filter>
        <v-container>
          <v-row>
            <v-col>
              <s-date
                v-model="filter.BeginDate"
                label="Fecha Inicio"
              ></s-date>
              <s-date
                v-model="filter.EndDate"
                label="Fecha Fin"
              ></s-date>
            </v-col>
          </v-row>
        </v-container>
      </template> -->

     <!--  <template v-slot:AtsStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.AtsStatus == 1 ? 'primary' : 'success'">
          {{ row.AtsStatus == 1 ? "Por Aprobación" : "Aprobado" }}
        </v-chip>
      </template>
 -->
      <template v-slot:OrdAttach1="{ row }">
        <v-icon style="font-size: 18px !important" class="mr-2" @click="showOrder(row)"> fas fa-eye </v-icon>
      </template>

      <template v-slot:SecStatus="{ row }">
        <v-icon small class="mr-2" @click="viewDetail(row)">
          {{ iconmobil }}
        </v-icon>
      </template>
      <template v-if="!this.isMobile" v-slot:options>
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" :disabled="selected.length == 0" small elevation="0" @click="AddMasive(true)">
              <v-icon style="font-size: 16px" color="success">far fa-thumbs-up</v-icon>
            </v-btn>
          </template>
          <span>Aprobacion Masivas</span>
        </v-tooltip>
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" :disabled="selected.length == 0" small elevation="0" @click="DenyMasive(true)">
              <v-icon style="font-size: 16px" color="error">far fa-thumbs-down</v-icon>
            </v-btn>
          </template>
          <span>Desaprobar Masivas</span>
        </v-tooltip>
      </template>

      <template v-slot:OrdTotal="{ row }">
        {{ row.OrdTotal >= 1000 ? parseFloat(row.OrdTotal.toFixed(2)).toLocaleString("es-MX") : row.OrdTotal.toFixed(2) }}
      </template>

    


    </s-crud>

    <v-col style="padding: 0px; margin-top: 6px" v-if="!this.isMobile">
      <s-toolbar :label="cabecera" download @download="downloadExcel()" ></s-toolbar>
      <v-data-table
        v-if="this.typeorder == 1"
        hide-default-footer
        :items-per-page="-1"
        dense
        :items="itemsDetail"
        :headers="headersDetail"
        item-key="name"
      >
        <template v-slot:item.OddAmount="{ item }">
          {{ item.OddAmount >= 1000 ? parseFloat(item.OddAmount.toFixed(2)).toLocaleString("es-MX") : item.OddAmount.toFixed(2) }}
        </template>
      </v-data-table>
      <v-data-table v-else hide-default-footer dense :items-per-page="-1" :items="itemsDetail" :headers="headersOrder">
        <template v-slot:item.OddAmount="{ item }">
          {{ item.OddAmount >= 1000 ? parseFloat(item.OddAmount.toFixed(2)).toLocaleString("es-MX") : item.OddAmount.toFixed(2) }}
        </template>
        <template v-slot:item.OddUnitPrice="{ item }">
          {{ item.OddUnitPrice >= 1000 ? parseFloat(item.OddUnitPrice.toFixed(2)).toLocaleString("es-MX") : item.OddUnitPrice.toFixed(2) }}
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">Esta seguro de Rechazar?</v-card-title>
        <v-card-text>
          <s-textarea autofocus label="Motivo de Rechazo" v-model="AtsObservation" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="closeDelete">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="denyItemConfirm()">confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogViewOrder" persistent max-width="600px">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>Orden</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogViewOrder = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="grey lighten-1" dark> far fa-clipboard </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="this.detail.OrdDocumentNumber"></v-list-item-title>
              <v-list-item-subtitle>N°Orden</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="grey lighten-1" dark> far fa-calendar-alt </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="$fun.formatDateView(this.detail.SecCreate)"></v-list-item-title>

              <v-list-item-subtitle>F.Creacion</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar v-if="this.detail.TypeCurrency == 2">
              <v-icon class="grey lighten-1" dark> fas fa-dollar-sign </v-icon>
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <h2>S/</h2>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <!-- {{this.detail.OrdTotal}} -->
                {{
                  this.detail.OrdTotal >= 1000
                    ? parseFloat(this.detail.OrdTotal.toFixed(2)).toLocaleString("es-MX")
                    : this.detail.OrdTotal
                    ? this.detail.OrdTotal.toFixed(2)
                    : this.detail.OrdTotal
                }}
              </v-list-item-title>

              <v-list-item-subtitle>Monto Total</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>

        <v-list three-line>
          <v-subheader style="height: 6px; font-weight: bold; margin-bottom: 3px">Listado de Items</v-subheader>
          <v-list-item-group v-model="selected">
            <template v-for="(item, index) in this.detail.Detail">
              <v-list-item :key="index" style="min-height: 0.5px">
                <template>
                  <v-row>
                    <v-col cols="6" class="pr-0 mr-0 pr-0">
                      <v-list-item-action style="font-size: 11px" class="mr-0 pr-0 text--primary">
                        {{ item.OddDescription }}
                      </v-list-item-action>
                    </v-col>
                    <v-col cols="6" class="pl-1 pr-0" style="align-self: center">
                      <div style="font-size: 11px" class="pr-0">
                        <v-row>
                          <v-col cols="1" class="pr-0 pl-3">{{ item.UnitMeasurement }}</v-col>
                          <v-col cols="4" class="pl-0 pr-0" style="align-self: center; text-align: right">
                            {{ item.OddQuantity.toFixed(2) }}
                          </v-col>
                          <v-col cols="6" class="pr-0 pl-0" style="align-self: center; text-align: right">{{
                            item.OddAmount >= 1000 ? parseFloat(item.OddAmount.toFixed(2)).toLocaleString("es-MX") : item.OddAmount.toFixed(2)
                          }}</v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </v-list-item>

              <v-divider v-if="index < items.length - 1" :key="index" style="height: 1px"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="denyItem($event)" style="width: 90px">Desaprobar</v-btn>
          <v-btn color="primary" dark @click="approvalMobil($event)" style="width: 90px">APROBAR</v-btn>
          <v-btn color="info" outlined style="width: 90px" @click="dialogOrder = true">Ver mas</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import _sNotification from "@/services/Logistics/ApprovalNotification";
import _sRequirement from "@/services/Logistics/RequirementService";
import _sOrder from "@/services/Logistics/OrderPurchaseService";
import _sApproval from "@/services/Logistics/OrderPurchaseApproval";
import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";


export default {
  components: { OrderPurchaseEdit },
  props: {},
  data: () => ({
    report : {},
    filter: { UsrID: 0, PstID: 0 },
    dialogDelete: false,
    dialogViewRequeriment: false,
    dialogViewOrder: false,
    dialogOrder: false,
    filterDetail: {
      ReqID: 0,
    },
    selected: {},
    reqID: 0,
    typeorder: 0,
    items: [],
    requeriment: [],
    detail: [],
    AtsObservation: "",
    itemsDetail: [],
    headersDetail: [
      { text: "ArticuloID", value: "ArtCode", sortable: true, width: 80 },
      { text: "Descripcion", value: "DerDescription" },
      { text: "Unid. Medida", value: "UnitMeasurement", sortable: false },
      { text: "Cantidad", value: "DerQuantity", sortable: false, align: "end" },
      { text: "Centro Costos", value: "CceName", sortable: false },
      { text: "Tipo Costo", value: "TypeCostName", sortable: false },
      { text: "Linea Negocio", value: "BslName", sortable: false },
    ],
    cabecera: "Detalle",
    headersOrder: [
      { text: "Descripcion", value: "OddDescription" },

      { text: "Cantidad", value: "OddQuantity", sortable: true, align: "center" },
      { text: "Unid. Medida", value: "UnitMeasurement", sortable: true },
      { text: "Proveedor", value: "CardName", sortable: true, width: 280 },
      { text: "Moneda", value: "TypeCurrencyName", sortable: false },
      { text: "Precio Unitario", value: "OddUnitPrice", sortable: false, align: "center" },
      { text: "Total", value: "OddAmount", sortable: false, align: "end" },

      { text: "Centro Costos", value: "CceName", sortable: false },
      { text: "Tipo Costo", value: "TypeCostName", sortable: false },
      { text: "Linea Negocio", value: "BslName", sortable: false },
    ],
    isMasive: false,
    denyitem: {},
    requerimentMobil: {},
    isDeny: false,
    denyMobil: {},
    iconmobil: "far fa-envelope",
  }),
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    filterDetail() {
      return { ReqID: this.reqID };
    },
    dialogViewOrder() {
      if (this.dialogViewOrder == false) this.iconmobil = "far fa-envelope";
    },
    dialogViewRequeriment() {
      if (this.dialogViewRequeriment == false) this.iconmobil = "far fa-envelope";
    },
  },
  created() {
    this.filter.UsrID = this.$fun.getUserID();
    this.filter.PstID = this.$fun.getUserInfo().CtrContract.PstID;
  },
  computed: {
    configCrud() {
      return {
        
        model: {
          OrdID: "ID",
          OrdDate: "date",
          AleStatus: "int",
          AtsStatus: "",
          OrdTotal: "",
          SecCreate: "date",
          OrdAttach1: "",
          OrdAttach2: "",
          OrdAttach3: "",
          OrdAttach4: "",

          SecStatus: "int",
          CmpName: ""
        },
        service: _sApproval,
        headers: this.isMobile
          ? [
              { text: "", value: "SecStatus", align: "center", width: "5" },
              { text: "N°Orden", value: "OrdDocumentNumber" },
              { text: "Fecha", value: "OrdDate" },
              { text: "Proveedor", value: "SupName" },
              { text: "Usuario", value: "NtpFullName" },
            ]
          : [
              { text: "N°Orden", value: "OrdDocumentNumber", width: "130" },
              /* { text: "Estado", value: "AtsStatus", width: "130" }, */
              { text: "Fecha", value: "OrdDate", width: "100" },
              { text: "Proveedor", value: "SupName" },
              { text: "Moneda", value: "CmpName" , align: "center"},
              { text: "Total", value: "OrdTotal", align: "end" },
              { text: "Usuario", value: "NtpFullName", width: "180" },
              {
                text: "Ver Más",
                value: "OrdAttach1",
                sortable: false,
                align: "center",
              },
              // { text: "Estado", value: "TrsStatus", sortable: false },
            ],
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    downloadExcel(){
      console.log("ss", "sadas")

      //this.itemsDetail
      // this.$fun.downloadFile(
      //           this.itemsDetail,
      //           this.$const.TypeFile.EXCEL,
      //           this.cabecera
			// 					);

      // _sQryConfigurationService
			// 		.downloadexcel(this.itemsDetail, this.$fun.getUserID())
			// 		.then(
			// 			(r) => {
			// 				if (r.status == 200) {

			// 					this.$fun.downloadFile(
			// 						r.data,
			// 						this.$const.TypeFile.EXCEL,
      //             this.cabecera
			// 					);
			// 				}
			// 			},
			// 			(e) => {
			// 				//this.messageProcessing = e.response.data.Message;
			// 			}
			// 		);

      let parametervalue = [];

      parametervalue.push(this.reqID)
      // parametervalue.push(this.filter.DateEnd)
      // parametervalue.push(this.filter.TypeCultive)
      let parametergen = "@OrdID";
      this.downloadReport(this.cabecera, "[LgsOrderPurchase_S_EXCEL]", parametervalue, parametergen);


    },
    showOrder(item) {
      console.log('item', item);
      this.reqID = item.OrdID;
      this.dialogOrder = true;
    },
    rowSelected(item) {
      console.log(item);
      item = item.filter(element =>{ 
        return element.AtsStatus == 1
      });
      this.selected = item;
      console.log('selected ',this.selected);
      if (item.length > 0) {
        this.reqID = item[item.length - 1].OrdID;
        this.typeorder = item[item.length - 1].TypeApproval;
      } else {
        this.reqID = 0;
        this.typeorder = 0;
        this.itemsDetail = [];
        this.cabecera = "Detalle";
      }
      if (this.reqID != 0) this.getdataGeneral(this.reqID, this.typeorder);
      //this.filterDetail.ReqID = this.reqID;
    },
    getdataGeneral(id, type) {
      if (type == 1) {
        this.requeriment = { ReqID: id };
        // Requerimiento
        _sRequirement.byID(this.requeriment, this.$fun.getUserID()).then((r) => {
          if (r.status == 200) {
            this.detail = { ...r.data };
            this.itemsDetail = this.detail.Detail;
            console.log('detalles ', this.itemsDetail);
            this.cabecera = "Detalle de Requerimiento " + this.detail.ReqDocumentNumber;
          }
        });
      } else {
        this.requeriment = { OrdID: id };
        _sOrder.byID(this.requeriment, this.$fun.getUserID()).then((r) => {
          if (r.status == 200) {
            this.detail = { ...r.data };
            this.itemsDetail = this.detail.Detail;
            this.cabecera = "Detalle Orden " + this.detail.OrdDocumentNumber;
          }
        });
      }
    },
    getdata(id, type) {
      if (type == 1) {
        this.dialogViewRequeriment = true;
        this.dialogViewOrder = false;
        this.requeriment.ReqID = id;
        // Requerimiento
        _sRequirement.byID(this.requeriment, this.$fun.getUserID()).then((r) => {
          if (r.status == 200) {
            console.log("data", r.data);
            this.detail = { ...r.data };
            this.detail.SecCreate = this.moment(this.detail.SecCreate, this.$const.FormatDateDB).format(this.$const.FormatDateView);
          }
        });
      } else {
        this.dialogViewRequeriment = false;
        this.dialogViewOrder = true;

        this.requeriment.OrdID = id;
        _sOrder.byID(this.requeriment, this.$fun.getUserID()).then((r) => {
          if (r.status == 200) {
            this.detail = { ...r.data };
            //this.detail.SecCreate = this.moment(this.detail.SecCreate, this.$const.FormatDateDB).format(this.$const.FormatDateView);
            //console.log(this.detail.SecCreate);
          }
        });
      }
    },
    //mostrar detalle
    viewDetail(item) {
      this.requerimentMobil = item;
      this.iconmobil = "far fa-envelope-open";
      this.isDeny = true;
      this.denyMobil = item;
      this.getdata(item.OrdID, item.TypeApproval);
    },
    //_sDetail.

    denyItem(item) {
      if (item != null) {
        this.dialogDelete = true;
        this.isMasive = false;
        this.AtsObservation = "";
        this.denyitem = item;
        this.isDeny = this.isMobile;
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    denyItemConfirm(item) {
      if (this.isMasive) {
        this.selected.forEach((element) => {
          element.DocumentNumber = element.OrdDocumentNumber;
          element.DocEntry = element.OrdID;
          element.TypeApproval = 2;

          element.UsrCreateID = this.$fun.getUserID();
          element.UsrUpdateID=this.$fun.getUserID();
          element.AtsStatus = 3;
          element.AtsObservation = this.AtsObservation;
        });
        this.save(this.selected, 2);
      } else {
        if (this.isDeny) {
          this.denyMobil.DocumentNumber = this.denyMobil.OrdDocumentNumber;
          this.denyMobil.DocEntry = this.denyMobil.OrdID;
          this.denyMobil.TypeApproval = 2;
          this.denyMobil.AtsStatus = 3;

          this.denyMobil.UsrCreateID = this.$fun.getUserID();
          this.denyMobil.UsrUpdateID=this.$fun.getUserID();
          this.denyMobil.AtsObservation = this.AtsObservation;
          this.items.push(this.denyMobil);
        } else {
          this.denyitem.DocumentNumber = this.denyitem.OrdDocumentNumber;
          this.denyitem.DocEntry = this.denyitem.OrdID;
          this.denyitem.TypeApproval = 2;
          this.denyitem.AtsStatus = 3;

          this.denyitem.UsrCreateID = this.$fun.getUserID();
          this.denyitem.UsrUpdateID=this.$fun.getUserID();
          this.denyitem.AtsObservation = this.AtsObservation;
          this.items.push(this.denyitem);
        }
        this.save(this.items, 2);
      }
      this.closeDelete();
    },
    refresh() {
      this.dialogOrder = false; 
      
      this.items = [];
      this.dialogViewRequeriment = false;
      this.dialogViewOrder = false;
      this.$refs.sCrudApproval.refresh();
    },
    AddMasive(item) {
      this.selected.forEach((element) => {
        element.AtsStatus = 2;
        element.DocumentNumber = element.OrdDocumentNumber;
        element.DocEntry = element.OrdID;
        element.TypeApproval = 2;
        element.UsrCreateID = this.$fun.getUserID();
        element.UsrUpdateID=this.$fun.getUserID();
      });
      this.save(this.selected, 1);
    },
    DenyMasive(item) {
      this.AtsObservation = "";
      this.dialogDelete = true;
      this.isMasive = true;
    },
    save(item, transaction) {
      this.$fun.alert("Confirmar Operación?", "question").then((r) => {
        if (r.value) {
          var message;
          if (transaction == 1) {
            message = "Guardado Correctamente";
          } else {
            message = "Actualizado Correctamente";
          }
          _sNotification.save(item, this.$fun.getUserID()).then((r) => {
            this.$fun.alert(message, "success");
            this.refresh();
          });
        }
      });
    },
    approvalMobil() {
      this.items = [];
      this.requerimentMobil.DocumentNumber = this.requerimentMobil.OrdDocumentNumber;
      this.requerimentMobil.DocEntry = this.requerimentMobil.OrdID;
      this.requerimentMobil.TypeApproval = 2;
      this.requerimentMobil.AtsStatus = 2;
      this.items.push(this.requerimentMobil);
      this.save(this.items, 1);
    },
    
    downloadReport(QryNameReport,qryname,parametervalue,parametergen) {
				 console.log("QryNameReport",QryNameReport);

       
				this.report.QryNameReport = QryNameReport	; 
      
        //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArray = parametergen	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArrayValue = parametervalue.join(","); //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryNameProcedure = qryname;
					//this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;
      
			 
				_sQryConfigurationService
					.downloadexcel(this.report, this.$fun.getUserID())
					.then(
						(r) => {
							if (r.status == 200) {
							 

								this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									 QryNameReport
								);
							}
						},
						(e) => {
							//this.messageProcessing = e.response.data.Message;
							this.processing = true;
						}
					);

			 
			}, 
  },
};
</script>

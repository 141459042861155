import Service from "../Service";
import Vue from "vue";
const resource = "generic/";

export default {
    getDistrict(requestID) {
        return Vue.prototype.$http.defaults.baseURL + "/generic/district";
    },
    getProvince(requestID) {
        return Vue.prototype.$http.defaults.baseURL + "/generic/province";
    },
    getDepartment(requestID) {
        return Vue.prototype.$http.defaults.baseURL + "/generic/department";
    },
    getZones(requestID) {
        return Vue.prototype.$http.defaults.baseURL + "/generic/zones";
    },
    getArticlesGroup() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/articlesgroup";
    },
    getNationality() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/nationality";
    },
    getUrlSearchArea() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/AreasSearch";
    },
    getUrlSearchAreaSCire() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/AreasSCire";
    },
    getUrlSearchBusinessLine() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/businesslineSearch";
    },
    getUrlPosition() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/PositionSearch";
    },
    getUrlProjects() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/Projects";
    },
    getUrlSearchCenterCost() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/CenterCostSearch";
    },
    getUrlSearchCampaign() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/campaigns";
    },
    getUrlSearchCampaignSe() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/campaignsSe";
    },
    getUrlSearchCampaignArea() { 
        return Vue.prototype.$http.defaults.baseURL + "/generic/campaignsArea";
    },
    searcharea(area, type, requestID) {
        return Service.post(
            resource + "areassearch", {}, { params: { search: area, TypeArea: type, requestID: requestID } }
        );
    },
    getUrlCompanySituation() {
        return (
            Vue.prototype.$http.defaults.baseURL + "/generic/GenCompanySituation"
        );
    },
    getUrlCompanySystem() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/companySystem";
    },
    getUrlCompanyComponent() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/companycomponents";
    },

    getUrlSearchProperty() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/PropertySettings";
    },
    getUrlPropertyTables() {
        return (
            Vue.prototype.$http.defaults.baseURL + "/generic/propertysettingstables"
        );
    },
    getURLTransaction() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/Transaction";
    },
    searchPropertyValues(text, requestID) {
        return Service.post(
            resource + "propertysettingsvalues", {}, { params: { search: text, requestID: requestID } }
        );
    },
    searchLocation(text, requestID, isprimary) {
        return Service.post(
            resource + "location", {}, { params: { search: text, requestID: requestID, isPrimary: isprimary } }
        );
    },
    searchLocationSecondary(text, requestID, isprimary) {
        return Service.post(
            resource + "locationsecondary", {}, { params: { search: text, requestID: requestID, isPrimary: isprimary } }
        );
    },
    getArticlesItems() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/articlesitems";
    },
};
<template>
    <div>
        <s-toolbar dense dark color="#7b7b7b" @save="saveBags()" @close="close()" save close label="Armado de Estático"/>
        <v-card class="pa-md-4 mx-lg-auto">      
            <v-row>
                <v-col style="padding-bottom: 10px;">
                    <v-card-title class="primary--text">Datos Generales</v-card-title>
                    <v-card-text>
                      
                            <v-row>
                                <v-col  hidden>
                                    <s-date
                                        label="Fecha de Produccion"
                                        v-model="item.DateProcess"                                        
                                    />
                                </v-col>
                                <v-col  >
                                    <s-select
                                        label="Turno"
                                        :items="itemTurn"
                                        item-value="TrnID"
                                        item-text="TnrObservation"
                                        v-model="item.TrnID"
                                        @input="initializeLines()"
                                    >                                       
                                    </s-select>
                                </v-col>
                                <v-col  >
                                    <s-select-definition
                                        label="Cultivo" 
                                        :def="1173"
                                        v-model="item.TypeCultive"
                                        @change="initializeLines()"
                                    ></s-select-definition>
                                </v-col> 
                                <v-col  >
                                    <s-select 
                                        label="Linea en proceso"
                                        item-text="LncDescription"
                                        item-value="ClpID"
                                        v-model="item.ClpID" 
                                        :items="itemsLineas"
                                        @input="getDataLine($event, item)"
                                        @change="change($event,item.ClpID,item.TypeCut)"
                                        >
                                        
                                    </s-select>
                                </v-col>
                                <v-col>
                                    <s-select-definition 
                                        label="Tipo de Carrito"
                                        v-model="item.TypeCarConditioned"
                                        :def="1469">

                                    </s-select-definition>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <s-select-client
                                        clearable
                                        label="Cliente"
                                        v-model="item.CumID"
                                        ref="txtCumID"
                                        full>
                                    </s-select-client>
                                </v-col>
                                <v-col>
                                    <s-select-brands                                                
                                        clearable
                                        label="Marca"
                                        v-model="item.CbdID"
                                        ref="SelectBrands"
                                        :cumID="item.CumID"
                                        full>
                                    </s-select-brands>  
                                </v-col> 
                                                            
                                <!-- <v-col >
                                    <s-select                   
                                        :items="itemColorVariety"
                                        item-text="CbcName"
                                        item-value="CcbColor"
                                        return-object
                                        v-model="ColorTextDescription"
                                        label="Color"
                                        ref="colorSelect"
                                        @input="inputColor($event)"
                                        clearable
                                    ></s-select>
                                </v-col> -->
                                <v-col >
                                    <s-text v-model="item.FasCarNumber" 
                                        label="N° de coche"
                                        readonly 
                                        decimal></s-text>
                                </v-col>
                           
                                <v-col >
                                    <s-select-definition
                                        label="Tipo corte"
                                        :def="1463"
                                        v-model="item.TypeCut"
                                        id="DedValue"
                                    ></s-select-definition>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col lg="2">
                                    <label slot="before" aria-hidden="true" class="v-label theme--light"><b>Hora armado de coche</b></label>
                                    <div class="relojito">
                                        <datetime title="Hora  :  Minutos" valueZone="America/Lima"  type="time" v-model="item.FasHour">
                                        </datetime>
									</div>
                                </v-col>   
                                <v-col lg="2">
                                    <s-text @input="CalcularWeightNet()" 
                                        v-model="item.NumberBags" 
                                        decimal 
                                        @max="max"
                                        label="Cantidad de mallas"
                                        ref="focusNumberBags"
                                        ></s-text>
                                </v-col>
                                <v-col lg="3">
                                    <s-text 
                                        v-model="item.WeightAverageBags" 
                                        decimal 
                                        label="Peso promedio malla(kg)"
                                        ref="focusWeightAverageBags"></s-text>
                                
                                    <!-- <s-text v-model="item.WeightNetBags" 
                                        decimal 
                                        label="Peso Neto del coche"></s-text> -->
                                </v-col>
                            </v-row>
                      
                    </v-card-text>
                </v-col>  
                <!-- <v-col lg="6" cols="12" style="padding-bottom: 0px;">
                    <v-card-title class="primary--text">
                        <v-badge dot>
                            Peso por Malla de Estático
                        </v-badge>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs class="ma-0 pa-0">                               
                            <v-row class="pt-3 pr-3 pl-3">
                                <s-text 
                                    v-model="WeightBagValue" 
                                    ref="focusWeightBagValue"
                                    decimal label="Peso Malla"></s-text>
                                
                                <v-btn style="margin: 10px" rounded fab x-small @click="addWeight()">
                                    <i class="fas fa-plus"></i>
                                </v-btn>                            
                            </v-row>                           
                            <v-row>
                                <v-data-table 
                                    dense
                                    :headers="headersDataWeight"
                                    :items="itemsDataWeight"
                                    height="150"                                        
                                    >
                                    <template v-slot:item.Actions="{ item }" >
                                        <v-btn
                                            @click="clickDeleteWeght(item)"
                                            x-small                                        
                                            fab
                                            rounded
                                            color="error">
                                            <v-icon style="font-size:10px !important">
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-row>                            
                        </v-container>
                    </v-card-text> 
                </v-col>   -->
            </v-row>             
        </v-card>
    </div>
</template>

<script>
    import { Datetime } from 'vue-datetime';
    import _sTurnService from "../../../services/FreshProduction/PrfTurnService"; // SERVICIO DEL TURNO
    import _sCultiveByColorService from "@/services/FrozenProduction/CultiveByColorService";
    import _sAssemblyStatic from '@/services/FrozenProduction/FrzCtnAssemblyStaticService.js'
    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"
    import _sCustomerMaduration from "@/services/FrozenProduction/FrzCustomersMaturationService";
    
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    import SSelectBrands from "@/components/FrozenProduction/Chamber/SSelectBrandChamber";

    
    export default {
    components: { Datetime, SSelectBrands, SSelectClient },
    props: {
        value:{
            required: false,
            default: -1,
        },        
        selected: {
            required: false,
            default: Array,
        },
        
    },
    data()  {
        return {   
            itemTurn: [], 
            TypeTurn: 0,
            itemsLineas: [],        
            itemsLineasCUP: [],        
            itemsDataWeight: [],
            itemsDeleteWeight: [],
            Lineprocess: 0,
            headersDataWeight:[
                { text: "item", value :"Line", width: 70},   
                { text: "Malla", value: "NameBag", width: 70 },             
                { text: "Peso", value: "WeightBag", width: 70 },
                { text: "Eliminar", value: "Actions",  align: "center", width: 50 },
            ],
            WeightBagValue: "",
            NumberBags: "",
            itemHead: {
                
            },
            WeightNetBags: "",
            FasDescription: "",
            item: this.defaultItem(),
            FasID: 0,
            DedValue:3,
            SumTotalNet: 0,
            itemColorVariety: [],
            ColorID: 0,
            listCustomer:[],
            DataLine: {},
            // ColorTextDescription: '',
            
        };
    },    
    
    mounted()
    {
        this.getCorrelative();
        this.item.FasHour = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));
        
    },
    created(){
        this.initialize();  
         
    },    
    watch:{
        value(){
            this.initialize();
        }
    },
    methods:
    {       
        getCorrelative()
        {
            _sAssemblyStatic
            .getCorrelative({}, this.$fun.getUserID())
            .then((r) => {
                if(r.status == 200)
                {
                    console.log(r.data);
                    this.item.FasCarNumber = r.data.FasCarNumber;
                }
            });                 
             
        },
        change(e,ClpID,TypeCut){
           let processInitial = {};
            processInitial.TrnID = this.item.TrnID;
            processInitial.TypeCultive = this.item.TypeCultive;
            console.log(processInitial)
            _sFrzCtnLinesProcessService.listLineas(processInitial, this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    this.itemsLineasCUP = r.data.filter(line => line.ClpID == ClpID)     
                    console.log("itemsLineasCUP",this.itemsLineasCUP);     
                    console.log("itemsLineasCUP",this.itemsLineasCUP[0].TypeCut);     
                   this.item.TypeCut= this.itemsLineasCUP[0].TypeCut;       
                   this.item.CumID = this.itemsLineasCUP[0].CumID;  
                }
            })       
        },
        getDataLine(value, item){       
          // this.item.TypeCut  =item.TypeCut    
          //  console.log("item",this.item);      
            _sFrzCtnLinesProcessService.getDataLine(item, this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    this.DataLine = r.data;    
                 //   console.log("DataLine",this.DataLine);       
                }
            })
        },
        addWeight() 
        {                        
            if(this.item.NumberBags > this.itemsDataWeight.length)
            {
                if(this.WeightBagValue.trim() == "") 
                {
                    this.$refs.focusWeightBagValue.error("Debe ingresar un peso valido");
                    return;
                }
                if(this.WeightBagValue <= 0) 
                {
                    this.$refs.focusWeightBagValue.error("Debe ingresar un peso valido");
                    return;
                }
                let itemsBags = {};
                itemsBags.FasID = this.FasID ? this.FasID : 0;
                itemsBags.NameBag = 'Malla ' + parseInt(this.itemsDataWeight.length + 1);
                itemsBags.Line = this.itemsDataWeight.length + 1;
                itemsBags.WeightBag = this.WeightBagValue;
                itemsBags.SecStatus = 1;
                this.itemsDataWeight.push(itemsBags);

                this.WeightBagValue = "";

                this.$refs.focusWeightBagValue.focus();
            }else{
                this.$fun.alert("Alcanzo el numero de mallas " , "warning");
            }
        },
        clickDeleteWeght(item)
        {
            this.itemsDataWeight = this.itemsDataWeight.filter(
                (x) => {
                    return x.Line != item.Line;
                }
            )
            item.SecStatus = 0;
            if(item.FasID != undefined)
            {
                this.itemsDeleteWeight.push(item);                
            }
        },
        CalcularWeightNet()
        {
            if(this.item.TypeCarConditioned==1 && this.item.NumberBags > 88){
                this.$fun.alert("Cantidad maxima permitida 88", "warning");
                this.item.NumberBags=null;
            }else if(this.item.TypeCarConditioned==2 && this.item.NumberBags > 176){
                this.$fun.alert("Cantidad maxima permitida 176", "warning");
                this.item.NumberBags=null;
            }
            // this.SumTotalNet = parseFloat(this.item.WeightAverageBags) * parseFloat(this.item.NumberBags);
            // this.item.WeightNetBags = parseFloat(this.SumTotalNet);
        },
        saveBags() 
        {   
            console.log(this.item);
            this.itemHead.WeightNetBags = this.item.WeightNetBags;
            
            if(this.item.NumberBags == undefined || this.item.NumberBags == 0)
            {
                this.$refs.focusNumberBags.error("Debe ingresar numero de mallas");;
                return;
            }
            if(this.item.TrnID == undefined || this.item.TrnID == 0)
            {
                this.$refs.focusNumberBags.error("Seleccione Turno");;
                return;
            }

            if(this.item.TypeCultive == undefined || this.item.TypeCultive == 0)
            {
                this.$refs.focusNumberBags.error("Seleccione Cultivo");;
                return;
            }

            if(this.item.CumID == undefined || this.item.CumID == 0)
            {
                this.$refs.focusNumberBags.error("Seleccione Cliente");;
                return;
            }

            
            this.itemHead.NumberBags = this.item.NumberBags;
            this.itemHead.FasDescription = this.item.FasDescription;
            this.itemHead.WeightAverageBags = this.item.WeightAverageBags;

            // this.itemHead.DataWeightBags = [...this.itemsDataWeight, ...this.itemsDeleteWeight];
        
            this.itemHead.UsrID = this.$fun.getUserID();
            this.itemHead.FasStatus = this.item.FasStatus ? this.item.FasStatus : 1;
            this.itemHead.SecStatus = this.item.SecStatus ? this.item.SecStatus : 1;
            this.itemHead.TrnID = this.item.TrnID;
            this.itemHead.TypeCultive = this.item.TypeCultive;
            this.itemHead.VrtID = this.DataLine.VrtID;
            // this.itemHead.ColorID = this.ColorID;
            this.itemHead.CumID = this.item.CumID;
            this.itemHead.CbdID = this.item.CbdID;
            this.itemHead.TypeCut = this.item.TypeCut;
            this.itemHead.FasHour = this.item.FasHour;
            this.itemHead.FasCarNumber = this.item.FasCarNumber;
            this.itemHead.TypeCarConditioned = this.item.TypeCarConditioned;
            if(this.item.ClpID == undefined || this.item.ClpID == 0)
            {
                this.$fun.alert("Debe seleccionar una linea en proceso", "warning");
                return;
            }
            this.itemHead.ClpID = this.item.ClpID;
             
            this.itemHead.FasID = this.value;
            
            this.$fun.alert("Estas seguro de guardar?", "question").then((r) => {
                if(r.value){
                    _sAssemblyStatic
                        .save(this.itemHead, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Registrado correctamente", "success");
                                this.$emit("save", r.data);
                                this.close();
                            }
                        });
                    }
                });
        },
        close()
        {
            this.$emit("closestatic");               
            this.item = this.defaultItem();     
            this.itemsDataWeight = [], 
            this.itemsDeleteWeight = [],
           
            this.FasID = 0    
            
            this.$emit("RefreshCars");
        },

        initializeLines()
        {        
            console.log(this.item);
            let processInitial = {};
            processInitial.TrnID = this.item.TrnID;
            processInitial.TypeCultive = this.item.TypeCultive;
            // let ntpId = this.$fun.isAdmin() ? null : this.$fun.getUserInfo().NtpID;

            // processInitial.NtpID = ntpId;
            console.log(processInitial)
            _sFrzCtnLinesProcessService.listLineas(processInitial, this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    this.itemsLineas = r.data.filter(line => line.TypeCut !== null)     
                    console.log("itemsLineas",this.itemsLineas);               
                }
            })       

            // this.getColorVariety(processInitial);
        },

        initialize()
        {              
            if(this.selected.length > 0){
                _sAssemblyStatic.get( this.selected[0], this.$fun.getUserID()).then((r) => {
                    this.item = r.data;                       
                    this.FasID = this.item.FasID;
                    
                    r.data.DataWeightBags.forEach((element, index) => {
                        element.Line = index + 1;
                    });
                    this.itemsDataWeight = r.data.DataWeightBags;                   
                });
            }else{
                
                this.item = this.defaultItem();
            }

            let TypeArea = 4
            _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemTurn = r.data;       
                                              
                    }
                });  

               
        },
        defaultItem(){
            return {
                FasID: -1,
                WeightNetBags: null,
                FasDescription: null,
                NumberBags: null,         
                
            }            
        },
       
    }, 
}
</script>

<style>
.relojito {
	width:auto;
	height: auto;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(160, 159, 159);
	border-radius: 4px;
	padding: 3px;
   
  }
  </style>
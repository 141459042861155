<template>
  <v-card>
   <v-toolbar
					dense
					color="primary"
					height="40"
					dark
					label="Nuevo Contrato">
					<h3>Creación de Contrato</h3>
					<v-spacer></v-spacer>
          <div >
						<v-btn
							fab
							class="elevation-0 mr-3"
							small
							color="primary"
							dark
							@click="guardarTotal()"
							><v-icon>mdi-content-save</v-icon></v-btn
						>
					</div>
          <v-btn
						fab
						class="elevation-0"
						small
						color="primary"
						dark
						@click="close()"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
        <v-row>
					<v-col cols="12" sm="12" lg="12">
            <v-row>
              <v-col cols="12" sm="12" lg="12">
              
                  <!-- Definir las pestañas -->
                  <v-tabs v-model="currentTab" centered>
                    <!-- <v-tab>Datos Personales</v-tab> -->
                    <v-tab>Datos Laborales</v-tab>
                    <v-tab>Datos Asegurados</v-tab>
                    <v-tab>Datos Bancarios</v-tab>
                  </v-tabs>

                  <!-- Contenido de las pestañas -->
                  <v-tabs-items v-model="currentTab">
                    <!-- Contenido para la pestaña de "Datos Personales" -->
                    <!-- <v-tab-item>
                      <v-card class="pa-4">
                        <v-row justify="center">
                          <v-col md="9" lg="9" sm="12">
                            <v-btn block color="success" @click="openDialog">AddPerson</v-btn>
                          </v-col> -->
                          <!-- <v-col md="9" lg="9" sm="12">
                            <s-add-person ref="sAddPerson" :full="true" @search="search" @save="save" :visibleTitle="false" :dataPers="dataPers"   v-model:dni="dni"/>
                          </v-col>
                          <v-col md="3" lg="3" sm="12">
                            <v-img style="border-radius:10px;" height="300" :src="image" />
                            <v-btn color="primary" @click="openCamera" class="mt-4" v-if="image!==null">Tomar Foto</v-btn>
                            <v-btn color="success" @click="capturePhoto" class="mt-4" :disabled="!isCameraActive" v-if="image!==null">Capturar Foto</v-btn>
                            <div>   -->
                            <!-- Elemento de video oculto para acceder a la cámara -->
                            <!-- <video ref="video" style="width: 100%;" autoplay></video> -->
                            <!-- Elemento de canvas para tomar la foto -->
                            <!-- <canvas ref="canvas" style="display: none;"></canvas>
                            </div>
                          </v-col>   -->
                        <!-- </v-row>
                      </v-card>
                    </v-tab-item> -->
                    <!-- Contenido para la pestaña de "Datos Laborales" -->
                    <v-tab-item>
                      <v-card class="pa-4">
                        <v-row justify="center">
                          <v-col md="12" lg="9" sm="12">
                            <s-data-labor ref="sDataLabor" :full="true" :visibleTitle="false"  :datawork="dataWork"/>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                    <!-- Contenido para la pestaña de "Datos Asegurados" -->
                    <v-tab-item>
                      <v-card class="pa-4">
                        <v-row justify="center">
                          <v-col md="9" lg="9" sm="12">
                            <s-data-Asegurado ref="sDataAsegurado" :full="true" :visibleTitle="false"  :datawork="dataWork"/>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                    <!-- Contenido para la pestaña de "Datos Bancarios" -->
                    <v-tab-item>
                      <v-card class="pa-4">
                        <v-row justify="center">
                          <v-col md="9" lg="9" sm="12">
                            <s-data-Bancarios ref="sDataBancarios" :full="true" :visibleTitle="false"  :datawork="dataWork"/>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions>
                
                  </v-card-actions>
              
              </v-col>
              <!-- <v-col md="1">
                <v-btn block color="primary" @click="guardarTotal">GUARDAR</v-btn>
              </v-col> -->
              <v-dialog v-if="dialogEditPerson" v-model="dialogEditPerson"  width="80%" style=" height:90%;" >
                <contract-person-add
                  ref="textAddPerson"
                  @personSaved="personSaved($event)"
                  @toclose="closeDialog()"
                
                />
              </v-dialog>
            </v-row>
          </v-col>
       </v-row>
			</v-card>
  
</template>

<script>
import ContractPersonAdd from "@/views/HumanResource/AgreementPersonAdd.vue";
import sAddPerson from "@/components/Utils/Person/sAddPerson.vue";
import sDataLabor from "@/components/Utils/RecursosHm/sDatosLaborals";
import sDataAsegurado from "@/components/Utils/RecursosHm/sDatosAsegurados";
import sDataBancarios from "@/components/Utils/RecursosHm/sDatosBancarios";
import _sCttoService from '@/services/HumanResource/HmnContractService.js';
import _service from "@/services/HelperService";
import _sWorkertService from '@/services/HumanResource/WorkerService.js';

import ServicePer from "@/services/General/PersonService";
export default {
  components: { sAddPerson,sDataLabor,sDataAsegurado,sDataBancarios, ContractPersonAdd},
  props: {
    objEdit: { type: Object },
  },
  data() {
    return {
      dni: '',
      dnii:"",
      currentTab: 0,
      person: null,
      image: null,
      isCameraActive: false, // Para saber si la cámara está activa
      dataTableTransport: [],
      item: {},
      objLot: {},
      dataWork: {},
      dataPers: {},
      dialogEditPerson:false,
    };
  },

  methods: {
    initialize()
			{
        if (this.objEdit && typeof this.objEdit === 'object') {
          console.log("Objeto Edit", this.objEdit);
        _sCttoService.workerPerson(this.objEdit.WkrID, this.$fun.getUserID()).then((r) => { 
          if (r.status == 200) {
            console.log("dataWork: ",r.data);
            this.dataWork=r.data;
            // ServicePer.getNaturalPerson(this.item.DNI, this.$fun.getUserID()).then((r) => {
            //   if (r.status == 200) {
            //     console.log("dataPers: ",r.data);
            //     this.dataPers=r.data;
            //     console.log("datageneralp: ",this.dataPers);
            //     console.log("datageneralw: ",this.dataWork);
            //   }
            // });
          }
        });
      }
			},
    search(value) {
      if (value != null) {
        if (value.GenPerson.PrsDocumentNumber.length > 0)
          _service.getPhotoWorker(value.GenPerson.PrsDocumentNumber, this.$fun.getUserID()).then((rsp) => {
            const blob = new Blob([rsp.data], { type: "image/jpg" });
            this.dnii=value.GenPerson.PrsDocumentNumber;
            let image = blob;
            var reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = () => {
              this.image = reader.result;
            };
          });
        else this.image = null;
      } else {
        this.image = null;
      }
    },
    save() {
      this.image = null;
    },
    guardarTotal() {
      //const datosPersonales = this.$refs.sAddPerson.getData();  
      const datosLaborales = this.$refs.sDataLabor?.getData();  
      const datosAsegurados = this.$refs.sDataAsegurado?.getData();  
      const datosBancarios = this.$refs.sDataBancarios?.getData();       

      if (!datosLaborales || !datosAsegurados || !datosBancarios) {
        //this.$fun.alert("Faltan datos por completar", "warning");
        return;
      }

      let worker = {
        WkrScireID: 0,
        WkrStatus:1,
        NtpID : datosLaborales.NtpID,
        Agreement:[ 
          { 
            ...datosLaborales,
            AgreementMovemts:[
              {
                ...datosLaborales,
                AmsChildrenQuantity : datosLaborales.AgtChildrenQuantity,
                TypeAms: 1,
                AmsBeginDate: datosLaborales.AgtBeginDate,
                AmsEndDate: datosLaborales.AgtEndDate,
                AmsTerminationDate: datosLaborales.AgtTerminationDate,
                AmsMaximunDay: datosLaborales.AgtMaximunDay,
                AmsSalary: datosLaborales.AgtSalary,
                AmsAdmissionDate: datosLaborales.AgtAdmissionDate,
                AmsStatus : datosLaborales.AgtStatus,
                AmsRenovation : datosLaborales.AgtRenovation,
                UsrCreateID:this.$fun.getUserID(),
                UsrUpdateID:this.$fun.getUserID(),
              }
            ]        
          }
        ],
        Health: [
          { 
            ...datosAsegurados.HmnHealth
          }
        ],
        Pension:[
          { 
          ...datosAsegurados.HmnPension
          }
        ],
        BankAccount : datosBancarios,
        Supervision: 1,
        Notification:1,
        UsrCreateID:this.$fun.getUserID(),
        UsrUpdateID:this.$fun.getUserID(),
        SecStatus:1,
      } 

      // const datosTotales = {
      //   ...datosPersonales,
      //   ...datosLaborales,
      //   ...datosAsegurados,
      //   ...datosBancarios,
      // //  imagen: this.image, // Incluir la imagen capturada si existe
      // };

      console.log("Trabajador",worker);
      // Aquí puedes hacer algo con los datos totales, por ejemplo, enviarlos al backend
      // console.log("Datos completos para guardar: ", datosTotales);
      // console.log("Datos bancarios: ", datosBancarios);
      
      _sWorkertService.save(worker,this.$fun.getUserID()).then(x=>{
        console.log("Data",x);
        if(x.status == 200 ){
          this.$fun.alert("Registrado Correctamene","success");
        }
        else{
          this.$fun.alert("No se Registro Correctamene","warning");
        }
      }).catch((error) => {
        console.error("Error al guardar el trabajador:", error);
        this.$fun.alert("Ocurrió un error al registrar", "error");
      });
    },
    openDialog(){
     this.dialogEditPerson=true;
    },
    personSaved(event){
      console.log("evento",event);
    },
    closeDialog(){
      this.dialogEditPerson=false;
    },
    close(){
      this.$emit("close");
    },
    cancelar(){

    },

    // Método para abrir la cámara
    openCamera() {
      navigator.mediaDevices
        .getUserMedia({ video: { width: 640, height: 480 } }) // Resolución baja
        .then((stream) => {
          this.$refs.video.srcObject = stream; // Asignar el stream al elemento <video>
          this.$refs.video.style.display = 'block'; // Mostrar el video en la interfaz
          this.$refs.video.play(); // Reproducir video
          this.isCameraActive = true; // Indicar que la cámara está activa
        })
        .catch((err) => {
          console.error("Error al acceder a la cámara: ", err);
          this.isCameraActive = false;
        });
    },

    // Método para capturar la foto desde el video
    capturePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");

      // Asegurarnos que el canvas tiene el mismo tamaño que el video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Dibujar el fotograma actual del video en el canvas
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convertir la imagen a base64
      const dataUrl = canvas.toDataURL("image/jpeg");

      // Asignar la imagen capturada al estado `image`
      this.image = dataUrl;

      // Detener la cámara
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      video.srcObject = null;
      this.isCameraActive = false;

      // Subir la imagen al servidor
      //this.uploadImage(dataUrl);
    },

    // Subir la imagen capturada al servidor
    uploadImage(imageData) {
    if (imageData != null) {
        // Crear un nuevo objeto FormData
        var formData = new FormData();
        
        // Convertir la imagen base64 a un Blob para enviar como archivo
        const byteString = atob(imageData.split(',')[1]); // Obtener los bytes de la imagen en base64
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);
        
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }
        
        const file = new Blob([arrayBuffer], { type: 'image/jpeg' });
        
        // Añadir el archivo al FormData
        formData.append('file', file, 'photo.jpg'); // 'photo.jpg' es el nombre del archivo

        // Ahora envía el FormData al backend
        _service.uploadfile(this.dnii, this.$fun.getUserID(), formData, 12)
            .then((resp) => {
                if (resp.status == 200) {
                    this.photoSampling = [];
                    this.$fun.alert("Cargado correctamente", "success");
                    return;
                }
            })
            .catch(error => {
                console.error("Error al subir la imagen: ", error);
            });
    } else {
        this.$fun.alert("Sin archivo para subir");
    }
}
},
created() {
  if (this.objEdit && typeof this.objEdit === 'object') {
    this.item = Object.assign({}, this.objEdit);
  } else {
    this.item = {}; // En caso de que objEdit sea null o undefined
  }
  console.log("datosparam", this.item);
  console.log("datosparam2", this.objEdit);
  this.initialize();
}
};
</script>

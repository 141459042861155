<template>
    <v-card elevation="0">
      <s-toolbar v-if="visibleTitle" label="Datos Bancarios" />
      <v-card-text>
        <v-row>
          <!-- Contenedor para SCTR Pensión -->
          <v-col class="s-col-form" sm="12" md="12" lg="12">
            <h4>{{ title == 0 ? 'EDITAR' : 'NUEVA'  }} CUENTA BANCARIA</h4>
            <v-card class="pa-3" style="border-radius: 12px; border: 1px solid #ccc;">
              <v-row>
                <v-col class="s-col-form" sm="6" md="6" lg="6">
                  <s-select-definition :def="1487" label="Cuenta de" v-model="item.TypePay" ref="TypePayRef"/>
                </v-col>
                <v-col class="s-col-form" sm="6" md="6" lg="6">
                    <s-select-definition :def="1488" label="Tipo de cuenta" v-model="item.TypeAccount" ref="TypeAccountRef"/>
                </v-col>
                
              </v-row>
              <v-row>
                <v-col  class="s-col-form" sm="6" md="6" lg="6">
                    <s-select-definition :def="1129" label="Moneda" v-model="item.TypeMoney"  ref="TypeMoneyRef"/>
                </v-col>
                <v-col class="s-col-form" sm="6" md="6" lg="6">
                    <s-select-definition :def="1316" label="Entidad" v-model="item.TypeEntityBank" ref="TypeEntityBankRef" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="s-col-form" sm="6" md="6" lg="6" >
                    <s-text label="N° cuenta"  v-model="item.BatAccountNumber" @keyupEnter="addItemDetail()"> </s-text>
                </v-col>
                <v-col class="s-col-form" sm="6" md="6" lg="6">
                    <s-select-definition :def="1508" label="Categoria" v-model="item.BatIsDefault" ref="BatIsDefaultRef" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="s-col-form" sm="12" md="12" lg="12">
                  <template>
                    <v-data-table  :headers="bankAccountHeaders" class="elevation-1" :items="bankAccountDetail" >
                      <template v-slot:[`item.actions`]="{ item }">
                        
                        <v-icon
                          small
                          class="mr-2"
                          @click="editItem(item,0)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          @click="deleteItem(item,0)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    
                    </v-data-table>
                  </template>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
  
      <!--<v-card-actions>
        <v-container>
          <v-row>
            <v-col class="s-col-form">
              <v-btn block text @click="close()">Cancelar</v-btn>
            </v-col>
            <v-col class="s-col-form">
              <v-btn block color="primary" @click="save()">GUARDAR</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>-->
    </v-card>
  </template>
  
  
  <script>

  import SText from "../SText.vue";
  export default {
    name: "sDatosBancarios",
    components: { SText},
    props: {
      visibleTitle: {
        type: Boolean,
        default: true,
      },
      full: {
        type: Boolean,
        default: false,
      },
      typeperson: {
        type: Number,
        default: 1,
      },
      addTypeSex: {
        type: Boolean,
        default: false,
      },
      addBirthDate: {
        type: Boolean,
        default: false,
      },
      addDriver: {
        type: Boolean,
        default: false,
      },
      datawork: { type: Object },
    },
    data() {
      return {
        dataWor:{},
        editing: false,
        item: {
            TypePay: 1,
            TypeAccount:2,
            TypeMoney:1,
            TypeEntityBank:2,
            BatAccountNumber:"",
            BatIsDefault: 1,
		    },
        editedIndex:-1,
        itemDefault: {
          TypePay: 1,
          TypeAccount: 2,
          TypeMoney: 1,
          TypeEntityBank: 2,
          BatAccountNumber:""
		    },
        title:1,
		    selectedCenterCost: 0,      
		    selectedBusinessLine: null,
        bankAccountHeaders:[
          //{ text: "ID"                  , value: "PpnID"                 ,sortable: false  , width: 20,  },
          { text: "Tipo de Pago"        , value: "TypePayName"            ,sortable: false  , width: 50,  },
          { text: "Tipo de Cuenta"      , value: "TypeAccountName"        ,sortable: false  , width: 30,  },
          { text: "Moneda"              , value: "TypeMoneyName"          ,sortable: false  , width: 20,  },
          { text: "Entidad Bancaria"    , value: "TypeEntityBankName"     ,sortable: false  , width: 30,  },
          { text: "Entidad Bancaria"    , value: "BatAccountNumber"       ,sortable: false  , width: 100, },
          { text: "Categoria"           , value: "BatIsDefaultName"       ,sortable: false  , width: 80   },
          { text: 'Acciones'            , value: 'actions'                ,sortable: false  , width: 30,  },
        ],       
        bankAccountDetail:[],
        menu3: false,
        config: {},
        dialog: false,
      };
    },
    methods: {
      initialize()
			{
      // if(this.datawork && typeof this.datawork === 'object'){
      //    this.item.NroCuenta =  this.dataWor.BankAccountsCE.NroCuenta || null;
      //    this.item.TypeAccount =  this.dataWor.BankAccountsCE.TypeAccount || null;
      //    this.item.TypePay =  this.dataWor.BankAccountsCE.TypePay || null;
      //    this.item.TypeMoney =  this.dataWor.BankAccountsCE.TypeMoney || null;
      //    this.item.TypeEntityBank =  this.dataWor.BankAccountsCE.TypeEntityBank || null;
      // }
			},
      deleteItem (item) {
        console.log("deleteItem",item);
        this.bankAccountDetail.splice(item, 1);
      },

      editItem(item) {
        console.log("Direcciones2222",item);
          this.isInitializing = true;
          this.editedIndex = this.bankAccountDetail.indexOf(item)
              // Deshabilita temporalmente el watch
          this.item  = item;
          this.title = 0;
          // this.title = 0;
          // this.TypeAddress = this.address.TypeAddress;
          // //this.TypeAddress.DedDescription= this.address.TypeAddressName;
          // this.Zone = this.address.ZonID;
          //this.Zone.DedDescription = this.address.ZonName;
        
       
        console.log("Edit",this.editedIndex);
      },

      addItemDetail(){
        console.log("Editindex",this.editedIndex);
        console.log("cuenta",this.item);
        let bankAccount = {
          TypePay: this.item.TypePay,
          TypePayName: this.$refs.TypePayRef.items.find((x) => x.DedValue === this.item.TypePay).DedDescription,
          TypeAccount: this.item.TypeAccount,
          TypeAccountName: this.$refs.TypeAccountRef.items.find((x) => x.DedValue === this.item.TypeAccount).DedDescription,
          TypeMoney: this.item.TypeMoney,
          TypeMoneyName: this.$refs.TypeMoneyRef.items.find((x) => x.DedValue === this.item.TypeMoney).DedDescription,
          TypeEntityBank: this.item.TypeEntityBank,
          TypeEntityBankName: this.$refs.TypeEntityBankRef.items.find((x) => x.DedValue === this.item.TypeEntityBank).DedDescription,
          BatAccountNumber: this.item.BatAccountNumber,
          BatIsDefault: this.item.BatIsDefault,
          BatIsDefaultName: this.$refs.BatIsDefaultRef.items.find((x) => x.DedValue === this.item.BatIsDefault).DedDescription,
          UsrCreateID: this.$fun.getUserID(),
          UsrUpdateID: this.$fun.getUserID(),
        };
              
        if (this.editedIndex > -1) {
          Object.assign(this.bankAccountDetail[this.editedIndex], bankAccount)
          this.editedIndex = -1;                
        } else {
          this.bankAccountDetail.push(bankAccount)
        }
       
        this.title= -1 ;
        this.clearDetail();
      },

      clearDetail() {
        console.log("Item default111",this.itemDefault);
        //this.item = this.itemDefault;
        this.item = {
          TypePay: 1,
          TypeAccount: 2,
          TypeMoney: 1,
          TypeEntityBank: 2,
          BatAccountNumber:  "", 
          BatIsDefault: 2,
        };
        console.log("Item default2222",this.item);
      },
       
      close() {
        this.$refs.document.focus();
        this.$emit("toclose", 2);
        this.clear();
      },
      save(){
        //this.item.AccountNumber= this.item.AccountNumber;
      },
      getData() {
        if(this.bankAccountDetail.length > 0){
          return this.bankAccountDetail;
        }
        else{
          this.$fun.alert("Al menos una Cuenta Bancaria es obligatorio","warning");
          return null;
        }
      },

      validateData(){
        let valid = true;
        console.log("Data Bancarios",this.item);

        if(this.bankAccountDetail.length=0){
          this.$fun.alert("Al menos una Cuenta Bancaria es obligatorio","warning");
          valid = false;  
        }
   
        
        return valid;
      },
    },
    watch: {
     
     
    },
    created() {
    if (this.datawork && typeof this.datawork === 'object') {
      this.dataWor = Object.assign({}, this.datawork);
    }
      this.initialize();
    },
  };
  </script>
  
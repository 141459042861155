<template>
	<v-card dense>
		<v-col>
			<v-row>
				<v-col>
					<s-toolbar
						label="Registros de temperatura"
						dark
						color="#BDBDBD"
						close
						@close="closeModalTemperatures()"
					></s-toolbar>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-data-table
						:headers="headersEnv"
						:items="itemsTemperatureRecords"
					>
						<template v-slot:[`item.CoeDate`]="{ item }">
							
								{{ $fun.formatDateView(item.CoeDate) }}
							
						</template>
					</v-data-table>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<s-toolbar
						label="Registros de temperatura Pulpa"
						dark
						color="#BDBDBD"
					></s-toolbar>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-data-table
						:headers="headersPulp"
						:items="itemsTemperaturePulpRecords"
					>
					<template v-slot:[`item.CodDate`]="{ item }">
								{{ $fun.formatDateView(item.CodDate) }}
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-col>
	</v-card>
</template>

<script>
	import _sTemperaturePalletService from "@/services/FreshProduction/TemperaturePalletSevice";

	export default {
		props: {
			item: []
		},

		data() {
			return {
				headersEnv: [
					{text:"ID", value: "CoeID"},
					{text:"Temp. 01", value: "CoeTempEnvir01"},
					/* {text:"Temp. 02", value: "CoeTempEnvir02"},
					{text:"Temp. 03", value: "CoeTempEnvir03"}, */
					{text:"Hora Temp.", value: "CoeHour"},
					{text:"Fecha Temp.", value: "CoeDate"},
				],
				itemsTemperatureRecords: [],
				headersPulp: [
					{text:"ID", value: "CodID"},
					{text:"Pallet", value: "PltID"},
					{text:"Temp. 01", value: "CodTempPulpe01"},
					/* {text:"Temp. 02", value: "CodTempPulpe02"},
					{text:"Temp. 03", value: "CodTempPulpe03"},
					{text:"Temp. 04", value: "CodTempPulpe04"}, */
					{text:"Hora Temp.", value: "CodHour"},
					{text:"Fecha Temp.", value: "CodDate"},
					{text:"Observación", value: "CodObservations"},
				],
				itemsTemperaturePulpRecords: [],
			}
		},

		created () {
			console.log('this.item.ChaID ',this.item.ChaID);
			this.getTemperatureEnvRecords();
			this.getTemperaturePulpRecords();
			
		},

		methods: {
			closeModalTemperatures() {
				this.$emit("closeModalTemperatures")
			},

			getTemperatureEnvRecords()
			{
				_sTemperaturePalletService
				.listtemperatureenvrecords(this.item, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsTemperatureRecords = resp.data;
					}
				})
			},

			getTemperaturePulpRecords()
			{
				_sTemperaturePalletService
				.listtemperaturepulprecords(this.item, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsTemperaturePulpRecords = resp.data;
						console.log('resp.data ', resp.data);
					}
				})
			}
		},
	}
</script>

<style scoped>

</style>
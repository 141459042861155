<template>
    <div>
        <s-toolbar dense dark color="#7b7b7b" @save="saveBrans()" @close="close()" save close label="Marcas"></s-toolbar>
        <v-card class="pa-md-4 mx-lg-auto">
            <v-row>
                <v-col lg="12" cols="12" style="padding-bottom: 10px;">
                    <v-card-title class="primary--text">Datos Generales</v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs class="ma-0 pa-0">
                            <v-row>
                                <v-col cols="4" lg="4" md="6" sm="12">
                                    <s-select-client
                                        clearable
                                        label="Cliente"
                                        v-model="item.CumID"
                                        ref="txtCumID"
                                        full>
                                    </s-select-client>
                                    <!-- {{ CcbID }} -->
                                </v-col>

                                <v-col cols="4" lg="4" md="6" sm="12">
                                    <s-select-definition
                                        label="Estado"
                                        v-model="item.CcbState"
                                        :def="1154"
                                    ></s-select-definition>
                                </v-col>
                            </v-row>
                            <v-row class="pt-3 pr-3 pl-3">
                                <h3>Marcas por cliente</h3>
                                <v-spacer></v-spacer>
                                <v-btn rounded fab x-small @click="addItemGP()"
                                    ><i class="fas fa-plus"></i
                                ></v-btn>   
                            </v-row>
                            <v-divider></v-divider>
                            <v-container>
                                <v-row>
                                    
                                    <v-col cols="4" lg="4" md="6" sm="12">
                                        <s-text
                                            label="Marca"
                                            v-model.trim="newBrans.CbdBrans"
                                            ref="txtBrans"
                                        ></s-text>
                                    </v-col>
                                    

                                </v-row>
                                <v-row>
                                    <v-col class="pl-0 pt-0">
                                        <v-data-table
                                            dense
                                            :headers="headerBransGroups"
                                            :items="itemsDataPG"
                                            hide-default-footer
                                            :items-per-page="-1"
                                            disable-sort
                                        >
                                            <template v-slot:item.Actions="{ item }">
                                                <v-btn
                                                    @click="clickDeleteItemGP(item)"
                                                    x-small
                                                    fab
                                                    rounded
                                                    color="error"
                                                >
                                                <v-icon style="font-size: 16px !important"
                                                >mdi-close</v-icon
                                                >
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
    import _sFrzChamberCustomerBrandService from "@/services/FrozenProduction/FrzChamberCustomerBrandService.js";

    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";

   
    export default {
        components:{SSelectClient},
        props:{
            CcbID:{
                // required: false,
                Type: Number,
            },
            selectGroup:{
                required: false,
                default: Array,
            }
        },
        data() {
            return {
                headerBransGroups: [
                    { text:"ID", value :"Line"},
                    { text: "Descripción", value: "CbdBrans"},
                    { text: "Eliminar", value: "Actions", width: 100},
                ],
                newBrans: {},
                itemsDataPG:[],
                itemsDeletePG: [],
                item: {},
                PdgNameGroup: '',
            }
        },
        watch:{
            value(){
                this.getdataBrans();
            }
        }, 
        created(){
            this.getdataBrans();
        },
        methods:
        {
            close()
            {
                this.$emit("close");     
            },
            
            addItemGP(){
                let existBrans = false;
                this.itemsDataPG.forEach(element => {
                    if(element.CbdBrans == this.newBrans.CbdBrans){
                        existBrans = true;
                        console.log('Marca ya existe ',existBrans)
                    }
                });
                if (existBrans){
                    this.$fun.alert("Marca ya Ingresada", "warning");
                    return ;
                }
                 if (!this.newBrans.CbdBrans) {
                    this.$refs.txtBrans.error("Debe ingresar un Motivo");
                    return;
                 } else {
                    this.newBrans.Line = this.itemsDataPG.length + 1;
                    this.newBrans.SecStatus = 1;
                    this.itemsDataPG.push({...this.newBrans});
                    this.newBrans = {};

                    this.CbdBrans = "";
                    this.$refs.txtBrans.focus();
                    
                }
            },
            
            clickDeleteItemGP(item) {
                
                this.itemsDataPG = this.itemsDataPG.filter(
                    (x) => {
                    return x.Line != item.Line
                    }
                );
                item.SecStatus = 0;
                if(item.CbdID > 0)
                {
                    this.itemsDeletePG.push({ ...item} );                    
                }
                
            },  
            
            saveBrans() {

                if(this.item.CumID == undefined)
                {
                    this.$fun.alert("Ingrese Cliente", "warning");
                    this.$refs.txtCumID.focus();
                    return;
                }
                
                if(this.itemsDataPG.length <= 0 ){
                    this.$fun.alert("Ingresar Motivos de paradas", "warning");
                    this.$refs.txtCumID.focus();
                    return;
                }
                
                this.itemsDataPGAdd = [...this.itemsDataPG, ...this.itemsDeletePG];
                
                this.item.FrzChamberCustomerBrandDetail = this.itemsDataPGAdd;
                this.item.UsrCreateID = this.$fun.getUserID();

                this.$fun.alert("Estas seguro de guardar?", "question").then((r) => {
                    if(r.value){
                
                        _sFrzChamberCustomerBrandService.save(this.item, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success");
                            this.$emit("close");

                            this.itemsDataPG = []
                        }
                        });
                    }
                })
            },
            getdataBrans()
            {
                console.log("value ", this.CcbID);
                // this.item = {}
                if(this.CcbID > 0){

                    if(this.selectGroup.length > 0)
                    {   
                        this.item = this.selectGroup[0];
                    }
                    this.itemsDataPG = []
                    
                    
                    _sFrzChamberCustomerBrandService.listdetail({ CcbID: this.CcbID }, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            r.data.forEach((el, index) => {
                                el.Line = index + 1;
                            });
                            this.itemsDataPG = r.data;
                            console.log('Detalles',this.itemsDataPG)
                        }
                    
                    })
                }else{
                    this.item = {};
                    this.itemsDataPG = [];
                }
            }
        }
    }
</script>
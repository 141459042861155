<template>
    <div>
        <v-container>
            <v-card>                
                    <v-card-title>
                        <s-toolbar :label="'Albaran - ' + item.PrdCardNameResponsable"
                            :save="!hiddenBtn"
                            @save="save($event)"
                            :color="'#BAB6B5'"
                            dark
                            :load="items.AlsID > 0"
                            @load="load()"
                            close
                            @close="close()"
                        />
                     
                      
                    </v-card-title>
                    <v-card-text>
                        <v-card dense :disabled="disabled">
                            <v-row style="margin: auto;">
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="6" lg="1">
                                            <s-text label="Nro. Lote"
                                                readonly 
                                                v-model="items.RcfID"></s-text>
                                        </v-col>
                                        <v-col cols="6" lg="2">
                                            <s-text label="Cultivo"
                                                readonly 
                                                v-model="items.TypeCultiveName">
                                            </s-text>
                                        </v-col>
                                        <v-col cols="6" lg="3">
                                            <s-text label="Tipo Cultivo"
                                                readonly 
                                                v-model="items.TypeCropName">
                                            </s-text>
                                        </v-col>
                                        <v-col cols="6" lg="3">
                                            <s-text
                                                label="Variedad"
                                                readonly
                                                v-model="items.VrtName"
                                            ></s-text>
                                        </v-col>
                                        <v-col cols="6" lg="3">
                                            <s-select-definition
                                                label="Tipo Pago"
                                                :def="1377" 
                                                v-model="TypeWeight"
                                                @change=calcules()
                                            />
                                        </v-col>
                                        
                                    </v-row>
                                    <v-row style="margin:auto">
                                                                                      
                                              
                                                    
                                                        <v-col>
                                                            <v-card :color="this.TypeWeight == 2 ? '#0dcaf0': '' "    >
                                                                <v-card-title class="text-h7">
                                                                    Peso de Planta(Kg)
                                                                </v-card-title>
                                                                <v-card-text class="text-h5">
                                                                    {{this.item.WbbNetWeight}}
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col>
                                                            <v-card :color="this.TypeWeight == 1 ? '#0dcaf0': '' ">
                                                                <v-card-title class="text-h7">
                                                                    Peso de Guia(Kg)
                                                                </v-card-title>
                                                                <v-card-text  class="text-h5">
                                                                    {{this.item.RcfWeightGuide}}
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col>
                                                            <v-card :color="this.TypeWeight == 3 ? '#0dcaf0': '' ">
                                                                <v-card-title class="text-h7">
                                                                    Peso de Ticket(Kg)
                                                                </v-card-title>
                                                                <v-card-text class="text-h5">
                                                                    {{this.item.RcfWeightTicket}}
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                               <!--
                                                    <v-col cols="6" lg="3" class="pb-0 pt-4" >
                                                        <b> <v-text-field                                                                                                                
                                                        label="Peso de Planta(Kg)"
                                                        readonly
                                                        v-model="item.WbbNetWeight"
                                                        color ="success"
                                                        ></v-text-field>                            
                                                        </b>
                                                    </v-col>
                                                    <v-col cols="6" lg="3" class="pb-0 pt-4" >
                                                        <v-text-field
                                                        number
                                                        label="Peso de Guia(Kg)"
                                                        readonly
                                                        v-model="item.RcfWeightGuide"
                                                        color ="success"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" lg="3" class="pb-0 pt-4" >
                                                        <v-text-field
                                                        number
                                                        label="Peso de Ticket(Kg)"
                                                        readonly
                                                        v-model="item.RcfWeightTicket"
                                                        ></v-text-field>
                                                    </v-col>-->
                                    </v-row>
                                               
                                         
                                       
                                </v-col>
                            </v-row>
                            <v-row style="margin:auto">
                                <v-col cols="12" lg="6" md="6">
                                    <v-card>
                                        <v-card-text style="height: 40px;  -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; " col-md-6>
                                            <b>Lote Calibrado</b>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-row>
                                                <v-col cols="4" lg="4" class="pb-0 pt-4">
                                                    <b><v-text-field
                                                        label="Cant. Jabas Cal."
                                                        readonly
                                                        v-model="item.LdhQuantityJaba"
                                                    ></v-text-field></b>
                                                </v-col>
                                               
                                                <v-col cols="4" lg="4" class="pb-0 pt-4">
                                                    <b>
                                                        <v-text-field
                                                            label="Promedio Jaba"
                                                            readonly
                                                            v-model="averageJabas"
                                                            :error="averageJabas < valParamMin || averageJabas > valParamMax"
                                                            :messages="averageJabas < valParamMin || averageJabas > valParamMax ? 'Peso Promedio fuera de Param. establecidos. (Min: ' + valParamMin + ', Max: ' + valParamMax + ')' : ''"
                                                        ></v-text-field>
                                                    </b>
                                                </v-col>
                                                <v-col cols="4" lg="4" class="pb-0 pt-4">
                                                    <b><v-text-field
                                                            label="Peso Exportable (KG)"
                                                            readonly
                                                            v-model="item.LdhWeightExportable"
                                                        ></v-text-field></b>
                                                </v-col>

                                                <v-col cols="6" lg="6" class="pb-0 pt-1">
                                                    <b>
                                                        <v-text-field
                                                            label="Descarte Total (KG)"
                                                            readonly
                                                            v-model="item.LdhDiscartTotal"
                                                        ></v-text-field>
                                                    </b>
                                                </v-col>
                                                <v-col cols="6" lg="6" class="pb-0 pt-1">
                                                    <b>
                                                        <v-text-field
                                                            label="Total Peso"
                                                            v-model="items.AlsQuantityKgNew"
                                                            readonly
                                                            
                                                        >
                                                        </v-text-field>                                              
                                                    </b>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" lg="6" md="6">
                                    <v-row>
                                        <v-col>                          
                                    
                                            <v-card>
                                                <v-card-text style="height: 40px;  -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; "
                                                    >
                                                    <b>Lote Producción</b>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-row>
                                                        <v-col cols="6" lg="4" class="pb-0 pt-1">
                                                            <b><v-text-field
                                                                label="Cant, Jabs Recep."
                                                                v-model="items.AmountJabasWeigh"
                                                                readonly
                                                            ></v-text-field></b>
                                                        </v-col>
                                                        <v-col cols="6" lg="4" class="pb-0 pt-1">
                                                            <b><v-text-field
                                                                label="Peso Promedio Jaba"
                                                                v-model="items.WbbWeightAverage"
                                                                readonly
                                                            ></v-text-field></b>
                                                        </v-col>
                                                        <v-col cols="6" lg="4" class="pb-0 pt-1">
                                                            <b><v-text-field
                                                                label="Peso Neto"
                                                                v-model="items.WbbNetWeight"
                                                                readonly
                                                            ></v-text-field></b>
                                                        </v-col>													
                                                    </v-row>
                                                </v-card-actions>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" style="margin-top: -10px; display: inline-table;">
                                            <v-card height="60">
                                                <v-card-actions>
                                                    <v-col cols="6" lg="6" class="pb-0 pt-1">
                                                        <b style="color: red">Margen de Error (Kg):  </b>{{marginErrorKG}} KG
                                                        <b><h3>{{parseFloat(item.LdhWeightExportable == null ? 0 : item.LdhWeightExportable) + parseFloat(marginErrorKG)}} KG</h3></b>
                                                        
                                                    </v-col>
                                                    <v-col cols="6" lg="6" class="pb-0 pt-1">
                                                        <b style="color: red">Diferencia de KG:  </b>
                                                        <b><h3>{{parseFloat(items.AlsQuantityKgNew == null ? 0 : items.AlsQuantityKgNew) - parseFloat(items.WbbNetWeight == null ? 0 : items.WbbNetWeight)}} KG</h3></b>
                                                        
                                                    </v-col>
                                                </v-card-actions>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>        
                            </v-row>
                            <v-divider></v-divider>
                            <v-col cols="12" lg="12" md="12">
                                <v-card>
                                    <v-card-text
                                        style="height: 40px;  -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; "
                                    >
                                        <b>KG x CALIBRE</b>
                                        <b style="color: blue; font-size: 18px"></b>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row>
                                            <v-col v-for="item in Object.keys(newGroups)" >
                                                <h4>{{ item }}</h4>
                                                <v-divider></v-divider>
                                                <v-col class="pt-5 pr-0">
                                                    <v-row v-for="d in  Object.keys(newGroups[item])" >
                                                        <v-col cols="8"
                                                        class="pb-0 pt-1 pr-0 pl-0">
                                                        <h5>{{ d }}</h5>
                                                        <v-row v-for="(j, i) in newGroups[item][d]" :key="i">
                                                            <v-col cols="9" lg="9" md="9">
                                                            <!-- <h5>{{ j }}</h5> -->
                                                                <s-text :label="j.TypeCaliberName"
                                                                    decimal
                                                                    :value="j.ClsQuantityKg == null ? 0 : j.ClsQuantityKg.toFixed(2)"
                                                                    :min="0"
                                                                    readonly></s-text>
                                                                </v-col>
                                                            <v-col cols="3" lg="3" md="3">
                                                                <s-text label="Cnt. Jabas"
                                                                    decimal
                                                                    :value="j.LppQuantityJaba == null ? 0 : j.LppQuantityJaba"
                                                                    :min="0"
                                                                    readonly></s-text>
                                                            </v-col>
                                                        </v-row>
                                                        <!-- <s-text :label="d.TypeCaliberName"
                                                            decimal
                                                            v-model="d.ClbQuantityKg == null ? 0 : d.ClbQuantityKg.toFixed(2)"
                                                            :min="0"
                                                            readonly></s-text> -->
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-card>
                    </v-card-text>
                <!-- </v-container> -->
            </v-card>
        </v-container>
    </div>
</template>

<script>

import _sAlbaranSapService 		from "@/services/FreshProduction/PrfCreateAlbaranSapService";
import _sGenParamService 		from "@/services/General/ParameterService";

export default{
    props:{
        RcfID: {
            required: false,
			default: 0,
        },
        TypeCultive: {
            required: false,
            default: 1,
        },
        item: null
    },
    data: () => ({
        
            items: {
				AlsID: 0,
				RcfID: 0,
				TypeCultive: 0,
				TypeCrop: 0,
				VrtID: 0,
				AlsQuantityKg: 0,
				AlsQuantityKgNew: 0,
				AlsQuantityFruit: 0,
				ExcludeIgv: false,
               // RcfWeightGuide
			},
            averageJabas: 0,
            valParamMin: 0,
			valParamMax: 0,
            marginErrorKG: 0,
            itemsCaliber: [],
            newGroups: [],
            hiddenBtn: false,
            differenceKG: 0,
            disabled: false,
            loadingSAP: false,
            TypeWeight : 2,
            cantKg :0,
            colorWeight: "success",
        
    }),
    watch: {
        'items.AlsQuantityKg'(){
            this.calcules();            
        }
    },
    created(){
        this.initialize();
        
        let fil ={
            RcfID: this.RcfID,
            TypeCultive: this.TypeCultive,
        }

        console.log("filtros",fil );
        _sAlbaranSapService
        .GetCaliber(
            { CtgID: 0, RcfID: this.RcfID, TypeCultive: this.TypeCultive },
                this.$fun.getUserID(),
            )
            .then(r => {
                console.log("Datasss",r.data);
                var dataCaliber = r.data;
                
                this.itemsCaliber = _.groupBy(dataCaliber, "TypeLineBusinessText");
                 
                   
                this.travelCaliber(this.itemsCaliber);
                
                console.log("Item Grupos", this.newGroups);
                console.log("Itemssssssss", this.items);
                this.items.AlsQuantityKg = this.items.AlsQuantityKgSend//.toFixed(2)sum.toFixed(2);

                console.log("SUMA para peso total1-> Cantidad de Kilos", this.items.AlsQuantityKg);
                console.log("SUMA para peso total2-> Descarte", this.item.LdhDiscartTotal)

                this.items.AlsQuantityKgNew = (parseFloat(this.getCantKg() == 0 ? 0 : this.getCantKg()) + parseFloat(this.item.LdhDiscartTotal == null ? 0 : this.item.LdhDiscartTotal));//.toFixed(2);
                console.log("SUMA para peso total3-> Descarte", this.items.AlsQuantityKgNew);
                            
                if(this.item.AlsID != 0) { 
                    this.TypeWeight = 3;
                    this.TypeWeight = this.item.TypeWeight;
                console.log("item33333",this.item);
                // this.calcules();
                }
        }); 

        console.log("item",this.item);
        

       
        this.loadParameter();
    },    
    mounted(){
        
        

        _sAlbaranSapService
        .GetCaliber(
            { CtgID: 0, RcfID: this.RcfID, TypeCultive: this.TypeCultive },
                this.$fun.getUserID(),
            )
            .then(r => {
                var dataCaliber = r.data;
                this.itemsCaliber = _.groupBy(dataCaliber, "TypeLineBusinessText");
                       
              
                
                this.travelCaliber(this.itemsCaliber);
                
                this.items.AlsQuantityKg = this.items.AlsQuantityKgSend.toFixed(2);//sum.toFixed(2);
                this.items.AlsQuantityKgNew = (parseFloat(this.getCantKg() == 0 ? 0 : this.getCantKg()) + parseFloat(this.item.LdhDiscartTotal == null ? 0 : this.item.LdhDiscartTotal)).toFixed(2)
                
                            
        }); 

        // this.initialize();
    },
    methods:{

        calcules(){
            //this.averageJabas = (parseFloat(this.items.AlsQuantityKg == null ? 0 : this.items.AlsQuantityKg)  / parseInt(this.item.LdhQuantityJaba)).toFixed(6);
           

            console.log("Tipo Get Cant", this.getCantKg());
            console.log("Tipo Quantity ",this.item.LdhQuantityJaba);
           this.averageJabas = (parseFloat(this.getCantKg() == 0 ? 0 : this.getCantKg())  / parseInt(this.item.LdhQuantityJaba)).toFixed(6);
           if(isNaN(this.averageJabas)){this.averageJabas = 0;}
           console.log("Totallllll ",this.averageJabas);
            this.differenceKG = Math.abs(parseFloat(this.getCantKg() == 0 ? 0 : this.getCantKg()) - parseFloat(this.item.LdhWeightExportable == null ? 0 : this.item.LdhWeightExportable)).toFixed(6);

            _sAlbaranSapService.GetCaliber({ CtgID: 0, RcfID: this.RcfID, TypeCultive: this.TypeCultive },this.$fun.getUserID(),).then(r => {
                console.log("Caliberrr",r.data);
                var dataCaliber = r.data;
                
                this.itemsCaliber = _.groupBy(dataCaliber, "TypeLineBusinessText");                                   
              
             });
             this.travelCaliber(this.itemsCaliber);
           
        },
        close() {
            this.$emit("close");
        },
        initialize() {
				
        _sAlbaranSapService
            .GetAlbaran({ RcfID: this.RcfID }, this.$fun.getUserID())
            .then(r => {
                console.log("Data Get Albaran", r.data);
                this.items = r.data;
                

                if (this.items.AlsStatusSap == 2) {
                    this.disabled = true;
                    this.$fun.alert(
                        "Solo Lectura, Albaran fue migrado a SAP",
                        "warning",
                    );
                }
            });
        },

        getCantKg(){
            let cantKg = 0;
            if(this.items.AlsQuantityKg != null){
                switch(this.TypeWeight){
                    case 1:
                        cantKg = this.item.RcfWeightGuide;
                        break;
                    case 2:
                        //cantKg = this.items.AlsQuantityKg;
                        cantKg = this.item.WbbNetWeight;
                        break;
                    case 3:
                        cantKg = this.item.RcfWeightTicket;
                        break;
                }
            }
            console.log('Computed cantKg:', cantKg);
            return cantKg;
        },

        travelCaliber(itemsCaliber){
            console.log("items caliberrr",itemsCaliber);
            var sum  = 0
            //this.calcules();
            console.log("Promedio Jaba",this.averageJabas);
            
            for (let prop in itemsCaliber) {
                    this.itemsCaliber[prop].map((i) => {

                        if(this.TypeWeight == 1 && this.averageJabas != 0 ){
                        console.log("Promedio Jaba +++", i.LppQuantityJaba);
                        }
                        i.RcfID = this.RcfID;
                        i.SecStatus = 1
                        i.UsrCreateID = this.$fun.getUserID();
                        i.ClsQuantityKg = this.averageJabas * i.LppQuantityJaba //(this.TypeWeight == 1 && this.averageJabas != 0) ? (this.averageJabas * i.LppQuantityJaba) : i.ClsQuantityKg
                        i.prueba = this.TypeWeight == 1   ? "prueba1" : "prueba2";
                        if(i.ClsQuantityKg == ''){
                            i.ClsQuantityKg = 0
                        }
                        sum += parseFloat(i.ClsQuantityKg == null ? 0 : i.ClsQuantityKg)
                            
                    });
                    let description = {};
                    description = _.groupBy(this.itemsCaliber[prop], "CtgDescription");
                    this.newGroups[prop] = description;
                   console.log("nuevo Group",this.newGroups);

                }
            
          
        },

        save()
        {
            
            this.items.itemsCaliber = [];
            for(let prop in this.newGroups )
                for(let prop2 in this.newGroups[prop])
                    for(let prop3 in this.newGroups[prop][prop2])
                        this.items.itemsCaliber.push(this.newGroups[prop][prop2][prop3]);

            if(this.item.AlsID){
            this.items.AlsID = this.item.AlsID;
            }
            this.items.SecStatus        = 1;
            this.items.UsrCreateID      = this.$fun.getUserID();           
            this.items.AlsQuantityFruit = this.item.LdhQuantityJaba;
            this.items.AlsAverageJaba   = this.averageJabas;
            this.items.TypeWeight       = this.TypeWeight;

            this.$fun.alert("Esta seguro de Guardar ?" , "question")
            .then(val => {
                if (val.value) {
                    console.log("Save",this.items);
                    _sAlbaranSapService
                    .save(this.items, this.$fun.getUserID())
                    .then(r => {
                        this.$fun.alert(
                            "Registro guardado correctamente",
                            "success",
                        );
                        //this.$refs.AlbaranCrud.refresh();
                        this.close();
                    });
                }
            });
        },
        loadParameter() {
            _sGenParamService
                .search(
                    { PrmName: "CalibradoMargenErrorKgAlbaran" },
                    this.$fun.getUserID(),
                )
                .then(resp => {
                    this.marginErrorKG = resp.data.PrmValue;
                });


            _sGenParamService
                .search(
                    { PrmName: "verificaPesoExportAlbaran" },
                    this.$fun.getUserID(),
                )
                .then(resp => {
                    this.isValidatedDiff = resp.data.PrmValue;
                });

            this.valParamMin = this.item.WpcMin
            this.valParamMax = this.item.WpcMax

            if(this.valParamMin == null || this.valParamMax == null){
                this.$fun.alert("Advertencia, no se ha configurado parametros Peso Promedio (Min - Max)", "warning")
                this.hiddenBtn = true
            }else{
                this.hiddenBtn = false
            }
        },
        load() {
            
			if(this.isValidatedDiff == 1){
            
            if(parseFloat(this.item.LdhWeightExportable.toFixed(0)) > parseFloat(this.item.LppWeightCalibrated.toFixed(0))){
                this.$fun.alert(
                    "Error, La distribución guardada en Albáran no coincide con el Peso Exportable, verificar distribución de Calibres.",
                    "warning",
                );
                return;
            }

            }



            this.ussUserCode = this.$fun.getUserSAPInfo().UssUserCode;
            if (this.items.AlbStatusSap == 2) {
            this.$fun.alert(
                "Upps... No cuenta con Permisos para modifcar Albaran.",
                "warning",
            );
            return;
            }

            if (this.ussUserCode == null) {
            this.$fun.alert(
                "No tiene un Usuario SAP autenticado, Comuniquese con el Area de TECNOLOGÍA DE LA INFORMACIÓN..",
                "warning",
            );
            return;
            }






            this.$fun.alert("Seguro de subir a SAP ? Productor a subir ==> " + this.item.PrdCardNameResponsable + "", "question")
            .then(r => {
            if(r.value){
                console.log("Prueba",this.item);
                this.processingSAP = true;
                
                /*_sAlbaranSapService
                .loadsapalbaran(this.item, this.$fun.getUserSAPInfo().UsrID, this.$fun.getUserID())
                    .then(resp => {
                        if (resp.status == 200) {
                            this.processingSAP = false;
                            this.$fun.alert("Subido Correctamente", "success");
                            this.close();
                        }
                    },
                    (e) => {
                        this.errorSap = true;
                        this.messageProcessingSAP = e.response.data.Message;
                        this.processingSAP = true;
                    }
                
                );*/
            }
            })
        },				
					
    },
    
    
} 
</script>

<template>
	<div>
		<v-row>
			<v-col >
			<s-crud
			title="Lista de Inducciones"
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
			ref="gridInstruction"
			@rowSelected="rowSelected($event)" 
			searchInput
			>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col lg="3">
							<s-date
								label="Fecha Inicio"
								v-model="filter.InsDate"
							></s-date>
						</v-col>
						<v-col lg="3">
							<s-date
								label="Fecha Fin"
								v-model="filter.InsDateEnd"
							></s-date>
						</v-col>                       					            			
						<!--<v-col cols="6" md="3" lg="3" >											
							<s-select-definition
								label="Nombre de la Empresa"
								:add="$fun.isAdmin()"
								:def="1471"
								v-model="filter.TypeCompany"
								clearable
							/>
                        </v-col>-->
					</v-row>
					<v-row>
						<v-col cols="6" md="3" lg="3" >
							<v-btn small="" block color="primary" @click="openDialogMassive()">
								Guardar Masivo
							</v-btn>
						</v-col>
					</v-row>
					
				</v-container>
			</template>
			
			
			<template scope="props">
				<v-container>
					<v-row >						
						<v-col cols="4" class="s-col-form">
							<s-date
								label="Fecha" 
								v-model="props.item.InsDate" 
							></s-date>
						</v-col>
						<v-col cols="4" class="s-col-form">
							<s-select-definition 
								label="Tipo Evento"
								:def="1067" 
								v-model="props.item.TypeInstruction"
							/>
						</v-col>
						<v-col cols="4" class="s-col-form">
							<s-select-campaign-se 
								label="Campaña"
								v-model="props.item.CmpID"
							/>
         		 		</v-col>
						<v-col cols="12" class="s-col-form">
            				<s-text label="Observaciones" v-model="props.item.InsObservation" />
          				</v-col> 
						
					</v-row>
					
				</v-container>
			</template>

				</s-crud>
			</v-col>
		</v-row>
		<v-dialog 
			v-if="dialogSaveMassive" 
			v-model="dialogSaveMassive" 
			width="60%" 
			height="80%"
			transition="dialog-bottom-transition"			
			>
			<instrutctionMasiive @EndProcess="endProcessChange($event)" > 

			</instrutctionMasiive>
		</v-dialog>	
	</div>
	
</template>
<script>
	import _sInstruction from '@/services/Management/InstructionListService.js';
	import instrutctionMasiive from '@/views/Management/Instruction/InstructionMassive.vue';

	export default {
    components: {  instrutctionMasiive },
    props: { },
		data() {
			return {
				documents: [],
				person  :[],
				filter: {
					AreID: 1,
					//PrvDateVisit,
                    //PrvDocumentNumber,
                    //PrvCompany
				},
				dialogChange: false,
				dialogSaveMassive: false,
				ins: {
					InsDate: "",
					InsObservation: "",
					InsID: 0,
					TypeInstruction: 1,
				},
				config: {
                    title: "Instructions",
					model: {
						IasID			: "ID",
						InsObservation	: "string",
                        InsDate 		: "date",                       
					},
					service: _sInstruction,
					headers: [
						{text: "ID", 					value: "IasID"					},
						{text: "Nombres y Apellidos", 	value: "NtpFullName"			},					
						{text: "DNI", 					value: "IinDocumentNumber"		},																
						{text: "Observaciones", 		value: "InsObservation"			},					
						{text: "Fecha", 				value: "InsDate"				},
						{text: "Tipo de Instruccion", 	value: "TypeInstructionText"	},
						{text: "Campaña", 				value: "CmpName"				},

					]
				},
				
			}
		},
		watch : {
			filter(){
				console.log(this.filter)
			}
		},
		methods: {
			initialize()
			{
				
			},

			save(item) {				

				console.log("item save", item);
				if(item.InsObservation === "" ){
					this.$fun.alert("Por favor ingresar observacion", "warning");
					return ;
				}
				if(item.SecStatus == null) item.SecStatus = 1

				item.save(item, this.$fun.getUserID());

				console.log("item save2",item);
			},

			validateVisit(){
				let isValid = false;
				let message = "";

				isValid = item.NtpID === "" && item.NtpFullName === "" ;
				if (isValid) message = "No se ha seleccionado a una Persona";
			},

			rowSelected(items){
				console.log("item",items);
				if(items.length > 0){ this.selected = items[0]; }
			},

			endProcessChange(rpt){
				this.dialogSaveMassive = false;
				console.log("vista",this.$refs.gridInstruction);
				this.$refs.gridInstruction.refresh();					
			},
			clear(event){
				this.person = [];
				this.documents = [];				
			},
			openDialogMassive(){
				this.dialogSaveMassive = true;
				console.log("Abrir open")
			},
		}
		
	};
</script>

<style scoped>

</style>
import Service from "../Service";

const resource = "receptionfrozen/";

export default {

    verifLoadsap(obj, requestID) {
        return Service.post(resource + "verifLoadsap", obj, {
            params: { requestID },
        });
    },

    loadsap(obj, UsrID, requestID) {
        return Service.post(resource + "loadsap", obj, {
            params: { UssUserID: UsrID, requestID },
        });
    },

    listpricessap(requestID) {
        return Service.post(resource + "listpricessap", {}, {
            params: { requestID },
        });
    },


    getprice(obj, requestID) {
        return Service.post(resource + "getprice", obj, {
            params: { requestID },
        });
    },

    listpricesSE(obj, requestID) {
        return Service.post(resource + "listpricesSE", obj, {
            params: { requestID },
        });
    },

    saveuploadsap(obj, requestID) {
        return Service.post(resource + "saveuploadsap", obj, {
            params: { requestID },
        });
    },

    searchbyid(obj, requestID) {
        return Service.post(resource + "searchbyid", obj, {
            params: { requestID },
        });
    },

    savejoinlottransfer(obj, requestID) {
        return Service.post(resource + "savejoinlottransfer", obj, {
            params: { requestID },
        });
    },


};
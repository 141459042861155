<template>
	<div>
		<v-card>
			<v-container>
				<!--<v-row>
					<v-col>
						<s-select-definition
							label="Linea Empaque"
							:def="1281"
							v-model="linePacking"
						></s-select-definition>
					</v-col>
				</v-row>-->
				<v-row>
					<v-col cols="4" lg="4" md="4">
						<s-select-definition
							label="Destino"
							:def="1181"
							v-model="TypeDestinyTk"	
							@change="getTypeArea(TypeDestinyTk)"										
							>
						</s-select-definition>
					</v-col>
					<v-col cols="4" lg="4" md="4">
						<s-select-definition
							:dgr="dgr"
							label="Linea Empaque"
							:def="1315"
							v-model="TypeLineProcess"
							 
							:add="$fun.isAdmin()"
							>
						</s-select-definition>
					</v-col>
					<v-col cols="4" lg="4" md="4">
                        <s-select-definition
                            :def="1266"
                            label="Calibres"
                            v-model="TypeCaliber">

                        </s-select-definition>
                    </v-col>
				</v-row>
				<v-row>
					<v-col cols="4" lg="4" md="4">
						<s-select-definition
							label="Faja"
							:def="1472"
							v-model="TypeFaja"
							>
						</s-select-definition>
					</v-col>
					<v-col cols="8" lg="8" md="8">
						<!--<s-presentation
							label="Presentación"
							return-object
                            v-model="ObjPresentation"
							
							>
						</s-presentation>-->
						<s-select
								:items="itemsPresentation"
								item-value="PrcID"
								full
								autocomplete
								item-text="PresentationName"
								label="Seleccione una Presentación"
								v-model="PrcID"
								@input="inputPresentation($event)"
								clearable
							></s-select>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="4" lg="4" md="4">
                    	<s-select
                    	label="Turno de Produccion"
                    	:items="itemTurn"
                    	item-value="TrnID"
                    	item-text="TnrObservation"
                    	v-model="processInitial.TrnID"
                    	>  
                    	</s-select>
                    </v-col>
				</v-row>
				<v-row>
					<v-col cols="12" lg="12">
						<s-text 
							label="Código" 
							@keyupEnter="keyupEnter()" 
							@input="input($event)" 
							v-model="CbpCode"
							ref="stext"
							clearable
						></s-text>
					</v-col>
				</v-row>
				
				
			</v-container>
		</v-card>
	</div>
</template>

<script>
	import _sPrfCountingBoxesPresentationService from "../../../services/FreshProduction/PrfCountingBoxesPresentation"
	import _service from "@/services/FreshProduction/PrfAssignTurnWorKerDetailService";
	import SPresentation from "@/components/Utils/SSelectPresentation";
	import _servicePresentation from "@/services/FreshProduction/PrfPresentationConfigService.js";
	import _sTurnService from "@/services/FreshProduction/PrfTurnService"; // SERVICIO DEL TURNO

	export default {
		components: {
			SPresentation
		},
		data() {
			return {
				CbpCode: "",
				separator: "",
				aux: "",
				linePacking : 0,
				TypeDestinyTk: 1,
				TypeLineProcess: 1,
				TypeCaliber:0,
				TypeFaja:0,
				ObjPresentation:null,
				dgr: 70,
				itemsPresentation: [],
				PrcID: 0,
				processInitial:{},
				itemTurn:[],
				TypeArea: 2
			}
		},

		watch: {
			CbpCode(newValue, oldValue) {
				console.log('cambiooo ', newValue, oldValue)
			}
		},

		methods: {

			//Cargar Presentaciones
			loadData() {
				
				_servicePresentation
					.listPresentation("" ,this.$fun.getUserID())
					.then(r => {
						//if (r.data.length > 0) {
						console.log("Item de Presentation", r.data);
						this.itemsPresentation = r.data;
						//}
					});
				this.getTypeArea(this.TypeDestinyTk);
			},

			Initialize()
			{
				let USA = this.$fun.hasSpecialPermission('USA');
				let EUR = this.$fun.hasSpecialPermission('EUR');
				let KOR = this.$fun.hasSpecialPermission('KOR');
				
				if (USA)
				{
					this.linePacking = 3;
				}
				if (EUR)
				{
					this.linePacking = 2;
				}
				if (KOR)
				{
					this.linePacking = 9;
				}
				
			},

			input(value)
			{
				
				//console.log('dato leido ', value);
				/* if(value.length > 16)
				{
					console.log('dato enviado');
				} */
				//let da = value.split(this.separator, 1)[0];
				//this.aux = da.slice(1, da.length)
				//this.CbpCode = aux;
				//console.log(this.CbpCode);
				//console.log(this.aux);
			},

			keyupEnter() {

				if(this.ObjPresentation === null ){
				console.log("Presentation1",this.ObjPresentation)
				this.$fun.alert("Seleccione una Presentación", "warning");
				return;
				}

				if(this.itemTurn.length == 0){
					this.$fun.alert("Error, No hay turno activo para este destino", "warning")
					return;
				}

				let TagCount= {
					CbpID : 0,
					TypeDestiny: this.TypeDestinyTk,
					TypeLine : this.TypeLineProcess,
					TypeCaliber : this.TypeCaliber,
					TypeBelt : this.TypeFaja,
					PrcID : this.ObjPresentation,
					CbpCode: this.CbpCode,
					TrnID: this.itemTurn[0].TrnID,
					SecStatus : 1 ,
					TypeArea: this.TypeArea,
					UsrCreateID: this.$fun.getUserID()
				}
				//this.CbpCode = this.CbpCode + ','+this.linePacking;
				this.CbpCode = '';
				console.log("Presentation1",TagCount)
				//if(this.CbpCode != ""){
 
				_sPrfCountingBoxesPresentationService.save(TagCount, this.$fun.getUserID())
					.then((resp) => {
						
						if(resp.status == 200){
							
							this.$refs.stext.focus();
						}
						console.log("Repta",resp);
					});
					this.CbpCode = ''
					console.log('click enter ', this.CbpCode);
				//}/*
			},
			changeDes(event){
				console.log("Prueba")
			},
			inputPresentation(event){
				console.log("Prueba", event);
				this.ObjPresentation = event;
			},

			TurnActive(){
            console.log("TypeArea:11 ", this.TypeArea);
            _sTurnService.turnActivePrf({TypeArea : this.TypeArea, TypeShip: 1}, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        console.log("Turnos",r.data);
                        this.itemTurn = r.data;
						if(this.itemTurn.length > 0)
                        this.processInitial.TrnID= this.itemTurn[0].TrnID;                               
                    }
                });      
            },

			getTypeArea(event){
               // console.log("destino",this.filter.TypeDestiny);
                console.log("destino22",event);
				this.TypeArea = 0;
                switch(event){
                    case 1:
                        this.TypeArea = 2;
                        break;
                    case 2:
                        this.TypeArea = 3;
                        break;
                    case 9:
                        this.TypeArea = 10;
                        break;
                }                                
                console.log("TypeArea222",this.TypeArea);
                this.itemTurn = [];                 
                this.TurnActive();
            }

		},

		created () {
			_service.codigoQR(this.$fun.getUserID())
			.then(resp => {
				if(resp.status == 200){
					if(resp.data)
					{
						this.separator = resp.data[0].PrmValue;
					}
				}
			});

			this.Initialize();
			this.loadData();
		},

		mounted () {
			this.$refs.stext.focus();
		},
		
	}
</script>
<template>
  <v-app class="scroll">
    <div v-if="dialog">
      <v-dialog
        class="elevation-0"
        v-model="dialog"
        width="100%"
        transition="dialog-bottom-transition"
      >
        <requirement-edit
          @close="dialog = false"
          v-if="this.htmlComponent == 'requirement-edit'"
          :value="this.DocEntry"
        ></requirement-edit>
        <order-purchase-edit
          @onClose="dialog = false"
          v-if="this.htmlComponent == 'orderpurchase-edit'"
          :value="this.DocEntry"
          :typeOrder="1"
          :order="{}"
        ></order-purchase-edit>
      </v-dialog>
    </div>
    <div v-if="dialogNotification">
      <v-dialog
        class="elevation-0"
        v-model="dialogNotification"
        width="80%"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <s-notification />
      </v-dialog>
    </div>
    <v-navigation-drawer
      class="scroll"
      app
      clipped
      v-model="drawer"
      fixed
      mobile-breakpoint=""
      absolute
      width="320"
      bottom
      :temporary="$vuetify.breakpoint.xs"
      :color="bck_navigation"
      hide-overlay
      :dark="darkMode"
      style="display: flex; flex-direction: column; justify-content: space-between; height: 100vh;" 
    >
      <v-treeview
        class="scroll"
        dense
        @update:active="updateMenu"
        @update:open="openMenu($event)"
        :items="userMenu"
        transition
        item-text="OptName"
        item-id="OptID"
        item-key="OptCode"
        item-children="SecOptionChild"
        open-on-click
        activatable
        off-icon="ds"
        on-icon=""
        :color="opt_nav"
        :style="opt_stylenav"
      >
        <template v-slot:prepend="{ item }">
          <v-icon x-small v-text="item.OptIcon"></v-icon>
        </template>
      </v-treeview>
       <!-- Slider de imágenes -->
      <div>
      <div>
       <v-img  
                  lazy-src="@/assets/sliderImagen1.jpg"
                  src="@/assets/sliderImagen1.jpg"
                  max-width="50vh"
                  max-height="62h"
                  class="d-flex justify-center align-center"
                  style="margin-bottom: 0; margin-top: 10px; border: 5px solid #FFFFFF; width: 100%; height: auto;" 
                   @click="dialogImg=true"
                /> 
       </div>
       </div>
        <!-- Fin Slider de imágenes -->
    </v-navigation-drawer>
    <!-- -->
    <v-app-bar
      app
      clipped-left
      dense
      tile
      flat
      height="35"
      :style="bck_toolbar"
      class="elevation-0"
      :dark="darkMode"
    >
      <v-app-bar-nav-icon
        small
        @click.stop="drawer = !drawer"
        :style="icon_tlb"
      />
        
      <v-toolbar-title :class="class_styleCompany"
        >
        <v-container
          class="gorro"
        ></v-container>
        SUNSHINE EXPORT  
        <!-- 🎅🎄⛄ -->
        <!-- <v-chip
          x-small
          color="error"
          v-if="this.baseUrl != 'http://172.16.1.9:8184/api' || this.baseUrl != 'https://190.116.178.179:8189/api'"
        >
          {{
             this.baseUrl != "http://172.16.1.9:8184/api" || this.baseUrl != 'https://190.116.178.179:8189/api' ? "ENTORNO PRUEBAS" : "" 
          }}
        </v-chip> -->
          
          <v-chip
            x-small
            color="success"
          >
            <marquee direction="left">{{ 
                this.personNight
                
            }} </marquee>
            
          </v-chip>
          
      </v-toolbar-title>
      <!-- <v-col>
        <v-img contain src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/2708147a-9e73-4d2e-a640-764f7a1be2ec/d5mxuaf-44e08c01-bd7f-4920-91ce-4c3d8bb3d045.png/v1/fill/w_500,h_383,strp/gorro_de_navidad_png_by_chequealostutoriales_d5mxuaf-fullview.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MzgzIiwicGF0aCI6IlwvZlwvMjcwODE0N2EtOWU3My00ZDJlLWE2NDAtNzY0ZjdhMWJlMmVjXC9kNW14dWFmLTQ0ZTA4YzAxLWJkN2YtNDkyMC05MWNlLTRjM2Q4YmIzZDA0NS5wbmciLCJ3aWR0aCI6Ijw9NTAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.KWNgxPGOFOwouMuNnzGCA7TFsKsb0tGphGxNdNYf_WU" height="20"></v-img>
      </v-col> -->
      <v-spacer></v-spacer>
      
    <!-- </v-row> -->
      <v-menu
        bottom
        offset-y
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" small @click="clickNotification()">
            <v-badge
              v-if="itemsAlertPending.length > 0"
              dot
              color="error error-4"
              bottom
              left
              offset-x="5"
              offset-y="0"
            >
            </v-badge>
            <v-icon :style="icon_tlb">mdi-bell-ring-outline</v-icon>
          </v-btn>
        </template>
        <v-card style="overflow: hidden">
          <v-list class="mx-auto">
            <v-toolbar height="25" elevation="0">
              <v-toolbar-title>Notificaciones</v-toolbar-title></v-toolbar
            >
            <v-row
              ><v-col lg="6" style="margin-bottom: 12px">
                <h5
                  style="
                    color: var(--v-error-base);
                    padding-left: 16px;
                    margin-bottom: 0px;
                  "
                >
                  {{ itemsAlertPending.length }} Nuevas Notificaciones
                </h5> </v-col
              ><v-spacer></v-spacer>
              <v-col lg="3" style="">
                <!-- <a @click="dialogNotification = true">Ver todas</a>-->
              </v-col>
            </v-row>

            <div
              style="height: 500px; overflow: auto"
              class="scroll"
              @scroll="handleScroll"
            >
              <v-list-item-group
                :value="selected"
                multiple
                active-class="primary--text"
              >
                <template v-for="(item, index) in itemsAlert">
                  <v-list-item
                    dense
                    exact
                    @click="showAlert(item)"
                    :key="item.AleID"
                  >
                    <v-list-item-avatar style="overflow: unset !important">
                      <v-avatar
                        style="overflow: unset !important"
                        :color="item.AleColor"
                      >
                        <span class="white--text">{{ item.TrsAbbrTable }}</span>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content style="padding: 0px">
                      <p style="margin-bottom: 6px">
                        <b>{{ item.AleName }}</b>
                      </p>
                      <h5 class="text--primary" style="margin-bottom: 0px">
                        <span>{{ item.AleMessage }}</span>
                      </h5>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="index" :inset="true"></v-divider>
                </template>
              </v-list-item-group>
            </div>
          </v-list>
        </v-card>
      </v-menu>

      <v-btn text small :class="class_styleCompany">
        {{ usrName }}
      </v-btn>
      <v-menu
        bottom
        offset-y
        :close-on-content-click="false"
        v-model="configurationUser"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" small>
            <v-icon x-small :style="icon_tlb">fas fa-cogs</v-icon>
          </v-btn>
        </template>
        <s-configuration
          :activate="configurationUser"
          :configUser="configUser"
          :user="user"
          :personImage="personImage"
          @getBackground="getBackground($event)"
          @getToolbar="getToolbar($event)"
          @getNavigation="getNavigation($event)"
          @getReset="getReset($event)"
          @getLogout="logout($event)"
        />
      </v-menu>
    </v-app-bar>

    <v-main :class="bck_main">
      <v-container fluid>
        <s-breadcrumb />
        <router-view />

        <!--  <modal-time-session @exit="exit()" v-if="isIdle == true" />-->
      </v-container>
    </v-main>
     <!-- <div class="trineo-container">
      <img
        src="@/assets/trineo.png" 
        alt="Trineo"
        class="trineo-img"
      />
    </div>  -->
    <v-footer app padless absolute dark>


      <v-container fluid>
        <v-row>
          <v-col class="py-1 body-2" cols="4">
            2024 — Equipo de desarrollo TI — Todos los derechos reservados.
          </v-col>
          <v-col class="py-1 body-2" cols="4" style="text-align: center;">
            <!-- ⛄ ¡Que pases unas felices fiestas! 🎄 -->
          </v-col>
          <v-col class="py-1 text-right body-2" cols="4">
            <div v-if="userSAP != null">[SAP] {{ userSAP.UssUserCode }}</div>
            <div v-else>[SAP] Sin Usuario asociado</div>
            <!-- 🦌🛷🤶 -->
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-dialog v-model="dialogImg" max-width="60vh" >
      <v-card>
        <v-img
          src="@/assets/sliderImagen1.jpg"
          class="white--text align-center"
          max-height="100%"
          max-width="100%"
        />
        <v-btn icon @click="dialogImg=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-app>
  

</template>

<script>
import _Information from "@/services/InformationTechnology/InventoryService";
import SBreadcrumb from "@/components/Layout/SBreadcrumb.vue";
import sConfiguration from "@/views/Configurations/ConfigurationTool.vue";
import sNotification from "@/views/Security/Notification.vue";
import ModalTimeSession from "@/components/Layout/ModalTimeSession";
import RequirementEdit from "@/views/Logistics/Requirement/RequirementEdit.vue";
import _sAuth from "@/services/AuthService";
import _sTransaction from "@/services/Security/AlertService";
import _sHelper from "@/services/HelperService";
import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit";
import _sGenParamService 		from "@/services/General/ParameterService";

import _sWorkerAllowance from "@/services/HumanResource/WorkerAllowanceService";

export default {
  components: {
    SBreadcrumb,
    ModalTimeSession,
    sConfiguration,
    RequirementEdit,
    sNotification,
    OrderPurchaseEdit,
  },
  data() {
    return {
      indexAlert: 0,
      lengthItemsAlert: 20,
      mini: true,
      dialogNotification: false,
      itemsAlert: [],
      itemsAlertPending: [],
      dialog: false,
      htmlComponent: "",
      DocEntry: 0,
      selected: [],
      usrID: this.$fun.getUserID(),
      timeToken: null,
      drawer: true,
      time: 10000,
      timeInactiveShow: 0,
      usrName: "",
      personImage: null,
      user: {},
      userMenu: [],
      managerSAP: false,
      userSAP: {},
      configurationUser: false,
      configurationTOOL: {},
      dialogImg:false,
      isNavigation: false,
      isToolbar: false,
      isMode: false,
      bck_navigation: "",
      icon_nav: "",
      opt_nav: "",
      opt_stylenav: "",
      bck_toolbar: "",
      class_styleCompany: "style-default",
      icon_tlb: "",
      bck_main: "",
      darkMode: false,
      configUser: {},
      configDefault: {
        bck_navDefault: "#E0E0E0",
        icon_navDefault: "black",
        opt_navDefault: "primary darken-1",
        opt_stylenavDefault: "black",
        bck_toolbarDefault: "#E0E0E0",
        class_styleCompanyDefault: "style-default",
        bck_tlbDefault: "#E0E0E0",
        icon_tlbDefault: "black",
        bck_Default: "background-body",
      },
      stylemodedark: "rgb(53, 54, 58)",
      baseUrl: "",
      personNight: ""
    };
  },
  created() {
    this.usrName = localStorage.getItem("UsrName");
    this.initialize();
    this.configurateActivity();
    this.inizializeAlerts();
    this.drawer = !this.$vuetify.breakpoint.xs;
    // this.birthday();
  },
  methods: {
    //samir 
    birthday(){
      _sWorkerAllowance.birthday({}, this.$fun.getUserID()).then((r) => {
        
      })
    },
    
    showAlert(al) {
      _sTransaction.byAlert(al, this.$fun.getUserID());
      this.DocEntry = al.DocEntry;
      this.htmlComponent = al.TrsComponentName;
      this.selected = [];
      this.itemsAlert.find((x) => x.AleID == al.AleID).AleStatus = 2;
      for (let i = 0; i < this.itemsAlert.length; i++)
        if (
          this.itemsAlert[i].AleStatus == 3 ||
          this.itemsAlert[i].AleStatus == 1
        )
          this.selected.push(i);
      this.itemsAlertPending =
        this.itemsAlert != null
          ? this.itemsAlert.filter((x) => x.AleStatus == 1)
          : [];

      this.dialog = true;
    },

    initialize() {
      _sAuth.initialize(this.usrID).then((response) => {
        this.userMenu = response.data.UserMenu;
        localStorage.setItem("UserMenu", JSON.stringify(response.data.UserMenu))
 
        this.user = response.data.User;
        this.userSAP = response.data.UserSAP;
        this.configUser = response.data.UserConfig;
        localStorage.setItem("UserInfo", JSON.stringify(response.data.User));
        localStorage.setItem(
          "UserSAPInfo",
          JSON.stringify(response.data.UserSAP || {})
        );
        this.$nextTick().then(() => {
          require("signalr");

          //AGREGA SCRIPT DE HUBS A LA CABERA DE LA APLICACION
          let labelScript = document.createElement("script");
          labelScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
          document.head.appendChild(labelScript);
          ////EVENTO CUANDO CARGA EL SCRIP DE SIGNALR
          labelScript.addEventListener("load", () => {
            $.connection.hub.url = this.$const.URL + "/signalr";

            var chat = $.connection.notification;

            chat.client.sendMessageGroup = (not, group) => {
              let dr = {
                length: this.lengthItemsAlert,
                start: 0,
                filter: { UsrID: this.$fun.getUserID() },
              };
              _sTransaction.pagination(dr, this.$fun.getUserID()).then((r) => {
                this.itemsAlert.push(...r.data.data);
                if (this.itemsAlert.length > 0) {
                  this.itemsAlertPending.push(this.itemsAlert[0]);
                  his.itemsAlert[0].AleStatus = 3;
                }
              });
              this.$push.create(not.Name, {
                body: not.Body,
                icon: "/favicon.ico",
                link: "www.google.com",
                timeout: 15000,
                onClick: function () {
                  window.focus();
                  this.close();
                },
              });
            };

            $.connection.hub.start({ jsonp: true }).done((e) => {
              if (this.$fun.getUserInfo().CtrContract)
                chat.invoke(
                  "addToGroup",
                  this.$fun.getUserInfo().CtrContract.PstID
                );
            });
          });
        });
        if (this.user.PrsDocumentNumber != null)
          this.getImage(this.user.PrsDocumentNumber);

        if (this.configUser != null) this.GetConfigurationUser(this.configUser);
        else this.initializeConfiguration();
      });
    },
    clickNotification() {
      _sTransaction.check(this.$fun.getUserID()).then(() => {
        this.itemsAlertPending = [];
      });
    },
    getImage(document) {
      _sHelper.getPhotoWorker(document, this.$fun.getUserID()).then((rsp) => {
        const blob = new Blob([rsp.data], {
          type: "image/jpg",
        });

        let image = blob;
        var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
          this.personImage = reader.result;
        };
      });
    },
    configurateActivity() {
      this.timeToken = setInterval(() => {
        if (this.usrID > 0) {
          let time = localStorage.getItem("TimeTokenCount") - 3000;
          localStorage.setItem("TimeTokenCount", time);
          if (localStorage.getItem("TimeTokenCount") < 1) {
            if (localStorage.getItem("token") != null) { 
               
              _sAuth.refreshToken(this.usrID).then((r) => {
                localStorage.setItem(
                  "TimeTokenCount",
                  localStorage.getItem("TimeToken")
                );
                localStorage.setItem("token", "");
                localStorage.setItem("token", r.data.token);
              }, (re) => { 
                localStorage.removeItem("token")
              });
            }
          } // else clearInterval(this.timeToken);
        }
      }, 3000);

      var t;

      let logoutTimeInactive = () => {
        localStorage.setItem("isIdle", true);
        clearInterval(t);
      };

      let resetTimer = () => {
        clearInterval(t);
        localStorage.setItem("isIdle", false);
        this.timeInactiveShow = 0;
        t = setInterval(() => {
          if (this.timeInactiveShow < localStorage.getItem("TimeToken")) {
            this.timeInactiveShow = this.timeInactiveShow + 1000;
          } else {
            logoutTimeInactive();
          }
        }, 1000);
      };

      resetTimer();
      document.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onmousedown = resetTimer;
      document.ontouchstart = resetTimer;
      document.onclick = resetTimer;
      document.onscroll = resetTimer;
    },
    exit() {
      clearInterval(this.timeToken);
    },

    openMenu(menu)
    {
      localStorage.setItem("NameMenuMain", menu[0])
    },

    updateMenu(menusActive) {
      if (menusActive.length > 0) {
        menusActive = [menusActive[0]];
        let route = this.$router.options.routes[1].children.find(
          (x) => x.name == menusActive[0]
        );
        if (route != undefined) this.$router.push(route.path);
        else {
          route = this.$router.options.routes.find(
            (x) => x.name == menusActive[0]
          );
          if (route != undefined) this.$router.push(route.path);
        }
      }
    },
    logout() {
      clearInterval(this.timeToken);
      localStorage.setItem("isIdle", false);
      document.onload = null;
      document.onmousemove = null;
      document.onmousedown = null;
      document.ontouchstart = null;
      document.onclick = null;
      document.onscroll = null;
      localStorage.clear();
      localStorage.removeItem("token");
      this.$router.push("/login");
    },

    setMode(mode) {
      //Dark
      if (mode) {
        this.bck_toolbar = "background:" + this.stylemodedark;
        this.class_styleCompany = "style-toolbar";
        this.icon_tlb = "color:white";
        this.bck_navigation = this.stylemodedark;
        this.icon_nav = "style-toolbar";
        this.opt_nav = "white";
        this.opt_stylenav = this.icon_tlb;
        this.darkMode = true;
      } else this.initializeConfiguration();

      //Ligth
    },
    getBackground(value) {
      if (value === true) this.bck_main = "background-dark";
      else this.bck_main = "background-light";
      this.setMode(value);
    },
    getToolbar(value) {
      if (value === this.configDefault.bck_toolbarDefault) {
        this.isToolbar = false;
        this.initializeConfiguration();
      } else {
        this.isToolbar = true;
        this.bck_toolbar = "background:" + value;
        this.class_styleCompany = "style-toolbar";
        this.icon_tlb = "color:white";
      }
    },
    getNavigation(value) {
      if (value === this.configDefault.bck_navDefault) {
        this.isNavigation = false;
        this.initializeConfiguration();
      } else {
        this.isNavigation = true;
        this.bck_navigation = value;
        this.icon_nav = "color:white";
        this.opt_nav = "white";
        this.opt_stylenav = "color:white";
        this.darkMode = true;
      }
    },
    getReset(value) {
      this.isNavigation = !value;
      this.isMode = !value;
      this.isToolbar = !value;
      this.initializeConfiguration();
    },

    initializeConfiguration() {
      //configuracion de inicio
      if (!this.isMode) {
        this.bck_main = "background-light";
        this.darkMode = false;
      }

      // toolbar
      if (!this.isToolbar) {
        this.bck_toolbar =
          "background:" + this.configDefault.bck_toolbarDefault;
        this.class_styleCompany = this.configDefault.class_styleCompanyDefault;
        this.icon_tlb = "color:" + this.configDefault.icon_tlbDefault;
      }

      // navigation
      if (!this.isNavigation) {
        this.bck_navigation = this.configDefault.bck_navDefault;
        this.icon_nav = "color:" + this.configDefault.icon_navDefault;
        this.opt_nav = this.configDefault.opt_navDefault;
        this.opt_stylenav = "color:" + this.configDefault.opt_stylenavDefault;
      }
    },

    GetConfigurationUser(value) {
      if (this.configUser != "") {
        if (
          this.configUser.CfgToolbar != null &&
          this.configUser.CfgToolbar != ""
        ) {
          this.isToolbar = true;
          this.getToolbar(this.configUser.CfgToolbar);
        } else this.isToolbar = false;

        if (this.configUser.CfgTheme != null) {
          this.bck_main =
            this.configUser.CfgTheme == 1
              ? "background-dark"
              : "background-light";
          this.isMode = true;
          this.darkMode = this.configUser.CfgTheme == 1;
        } else this.isMode = false;

        if (
          this.configUser.CfgNavigation != null &&
          this.configUser.CfgNavigation != ""
        ) {
          this.isNavigation = true;
          this.getNavigation(this.configUser.CfgNavigation);
          
        } else this.isNavigation = false;
      }
    },

    inizializeAlerts() {
      this.itemsAlert = this.$fun.getSecurity().Alerts;
      for (let i = 0; i < this.itemsAlert.length; i++)
        if (
          this.itemsAlert[i].AleStatus == 3 ||
          this.itemsAlert[i].AleStatus == 1
        )
          this.selected.push(i);
      this.itemsAlertPending =
        this.itemsAlert != null
          ? this.itemsAlert.filter((x) => x.AleStatus == 1)
          : [];
    },
    handleScroll: function (el) {
      if (
        el.srcElement.offsetHeight + el.srcElement.scrollTop >=
        el.srcElement.scrollHeight
      ) {
        let dr = {
          length: this.lengthItemsAlert,
          start: this.indexAlert * this.lengthItemsAlert,
          filter: { UsrID: this.$fun.getUserID() },
        };

        _sTransaction.pagination(dr, this.$fun.getUserID()).then((r) => {
          this.itemsAlert.push(...r.data.data);
        });
      }
    },
  },
  watch: {
    $route(to, from) {
      this.inizializeAlerts();
    },
  },
  computed: {
    timeShow() {
      return (
        parseInt(
          Math.round(
            (localStorage.getItem("TimeToken") - this.timeInactiveShow) / 60000
          )
        ).toString() + " min"
      );
    },
    isIdle() {
      return localStorage.isIdle;
    },
  },

  mounted(){
    this.baseUrl = this.$const.BASEURL;

    _sGenParamService
					.search(
						{ PrmName: "responsableturnonoche" },
						this.$fun.getUserID(),
					)
					.then(resp => {
            if(resp.data != null)
            {
              this.personNight = resp.data.PrmValue;  
              // this.personNight = process.env.VUE_APP_BASE_URL_VUE;
            }
					});
  }
};
</script>
<style scoped>
.background-light {
  background: #eceff1 !important;
}
.background-dark {
  background: rgb(53, 54, 58) !important;
}
.style-toolbar {
  color: white;
  font-size: 12px !important;
}
.style-font {
  color: white;
  font-size: 12px !important;
}
.style-default {
  font-weight: 400 !important;
  font-size: 12px !important;
}

.gorro{
  width: 15px;
  height: 15px; /* You must set a specified height */
  margin-right: auto;
  position: absolute;
  display: inline;
  margin-top: -10px;
  margin-left: -12px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  transform: scaleX(-1) rotate(10deg);
 
  
}
.image-container {
  position: absolute;
  bottom: 0;  /* Imagen en la parte inferior si no hay scroll */
  left: 0;
  width: 100%;
  max-width: 280px;
  max-height: 400px;
}

.v-navigation-drawer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
}

.scroll {
  overflow-y: auto; /* Permitir scroll si el contenido es largo */
}
.v-treeview {
    min-height: 46vh;
  }

  @media (max-height: 800px) {
    .v-treeview {
      min-height: 24vh; /* Adjust to smaller value */
    }
  }
  
   .trineo-container {
  position: absolute;
  bottom: 0px; 
  left: 0;
  z-index: 5; 
  width: 100%;
  height: 50px; 
}

.trineo-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  animation: move-trineo 15s linear infinite;
}

@keyframes move-trineo {
  0% {
    transform: translateX(0); 
  }
  100% {
    transform: translateX(100vw);
  }
} 
</style>

<template>    
    <div>    
        <v-card>
            <v-card-title>Envio de Kg a Linea</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col lg="3" col="3">
                        <s-select 
                            label ="Periodo de Produccion"
                            v-model="PchID"
                            :items="itemPeriodo"
                            item-value="PchID"
                            item-text="PchPeriodo"
                            ref="txtPchPeriodo"
                            clearable
                            @change="changePeriodo"
                        >
                        </s-select>
                    </v-col>
                    <v-col lg="4" col="4">
                        <s-select 
                            label="N° de Lote "
                            v-model="newRcfID"
                            return-object
                            :items="itemRcfid"
                            item-value="DtbID"
                            item-text="RcfDate"
                            ref="txtnewRcfID"
                            clearable
                            autocomplete
                            full
                            :slotTmp="true"
                        >
                        <template v-slot:item="data">
									{{ data.item.RcfDate }}
									<v-chip
										x-small
										style="margin-left: 5px"
										:color="data.item.LpcQuantityJaba > 0?'error':'info'"
										>{{
											data.item.LpcQuantityJaba > 0
												? "Cajas Transf: " + data.item.LpcQuantityJaba
												: "S/C Transf"
										}}</v-chip
									>
								</template>
                        </s-select>
                    </v-col>
                    <v-col lg="2" col="2">
                        <s-text 
                            label="Cajas"
                            number
                            v-model="QuantityJaba"
                            ref="txtQuantityJaba"
                            :max="this.newRcfID.DlpQuantity - this.newRcfID.LpcQuantityJaba"
                        >
                            
                        </s-text>
                    </v-col>
                    <v-col lg="2" col="2">
                        <s-text
                            label="Kilogramos"
                            decimal
                            v-model="QuantityKg"
                            ref="txtQuantityKg"
                        >
                            
                        </s-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-radio-group
                            row
                            hide-details
                            v-model="lineSelected">
                            <v-radio
                                v-for="line in linesProduction"
                                :key="line.ClpID"
                                :label="line.LncDescription"
                                :value="line.ClpID">

                            </v-radio>
                        </v-radio-group>
                    </v-col>
                
                    <v-col  class="pl-5 pt-6" >
                        <v-btn class="s-col-form"
                       
                            color="success"
                            text 
                            outlined
                            @click="SaveKgLine()">
                            Guardar

                        </v-btn>
                    </v-col>
                </v-row>  
                         
            </v-card-text>
            <v-card-actions>
                
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                        dense itemsTable
                        :headers="headersTable"
                        :items="itemsTable"
                        hide-default-footer
                        :items-per-page="-1"
                        disable-sort >
                            <template v-slot:item.Actions="{item}">
                                <v-btn
                                x-small
                                fab
                                rounded
                                color="error" 
                                @click="DeleteKg(item)"
                                v-if="item.SecStatus != 2"
                                >
                                <v-icon style="font-size:10px !important">
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>    
                
            </v-card-actions>
        </v-card>           
    </div>
</template>

<script>
    import TablesLine from '@/components/FrozenProduction/Aconditionado/TablesLine.vue';
    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"
    import _FrzCtnSendKgLinesProcess from "@/services/FrozenProduction/FrzCtnSendKgLinesProcess.js"

    import _sProcessSampling from '@/services/FrozenProduction/ProcessSamplingService';
import ConfigTreatment from '../WashDisinfect/ConfigTreatment.vue';


    export default {
        components: {TablesLine},
        props:{
            ClpID: {
                type: Number,
                required: true,
            },
        },
        data(){
            return {
                QuantityKg: 0,
                QuantityJaba:0,
                linesProduction: [],
                lineSelected: 0,
                filter:{
                    TypeCultive: 1,

                },
                headersTable: [
                    { text: "ID", value: "LpcID"},
                    { text: "PERIODO", value: "PchPeriodo"},
                    { text: "ID Lote", value: "DtbID"},
                    { text: "Linea Origen", value: "ClpIDOldName"},
                    { text: "Linea Destino", value: "ClpIDNewName"},
                    { text: "Total Kg.", value: "AverageWeightJabaN"},
                    { text: "Hora", value: "HourDate"},
                    // { text: "Estado", value: "SecStatus"},
                    { text: "", value: "Actions"},
                ],
                itemsTable: [],
                PchID:0,
                newRcfID:{},
                itemPeriodo:[],
                itemRcfid:[],
            }
        },
        methods:{

            getLinesProduction(){
                _sFrzCtnLinesProcessService.linesactive(  this.filter , this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						
						this.linesProduction = resp.data;
						
					}
				})
            },
            SaveKgLine(){
                if(this.PchID == ''){
                    this.$fun.alert("Seleccione periodo de producción","warning");
                    this.$refs.txtPchPeriodo.focus();
                    return
                }
                
                if(this.newRcfID.RcfID == undefined){
                    this.$fun.alert("Seleccione Lote de Produccion","warning");
                    this.$refs.txtnewRcfID.focus();
                    return
                }
                if(this.QuantityKg == ''){
                    this.$fun.alert("Ingrese cantidad de Kg","warning");
                    this.$refs.txtQuantityKg.focus();
                    return
                }
                if(this.QuantityJaba == ''){
                    this.$fun.alert("Ingrese cantidad de Cajas","warning");
                    this.$refs.txtQuantityJaba.focus();
                    return
                }
                if(this.QuantityJaba > (this.newRcfID.DlpQuantity - this.newRcfID.LpcQuantityJaba)){
                    this.$fun.alert("Cantidad de cajas incorrecta supera el limite","warning");
                    this.$refs.txtQuantityJaba.focus();
                    return
                }
                if(this.ClpID == this.lineSelected)
                {
                    this.$fun.alert("No se puede enviar a la misma linea", "info");
                    return;
                }
                if(this.lineSelected == 0 || this.lineSelected == undefined)
                {
                    this.$fun.alert("Debe seleccionar a que linea de enviaran los Kg", "info");
                    return;
                }
                let items = {
                    ClpID: this.ClpID,
                    AverageWeightJabaN : this.QuantityKg,
                    ClpIDNew: this.lineSelected,
                    UsrID: this.$fun.getUserID(),
                    PchID : this.PchID,
                    RcfID : this.newRcfID.RcfID,
                    LpcQuantityJaba: this.QuantityJaba,
                    DtbID : this.newRcfID.DtbID
                }
                
                // return

                this.$fun.alert("¿Desea guardar la informacion?", "question")
                .then( r => {
                    if(r.value)
                    {
                        _FrzCtnSendKgLinesProcess.save(items, this.$fun.getUserID())
                        .then( resp => {
                            if(resp.status == 200)
                            {
                                this.$fun.alert("Se registro Correctamente", "success");
                                this.getSendKgLinesProduction();
                               // this.listperiodoActivo();
                                this.resetForm();
                            }
                        })
                    }
                });
                
            },

            getSendKgLinesProduction(){
                _FrzCtnSendKgLinesProcess.list({ ClpID: this.ClpID}, this.$fun.getUserID()) 
                .then( resp => {
                    if(resp.status == 200)
                    {
                        this.itemsTable = resp.data;                        
                    }
                })        
            },
            DeleteKg(item){
                
            
                this.$fun.alert("¿Desea eliminar?", "question")
                .then( r => {
                    if(r.value)
                    {
                        let items = {
                            LpcID : item.LpcID,
                            UsrUpdateID : this.$fun.getUserID(),
                        }
                        
                        _FrzCtnSendKgLinesProcess.update(items, this.$fun.getUserID())
                        .then( resp => {
                            if(resp.status == 200)
                            {
                                this.$fun.alert("Se registro Correctamente", "success");
                                this.getSendKgLinesProduction();
                               // this.listperiodoActivo();
                                this.resetForm();
                            }
                        })
                    }
                });
            },
            listperiodoActivo(){
                _sFrzCtnLinesProcessService.listPeriodoActivo({ ClpID: this.ClpID}, this.$fun.getUserID()) 
                .then( resp => {
                    if(resp.status == 200)
                    {
                        this.itemPeriodo = resp.data;    
                        //this.PchID=this.itemPeriodo[0].PchID;
                       // console.log("Periodoitme", this.itemPeriodo[0].PchID);                  
                    }
                })   
            },
            changePeriodo(){
                console.log("Periodoc", this.PchID); 
                this.LoteProduccion(); 
            },
            resetForm() {
                this.PchID = null;          // Reseteamos el periodo de producción
                this.newRcfID = {};         // Reseteamos el lote de producción
                this.QuantityKg = 0;        // Reseteamos la cantidad de kg
                this.QuantityJaba = 0;      // Reseteamos la cantidad de cajas
                this.lineSelected = 0;      // Reseteamos la selección de línea
                this.$refs.txtPchPeriodo.clearable = true; // Opcional: limpiar el campo de selección
                this.$refs.txtnewRcfID.clearable = true;   // Opcional: limpiar el campo de selección
            },
            LoteProduccion(){
                let filter = {
                    // DtbID: this.DtbID,
                    // TypeCultive: this.TypeCultive
                    ClpID : this.ClpID,//3319,
                    DtbID : this.DtbID

                }
                
                // return
                _sProcessSampling.lst(filter, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
  
                       //console.log("Periodo", this.PchID);   
                       if(this.PchID===null || this.PchID===0){
                       this.itemRcfid = r.data; 
                       }else{
                        this.itemRcfid= r.data.filter(item => item.PchID == this.PchID);  
                       }                                           
                    }
                });
            },
        },
        mounted()
        {
            this.getLinesProduction();
        },
        created(){
            this.getSendKgLinesProduction();
            this.listperiodoActivo();
            this.LoteProduccion();
        }
    }


</script>
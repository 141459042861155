<template>
  <div>
    <s-toolbar-crud
      :view="view" v-if='!noToolbar'
      :searchInput="searchInput"
      style="margin-bottom:3px;"
      ref="toolbar"
      class="mb-1"
      @load="loadEvent()"
      :load="load"
      :transfer="transfer"
      @transfer="transferEvent()"
      :enable="enable"
      @enable="enableEvent()"
      :saveList="saveList"
      :search="!noSearch"
      :formPermanent="formPermanent"
      :title="title"
      :doubleClick="doubleClick"
      @showFormEvent="showFormDefEvent($event)"
      @EditEvent="EditEvent()"
      @addEvent="AddEventClick()"
      @activePrd="activePrd()"
      :colorToolbar="colorToolbar"
      :noDark="noDark"
      @excel="reportExcel()"
      @restartEntity="restartEntity($event)"
      @refresh="refresh($event)"
      @save="saveEvent()"
      :save="save"
      :service="config.service"
      @searchEvent="searchEvent($event)"
      :entity="row"
      :show="showForm"
      :add="add"
      :edit="edit"
      :excel="excel" :off=off @off='$emit("off",row)'
      @edit="editEvent()"
      :pdf="pdf"
      @pdf="pdfEvent()"
      :remove="remove"
      @delete="deleteEvent($event)"
      :deleteNoFunction="deleteNoFunction"
      :addNoFunction="addNoFunction"
      @add="addEvent()"
      :restore="restore"
      @removed="deleteExecuted()"
      @approved="$emit('approved')"
      @disapproved="$emit('disapproved')"
      :approve="approve"
      :close="close"
      @close="$emit('close')"
      :print="print"
      @print="$emit('print')"
      @sync="$emit('sync')"
      :sync="sync"
      :btnsearch="btnsearch"
      @btnsearch="searchBtn()"
      :btnsearchKey="btnsearchKey"
      :loading="loading"
    >
      <template v-slot:options>
        <slot name="options"> </slot>
      </template>
    </s-toolbar-crud>

    <v-card elevation="0">

        
      <div v-if="!showForm">
         <slot style="z-index:-999;" name="filter"> </slot> 
      </div>

      <v-container v-else class="py-2 px-0 mb-2" >
        <v-row>
          <v-col >
            <slot :item="row"></slot>
          </v-col>
        </v-row> 

        <v-row v-if="formPermanent" class="px-3">
          <v-col cols="6" class="pb-2 pt-3">
            <v-btn small block text @click="btnAdd()">Nuevo </v-btn>
          </v-col>
          <v-col cols="6" class="pb-2 pt-3">
            <v-btn small block color="primary" @click="btnSave()">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div elevation="0" rounded>
        <v-data-table 
          @toggle-select-all="toggleSelectAll"
          :show-select="!singleRow"
          fixed-header=""
          :disable-sort="!sortable"
          class="elevation-0 scroll"
          @click:row="rowClick"
          @item-selected="selectedCheckSlot($event)"
          @dblclick:row="doubleClickFunc"
          :height="$vuetify.breakpoint.xs?'auto': height"
          :hide-default-footer="noFooter"
          :headers="config.headers"
          selectable-key="isSelectable"
          v-model="selected"
          :items="desserts"
          :item-key="propID"
          :options.sync="options"
          dense
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            itemsPerPageOptions: itemsPerPage,
          }"
          :server-items-length="total"
          :loading="loading"
          loading-text="Cargando"
          no-data-text="Sin Datos"
          :single-expand="true"
          :expanded.sync="expanded"
          :mobile-breakpoint="mobile"
          :group-by="groupBy"
          :show-group-by="showGroupBy"
          
          
        >
          <template v-slot:expanded-item="{ headers, item }" v-if="expandDetail">
            <td :colspan="headers.length" style="margin:0px;padding:0px;">
              <slot name="expanded" :item="item"> </slot>
            </td>
          </template>
          <!-- SLOT PROPIEDADES-->
          <template v-for="p in getProps()" v-slot:[getNameSlot(p)]="{ item }">
            <slot :name="p" :row="item">{{ formatItem(item[p], config.model[p]) }} </slot>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import TruckFlowVue from '../../views/FreshProduction/TruckFlow/TruckFlow.vue';
export default {
  name: "SCrud",
  props: {
    mobile: {type: Number, default: 600},
    approve: { type: Boolean, default: false },
    noFull: { type: Boolean, default: false },
    noEdit: { type: Boolean, default: false },
    save: { type: Boolean, default: false },
    view: { type: Boolean, default: false },
    //expandir detalles
    expandDetail: { type: Boolean, default: false },
    //RESTAURAR EL ITEM HACE STAAATUS IGUAL A 1
    restore: { type: Boolean, default: false },
    deleteNoFunction: { type: Boolean, default: false },
    searchInput: { type: Boolean, default: false },
    //desabilita por accion del boton agregar de abrir el formulario: emite evento add(item)
    addNoFunction: { type: Boolean, default: false },
    config: { type: Object, default: null },
    noToolbar: { type: Boolean, default: false },
    colorToolbar: { type: String, default: "primary" },
    height: { type: String, default: "320" },
    noSearch: { type: Boolean, default: false },
    noDark: { type: Boolean, default: false },
    noFooter: { type: Boolean, default: false },
    load: { type: Boolean, default: false },
    transfer: { type: Boolean, default: false },
    enable: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
    off: {
      type: Boolean,
      default: false,
    },
    filter: { type: Object, default: null },
    title: { type: String, default: "" },
    refreshTable: { type: Boolean, default: false },
    add: { type: Boolean, default: false },
    sync: { type: Boolean, default: false },
    btnsearch: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    remove: { type: Boolean, default: false },
    excel: { type: Boolean, default: false },
    pdf: { type: Boolean, default: false },
    print: { type: Boolean, default: false },
    parentID: { type: Number, default: 0 },
    //SI ES SELECCION UNICA
    singleRow: { type: Boolean, default: true },
    //GUARDA O EDITA MULTIPLES FILAS SELECCIONADAS..ACTUALMENTE SOLO ESTA HABILITADO PARA ELIMINAR REGISTROS MASIVOS
    saveList: { type: Boolean, default: false },
    sortable: { type: Boolean, default: false },
    noBorder: { type: Boolean, default: false },
    //FORMULARIO DE AGREGAR O EDITAR NO SE OCULTA
    formPermanent: { type: Boolean, default: false },
    //DESABILITA LA ACCION DE QUE EL DOBLECLICK EJECUTE LA ACCION DEL BOTON DE EDITAR
    noDoubleClick: { type: Boolean, default: false },
    //DESABILITA EL MENSAJE DE CONFIRMACIÓN AL AGREGAR O EDITAR O ELIMINAR
    noConfimationSave: { type: Boolean, default: false },
    groupBy: null,
    showGroupBy: {type: Boolean, default: false},
    rowDefault : {type: Boolean, default: true} //enviar false para que no se seleccione fila por defecto
  },
  data: () => ({
    itemsPerPage:[5, 10, 25, 50, -1],
    expanded: [],
    //peticion actual de paginacion del crud
    request: null,
    doubleClick: false,
    showForm: false,
    messageError: [],
    row: null,
    total: 0,
    loading: true,
    options: {},
    search: "",
    selected: [],
    desserts: [],
    parametersPagination: {
      filter: {},
      draw: 1,
      start: 0,
      length: 10,
      order: [{ dir: "asc", column: "" }],
      searchText: "",
    },
    btnsearchKey: false,
  }),
  watch: {
    refreshTable() { 
      this.selected = [];
      this.getDataFromApi().then((data) => {
        (this.desserts = data.items), (this.total = data.total);
        if (this.desserts.length > 0) {
          this.refresh(this.desserts[0]);
        }
      });
    },
    filter: {
      handler() {
        // console.log("filter", this.filter);
        // console.log("CRUD parametersPagination", this.parametersPagination.filter);
         
        if (this.btnsearch) {
          this.btnsearchKey = true;
          return;
        }
        this.options.page=1;
        this.selected = [];
        if (!this.noToolbar) this.$refs.toolbar.showForm(false, false);
        this.getDataFromApi().then((data) => {
          this.desserts = data.items;
          this.total = data.total;
          if (this.desserts.length > 0) this.refresh(this.desserts[0]);
        });
      },
      deep: true,
    },
    search() { 
      this.debouncedGetAnswer();
    },
    options: {
      handler() { 
        this.getDataFromApi().then((data) => {
          this.desserts = data.items;
          this.total = data.total;

          if (this.desserts.length > 0) this.refresh(this.desserts[0]);
        });
      },
    },
  },
  filters: {},
  methods: {
    searchBtn(){
      console.log("searchBtn", this.filter);
      this.btnsearchKey = false;
      this.options.page=1;
      this.selected = [];
      if (!this.noToolbar) this.$refs.toolbar.showForm(false, false);
      this.getDataFromApi().then((data) => {
        this.desserts = data.items;
        this.total = data.total;
        if (this.desserts.length > 0) this.refresh(this.desserts[0]);
      });
      this.$emit('btnsearch')
    },
    EditEvent(){
      this.$emit("EditEvent", this.selected);
    },
    AddEventClick(){
      this.$emit("addEvent");
    },

    activePrd(){
      this.$emit("activePrd");
    },
    formatItem(value, prop) {
      let val = value;
      if (prop == "date") val = this.$fun.formatDateView(val);
      if (prop == "time") val = this.$fun.formatTimeView(val);
      if (prop == "datetime") val = this.$fun.formatDateTimeView(val);
      return val;
    },
    selectedCheckSlot(obj) {
      let any = obj.item;
      if (
        this.selected.find((x) => {
          return x == any;
        }) != null
      )
        this.selected = this.selected.filter((x) => {
          return x != any;
        });
      else {
        this.selected.push(any);
        this.row = Object.assign({}, any);
      }
      this.$emit("itemSelected", any);
      this.$emit("rowSelected", this.selected);
    },
    loadEvent() {
      this.$emit("load");
    },

    transferEvent() {
      this.$emit("transfer");
    },
    enableEvent() {
      this.$emit("enable");
    },

    deleteExecuted() {
      this.$emit("removed");
    },
    deleteEvent(item) {
      if(this.singleRow)
      this.$emit("delete", item);
      else   this.$emit("delete", this.selected);
    },
    pdfEvent() {
      if (this.row[this.propID] != 0) this.$emit("pdf", this.row);
      else this.$emit("pdf", null);
    },
    getProps() {
      let r = [];
      for (var prop in this.config.model) r.push(prop);
      return r;
    },
    getNameSlot(p) {
      return "item." + p;
    },
    btnAdd() {
      this.$refs.toolbar.showForm(true, false);
      this.$emit("clearForm");
    },
    btnSave() {
      this.$refs.toolbar.saveEvent();
    },
    
    removed(obj) {
      if (this.saveList) obj = obj[0];
      this.refresh(obj);
      this.$emit("removed");
    },
    doubleClickFunc: function(mouse, row) {
      if (this.edit) this.doubleClick = !this.doubleClick;
      this.$emit("doubleClick", row.item);
      this.$emit("dblClick", row.item);
    },
    refresh(obj) {
      this.expanded = [];
      if (obj == null) {
        this.getDataFromApi().then((data) => {
          this.desserts = data.items;
          this.total = data.total;
          if (this.desserts.length > 0) {
            this.selected = this.formPermanent ? [] : [this.desserts[0]];
            this.row = Object.assign({}, this.desserts[0]);
            this.$emit("rowSelected", this.selected);
          }
        });
      } else if (this.propID != null) {
        let ob = this.desserts.find((x) => obj[this.propID] == x[this.propID]);
        /* console.log("ob",ob); */
        for (var prop in ob) {
          ob[prop] = obj[prop];
        }

        if(this.rowDefault){
          this.selected = this.formPermanent ? [] : [ob];
        }
          
        this.row = Object.assign({}, ob);
        this.$emit("rowSelected", this.selected);
      }
      if (this.formPermanent){ this.restart();}
    },
    searchEvent(search) {
      this.parametersPagination.searchText = search;
      if (this.btnsearch) {
        this.btnsearchKey = true;
        return;
      }
      
      this.options.page = 1;
      this.getDataFromApi().then((data) => {
        this.desserts = data.items;
        this.total = data.total;
        if (this.desserts.length > 0) this.refresh(this.desserts[0]);
      });
    },

    saveExecute() {
      return new Promise((resolve, reject) => {
      let objsave = this.row;
      objsave.UsrCreateID = objsave[this.propID] == null||objsave[this.propID] == 0 ? this.$fun.getUserID() : objsave.UsrCreateID;
      objsave.UsrUpdateID = objsave[this.propID] != null ? this.$fun.getUserID() : objsave.UsrCreateID;
      this.config.service.save(objsave, this.$fun.getUserID()).then((response) => {
           resolve(response);
        if (response.status == 200) {
          this.$emit("returnObject", response.data);
          let obj = Object.assign({}, this.row);
          if (obj[this.propID] == 0) {
            this.getDataFromApi().then((data) => {
             
          
              this.desserts = data.items;
              this.total = data.total;
              if (this.desserts.length > 0) this.refresh(this.desserts[0]);
              this.showForm = false || this.formPermanent;
              this.$fun.alert("Agregado Correctamente", "success");
            });
          } else { 
            this.refresh();
            this.showForm = false || this.formPermanent;
            this.$fun.alert("Actualizado Correctamente", "success");
          }
        }
        
      },
       (e)=>{
          reject(e);
      })
      })
      
    },
    saveEvent() {
      if (this.save) {
        this.$emit("save", this.row);
        return;
      }
     
      let obj = Object.assign({}, this.row);
      for (var prop in this.config.model) if (this.config.model[prop] == "time") obj[prop] = this.$moment(new Date()).format("L") + this.row[prop];

      this.row.save = () => {
           return new Promise((resolve, reject) => {

        if (this.noConfimationSave) this.saveExecute().then((r)=>{
resolve(r);
        },(e)=>{
reject(e)
        });
        else
          this.$fun.alert("Seguro de Guardar?", "question").then((val) => {
            if (val.value) this.saveExecute().then((r)=>{
resolve(r);
        },(e)=>{
reject(e)
        });
          });
           })
      };
      this.$emit("save", this.row);
    },
    reportExcel() {
      if(this.row)
      this.parametersPagination.filter[this.propID]=this.row.OrdID;
      this.config.service.excel(this.parametersPagination, this.$fun.getUserID(), this.row).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, this.title);
      });
    },
    rowClick: function(item, row) {
      if (this.singleRow) {
        /* if(this.selected.length==1){console.log(this.selected[0][this.propID]);
        if(this.selected[0][this.propID]==item[this.propID])this.expanded=[];
        else this.expanded=[item];}
        else this.expanded=[item];*/
        this.expanded = [item];
        this.selected = [item];
        this.row = Object.assign({}, item);
      } else {
        if (
          this.selected.find((x) => {
            return x == item;
          }) != null
        )
          this.selected = this.selected.filter((x) => {
            return x != item;
          });
        else {
          this.selected.push(item);
          this.row = Object.assign({}, item);
        }
      }
      this.$emit("rowSelected", this.selected);
      if(this.noEdit) this.btnAdd();
    },
    restartEntity(entity) {
      if (!this.formPermanent) {
        this.row = entity;
        this.selected = [entity];
        this.$emit("rowSelected", this.selected);
      }
    },
    cancelRequest() {
      this.request.cancel();
      this.request = null;
    }, 
    areObjectsEqual(objA, objB) {
      const keysA = Object.keys(objA);
      const keysB = Object.keys(objB);

      if (keysA.length !== keysB.length) return false;

      for (let key of keysA) {
          if (objA[key] !== objB[key]) return false;
      }

      return true;
    },
    getDataFromApi() {
      // console.log("CRUD parametersPagination compa", this.parametersPagination.filter == this.filter);
      // if ( this.areObjectsEqual( this.parametersPagination.filter, this.filter) ) {
      //   return new Promise((resolve, reject) => {
      //     reject({})
      //   });
      // }
      // console.log("CRUD filter", this.filter);
      // console.log("CRUD parametersPagination", this.parametersPagination.filter);
          
      //samir
      // this.filterTemp = this.filter;
      // console.log("this.filterTemp", this.filterTemp);

      if (this.request) this.cancelRequest();
      const axiosSource = axios.CancelToken.source();
      //  console.log("----------------------------------------------------");
      //  console.log(axiosSource.token);
      this.request = { cancel: axiosSource.cancel, msg: "Loading..." };

      this.loading = true;
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.parametersPagination.start = (this.options.page - 1) * this.parametersPagination.length;
        this.parametersPagination.filter = this.filter;
        this.parametersPagination.length = itemsPerPage;
        if (this.options.sortBy.length > 0)
          this.parametersPagination.order = [
            {
              column: this.options.sortBy[0],
              dir: this.options.sortDesc[0] ? "desc" : "asc",
            },
          ];
        this.config.service.pagination(this.parametersPagination, this.$fun.getUserID(), axiosSource).then((response) => {
          this.selected = [];
          this.$emit("rowSelected", this.selected);
      
          this.restart();
          let items = response.data.data;
          items.forEach((element) => {
            for (var prop in this.config.model)
              if (this.config.model[prop] == "date")
                element[prop] = element[prop] == null ? "" : this.$moment(element[prop]).format(this.$const.FormatDateDB);
              else if (this.config.model[prop] == "time")
                element[prop] = element[prop] == null ? "" : this.$moment(element[prop]).format(this.$const.FormatTimeDB);
              else if (this.config.model[prop] == "time2")
                element[prop] = element[prop] == null ? "" : this.$moment(element[prop]).format(this.$const.FormatTimeShortView);
              else if (this.config.model[prop] == "decimal")
                element[prop] = element[prop] == null ? "" :element[prop].toFixed(2) ;
              else if (this.config.model[prop] == "decimal4")
                element[prop] = element[prop] == null ? "" :element[prop].toFixed(4) ;
              else if (this.config.model[prop] == "datetime")
                element[prop] = element[prop] == null ? "" : this.$moment(element[prop]).format(this.$const.FormatDateTimeDB);
              else if (this.config.model[prop] == "currency")
                element[prop] = element[prop] == null ? "" :  new Intl.NumberFormat("es-PE", { 
                    minimumFractionDigits: 2, 
                    maximumFractionDigits: 2,
                    // style: "unit",
                    // unit: "KGM",
                }).format(element[prop]);
                
          });
          const total = response.data.recordsTotal;
          this.$emit("getDataRefresh", items);
           
          this.$emit("getFullData", response.data);
          if (!this.formPermanent) this.showForm = false;
          setTimeout(() => {
            this.loading = false;
            resolve({ items, total });
          }, 0);
        });
      });
    },
    toggleSelectAll({ items, value }) {
      if (value) this.selected = items;
      else this.selected = [];
      this.$emit("rowSelected", this.selected);
    },
    getAnswer() {},
    restartCreated() {
      this.selected = [];
      
      let r = {};
      for (var prop in this.config.model) {
        if (this.config.model[prop] == "date") {r[prop] =null;
       
        }
        else 
        if (this.config.model[prop] == "string") r[prop] = "";
        else if (this.config.model[prop] == "number") r[prop] = 0;
        else if (this.config.model[prop] == "ID") r[prop] = 0;
        else if (this.config.model[prop] == "time") r[prop] = "00:00";
        else if (this.config.model[prop] == "boolean") r[prop] = 0;
        else r[prop] = "";
        r.SecStatus = null;
      }

      this.row = Object.assign(r, {});
    },
    restart() {
      this.row=null;
      this.selected = [];
      
      let r = {};
      for (var prop in this.config.model) {
        if (this.config.model[prop] == "date") {r[prop] =null;
       
        }
        else 
        if (this.config.model[prop] == "string") r[prop] = "";
        else if (this.config.model[prop] == "number") r[prop] = 0;
        else if (this.config.model[prop] == "ID") r[prop] = 0;
        else if (this.config.model[prop] == "time") r[prop] = "00:00";
        else if (this.config.model[prop] == "boolean") r[prop] = 0;
        else r[prop] = "";
        r.SecStatus = null;
      }

      this.row = Object.assign(r, {});
    },
    addEvent() {
        //alert("nuevo")
      if (this.row[this.propID] != 0) this.$emit("add", this.row);
      else this.$emit("add", null);
    },
    editEvent() {
      //alert("sdfsdfds");
      if (this.row[this.propID] != 0) this.$emit("edit", this.row);
      else this.$emit("edit", null);
    },
    showFormDefEvent(options) {
      if (options.add) {
  
        this.restart();
        this.$emit("rowSelected", this.selected);
      }
      this.showForm = options.add || options.edit || this.formPermanent;
    },
  },
  computed: {
    propID() {
      for (var prop in this.config.model) if (this.config.model[prop] == "ID") return prop;
      return null;
    },
  },
  mounted() {
    this.parametersPagination.IsLevelAdmin = this.$fun.getSecurity().IsLevelAdmin ? 1 : 0;
    this.showForm = this.formPermanent;
  },
  created() { 
    if(this.noFull)
    this.itemsPerPage=[5, 10, 50, 200],
     this.restart();
    let filterHeaders = [];
    this.config.headers.forEach((element) => {
      if (element.IsLevelAdmin == true) {
        if (this.$fun.getSecurity().IsLevelAdmin) filterHeaders.push(element);
      } else filterHeaders.push(element);


      if(this.config.model[element.value]=='decimal')
        element.align='end';
    });
    this.config.headers = filterHeaders;
  
    this.debouncedGetAnswer = _.debounce(this.getAnswer, 500); 
  },
  destroyed() {},
};
</script>

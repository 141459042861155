<template>
    <div>
        <v-row>
            <v-col>
                <v-chip  color="#12a14b ">
                    P-{{PchPeriodo}}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-chip  color="#F7E07B">
                    {{ line.SAverageWeightJabaN + ' kg' }}
                   <!--<v-icon color="grey" v-if="!LstEndProcees.ClpID" small style="cursor:pointer; margin:0px 0px 0px 5px" @click="openDialog(line)"> fa-database  </v-icon>--> 
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-chip
                    style="margin-bottom: 5px"
                    small
                    :color="TypeAction == 1 ? 'red' : 'info'"
                >       
                    <v-icon v-if="LstEndProcees.ClpID" left x-small bordered style="cursor:pointer;" @click="finallyLineProcess()">
                        fas fa-hand-paper
                    </v-icon>

                    {{line.LncDescription}}

                    <v-icon v-if="!LstEndProcees.ClpID" small style="cursor:pointer; margin:0px 0px 0px 5px" @click="openDialog(line)"> fas fa-cog </v-icon>
                </v-chip>   
            </v-col>
        </v-row>    
        <div v-if="line.ClpState == 1" class="containerLine">
            <!-- ESTACIONES DE TRABAJO EN LA IZQUIERDA -->
            <div class="lineSide" v-if="!LstEndProcees.ClpID">
                <div v-for="(table, index) in itemsTablesOne" :key="index"> 
                    <c-tables-lines
                    @refreshlines="refreshlines()"
                    :table="table" 
                    :lineprocess="line">
                    </c-tables-lines>
                </div>
            </div>
            <!-- FIN ESTACIONES DE TRABAJO EN LA IZQUIERDA -->
            <!-- INICIO LINEAS -->
            <div v-if="!LstEndProcees.ClpID" class="grafLine">
                <v-btn
                    v-if="!LstEndProcees.ClpID"
                    class="mt-10"
                    fab 
                    x-small
                    @click="openDialogStop(line)" 
                    :color="TypeAction == 1 ? 'success' : 'warning'"
                >
                    <v-icon v-if="TypeAction == 2 || TypeAction == 0">far fa-pause-circle</v-icon>
                    <v-icon v-if="TypeAction == 1">far fa-play-circle</v-icon>
                </v-btn>
                <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                
            </div>
            <!-- FIN LINEAS -->
            <div 
                class="textLineVertical grafLineEndProcess"
                v-else="LstEndProcees.ClpID">
                Proceso Finalizado
            </div>
             <!-- INICIO ESTACIONES DE TRABAJO EN LA DERECHA -->
            <div class="lineSide" v-if="!LstEndProcees.ClpID">
                <div v-for="table in itemsTablesTwo"> 
                    <c-tables-lines 
                    @refreshlines="refreshlines()"
                    :table="table"
                    :lineprocess="line">
                    </c-tables-lines>
                </div>
                
            </div>
             <!-- FIN ESTACIONES DE TRABAJO EN LA DERECHA -->
        </div>


        <!-- v-else -->

        <div v-if="line.ClpState == 2 " class="containerLineLocked">
            <div class="lineSide">
                <div v-for="table in itemsTablesOne" > 
                    <c-tables-lines 
                    @refreshlines="refreshlines()"
                    :table="table"
                    :lineprocess="line" >
                    </c-tables-lines>
                </div>
            </div>

            <div class="grafLine">
                <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            </div>

            <div class="lineSide">
                <div v-for="table in itemsTablesTwo"> 
                    <c-tables-lines 
                    @refreshlines="refreshlines()"
                    :table="table"
                    :lineprocess="line">
                    </c-tables-lines>
                </div>
                
            </div>
        </div>
        <v-chip
            style="margin: 0px"
            small
            :color="line.CecColor"
        >
            {{line.CecDescription}}
        </v-chip>

        <!-- MODAL DE MESAS  -->
        <v-dialog
            v-model="modelDialog"
            persistent
            max-width="1100"
            height="650px"
            v-if="modelDialog"
        >

            <v-card>
                <s-toolbar     
                    label="DETALLES"
                    dark
                    close
                    @close="closeDialog()"
                    color="primary">                
                </s-toolbar>
                <v-card outlined class="pa-md-4 mx-lg-auto">
                    <v-row>
                        <v-col lg="12" cols="12" style="padding-bottom: 10px;">
                            <v-tabs v-model="ItemSelect">
                                <v-tab href="#ConfLine">Configuracion de Linea</v-tab>
                                <v-tab href="#ResponsablesLineas">Responsables de Lineas</v-tab>
                                <v-tab v-if="line.TypeCultive == 1" href="#SendKgLine">Enviar Kg </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="ItemSelect">
                                <v-tab-item :value="'ConfLine'">
                                    <c-conf-lines
                                        @refreshlines="refreshlines()"
                                        :lineaProcess = "line"
                                      
                                    >   
                                    </c-conf-lines>
                                </v-tab-item>
                                <v-tab-item :value="'ResponsablesLineas'">
                                    <c-responsable-line
                                        :ClpID="line.ClpID"
                                    >
                                    </c-responsable-line>
                                </v-tab-item>
                                <v-tab-item :value="'SendKgLine'">
                                    <send-kg-line
                                        :ClpID="line.ClpID"
                                    >
                                    </send-kg-line>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card>
        </v-dialog>
        <!-- FIN DE MODAL DE MESAS  -->

        <!-- MODAL PARA LAS PARADAS POR LINEA -->
        <v-dialog
            v-if="dialogStop"
            v-model="dialogStop"
            persistent
            width="500"
        >
            <stop
                @refreshlines="refreshlines()"
                @closeDialogStop="closeDialogStop()"
                :lineSelected="lineSelected"
            ></stop>
        </v-dialog>
 </div>
</template>


<script>
    import('../../../styles/linesPacking.css');

    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"
    import _sStop from "@/services/FrozenProduction/FrzCtnLinesProcessStop.js"


    import cTablesLines from "./CTablesLines.vue";
    // import sSelectCustomer from "@/components/Sale/SselectClientSap.vue";
    import Stop from './Stop.vue';
    import cResponsableLine from "./CResponsableLine.vue";
    import cConfLines from "./CConfLines.vue";
    import SendKgLine from "./CtnSendKgLine.vue";


  
  export default {
    components: { cTablesLines, Stop ,cResponsableLine,cConfLines, SendKgLine},
    props: {
        line: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
       
        itemsTablesLines:[],
        itemsTablesOne:[],
        itemsTablesTwo:[],
        modelDialog : false,
        dialogStop: false,
        lineSelected: {},
        TypeAction: 0,
        lastStop: {},
        LstEndProcees:{},
        ItemSelect: "tab-Funciones",
        LstConfiInitial:{},
        CecCol:'-',
        CecDesc:0,
        PchPeriodo: '',
    }),
    
    methods: {
        refreshlines(){
            this.$emit("refreshlines")
            
        },

        openDialog(line) {
            this.items = line;
            this.modelDialog = true;
        },

        closeDialog(){
            this.modelDialog = false;
            this.refreshlines()
        },

        TablesLinesLst(ClpID){
            
            _sFrzCtnLinesProcessService.listTablesByLines({ClpID},this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    this.itemsTablesLines = r.data
                    
                    
                    // this.itemsTablesOne = this.itemsTablesLines
                    this.itemsTablesOne = this.itemsTablesLines.filter(x => x.CttPosition == 1)
                    this.itemsTablesTwo = this.itemsTablesLines.filter(x => x.CttPosition == 2)
                }
            })
        },
        openDialogStop(line)
        {
            if(this.TypeAction == 2 || this.TypeAction == 0)
            {
                this.lineSelected = line;
                this.dialogStop = true;
            }
            else
            {
                this.savePlay();
            }
        },

        closeDialogStop()
        {
            this.getLastStopByLine();
            this.dialogStop = false;
        },

        getLastStopByLine()
        {
            if(this.line != null)
            {
                _sStop
                .lasstopbyline(this.line, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        if(resp.data != null)
                        {
                            this.TypeAction = resp.data.TypeAction;
                            this.lastStop = resp.data;
                            
                        }
                        
                    }
                });
            }
        },

        savePlay()
		{
            this.$fun.alert("¿Seguro de reanudar?", "question")
            .then(r =>{
                if(r.value)
                {
                    this.lastStop.LpsID = 0;
                    this.lastStop.LpsDate = new Date();
                    this.lastStop.TypePlace = 1;
                    this.lastStop.TypeAction = 2;
                    this.lastStop.TypeCategory = null;
                    this.lastStop.LpsResponsible = null; 
                    this.lastStop.LpsReason = null;
                    this.lastStop.SecStatus = 1;
                    this.lastStop.UsrCreateID = this.$fun.getUserID();
                    this.lastStop.UsrUpdateID = this.$fun.getUserID();

                    
                    // return


                    _sStop
                    .save(this.lastStop, this.$fun.getUserID())
                    .then(resp => {
                        if(resp.status == 200)
                        {
                            this.$fun.alert("Reanudado correctamente", "success");
                            this.getLastStopByLine();
                            this.refreshlines()
                            return;
                        }
                    })
                }
            })
		},
        finallyLineProcess(){
            

            this.$fun.alert("¿Esta seguro de finalizar Proceso?", "question")
            .then((r) => {
                if (r.value) {
                    let obj = {
                        ClpID : this.line.ClpID,
                        UsrUpdateID : this.$fun.getUserID(),
                    }
                    _sFrzCtnLinesProcessService.linesEnd(obj, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Proceso Terminado Correctamente", "success");
                            this.$emit("refreshlines");
                        }
                    })
                    
                }
            });
        },
        linesEndProcess(){
           
            _sFrzCtnLinesProcessService.linesEndProcess({ClpID : this.line.ClpID}, this.$fun.getUserID())
           .then((r) => {
               if (r.status == 200) {
                   if(r.data != null)
                   {
                       this.LstEndProcees = r.data;
                       
                   }
               }
           })
        },
        ConfigInitialActive(){
           
            _sFrzCtnLinesProcessService.ConfInitial({ClpID : this.line.ClpID}, this.$fun.getUserID())
           .then((r) => {
               if (r.status == 200) {
                   if(r.data != null)
                   {
                        this.LstConfiInitial = r.data;
                        
                        if(this.LstConfiInitial.length > 0 ){ 
                           this.PchPeriodo = this.LstConfiInitial[0].PchPeriodo.toString().length > 1 ? this.LstConfiInitial[0].PchPeriodo.toString() : "0" + this.LstConfiInitial[0].PchPeriodo;
                           // this.CecCol = this.LstConfiInitial[0].CecColor;
                           // this.CecDesc = this.LstConfiInitial[0].CecDescription;
                        }
                   }
               }
           })
        },
        
       
        
    },
    created () {
        
        this.getLastStopByLine();
        this.linesEndProcess();
        this.ConfigInitialActive();
	},
    mounted(){
        
        this.TablesLinesLst(this.line.ClpID);
        
    }

  };

</script>
<template>
    <v-card dense> 
         
            <br>
            <v-row>
                <s-toolbar
                    save
                    @save="saveTmpPulple()"
                    :color="'#BAB6B5'"
                    dark
                    label=""
                    close
                    @close="close()"
                />
            </v-row>

            <v-row style="margin-left: 5px" justify="center">
                <v-col
                    lg="12"
                    class="pt-0"
                    cols="12"
                    v-for="item in pallets"
                >
                    <v-toolbar dense>
                        <v-toolbar-title
                            ><h4 style="-webkit-text-stroke: medium">
                                Pallet - {{ item.PltID }}
                            </h4></v-toolbar-title
                        >
                    </v-toolbar>
                    <v-card dense>
                        <v-row justify="center" style="margin: auto">
                            <v-col cols="6" md="3" lg="3">
                                <s-text
                                    label="Temp. Pulpa 01 (°C)"
                                    v-model="item.CodTempPulpe01"
                                    decimal
                                ></s-text>
                            </v-col>
        
                            <v-col cols="12" md="12" lg="12">
                                <s-textarea
                                    label="Observaciones"
                                    v-model="item.CodObservation"
                                ></s-textarea>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        
    </v-card>
</template>
<script>

    import _sTemperaturePalletService from "../../../services/FreshProduction/TemperaturePalletSevice";

    export default {
        props:{
            pallets: {
                default: null
            },
            item:{
                default: null
            }
        },
        data(){
            return {

            }
        },
        methods: {
            saveTmpPulple() {
				console.log("guardar", this.pallets);
                console.log("guardar2", this.item);
				if(this.item.CocDateInitialProcess == null && this.item.TypeChamberTunnel == 2){
					this.$fun.alert("Error, Para registrar temperaturas de Pulpa, Inicie Proceso", "warning")
					return;
				}

				this.$fun.alert("¿Seguro de Guardar?", "question").then((val) => {
					if (val.value) {
						this.pallets.forEach((element) => {
							element.CodHour = this.$fun.getHour();
							element.CodDate = this.$fun.getDateTimeShortDB();
							element.SecStatus = 1;
							element.UsrCreateID = this.$fun.getUserID();
                            console.log("guardar3", element);
							_sTemperaturePalletService
								.save(element, this.$fun.getUserID())
								.then((resp) => {
									if (resp.status == 200) {
                                        this.$fun.alert("Registrado correctamente", "success")
										this.dialogTempPulpe = false;
										// this.close();
									}
								});
						});
					}
				});
			},
            close(){
                this.$emit("close")
            }
        }
    }

</script>
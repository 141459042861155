<template>
    <v-card>
        <div class="modal">
            <div class="modal-content" style="width: 100%;">
                <s-toolbar
                    label="CONSOLIDADO DE PERSONAL CON SALIDA DE EQUIPO"
                    dark
                    :color="'#BAB6B5'"
                    close
                    @close="$emit('close')"
                    class="mb-6"
                    print @print="downloadPdf()"
                ></s-toolbar>
                <vue-html2pdf
                    :show-layout="true"
                     :float-layout="false"
                    :enable-download="false"
                    :preview-modal="false"
                    :filename="'Listado'"
                    pdf-format="a4"
                    pdf-orientation="landscape"
                    pdf-content-width="100%" 
                    :manual-pagination="true"
                    :margin="10"
                    @beforeDownload="beforeDownload($event)"
                    :autoPaging="true"
                    ref="html2Pdf"
                >
                    <section slot="pdf-content">
                        <div v-for="(group, pageIndex) in groupedItems" :key="pageIndex" class="pdf-page" >
                            <!-- Cabecera -->
                            <div style="width: 95%; margin: auto;">
                                <br>
                                <table  style="width: 100%;" class="details-table">
                                    <tr></tr>
                                    <tr>
                                        <td style="width: 30%; text-align: left;">
                                            <img src="../../../public/static/logo_sp.png" alt="Logo" width="120px" />
                                        </td>

                                        <td style="width: 55%; text-align: center; font-weight: bold; font-size: 10.5px;">
                                            LISTADO DE PERSONAL AUTORIZADO PARA SALIDA DE LAPTOPS
                                        </td>
                                        <td style="width: 15%; text-align: center; font-size: 5.5px;">
                                            CÓDIGO:AS-LI-001<br>
                                            VERSIÓN:02<br>
                                            Fecha de Creación:6/02/2023<br>
                                            Última Revisión:11/02/2024<br>
                                            Página: {{ pageIndex+1 }} de {{ contador }} <br>
                                            Anexos:00
                                        </td>
                                    </tr>
                                </table>
                  
                                <table style="width: 100%; border-right: 1px solid black; border-left: 1px solid black;">
                                    <tr>
                                        <td style="width: 20%;  font-size: 8.5px;">
                                            <b>Actualizado: {{getFormattedDate()}}</b>
                                        </td>
                                        <td style="width: 60%;">

                                        </td>
                                        <td style="width: 20%; font-size: 8.5px; text-align: center;">        
                                            <b>{{refPerondalExitEpp.Observacion }}</b>                                                                   
                                        </td>
                                    
                                    </tr>
                                </table>
                            </div>
                            <!-- Tabla de Detalles -->
                            <div  style="width: 95%; margin: auto; ">
                                <!--<table style="width: 100%; border-collapse: collapse; border: 1px solid black;">-->
                                <table class="details-table">
                                    <thead>
                                            <!--<tr style="background-color: #f2f2f2; text-align: center;">-->
                                            <tr>
                                                <th >ITEM</th>
                                                <th >DNI</th>
                                                <th >APELLIDOS NOMBRES</th>
                                                <th >CARGO</th>
                                                <th >TIPO</th>
                                                <th >MARCA</th>
                                                <th >MODELO</th>
                                                <th >SERIE</th>
                                                <th >FECHA LIMITE</th>
                                                <th >L</th>
                                                <th >M</th>
                                                <th >X</th>
                                                <th >J</th>
                                                <th >V</th>
                                                <th >S</th>
                                                <th >D</th>
                                            </tr>
                                    </thead>
                                    <tbody>                                        
                                            <tr v-for="(item, index) in group" :key="index" >    
                                                <td>{{ index+1 }}</td>
                                                <td >{{item.PrsDocumentNumber}}</td>
                                                <td >{{item.NtpFullName}}</td>
                                                <td >{{item.PstName}}</td>
                                                <td >{{item.NameTypeEquipment}}</td>
                                                <td >{{item.NameTypeBrand}}</td>
                                                <td >{{item.NameTypeModel}}</td>
                                                <td >{{item.NumSeries}}</td>
                                                <td>{{ item.MngEndDate!==null ? moment(item.MngEndDate).format('DD-MM-YYYY') : 'PERMANENTE' }}</td>
                                                <td >{{item.Monday}}</td>
                                                <td >{{item.Tuesday}}</td>
                                                <td >{{item.Wednesday}}</td>
                                                <td >{{item.Thursday}}</td>
                                                <td >{{item.Friday}}</td>
                                                <td >{{item.Saturday}}</td>
                                                <td >{{item.Sunday}}</td>
                                            </tr>
                                    </tbody>
                        
                                </table>
                                
                            </div>
                            
                        </div>
                    </section>
                </vue-html2pdf>
            </div>
        </div>
    </v-card>
</template>
<script>
    import moment from 'moment';
    import VueHtml2pdf from 'vue-html2pdf'
    //import jsPDF from 'jspdf';
    import _sHelper from "@/services/HelperService";

    export default{ 
        props:{
            refPerondalExitEpp: {
				type: Array,
				default: null,
			},
        },
        components:{
            VueHtml2pdf
        },
        data: () => ({
            contador:1,
            pagin:0,
            SignatureImage: null,
		    SignatureImageSuper: null,
            BossName: "",
            items : [ ],
            headers : [ 
                { text: "ITEM"          , value: ''          },
                { text: "DNI"          , value: 'PrsDocumentNumber'          },
                { text: "APELLIDO Y NOMBRE"              , value: 'NtpFullName'   },
                { text: "CARGO"         , value: 'PstName'  },
                { text: "TIPO"                 , value: 'NameTypeEquipment'      },
                { text: "MARCA"          , value: 'NameTypeBrand'          },
                { text: "MODELO"        , value: 'NameTypeModel'          },
                { text: "SERIE"               , value: 'NumSeries'     },
                { text: "FECHA LIMITE"                 , value: 'MngEndDate'          },
                { text: "L"                 , value: 'Monday'          },
                { text: "M"                 , value: 'Tuesday'          },
                { text: "X"                 , value: 'Wednesday'          },
                { text: "J"                 , value: 'Thursday'          },
                { text: "V"                 , value: 'Friday'          },
                { text: "S"                 , value: 'Saturday'          },
                { text: "D"                 , value: 'Sunday'          },
            ],
            groupedItems: [],
        }),
        methods:{
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
            getFormattedDate() {
                const date = new Date();
                return new Intl.DateTimeFormat('es-ES', {
                    weekday: 'long',  // Día de la semana completo (ej. "lunes")
                    day: 'numeric',   // Día del mes (ej. "5")
                    month: 'long',    // Mes completo (ej. "noviembre")
                    year: 'numeric'   // Año (ej. "2024")
                }).format(date);
            },
            formatDate(date){
                return this.$fun.formatDateTimeShort12HView(date)
                .replace("AM", "a.m.")
                .replace("PM", "p.m.")
            },
            groupItems(items, groupSize = 9) {
                const grouped = [];
                for (let i = 0; i < items.length; i += groupSize) {
                    grouped.push(items.slice(i, i + groupSize));
                }
                return grouped;
            },
            beforeDownload ({ html2pdf, options, pdfContent }) {
				console.log(`On Before PDF Generation`)
				html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
				//	const totalPages = pdf.internal.getNumberOfPages()
				//	for (let i = 1; i <= totalPages; i++) {
				//		pdf.setPage(i)
				//		pdf.setFontSize(10)
				//		pdf.setTextColor(150)
				//		pdf.text('Página ' + i + ' de ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))

                  //  } 
				}).save()
			},

            groupItemsDynamic(items, maxRowsPerPage = 40) {
                const grouped = [];
                let currentGroup = [];
                let rowCount = 0;

                items.forEach((item) => {
                currentGroup.push(item);
                rowCount++;

                if (rowCount === maxRowsPerPage) {
                    grouped.push(currentGroup);
                    currentGroup = [];
                    rowCount = 0;
                    this.contador=this.contador+1;
                }
                });

                if (currentGroup.length > 0) {
                grouped.push(currentGroup);
                }
                console.log("gruporef", this.grouped);
                return grouped;
            },
        },
        mounted(){
            console.log("refPerondalExitEpp", this.refPerondalExitEpp);
        },
        created () {
			this.years = this.$fun.getTop10LastYears();
			this.year = this.$moment().year();
            console.log("refPerondalExitEpp222", this.refPerondalExitEpp);
            this.groupedItems= this.groupItemsDynamic(this.refPerondalExitEpp)
		},
    }
</script>
<style >
.pdf-page {
  height: 210mm; /* Altura exacta de una página A4 */
  width: 297mm; /* Ancho de una página A4 */
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px; /* Un margen extra entre páginas para evitar desbordamientos */
  page-break-inside: avoid; /* Evita que el div se divida entre páginas */
  width: 100%;
  height: 100%; /* Garantiza que cada div ocupe una página completa */
  min-width: 210mm;
}

.obs {
  width: 90%;
  margin: auto;
  font-size: 9px;
  page-break-inside: avoid; /* Evita divisiones en el contenido de observaciones */
}

.details-table thead {
  display: table-header-group;
}

.details-table tr {
  page-break-inside: avoid;
}

.details-table tbody tr {
  page-break-inside: avoid;
  margin-top:2px;
}

.details-table {
  width: 100%;
  border-collapse: collapse; /* Asegura que los bordes de la tabla estén unidos */
  font-size: 9px;
  margin-top: 0px;
  border: 2px solid black; /* Borde alrededor de la tabla */
}

.details-table th,
.details-table td {
  border: 1px solid black; /* Bordes en todas las celdas */
  text-align: center;
  padding: 1px;
}

.details-table th {
  border-bottom: 1px solid black; /* Borde en la parte inferior del encabezado */
  background-color: #f2f2f2; /* Fondo gris para los encabezados */
  font-weight: bold;
}

.details-table td {
  background-color: #fff; /* Fondo blanco para las celdas de datos */
}

/* Agregar líneas verticales y horizontales */
.details-table td,
.details-table th {
  border-left: 1px solid black; /* Borde izquierdo de cada celda */
  border-top: 1px solid black; /* Borde superior de cada celda */
}

/* Agregar márgenes entre las celdas */
.details-table td:first-child,
.details-table th:first-child {
  border-left: none; /* No agregar borde izquierdo a la primera columna */
}

.details-table td:last-child,
.details-table th:last-child {
  border-right: none; /* No agregar borde derecho a la última columna */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


.modal-content {
  background-color: #fff;
  padding: 20mm; /* Márgenes internos */
  border: 1px solid #000;
}


</style>
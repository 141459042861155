<template>
<div>
  <s-crud
    @returnObject="refresh($event)"
    no-border
    title="Registro de Visitas Técnicas"
    :config="this.config"    
    @clearForm="clearForm()"
    restore
    @rowSelected="rowSelected($event)"
    :filter="filter"
     ref="crudListaNotAllow"
  >
 <template v-slot:ListadoNsa="{ row }">
                <v-btn icon color="primary" v-if="row.ListadoNsa == 1" x-small  @click="showModal">
                    <v-icon>fas fa-eye</v-icon>
                </v-btn>
            </template>

</s-crud>

<list-supllies-not-allow :dialog="dialog" :tvID="TvID1" @hideModal="hideModal" @recharge="recharge"></list-supllies-not-allow>

</div>
</template>

<script>
import _technicalVisit from "@/services/Technicalassistance/TasTechnicalVisitSnaService";
import _sHarvest from "@/services/Technicalassistance/HarvestSchedule";
import ListSuplliesNotAllow from "@/components/TecnicalAssistence/ListSupplies/ListSuplliesNotAllow.vue";
export default {
  components: {
ListSuplliesNotAllow
  },
  props: {
    defID: 0,
    defDescription: "",
  },
  data: () => ({
    
   //fin carga objetos carga lote
    dialog: false,
    TvID1: 0,   
    config: {
      title: "Registro de Visitas Técnicas",
      service: _technicalVisit,
      model: {
        TvID: "ID",
        TvDate: "datetime",
        FltID: "int",
        NtpID: "int",
        DedValue: "int",
        SecStatus: "status",
        TvStatus: "status",
        TvObservation: "string",
        TvCultiveID: "int",
        ListadoNsa: "int",
      },
      headers: [
        {text: "ID",value: "TvID",width: "60",align: "end", sorteable: true,  },
        {text: "Fecha",value: "TvDate",sorteable: true,},
        {text: "Productor",value: "FagName",sorteable: false,},
        {text: "Lote",value: "FltName",sorteable: true,},
        {text: "Latitud",value: "FagLatitude",sorteable: true,},
        {text: "Longitud",value: "FagLength",sorteable: true,},
        {text: "Cultivo",value: "CtvName",sorteable: true,},
        {text: "Variedad",value: "VrtName",sorteable: true,},
        { text: "Tipo Visita",value: "TypeVisitName",align: "center",sorteable: false,},
        { text: "Ver", value: "ListadoNsa" },
      ],
    },
    currentItem: "tab-Funciones",
  }),

  computed: {
    filter() {
      return {};
    },
  },
  methods: {
     showModal()
    {
      this.dialog = true;
    },

     hideModal()
    {
        this.dialog = false;
    },
    
    recharge(){
        this.$refs.crudListaNotAllow.refresh();
    },
    
 
   rowSelected(items) {
      if (items.length > 0) {
           this.TvID1 = items[0].TvID;
    }
         
    },  
     watch:{
      TvID1(){
          this.Initialize();
      }
  },
  created() {
    this.filter = {TvID: this.TvID1};
  },   
  
  },
};
</script>

<template>
    <v-col @click="documentFocus()">
        <v-row>
        <v-col lg="8" md="9" sm="8" cols="12">
            <!-- <s-toolbar   dark  color="" label="LECTOR DE PERSONAL AUTORIZADO PARA SALIDA DE EQUIPO" 
            ></s-toolbar> -->
            <v-card style="height:480px;">
            <v-col>
                <v-row justify="center">
                <v-col col="8" lg="8" md="8" class="s-col-form">
                    <s-text autofocus  
                      label="Documento" 
                      ref="textDNI" 
                      @keyupEnter="search" 
                      v-model="items.documento" 
                      clearable></s-text>
                </v-col>
                <v-col col="2" lg="2" md="2" >
                  <v-btn @click=limpiarDocument()   dark color="red">X</v-btn>
                </v-col>
                </v-row>
            </v-col>
            <v-col v-if="workerstic != null">
                <v-sheet height="130">
                <p class="text-center" style="color:var(--v-primary-base); font-size:18px;margin:0px;">
                    <b> {{this.dnii }} - {{workerstic.NtpFullName }}  </b>
                </p>
                <v-sheet style="padding:10px; padding-top:15px;" tile class="d-flex">
                    <v-spacer></v-spacer>
                    <h2 v-if="workerstic.NtpFullName != null && workerstic.AtqID!=null"  style="color: white; background-color: #27ae60; border-radius: 10px; font-size: 35px; padding: 10px; margin-top:-10px;">
                    {{ workerstic.Observacion }}
                    </h2>
                    <h2 v-if="workerstic.NtpFullName != null && workerstic.AtqID==null" style="color: white; background-color: #c0392b; border-radius: 10px; font-size: 35px; padding: 10px; margin-top:-10px;">
                    {{ workerstic.Observacion }}
                    </h2>
                    <v-spacer></v-spacer>
                </v-sheet>
                </v-sheet>
            </v-col>
                      <!-- Mostrar tabla con datos -->
          <v-col v-if="workerstic != null">
            <v-data-table
              v-bind:items="[workerstic]"  
              :headers="headers" 
              item-key="AtqID"         
              class="elevation-1"
            >
              <template v-slot:item="{ item }">
                <td class="text-center">{{ item.AtqID }}</td> 
                <td class="text-center">{{ item.NameTypeEquipment }}</td>
                <td class="text-center">{{ item.NameTypeBrand }}</td>
                <td class="text-center">{{ item.NameTypeModel }}</td>
                <td class="text-center">{{ item.NumSeries }}</td>
              </template>
            </v-data-table>
          </v-col>
            </v-card>
        </v-col>
        <v-col md="3" lg="4" sm="4">
        <v-img style="border-radius:10px;" height="300" :src="image" />
    </v-col>
        </v-row>
    </v-col>
   
</template>

<script>
import SToolbarPerson from "./../../components/Utils/Person/sToolbarPerson";
import _sAExitService from '@/services/Management/AuthorizedExitEpp.js'; 
import SText from '@/components/Utils/SText.vue';
import _service from "@/services/HelperService";
export default {
  name: "ReserveAdmin",
  components: { SToolbarPerson,SText },

  data: () => ({
        workerstic: null,
        dnii:null,
        items:{
            documento:null
        },
        image: null,
        headers: [
        { text: 'ID', align: 'center', key: 'AtqID' },
        { text: 'EQUIPO DE TI', align: 'center', key: 'NameTypeEquipment' },
        { text: 'MARCA DE EQUIPO', align: 'center', key: 'NameTypeBrand' },
        { text: 'MODELO DE EQUIPO', align: 'center', key: 'NameTypeModel' },
        { text: 'NUMERO SERIE', align: 'center', key: 'NumSeries' }
      ]
  }),
   
  computed: {
    
  },
  methods: { 
    documentFocus() {
      this.$refs.textDNI.focus();
    },
    limpiarDocument(){
      this.items.documento=null;
      this.$refs.textDNI.focus();
    },
    search(value) {
      const parts = value.split(',');
      this.dnii=parts[0];
      console.log();
      if (value != null) {
          _service.getPhotoWorker(this.dnii, this.$fun.getUserID()).then((rsp) => {
            const blob = new Blob([rsp.data], { type: "image/jpg" });
            let image = blob;
            var reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = () => {
              this.image = reader.result;
            };
          });
          this.limpiarDocument();
          let obj = {
            PrsDocumentNumber: this.dnii,
            UsrCreateID:this.$fun.getUserID()
          }
          _sAExitService
          .List(obj, this.$fun.getUserID())
          .then(resp => {
            if(resp.status == 200){
              this.workerstic = resp.data[0];
              if(this.workerstic.AtqID==null){this.$fun.soundAlert();}
              console.log('trabajLector', this.workerstic)
              console.log('trabajLector', this.workerstic.Observacion)
            }
          })
      } else {
        this.image = null;
      }
    },
  },
};
</script>

<style scoped>
.h + .v-date-picker-table {
  height: 150px !important;
}

.date + .v-btn {
  height: 20px !important;
}
</style>

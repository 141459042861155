<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <v-card>
        <s-toolbar  
            color="primary"             
            dark
            close
            @close="close"
            label="Lista de Insumos no permitidos aplicados a la visita"
        >
        </s-toolbar>

        <v-card-text>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="dataReceived"
            :single-select="singleSelect"
            item-key="LsID"
            
            class="elevation-1"
           >
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _servicio from "@/services/Technicalassistance/TasTechnicalVisitSnaService.js";
import ApprovalRequirementVue from '../../../views/Security/Approval/ApprovalRequirement.vue';
  export default {
    name:"ListSuplliesNotAllow",
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
      tvID: {
        type: Number,
        default: 0
      },
      },
    data () {
      return {
        rowSel: {},
        singleSelect: false,
        selected: [],
        headers: [
          { text: 'Visita', align: 'start', sortable: false, value: 'TvID' },
          { text: 'Producto', value: 'LsDescription' },
          { text: 'Dosis', value: 'PpaDose' },
        ],

        /* dialog: true, */
        filter: {},
        dataReceived: [],
    }
    },
    methods: { 
      close()
      {
        this.hideModal();
      },

      Initialize() {
        this.filter = {CsID: this.CsID};
      },
      hideModal() {
        this.$emit('hideModal');
      },
      loadDataInitial()
      {
       _servicio
        .listadoInsumosNoPermitidos(this.tvID)
        .then((resp) => {
          console.log('res', resp.data)
          this.dataReceived = resp.data;
        })
      },

      
    },
    watch:{
      tvID()
      {
          this.loadDataInitial();
      }
     
    }

  }
</script>

<template>
    <v-card>
        <div class="modal">
            <div class="modal-content" style="width: 100%;">
                <s-toolbar
                    :label="'Impresión de ' + referralGuideSelected.TypeRequirementName"
                    dark
                    :color="'#BAB6B5'"
                    close
                    @close="$emit('close')"
                    class="mb-6"
                    print @print="downloadPdf()"
                ></s-toolbar>
                <vue-html2pdf
                    :show-layout="true"
                     :float-layout="false"
                    :enable-download="false"
                    :preview-modal="true"
                    :filename="'Requerimiento_Compra-' +  referralGuideSelected.ReqDocumentNumber "
                    pdf-format="a4"
                    pdf-orientation="landscape"
                    pdf-content-width="100%" 
                    :manual-pagination="true"
                    :margin="10"
                    @beforeDownload="beforeDownload($event)"
                    :autoPaging="true"
                    ref="html2Pdf"
                >
                    <section slot="pdf-content">
                        <div v-for="(group, pageIndex) in groupedItems" :key="pageIndex" class="pdf-page" >
                            <!-- Cabecera -->
                            <div style="width: 90%; margin: auto;">
                                <table style="width: 100%; border: none;">
                                    <tr><br></tr>
                                    <tr>
                                        <td style="width: 30%; text-align: left;">
                                            <img src="../../../../public/static/logo_sp.png" alt="Logo" width="120px" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 70%; text-align: center; font-weight: bold; font-size: 17.5px;">
                                            {{ (referralGuideSelected.TypeRequirementName).toUpperCase() }}
                                        </td>
                                    
                                    </tr>
                                </table>
                            </div>
                            <!-- Información General -->
                            <div style="margin: 20px auto; width: 90%; font-size: 12px">
                                <table style="width: 100%; border-collapse: collapse;">
                                    <tr>
                                        <td style="width: 50%;">
                                            <b>Solicitante:</b> {{ referralGuideSelected.UsrName }}
                                            <br>
                                            <b>Área:</b> {{referralGuideSelected.AreName}}
                                        </td>
                                        <td style="width: 50%;">                                                                             
                                            <b>N° Requerimiento:</b> {{referralGuideSelected.ReqDocumentNumber}} 
                                            <br>
                                            <b>Estado:</b> {{referralGuideSelected.RqStatusName}}
                                        </td>
                                    
                                    </tr>
                                    <tr>
                                        <td> </td>
                                        <td>
                                            <b>Fecha de Solicitud:</b>  {{ formatDate(referralGuideSelected.ReqDate)}}
                                        
                                            <br>
                                            <b>Campaña:</b> {{referralGuideSelected.CmpName}}</td>
                                                                        
                                    </tr>
                                    
                                </table>
                            </div>
                            <!-- Tabla de Detalles -->
                            <div  style="width: 90%; margin: auto; ">
                                <!--<table style="width: 100%; border-collapse: collapse; border: 1px solid black;">-->
                                <table class="details-table">
                                    <thead>
                                            <!--<tr style="background-color: #f2f2f2; text-align: center;">-->
                                            <tr>
                                                <th >Código Artículo</th>
                                                <th >Descripción</th>
                                                <th >Unidad de Medida</th>
                                                <th >Cantidad</th>
                                                <th >Centro de Costo</th>
                                                <th >Línea de Negocios</th>
                                                <th >Tipo Costo</th>
                                                <th >Proyecto</th>
                                            </tr>
                                    </thead>
                                    <tbody>                                        
                                            <tr v-for="(item, index) in group" :key="index" >    
                                                <td >{{item.ArtCode}}</td>
                                                <td >{{item.DerDescription}}</td>
                                                <td >{{item.UnitMeasurement}}</td>
                                                <td >{{item.DerQuantity}}</td>
                                                <td >{{item.CceName}}</td>
                                                <td >{{item.BslName}}</td>
                                                <td >{{item.TypeCostName}}</td>
                                                <td >{{item.PrjName}}</td>
                                            </tr>
                                    </tbody>
                        
                                </table>
                                
                            </div>
                            
                            <!-- Observaciones -->
                            <div class="obs" style=" width: 90%;  margin: 20px auto; font-size: 12px;">
                                <tr style="width: 90%; page-break-inside: avoid;   ">
                                    
                                    <td ><p><b>Observación:</b> {{referralGuideSelected.ReqObservation}}</p></td>
                                </tr>
                            
                            </div>
                        </div>
                    </section>
                </vue-html2pdf>
            </div>
        </div>
    </v-card>
</template>
<script>
    import moment from 'moment';
    import VueHtml2pdf from 'vue-html2pdf'
    //import jsPDF from 'jspdf';
    import _sHelper from "@/services/HelperService";

    export default{ 
        props:{
            referralGuideSelected: {
				type: Object,
				default: null,
			},
        },
        components:{
            VueHtml2pdf
        },
        data: () => ({
           
            SignatureImage: null,
		    SignatureImageSuper: null,
            BossName: "GINO HERNAN BENITES CIEZA",
            items : [ ],
            headers : [ 
                { text: "Código Artículo"          , value: 'ArtCode'          },
                { text: "Descripción"              , value: 'DerDescription'   },
                { text: "Unidad de Medida"         , value: 'UnitMeasurement'  },
                { text: "Cantidad"                 , value: 'DerQuantity'      },
                { text: "Centro de Costo"          , value: 'CceName'          },
                { text: "Línea de Negocios"        , value: 'BslName'          },
                { text: "Tipo Costo"               , value: 'TypeCostName'     },
                { text: "Proyecto"                 , value: 'PrjName'          },
            ],
            groupedItems: [],
        }),
        methods:{
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
           
            formatDate(date){
                return this.$fun.formatDateTimeShort12HView(date)
                .replace("AM", "a.m.")
                .replace("PM", "p.m.")
            },
            groupItems(items, groupSize = 9) {
                const grouped = [];
                for (let i = 0; i < items.length; i += groupSize) {
                    grouped.push(items.slice(i, i + groupSize));
                }
                return grouped;
            },
            beforeDownload ({ html2pdf, options, pdfContent }) {
				console.log(`On Before PDF Generation`)
				html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages()
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i)
						pdf.setFontSize(10)
						pdf.setTextColor(150)
						pdf.text('Página ' + i + ' de ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
					} 
				}).save()
			},

            groupItemsDynamic(items, maxRowsPerPage = 9) {
                const grouped = [];
                let currentGroup = [];
                let rowCount = 0;

                items.forEach((item) => {
                currentGroup.push(item);
                rowCount++;

                if (rowCount === maxRowsPerPage) {
                    grouped.push(currentGroup);
                    currentGroup = [];
                    rowCount = 0;
                }
                });

                if (currentGroup.length > 0) {
                grouped.push(currentGroup);
                }

                return grouped;
            },
        },
        mounted(){
            console.log("referralGuideSelected", this.referralGuideSelected);
        },
        created () {
			this.years = this.$fun.getTop10LastYears();
			this.year = this.$moment().year();
            console.log("referralGuideSelected2222", this.referralGuideSelected);
            this.groupedItems= this.groupItemsDynamic(this.referralGuideSelected.Detail)
		},
    }
</script>

<style >

.pdf-page {
  height: 210mm; /* Altura exacta de una página A4 */
  width: 297mm; /* Ancho de una página A4 */
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;/* Un margen extra entre páginas para evitar desbordamientos */

  page-break-inside: avoid; /* Evita que el div se divida entre páginas */
  width: 100%;
  height: 100%; /* Garantiza que cada div ocupe una página completa */
  min-width: 210mm ;
}


.obs {
    width: 90%; margin: auto; font-size: 12px;
    page-break-inside: avoid; /* Evita divisiones en el contenido de observaciones */
}
.details-table thead {
    display: table-header-group;
}

.details-table tr {
    page-break-inside: avoid;
    
}

.details-table tbody tr {
    page-break-inside: avoid;
    margin-top: 10px;
}

.details-table {
    page-break-inside: auto;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px;
  margin-top: 10px;
  border-top: 4px double  black;
  border-bottom: 4px double  black;
}


.details-table th {
  border-bottom: 4px double black;
  text-align: center;
  padding: 8px;
}

.details-table td{
    text-align: center;
    padding: 8px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


.modal-content {
  background-color: #fff;
  padding: 20mm; /* Márgenes internos */
  border: 1px solid #000;
}


</style>
<template>
    <div>
        <s-text
            :label="label"
            ref='texPlate'
            v-model="VehPlate"
            @keyupEnter="consultFarm()"
        >
        <template v-slot:append>
            <v-icon style="cursor:pointer;margin-top:4px" >
            mdi-magnify
            </v-icon>
        </template>
        </s-text>
        <!--MODAL PARA BUSCAR FUNDOS CON SUS LOTES-->
        <v-dialog v-if="dialogSearchFarm" v-model="dialogSearchFarm" persistent max-width="850" transition="dialog-bottom-transition">
            <v-card rounded="">
                <v-toolbar text dense dark color="primary">             
                    <v-toolbar-title>Transportistas</v-toolbar-title><v-spacer></v-spacer>
                      <v-btn icon dark @click="dialogSearchFarm=false" >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-col lg="12">
                    <s-search-farm
                    :FltObservation="FltObservation"
                    @returnFarm="returnFarm($event)"
                    :items=itemsLots
                    @dblclick="dblclick($event)"
                    />
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import sSearchFarm from "@/components/HarvestSchedule/sSearchFarm";
import sAsistementDriverVehicle from "../../services/Technicalassistance/AssistementDriverVehicle";
import sVehicle from "@/services/Management/VehicleService"
export default {
    components:{sSearchFarm},
    props:{
        label:{
            type:String,
            default:'Chofer - Vehículo'
        },
        autofocus: {
            type: Boolean,
            default: true,
        },
        FagID : {
            type: Number,
            default: 0,
        },
        FltName : {
            type: String,
            default: '',
        },
        FltObservation: {
            type: String,
            default: '',
        },
        CodSenasa : {
            type: String,
            default: '',
        },
        typeCharge:{type:Number, default:0},
        Quantity: null


    },
    watch:{
        value(val){
            this.CodSenasa=val;
        },
        "CodSenasa"(){
            this.$emit("input",this.CodSenasa);
        },
    },
    methods:{
        dblclick(item){
            if(item != null)
            this.$emit('dblclick',item);
            this.dialogSearchFarm=false;
        },
        consultFarm(){
            /* if(this.Quantity == ''){
                this.$fun.alert("No existe un cantidad especificada", "warning")
                return;
            } */
            
            let obj;

            sAsistementDriverVehicle.listVehicle(this.typeCharge, this.Quantity, this.VehPlate, this.$fun.getUserID()).then((response)=>{
                
                if(response.data != null){
                    this.itemsLots=response.data;
                    this.dialogSearchFarm=true;
                }
            });
        },
        returnFarm(){
            this.consultFarm()
            this.dialogSearchFarm=false;
        }
    },
    data:()=> ({
        itemsLots:[],
        VehPlate: "",
            dialogSearchFarm:false,
            config:{
                model:{
                    CodSenasa:"string"
                }
            }
        }),
        
}
</script>
import Service from "../Service";

const resource = "sundrive/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },
    TicFileFolderList(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "TicFileFolderList", obj, {
            params: { requestID: requestID },
        });
    },
    saveDetail(cmp, requestID) {
        return Service.post(resource + "saveDetail", cmp, {
            params: { requestID: requestID },
        });
    },
    TicFileFolderDetailList(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "TicFileFolderDetaList", obj, {
            params: { requestID: requestID },
        });
    },
    TicFileFolderDetaListShare(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "TicFileFolderDetaListShare", obj, {
            params: { requestID: requestID },
        });
    },
    SaveDetailPermissions(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "SaveDetailPermissions", obj, {
            params: { requestID: requestID },
        });
    },
    saveDetPermiss(cmp, requestID) {
        return Service.post(resource + "saveDetPermiss", cmp, {
            params: { requestID: requestID },
        });
    },
   
};
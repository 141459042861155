<template >
	<!-- <v-row> -->
	<v-card>
		<v-row justify="center">
			<v-col cols="12">
				<s-toolbar
					:label="'Tratamiento - ' + item.TypeTankName"
					dark
					:color="'#BAB6B5'"
					close
					@close="close()"
					class="mb-0"
				></s-toolbar>
			</v-col>

			<v-col cols="12" lg="8" md="8" >
				<!-- Datos de canastilla -->
				<v-col cols="12">
					<v-toolbar dense>
						<v-toolbar-title
							>
							
							 Datos de Canastilla 
							<!-- <span>
								<v-switch style="display: inline-block;" label="Modo Grafico" v-model="modeGrap" @click="removeTmp()"></v-switch>
							</span> -->
							<!-- <v-btn
								@click="newItem()"
								class="mx-4"
								fab
								dark
								color="warning"
								small
							>
								<v-icon dark>
									mdi-plus
								</v-icon>
							</v-btn> -->
						</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
						<v-row justify="center" style="margin: auto;">
							<v-col>
								<p>{{ item.TypeDestinyName}}</p>
							</v-col>
							<v-col cols="12">
								<v-card-title>
									<b style="width: 100%; ">
										<v-text-field
											v-model="search"
											append-icon="mdi-magnify"
											label="Search"
											single-line
											hide-details
											style="margin-top: -30px;"
										></v-text-field>
									</b>
								</v-card-title>
								<v-data-table
									item-key="LytID"
									dense
									:items-per-page="5"
									:items="itemsLayetteFormed"
									:headers="headersLayetteFormed"
									v-model="selectedLayetteFormed"
									:search="search"
									group-by="TypeTimeHidroName"
									show-group-by
								>
									<template v-slot:item.Actions="{ item }">
										<v-btn
											@click="newItem(item)"
											x-small
											elevation="0"
											fab
											dark
											color="warning"
										>
											<!-- <v-icon style="font-size: 16px !important"
										>mdi-plus</v-icon
									> -->
											<v-icon dark>
												mdi-plus
											</v-icon>
										</v-btn>
									</template>
								</v-data-table>
							</v-col>
							<v-col>
								<s-text label="Posición en Tanque" v-model="TndPosition" ref="TndPosition" :min="0" number hidden></s-text>
							</v-col>

							<!-- <v-col cols="12" lg="3" md="3">
								<s-select-generic
									label="Canastillas"
									itemtext="TypeLayetteName"
									itemvalue="LytID"
									id="LytID"
									return-object
									@input="inputLayette($event)"
									:config="configLayette"
									v-model="objLayette.LytID"
								>
								</s-select-generic>
							</v-col>
							
							<v-col cols="12" lg="3" md="3">
								<s-text v-model="itemLayette.TypeCropName" label="Tipo Cultivo" readonly />
							</v-col>
							<v-col cols="12" lg="3" md="3">
								<s-text v-model="itemLayette.TypeCultiveName"  label="Cultivo" readonly />
							</v-col>
							<v-col cols="12" lg="3" md="3">
								<s-text v-model="itemLayette.VrtName" label="Variedad" readonly />
							</v-col>
							<v-col cols="12" lg="3" md="3">
								<s-text
									v-model="itemLayette.TypeTimeHidroName"
									label="Tiempo Proceso (min)"
									readonly
								/>
							</v-col>
							<v-col cols="12" lg="3" md="3">
								<s-text
									v-model="itemLayette.TypeFrontLayyeteName"
									label="Frente de carga"
									readonly
								/>
							</v-col>
							<v-col cols="12" lg="3" md="3">
								<s-text
									v-model="dateArmed"
									label="Fecha de armado"
									readonly
								/>
							</v-col>
							<v-col cols="12" lg="3" md="3">
								<s-text
									v-model="timeArmed"
									label="Hora de armado"
									readonly
								/>
							</v-col> -->
						</v-row>
					</v-card>
				</v-col>

				<!-- Datos de tanque -->
				<v-col cols="12" >
					<v-toolbar dense>
						<v-toolbar-title
							>Datos de Tanque

							<!-- <v-btn class="mx-4" fab dark color="error" small>
								<v-icon dark>
									mdi-minus
								</v-icon>
							</v-btn> -->
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="mx-4"
										fab
										dark
										color="info"
										small
										@click="save()"
									>
										<v-icon dark v-bind="attrs" v-on="on">
											mdi-content-save
										</v-icon>
									</v-btn>
								</template>
								<span>Guardar</span>
							</v-tooltip>
						</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
					<v-container>
							<!--justify="center" style="margin: auto;"-->
						<v-row >
							<v-col cols="12" lg="3" md="6">
								<s-select-definition
									v-model="item.TypeLineProcess"
									:def="1269"
									label="Linea Proceso"
								/>
							</v-col>
							<v-col cols="12" lg="3" md="6">
								<s-text
									:min="0"
									v-model="item.TnkNumberTreatment"
									number
									label="Nro. Tratamiento"
									readonly
								/>
							</v-col>
							<!-- <v-col cols="12" lg="4" md="4">
								<s-text
									:min="0"
									v-model="item.TnkPosition"
									number
									label="Posición tanque"
								/>
							</v-col> -->
							<v-col cols="6" lg="3" md="6">
								<!-- <s-date-time :labek="'Hora entrada'"></s-date-time> -->
								<s-date
									type="datetime"
									@input="inputHourEntry($event)"
									v-model="item.TnkHourEntry"
									label="Hora entrada"
								/>
							</v-col>
							<v-col cols="6" lg="3" md="6">
								<!-- <s-date-time :labek="'Hora salida'"></s-date-time> -->
								<s-date
									type="datetime"
									v-model="item.TnkHourExit"
									label="Hora salida"
								/>
							</v-col>
							<v-col cols="6" lg="3" md="6">
								<!-- <s-date-time :labek="'Hora salida'"></s-date-time> -->
								<s-text
									decimal
									v-model="item.TemperatureBegin"
									label="Temperatura° Inicio"
								/>
							</v-col>
							 
						</v-row>

					</v-container>
						<v-card-actions>
							<v-col cols="12">
								<v-data-table
									item-key="Line"
									:headers="headersDetails"
									:items="tankDetails"
									dense
									disable-sort
								>
									<template v-slot:item.Actions="{ item }">
										<v-btn
											@click="clickDelete(item)"
											x-small
											color="error"
											elevation="0"
											block
										>
											<v-icon
												style="font-size: 16px !important"
												>fas fa-times</v-icon
											>
										</v-btn>
									</template>

									<template v-slot:item.TndPosition="{ item }">
										<s-text v-model="item.TndPosition" number :min="0"></s-text>
									</template>

									
								</v-data-table>
							</v-col>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-col>

			<!-- <v-col cols="12" v-if="modeGrap">
				<v-card flat :disabled="disabled">
					<v-row>
						<v-col align="center" cols="12" lg="12" md="12">
							<drag-drop
								:dropzones="itemsPositionAll"
								:originalData="tankDetails"
								:dropzonesTitle="'UBICACIÓN DE CANASTILLA EN TANQUE'"
								:inPlace="true"
								:enableSave="true"
								@save="saveDrap"
							>
								<template #dd-card="{ cardData }">
									<custom-card-layette
										:data="cardData"
									/>
								</template>
							</drag-drop>
						</v-col>
					</v-row>
				</v-card>
			</v-col> -->
		</v-row>
	</v-card>
	<!-- </v-row> -->
</template>
<script>
	import _sTankService from "../../../services/FreshProduction/PrfTankService";
	import _sGenParam from "../../../services/General/ParameterService";

	export default {
		props: {
			item: {
				required: true,
				default: null
			}
		},
		components: {},
		data() {
			return {
				itemLayette: {},
				/* configLayette: {}, */
				itemsLayetteFormed: [],
				selectedLayetteFormed: [],
				headersLayetteFormed: [
					{ text: "Acciones", value: "Actions", width: 100 },
					{ text: "Canastilla", value: "TypeLayetteName" },
					{ text: "Tipo Cultivo", value: "TypeCropName" },
					{ text: "Cultivo ", value: "TypeCultiveName" },
					{ text: "Variedad ", value: "VrtName" },
					{ text: "Tiempo Proceso (min)", value: "TypeTimeHidroName" },
					{ text: "Frente de carga", value: "TypeFrontLayyeteName" },
					{ text: "Fecha de armado", value: "dateArmed" },
					{ text: "Hora de armado", value: "timeArmed" },
					
				],
				search: "",

				timeArmed: null,
				dateArmed: null,
				objLayette: {},
				tankDetails: [],
				tankDetailsRemoved: [],
				headersDetails: [
					{ text: "", value: "Line" },
					{ text: "Acciones", value: "Actions", width: 100 },
					{ text: "N° Canastilla", value: "LytID" },
					{ text: "Canastilla", value: "TypeLayetteName" },
					{ text: "Tiempo Proceso (min)", value: "TypeTimeHidroName" },
					/* { text: "Posición", value: "TndPosition", align: "center", width: 200 }, */
					
				],

				tankD: [],
				tankCapacity: 0,
				/* modeGrap:false,
				itemsPositionAll: [],*/
				TndPosition: 1
			};
		},

		created() {
			/* this.configLayette = {
					url: _sTankService.GetLayetteFormed(),
					params: { search: "", requestID: this.$fun.getUserID() },
				}; */

			this.intitialize();
			this.GetLayetteFormed();
		},

		mounted() {
			this.GetTankDetail();
		},

		methods: {
			intitialize() {
				_sGenParam
					.search(
						{ PrmName: "TanqueCapacidadCanastilla" },
						this.$fun.getUserID()
					)
					.then(resp => {
						if (resp.status == 200) {
							this.tankCapacity = resp.data.PrmValue;
							console.log(this.tankCapacity);
						}
					});
			},

			close() {
				this.$emit("close");
			},

			/* inputLayette(val) {
				if (val !== undefined && val !== null) {
					if (val !== null || val !== undefined) {
						this.itemLayette = val;

						this.timeArmed = this.$moment(val.SecCreate).format(
							this.$const.FormatTimeDB
						); //this.$fun.formatTimeView(val.SecCreate);
						this.dateArmed = this.$moment(val.SecCreate).format(
							this.$const.FormatDateDB
						); //this.$fun.formatDateView(val.SecCreate);
					}
				}
			}, */

			inputHourEntry(val) {
				console.log("fecha selecionada", val);
				//&& this.item.TnkHourExit == ""
				if (val !== null && val !== "" ) {
					
					if(this.tankDetails.length > 0){
						var time = this.tankDetails[0].TypeTimeHidroName;

						//samir
						
						const dateNow = new Date(val);
						dateNow.setMinutes(dateNow.getMinutes() + parseInt(time));

						var now = this.$moment(dateNow).format(
								this.$const.FormatDateTimeDB);

						this.item.TnkHourExit = now
					}else{
						this.$fun.alert("Por favor seleccionar una canastilla.", "warning")
						return
					}
					

				}
			},

			GetTankDetail() {
				if (this.item.TnkID !== null) {
					_sTankService
						.GetTankDetail(this.item.TnkID, this.$fun.getUserID())
						.then(r => {
							if (r.status == 200) {
								this.tankDetails = r.data;
								this.orderDetails();
							}
						});
				}
			},

			GetLayetteFormed() {
				_sTankService.GetLayetteFormed(this.item.TypeDestinyName, this.$fun.getUserID()).then(r => {
					if (r.status == 200) {
						//samir
						//console.log("layetees", r.data);
						this.itemsLayetteFormed = r.data;

						this.itemsLayetteFormed.forEach(element => {
							element.timeArmed = this.$moment(element.SecCreate).format(this.$const.FormatTimeDB); //this.$fun.formatTimeView(val.SecCreate);
							element.dateArmed = this.$moment(element.SecCreate).format(this.$const.FormatDateDB)
						});
					}
				});
			},

			newItem(item) {


				//console.log(this.tankDetails.length + 1);
				if(this.tankDetails.length > 0)
				{
					this.$fun.alert("Error, el tanque ya esta lleno", "warning");
					return;
				}
				let tamanio = this.tankDetails.length + 1;
				if (tamanio > this.tankCapacity) {
					this.$fun.alert(
						"Error, esta sobrepasando la capacidad de canastilla por tanque ==>" +
							this.tankCapacity,
						"warning"
					);
					return;
				}

				if(this.TndPosition <= 0){
					this.$refs.TndPosition.error("Error, Ingrese una Posición de canastilla en Tanque");
					this.$fun.alert(
						"Error, Ingrrese una Posición de canastilla en Tanque",
						"warning"
					);
					return;
				}
				
				if(parseInt(this.tankCapacity) < parseInt(this.TndPosition)){
					this.$fun.alert("Error, La posición ingresada no es correcta", "warning");
					return;
				}				

				/* if (this.objLayette.LytID !== null) { */
				if (this.itemsLayetteFormed.length > 0) {
					var exist = false;
					this.tankDetails.forEach(element => {
						if (item.LytID == element.LytID) {
							exist = true;
							this.$fun.alert("Canastilla repetida", "warning");
						}

						console.log(item.TypeTimeHidroName, element.TypeTimeHidroName);

						if (item.TypeTimeHidroName !== element.TypeTimeHidroName) {
							exist = true;
							this.$fun.alert("Error, esta seleccionando canastilla con tiempo de proceso diferente a las ya agregadas", "warning");
						}

						if(element.TndPosition == this.TndPosition){
							exist = true;
							this.$fun.alert("Error, La posición ingresa para la Canastilla seleccionada esta ocupada, ingrese una posición diferente.", "warning");
						}

						
					});

					if (exist == false) {
						let temp = {
							LytID: item.LytID,
							TypeLayetteName: item.TypeLayetteName,
							TypeTimeHidro: item.TypeTimeHidro,
							TypeTimeHidroName: item.TypeTimeHidroName,
							Line: this.tankDetails.length + 1,
							TnkID: 0,
							TndPosition: this.TndPosition,
							SecStatus: 1,
							UsrCreateID: this.$fun.getUserID(),
							TypeCropName: item.TypeCropName,
							TypeCultiveName: item.TypeCultiveName,
							VrtName: item.VrtName,
							TypeTimeHidroName: item.TypeTimeHidroName,
							TypeFrontLayyeteName: item.TypeFrontLayyeteName,
							dateArmed: item.dateArmed,
							timeArmed: item.timeArmed
						};

						this.tankDetails.push(temp);
						console.log('pusheado ', temp);
						this.itemsLayetteFormed = this.itemsLayetteFormed.filter(element => {
							return element.LytID != item.LytID
						})
						this.TndPosition = 0
					}
				}
			},

			orderDetails() {
				let i = 0;
				this.tankDetails.map(e => {
					i++;
					e.Line = i;
				});
			},

			clickDelete(item) {
				console.log('item delete ', item);
				if (this.tankDetails.length > 0) {
					if (item.TndID > 0) {
						this.tankD.push(
							this.tankDetails.filter(x => x.Line == item.Line)[0]
						);
						this.tankD.forEach(element => {
							element.SecStatus = 0;
						});
					}

					this.tankDetails = this.tankDetails.filter(
						x => x.Line != item.Line
					);
					this.itemsLayetteFormed.push(item)
					if(this.tankDetails.length == 0)
					{
						this.TndPosition = 1;
					}
					this.orderDetails();
				}
			},

			isValidated() {
				var isValid = true;
				if (this.item.TnkHourEntry == "" || this.item.TnkHourExit == "") {
					this.$fun.alert(
						"Debe seleccionar Hora entrada y/o Hora salida",
						"warning"
					);
					isValid = false;
					return;
				}

				if (this.item.TnkHourEntry > this.item.TnkHourExit) {
					this.$fun.alert(
						"La fecha/hora de salida no debe ser menor a la fecha/hora de entrada",
						"warning"
					);
					isValid = false;
					return;
				}

				/* if (this.item.TnkNumberTreatment <= 0) {
					this.$fun.alert("Ingrese numero tratamiento", "warning");
					isValid = false;
					return;
				} */

				/* if (this.item.TnkPosition <= 0) {
					this.$fun.alert("Ingrese posicion tanque", "warning");
					isValid = false;
					return;
				} */

				if (this.tankDetails.length == 0) {
					this.$fun.alert(
						"NO existen detalles para el tanque",
						"warning"
					);
					isValid = false;
					return;
				}

				if(this.item.TemperatureBegin <= 0){
					this.$fun.alert( "Ingrese Temperatura de Inicio.", "warning" );
					isValid = false;
					return;
				}

				return isValid;
			},

			save() {
				if (!this.isValidated()) {
					return;
				}
				 
				this.item.TypeTankID = this.item.TypeTankID;
				this.item.TnkState = 1;
				this.item.SecStatus = 1;
				this.item.UsrCreateID = this.$fun.getUserID();

				this.item.itemsDetail = [...this.tankDetails, ...this.tankD];

				console.log("Guardar", this.item);

				this.$fun
					.alert("Esta seguro de Guardar ?  Hora de entrada : " + this.item.TnkHourEntry + ', Hora Salida: '+ this.item.TnkHourExit, "question")
					.then(val => {
						if (val.value) {
							_sTankService
								.save(this.item, this.$fun.getUserID())
								.then(r => {
									this.$fun.alert(
										"Registro guardado correctamente",
										"success"
									);
									this.close();
								});
						}
					});

				
			},

		}
	};
</script>

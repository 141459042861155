<template>
	<div>
		<v-row>
			<v-col>
				<s-crud
					title="Subir Archivos"
					:config="config"
					:filter="filter"
					add
					@add="add()"
					@save="save($event)"
					ref="gridArchivist"
					searchInput
				>
					<template v-slot:filter>
						<v-container>
							<v-row>

								<v-col cols="3">
									<s-select-definition
										label="Tipo de Documentos"
										:def="1477"
										v-model="filter.typeDocumentsSap"
										clearable
									></s-select-definition>
								</v-col>
							</v-row>
						</v-container>
					</template>

					<template scope="props">
						<v-container>
							<v-row justify="center">
								<v-col cols="8" md="8">
									
									<v-col>
										<v-card style="height: 50%;  overflow-y: auto;" >
											<v-card-title class="grey" style=" padding-left: 3; padding-top: 0%; padding-bottom: 0%; ">
												<span style="color: #f1f1f1;">Agregar Documentos</span>
											</v-card-title>
											<v-card-text style="padding-left: 3; padding-top: 1%; padding-bottom: 0%;">
												<v-row>
													<v-col>
														<v-text-field
															v-model="nuevoDocumento"
															style="margin: 0; padding: 0%;" 
															clearable
														></v-text-field>
														
														
			  			
													</v-col>
													<v-col cols="auto">
														<v-btn 
															@click="agregarDocumento" 
															color="grey" 
															style="margin-top: 20%; padding: 0%;"
															x-small 
															class="white--text"
														>
															<v-icon>mdi-plus</v-icon>
														</v-btn>
													</v-col>
													<v-col cols="6" md="3" lg="3" >
														<v-btn small="" block color="primary" @click="openDialogMassive()">
															Agregar Masivo
														</v-btn>
													</v-col>
												</v-row>
											</v-card-text>

											<!-- Lista de documentos con paginación -->
											<v-list style="margin: 0; padding: 0%;" :lines="false" density="compact" nav dense class="scrollable">
												<v-list-item-group>
													<v-list-item
														v-for="(documento, index) in paginatedDocumentos"
														:key="documento.McdID"
														color="info"
													>
														<v-list-item-icon>
															<v-icon>mdi-card</v-icon>
														</v-list-item-icon>
														<v-list-item-content>
															<v-list-item-title>{{ documento.McdNumDocument }}</v-list-item-title>
														</v-list-item-content>
														<v-list-item-action>
															<v-btn 
																icon 
																@click="eliminarDocumento(index)" 
																class="grey--text"
																x-small
															>
																<v-icon>mdi-close</v-icon>
															</v-btn>
														</v-list-item-action>
													</v-list-item>
												</v-list-item-group>
											</v-list>

											<!-- Componente de paginación -->
											<v-pagination
												v-model="page"
												:length="pageCount"
												:total-visible="4"
												 class="mt-3"
                                                 color="grey"
											></v-pagination>
										</v-card>
									</v-col>
								</v-col>

								<!-- Archivos adjuntos -->
								<v-col cols="4" md="4">
									<v-row>
										<v-col lg="12">
											<s-select-definition
												label="Tipo de Documentos"
												:def="1477"
												v-model="props.item.typeDocumentsSap"
												clearable
											></s-select-definition>
										</v-col>
									</v-row>
									<v-row>
										<v-col lg="12">
											<v-file-input
												:disabled="!isEditable"
												label="Anexo 01"
												v-model="Attach1"
												hide-details
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col lg="12">
											<v-file-input
												:disabled="!isEditable"
												label="Anexo 02"
												v-model="Attach2"
												hide-details
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col lg="12">
											<v-file-input
												:disabled="!isEditable"
												label="Anexo 03"
												v-model="Attach3"
												hide-details
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col lg="12">
											<v-file-input
												:disabled="!isEditable"
												label="Anexo 04"
												v-model="Attach4"
												hide-details
											/>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-container>
					</template>
          
          <template v-slot:NumDocs="{ row }">
            <v-btn
			  v-if="row.SapStatus == 1 "
              x-small
              color="success"
              @click="openDetailDocuments(row)"
            >
              <i class="fas fa-eye"></i>
            </v-btn>
          </template>
		  <template v-slot:btnUpload="{ row }">
            <v-btn 
			  v-if="row.SapStatus == 1"
              x-small
              color="info"
              @click="inUploadSap(row)"
            >
              <i class="fas fa-upload"></i>
            </v-btn>
          </template>
		  <template v-slot:SapStatu="{ row }">
				<v-chip 
					x-small 
					white
					:color= "row.SapStatus === 1 ? 'warning' : 'success'"
				>
					{{ row.SapStatus === 1 ? 'Pendiente' : 'Subido' }}
				</v-chip>
			</template>


				</s-crud>
        
			</v-col>
		</v-row>
    	<v-dialog
			v-model="detailDocuments"  
			width="500"
		>
			<v-card style="height: 290px; overflow-y: auto;">
				<s-toolbar
					label="N° DE DOCUMENTOS"
					color="#E0F7F2"
					close
					@close="closeDetailDocuments()"
				></s-toolbar>
				<v-container>
					<v-row>
				<v-col lg="12" md="12" xs="12" xl="12" sm="12" style="padding:0px;">
					<v-card-title dense style="padding-top:0px;margin-top:0px;padding-bottom:2px;">
					<v-text-field
						v-model="search"
						ref="buscar"
						append-icon="mdi-magnify"
						dense
						hide-details
						label="Buscar Documento"
					></v-text-field>
					<!-- Mostrar el botón solo si no hay coincidencias -->
					<v-btn 
						v-if="filteredItems.length === 0 && search"
						@click="guardarNuevoDocumento()"
						icon
						color="success"
						x-large
						style="position: absolute; top: 50%; right: 50%;left: 45%; transform: translateY(-50%);"
					>
						<v-icon>mdi-content-save</v-icon>
					</v-btn>
					</v-card-title>
				</v-col>
				</v-row>
					<v-row>
            <v-col class="pl-0 pt-0">
            <v-data-table
             dense
             :headers="headerProductionGroup"
             :items="filteredItems"
              hide-default-footer
              disable-sort
			  
              >
              <template v-slot:item.Actions="{ item }">
               <v-btn
               @click="clickDeleteItemGP(item)"
               x-small
               block
               rounded
               color="error"
               >
               <v-icon style="font-size: 16px !important"
                >mdi-close</v-icon
                >
              </v-btn>
                </template>
                </v-data-table>
                </v-col>
              </v-row>
        </v-container>
			</v-card>
		</v-dialog>


		<v-dialog v-if="dialogSaveMassive" v-model="dialogSaveMassive" width="60%" persistent  hide-overlay formPermanent  >
			<v-container>
				<v-row style="height :'700px'" >
					<v-col >
					<s-toolbar close   
						no-border  
						label="Documentos - Carga Masivos" 
						@close="close()"
						dark
						color="#2955c8"                
					>

					</s-toolbar>
						
						<v-card >	
							<v-col class="s-col-form">
								<v-row>
								<v-col >
									<s-import-excel
										@changeData="changeFile($event)"
										:headers = "headersDocuments"
										@loaded	="saveMassive($event)"					
									></s-import-excel>
								</v-col>
								</v-row>
							</v-col>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-dialog>	
	</div>

          
</template>
<script>
	import _sArchivService from '@/services/Management/Archivist.js';
	import sTextarea from '../../../components/Utils/sTextarea.vue';
	import _sGeneral from "@/services/HelperService.js";
	import { isUndefined } from 'lodash';

	export default {
		components: { sTextarea },
		data() {
			return {
				proyect: {},
				datatransfer: {},
				dataPaused: {},
				dialogTransfer: false,
				dialogPaused: false,
				selected: {},
                detailDocuments:false,
				Proyectic: [],
				itemsDataPG:[],
				nuevoDocumento: '', 
			    IMrcID:0,
				search: "",
				page: 1,  
				itemsPerPage: 3,  
				filter: {
					PrsID: 0,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0,
					TkpID: 0,
				},
				config: {
					model: {
						MrcID: "ID",
						Action: "Action",
						SapStatu: "integer",
                        NumDocs : "",
						btnUpload:"",
					},
					service: _sArchivService,
					headers: [
						{text: "ID", value: "MrcID"},
						{text: "Tipo de documento", value: "DedDescription"},
						{text: "Subir SAP", value: "btnUpload"},
						{text: "N° doc", value: "NumDocs"},
						{text: "Estado", value: "SapStatu"},
						{text: "Adjunto 1", value: "MrcAdjunto1"},
						{text: "Adjunto 2", value: "MrcAdjunto2"},
						{text: "Adjunto 3", value: "MrcAdjunto3"},
						{text: "Adjunto 4", value: "MrcAdjunto4"},
					]
				},
				Attach1: null,
				Attach2: null,
				Attach3: null,
				Attach4: null,
				headerProductionGroup: [
                    { text:"item", value :"McdID"},
                    { text: "Documento", value: "McdNumDocument"},
					{ text: "", value: "Actions" },
                ],

				headersDocuments:[
					{
						column	: 	"NUM_DOCUMENT",
						value	: 	"NUM_DOCUMENT",
						text 	: 	"Número de Documento"
					},		
				],
				documents: {

				},
				dialogSaveMassive:false,
			};
		},
		computed: {
			filteredItems() {
			if (this.search) {
				return this.itemsDataPG.filter(item => {
				return item.McdNumDocument.toLowerCase().includes(this.search.toLowerCase());
				});
			}
			return this.itemsDataPG;
			},
			// Calcula la cantidad total de páginas
			pageCount() {
				return Math.ceil(this.Proyectic.length / this.itemsPerPage);
			},
			// Obtiene los proyectos para la página actual
			paginatedDocumentos() {
				const start = (this.page - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.Proyectic.slice(start, end);
			}
		},
		methods: {
			agregarDocumento() {
				if (this.nuevoDocumento) {
					this.Proyectic.push({ McdID: this.Proyectic.length + 1, McdNumDocument: this.nuevoDocumento });
					this.nuevoDocumento = ''; // Limpia el campo de entrada
				}
			},
			eliminarDocumento(index) {
				this.Proyectic.splice(index, 1); // Elimina el documento de la lista
			},
			openFile(url) {
				_sGeneral.download(url.name, this.$fun.getUserID()).then((r) => {
					if (r.status === 200) {
						this.$fun.downloadHttp(r);
					}       
				});
			},
			initialize() {
				// Inicialización si es necesario
			},
			openDetailDocuments(row){
				console.log(row.MrcID);
				this.IMrcID=row.MrcID;
				this.detailDocuments = true;
			
				_sArchivService.DetailByMrcID({ MrcID: row.MrcID }, this.$fun.getUserID())
				.then(response => {
					if (response.status === 200) {
					this.itemsDataPG = response.data; 
					} else {
					this.$fun.alert("No se pudieron cargar los detalles del documento", "error");
					}
				})
				.catch(error => {
					console.error("Error al obtener los detalles:", error);
					this.$fun.alert("Error al obtener los detalles del documento", "error");
				});
			},
			closeDetailDocuments(){
				this.detailDocuments = false;
				this.search="";
			},
			inUploadSap(item){
				this.$fun.alert("Esta seguro de subir a SAP", "question")
				.then(val => {
					if (val.value) {
						item.U_syp_anexo1=item.MrcAdjunto1;
						item.U_syp_anexo2=item.MrcAdjunto2;
						item.U_syp_anexo3=item.MrcAdjunto3;
						item.U_syp_anexo4=item.MrcAdjunto4;
						item.tipoDocumento=item.typeDocumentsSap;
						_sArchivService.uploadinSap(item, this.$fun.getUserID())
                          .then(r => {
                            if (r.status === 200) {
								this.$fun.alert("Cargado correctamente", "success");
								this.$refs.gridArchivist.refresh();
                            } else {
                              this.$fun.alert("Error al eliminar documento de la lista", "error");
                            }
                          })
                          .catch(err => {
                            this.$fun.alert("Error al eliminar documento de la lista: " + err.message, "error");
                          });
					}
				});
			},
			clickDeleteItemGP(item){
				this.$fun.alert("Esta seguro de eliminar documento?", "question")
				.then(val => {
					if (val.value) {
						item.UsrUpdateID = this.$fun.getUserID();
						_sArchivService.updateStatuDetail(item, this.$fun.getUserID())
                          .then(r => {
                            if (r.status === 200) {
								_sArchivService.DetailByMrcID({ MrcID: item.MrcID }, this.$fun.getUserID())
								.then(response => {
									if (response.status === 200) {
									this.itemsDataPG = response.data; 
									} 
								})
								.catch(error => {
									console.error("Error al obtener los detalles:", error);
								});
                            } else {
                              this.$fun.alert("Error al eliminar documento de la lista", "error");
                            }
                          })
                          .catch(err => {
                            this.$fun.alert("Error al eliminar documento de la lista: " + err.message, "error");
                          });
					}
				});
			},
			save(items) {
				this.$fun.alert("Esta seguro de guardar cambios?", "question")
				.then(val => {
					if (val.value) {
						items.UsrCreateID = this.$fun.getUserID();
						items.MrcAdjunto1 = this.Attach1 != null ? this.Attach1.name : "";
						items.MrcAdjunto2 = this.Attach2 != null ? this.Attach2.name : "";
						items.MrcAdjunto3 = this.Attach3 != null ? this.Attach3.name : "";
						items.MrcAdjunto4 = this.Attach4 != null ? this.Attach4.name : "";
						// Crea un FormData para subir los archivos
						let files = [];
						if (this.Attach1) files.push(this.Attach1);
						if (this.Attach2) files.push(this.Attach2);
						if (this.Attach3) files.push(this.Attach3);
						if (this.Attach4) files.push(this.Attach4);
						var formData = new FormData();
						let i = 0;
						files.forEach((element) => {
							formData.append(i.toString(), element);
							i++;
						});
						// Primero sube los archivos
						_sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 3)
							.then(response => {
								if (response.status === 200) {
									_sArchivService.SaveCabecera(items, this.$fun.getUserID())
										.then(r => {
											if (r.status == 200) {
                        const mrcID = r.data[0].MrcID; // Asumiendo que MrcID viene en la respuesta

                      // Paso 2: Recorrer la lista de documentos y guardar los detalles
                      this.Proyectic.forEach(documento => {
                        const detailData = {
                          UsrCreateID: this.$fun.getUserID(),
                          McdNumDocument: documento.McdNumDocument, // El número de documento
                          MrcID: mrcID // El MrcID que obtuvimos de la cabecera
                        };

                        // Llamar a SaveDetail por cada documento
                        _sArchivService.SaveDetail(detailData, this.$fun.getUserID())
                          .then(r => {
                            if (r.status === 200) {
                              this.Proyectic = [];
                              this.Attach1 = null;  
                              this.Attach2 = null;  
                              this.Attach3 = null;  
                              this.Attach4 = null;  
                             // this.$fun.alert("Se guardó correctamente el detalle del documento", "success");
                            } else {
                              this.$fun.alert("Error al guardar el detalle del documento", "error");
                            }
                          })
                          .catch(err => {
                            this.$fun.alert("Error al guardar el detalle del documento: " + err.message, "error");
                          });
                      });

                      // Mostrar mensaje de éxito al guardar la cabecera
                      this.$fun.alert("Se guardó correctamente", "success");

                      // Actualizar la grilla (refrescar la vista)
                      this.$refs.gridArchivist.refresh();
                      }
                      })
                      .catch(err => {
                      this.$fun.alert("Error al guardar: " + err.message, "error");
                      });
                      }
                      })
                      .catch(err => {
                      this.$fun.alert("Error al cargar archivos: " + err.message, "error");
                      });
                      }
                      });
                      },					
			guardarNuevoDocumento(){
                      console.log(this.IMrcID);
                      console.log(this.search);
					  const detailData = {
                          UsrCreateID: this.$fun.getUserID(),
                          McdNumDocument: this.search, 
                          MrcID: this.IMrcID 
                        };
                        _sArchivService.SaveDetail(detailData, this.$fun.getUserID())
                          .then(r => {
                            if (r.status === 200) {
                              this.$fun.alert("Se guardó correctamente", "success");
							  this.search="";
							  _sArchivService.DetailByMrcID({ MrcID: this.IMrcID }, this.$fun.getUserID())
								.then(response => {
									if (response.status === 200) {
									this.itemsDataPG = response.data; 
									} 
								})
								.catch(error => {
									console.error("Error al obtener los detalles:", error);
								});
                            } else {
                              this.$fun.alert("Error al guardar documento", "error");
                            }
                          })
                          .catch(err => {
                            this.$fun.alert("Error al guardar  documento: " + err.message, "error");
                          });
			},
			isEditable() {
				return (
					(this.orderLocal.OrdStatus == 3 &&
					this.$fun.getUserID() == this.orderLocal.UsrCreateID) ||
					this.orderLocal.OrdID == null
				);
			},

			changeFile(data){        
				console.log('documento', this.documents)	       	
				this.documents = data;
				console.log('documento', this.documents)	    
               // this.personEn = this.searchPersonEn(this.documents);
				//console.log("Datos posteriores personEn" , this.personEn)	
               // this.searchCompany();
            },
			openDialogMassive(){
				this.dialogSaveMassive = true;
				console.log("Abrir open")
			},
			saveMassive(items){
				console.log("Items",items);
				items.forEach(element => {
					this.Proyectic.push({ McdID: this.Proyectic.length + 1, McdNumDocument: element.NUM_DOCUMENT });
				});
				console.log("Se subio",this.Proyectic);	
				this.dialogSaveMassive = false;
			},
			close(){
				this.dialogSaveMassive = false;
			}
		}
	};
</script>

<style>
	.scrollable {
		max-height: 450px; /* Ajusta según sea necesario */
		overflow-y: auto;  /* Habilita el scroll vertical */
	}
</style>

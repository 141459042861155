<template lang="">
    <v-card>
        <s-crud
            :config="config"
            title="Reservación de nuevo Personal"
            :filter="filter"
            add
            edit
            remove
            @save="save($event)"
        >
            <template v-slot:filter>
                <v-container>
                    <!-- <v-row>                        
                        <v-col>
                            <s-text
                                label="Codigo"
                                v-model="filter.ScfCode"
                            ></s-text>
                        </v-col>
                    </v-row> -->
                </v-container>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row >
                        <v-col cols="5" class="s-col-form">
                            <s-toolbar-person noCamera label="Documento*" v-model="props.item.PrsDocumentNumber" @returnPerson="returnPerson($event, props.item)" />
                        </v-col>
                        <v-col cols="7" class="s-col-form">
                            <s-text readonly label="Persona" v-model="props.item.NtpFullName"></s-text>
                        </v-col>
                        <v-col cols="4" class="s-col-form">
                            <s-date label="F. Reservación" v-model="props.item.RnpReservationDate" clearable></s-date>
                        </v-col>
                        <v-col class="s-col-form" style="padding-bottom:0px;">
                            <s-select-definition label="Selecciona Plato" :def="1065" v-model="props.item.TypeConsumition"></s-select-definition>
                        </v-col>
                        <v-col class="s-col-form" style="padding-bottom:0px;">
                            <s-select-definition label="Tipo Costo" :def="1045" v-model="props.item.TypeCost"></s-select-definition>
                        </v-col>
                        <v-col class="s-col-form" style="padding-bottom:0px;">
                            <s-select-area label="Centro de Costos" centercost clearable full v-model=props.item.CceID  :typeCost="props.item.TypeCost" noDefault></s-select-area>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="s-col-form" style="padding-bottom:0px;">
                            <s-select-business-line clearable v-model="props.item.BslID" noDefault :cceID="props.item.CceID"></s-select-business-line>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>
    </v-card>
  </template>
  
  <script>
  
    import SText from '../../components/Utils/SText.vue'
    import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
    import ServiceReservationNewPerson from "./../../services/DinningHall/MealReservationNewPerson";
  
    export default {
      components: {
        SText,
        sToolbarPerson,
      },
      data() {
        return {
            filter:{},
            config: {
              service: ServiceReservationNewPerson,
              model: {
                    RnpID : "ID",
                    NtpID: "int",
                    NtpFullName: "string",
                    PrsDocumentNumber: "string",
                    RnpReservationDate: "date",
                    TypeConsumitionName: "string",
                    TypeCostName: "string",
                    CceName: "string",
                    BslName: "string"
              },
              headers: [
                  { text: "ID", value: "RnpID" },
                  { text: "Persona", value: "NtpFullName" },
                  { text: "Fecha Reserva", value: "RnpReservationDate" },
                  { text: "Plato", value: "TypeConsumitionName" },
                  { text: "Tipo Costo", value: "TypeCostName" },
                  { text: "Centro de Costo", value: "CceName", sortable: false },
                  { text: "Linea de Negocio", value: "BslName", sortable: false },
              ]
            }
        }
      },
      methods:{
        returnPerson(value, item) {
            item.NtpFullName = value.NtpFullName;
            item.PrsDocumentNumber = value.PrsDocumentNumber;
            item.NtpID = value.NtpID;
            item.PrsID = value.PrsID;
        },
        save(item){
              
            console.log(item);
            if(item.PrsDocumentNumber.length <= 0){
                this.$fun.alert("El campo Documento es Obligatorio","warning");
                return;
            }
  
            item.save();
        }        
      }
    }
  </script>
  
<template>
  <v-row>
    <v-col lg="7" cols="12" class="pr-0">
      <s-crud title="Lista de Insumos Campo" :config="configInsumos" @save="save($event)" edit no-full add remove
        :filter="filter" search-input ref="crud" height="400" @rowSelected="selectedSupplie($event)">
        <template v-slot:filter>
          <v-col>
            <v-row justify="center">
              <v-col lg=4>
                <s-select-definition v-model="filter.TypeCultive" noDefault clearable :def="1325"
                  label="Tipo de Insumo" />
              </v-col>
            </v-row>
          </v-col>
        </template>

        <template scope="props">
          <v-container>
            <v-row justify="center">
              <v-col cols="2" class="s-col-form">
                <s-text v-model="props.item.LsCode" label="Código (SAP)"> </s-text>
              </v-col>
              <v-col cols="4" class="s-col-form">
                <s-select-definition :def="1325" label="Tipo Insumo"
                  v-model="props.item.TypeProduct"></s-select-definition>
              </v-col>
              <v-col cols="4" class="s-col-form">
                <s-text v-model="props.item.LsDescription" autofocus label="Descripción"> </s-text>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col lg="4" class="s-col-form" cols="12">
                <s-select-definition v-model="props.item.LsUnitID" id='DedValue' add :def="1206" label="Unidad">
                </s-select-definition>
              </v-col>
              <v-col lg=6 class="s-col-form"> 
                <s-text v-model=props.item.LsActiveIngredientName label="Ingrediente Activo" /> 
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col :lg="6" class="s-col-form">
                <s-text v-model="props.item.LsObservation" label="Observaciones"> </s-text>
              </v-col>
              <v-col class="s-col-form" cols=12 lg="4" v-show="props.item.TypeProduct != 1">
                <s-select-definition v-model="props.item.LsStatus" :def="1224" label="Estado"></s-select-definition>
              </v-col>
              <v-col class="s-col-form" cols=12 lg="4" v-show="props.item.TypeProduct == 1">
                <s-select-definition v-model="props.item.TypeApplication" :def="1473" label="Tipo Aplicacion"></s-select-definition>
              </v-col>
            </v-row>
          </v-container>

        </template>

        <template v-slot:LsStatusName="{ row }">
          <v-chip style="margin:0px" x-small :color="row.LsStatus == 1 ? 'info' : row.LsStatus == 2 ? 'success' : 'error'">
            <b> {{ row.LsStatusName }}</b>
          </v-chip>
        </template>
        <template v-slot:TypeProduct="{ row }">
          <v-chip style="margin:0px" x-small
            :color="row.TypeProduct == 1 ? 'warning' : row.TypeProduct == 2 ? 'default' : 'error'">
            <b> {{ row.TypeProductName }}</b>
          </v-chip>
        </template>
      </s-crud>
    </v-col>
    <v-col lg="5">
      <s-crud v-if="itemSelected" title="Plagas/Cultivos" :config="configDetail" @save="saveDetail($event)" edit add
        :filter="filterDetail" remove :height=440>
        <template scope="props">
          <v-container>
            <v-row justify="center">
              <v-col cols="5" class="s-col-form">
                <s-text v-model="props.item.LsDosisHa" autofocus label="Dosis/Ha"> </s-text>
              </v-col>
              <v-col lg="5" class="s-col-form" cols="12">
                <s-select-definition v-model="props.item.TypeCultive" noDefault clearable :def="1173" label="Cultivo">
                </s-select-definition>
              </v-col>


            </v-row>
            <v-row justify="center">
              <v-col lg="10" class="s-col-form" cols="12">
                <s-select-plague-cultive clearable v-model="props.item.PlID" :plID="props.item.TypeCultive"
                  label="Plaga" />
              </v-col>

            </v-row>
            <v-row justify="center">
              <v-col lg="3" class="s-col-form" cols="12">
                <s-text label="Cod Senasa" v-model="props.item.CodSenasa"></s-text>
              </v-col>
              <v-col lg="3" class="s-col-form" cols="12">
                <s-text label="Cod PPPL" v-model="props.item.CodPPL"></s-text>
              </v-col>
              <v-col :lg='4' class="s-col-form"> <s-text number v-model=props.item.LsDuration label="Duración (Dias)" />
              </v-col>

            </v-row>
          </v-container>

        </template>
      </s-crud>
    </v-col>
  </v-row>


</template>
<script>
import _sVarietyService from "@/services/Technicalassistance/ListSuppliesService.js";
import _sDetail from "@/services/Technicalassistance/ListSupplieDetail.js";
import SSelectOriginSupplies from '../../../components/TecnicalAssistence/ListSupplies/SSelectOriginSupplies.vue';
import SSelectPlagueCultive from '../../../components/TecnicalAssistence/Plagues/SSelectPlagueCultive.vue';
export default {
  name: "RequirementServiceSupplies",
  components: { SSelectOriginSupplies, SSelectPlagueCultive },
  props: {
    DedID: { type: Number, default: 0 },
    OriginID: { type: Number, default: 0 },
    parentID: { type: Number, default: 0 },
  },

  data() {
    return {
      itemSelected: null,
      filter: { TypeCultive: null },
      filterDetail: { LsID: 0 },
      dialogEdit: false,
      configDetail: {
        model: {
          DspID: 'ID'
        },
        headers: [//{text:'ID',value:'DspID'},
          { text: 'Cultivo', value: 'TypeCultiveName' },
          { text: 'Plaga', value: 'PlDescription' }, { text: 'Dosis', value: 'LsDosisHa' }],
        service: _sDetail
      }
    };
  },

  computed: {

    configInsumos() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          LsID: "ID",
          LsDescription: "string",
          DedDescription: "string",
          LsStatus: "",
          LsStatusName: "status",
          TypeProduct: 'int'
        },
        service: _sVarietyService,
        headers: this.$fun.getSecurity().IsLevelAdmin
          ? [
            //Vista de tabla para admin
            { text: "Tipo", value: "TypeProduct", sortable: false, width: 100 },
            // { text: "ID", value: "LsID", sortable: false,width:40 },
            { text: "Descripción", value: "LsDescription", sortable: false },
            //    { text: "Plaga", value: "Plaga", sortable: false },
            { text: "Unidad", value: "UnitName", sortable: false },
            //  { text: "Dosis", value: "LsDosisHa", sortable: false },              
            //  { text: "Tipo Insumo", value: "DedDescription", sortable: false }, 
            { text: "Tipo Aplication", value: "TypeApplicationName", sortable: false },
            { text: "Observaciones", value: "LsObservation", sortable: false },
            { text: "Estado", value: "LsStatusName", sortable: false, align: 'center' },
          ]
          : [
            // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
            { text: "ID", value: "LsID", sortable: false },
            { text: "Código", value: "LsCode", sortable: false },
            { text: "Descripción", value: "LsDescription", sortable: false },
            { text: "Plaga", value: "Plaga", sortable: false },
            { text: "Unidad", value: "UnitName", sortable: false },
            { text: "Dosis", value: "LsDosisHa", sortable: false },
            //     { text: "Tipo Insumo", value: "DedDescription", sortable: false }, 
            { text: "Estado", value: "LsStatusName", sortable: false, align: 'center' },
          ],
      };
    },

    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
    },
  },


  methods: {
    edit(item) {
      this.dialogEdit = true;

    },

    selectedSupplie(rows) {
      if (rows.length > 0) {
        this.filterDetail.LsID = rows[0].LsID;
        this.itemSelected = rows[0];
      }
    },
    saveDetail(item) {
      item.LsID = this.itemSelected.LsID;

      item.save();
    },
    save(item) {
      item.TypeSupplies = 1;

      if (this.$fun.getSecurity().IsLevelAdmin) {
        //VALIDACIONES
        if (item.LsDescription.length == 0) {
          this.$fun.alert("Registre la descripción", "warning");
          return;
        }

        item.SecStatus = 1;
        item.save();
      }
      else {

        if (item.LsDescription.length == 0) {
          this.$fun.alert("Registre la descripción", "warning");
          return;
        }
        if (item.DedID == 0) {
          this.$fun.alert("Seleccione el Cultivo", "warning");
          return;
        }
        item.SecStatus = 1;
        item.save();

      }


    },
  },
  watch: {
    // este DedID es el que esta en variety, vista general en DedID  ="dedID"
    DedID() {

      this.Initialize();
    },
  },
};
</script>

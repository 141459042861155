<template>
    <v-card>
        <v-card-title>
            <s-toolbar label="Listado de Reempaque"
                dark 
                close 
                @close="close()"
                color="primary">
            </s-toolbar>
        </v-card-title>        
        <v-card-text>
            <v-row>
                <v-col>
                    <v-data-table  
                        :items="itemsBagSealedDetail"
                        dense 
                        :headers="HeaderBagSealedDetail"
                        :items-per-page="-1"
                        disable-sort 
                        width="auto"
                        height="auto"
                        hide-default-footer>
                        <!-- <template v-slot:item.PloDescription=" row ">
                            <s-select label="Fajas"
                                v-model="row.item.PlpID"
                                :items="linesOutProcess"
                                item-value="PlpID"
                                item-text="PloDescription">

                            </s-select>
                        </template> -->
                        <template v-slot:item.typeLine=" row ">
                            <s-select-definition
                                label="Linea"
                                v-model="row.item.typeLine"
                                :def="1446"
                                >
                            </s-select-definition>
                        </template>
                        <template v-slot:item.CumNombre=" row ">
                            <s-select-client
                                clearable                               
                                :label="row.item.CumNombre"
                                v-model="row.item.ClientID"                               
                                ref="txtCumID"
                               
                                full>
                            </s-select-client>
                        </template>
                        <template v-slot:item.CbdBrans=" row ">
                            <s-select-brands  
                                :label="row.item.CbdBrans"                                              
                                clearable                                
                                v-model="row.item.BransID"
                                ref="SelectBrands"
                                :cumID="row.item.ClientID"
                                full>
                            </s-select-brands> 
                        </template>
                        <template v-slot:item.TotalBox=" row ">
                            <s-text 
                                v-model="row.item.TotalBox"
                                label="Cantidad"
                                type="number"
                                :min="1"
                                :max="row.item.TotalBoxOld"
                                @input="CalcularBoxWeight(row)">
                            </s-text> 
                        </template>
                        <template v-slot:item.TcpDescription=" row ">
                            <s-select
                                label="Tipo corte Empaque"
                                :items="TypesCutPackings"
                                item-value="TcpID"
                                item-text="TcpDescription"
                                v-model="row.item.TcpID"
                            ></s-select>
                        </template>
                        <template v-slot:item.TypePackingName=" row ">
                            <s-select-definition
                                label="Empaque"
                                v-model="row.item.TypePackingObject"
                                return-object
                                :def="1435"
                            ></s-select-definition>
                        </template>
                        <template v-slot:item.TypeProductionName=" row ">
                            <s-select-definition v-model="row.item.TypeProduction" 
                                label="T. Producción"
                                :def="1464" 
                                @input="changeTypeProduction($event)">
                            </s-select-definition>
                        </template>
                        <template v-slot:item.TypeVariantName=" row ">
                            <s-select v-model="row.item.TypeVariant" 
                                label="Variante"
                                :items="ListVariante"
                                item-value="TsfID"
                                item-text="TsfDescription"
                            ></s-select>
                        </template>
                        <template v-slot:item.StateFinalName=" row " >
                            <s-select v-model="row.item.StateFinal"
                                label="Estado Final"
                                item-value="TsfID"
                                item-text="TsfDescription"
                                :items="ListEstadoFinal">
                            </s-select>
                        </template>
                        <template v-slot:item.Period=" row ">
                            <s-select-definition
                                label="Periodo"
                                v-model="row.item.TypePeriod"
                                :def="1444"
                            ></s-select-definition>
                        </template>
                        <template v-slot:item.ReturnBox=" row ">
                            <v-checkbox v-model="row.item.ReturnBox">

                            </v-checkbox>
                        </template>
                        <template v-slot:item.accion=" row ">
                            <v-btn  fab color="primary"
                                    outlined 
                                    depressed
                                    x-small  
                                    @click="Save(row.item)"
                                    >
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.PackingLoss=" row ">
                            <v-btn  fab color="error"
                                    outlined 
                                    depressed
                                    x-small  
                                    @click="SavePackingLoss(row.item)"
                                    >
                                <v-icon>mdi-cup-off</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import _sendsealedbags from "@/services/FrozenProduction/FrzPackingSealedBags.js"; 
import _sFrzLinesPackingService from "@/services/FrozenProduction/FrzLinesPackingL.js"
import _sFrzPackingArmedBox from "@/services/FrozenProduction/FrzPackingArmedBox.js";
import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
import _typestatefinal from "@/services/FrozenProduction/FrzTypeStateFinal.js";  
import SSelectBrands from "@/components/FrozenProduction/Chamber/SSelectBrandChamber";
import _sFrzTypeCutPacking from '@/services/FrozenProduction/FrzTypeCutPackingService.js';
import _sFrzPackingLoss from '@/services/FrozenProduction/FrzPackingLoss.js';
export default {
    components: {SSelectClient, SSelectBrands},
    props:{
        itemTurn:{
            default: null,
            type: Object,
        }
    },
    data(){
        return {
            itemsBagSealedDetail:[],
            HeaderBagSealedDetail: [
                { text: "item", value:"Line"},
                { text: "Linea", value:"typeLine", width: 90},
                { text: "Cliente", value:"CumNombre", width: 160,},
                { text: "Marca", value:"CbdBrans", width: 160,},
                { text: "N° Cajas", value:"TotalBox", width: 90},
                { text: "Peso", value:"WeightNetBoxes", width: 90},
                { text: "Cultivo", value:"TypeCultiveName", width: 80},
                { text: "Variedad", value:"VrtDescription"},                
                // { text: "Faja", value:" PloDescription"},
                // { text: "T. Corte Acon.", value:"TypeCutNameAcondition"},
                { text: "T. Corte", value:"TcpDescription", width: 160,},
                { text: "ID Trazabilidad", value:"TraceabilityID"},
                { text: "Empaque", value:"TypePackingName", width: 160,},
                { text: "Congelamiento", value:"CecDescription"},
                // { text: "Bolsa por caja", value:"BoxBag"},
                { text: "Tipo Produccion", value:"TypeProductionName", width: 120},
                { text: "Variante", value:"TypeVariantName", width: 120},
                { text: "E. Final", value:"StateFinalName", width: 120},
                { text: "Observacion", value:"Observation"},
                { text: "Periodo", value:"Period", width: 90},
                { text: "Retornar", value:"ReturnBox", width: 90},
                { text: "Accion", value:"accion", width: 90},
                { text: "Merma", value:"PackingLoss", width: 90},
            ],
            linesOutProcess:[],
            ListVariante: [],
            ListEstadoFinal: [],
            TypeProduction: 1,
            TypesCutPackings: [],

        }
    },
    methods:{
        CalcularBoxWeight(row)
        {
            console.log(row);
            if(parseInt(row.item.TotalBox) > row.item.TotalBoxOld)
            {
                this.$fun.alert("Esta superando el numero de caja", "warning");
                row.item.TotalBox = row.item.TotalBoxOld;
                row.item.WeightNetBoxes = parseInt(row.item.TotalBox) * (row.item.Period.QuantityBag * row.item.Period.WeightPacking)
                return;
            }
            if(parseInt(row.item.TotalBox) > 0)
            {
                row.item.WeightNetBoxes = parseInt(row.item.TotalBox) * (parseFloat(row.item.TypePackingObject.DedFormat) * parseFloat(row.item.TypePackingObject.DedHelper))
                
            }else{
                row.item.WeightNetBoxes  = 0;
            }
           
        },
        TypeCutPacking(item)
        {
            // El tipo de corte de acondicionado es el tipocut del periodo
           
            if(item.Period != null){
           
                item.TypeCut = item.Period.TypeCut;
                _sFrzTypeCutPacking.list(item, this.$fun.getUserID()).then( (r) => {
                    if(r.status == 200)
                    {                    
                        this.TypesCutPackings = r.data;
                    }
                })
            }
        },
        changeTypeProduction(item)
        {             
            
            this.TypeProduction = item;
            this.ListVariante = [];
            this.ListEstadoFinal = [];
            this.GetListVariante();
            this.GetListEstadoFinal();
        
        },
        GetListVariante(){
            _typestatefinal.list({DedID : this.TypeProduction, Position : 1}, this.$fun.getUserID()).then( r => {
                if(r.status == 200)
                {
                    this.ListVariante = r.data;
                    this.ListVariante.forEach((element, index) => {
                        element.ID = index + 1;
                    });
                    
                }
            })
        },
        GetListEstadoFinal(){
            _typestatefinal.list({DedID : this.TypeProduction, Position : 2}, this.$fun.getUserID()).then( r => {
                if(r.status == 200)
                {
                    this.ListEstadoFinal = r.data;
                    this.ListEstadoFinal.forEach((element, index) => {
                        element.ID = index + 1;
                    });
                    
                }
            })
        },
        SearchCliente(row)
        {
            
        },
        close()
        {
            this.$emit("close");
        },
        Save(item){
            

            let listitem = [];
            let headitem = {};
            
            item.SecStatus = 1;
            item.PxdStatus = 1;
            
            if(item.ClientID == undefined) 
            {
                this.$fun.alert("Seleccione Cliente", "warning");
                return
            }
                
            item.CumID = item.ClientID;
            item.CbdID = item.BransID;
            //El TypeCut de empaque es el TcpID 
            item.TypeCut = item.TcpID;
            item.BoxBag = parseFloat(item.Period.QuantityBag);
            item.TotalBags  = parseFloat(item.Period.QuantityBag * item.TotalBox);
            item.BagWeight = item.Period.WeightPacking;
            item.isRepacking = 1;
            item.TypePacking = item.TypePackingObject.DedValue;
            if(item.ReturnBox)
            {
                item.ReturnBox = 1;
            }
            item.bagutilizadas = item.TotalBags;
            listitem.push(item);
           
            headitem.TotalBox = parseFloat(item.TotalBox);
            headitem.TotalBagWeight = parseFloat(item.WeightNetBoxes);
            headitem.CumID = item.CumID;
            headitem.boxDetails = listitem;
            headitem.TotalBags =  item.TotalBags;
            headitem.PipID = item.PipID;
            headitem.isRepacking = 1;
            this.$fun.alert("Estas seguro de guardar ?", "question")
                .then(res => {
                    if (res.value) {
                       
                        _sFrzPackingArmedBox.save(headitem, this.$fun.getUserID())
                        .then( r => {
                            if(r.status == 200)
                            {                                
                                this.$fun.alert("Se registro correctamente", "success");
                                this.initialize();  
                            }
                        });
                    }
                });  
        },
        SavePackingLoss(item)
        {
            
            item.WeightLost = item.WeightNetBoxes;
            item.UsrCreateID = this.$fun.getUserID();
            this.$fun.alert("Estas seguro de guardar Kg para Merma?", "question")
                .then(res => {
                    if (res.value) {                       
                        _sFrzPackingLoss.save(item, this.$fun.getUserID())
                        .then( r => {
                            if(r.status == 200)
                            {                                
                                this.$fun.alert("Se registro correctamente", "success");
                                this.initialize();  
                            }
                        });
                    }
                });  
        },
        initialize(){
            _sendsealedbags.listreempaque({}, this.$fun.getUserID()).then(r => {
                if(r.status == 200)
                {
                    r.data.forEach((element, index) => {
                        element.Line = index + 1; 
                        element.TcpID = element.TypeCut;
                        element.TotalBoxOld = element.TotalBox;                     
                        this.TypeCutPacking(element);                       
                    });
                    this.itemsBagSealedDetail = r.data;  
                    
                   
                    this.LinesOutProcess();    
                    
                }
            })
        },
        LinesOutProcess()
        {      
            
            let NtpId = this.$fun.isAdmin() ? null : this.$fun.getUserInfo().NtpID;
            let data = {
                TrnID : this.itemTurn.TrnID,
                NtpId : NtpId,
            }
            
            _sFrzLinesPackingService.listLineas(data, this.$fun.getUserID())
            .then(r => {
                if(r.status == 200){
                    this.linesOutProcess = r.data;                                   
                }
            });
        },  
        
    },
    mounted()
    {        
        this.initialize();        
    },
    updated()
    {
        this.itemsBagSealedDetail.forEach(item => {
            item.ClientID = item.CumID;
           
            console.log(item);
        })
    }
}
</script>

<template>
    <v-card>
        <v-card-title>   
            <v-row>
                <v-col>
                    <h3>Lineas</h3>
                </v-col>
            </v-row>        
        </v-card-title>
        <v-card-text>
            <div hidden>
                <vue-qr-generator ref="qrGenerator" :text="QrData.toString()" :size="200"></vue-qr-generator>
            </div>
            <v-row>
                <v-col>               
                    <s-crud title="Numero de jabas a Retornar"
                        :config="config"
                        edit
                        add
                        remove
                        height="auto"                   
                        @save="save($event)"                  
                        :filter="filter"
                        search-input
                        :singleRow="false"     
                        @rowSelected="rowSelected($event)"     
                        ref="TableSelection"
                        no-full
                    > 
                        <template  v-slot:QuantityJaba="{ row }">                            
                            <s-text  type="number" v-model="row.QuantityJaba"  @input="UpdateWeight(row)"  :max="row.QuantityJabaOld" :min="0"></s-text>                           
                        </template>
                        <template slot-scope="props">
                            <v-container>
                                <v-row>                                    
                                    <v-col>
                                        <s-select
                                                label="Turno de Produccion"
                                                :items="itemTurn"
                                                item-value="TrnID"
                                                item-text="TnrObservation"
                                                v-model="props.item.TrnID"
                                                @input="initialize($event, props.item)"
                                            >  
                                            </s-select>
                                    </v-col>
                                    <v-col>
                                        <s-select-definition
                                            label="Cultivo" 
                                            :def="1173"
                                            v-model="props.item.TypeCultive"
                                            @change="initialize($event, props.item)"
                                        ></s-select-definition>
                                    </v-col>
                                    <v-col>
                                        <s-select 
                                            label="Linea de Produccion"
                                            :items="itemsLineas"
                                            item-value="ClpID"
                                            item-text="LncDescription"
                                            v-model="props.item.ClpID"
                                            @input="getDataLine($event, props.item)">

                                        </s-select>
                                    </v-col>
                                    <v-col>
                                        <s-select-definition
                                            :def="1440"
                                            label="Tipo de descarte"
                                            v-model="props.item.FsgTypeDiscart"
                                            clearable
                                        >
                                        </s-select-definition>
                                    </v-col>
                                    <v-col>
                                        <s-text label="Numero de Jabas" 
                                            type="number"                                            
                                            v-model="props.item.QuantityJaba" :min="0"></s-text>
                                    </v-col>
                                    <v-col>
                                        <s-text label="Peso Total Jabas" type="number" v-model="props.item.WeightJaba" :min="0"></s-text>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>          
                    </s-crud>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <s-toolbar dark :color="'#BAB6B5'" label="Armado de Pallet" save @save="SavePalletReturn()"></s-toolbar>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <s-select-definition
                        :def="1363"
                        label="Tipo de parihuela"
                        v-model="SelectionGreen.TypePallet"
                        clearable
                    />
                </v-col>
                <v-col>
                    <s-select-definition
                        label="Calibre"
                        :def="1326"
                        v-model="SelectionGreen.TypeFrozenCategory"
                        clearable
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>                    
                    <s-text label="Tipo Cultivo" disabled v-model="SelectionGreen.TypeCultiveName"></s-text>
                </v-col>
                <v-col>                    
                    <s-text label="Total de Cajas" disabled type="number" :min="0" v-model="SelectionGreen.QuantitysJaba"></s-text>
                </v-col>
                <v-col>
                    <s-text label="Peso Total" disabled decimal :min="0" v-model="SelectionGreen.WeightsJaba"></s-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <s-toolbar dark color="succes" label="Pallets Creado" ></s-toolbar>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="3" lg="3">
                    <s-date
                        label="Fecha Inicio"
                        v-model="filter.DateBegin"
                        @input="getSelectionGreenPallet()"
                    ></s-date>
                </v-col>
                <v-col cols="6" md="3" lg="3">
                    <s-date
                        label="Fecha Fin"
                        v-model="filter.DateEnd"
                        @input="getSelectionGreenPallet()"
                    ></s-date>
                </v-col>
                <v-col>
                    <s-select :items="LocationPallet"
                        label="Ubicacion"
                        item-value="FspStatus"
                        item-text="FspLocation"
                        v-model="filter.FspStatus"
                        @input="getSelectionGreenPallet()">                        
                    </s-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        :items="SelectionGreenPallets"
                        :headers="headersPallet"
                        dense>
                        <template v-slot:item.FspLocation="{item}">
                            <v-chip style="margin:0px" x-small :color="item.FspStatus == 1 ? 'success' : 'warning'">
                                {{ item.FspStatus == 1 ? "Acondicionado" :  "En Maduracion"}}
                            </v-chip>
                        </template>
                        <template v-slot:item.Send="{item}">
                            <v-btn
								x-small
								fab
                                icon
								color="#5edf87"
                                v-if="item.FspStatus == 1"
                                :disabled="false"
                                 @click="sendPalletMaturation(item)"
								>
								<v-icon
                                    >
                                    mdi-send
                                </v-icon>
							</v-btn>

                            <v-btn
								x-small
								fab
                                icon
								color="#BAB6B5"
                                v-if="item.FspStatus == 2"
                                :disabled="false"
                                 @click="PrintPalletQR(item)"
								>
								<v-icon
                                    >
                                    mdi-printer
                                </v-icon>
							</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import _sTurnService from "@/services/FreshProduction/PrfTurnService.js"; // SERVICIO DEL TURNO
import _sFrzSelectionGreen from "@/services/FrozenProduction/FrzSelectionGreen";
import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"

import _sFrzCtnPalletsDiscartLot from '@/services/FrozenProduction/FrzCtnPalletsDiscardLot';

import _sFrzSelectionGreenPallet from "@/services/FrozenProduction/FrzSelectionGreenPallet.js";
import VueQrGenerator  from "vue-qr-generator";

export default 
{
    components: { VueQrGenerator },
    data(){
        return {
            itemTurn: [],
            itemsLineas:[],
            item:{
                TrnID: 0,
                TypeCultive: 0
            },
            config:{
                model:{
                    FsgID: "ID",
                    QuantityJaba: 0
                },
                service: _sFrzSelectionGreen,
                headers: [
                    { text: "ID", value: "FsgID", sortable: false },
                    { text: "Cultivo", value: "TypeCultiveName", sortable: false },
                    { text: "Variedad", value: "VrtDescription", sortable: false },
                    { text: "Cantidad de Jabas", value: "QuantityJaba", sortable: false, width:150},
                    { text: "Peso", value: "WeightJaba", sortable: false },
                    // { text: "Selecion", value: "TypeSelectionFrozenName", sortable: false },
                    // { text: "Categoria", value: "TypeFrozenCategoryName", sortable: false },
                    { text: "Devolucion", value: "FsgTypeDiscartName", sortable: false },
                ]
            },
            filter:{},
            DataLine: {},
            SelectionGreen:{
                QuantitysJaba: 0,
                WeightsJaba : 0,
                TypeCultive: 0,
                TypeCultiveName: ''
            },
            SelectionGreenPallets: [],
            headersPallet: [
                { text: "ID", value: "FspID", sortable: false },
                { text: "PalletID", value: "LlpID", sortable: false },
                { text: "Cultivo", value: "TypeCultiveName", sortable: false },
                { text: "Variedad", value: "VrtDescription", sortable: false },
                { text: "Cantidad de Jabas", value: "QuantitysJaba", sortable: false },
                { text: "Peso Neto", value: "WeightsJaba", sortable: false },
                { text: "Peso Bruto", value: "WeightPalletGross", sortable: false },
                // { text: "Selecion", value: "TypeSelectionFrozenName", sortable: false },
                { text: "Calibre", value: "TypeFrozenCategoryName", sortable: false },
                { text: "Devolucion", value: "FsgTypeDiscartName", sortable: false },
                { text: "Destino", value: "FspLocation", sortable: false },
                { text: "Enviar", value: "Send"},
            ],
            formSubmitted: false,
            SelectJabas: [],
            LocationPallet: [
                { FspStatus: 1, FspLocation: 'ACONDICIONADO'},
                { FspStatus: 2, FspLocation: 'MADURACION'}
            ],
            
            itemsDetail: [],
            QrData: String,
            DateBegin: Date,
            DateEnd: Date,
            filter:{ 
                FspStatus: 1,
            },
            formSubmitted: false,
            ArmedPalletDetail: []
        }
    },
    computed:{
        QuantitysJaba(){
            return QuantitysJaba;
        }
    },
    methods:{
        UpdateWeight(item){
            if(parseInt(item.QuantityJaba) > item.QuantityJabaOld)
            {
                item.QuantityJaba = item.QuantityJabaOld;
                this.$fun.alert("El numero de cajas mayor que " + item.QuantityJabaOld, "error");
            }
            else{
                let weightUnit = item.WeightJabaOld / item.QuantityJabaOld;
                console.log(weightUnit);
                item.WeightJaba = weightUnit * item.QuantityJaba;
            }
        },
        sendPalletMaturation(item)
        {
            if(this.formSubmitted) return;
            this.formSubmitted = true;
            this.$fun.alert("Esta seguro de enviar Pallet a Maduracion?", "question").then((r) => {
                if (r.value) {
                
                    _sFrzSelectionGreenPallet.sendPalletMaturation(item, this.$fun.getUserID()).then(r => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success"); 
                            this.formSubmitted = false;   
                            this.getSelectionGreenPallet();              
                        }
                    });
                }
            });
        },
        getDataLine(value, item){            
            _sFrzCtnLinesProcessService.getDataLine(item, this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    this.DataLine = r.data;    
                                  
                }
            })
        },
        TurnActive(){
            let TypeArea = 4
            _sTurnService.turnActive({TypeArea : TypeArea}, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemTurn = r.data;                           
                        this.item.TrnID = this.itemTurn[0].TrnID;                         
                    }
                });      
        },
      
        initialize(value, item){
            this.itemsLineas = [];
            
            
            if ( this.item.TrnID == null || this.item.TypeCultive == null) {
                return;
            }
            
            let ntpId = this.$fun.isAdmin() ? null : this.$fun.getUserInfo().NtpID;

            item.NtpID = ntpId; 

            _sFrzCtnLinesProcessService.listLineas(item, this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    this.itemsLineas = r.data;
                   
                }
            })
        },
        save(item){
            
            if(item.ClpID == undefined)
            {
                this.$fun.alert("Ingrese numero de Jabas", "warning");     
                return;
            }            
            if(item.FsgTypeDiscart == undefined)
            {
                this.$fun.alert("Seleccione tipo de descarte", "warning");     
                return;
            }
            if(item.QuantityJaba == "" || item.QuantityJaba == 0)
            {
                this.$fun.alert("Ingrese numero de Jabas", "warning");     
                return;
            }
            if(item.WeightJaba == "" || item.WeightJaba == 0)
            {
                this.$fun.alert("Ingrese numero de Jabas", "warning");     
                return;
            }

            if(this.formSubmitted) return;
            this.formSubmitted = true;
            item.TypeSelectionFrozen = this.DataLine.TypeSelectionFrozen;
            item.TypeFrozenCategory = this.DataLine.TypeFrozenCategory; 
            item.TypeCrop = this.DataLine.TypeCrop; 
            item.VrtID = this.DataLine.VrtID;
            item.RcfID = this.DataLine.RcfID; 
            item.save();
            this.formSubmitted = false;
        },
        rowSelected(rows) {
            if(rows.length > 1)
            {
                let FsgTypeDiscart = rows[0].FsgTypeDiscart;
                rows.forEach(r => {
                    if(FsgTypeDiscart != r.FsgTypeDiscart)
                    {
                        this.$fun.alert("Esta intentado crear un Pallet con diferente tipo de devolucion", "warning");                     
                        return;
                    }
                });
            }
			this.SelectionGreen.QuantitysJaba = 0;
            this.SelectionGreen.WeightsJaba = 0;
            let FsgIDs = [];
            let RcfIDs = [];
            rows.forEach(item => {
                this.SelectionGreen.QuantitysJaba += parseInt(item.QuantityJaba);
                this.SelectionGreen.WeightsJaba += item.WeightJaba;
                FsgIDs.push(item.FsgID);
                RcfIDs.push(item.RcfID);
            });
            if(rows.length > 0)
            {
                this.SelectionGreen.TypeCultive = rows[0].TypeCultive;
                this.SelectionGreen.TypeCultiveName = rows[0].TypeCultiveName;
                this.SelectionGreen.FsgIDs = FsgIDs.join(",");
                this.SelectionGreen.RcfIDs = RcfIDs.join(",");         
                this.SelectionGreen.FsgTypeDiscart = rows[0].FsgTypeDiscart;  
                this.SelectionGreen.TypeSelectionFrozen = rows[0].TypeSelectionFrozen;   
                this.SelectionGreen.TypeCrop = rows[0].TypeCrop; 
                this.SelectionGreen.VrtID = rows[0].VrtID;   
                this.SelectionGreen.TrnID = rows[0].TrnID;          
                
                this.SelectJabas = rows;
            }
            this.SelectionGreen.UsrCreateID = this.$fun.getUserID();      
            console.log(rows);     
            this.ArmedPalletDetail = rows;
		},
        SavePalletReturn()
        {
            //obteniendo el RsfID representativo
            this.SelectJabas.sort((a,b) =>
                b.QuantityJaba - a.QuantityJaba
            );
           
            this.SelectionGreen.RcfID = this.SelectJabas[0].RcfID; 
            if(this.SelectionGreen.QuantitysJaba > 48)
            {
                this.$fun.alert("Excede el limite de Jabas", "warning");     
                return;
            }
            if(this.SelectionGreen.QuantitysJaba == 0)
            {
                this.$fun.alert("No hay jabas para crear pallet", "warning");     
                return;
            }
            if(this.SelectionGreen.TypePallet == undefined)
            {
                this.$fun.alert("Seleccione el tipo de Pallet", "warning");                     
                return;
            }
            if(this.SelectionGreen.TypeFrozenCategory == undefined)
            {
                this.$fun.alert("Seleccione la categoria", "warning");                     
                return;
            }
            
            this.$fun.alert("Esta seguro de guardar?", "question").then((r) => {
            if (r.value) {               
                _sFrzSelectionGreenPallet
                .save(this.SelectionGreen, this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {
                        _sFrzSelectionGreenPallet.updateQuantitysJaba(this.ArmedPalletDetail, this.$fun.getUserID())
                        .then((r) => { 
                                  
                            this.$refs.TableSelection.refresh();    
                            this.getSelectionGreenPallet();
                            this.$fun.alert("Registrado correctamente", "success");     
                        });
                       
                    }
                });
                }
            });
        },
        getSelectionGreenPallet()
        {            
            _sFrzSelectionGreenPallet.getSelectionPallet(this.filter, this.$fun.getUserID()).then((r) => 
            {
                if (r.status == 200) 
                {
                    this.SelectionGreenPallets = r.data;
                }
            });            
        },
        PrintPalletQR(item)
        {            
            _sFrzSelectionGreenPallet.GetDetailQR(item.LlpID , this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                    this.itemsDetail = resp.data;
                    console.log(this.itemsDetail);
                    this.QrData = item.LlpID.toString();                    
                    this.printQR();
                }
            });
        },
        printQR() {
				var imgAll = document.querySelector("img");
				console.log('imgAll ', imgAll);
				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  body{font-family: arial;} table, th, td {border: 1px solid black;border-collapse: collapse;padding:2px;font-size:9pt;}" + 
						"  @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);
				
				/* imgAll.forEach(element => { */
					pwa.document.write(
						"<div class='col col-4' style='margin: 20px 20px 20px 20px; position:absolute;'><img alt='Scan me!' style='display: block;'  src='" +
							imgAll.currentSrc +
							"' />",
					);
					pwa.document.write("<b style='font-size: 50px; z-index: 2;transform: rotateZ(0deg);margin-top: 5px;position: absolute;width: 133px;border-radius: 20px;'>" + 
				((this.itemsDetail[0].TypeSelectionFrozenName === null) ? 'DESCARTE' : this.itemsDetail[0].TypeSelectionFrozenName ) + "</b>"); 


					pwa.document.write("</div><div style='margin: 30px 0px 0px 410px;'><table colspan='1' border='1'>");
					pwa.document.write(
						"<tr><td colspan='2' style='text-align:center;'><b>Sunshine</b></td>" + 
								// "<td align='center'><b> " + this.selected[0].TypeCultiveName + "-" + this.selected[0].VrtName +
								// " <br> "  + this.selected[0].TypeCropName +
								// " </b></td>" + 
								"</tr>"+
								 "<tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AP-FR-213<br>Paleticket de identificación de pallet</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VERSION:04" +
								"<br>F. creación:20/09/2021" +
								"<br>F. última revisión:18/09/2023</td></tr>" +		

							"<tr><td>Materia Prima</td><td>" + this.itemsDetail[0].TypeCultiveName + "</td></tr>"+
							"<tr><td>Variedad</td><td>" + this.itemsDetail[0].VrtName + "</td></tr>"+
							"<tr><td>Tipo de Cultivo</td><td>" + this.itemsDetail[0].TypeCropName + "</td></tr>"+
							"<tr><td>N° Pallet</td><td>" + this.itemsDetail[0].LlpCorrelative + "</td></tr>" +
							"<tr><td>Peso Calibrado (Kg)</td><td>" + this.itemsDetail[0].LlpWeightOriginalNet + " KG.</td></tr>"+
							"<tr><td>Estado de Madurez</td><td></td></tr>" +
							"<tr><td>Tamaño</td><td>" + this.itemsDetail[0].TypeFrozenCategoryName + " </td></tr>" + 
							"<tr><td>Tipo Selección</td><td>" +  	
							((this.itemsDetail[0].TypeSelectionFrozenName === null) ? 'DESCARTE ' : this.itemsDetail[0].TypeSelectionFrozenName) + "</td></tr>" +
										
							"<tr><td>ID pallet sunexpert</td><td>" + this.QrData + "</td></tr>" +			
							"<tr><td>°Brix de Recepcion</td><td>" + "" + "</td></tr>" +			
							
							// "<tr><td>Turno</td><td>RECEPCION|MADURACION</td></tr>" +
							"<tr><td>%LMF</td><td></td></tr>" +

							// "<tr><td>Hora</td><td>"+ (new Date(this.selected[0].SecCreate).getHours()) + ":"+
							// 	""+ (new Date(this.selected[0].SecCreate).getMinutes()) + "</td></tr>" +
							"<tr><td>TUNNEL-SEMANA-BATCH</td><td></td></tr>" +
							"<tr><td>Turno</td><td></td></tr>" +
							"<tr><td>Fecha a.Proceso</td><td></td></tr>" +
							"<tr><td>Peso a Proceso</td><td></td></tr>" +
							"<tr><td>Tipo de Proceso</td><td></td></tr>" +
							"<tr><td>N° de dias en planta</td><td></td></tr>" +
							"<tr><td>Tipo de Desinfección</td><td>I&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;| II&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;| III</td></tr>"
					);
					pwa.document.write("</table></div>");

					// this.itemsDetail.forEach(detail => {
							
					// 		pwa.document.write("<div style='margin: 30px 0px 0px 410px;' ><table border='1'>");

					// 		pwa.document.write(
					// 			"<tr><td>Lote de Materia Prima: </td><td>" + detail.PrfYuliano + "</td></tr>" +
					// 			"<tr><td>N° de Jabas: </td><td>" + detail.LlpQuantityJaba +"</td></tr>"
					// 		);

					// 		pwa.document.write(
					// 			// "<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
					// 			/* "<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + "</td></tr>" + */
					// 			"<tr><td>Tipo de Proveedor: </td><td>" + detail.ZonName + " - " + detail.TypeZonLocationName + "</td></tr>"
					// 		);

					// 		// pwa.document.write(
					// 		// 	" </td><td> Peso Neto: </td><td>" +
					// 		// 	detail.WpbWeightNet +
					// 		// 	" </td></tr>",
					// 		// );

					// 		pwa.document.write(
					// 			// "<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>" +
					// 			"<tr><td>Fecha Recepción</td><td>" + detail.RcfDateReception + "</td></tr>" +
					// 			"<tr><td>Fecha Calibrado</td><td>" + detail.RcfDateCalibrated + "</td></tr>" 
								 
					// 		);

					// 		if (this.selected[0].TypeCultiveName == "PALTO") {
					// 			pwa.document.write(								 
					// 				"<tr><td>% De Materia Seca</td><td>" + detail.DryMaterial + "</td></tr>"  
					// 			);
					// 		}

					// 		pwa.document.write("</table></div>");
					// 	});

				/* }); */

				
				pwa.document.write("</div></body></html>");
				pwa.document.close();
			},

    },
    created(){       
		
        this.TurnActive();   
        this.getSelectionGreenPallet();
    }
}
</script>
<template>
  <div>
    <s-text
      :placeholder="placeholder"
      :label="label"
      :readonly="readonly"
      @click="
        filterCrud.ArtName = '';
        dialogArticle = true;
      "
      :clearable="clearable"
      search-icon
      v-model="textDescription"
    />

    <v-dialog
      width="80%"
      persistent
      v-model="dialogArticle"
      v-if="dialogArticle"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card style="overflow: hidden !important">
        <s-toolbar
          label="Articulos"
          close
          dark
          class="primary"
          @close="dialogArticle = false"
          :add="selectedItem != null"
          @add="doubleClick(selectedItem)"
          excel
          @excel="excel()"
        ></s-toolbar>

        <v-col>
          <v-row>
            <v-col>
              <s-crud
                noToolbar
                @rowSelected="selectedRow($event)"
                title="Artículos "
                height="350"
                @doubleClick="doubleClick($event)"
                :config="configCrud"
                no-search
                :filter="filterCrud"
                sortable
                btnsearch
                ref="gridArticle"
              >
                <template v-slot:filter>
                  <v-container style="margin-left: 0px">
                    <v-row>
                      <v-col class="" style="" lg="4">
                        <s-text
                          search
                          clearable
                          label="Nombre"
                          autofocus
                          v-model="filterCrud.ArtName"
                          @keyupEnter="keyupEnter($event)"                          
                        ></s-text>
                      </v-col>

                      <v-col style="" class="" lg="2">
                        <s-text
                          label="Código"
                          search
                          clearable
                          v-model="filterCrud.ArtCode"
                          @keyupEnter="keyupEnter($event)"
                        ></s-text>
                      </v-col>
                      <v-col cols="2">
                        <v-btn style="margin-top: 15px; margin-left: -10px;"
                          class="ma-2"
                          color="primary"
                          dark
                          @click="searchiArticle()"
                        >
                          Buscar
                        <v-icon dark right> mdi-magnify </v-icon>                                                
                        </v-btn>
                        </v-col>
                    </v-row>
                  </v-container>
                </template>
              </s-crud>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _sLocations from "../../../services/Logistics/ConsultingSap/LocationsService";
export default {
  props: {
    value: null,
    readonly: { default: false },
    clearable: { default: false },
    label: { default: "Descripción de Artículo" },
    placeholder: { default: "" },
  },
  data() {
    return {
      selectedItem: null,
      article: null,
      textDescription: "",
      dialogArticle: false,
      parametersPagination: {
        filter: { WhsCode: "01", SecStatus: 1 },
        draw: 1,
        start: 0,
        length: 10,
        order: [{ dir: "asc", column: "" }],
        searchText: "",
      },
      filter:{},
      filterCrud: {
        ArtCode: "",
        ArtName: "",
        WhsName: "",
        WhsCode: "",
      },
      configCrud: {
        model: {
          ArtCode: "ID",
          ArtName: "string",
          SecStatus: "status",
        },

        service: _sLocations,
        headers: [
          { text: "Código", value: "ArtCode", sortable: true },
          { text: "Descripción Artículo", value: "ArtName", sortable: true },
          {
            text: "Und.Medida",
            value: "UnitOfMeasuarement",
            sortable: true,
            aling: "center",
          },
          { text: "Stock", value: "ArtStock", sortable: true, align: "right" },
          {
            text: "Mnd. C.",
            value: "LastPurCurrency",
            sortable: true,
            align: "right",
          },
          {
            text: "Ultimo Precio C.",
            value: "LastPurPrice",
            sortable: true,
            align: "right",
          },
        ],
      },
    };
  },
  methods: {
    selectedRow(items) {
      if (items) if (items.length > 0) this.selectedItem = items[0];
    },
    doubleClick(item) {
      this.article = item;
      this.$emit("input", item);
      this.$emit("selected", item);
      this.textDescription = item.ArtName;

      this.dialogArticle = false;
    },
    excel() {
      _sLocations.excel(this.parametersPagination, this.$fun.getUserID());

      _sLocations
        .excel(this.parametersPagination, this.$fun.getUserID(), this.row)
        .then((r) => {
          this.$fun.downloadFile(
            r.data,
            this.$const.TypeFile.EXCEL,
            "REPORTE_ARTICULOS"
          );
        });
    },
    searchiArticle(){
      let mess = "Mínimo de 3 caracteres en el nombre o código para hacer la búsqueda";

      // Validamos si ArtCode o ArtName tienen contenido antes de proceder
      let hasArtCode = this.filterCrud.ArtCode && this.filterCrud.ArtCode.trim() !== "";
      let hasArtName = this.filterCrud.ArtName && this.filterCrud.ArtName.trim() !== "";
      if (hasArtCode || hasArtName) {
        // Validamos la longitud solo si tienen contenido
        if ((hasArtCode && this.filterCrud.ArtCode.length >= 3) || 
            (hasArtName && this.filterCrud.ArtName.length >= 3)) {
          this.$refs.gridArticle.searchBtn();
        } else {
          this.$fun.alert(mess);
        }
      } else {
        this.$fun.alert(mess);
      }
    },
    keyupEnter(){
      this.searchiArticle();
    }
  },
  created() {
    this.article = this.value;
    if (this.article != null) {
      this.textDescription = this.article.ArtName;
    }
  },
  watch: {
    value() {
      this.textDescription = this.value == null ? "" : this.value.ArtName;
      this.article = this.value;
    },
  },
};
</script>

import { default } from './VisitsG.vue';
import AllowanceDocument from '@/views/HumanResource/HouseHoldAllowance/AllowanceDocument';
<template>
    <v-card>
        <v-row>
            <v-col>                  
                <s-crud
                    title="Applicaciones"
                    :config="config"
                    edit
                    add 
                    @addEvent="addRecom()"                  
                    @save="save($event)"                
                    remove
                    :filter="filter"
                    search-input
                    height="170"
                    ref="crudVist"
                    @rowSelected="rowSelected($event)" >            
                    <template slot-scope="props">
                        <v-card>
                            <v-container>
                            <v-col>
                                <v-row>
                                <h2>Nueva Aplicación</h2>
                                </v-row>
                            </v-col>
                            <v-row>
                                <v-col>
                                <v-divider></v-divider>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col lg="12">
                                <v-row justify="center">
                                    <v-col lg="4" class="pt-0 pb-0" cols="12">
                                     
                                        <!--Observacion-->
                                        <s-textarea v-if="PpaObs"
                                        label="Observacion"
                                        ref="PpaObservation"
                                        v-model="PpaObservation"/>
                                  

                                    <!-- componente a verificar -->
                                    <s-select-listsupplies v-if="!PpaObs"
                                        add
                                        label="Producto"
                                        ref="objProductApplication"
                                        return-object
                                        :PlID="0"
                                        :TypeCultive="Vst.TypeCultive"
                                        v-model="objProductApplication"
                                        @input="inputProduct($event)"
                                        />
                                    <!-- MODIFICAR COMPONENTE -->
                                    </v-col>
                                    <v-col lg="3" class="pt-0 pb-0" cols="12">
                                    <s-select-definition
                                        label="Tipo"
                                        return-object
                                        v-model="objTypeAplicationDetail"
                                        :def="1360">

                                    </s-select-definition>
                                    </v-col>
                                    <v-col lg="2" class="pt-0 pb-0" cols="12">
                                    <s-text
                                        label="Dosis"
                                        ref="ppaDose"
                                        decimal
                                        v-model="ppaDose"/>
                                    </v-col>
                                    <v-col lg="2" class="pt-0 pb-0" cols="12">
                                    <s-text
                                        label="Cantidad total"
                                        ref="refAmountTotal"
                                        decimal
                                        v-model="AmountTotal">
                                    </s-text>
                                    </v-col>

                                    <v-col lg="1" class="pt-0" cols="12">
                                    <v-btn
                                        fab
                                        class="mt-2"
                                        color="success"
                                        rounded
                                        @click="addProduct()"
                                        small
                                        block>
                                        <v-icon height="20" style="font-size: 14px !important"
                                        >fas fa-plus</v-icon>
                                        </v-btn
                                    >
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col lg="4" class="pt-0 pb-0" cols="12">
                                        <v-switch
                                                label="Observacion"
                                                v-model="PpaObs" color="primary"
                                                @click ="saveswitchv(PpaObs)"
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                    <v-data-table
                                        :items="itemsAppl"
                                        :headers="[
                                        { text: '',                 value: 'Line' },
                                        { text: 'Producto',         value: 'LsName' },
                                        { text: 'Observaciones',    value: 'PpaObservation'},
                                        { text: 'Tipo',             value: 'TypeApplicationName' },
                                        { text: 'Dosis',            value: 'PpaDose' },
                                        { text: 'Cantidad',         value: 'AmountTotal' },
                                        {
                                            text: 'Acciones',
                                            value: 'Actions',
                                            align: 'center',
                                            width: '90',
                                            sortable: false,
                                        },
                                        ]"
                                        dense
                                        :items-per-page="5">
                                        <template v-slot:item.Actions="{ item }">
                                        <v-btn
                                            @click="clickDeleteApplication(item)"
                                            x-small
                                            color="error"
                                            elevation="0"
                                            block>
                                            <v-icon style="font-size: 16px !important"
                                            >fas fa-times</v-icon
                                            >
                                        </v-btn>
                                        </template>
                                    </v-data-table>
                                    </v-col>
                                </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                <v-row>
                                    
                                    <v-col lg="6" class="pt-0">
                                    <s-select-definition
                                        v-model="props.item.TypeApplication"  
                                        clearable 
                                        :def="1361" 
                                        label="Tipo Aplicación">

                                    </s-select-definition>
                                    </v-col>
                                    <v-col lg="6" class="pt-0">
                                    <s-select-definition
                                        v-if="props.item.TypeApplication==2"
                                        v-model="props.item.TypeFocused"  
                                        :def="1515" 
                                        label="Focalizada">
                                    </s-select-definition>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col lg="6" class="pt-0">
                                    <s-date label="Fecha Inicio" v-model="props.item.PapDate"> </s-date>
                                    </v-col>
                                    <v-col lg="6" class="pt-0">
                                    <s-date label="Fecha Fin" v-model="props.item.PapDateEnd"> </s-date>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col lg="6" class="pt-0"  v-if="props.item.TypeApplication==1">
                                    <s-text
                                        number
                                        v-model="props.item.PapLiterWater"
                                        ref="refPapLiterWater"
                                        label="Litros de Agua"/>
                                    </v-col>
                                    <v-col lg="6" class="pt-0">
                                    <s-text
                                    Decimal
                                    label="Gasto combustible (Gal)"
                                    ref="refPapFuelExpense"
                                    v-model="props.item.PapFuelExpense">

                                    </s-text>
                                    </v-col>
                                </v-row>
                                 
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card>
   
                            
                    </template>
                </s-crud>
      
            </v-col>
        </v-row>
    </v-card>
</template>

<script  >

import _visitApplication from "@/services/Technicalassistance/Visit/VisitApplicationsService.js";
import sSelectListsupplies from "@/components/TecnicalAssistence/ListSupplies/SSelectListsupplies";


    export default {
        components: { 
            sSelectListsupplies 
        },
        props: {
            Vst: { 
                type: Object, 
                default: 0 
            },
        },
        watch: {
            Vst(){
                this.filter.VstID = this.Vst.VstID;
                console.log("this.Vst.", this.Vst);

                // this.itemApp.TasVisitProductApplications = [];
                this.itemsAppl = [];
                this.itemsDeleteAppl= [];
            }
        },
        data() {
            return {
                ByFocused:null,
                filter: { 
                    VstID: null,               
                },
                config: {
                    model: {
                        PapID: "ID"
                    },
                    service: _visitApplication,
                    headers: [
                        { text: '', value: 'Line' },
                        { text: 'Aplicaciones Fitosanitarias y Fertilizantes Realizadas', value: 'TasProductsApplicationName'},
                        { text : 'Observaciones' , value: 'PpaObservation'},
                        { text: 'Fecha Inicio', value: 'PapDate' },
                        { text: 'Fecha Fin', value: 'PapDateEnd' },
                        { text: 'Tipo aplicación', value: 'TypeApplicationName' },
                        { text: 'Tipo Focalizada', value: 'TypeFocusedName' },
                        { text: 'Gasto combustible', value: 'PapFuelExpense', width: '50' },
                        { text: 'Acciones', value: 'Actions', align: 'center', width: '90',sortable: false},    
                    ],
                },
                objProductApplication: {
                },
                objTypeAplicationDetail: {             
                },
                itemApp: {
                    TasVisitProductApplications: []
                },
                ppaDose: "",
                AmountTotal: "",
                objTypeUnit: null,
                itemsAppl : [],
                PpaObservation: null,
                PpaObs: false,
                itemsDeleteAppl: [],
            };
        },
        mounted(){
            //  console.log("mounted aplications")
            this.filter.VstID = this.Vst.VstID;
        },
        methods: {
            clickDeleteApplication(item) {

                if(this.itemsAppl.length > 0){
                if(item.PpaID > 0){
                    this.itemsDeleteAppl.push(
                    this.itemsAppl.filter(x=> x.Line == item.Line)[0],
                    );

                    this.itemsDeleteAppl.forEach(element => {
                    element.SecStatus = 0;
                    });
                }

                this.itemsAppl = this.itemsAppl.filter(
                    (x) => x.Line != item.Line
                );
                this.orderApplis();
                }
            },
            orderApplis() {
                let i = 0;
                this.itemsAppl.map((e) => {
                    i++;
                    e.Line = i;
                });
            },
            rowSelected(row){
                if (row.length == 0) {
                    return;
                }
                console.log("roww", row)

                _visitApplication.listProductApplication(row[0], this.$fun.getUserID()).then((r) => {

                    if (r.status == 200) {
                        
                        console.log("listProductApplication", r.data)
                        this.itemsAppl = r.data;
                        this.orderApplis();
                    }

                })
            },
            addRecom(){
                // this.itemApp.TasVisitProductApplications = [];
                this.itemsAppl = [];
                this.itemsDeleteAppl= [];
            },
            inputProduct(item) {
            if (item)
                this.objTypeUnit = {
                DedValue: item.LsUnitID,
                DedDescription: item.DedDescription,
                };
            },
            addProduct() {
                //  console.log("Focused",this.ByFocused);
                if(!this.PpaObs){
                    if (!this.objProductApplication) {
                        this.$fun.alert("Seleccione Producto", "warning");
                      //  this.$refs.objProductApplication.focus();
                        return;
                    }
                } else{
                    if(this.PpaObservation==="") {
                    this.$fun.alert("Escriba una observacion", "warning");
                      //  this.$refs.PpaObservation.focus();
                        return;
                    }
                }

                if (!this.$fun.isValidDecimal(this.ppaDose)) {
                    this.$refs.ppaDose.error("Dosis Incorrecta");
                    return;
                }
                if (this.ppaDose) {
                if ((this.ppaDose) <= 0) {
                    this.$refs.ppaDose.error("Dosis Incorrecta");
                    return;
                }
                }
                if (!this.ppaDose) {
                    this.$refs.ppaDose.error("Dosis Incorrecta");
                    return;
                }

                if(this.AmountTotal <= 0){
                    this.$refs.refAmountTotal.error('Debe Ingresar una cantidad válida');
                    return;
                }

                this.itemsAppl.push({
                    TypeUnit: this.objTypeUnit.DedValue,
                    TypeUnitName: this.objTypeUnit.DedDescription,
                    PpaDose: this.ppaDose,
                    TypeAplication : parseInt(this.objTypeAplicationDetail.DedValue),
                    TypeApplicationName : this.objTypeAplicationDetail.DedDescription,
                    AmountTotal : this.AmountTotal,
                    LsID: !this.PpaObs ? this.objProductApplication.LsID: null,
                    LsName: !this.PpaObs ? this.objProductApplication.LsDescription : "",
                    PpaObservation : this.PpaObservation,
                    Line: this.itemApp.TasVisitProductApplications.length + 1,
                    SecStatus: 1,
                    UsrCreateID: this.$fun.getUserID(),
                });

                this.objProductApplication = null;
                this.ppaDose = null;
                this.PpaObservation = null;
                this.AmountTotal = 0;
               // this.$refs.objProductApplication.focus();
            },
            save(appl){
                // console.log("FocusedS",this.ByFocused);
                if (this.Vst.VstID == 0 || this.Vst.VstID == null) {
                    this.$fun.alert("Debe Seleccionar una Visita", "warning")
                    return;
                }
              
                appl.VstID = this.Vst.VstID;
                appl.SecStatus = 1;

                appl.TasVisitProductApplications = [...this.itemsAppl, ...this.itemsDeleteAppl];

                console.log("appl v", appl)
                appl.save().then((r) => {
                    this.$emit("reload");
                });
                
            },

            saveswitchv(appl){
                if(this.PpaObs){
                    this.objProductApplication == null;
                }else{
                    this.PpaObservation = null;
                }
            }
        }
}
</script>
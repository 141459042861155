import Service from "../Service";
import Vue from "vue";
const resource = "Packing/"

export default {


    /* OpenConnectionSAP(requestID) {
        return Service.post(resource + "openConnection", "", {
            params: { requestID: requestID },
        });
    }, */

    HideParihuela(obj, requestID) {
        return Service.post(resource + "hideParihuela", obj, {
            params: { requestID: requestID },
        });
    },

    saveSAP(obj, UssUserID, requestID) {
        return Service.post(resource + "saveSAP", obj, {
            params: { UssUserID: UssUserID, requestID: requestID },
        });
    },

    GetRePackingSearch(obj, requestID) {
        return Service.post(resource + "GetRePackingSearch", obj, {
            params: { requestID: requestID },
        });
    },

    GetStretcher(obj, requestID) { 
        return Service.post(resource + "GetStretcher", obj, {
            params: { requestID: requestID },
        });
    },

    GetOrderFabrication(UwoOrderProductionID, UssUserID, requestID) {
       // alert("GetOrderFabrication");
        return Service.post(resource + "GetOrderFabrication", "", {
            params: { UwoOrderProductionID: UwoOrderProductionID, UssUserID: UssUserID, requestID: requestID },
        });
    },

    getDataQR(obj, requestID) {

        let obj2 = { PltID: obj.CodeID }

        return Service.post(resource + "GetDetail", obj2, {
            params: { requestID: requestID },
        });
    },

    GetDetail(obj, requestID) {
        return Service.post(resource + "GetDetail", obj, {
            params: { requestID: requestID },
        });
    },

    GetSearch(obj, requestID) {
        return Service.post(resource + "GetSearch", obj, {
            params: { requestID: requestID },
        });
    },
    GetOrder(obj, requestID) {
        console.log("GetOrder",obj);
        return Service.post(resource + "GetOrder", obj, {
            params: { requestID: requestID },
        });
    },

    GetLotProduction(obj, requestID) {
        return Service.post(resource + "GetLotProduction", obj, {
            params: { requestID: requestID },
        });
    },

    /* GetOrderProduction(obj, requestID) {
        return Service.post(resource + "GetOrderProduction", obj, {
            params: { requestID: requestID },
        });
    }, */
    /* GetOrder() {
        return Vue.prototype.$http.defaults.baseURL + "/Packing/GetOrder";
    },

    GetOrderProduction() {
        return Vue.prototype.$http.defaults.baseURL + "/Packing/GetOrderProduction";
    },

    GetCustomer() {
        return Vue.prototype.$http.defaults.baseURL + "/Packing/GetCustomer";
    }, */

    save(obj, requestID) {
        return Service.post(resource + "Save", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(obj, requestID) {
        //alert("pagination");
        console.log("pagination",obj);
        if (obj.filter.ordAreProduction == "") {
            return new Promise((resolve, reject) => {
                 resolve({
                    data: {
                        data: []
                    },
                    status: 200
                 });
              });
        }
        let answer = Service.post(resource + "pagination", obj, {
            params: { requestID: requestID }
        }); 
        console.log("answere", answer);
        return answer;
    },


    list(TypeDestinity, requestID) {
        return Service.post(resource + "list", {}, {
            params: { TypeDestinity: TypeDestinity, requestID: requestID }
        });
    },



    updateord(OrdID, OrdIDOLD, requestID) {
        return Service.post(resource + "updateord", {}, {
            params: { OrdID: OrdID, OrdIDOLD: OrdIDOLD, requestID: requestID }
        })
    },

    searchLotsUsed(obj, requestID) {
        return Service.post(resource + "searchLotsUsed", obj, {
            params: { requestID: requestID }
        })
    },
    syncSap(obj, requestID) {
        return Service.post(resource + "syncSap", obj, {
            params: { requestID: requestID }
        })
    }
    
};
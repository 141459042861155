<template>
	<v-container>
		<div>
			<!--  -->
			<s-crud
				:filter="filter"
				:config="config"
				add
				edit
				:remove="!disabledInterfaz"
				:load="!disabledInterfaz"
				search-input
				ref="gridPacking"
				:title="'Empaque - ' + ordAreProduction"
				no-full
				@load="loadSap()"
				@save="save($event)"
				@rowSelected="rowSelected($event)"
				@addEvent="addEvent()"
				height="auto"
				:sync="$fun.hasSpecialPermission('syncSap')"
				@sync="sync()"
			>
				<template v-slot:filter>
					<v-container>
						<v-row style="margin-left: 5px">
							<v-col cols="6" md="6" lg="3">
								<s-date
									label="Fecha Inicio"
									v-model="filter.cDateInitial"
								></s-date>
							</v-col>
							<v-col cols="6" md="6" lg="3">
								<s-date
									label="Fecha Fin"
									v-model="filter.cDateFin"
								></s-date>
							</v-col>
							<v-col cols="6" md="6" lg="3">
								<s-select-definition
									 
									v-model="filter.TypePlantProcessing"
									label="Planta Procesadora"
									:def="1230"
								></s-select-definition>
							</v-col>
							<v-col cols="6" md="6" lg="3">
								<s-select-definition
									 
									v-model="filter.PltState"
									label="Estado de Pallet"
									:def="1394"
								></s-select-definition>
							</v-col>
							

						</v-row>
						<v-row style="margin-left: 5px">

							<v-col cols="6" md="6" lg="2">
								<s-select-definition
									clearable
									:def="1172"
									label="Tipo Cultivo"
									v-model="filter.TypeCrop"
								/>
							</v-col>
							<v-col cols="6" md="6" lg="2">
								<s-select-definition
									clearable
									:def="1173"
									label="Cultivo"
									v-model="filter.TypeCultive"
								/>
							</v-col>

							<v-col cols="6" md="6" lg="2">
								<s-select-variety
									clearable
									
									:cultiveID="filter.TypeCultive"
									label="Variedad"
									full
									v-model="filter.VrtID"
								/>
							</v-col>

							<v-col cols="6" md="6" lg="3">
								<s-select-definition
									clearable
									v-model="filter.TypeProductionCenter"
									label="Centro Producción"
									:def="1277"
								></s-select-definition>
							</v-col>

							<v-col cols="6" md="6" lg="3">
								<s-select-definition
									clearable
									:def="1279"
									label="Calidad Pallete"
									v-model="filter.TypeQualityPallet"
								/>
							</v-col>
						</v-row>
					</v-container>
				</template>
				<!-- :disabled="props.item.TypeBalance == false ? true: false" -->
				<template scope="props">
					<v-card :disabled="disabledInterfaz">
						<v-alert
							v-model="alert"
							border="right"
							:color="'#9EB8F6'"
							dark
							dense
							dismissible
							shaped
							style="position:fixed;border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 5%;"
						>
							<div>
								<v-row justify="center">
									<v-col lg="12" cols="12">
										<b>Producto Terminado:</b>
										{{ viewItemSearch.UwoMaterialListName }}
									</v-col>
								</v-row>
							</div>
						</v-alert>

						<v-row>
							<v-col cols="12">
								<v-col cols="12">
									<v-card-title style="margin-top: -40px;">
										Busqueda
										<!-- <span class="">
											<v-btn
												style="margin-left: 15px;"
												color="warning"
												>Cronograma</v-btn
											>
										</span> -->
										<span>
											<v-checkbox
												style="font-size: 20px;"
												label="Pallet Completo"
												v-model="typeBalance"
												
											/>
										</span>
										<!-- :disabled="disabledTypeBalance" -->
										<v-spacer></v-spacer>
										<v-text-field
											v-model="search"
											append-icon="mdi-magnify"
											label="Buscar"
											single-line
											hide-details
										></v-text-field>
									</v-card-title>
									<v-data-table
										item-key="UwoOrderProductionID"
										dense
										fixed-header=""
										v-model="selectedSearch"
										:items="itemsSearch"
										:headers="headersSearch"
										:items-per-page="5"
										:search="search"
										@click:row="rowClickSearch"
									>
									</v-data-table>
								</v-col>
							</v-col>
						</v-row>

						<v-row justify="center">
							<div class="col-md-6">
								<v-col cols="12" md="12">
									<v-row justify="center">
										<v-col cols="8" md="8" style="margin-top: -15px;">
											<s-select
												clearable
												autocomplete
												label="Nro. Pedido"
												:items="itemsOrder"
												item-text="OrdID"
												item-value="OrdID"
												v-model="props.item.OrdID"
											>
											</s-select>
										</v-col>
										<v-col cols="4" md="4" style="margin-top: -15px;">
											<s-select-definition
												:def="1278"
												label="Turno"
												v-model="props.item.TypeTurn"
											/>
										</v-col>
										<v-col
											cols="8"
											md="8"
											style="margin-top: -15px;"
										>
											<s-text
												label="Producto Terminado"
												v-model="viewItemSearch.UwoMaterialListName"
												readonly
											/>
										</v-col>
										<v-col
											cols="4"
											md="4"
											style="margin-top: -15px;"
										>
											<s-text
												label="Cant. Cajas"
												v-model="viewItemSearch.UwoMaterialListNumberBoxes"
												readonly
											/>
										</v-col>
										
										<v-col
											cols="12"
											md="12"
											style="margin-top: -15px;"
										>
											<s-select-definition
												:def="1279"
												label="Calidad Pallete" 
												@input="changeQuealityPallet($event)"
												v-model="props.item.TypeQualityPallet"
												clearable
											/>
										</v-col>
										<v-col
											cols="12"
											md="12"
											style="margin-top: -15px;"
										>
											<s-select-definition 
												:def="1171"
												label="Procedencia"
												v-model="props.item.TypeOrigin"
											/>
										</v-col>
										<v-col cols="12"
											md="12"
											style="margin-top: -15px;">
											<s-select-definition
													:def="1455"
													label="Linea de Negocio"
													v-model="props.item.TypeBsnLine"
													clearable
												/>
										</v-col>
									</v-row>
								</v-col>
							</div>

							<div class="col-md-6">
								<v-col cols="12" md="12">
									<v-card outlined>
									<v-row justify="center" style="margin:auto">
										

										
										<v-col
											cols="12"
											md="12"
											style="margin-top: -15px;"
										>
											<s-toolbar
												dark
												:color="'#BAB6B5'"
												style=" height: 28px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
												label="Pesado"
											>
											</s-toolbar>
										</v-col>
										<!-- <v-col cols="6" md="6" style="margin-top: -15px;">
											<s-text
												decimal
												label="Peso Total Descarte"
												v-model="destareWeight"
											/>
										</v-col> -->
										<v-col cols="6" md="6" style="margin-top: -15px;">
											<s-text
												decimal
												label="Peso Neto estándar"
												v-model="props.item.PltWeightNet"
												readonly
											/>

										</v-col>
										
										<v-col
											cols="6"
											md="6"
											style="margin-top: -15px;"
										>
											<s-text
												decimal
												label="Peso Real"
												v-model="props.item.PltGrossWeight"
											/>
										</v-col>

										<v-col lg="12">
											<s-select-definition
												label="Otras Descripciones"
												:add="$fun.isAdmin()"
												:def="1349"
												v-model="props.item.TypePresentationPacking"
												clearable
											/>
										</v-col>
										
									</v-row>
									</v-card>
								</v-col>
							</div>
						</v-row>

						<v-row style="margin-top: -30px;">
							<div class="col-md-12 col-sm-12">
								<v-col cols="12" md="12"  v-if="viewItemSearch.UwoOrderProductionID !== undefined"> 
									<v-card flat outlined style="padding: 10px;"> 
									<v-row center>
										<v-col  cols="3" >
											<b>Detalle || </b>
											<span style="margin-left: 2%; color: red; font-size: 11px; position: absolute;" >
												<b>Campos Obligatorios (*)</b>
											</span> 
										</v-col>
<!-- clearable -->						<v-col  cols="2">
											<s-select-definition
												@input="debouncedLoadStretcher"	
												
												v-model="TypeProcessingPlant"
												label="Planta Procesadora"
												:def="1230"
											></s-select-definition>
										</v-col>
										<v-col cols="2">
											<s-select-definition
												:dgr="dgr"
												label="Linea Empaque"
												:def="1315"
												v-model="TypeLineProcess" 
												@input="debouncedLoadStretcher">
											</s-select-definition>
										</v-col>
											
										<v-col cols="2">
											<v-checkbox
												v-model="isReprocess"
												@click="filtReprocess()"
												label="Reproceso"
												color="red"
												hide-details
											></v-checkbox>
										</v-col>
									</v-row>
									</v-card>
									<v-row justify="center" style="margin-top: -35px;">
										<div class="col-md-12">
											<v-col cols="12" md="12">
												<v-row justify="center">
													<v-col cols="12">
														<v-spacer></v-spacer>

														<b
															><v-text-field
																v-model="searchStretcher"
																append-icon="mdi-magnify"
																label="Buscar"
																single-line
																hide-details
																style="margin-top: 5px"
															></v-text-field
														></b>
<!---->
														<v-data-table
															:item-key="TypeDestiny== 1 ? 'RcfID' : 'LppID'"
															dense
															:items-per-page="5"
															:items="itemsStretcher"
															:headers="headersStretcher"
															v-model="selectedStretcher"
															:search="searchStretcher"
															@click:row="rowClickStretcher"
															show-group-by
															:loading="LoadingStretcher"
														>
															<!-- <template
																v-slot:item.Quantity="{ item }"
															>
																<s-text
																	v-model="item.Quantity"
																	number
																	:min="0"
																	@keyupEnter="
																		keyupEnter(item)
																	"
																>
																	<template v-slot:append>
																		<i
																			style="color: red; font-size: 7px;"
																			class="fa fa-asterisk"
																		></i>
																	</template>
																</s-text>
															</template> -->
															
															<template
																v-slot:item.SpdDate="{ item }"
															>
																 {{moment(item.SpdDate).format($const.FormatDateTimeView)}}
															</template>
															<template
																v-slot:item.RcfID="{ item }"
															>
																<v-chip
																	style="margin: 0px"
																	small
																	:color="''"
																>
																	LP-{{ item.RcfID + "-" + item.RcfCode }}
																</v-chip>
															</template>

															<template
																v-slot:item.xCount="{ item }"
															>
																<v-chip
																	x-small
																	:color="item.xCount == 0 ? 'warning' : 'info'"
																>
																	{{item.xCount == 0 ? 'No' : 'Si:   ' + item.xCount}}
																</v-chip>
															</template>

															<template v-slot:item.Hide="{ item }">
																<v-btn
																	@click="clickHide(item)"
																	x-small
																	color="error"
																	elevation="0"
																	outlined
																	
																>
																	<v-icon style="font-size: 16px !important"
																		>fas fa-times</v-icon
																	>
																</v-btn>
															</template>

															<!-- <template v-slot:item.Action="{ item }">
																<v-btn
																	x-small
																	color="info"
																	elevation="0"
																	block
																	rounded
																	@click="newItem(item)"
																>
																	<v-icon dark>
																		mdi-plus
																	</v-icon>
																</v-btn>
															</template> -->


															<template
																v-slot:item.LppWeightAverageLot="{ item }"
															>
																<v-chip
																	style="margin: 0px"
																	small
																	:color="'success'"
																>
																	{{ item.LppWeightAverageLot.toFixed(2) +'prueba' }}
																</v-chip>
															</template>

															<template
																v-slot:item.LppWeightDifference="{ item }"
															>
																<v-chip
																	style="margin: 0px"
																	small
																	:color="'success'"
																>
																	{{ (item.LppWeightAverageLot  - item.PldWeight).toFixed(2)}}
																</v-chip>
															</template>


															<template v-if="TypeDestiny == 2" v-slot:item.LlpID="{ item }">
																{{ item.LlpID }}
															</template>

															

														</v-data-table>

														<v-row>
															<v-col
																cols="6"
																lg="2"
																md="2"
																style="margin-top: 0px;"
															>
															<s-text
																	label="LP Seleccionado"
																	v-model="RcfIDCheck"
																	readonly
																/>
															</v-col>
															<v-col
																cols="6"
																lg="2"
																md="2"
																style="margin-top: 0px;"
															>
															<s-text
																	label="Nro. Cajas"
																	number
																	:min="0"
																	v-model="Quantity"
																	ref="Quantity"
																>
																	<template v-slot:append>
																		<i
																			style="color: red; font-size: 7px;"
																			class="fa fa-asterisk"
																		></i>
																	</template>
																</s-text>
															</v-col>
															<v-col>
																<s-select-definition
																	:def="1266"
																	label="Calibre"
																	v-model="objCaliber"
																	return-object
																	ref="TypeCaliber"
																	@input="inputCaliber($event)"
																>
																</s-select-definition>
															</v-col>


															<v-col
																cols="6"
																lg="2"
																md="2"
																style="margin-top: 0px;"
																
															>
																<b><s-text
																	label="Fruta x Caja:"
																	v-model="pldFruitxBoxe"
																	number
																	:min="1"
																	ref="pldFruitxBoxe"
																>
																	<template v-slot:append>
																		<i
																			style="color: red; font-size: 9px;"
																			class="fa fa-asterisk"
																		></i>
																	</template>
																</s-text></b>
															</v-col>

															<v-col cols="6" lg="3">
																<b>	
																	<!-- v-model="isNaN(sumDiscartWeightPorce) ? 0 : sumDiscartWeightPorce" 
																	sumDiscartWeightPorce >= alert && sumDiscartWeightPorce < critical ? 'Alerta' : sumDiscartWeightPorce >= critical == 'Critico'
																	sumDiscartWeightPorce >= alert && sumDiscartWeightPorce < critical ? 'yellow' : sumDiscartWeightPorce >= critical == 'error'
																	-->
																	<v-text-field
																		label="Cajas disponibles (Aprox.)"
																		readonly
																		v-model="boxeAproximate"
																	>
																	</v-text-field>
																
																</b>
															</v-col>

															<v-col cols="12" style="margin-top: -40px">
																<v-btn
																	small
																	color="info"
																	elevation="0"
																	fab
																	
																	@click="newItem(item)"
																>
																	<v-icon dark>
																		mdi-plus
																	</v-icon>
																</v-btn>
															</v-col>

															<!-- <v-col
																cols="2"
																style="margin-top: -20px; margin-left: -50px;"
															>
																<v-btn
																	class="mx-10"
																	fab
																	dark
																	color="warning"
																	@click="newItem(selectedStretcher[0])"
																>
																	<v-icon dark>
																		mdi-plus
																	</v-icon>
																</v-btn>
															</v-col> -->
														</v-row>
													</v-col>
												</v-row>
											</v-col>
										</div>
									</v-row>

									<v-row>
										
										<v-col cols="12">
											<v-data-table
												item-key="PldLine"
												dense
												:items-per-page="-1"
												:items="itemsDetail"
												:headers="headersDetail"
												hide-default-footer
											>
												<template v-slot:item.Actions="{ item }">
													<v-btn
														@click="removeItem(item)"
														x-small
														color="error"
														elevation="0"
														block
													>
														<v-icon
															style="font-size: 16px !important"
															>fas fa-times</v-icon
														>
													</v-btn>
												</template>
												<template v-slot:item.TypeCaliberName="{item}">
													<s-select-definition
														:def="1266"
														v-model="item.TypeCaliber"
													>
													</s-select-definition>
												</template>
												<template v-slot:item.PldFruitxBoxe="{item}">
													<s-text
														number
														v-model="item.PldFruitxBoxe"
													></s-text>
												</template>
												<template v-slot:item.PldQuantityBoxes="{item}">
													<s-text
														readonly
														number
														v-model="item.PldQuantityBoxes"
														@input="inputSumBoxes()"
													></s-text>
												</template>
											</v-data-table>
											<b>Total Cajas: {{ sumTotalBoxes }}</b>
										</v-col>
										<v-col cols="12" align="center">
											<b style="color: red; font-family: Courier; font-size: 10px"><h2>{{viewItemSearch.UwoMaterialListNumberBoxes == sumTotalBoxes ? 'Atención !!! Cantidad de cajas coincide con cant. cajas de presentación' : ''}}</h2></b>
										</v-col>
										<v-col cols="12" align="center">
											<b style="color: red; font-family: Courier; font-size: 10px"><h2>{{ viewItemSearch.UwoMaterialListNumberBoxes == sumTotalBoxes  ? difTypeLine() : ''}}</h2></b>
										</v-col>
									</v-row>
								</v-col>
							</div>
						</v-row>
					</v-card>
				</template>


				<template v-slot:Options="{ row }">
					<!-- <div> -->
						<v-btn
							x-small
							:color="'error'"
							fab
							style="text-transform: initial;"
							
							:disabled="row.PltState == 2 ? false: true"
							v-if="row.TypeBalance == true && $fun.isAdmin()"
							@click="enabledPallet(row)"
						>
							<!--@click="clickRePacking(row)" :disabled="row.TypeStatusCoolingChamber == 1 ? false: true" <i style="font-size:16px;margin-right:6px;" class="fas fa-voicemail"></i>  -->
							<v-icon>fas fa-check-circle</v-icon>
						</v-btn>
						<!-- <v-btn
							x-small
							:color="'error'"
							fab
							style="text-transform: initial;"
							disabled
							v-if="row.TypeBalance == true"
						>
							
							<v-icon>fas fa-check-circle</v-icon>
						</v-btn> -->

						<v-btn
							x-small
							:color="'info'"
							fab
							style="text-transform: initial;"
							
							v-if="row.TypeBalance == false"
						>
							<!-- :disabled="row.TypeStatusCoolingChamber == 1 ? false: true"<i style="font-size:16px;margin-right:6px;" class="fas fa-voicemail"></i>  -->
							SA
						</v-btn>
						
					<!-- </div> -->
				</template>

				<template v-slot:TypeQualityPalletName={row}>
					<v-chip
						x-small
						dark
						:color="row.TypeQualityPalletName =='Process' ? 'error' : 'light-blue darken-2'">

						{{  row.TypeQualityPalletName}}

					</v-chip>
				</template>

				<template v-slot:PltState="{ row }">
					<v-chip
						x-small
						:color="row.PltState == 1 ? 'error':'success'"
					>
						{{row.PltState == 1 ?'Sin Subir':'SAP'}}
					</v-chip>
				</template>

				
			</s-crud>
			<!-- <v-card>
				<v-row style="margin: auto">
					<v-col cols="6">
						Cantidad de Pallet
					</v-col>
				</v-row>
			</v-card> -->
		</div>

		<v-dialog
			v-if="openDialog"
			v-model="openDialog"
			persistent
			hide-overlay
			width="60%">
			<packing @click="click($event)"></packing>
		</v-dialog>

		<v-dialog
			v-if="openDialogSAP"
			v-model="openDialogSAP"
			fullscreen
			persistent>
			<packing-order-view
				:items="selected"
				@clickCancel="clickCancel()"
			></packing-order-view>
		</v-dialog>
	</v-container>
</template>

<script>
	//Service
	import _sPackingService from "../../../services/FreshProduction/PackingService";
	import SSelectOrder from "../../../components/FreshProduction/Packing/sSelectOrder.vue";
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	import Packing from "./Packing.vue";
	import PackingOrderView from "./PackingOrderView.vue";
	import debounce from 'lodash/debounce';
import moment from "moment";
	/* import signalr from "signalr"; */

	export default {
		components: { SSelectOrder, Packing, PackingOrderView, SSelectVariety },

		data: () => ({
			LoadingStretcher: false,
			TypeLineProcess: 0,
			dgr: 0,
			filter: {
				cDateInitial: "",
				cDateFin: "",
				ordAreProduction: "",
				cBalance: 1,
			},
			config: {
				service: _sPackingService,
				model: {
					PltID: "ID",
					PltDate: "date",
					Options: "",
					PltState:"",
					TypeQualityPalletName: "",
				},
				headers: [
					{ text: "Re-empaque", value: "Options", width: 50, align: "center"},
					{ text: "ID Pallet", value: "PltID", width: 100 },
					{ text: "Pallet Correlativo", value: "PltNumberCorrelative", width: 100 },
					{ text: "Procedencia", value: "TypeZonLocationName", width: 100 },
					{
						text: "Producto Terminado",
						value: "UwoMaterialListID",
						width: 140,
					},
					{ text: "Pedido", value: "OrdID", width: 100 },
					{ text: "Fundo/Usuario", value: "FagName", width: 340 },
					{ text: "Cliente.", value: "UwoCustomerName", width: 340 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 140 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 140 },
					{
						text: "Cantidad",
						value: "UwoMaterialListNumberBoxes",
						width: 140,
					},
					{ text: "Peso Estandar", value: "PltWeightNet", width: 140 },
					{ text: "Peso real", value: "PltGrossWeight", width: 140 },
					{ text: "Linea de Empaque", value: "TypeLineName", width: 140 },
					{ text: "Fecha", value: "PltDate", width: 140 },
					{ text: "Calidad", value: "TypeQualityPalletName", width: 140 },
					{ text: "Estado", value: "PltState", width: 140 },
				],
			},

			selected: {},

			//*************************************************************** */
			headersSearch: [
				
				{
					text: "Plantilla",
					value: "UwoMaterialListID",
					width: 120,
				},
				{
					text: "Nro. Orden Produccion",
					value: "UwoOrderProductionID",
					width: 120,
				},
				{ text: "Cliente", value: "UwoCustomerName", width: 250 },
				{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
				{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
				{
					text: "Centro de Produccion",
					value: "UfdProductionCenter",
					width: 250,
				},
				{
					text: "Cantidad",
					value: "UwoMaterialListNumberBoxes",
					width: 50,
				},
				{ text: "Peso", value: "UwoPlannedQty", width: 50 },
				{ text: "KG x Caja", value: "NumInSaleString", width: 50 },
				{ text: "Planta Proc.", value: "UwoPlantProcessing", width: 50 },
			],

			itemsSearch: [], //Listado
			selectedSearch: [],
			viewItemSearch: {},
			search: "",

			//*************************************************************** */
			headersDetail: [
				{ text: "Acciones", value: "Actions", width: 60 },
				{ text: "Variedad", value: "VrtName", width: 120 },
				{ text: "Calibre", value: "TypeCaliberName", width: 150 },
				{ text: "Fruta x Caja", value: "PldFruitxBoxe", width: 100 },
				{ text: "Nro. Lote", value: "RcfID", width: 100 },
				{ text: "Procedencia", value: "TypeZonLocationName", width: 100 },
				{ text: "Correlativo. Lote", value: "RcfCode", width: 100 },
				{ text: "Cantidad", value: "PldQuantityBoxes", width: 250 },
			],

			itemsDetail: [],
			itemsDetailRemoved: [],
			/* selectedDetail: [], */

			//*************************************************************** */
			headersStretcher: [
				{ text: "Add", value: "Action", width: 40 },
				{ text: "F. Abast.", value: "SpdDate", width: 40 },
				/* { text: "Calibre", value: "TypeCaliberName", width: 100 }, */
				/* { text: "F. Abaste", value: "TypeCaliberName", width: 100 }, */
				{ text: "Lote Produccion", value: "RcfID", width: 50 },
				{ text: "Procedencia", value: "TypeZonLocationName", width: 100 },
				{ text: "Certificación", value: "TcDescription", width: 200 },
				/* { text: "Fundo", value: "FagName", width: 250 }, */
				/* { text: "Cultivo", value: "TypeCultiveName", width: 100 }, */
				{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
				{ text: "Variedad", value: "VrtName", width: 100 },
				{ text: "Parihuela", value: "LlpID", width: 100 },
				// { text: "Cantidad Jabas", value: "LppQuantityJaba", width: 100 },
				{ text: "KG Disponibles", value: "LppWeightDifference", width: 60 },
				/* { text: "KG Usados", value: "PldWeight", width: 60 },
				{ text: "KG Abastecidos", value: "LppWeightAverageLot", width: 60 }, */
				

				
				{ text: "Ocultar", value: "Hide", width: 60 },
				{ text: "Usado", value: "xCount", width: 30 },
			],

			itemsStretcher: [],
			selectedStretcher: [],
			searchStretcher: "",

			//*************************************************************** */

			itemsOrder: [],
			ordAreProduction: "",

			//Detalle
			objCaliber: null, //Tipo de Calibre
			objStretcher: null, //Obj Parihuela
			pldFruitxBoxe: 1, //Fruta por Caja
			sumTotalBoxes: 0, //Suma total de Cajas en detalle
			itemRemoved: [],

			//Cabecera
			PltWeightNetReal: 0,
			//destareWeight: 0,
			typeBalance: true,
			disabledTypeBalance: false,
			TypeDestiny: 0,
			VrtID: 0,
			TypeProcessingPlant: null,

			isReprocess: false,

			alert: true,
			openDialog: true,
			openDialogSAP: false,
			ussUserCode: null,
			Quantity: 0,
			RcfIDCheck : 0,
			boxeAproximate: 0,

			disabledInterfaz: false,
			debouncedLoadStretcher: null, // Declarar la versión "debounced" de la función
		}),

		created() {
			this.debouncedLoadStretcher = debounce(this.loadStretcher, 300); // 300 ms de retraso
		},

		watch: {
			//Cargar Nro Pedido

			
			selectedSearch() {
				console.log('seleccionamos la plantilla', this.viewItemSearch);
				_sPackingService
					.GetOrder(
						{
							OrdAreProduction: this.ordAreProduction,
							UwoCustomerID: this.viewItemSearch.UwoCustomerID,
							UwoOrderProductionID: this.viewItemSearch
								.UwoOrderProductionID,
						},
						this.$fun.getUserID(),
					)
					.then(r => {
						this.itemsOrder = r.data;
					});

				this.alert = true;
				//this.loadStretcher();
			},

			itemsDetail() {
				this.sumTotalBoxes = this.isSumBoxes();
			},

		},

		computed: {

			GetWeightNet() {
				return this.grossWeight - this.destareWeight;
			},

		},

		methods: {

			//************************************************ Eventos ****************************************** */
			click(val) {
				console.log('verificamos que presiono el usuario si usa o europa', val)
				this.ordAreProduction = val.DedAbbreviation;
				this.TypeDestiny = val.DedValue;
				this.dgr = parseInt(val.DedHelper);
				this.filter.ordAreProduction = this.ordAreProduction;
				this.initialize();
				this.openDialog = false;
			},

			clickCancel() {
				this.openDialogSAP = false;
				this.$refs.gridPacking.refresh();
				console.log("Cancelarr");
			},

			keyupEnter(item) {
				this.newItem(item);
			},

			clickRePacking(items) {
				

				if (items.PltState == 2) {
					this.$fun.alert(
						"El Pallet " +
							items.PltID +
							" Ya esta cargado en SAP, no puede ser Reempacado, debe estornar",
						"warning",
					);
					return;
				}

				this.$fun
					.alert("Seguro de enviar para Re-Empaque ? ", "question")
					.then(val => {
						if (val.value) {

							items.SecStatus = 10;
							items.TypeBalance = false;
							items.UsrCreateID = this.$fun.getUserID();

							_sPackingService
								.save(items, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert(
											"operacion realizada correctamente",
											"success",
										);
										this.$refs.gridPacking.refresh();
									}
								});
						}
					});
			},

			cleanAll() {
				this.typeBalance = true;
				this.disabledTypeBalance = false;
				this.search = "";
				this.viewItemSearch = {};
				this.pldFruitxBoxe = 1;
				this.sumTotalBoxes = 0;
				this.itemsDetail = [];
				this.itemsDetailRemoved = [];
				this.grossWeight = 0;
				this.destareWeight = 0;
				this.itemsOrder = [];
			},

			addEvent() {
				this.cleanAll();
				this.itemsStretcher = [];
				this.TypeLineProcess = 0;
			},

			inputCaliber(item){
				if(item !== null){
					console.log(item);
					this.pldFruitxBoxe = item.DedHelper
				}
				
			},

			//************************************************ Listado de Informacion ****************************************** */

			//Listado de Pedido - Clientes
			initialize() {
				//console.log("samir");
				_sPackingService
					.GetSearch(
						{ OrdAreProduction: this.ordAreProduction },
						this.$fun.getUserID(),
					)
					.then(r => {
						this.itemsSearch = r.data;
						console.log("this.itemsSearch", this.itemsSearch);
					});
			},

			//cambio de calidad del pallet 
			changeQuealityPallet(item) {
				console.log('cambio de calidad', item)
			},

			//Cargar Parihuela
			loadStretcher() {
				console.log("this.TypeLineProcess", this.TypeLineProcess);
				this.itemsStretcher = [];
				var IsRework =  (this.isReprocess) ? 1 : null;

				// Validar si ya se tienen los mismos valores antes de llamar a la API
				// if (
				// 	this.lastTypeDestiny === this.TypeDestiny &&
				// 	this.lastTypeProcessingPlant === this.TypeProcessingPlant &&
				// 	this.lastTypeLineProcess === this.TypeLineProcess &&
				// 	this.lastIsRework === IsRework
				// ) {
				// 	console.log("No hay cambios en los parámetros, no se recarga la lista.");
				// 	return;
				// }

				// Guardar los nuevos valores
				this.lastTypeDestiny = this.TypeDestiny;
				this.lastTypeProcessingPlant = this.TypeProcessingPlant;
				this.lastTypeLineProcess = this.TypeLineProcess;
				this.lastIsRework = IsRework;
				this.LoadingStretcher = true;
				_sPackingService
					.GetStretcher(
						{
							TypeDestiny: this.TypeDestiny,
							TypeProcessingPlant: this.TypeProcessingPlant ,
							IsRework : IsRework,
							TypeLineProcess: this.TypeLineProcess
						},
						this.$fun.getUserID(),
					)
					.then(r => {
						this.itemsStretcher = r.data;
						this.LoadingStretcher = false;
						console.log("this.itemsStretcher", this.itemsStretcher);
						if(this.itemsDetail.length>0){
						// recorrer dentro del for mejor
							this.itemsStretcher.forEach(element=>{	
								console.log("this.detalle11", this.itemsDetail);						
								this.getDiference(element);
							})
						}
						
					});
					
					
			},

			loadItemsDetail(PltID) {
				
				_sPackingService
					.GetDetail(
						{
							PltID: PltID,
						},
						this.$fun.getUserID(),
					)
					.then(r => {
						this.itemsDetail = r.data;
						console.log("getdetail",r.data);
						this.orderDetails();
					});
			},

			//******************************** Row Selected, Row Click ****************************************************************** */

			rowSelected(val) {
				if (val.length > 0) {
					if (val[0].PltID > 0) {
						this.selected = val[0];
						console.log("this.selected", this.selected);
						this.loadItemsDetail(this.selected.PltID);
						this.grossWeight = this.selected.PltGrossWeight;
						this.destareWeight = this.selected.PltDestareWeight;

						this.VrtID = this.selected.VrtID;
						this.pldFruitxBoxe = this.selected.PltFruitxBoxe;
						this.typeBalance = this.selected.TypeBalance;
						this.disabledTypeBalance = true;
						this.TypeLineProcess = this.selected.TypeLine;

						this.search = this.selected.UwoOrderProductionID.toString();
						let ob = this.itemsSearch.find(
							x => x.UwoOrderProductionID == this.search,
						);
						this.selectedSearch = [ob];
						this.rowClickSearch(ob, ob);


						if(this.selected.PltState == 2){
							this.disabledInterfaz = true
						}else{
							this.disabledInterfaz = false
						}
					}
				}

				console.log("this.selected", this.selected);
			},

			rowClickSearch: function(item, row) {
				if (item !== undefined) {
					this.selectedSearch = [item];
					this.viewItemSearch = this.selectedSearch[0];
					console.log("this.viewItemSearch",this.viewItemSearch);
				}
			},

			rowClickStretcher: function(item, row) {
				if (item !== undefined) {
					this.selectedStretcher = [item];
					this.RcfIDCheck = this.selectedStretcher[0].RcfID
                    if(this.viewItemSearch.NumInSale==null){
						this.$fun.alert("El valor de 'peso con hidratación' no puede ser nulo. POR FAVOR, asegúrese de configurar el peso con hidratación antes de continuar", "warning");
					    this.boxeAproximate = 0;
						this.Quantity =0;
						return;
					}
					this.boxeAproximate = parseInt((parseInt(this.selectedStretcher[0].LppWeightAverageLot)  - parseInt(this.selectedStretcher[0].PldWeight)) / this.viewItemSearch.NumInSale)
					this.Quantity = this.boxeAproximate;
					console.log("item seleccionado", this.selectedStretcher[0].LppWeightAverageLot);
					console.log("item seleccionado", this.selectedStretcher[0].PldWeight);
					console.log("item seleccionado", this.viewItemSearch);
				}
			},

			

			//************************New Item, Remove Item, Save, Load SAP ********************************* */

			newItem(val) {
				
				
				val  = this.selectedStretcher[0];
				val.Quantity = this.Quantity;

				console.log('reproceso en detalle',this.isReprocess);

				var IsReworkP = (this.isReprocess) ? 1 : null;

				var PlantProc = (val.TypeProcessingPlant == 1) ? "Sunshine" : "Maquila";

				console.log(PlantProc.toUpperCase())
				console.log(this.viewItemSearch.UwoPlantProcessing.toUpperCase())

				// if(this.viewItemSearch.UwoPlantProcessing.toUpperCase() !== PlantProc.toUpperCase()) {
				// 	this.$fun.alert(
				// 			"No se puede armar Parihuela con diferente planta procesadora",
				// 			"warning",
				// 		);
				// 	return;
				// }


				if(this.Quantity > this.boxeAproximate){
					this.$fun.alert("La cantidad de Cajas supera las cajas Disponibles ", "warning");
					return;
				}

				console.log("qqqqqqqqqqqqqqq",val);
				console.log('informacion que me trae ddddd', val);

				console.log("viewItemSearch", this.viewItemSearch);
				if (!this.isValidatedDetail(val)) {
					return;
				}

				let exist = false;
				this.itemsDetail.forEach(element => {
					
					if (val.VrtID !== element.VrtID) {
						exist = true;
						this.$fun.alert(
							"No se puede armar Parihuela con diferente variedad",
							"warning",
						);
					}

					// if(val.TypeProcessingPlant !== element.TypeProcessingPlant ) {
					// 	exist = true;
					// 	this.$fun.alert(
					// 		"No se puede armar Parihuela con diferente planta procesadora",
					// 		"warning",
					// 	);
					// }



					/* if (val.LppID == element.LppID) {
						exist = true;
						this.$fun.alert(
							"Error, Parihuela seleccionada ya fue usada, quite de detalle y vuelva a ingresar la nueva cantidad de cajas.",
							"warning",
						);
					} */

					/* if (parseInt(this.pldFruitxBoxe) !== parseInt(element.PldFruitxBoxe)) {
						exist = true;
						this.$fun.alert(
							"Cantidad de Fruta por Caja no coincide con los item ya agregados, verifique",
							"warning",
						);
					} */
				});

				if (exist == false) {
					var PldLine = this.itemsDetail.length + 1;
					let item = {
						PldLine: PldLine,
						PldID: 0,
						PltID: 0,
						RcfID: val.RcfID,
						RcfCode: val.RcfCode,
						FagID: val.FagID,
						LlpID: val.LlpID,
						LppID: val.LppID,
						TypeCaliberName: this.objCaliber.DedDescription,
						TypeCaliber: this.objCaliber.DedValue,
						VrtID: val.VrtID,
						VrtName: val.VrtName,
						PldQuantityBoxes: this.Quantity,//val.Quantity,
						PldFruitxBoxe: this.pldFruitxBoxe,
						//UwoPlannedQty:  this.viewItemSearch.UwoPlannedQty,
						PldNumInSale: this.viewItemSearch.NumInSale,
						TypeZonLocationName: val.TypeZonLocationName,
						SecStatus: 1,
						UsrCreateID: this.$fun.getUserID(),
						TypeDestiny: this.TypeDestiny,
						IsReworkP: IsReworkP,
						TypeLine: this.TypeLineProcess
					};

					/* */
					console.log("this.selectedSearch", this.selectedStretcher[0]);

					var diferenceeDis = this.selectedStretcher[0].LppWeightAverageLot  - this.selectedStretcher[0].PldWeight;

					var diferencee = (this.Quantity * this.selectedSearch[0].NumInSale)
					 
					

					 if (diferencee > diferenceeDis) {
						this.$fun.alert("La cantidad de Kg supera las Kg Disponibles ", "warning");
						return;
					 }
					 /** */
					this.VrtID = val.VrtID;
					this.itemsDetail.push(item);

					console.log('detalles ya agregados', this.itemsDetail)
					//samir
					/* */
					//this.selectedStretcher[0].LppWeightDifference = diferenceeDis - diferencee;
					this.selectedStretcher[0].LppWeightAverageLot = (diferenceeDis - diferencee) + this.selectedStretcher[0].PldWeight;

					
					// console.log("this.selectedSearch", this.selectedStretcher[0].LppWeightDifference );
				}
			},

			orderDetails() {
				let i = 0;
				this.itemsDetail.map(e => {
					i++;
					e.PldLine = i;
				});
			},

			removeItem(item) {

				console.log("item ya", item);

				var weight = item.PldNumInSale * item.PldQuantityBoxes;

				if (this.itemsDetail.length > 0) {
					if (item.PldID > 0) {
						this.itemRemoved.push(
							this.itemsDetail.filter(x => x.PldLine == item.PldLine)[0],
						);
						this.itemRemoved.forEach(element => {
							element.SecStatus = 0;
						});
					}

					this.itemsDetail = this.itemsDetail.filter(
						x => x.PldLine != item.PldLine,
					);
					console.log("weight rest", weight);
					if(weight <= 0){
						
						return;
					}
					let keyExist = 0;
					//samir 
					this.itemsStretcher.forEach(element => {
						console.log("dentro elemett", element);
						if(item.LlpID != null){
							if(element.LlpID == item.LlpID){
								element.LppWeightAverageLot =  element.LppWeightAverageLot + weight;	
								keyExist = 1;
							}
						}else{
							if(element.RcfID == item.RcfID){
								element.LppWeightAverageLot =  element.LppWeightAverageLot + weight;	
								keyExist = 1;
							}
						}
					});
					//samir 

					item.LppWeightAverageLot = weight;
					 
					item.PldWeight = 0;
					 

					if(keyExist == 0){
						this.itemsStretcher.push(item);

						console.log("item eiminado", item);
					}


					this.orderDetails();
				}
			},

			save(val) {
				
				if (this.selectedSearch.length > 0) {

					console.log("LocationName", val);
					if (!this.isValidatedHeader(val)) {
						return;
					}
					console.log("val.TypePresentationPacking", val.TypePresentationPacking);

					if (val.TypePresentationPacking == null) {
						this.$fun.alert( "Otras Descripciones no debe ser vacio", "warning",
					);
						return;
					}
					if (val.TypeQualityPallet == null) {
						this.$fun.alert( "Calidad de Pallet no debe ser vacio", "warning",
					);
						return;
					}
					
					
					val.PltWeightNet = this.sumTotalBoxes * this.viewItemSearch.NumInSale//this.viewItemSearch.UwoPlannedQty;
					//val.PltGrossWeight = this.grossWeight;
					//val.PltDestareWeight = this.destareWeight;

					val.UwoMaterialListNumberBoxes = this.sumTotalBoxes;
					val.UwoMaterialListID = this.viewItemSearch.UwoMaterialListID;
					val.UwoCustomerID = this.viewItemSearch.UwoCustomerID;
					val.UwoOrderProductionID = this.viewItemSearch.UwoOrderProductionID;
					//val.UwoLinePackingID = this.viewItemSearch.UwoLinePackingID;
					//Prueba-lancajima
					val.TypeLine = this.TypeLineProcess;
					val.UfdProductionCenter = this.viewItemSearch.UfdProductionCenter;
					val.UfdProductionCenterID = this.viewItemSearch.UfdProductionCenterID;
					
					val.TypeDestinyPacking = this.ordAreProduction;
					val.TypeBalance = this.typeBalance;
					val.TypeCultive = this.viewItemSearch.TypeCultive;
					val.TypeCrop = this.viewItemSearch.TypeCrop;
					val.VrtID = this.VrtID;
					val.PltFruitxBoxe = this.pldFruitxBoxe;

					val.FagIDRepresentative = this.getFundRepresentative();
					val.RcfIDRepresentative = this.getLotRepresentative();
					val.RePacking = 1;

					val.SecStatus = 1;
					val.UsrCreateID = this.$fun.getUserID();

				
					val.itemsDetail = [...this.itemsDetail, ...this.itemRemoved];
					val.ZonLocationName = this.getLocationName();
					//val.TypeBsnLine = this.
					console.log("Guardar2", val);
					
					val.save();
				}
			},

			getLocationName( ){
				
				let locationName = this.getLocations(this.itemsDetail);
				
				console.log("Cantidad11",locationName.length);
				let cantTotal = [] ;

				/*locationName[1]="CASMA" ;
				locationName[2]="CHICLAYO" ;
				this.itemsDetail[4] = {
					TypeZonLocationName : "CASMA",
					PldQuantityBoxes : 30 
				}
				this.itemsDetail[5] = {
					TypeZonLocationName : "CHICLAYO",
					PldQuantityBoxes : 181 
				}*/
				//cantTotal[0] = 0;
				console.log("cant",cantTotal[0]);
				this.itemsDetail.forEach((element)=>{
					for(var i = 0; i < locationName.length; i++){
						if(element.TypeZonLocationName === locationName[i]){
							console.log("Cantidad22", cantTotal[i]);
							
							cantTotal[i] =  (cantTotal[i]== undefined ? 0 : cantTotal[i]) + parseInt(element.PldQuantityBoxes);
							
						}
					}					
				})
				console.log("Procedencia",locationName);
				console.log("canti total", cantTotal);
				let mayor = Math.max.apply(null, cantTotal);
				let index =  cantTotal.indexOf(mayor);
				console.log("El lugar de procedencia con mayor cantidad2" ,mayor);
				console.log("Procedenia Mayor Index" , index );
				console.log("Procedenia Mayor " ,locationName[index]);
				
				return locationName[index];

			},

			getQuantyMax(cantidTotal){
				cantidTotal.forEach

			},

			getLocations(items){
				let locations = [];
				
				items.forEach(element=>{
					if (locations.indexOf(element.TypeZonLocationName) === -1) {
						locations.push(element.TypeZonLocationName);
						console.log("La nueva colección de locaciones es: " + locations);
					} else if (locations.indexOf(element.TypeZonLocationName) > -1) {
						console.log(element.TypeZonLocationName + " ya existe en la colección de locaciones.");
					}
				});

				return locations;
			},

			loadSap() {
				this.ussUserCode = this.$fun.getUserSAPInfo().UssUserCode;

				if(this.selected.length == 0) {
					this.$fun.alert(
						"No ha seleccionado registro",
						"warning",
					);
					return;
				}

				if (this.selected.PltState == 2) {
					this.$fun.alert(
						"El Pallet " +
							this.selected.PltID +
							" Ya esta cargado en SAP",
						"warning",
					);
					return;
				}

				if (this.selected.PltGrossWeight == 0) {
					this.$fun.alert(
						"No se ha Pesado el Pallet ==> " + this.selected.PltID,
						"warning",
					);
					return;
				}

				if (this.ussUserCode == null) {
					this.$fun.alert(
						"No tiene un Usuario SAP autenticado, Comuniquese con el Area de TECNOLOGÍA DE LA INFORMACIÓN.",
						"warning",
					);
					return;
				}

				this.openDialogSAP = true;
			},

			//***************************************** VALIDACIONES ************************************** */
			isValidatedHeader(item) {
				var isValidated = true;

				if (this.typeBalance == true) {
					if (item.OrdID == undefined || item.OrdID == 0) {
						this.$fun.alert("Seleccione un Nro. de Pedido", "warning");
						isValidated = false;
						return;
					}

					if (
						this.sumTotalBoxes !==
						this.viewItemSearch.UwoMaterialListNumberBoxes
					) {
						this.$fun.alert(
							"El Total de Cajas no coincide con el Nro. de Cajas permitidas por el Producto Terminado",
							"warning",
						);
						isValidated = false;
						return;
					}
				}

				if(item.TypePresentationPacking == null){
					this.$fun.alert("Seleccione tipo de caja", "warning");
					isValidated = false;
				}

				if (this.itemsDetail.length == 0) {
					this.$fun.alert("No existe Detalle de Empaque", "warning");
					isValidated = false;
					return;
				}

				if (this.viewItemSearch.UwoOrderProductionID == undefined) {
					this.$fun.alert(
						"Seleccione una Orden de Produccion",
						"warning",
					);
					isValidated = false;
					return;
				}
				

				return isValidated;
			},

			isValidatedDetail(item) {

				console.log("validandooooo", item);
				console.log('selectedSearch ', this.selectedSearch);
				var isValidated = true;
				if (
					this.viewItemSearch.UwoMaterialListNumberBoxes == undefined ||
					this.viewItemSearch.UwoMaterialListNumberBoxes == 0
				) {
					this.$fun.alert(
						"Debe Seleccionar una Orden de Produccion",
						"warning",
					);
					return;
				}

				if (item == undefined) {
					this.$fun.alert("Seleccione un Lote de Produccion", "warning");
					return;
				}

				if (this.Quantity <= 0) {
					//this.$fun.alert("La cantidad debe ser mayor a 0", "warning");
					//this.$refs.Quantity.focus();
					this.$refs.Quantity.error("La cantidad debe ser mayor a 0");
					isValidated = false;
					return;
				}

				if (this.pldFruitxBoxe <= 0) {
					//this.$fun.alert("Ingrese Fruta x Caja", "warning");
					this.$refs.pldFruitxBoxe.error("Ingrese Fruta x Caja");
					isValidated = false;
					return;
				}

				if(this.objCaliber == null){
					this.$refs.TypeCaliber.error("Seleccione un calibre");
					isValidated = false;
					return;
				}

				if (item.Quantity > this.isDifferenceBoxes()) {
					this.$fun.alert(
						"Esta superando la Cantidad de Cajas solicitadas segun Orden de Produccion",
						"warning",
					);
					isValidated = false;
					return;
				}

				if(this.isDifferenceCultive(item) > 0)
				{
					this.$fun.alert(
						"La plantilla seleccionada tiene un cultivo diferente al que desea agregar",
						"warning",
					);
					isValidated = false;
					return;
				}

				if(this.viewItemSearch.TypeCropName=== "ORGÁNICO"){
					if (this.viewItemSearch.TypeCropName !== item.TypeCropName) {
						this.$fun.alert(
							"El Tipo de Cultivo de la Orden de Produccion es ==> " +
								this.viewItemSearch.TypeCropName +
								" y el Tipo de Cultivo del Lote de Producción es ==> " +
								item.TypeCropName +
								", No se puede agregar. Contacte con el Area de Produccion",
							"warning",
						);
						isValidated = false;
						return;
					}
				}

				

				return isValidated;
			},

			//******************************** FUNCIONES ****************************************** */

			/* inputStretcher(val) {
				if (val !== null) {
					this.TypeCaliber = val.TypeCaliber;
				}
			}, */

			getLotRepresentative() {
				var max = 0;
				var LotID = 0;
				this.itemsDetail.forEach(element => {
					if (max <= element.PldQuantityBoxes) {
						max = element.PldQuantityBoxes;
						LotID = element.RcfID;
					}
				});
				return LotID;
			},
			getProcedenciaRepresentative() {
				// ZonLocationName
 
				var max = 0;
				var LotID = 0;
				this.itemsDetail.forEach(element => {
					if (max <= element.PldQuantityBoxes) {
						max = element.PldQuantityBoxes;
						LotID = element.RcfID;
					}
				});
				const result = _.chain(this.itemsDetail)
    					.groupBy("Ciudad")

				return LotID;
			},
			getFundRepresentative() {
				var max = 0;
				var FagID = 0;
				this.itemsDetail.forEach(element => {
					if (max <= element.PldQuantityBoxes) {
						max = element.PldQuantityBoxes;
						FagID = element.FagID;
					}
				});
				return FagID;
			},

			inputSumBoxes() {
				var total = 0;
				if (this.itemsDetail.length > 0) {
					this.itemsDetail.forEach(element => {
						total += parseInt(element.PldQuantityBoxes);
					});
				}

				this.sumTotalBoxes = total;
			},

			isSumBoxes() {
				var total = 0;
				if (this.itemsDetail.length > 0) {
					this.itemsDetail.forEach(element => {
						total += parseInt(element.PldQuantityBoxes);
					});
				}

				return total;
			},

			isDifferenceBoxes() {
				var difference = 0;
				difference =
					this.viewItemSearch.UwoMaterialListNumberBoxes -
					this.sumTotalBoxes;
				return difference;
			},

			isDifferenceCultive(item)
			{
				var difference = 0;
				if(this.viewItemSearch != null)
				{
					if(this.viewItemSearch.TypeCultive != item.TypeCultive)
					{
						difference = 1
					}
				}
				return difference;
			},

			filtReprocess() {

				var IsRework =  (this.isReprocess) ? 1 : null;
				console.log('valor del reproceso' , this.isReprocess)
				_sPackingService
					.GetStretcher(
						{
							TypeDestiny: this.TypeDestiny,
							TypeProcessingPlant: this.TypeProcessingPlant,
							IsRework : IsRework
						},
						this.$fun.getUserID(),
					)
					.then(r => {
						console.log("this.itemsStretcher22", this.itemsStretcher);
					});
			},

			clickHide(item){
				
				console.log('itemm ', item);
				console.log('this.TypeDestiny ', this.TypeDestiny);
				// if(item.LppID == null){
				// 	this.$fun.alert("Error, no se puede ocultar parihuela, se esta trabajando a nivel de LP", "warning")
				// 	return
				// }
				let itemSend = {};
				itemSend.LppID = item.LppID;
				itemSend.RcfID = item.RcfID;
				itemSend.TypeDestiny = this.TypeDestiny;

				if(this.TypeDestiny != 1){					
					this.$fun.alert("¿ Seguro de ocultar parihuela ?","question").then(resp => {
						if(resp.value){
							_sPackingService.HideParihuela(itemSend, this.$fun.getUserID()).then(data => {
								
								if(data.status == 200){
									this.loadStretcher()
								}								
							})
						}
					})
				}

				if(this.TypeDestiny == 1){					
					this.$fun.alert("¿ Seguro de ocultar parihuela ?","question").then(resp => {
						if(resp.value){
							_sPackingService.HideParihuela(itemSend, this.$fun.getUserID()).then(data => {
								
								if(data.status == 200){
									this.loadStretcher()
								}								
							})
						}
					})
				}

			},

			enabledPallet(row){
				console.log("fila seleccionada", row);

				row.itemsDetail = null
				row.enabledSAP = 10

				console.log("habilitar lote", row);

				this.$fun.alert("Seguro de Habilitar Pallet", "question").then(val => {
					if(val.value){
						_sPackingService.save(row, this.$fun.getUserID()).then(resp => {
							if(resp.status == 200){
								this.$refs.gridPacking.refresh();
							}
						})
					}
				})

			}
			,
			sync(){

				if (this.selected.PltID == null) {
					return;
				}
				console.log("sync", this.selected)

				_sPackingService.syncSap(this.selected, this.$fun.getUserID())
				.then( r => {
					if (r.status == 200) {
						
					}
				});

			},
			difTypeLine(){
				let typeLine = [];
				let message = ""
				this.itemsDetail.forEach(element=>{
					if (typeLine.indexOf(element.TypeLine) === -1) {
						if(typeLine.length!=0){ message = "Atención!!!  esta registrando de diferente linea"}
						typeLine.push(element.TypeLine);
						
					} else if (typeLine.indexOf(element.TypeLine) > -1) {
						console.log(element.TypeLine + " ya existe en la colección de Type Line.");
					}
				});

				return message;
			},
			getDiference(item){
				let result = 0 ;
				this.selectedStretcher = [item];
				console.log("Item22 " , item);
				if(this.itemsDetail.length>0){
				this.itemsDetail.forEach(element => {
						var itemD = element; 
						var diferenceeDis = this.selectedStretcher[0].LppWeightAverageLot  - this.selectedStretcher[0].PldWeight;
						var diferencee = (itemD.PldQuantityBoxes * this.selectedSearch[0].NumInSale)
						console.log("Fila seleccionadaaaa",this.selected);
						if(this.selected){
							console.log("editando111",this.itemRemoved);
							/*if(this.itemRemoved.length>0){
								console.log("Porqueeeee2222",this.itemRemoved);
								if()
								if(itemD.LlpID != null){
									if(item.LlpID == itemD.LlpID){
									this.selectedStretcher[0].LppWeightAverageLot = (diferenceeDis - diferencee) + this.selectedStretcher[0].PldWeight;
									}
								}else{
									if(this.selectedStretcher[0].RcfID == itemD.RcfID){
									this.selectedStretcher[0].LppWeightAverageLot = (diferenceeDis - diferencee) + this.selectedStretcher[0].PldWeight;
									}
									if(this.selectedStretcher.i)
								}															
							}*/
						}else{
							if(itemD.LlpID != null){
								if(item.LlpID == itemD.LlpID){
									this.selectedStretcher[0].LppWeightAverageLot = (diferenceeDis - diferencee) + this.selectedStretcher[0].PldWeight;								
								}
							}else{
								if(this.selectedStretcher[0].RcfID == itemD.RcfID){
									this.selectedStretcher[0].LppWeightAverageLot = (diferenceeDis - diferencee) + this.selectedStretcher[0].PldWeight;							
								}
							}
						}
					});
			
				console.log("Itemmm1111", item);
				}
				console.log("result", result);
			}
		},

	/* 	mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.SendMessageCreatePacking = group => {
					this.loadStretcher();
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "updateWaitingChamber");
				});
			});
		}, */

		
	};
</script>

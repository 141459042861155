import Service from "../Service";

const resource = "taskProyectPermit/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    UsrAllPermit(TkpID,TypeNivelUsr, usrs, requestID) {
        return Service.post(resource + "UsrAllPermit", usrs, {
          params: { TkpID: TkpID,TypeNivelUsr:TypeNivelUsr, requestID: requestID },
        });
    },
}
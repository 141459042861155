import Service from "../Service";

const resource = "taskProyect/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    ticworkers(parameters, requestID) {
        return Service.post(resource + "ticworkers", parameters, {
            params: { requestID: requestID },
        });
    },

    sendmail(task, requestID) {
        return Service.post(resource + "sendmail", task, {
            params: { requestID: requestID },
        });
    },

    taskbystatus(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "taskbystatus", obj, {
            params: { requestID: requestID },
        });
    },

    ticketbymonth(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "ticketbymonth", obj, {
            params: { requestID: requestID },
        });
    },

    ticketbyresponsible(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "ticketbyresponsible", obj, {
            params: { requestID: requestID },
        });
    },

    ticketbyarea(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "ticketbyarea", obj, {
            params: { requestID: requestID },
        });
    },
    ticketbylastmonth(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "ticketbylastmonth", obj, {
            params: { requestID: requestID },
        });
    },
};
<template>
	<div>
		<!-- <v-btn
			color="primary"
			@click="dialogDecrease = true"
		>
			Merma
		</v-btn> -->
		
		<!-- <v-dialog
			width="700px"
			persistent
			v-model="dialogDecrease"
		>
			<decrease 	@closeDialogDecrease="closeDialogDecrease"
			@saveDecrease="saveDecrease"
			v-model="decrease"
			></decrease>
			
		</v-dialog> -->
		<frz-reception-fresh :title="title" :ship="ship">
		</frz-reception-fresh>
	</div>
</template>

<script>
	 
	import FrzReceptionFresh from './Enablebatches.vue';
	
	export default {
		components: {
			FrzReceptionFresh,
			 
		},

		data() {
		
			return {
				title: "Recepción Frescos - Habilitar Lote",
				dialogDecrease: false,
				decrease: {},
				ship: 1
			}
		},
		
		methods: {
			closeDialogDecrease() {
				this.dialogDecrease = false;
			},

			saveDecrease(item)
			{
				this.decrease = item;
			}
		}
	}
</script>
<template>
  <div>
    <s-crud
      title="Registro de Proceso"
      :config="config"
      add
      @add="openModal()"
      addNoFunction
      @rowSelected="rowSelected($event)"
      :filter="filter"
      ref="dataProcess"
      height="auto"
    >
      <template v-slot:filter>
        <v-container>
          <v-row justify="center" class="s-col-form">
            <v-col cols="12" lg="4" md="4">
              <s-select-definition
              v-model="filter.FprTypeProcess"
              label="Proceso"
              :def="1340">

              </s-select-definition>
            </v-col>
            <v-col cols="12" lg="4" md="4"
              ><s-date
                label="Fecha Inicio"
                v-model="filter.BeginDate"
              ></s-date>
            </v-col>
            <v-col cols="12" lg="4" md="4">
              <s-date label="Fecha Fin" 
                v-model="filter.endDate">
              </s-date>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:edited="{ row }">
        <v-btn color="success"
              x-small
              fab
              @click="editRegist(row)"
              dark>
          <v-icon x-small >fa-light fa-pen</v-icon>
        </v-btn>
      </template>

      <i class="fa-light fa-square-pen"></i>

      <!-- <template v-slot:FtmStatus="{ row }">
      <v-chip
        style="margin: 0px"
        x-small
        :color="row.FtmStatus == 1 ? 'success' : 'error'"
      >
        {{ row.FtmStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template> -->
    </s-crud>
    <v-dialog
      persistent
      v-model="ModalRegist"
      transition="dialog-bottom-transition"
      v-if="ModalRegist"
    >
      <v-card class="elevation-0">
        <regist-data :isEdit="isEditProc"  :itemsEdit="itemsData" @close="closeModel()">
        </regist-data>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _sProcessRegistrationService from "@/services/FrozenProduction/ProcessRegistrationService";
import RegistData from "@/views/FrozenProduction/Process/RegistData";

export default {
  components: {
    RegistData,
  },
  data: () => ({
    isEditProc : false,
    itemsData: {},
    ModalRegist: false,
    ftmDescription: "",
    dedValue: 0,
    filter: { BeginDate: null, endDate: null, FprTypeProcess: null},
    config: {
      service: _sProcessRegistrationService,
      model: {
        FprID: "ID",
        FprDate:"date",
        edited : "",
        // FrpDateBegin: "date",
        // FrpDateEnd: "date",
      },
      headers: [
        { text: "Editar", value : "edited", width: "10%", sorteable: true},
        {
          text: "ID",
          value: "FprID",
          width: "5%",
          align: "begin",
          sorteable: true,
        },
        { text: "Fecha", value: "FprDate", width: "10%", sorteable: true },
        { text: "Hora Inicio", value: "FprHourBegin", width: "10%", sorteable: true },
        { text: "Hora Fin", value: "FprHourEnd", width: "10%", sorteable: true },
        { text: "Materia P Movida", value: "FprMovedRawMaterial", width: "10%", sorteable: true },
        { text: "Materia P Movida Acu", value: "FprMovedRawMaterialAcu", width: "10%", sorteable: true },
        { text: "Materia P Procesada", value: "FprProcessRawMaterial", width: "10%", sorteable: true },
        { text: "Materia P Procesada Acu", value: "FprProcessRawMaterialAcu", width: "10%", sorteable: true },
        {
          text: "Tipo de proceso",
          value: "TypeProcessName",
          width: "15%",
          sorteable: false,
        },
        {
          text: "Variedad",
          value: "VrtName",
          width: "5%",
          align: "center",
          sorteable: false,
        },

        {
          text: "Cultivo",
          value: "TypeCropName",
          width: "5%",
          align: "center",
          sorteable: false,
        },
        {
          text: "Tipo cultivo",
          value: "TypeCultiveName",
          width: "5%",
          align: "center",
          sorteable: false,
        },
      ],
    },
  }),

  methods: {
    rowSelected(items) {},

    openModal() {
      console.log("quiero abrir el modal");
      this.ModalRegist = true;
      this.isEditProc = false;
      console.log(this.ModalRegist);
    },

    closeModel() {
      this.$refs.dataProcess.refresh();
      this.ModalRegist = false;
      
      // this.$refs.crudemb.refresh();
    },

    editRegist(item){
      console.log('Registro a editar', item);
      this.itemsData = item;
      this.isEditProc = true;
      this.ModalRegist = true;
    },
    

    saveDed(item) {},
  },
};
</script>

<template v-slot:no-data>
	<div>
		<v-container style="display: contents;">
			<v-row>				
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-row style="margin: auto;">
							<v-col cols="12">
								<s-toolbar
									dark
                                    label="Listado de Parihuelas"
									:color="'#BAB6B5'"
									
								></s-toolbar>
							</v-col>
						</v-row>

						<v-row justify="center" style="margin: auto;">

							<v-col cols="3">
								<s-date label="Fecha Inicio"
									v-model="itemsSendDateBegin"
									@input="searchitemsSend($event)"
									>
								</s-date>
							</v-col>
							<v-col cols="3">
								<s-date label="Fecha Fin"
									v-model="itemsSendDateEnd"
									@input="searchitemsSend($event)"
									>
								</s-date>
							</v-col>
                            <v-col cols="2">
								<s-text label="RcfID"
									v-model="RcfID"
									
									>
								</s-text>
							</v-col>
                            <v-col cols="2">
								<s-text label="Juliano"
									v-model="PrfYuliano"
									
									>
								</s-text>
							</v-col>
                            <v-col cols="2">
                            <v-btn
								style="margin-top: 15px; margin-left: -10px;"
                                class="ma-2"
                                color="primary"
                                dark
								@click="searchitemsSend()"
							>
							    Buscar
                                <v-icon    
                                    dark
                                    right>
                                    mdi-magnify
                                </v-icon>                                                
							</v-btn>
                            </v-col>
						</v-row>
					<!--	<v-row style="margin: auto;">
							<v-col cols="4">
								<s-select-definition
									:def="1230"							 
									@input="inputFilterFrozen($event)"
									label="Tipo">
								</s-select-definition>
							</v-col>
							<v-col cols="4">
								<s-select-definition
									:def="1173"							 
									@input="inputTypeSelectionFrozen($event)"
									label="Tipo">
								</s-select-definition>
							</v-col>
							
						</v-row>-->
						<v-row style="margin: auto;">
							<v-col cols="12">
								
								<v-data-table
									:items="itemsSend"
									item-key="LlpID"
									dense
									:headers="headerSend"
									:items-per-page="15"
									:loading=loading
									disable-sort
									v-model="selected"
									:search="search"
								>
								<!-- show-group-by -->
								<!-- group-by="TypeSelectionFrozenName" rowSelected($event)-->
									<template v-slot:top>
										<v-text-field
										v-model="search"
										label="Search"
										class="mx-4"
										></v-text-field>
									</template>

									<template v-slot:item.PrfYuliano="row">
										<v-chip
											small
											dark
											outlined
											:color="'red'"
										>
											{{row.item.PrfYuliano}}
										</v-chip>
									</template>
									<template v-slot:item.Tranfer="props">
										<v-tooltip top allow-overflow color="primary">
											<!-- fab border-radius: 13px; fa-exchange-alt group-by="TypeDestinyName"-->
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													x-small
													:color="'info'"
													fab
													style="margin: 10px 10px 10px 10px;"
													@click="clickTranfer(props.item)"
													v-bind="attrs"
													v-on="on"
													>
													<v-icon class="fas fa-arrow-circle-up"></v-icon>
												</v-btn>
											</template>
											<span>Transferir</span>
										</v-tooltip>
										
									</template>

									<template v-slot:item.TypeSelectionFrozenName="row">
										{{ ((row.item.TypeSelectionFrozenName === null) ? 'DESCARTE' : row.item.TypeSelectionFrozenName) }}
									</template>

									<template v-slot:item.LlpState="row">									
										<v-chip small :color="((row.item.LlpState == 1) ? 'warning' : 'success')">
											{{ ((row.item.LlpState == 1) ? 'Abastecido' : "En Maduracion") }}
										</v-chip>
									</template>
									<template v-slot:item.SecCreate="row">									
										{{ (new Date(row.item.SecCreate).getHours()) + ":"+
											""+ (new Date(row.item.SecCreate).getMinutes())
										}}
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

	</div>
</template>
<script>

	import SSelectFruitState from '../../../components/FrozenProduction/FruitState/SSelectFruitState.vue';
	//Service
	import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
	import _sLotProdSelPalletSendService from "../../../services/FrozenProduction/FrzLotProductionSelectionPalletSendService"
	import _sLotProdSelPalletService from "../../../services/FrozenProduction/FrzLotProductionSelectionPalletService"
	import _sDamageFlawCultiveService from '@/services/FrozenProduction/DamageFlawCultiveService';	
	import _sLotProdSelPalletDefectService from "../../../services/FrozenProduction/LotProductionSelectionPalletDefectService.js"
	import FrzTransferPallet from './FrzTransferPallet.vue';
    import signalr from "signalr";
	

	export default {
		components: {  SSelectFruitState, FrzTransferPallet },
		data: () => ({
			search: "",
			itemsSendDateBegin: "",
			itemsSendDateEnd: "",
            RcfID: "",
            PrfYuliano: "",
            loading:false,
			FrozenCategories: [],
			TypeFrozenCategory: 0,
			TypeFrozenCategoryName : "",
			
			itemsSend: [],
			headerSend: [
                { text: "Id", value: "LlpID" },
				{ text: "Correlativo", value: "LlpCorrelative" },				
				{ text: "Lote Producción", value: "PrfYuliano" },
				{ text: "Peso Bruto", value: "LlpWeightOriginalGross"},
				{ text: "Peso Neto", value: "LlpWeightOriginalNet" },
				/* { text: "N° Guia Cosecha", value: "RcfNumberHarvestGuide", width: 100 }, */
				/* { text: "Peso Pallet", value: "LlpWeight"}, */
				{ text: "Tipo Selección", value: "TypeSelectionFrozenName" },
				{ text: "Categoría", value: "TypeFrozenCategoryName" },
				{ text: "Cant. Jabas", value: "LlpQuantityJaba" },
				{ text: "Tipo Cultivo", value: "TypeCropName" },
				{ text: "Cultivo", value: "TypeCultiveName" },
				{ text: "Variedad", value: "VrtName" },
				{ text: "Estado", value: "LlpState" },
				{ text: "Hora", value: "SecCreate" },
				
			],
			pallet: {},
			TypeCultive: 0,
			selected: [],
			itemsDetail : [],
			TypeFrozenCategoryEdit : 0,
			LlpID: 0,
		}),
		watch: {
			
		},
		created() {
			this.intitialize();
			//this.close();
			console.log("persmisos sam", this.$fun.getUserInfo().PrsID);
		},

		methods: {
		
			getPalletSend(){
                this.loading=true;
				//samir
				if(this.itemsSendDateBegin == "" || this.itemsSendDateEnd == ""){
                    this.loading = false;
					return;
				}
                console.log("PrsID",this.$fun.getUserInfo().PrsID);
				_sLotProdSelPalletSendService
					.getlotproductionselectionpalletsend(
						{ 
							PrsID       : this.$fun.getUserInfo().PrsID,
							DateBegin   : this.itemsSendDateBegin,
							DateEnd     : this.itemsSendDateEnd,
                            xList       : 1,
                            RcfID       : this.RcfID,
                            PrfYuliano  : this.PrfYuliano
						}
						, this.$fun.getUserID())
					.then(r => {
						this.itemsSend = r.data;
                        this.loading=false;
						console.log("item cargados", this.itemsSend);
						this.TypeFrozenCategoryEdit = r.data.TypeFrozenCategory

					});
			},
			searchitemsSend(date){
				console.log("date", date);
				this.getPalletSend();
			},
			intitialize() {

				let obj = this.$fun.getUserInfo();

				console.log("sam obj", obj);

				let filter = {
					PrsID : obj.PrsID,
				//	TypeCultive : this.TypeCultiveFilter
				}
				console.log('filter ', filter);

				_sLotProdSelPalletService
					.getlotprodselFrozenCatPalletDed(filter ,this.$fun.getUserID())
					.then(r => {
						this.FrozenCategories = r.data;
						console.log('FrozenCategories ', r.data);
					});
				
			},

		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.SendFrozenParihuela = group => {
					this.intitialize();
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "frozenUpdateWaitingSendParihuela");
				});
			});
		},
	};
</script>

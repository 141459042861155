import Service from "../Service";

const resource = "ItemsGroupArticle/";

export default {
    pagination(dtr, requestID) {
        return Service.post(
            resource + "Pagination",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },
    save(dtr, requestID) {
        return Service.post(
            resource + "save",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },
};
import Service from "../Service";

const resource = "printlabel/"

export default{
    pagination(parameters, requestID){
        console.log("filter",parameters);
        return Service.post(resource + "paginationList" , parameters,{
            params:{requestID:requestID},
        });
    },
    // save(parameters, requestID)
    // {
    //     return Service.post(resource + 'save', parameters,{
    //         params:{requestID}
    //     })
    // }

    printLabel(parameters, requestID)
    {
        return Service.post(resource + 'printLabel', parameters,{
            params:{requestID}
        })
    }
}
<template>
	<div>
		<!-- <v-btn
			@click="sendMail()"
		>
			enviar
		</v-btn> -->
		<s-crud
			title="Listado De Tratamientos"
			:config="config"
			:filter="filter"
			edit
			@save="save($event)"
			ref="gridTreatment"
			@rowSelected="rowSelected($event)" 
			searchInput
		>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col>
							<s-date
								label="Fecha Inicio" 
								v-model="filter.DateBegin" 
							></s-date>
						</v-col>
						<v-col>
							<s-date
								label="Fecha Fin" 
								v-model="filter.DateEnd" 
							></s-date>
						</v-col>
						<v-col cols="12" md="3" lg="3">
							<s-select-definition
								:def="1181"
								v-model="filter.TypeDestiny"
								label="Destino"
							>
							</s-select-definition>
						</v-col>
						<!--<v-col >
							<s-select
								label="Responsable"
								:items="workerstic"
								autocomplete
								clearable
								item-text="WorkerName"
								item-value="PrsID"
								v-model="filter.PrsID"
							></s-select>
						</v-col>
						<v-col>
							<s-select-definition
								label="Prioridad"
								:def="1392"
								v-model="filter.TkpPriority"
								clearable
							></s-select-definition>
						</v-col>-->
						<!--<v-col>
							<s-select-definition
								label="Estado"
								:def="1393"
								v-model="filter.TkpStatus"
								clearable
							></s-select-definition>
						</v-col>-->
					</v-row>
				</v-container>
			</template>

			<template scope="props">
				<v-container>
					<v-row justify="center">
						<!--<v-col >
							<s-select
								label="Asignado a"
								:items="workerstic"
								autocomplete
								clearable
								v-model="props.item.PrsID"
								item-text="WorkerName"
								item-value="PrsID"
							></s-select>
						</v-col>
						<v-col>
							<s-select-definition
								label="Prioridad"
								:def="1392"
								v-model="props.item.TkpPriority"
							></s-select-definition>
						</v-col>-->
                        <v-col >
								<s-date
									type="datetime"
									v-model="props.item.TnkHourEntry"
									label="Hora entrada"
								/>
						</v-col>
                        <v-col >
								<s-date
									type="datetime"
									v-model="props.item.TnkHourExit"
									label="Hora Fin"
								/>
						</v-col>
                        <v-col >
								<s-date
									type="datetime"
									v-model="props.item.DateEndCooling"
									label="Hora Fin Congelamiento"
								/>
						</v-col>
						
					</v-row>
				</v-container>
			</template>

			<template v-slot:options>
				<!-- <v-tooltip bottom="" v-if="selected.TskStatus == 1 || selected.TskStatus == 2">
					<template v-slot:activator="{ on }">
						<v-btn text small style="margin-right:5px;" v-on="on" @click="pausedTask()"> 
							<v-icon style="font-size:18px;" v-if="selected.TskPaused == 0"> far fa-pause-circle</v-icon>
							<v-icon style="font-size:18px;" v-if="selected.TskPaused == 1"> far fa-play-circle</v-icon>
						</v-btn>
					</template>
					<span v-if="selected.TskPaused == 0">Pausar</span>
					<span v-if="selected.TskPaused == 1">Iniciar</span>
				</v-tooltip> -->

				<!-- <v-tooltip bottom="" v-if="selected.TskStatus == 1 || selected.TskStatus == 2">
					<template v-slot:activator="{ on }">
						<v-btn text small style="margin-right:5px;" v-on="on" @click="transferTask()"> <v-icon style="font-size:18px;"> fas fa-random</v-icon></v-btn>
					</template>
					<span>Transferir</span>
				</v-tooltip> -->
			</template>

			<!--<template v-slot:Action="{ row }">
				<v-btn
					v-if="row.TkpStatus == 1"
					x-small
					color="success"
					@click="clickActionTask(row)"
				>
					{{'Iniciar'}}
				</v-btn>
				<v-btn
					v-if="row.TkpStatus == 2"
					x-small
					color="error"
					@click="clickActionTask(row)"
				>
					{{'Terminar'}}
				</v-btn>
			</template>-->

			<template v-slot:TkpStatus="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TkpStatusColor"
				>
					{{ row.TkpStatusName }}
				</v-chip>
			</template>

			<!--<template v-slot:TkpPriority="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TkpPriorityColor"
				>
					{{ row.TkpPriorityName }}
				</v-chip>
			</template>-->
		</s-crud>

		<!--<v-dialog
			v-model="dialogTransfer"
			width="500"
		>
			<v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-select
								label="Transferir a"
								:items="workerstic"
								autocomplete
								clearable
								item-text="WorkerName"
								item-value="PrsID"
								v-model="datatransfer.PrsIDTraslate"
							></s-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-textarea
								label="Motivo"
								v-model="datatransfer.TkpReasonTraslate"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								color="primary"
								@click="sendTransfer()"
							>
								{{"Transferir"}}
							</v-btn>
						</v-col>
						<v-col>
							<v-btn
								color="warning"
								@click="cancelTransferTask()"
							>
								{{"Cancelar"}}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>-->

		<v-dialog
			v-model="dialogPaused"
			width="500"
		>
			<v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-textarea
								label="Motivo"
								v-model="dataPaused.TkpReasonTraslate"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								color="primary"
								@click="sendPaused()"
							>
								{{selected.TkpPaused == 0 ? "Pausar" : "Reanudar"}}
							</v-btn>
						</v-col>
						<v-col>
							<v-btn
								color="warning"
								@click="cancelPausedTask()"
							>
								{{"Cancelar"}}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sTaskService from '@/services/FreshProduction/ListTreatmentService.js';
	import sTextarea from '../../../components/Utils/sTextarea.vue';
import { isUndefined } from 'lodash';

	export default {
  components: { sTextarea },
		data() {
			return {
				datatransfer: {},
				dataPaused: {},
				dialogTransfer: false,
				dialogPaused: false,
				selected : {},
				workerstic: [],
				filter: {
					PrsID: 0,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0
				},
				config: {
					model: {
						TnkID: "ID",
						Action: "Action",
					},
					service: _sTaskService,
					headers: [
						{text: "ID", value: "TnkID"},
						//{text: "Acción", value: "Action"},
						{text: "N° de tratamiento", value: "TnkNumberTreatment"},
						{text: "Hora Ingreso", value: "TnkHourEntry"},
						//{text: "Prioridad", value: "TkpPriority"},
						{text: "Hora salida", value: "TnkHourExit"},
						{text: "Duración", value: "tiempo"},
						{text: "Hora fin congelamiento", value: "DateEndCooling"},
						//{text: "Asignado a", value: "WorkerName"},
						// {text: "F. Ini", value: "TskDateBeginName"},
						// {text: "F. Fin", value: "TskDateEndName"},
						{text: "Cantidad Parihuela", value: "DetailCount"},
						{text: "Destino", value: "Destino"},
					]
				},
				
			}
		},
		watch : {
			filter(){
				console.log(this.filter)
			}
		},
		methods: {
			initialize()
			{
				this.getTicWorkers();
			},


			created () {
				this.filter.AreID =  this.$fun.getUserInfo().CtrContract.AreID ?? 0;
				
				this.initialize();
			},
			save(items) {
					//console.log("cerrar lote", items);
					this.$fun
						.alert("Esta seguro de guardar cambios ? ", "question")
						.then(val => {
							if (val.value) {
								//items.UserID = this.$fun.getUserID();
								//items.status = 1;
								_sTaskService
									.save(items, this.$fun.getUserID())
									.then(r => {
										if (r.status == 200) {
											this.$fun.alert(
												"Se guardo correctamente",
												"success",
											);
											this.$refs.gridTreatment.refresh();
										}
									});
								this.$refs.gridTreatment.refresh();
							}
						});
				},
				rowSelected(row){
					console.log("rowSelected",row);
				},
		
		
		},
		mounted() {
			
			this.filter.PrsID =  this.$fun.getUserInfo().PrsID; 
					
			console.log( "USER INFO",this.$fun.getUserInfo()); 
		}
    
    }
</script>

<style scoped>

</style>
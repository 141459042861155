<template>
    <v-card elevation="0">
      <s-toolbar v-if="visibleTitle" label="Persona"  color="#BAB6B5"  close @close="close()" save @save="save()" style="color:white; "/>
      <v-container style="margin: auto; "   >
        <v-row style="margin-left: 5px ;" justify="center" >
          <v-col cols="6" sm="3" md="3" lg="3" >	
            
            <s-select-definition :def="1036" label="Tipo Documento" v-model="person.GenPerson.TypePersonDocument" returnObject  @change="Limit()"/>
          </v-col>
          <v-col   cols="6" sm="3" md="3" lg="3">			
            <s-text
              @keyupEnter="txtDocument()"
              label="Documento*"
              number
              :rules="[
				        v => !!v || 'El campo es obligatorio.',
                v => v.length >= person.GenPerson.TypePersonDocument.DedHelper2 || 'Debe tener al menos '+ person.GenPerson.TypePersonDocument.DedHelper2 +' caracteres'
			        ]"
              ref="document"
              :maxlength="16"
              v-model="person.GenPerson.PrsDocumentNumber"
            />
          </v-col>
          <v-col  cols="6" sm="6" md="6" lg="6" v-if="typeperson == 1">			          
            <s-text
              ref="textNtpName"
              label="Nombre*"
              :rules="[
				        v => !!v || 'El campo es obligatorio.',
                v => v.length >= 3 || 'Debe tener al menos 3 caracteres'
			        ]"
              v-model="person.NtpName"
              :autofocus="person.GenPerson.PrsDocumentNumber.length > 0"
            />
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6" v-if="typeperson == 2">
            <s-text ref="textJrpName" label="Nombre Juridico*" v-model="person.JrpName" :autofocus="person.GenPerson.PrsDocumentNumber.length > 0" />
          </v-col>
        </v-row>
      
  
        <v-row style="margin-left: 5px; " justify="center" v-if="typeperson == 1" >
            <v-col cols="6" sm="6" md="6" lg="6" >
            <s-text label="Apellido Paterno*" 
            :rules="[
				        v => !!v || 'El campo es obligatorio.',
                v => v.length >= 3 || 'Debe tener al menos 3 caracteres'
			        ]"
            v-model="person.NtpPaternalSurname
            " />
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6" >
            <s-text label="Apellido Materno*" 
            :rules="[
				        v => !!v || 'El campo es obligatorio.',
                v => v.length >= 3 || 'Debe tener al menos 3 caracteres'
			        ]"
            v-model="person.NtpMaternalSurname" />
          </v-col>
        </v-row>
  
        <v-row style="margin-left: 5px" justify="center">
          <v-col  cols="4" sm="6"  lg="4" >  
            <s-date  v-model="person.NtpBirthDate" label="Fecha Nacimiento" />
          </v-col>
          <v-col cols="4" sm="6"  lg="4" >
            <s-select-definition :def="1037" label="Género" v-model="person.TypeSex" />
          </v-col>
          <v-col cols="4" sm="6" lg="4" >
            <s-select-definition :def="1502" label="Nacionalidad" v-model="person.GenPerson.NatID" />
            <!-- <s-select-generic label="Nacionalidad" itemvalue="GenID" v-model="person.GenPerson.NatID" autocomplete full :config="configNationality" /> -->
          </v-col>
        </v-row>
        <v-row  justify="center">
          <v-col cols="12" sm="12" lg="12"> 
          <v-tabs v-model="currentItem" centered>
            <v-tab href="#Address"> Direcciones </v-tab>
            <v-tab href="#TelephoneEmail"> Teléfonos y Correos </v-tab>
            <v-tab href="#Driver"> Brevete </v-tab>
            <v-tab href="#Photo"> Foto </v-tab>
            <v-tab href="#Fingerprint"> Huella </v-tab>
          </v-tabs>

          <v-tabs-items v-model="currentItem">
            <v-tab-item :value="'Address'"> 
              <!--  <v-row style="margin-left: 5px; width: 100%;"  justify="center"> -->
                <v-col cols="12" sm="12" lg="12"> 
                  <v-card dense >
                      <v-card-title class="text-h7" style="font-weight: bold;">
                        <v-row>
                          <v-col>
                            {{ title == 0 ? 'Editar' : 'Nueva' }} Dirección 
                          </v-col>
                          <div style="text-align: end;">
                              
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              @click="addItemDetail(0)"
                              color="success"
                            >
                              <v-icon dark>
                                mdi-plus
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-row style="margin-left: 5px" justify="center">        
                          <v-col cols="3" sm="6" lg="3" >
                            <s-select-generic label="Departamento " itemvalue="GenHelper" v-model="DepartmentUbigeo" autocomplete full :config="configDepartment" />
                          </v-col>        
                          <v-col cols="3" sm="6" lg="3" >
                            <s-select-generic label="Provincia" itemvalue="GenHelper" v-model="ProvinceUbigeo" autocomplete full :config="configProvince" />
                          </v-col>
                          <v-col cols="3" sm="6" lg="3">
                            <s-select-generic
                              itemvalue="GenHelper"
                              v-model="DistrictUbigeo"
                              label="Distrito"
                              autocomplete
                              @updateData="updateDataDistrict()"
                              full
                              :config="configDistrict"
                            />
                          </v-col>
                          <v-col cols="3" sm="6" lg="3">
                            <!--<s-select-generic label="Zona" itemvalue="GenID" v-model="ZoneID" autocomplete full :config="configZones"   />-->
                            <s-select-definition :def="1513" label="Zona" v-model="Zone"  ref="ZoneRef" />
                          </v-col>
                        </v-row>
                        <v-row style="margin-left: 5px" justify="center">
                          <v-col cols="4" sm="6"  lg="4" >
                            <s-select-definition :def="1512" label="Tipo de Dirección" v-model="TypeAddress"   ref="TypeAddressRef" />
                          </v-col>
                          <v-col cols="4" sm="6" lg="4">
                            <s-text 
                            :rules="[
                                v => !!v || 'El campo es obligatorio.',
                                v => v.length >= 3 || 'Debe tener al menos 3 caracteres'
                              ]"
                            v-model="address.PadAddress" label=" Dirección" />
                          </v-col>         
                          <v-col cols="4" sm="6" lg="4">
                            <s-text  v-model="address.PadAddressReference" label="Referencia" />
                          </v-col>
                        </v-row>
                        <v-row style="margin-left: 5px" justify="center" >
                          <v-col cols="12" sm="12" lg="12">
                            <template>
                              <v-data-table  :headers="addressHeaders" class="elevation-1" :items="addressDetail" >
                                <template v-slot:[`item.actions`]="{ item }">
                                  
                                  <v-icon
                                    small
                                    class="mr-2"
                                    @click="editItem(item,0)"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                  <v-icon
                                    small
                                    @click="deleteItem(item,0)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>
                               
                              </v-data-table>
                            </template>
                          </v-col>
                        </v-row>
                      </v-card-text>
                  </v-card>
                </v-col>
              <!-- </v-row> --> 
            </v-tab-item>

            <v-tab-item :value="'TelephoneEmail'"> 
              <v-col cols="12" sm="12" lg="12"> 
                <v-row style="" justify="center" > 
                  <v-col cols="8" sm="8" lg="8">
                    <v-card dense    >
                      <v-card-title class="text-h7"  style="font-weight: bold;" >
                        <v-row>
                          <v-col>
                            {{ title == 1 ? 'Editar' : 'Nuevo' }}  Teléfono
                          </v-col>
                          <div style="text-align: end;">
                              
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              @click="addItemDetail(1)"
                              color="success"
                            >
                              <v-icon dark>
                                mdi-plus
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-row style="margin-left: 5px ;margin: auto" >
                          <v-col cols="6" sm="6"  lg="6" >
                            <s-select-definition :def="1111" label="Tipo de Teléfono" v-model="phone.TypePhone" ref="TypePhoneRef" />
                          </v-col>
                          <v-col cols="6" sm="6" lg="6" >
                            <s-text v-model="phone.PpnNumberPhone" type="tel" number label=" Teléfono"   />
                          </v-col>
                          <!-- <v-col cols="4" sm="6" lg="4" >
                          <v-text-field
                            type="number"                   
                          
                            counter="9"
                            max="9"
                            maxlength="9"
                            hint="This field uses counter prop"
                            label="Regular"
                          ></v-text-field>
                          </v-col> -->
                        </v-row>
                        <v-row style="margin-left: 5px" justify="center" >
                          <v-col cols="12" sm="12" lg="12">
                            <template>
                              <v-data-table  :headers="phonesHeaders" class="elevation-1" :items="phonesDetail" >
                                <template v-slot:[`item.actions`]="{ item }">
                                  
                                  <v-icon
                                    small
                                    class="mr-2"
                                    @click="editItem(item,1)"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                  <v-icon
                                    small
                                    @click="deleteItem(item,1)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>                          
                              </v-data-table>
                            </template>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>  
                  <v-col cols="4" sm="4" lg="4"> 
                    <v-card dense >
                      <v-card-title class="text-h7" style="font-weight: bold;">                        
                        <v-row>
                          <v-col>
                            {{ title == 2 ? 'Editar' : 'Nuevo' }} Correo
                          </v-col>
                          <div style="text-align: end;">
                              
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              @click="addItemDetail(2)"
                              color="success"
                            >
                              <v-icon dark>
                                mdi-plus
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-row style="margin-left: 5px ;margin: auto" >
                          <v-col cols="12" sm="12" lg="12" >
                            <s-text label="Email" v-model="email.PemEmail" :rules="[                               
                                v => {
                                  const pattern = validEmail
                                  return pattern.test(v) || 'Correo Invalido.'
                                }
                              ]" 
                              :placeholder="'ejemplo@gmail.com'"
                            />
                          </v-col>
                        </v-row>
                        <v-row style="margin-left: 5px" justify="center" >
                          <v-col cols="12" sm="12" lg="12">
                            <template>
                              <v-data-table  :headers="emailsHeaders" class="elevation-1" :items="emailsDetail" >
                                <template v-slot:[`item.actions`]="{ item }">
                                  
                                  <v-icon
                                    small
                                    class="mr-2"
                                    @click="editItem(item,2)"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                  <v-icon
                                    small
                                    @click="deleteItem(item,2)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>                               
                              </v-data-table>
                            </template>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>                                
                </v-row> 
              </v-col>   
            </v-tab-item>
            <v-tab-item :value="'Driver'"> 
              <!-- <v-row style="margin-left: 5px;width: 100%;" justify="center" v-if="typeperson == 1 "> -->
                <v-col cols="12" sm="12" lg="12">
                  <v-card dense >
                      <v-card-title class="text-h7" style="font-weight: bold;">
                        Brevete
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="4" sm="6" lg="3">
                            <s-text @keyupEnter="save()" v-model="driver.NpdDocumentNumber" label="Brevete" />
                          </v-col>
                          <v-col cols="4" sm="6" lg="3">
                            <s-select-definition :def="1139" label="Categoría" v-model="driver.TypeDocumentCategory" />
                          </v-col>
                          <v-col cols="4" sm="6" lg="3">
                            <s-date v-model="driver.NpdDocumentInit" label="Emisión" />
                          </v-col>
                          <v-col cols="4" sm="6" lg="3">
                            <s-date v-model="driver.NpdDocumentEnd" label="Revalidación" />
                          </v-col>
                        </v-row>
                      </v-card-text>
                  </v-card>
                </v-col>
              <!-- </v-row> -->
            </v-tab-item>
            <v-tab-item :value="'Photo'">
              <v-col cols="12" sm="12" lg="12">
                <v-card dense >
                      <v-card-title class="text-h7" style="font-weight: bold;">
                        Foto
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col md="4"  sm="12" lg="6">
                           
                            <v-img style="border-radius:10px;" height="300" width="300" :src="image?image:'../../../public/static/User.png'" />
                            <v-btn color="primary" @click="openCamera" class="mt-4" >Tomar Foto</v-btn>
                            <v-btn color="success" @click="capturePhoto" class="mt-4" :disabled="!isCameraActive" >Capturar Foto</v-btn>
                          </v-col>
                          <v-col md="4"  sm="12" lg="6">                                                                  
                              <!-- Elemento de video oculto para acceder a la cámara -->
                              <video ref="video" style="width: 100%; height: 300px;" autoplay></video>
                              <!-- Elemento de canvas para tomar la foto -->
                              <canvas ref="canvas" style="display: none;"></canvas>                            
                          </v-col>
                        </v-row>
                      </v-card-text>
                </v-card>
               
              </v-col>  
            </v-tab-item>

            <v-tab-item :value="'Fingerprint'">
    <v-col cols="12" sm="12" lg="12">
      <v-card dense>
        <v-card-title class="text-h7" style="font-weight: bold;">
          <v-row justify="center">
            <v-col cols="6"  class="d-flex justify-center">  <span>IZQUIERDA</span> </v-col>
            <v-col cols="6"  class="d-flex justify-center">   <span>DERECHA</span> </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" class="d-flex justify-center">
              <!-- Imagen de las manos con contenedor relative -->
              <div style="position: relative; display: inline-block;">
                <v-img 
                  lazy-src="@/assets/hands.png"
                  max-height="400"
                  max-width="700"
                  src="@/assets/hands1.png"
                  class="d-flex justify-center align-center"
                />

                <!-- Numeración de huellas encima de la imagen -->
                <div 
                  v-for="(finger, index) in fingers" 
                  :key="index"
                  class="finger"
                  :style="{
                    position: 'absolute',
                    top: finger.position.top,
                    left: finger.position.left,
                    backgroundColor: selectedFinger === index ? 'orange' : 'transparent', 
                    cursor: 'pointer', 
                    width: '30px', 
                    height: '30px', 
                    borderRadius: '50%', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    color: 'black',
                    transition: 'background-color 0.3s'
                  }"
                  @mouseover="highlightFinger(index)"
                  @mouseleave="resetHighlight"
                  @click="selectFinger(index)"
                >
                  {{ index + 1 }} <!-- Número de la huella -->
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-tab-item>

          </v-tabs-items>
          
          </v-col>
          
        </v-row>
        <v-dialog v-model="showModal" max-width="300px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center"  style="background-color: #fadf9b;">
          <span class="headline" >{{ statusOpen?"Captura Huella":"Esperando...." }}</span>
        </v-card-title>
        <v-card-text style="margin-top: 5px">
          <div class="d-flex justify-center">
            <!-- Icono de huella con animación de escaneo -->
            <div class="fingerprint-icon-container">
              <v-img 
                src="@/assets/FingrPrint.png" 
                alt="Fingerprint Icon" 
                class="fingerprint-icon"
                max-height="150"
                max-width="150"
                v-if="scanning"
                :style="{ backgroundColor: statusOpen ? '#ffcec7' : (captured ? '#d5fb9f' : 'transparent') }"
              >
                <v-progress-linear 
                  v-if="scanning" 
                  indeterminate 
                  color="red" 
                  class="scanning-line"
                   :style="{ height: '10px' }"
                />

              </v-img>
              <v-progress-linear 
                  v-if="scanning" 
                  indeterminate 
                  color="red" 
                  class="scanning-line"
                   :style="{ height: '10px' }"
                />
              <!-- Check verde cuando la huella se haya capturado correctamente -->
              <v-icon v-if="captured" color="green" class="capture-success"   style="font-size: 50px;">
                mdi-check-circle
              </v-icon>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">

          <v-btn  
            color="" 
            @click="CloseFootprint"
            :disabled="captured"
          >
            Cerrar
          </v-btn>
        </v-card-actions> 
      </v-card>
    </v-dialog>

        
      </v-container>
      
    </v-card>

    
  </template>
  
  <script>
  import Service from "@/services/General/PersonService";
  import _sGeneric from "@/services/General/GenericService";
  import _sContracthmRs from "@/services/HumanResource/HmnContractService";
  import SText from "@/components/Utils/SText.vue";


  export default {
    name: "ContractPersonAdd",
    components: { SText },
    props: {
      propPerson: {
        type: Object,
        default: null,
      },
      visibleTitle: {
        type: Boolean,
        default: true,
      },
      full: {
        type: Boolean,
        default: false,
      },
      typeperson: {
        type: Number,
        default: 1,
      },
      addTypeSex: {
        type: Boolean,
        default: false,
      },
      addBirthDate: {
        type: Boolean,
        default: false,
      },
      addDriver: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showModal: false, // Controla si el modal está visible
      scanning: false, // Bandera para saber si estamos en el proceso de escaneo
      captured: false, // Bandera para saber si la huella ha sido capturada correctamente
        editing: false,
        configNationality: null,
        statusOpen:false,
        configZones: null,
        TypeAddress:null,
        configDepartment: null,
        DepartmentUbigeo: "25",
        ProvinceUbigeo: "00",
        DistrictUbigeo: "",
        item: 0,
        Zone: null,
        editedIndex:-1,
        currentTab: 0,
        currentItem:"tab-Funciones",
        isCameraActive: false, // Para saber si la cámara está activa
        itemsNationality: [],
        image:null,
        menu3: false,
        title : -1,
        config: {},
        dialog: false,
        validEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        fingers: [

        { id: 0, position: { top: '17%', left: '13%' }, style: {} }, // Pulgar izquierdo
        { id: 1, position: { top: '10%', left: '18%' }, style: {} }, // Índice izquierdo
        { id: 2, position: { top: '8%', left: '23%' }, style: {} }, // Medio izquierdo
        { id: 3, position: { top: '10%', left: '29%' }, style: {} }, // Anular izquierdo
        { id: 4, position: { top: '39%', left: '42%' }, style: {} }, // Meñique izquierdo
        { id: 5, position: { top: '39%', left: '52%' }, style: {} }, // Pulgar derecho
        { id: 6, position: { top: '10%', left: '65%' }, style: {} }, // Índice derecho
        { id: 7, position: { top: '8%', left: '70%' }, style: {} }, // Medio derecho
        { id: 8, position: { top: '10%', left: '76%' }, style: {} }, // Anular derecho
        { id: 9, position: { top: '17%', left: '81%' }, style: {} }  // Meñique derecho

        ],
        selectedFinger: null,
        phone: {
          TypePhone: 1,
          PpnNumberPhone: "",
          PpnIsDefault: 1,
        },
        addressDetail:[
          // {
          //   PadID:1,
          //   PadCodeUbigeo: '200101',
          //   PadAddress: "CALLE APURIMAC N° 640",
          //   PadAddressReference:  "REFERENCIA FRENTE AL POZO DE AGUA",
          //   PadStreet: "",
          //   TypeAddress: 2,
          //   TypeAddressName: "Domicilio Actual",
          //   ZonID:7,
          //   ZonName:"CATACAOS",
          //   PadIsDefault:1
          // },
          // {
          //   PadID:2,
          //   PadCodeUbigeo: '200104',
          //   PadAddress: "CHICA ALTA",
          //   PadAddressReference: "REFERENCIA PACHACUTEC",  
          //   TypeAddress: 1,        
          //   TypeAddressName: "Domicilio DNI",
          //   ZonID:7,
          //   ZonName:"CATACAOS",
          //   PadIsDefault:1
          // },
        ],
        phonesDetail:[
          // {
          //   TypePhone: 1,
          //   TypePhoneName: "Celular",
          //   PpnNumberPhone: "99999999",
          //   PpnIsDefault: 1,
          // },
        ],
        emailsDetail:[
          // {
          //   PemEmail: "ejemplo@gmail.com",
          //   PemIsDefault: 1,
          // }
        ],
        address: {
          PadCodeUbigeo: "",
          PadAddress: "...",      
          PadAddressReference: "",        
          PadDepartment: "",
          ZonID: 1,
          PadIsDefault: 1,
          TypeAddress:1,
        },
        addressDefault: {
          PadCodeUbigeo: "",
          PadAddress: "...",      
          PadAddressReference: "",        
          PadDepartment: "",
          ZonID: 1,
          PadIsDefault: 1,
          TypeAddress:1,
        },
        addressHeaders: [ 
          //{ text: "ID"                  , value: "PadID"                  ,sortable: false  , width: 20,  },
          { text: "Distrito Ugigeo"     , value: "PadCodeUbigeo"          ,sortable:false   , width: 50,  },
          { text: "Direccion"           , value: "PadAddress"             ,sortable:false   , width: 100, },         
          //{ text: "Tipo de Dirección"   , value: "TypeAddress"            ,sortable:false   , width: 50,  },   
          { text: "Tipo de Dirección"   , value: "TypeAddressName"        ,sortable:false   , width: 50,  },             
          { text: "Referencia"          , value: "PadAddressReference"    ,sortable:false   , width: 100, },
          //{ text: "Zona"                , value: "ZonID"                  ,sortable:false   , width: 70,  },
          { text: "Zona"                , value: "ZonName"                ,sortable:false   , width: 70,  },
          { text: 'Acciones'            , value: 'actions'                ,sortable: false  , width: 30,  },
			  ],
        phonesHeaders: [ 
          //{ text: "ID"                  , value: "PpnID"                 ,sortable: false  , width: 20,  },
          { text: "Tipo de Telfono"     , value: "TypePhoneName"         ,sortable: false  , width: 20,  },
          { text: "Telefono"            , value: "PpnNumberPhone"        ,sortable: false  , width: 20,  },
          { text: 'Acciones'            , value: 'actions'               ,sortable: false  , width: 30,  },
			  ],        
        emailsHeaders: [ 
          //{ text: "ID"                  , value: "PemID"                 ,sortable: false  , width: 20,  },
          { text: "Correo"              , value: "PemEmail"              ,sortable: false  , width: 20,  },
          { text: 'Acciones'            , value: 'actions'               ,sortable: false  , width: 30,  },
			  ],                
        email: {
          PemEmail: "",
          PemIsDefault: 1,
        },
        person: {
          NtpName: "",
          NtpPaternalSurname: "",
          NtpMaternalSurname: "",
          NtpID: 0,
          NtpBirthDate: null,
          TypeSex: 1,
          GenPerson: {
            TypePersonDocument: 0,
            PrsDocumentNumber: "",
            NatID: 1,
            Telephones:"0",
          },
          GenNaturalPersonDriver: null,
        },
        driver: {
          NpdID: 0,
          NpdDocumentNumber: "",
          NtpID: 0,
          NpdDocumentInit: null,
          NpdDocumentEnd: null,
          TypeDocumentCategory: 0,
        },
        isInitializing: false, // Bandera para controlar la inicialización
      };
      
    },
    methods: {
      changeAddress(item){
        console.log("itemmAdrress",item);
        this.TypeAddress = item;
      },
      highlightFinger(index) {
        this.selectedFinger = index;
      },
      resetHighlight() {
        this.selectedFinger = null;
      },
      selectFinger(index) {
        console.log('Huella seleccionada:', index);
        this.initializeDevice(index);
        this.showModal = true;
        this.scanning = true;  // Empezar la animación de escaneo
        this.captured = false; // Resetear la bandera de captura
      },
      deleteItem (item,detail) {
        console.log("deleteItem",item);
        switch (detail){
          case 0:
            this.addressDetail.splice(item, 1);
            break;
          case 1:
            this.phonesDetail.splice(item,1);
            break;
          case 2:
            this.emailsDetail.splice(item,1);
            break;
          default:
            break;
        }
        //this.addressDetail.splice(item, 1);
        // this.editedIndex = this.addressDetail.indexOf(item)
        // this.editedItem = Object.assign({}, item)
        // this.dialogDelete = true
      },

      editItem(item,detail) {
        console.log("Direcciones2222",item);
          //this.ZoneID = item.ZonID;
          
        
          // this.editedItem = Object.assign({}, item)
          // this.dialogDelete = true
          // this.DistrictUbigeo = item.PadCodeUbigeo;
          this.isInitializing = true;
          switch (detail){
            case 0:
              this.editedIndex = this.addressDetail.indexOf(item)
              // Deshabilita temporalmente el watch
              this.address = item;
              this.title = 0;
              this.TypeAddress = this.address.TypeAddress;
              //this.TypeAddress.DedDescription= this.address.TypeAddressName;
              this.Zone = this.address.ZonID;
              //this.Zone.DedDescription = this.address.ZonName;
              this.updateUbigeo(item.PadCodeUbigeo);
              // this.DepartmentUbigeo = item.PadCodeUbigeo.substr(0, 2);
              // this.ProvinceUbigeo   = item.PadCodeUbigeo.substr(2,2);
              // this.DistrictUbigeo   = item.PadCodeUbigeo.substr(4, 2);
              break;
            case 1:
              this.editedIndex = this.phonesDetail.indexOf(item);
              this.title = 1;
              this.phone= item;
              break;
            case 2:
              this.editedIndex = this.emailsDetail.indexOf(item)
              this.title = 2;
              this.email= item;
              break;
            default:
              this.title = -1;
              break;
        }
        console.log("Edit",this.editedIndex);
      },

      async  updateUbigeo(ubigeo) {
        const department  = ubigeo.substr(0, 2);
        const province    = ubigeo.substr(2, 2);
        const district    = ubigeo.substr(4, 2);

    

        this.DepartmentUbigeo = department;
        await this.updateProvinces(department,province);
        this.ProvinceUbigeo = province;
        // console.log("ProvinceUbigeo",this.ProvinceUbigeo)
        await this.updateDistricts(province,district);
        this.DistrictUbigeo = district;
        this.$nextTick(() => {
          this.isInitializing = false; // Reactiva el watch después de inicializar        
        });
      },

      async updateProvinces(department,province){
        this.loadingProvinces = true; // Puedes usar esto para mostrar un spinner mientras cargas
        try {
          this.ProvinceUbigeo =  province;
          this.configProvince = {
            title: "Provincia",
            url: _sGeneric.getProvince(),
            params: {
              requestID: this.$fun.getUserID(),
              DepartmentUbigeo: department,
              SearchText:"" 
            },
          }
        } catch (error) {
          console.error("Error al cargar distritos:", error);
        } finally {
          this.loadingDistricts = false;
          
        }
      },

      async updateDistricts(province,district) {
        this.loadingDistricts = true; // Puedes usar esto para mostrar un spinner mientras cargas
        try {
          this.DistrictUbigeo = district;
          this.configDistrict = {
              title: "Distrito",
              url: _sGeneric.getDistrict(),
              params: {
                requestID: this.$fun.getUserID(),
                DistrictUbigeo: this.DepartmentUbigeo.toString() + province,
                SearchText: "" 
              },
          }
        } catch (error) {
          console.error("Error al cargar distritos:", error);
        } finally {
          this.loadingProvinces = false;
        }
      
      },

      addItemDetail(detail){
        console.log("Editindex",this.editedIndex);
        console.log("TypeAddress",this.TypeAddress);
        console.log("ZonID",this.Zone);       
        
        //this.close()
        //this.addressDetail.push(address);
        switch (detail){
          case 0:
              let address = {
                PadCodeUbigeo : this.DepartmentUbigeo  + this.ProvinceUbigeo + this.DistrictUbigeo,
                PadAddress: this.address.PadAddress,      
                PadAddressReference: this.address.PadAddressReference,        
                PadDepartment: "",
                ZonID: this.Zone ? this.Zone : 1,
                ZonName: this.$refs.ZoneRef.items.find((x) => x.DedValue === this.Zone).DedDescription,
                //objZone: this.Zone,
                TypeAddress: this.TypeAddress ? this.TypeAddress : 3,
                TypeAddressName : this.$refs.TypeAddressRef.items.find((x) => x.DedValue === this.TypeAddress).DedDescription,
                //objTypeAddress: this.TypeAddress,
                PadIsDefault: 1,
                UsrCreateID: this.$fun.getUserID(),
                UsrUpdateID: this.$fun.getUserID()
              };
              
              if (this.editedIndex > -1) {
                Object.assign(this.addressDetail[this.editedIndex], address)
                this.editedIndex = -1;                
              } else {
                this.addressDetail.push(address)
              }
            break;
          case 1:
            let phone ={          
              TypePhone: this.phone.TypePhone,
              TypePhoneName: this.$refs.TypePhoneRef.items.find((x) => x.DedValue === this.phone.TypePhone).DedDescription,
              PpnNumberPhone: this.phone.PpnNumberPhone,
              PpnIsDefault: 1,
              UsrCreateID: this.$fun.getUserID(),
              UsrUpdateID: this.$fun.getUserID()
            } 
            if (this.editedIndex > -1) {
              Object.assign(this.phonesDetail[this.editedIndex], phone);
              this.editedIndex = -1;
            } else {
              this.phonesDetail.push(phone);
            }
            break;
          case 2:
            let email = {
              PemEmail: this.email.PemEmail,
              PemIsDefault: 1,
              UsrCreateID: this.$fun.getUserID(),
              UsrUpdateID: this.$fun.getUserID()
            }
            if (this.editedIndex > -1) {
              Object.assign(this.emailsDetail[this.editedIndex], email);
              this.editedIndex = -1;
            } else {
              this.emailsDetail.push(email);
            }
            break;
          default:
            break;
        }
        this.title= -1 ;
        this.clearDetail();
      },

      clearDetail(){
        this.address = this.addressDefault;
        this.DepartmentUbigeo = "20";
        this.TypeAddress = 3;
        this.Zone = 1;
       
        this.phone.TypePhone = 1;
        this.phone.PpnNumberPhone = "";       
        this.email.PemEmail = "";
      },

      initialize(){

      },
      initializeDevice(index) {
        let obj = {
          WftID: 0,
					DNI: this.person.GenPerson.PrsDocumentNumber,
					WftStatus: 1,
          WfpTypeFinger:index,
          UsrCreateID:this.$fun.getUserID()
        	}
      console.log("objInit",obj);
      console.log("statu",this.statusOpen);
    _sContracthmRs.InitialFingerprint(obj,this.$fun.getUserID())
              .then(response => {
                  this.statusOpen=true;
                  console.log("msg",response);
              })
              .catch(error => {
                  console.error("Error initializing device:", error);
              });

      },
      
      CaptureFootprint(){
        this.scanning = true;
        _sContracthmRs.captureFingerprint()
        .then(fingerprintData => {
          
            // Do something with the fingerprint data (e.g., show it on screen)
          console.log("Captured fingerprint:", fingerprintData.fingerprintBase64);
          // Finalizar la animación de escaneo
          this.scanning = false;

          // Mostrar el icono de check verde para indicar éxito
          this.captured = true;
        })
        .catch(error => {
            // Handle error
            this.scanning = false;  // Detener la animación
            alert('No se pudo capturar la huella');
        });
      },
      CloseFootprint(){
        this.showModal=false;
        this.captured = false;
        this.scanning = false;
        this.statusOpen=false;
      },
      // Método para abrir la cámara
      openCamera() {
        navigator.mediaDevices
          .getUserMedia({ video: { width: 640, height: 480 } }) // Resolución baja
          .then((stream) => {
            this.$refs.video.srcObject = stream; // Asignar el stream al elemento <video>
            this.$refs.video.style.display = 'block'; // Mostrar el video en la interfaz
            this.$refs.video.play(); // Reproducir video
            this.isCameraActive = true; // Indicar que la cámara está activa
          })
          .catch((err) => {
            console.error("Error al acceder a la cámara: ", err);
            this.isCameraActive = false;
          });
      },
      // Método para capturar la foto desde el video
      capturePhoto() {
        const video = this.$refs.video;
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");

        // Asegurarnos que el canvas tiene el mismo tamaño que el video
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Dibujar el fotograma actual del video en el canvas
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convertir la imagen a base64
        const dataUrl = canvas.toDataURL("image/jpeg");

        // Asignar la imagen capturada al estado `image`
        this.image = dataUrl;

        // Detener la cámara
        const stream = video.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
        video.srcObject = null;
        this.isCameraActive = false;

        // Subir la imagen al servidor
        //this.uploadImage(dataUrl);
      },
      Limit(){
        console.log("Limit",this.person.GenPerson.TypePersonDocument)  ;
      },
      updateDataDistrict() {
        if (this.person != null)
          if (this.person.GenPerson != null)
            if (this.person.GenPerson.Addresses != null)
              if (this.person.GenPerson.Addresses.length > 0) {
                this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
              }
      },
      txtDocument() {
        if (this.typeperson == 1) this.$refs.textNtpName.focus();
        else this.$refs.textJrpName.focus();
      },
      search() {
        this.clear();
        console.log("Persona11111",this.person);
        Service.getNaturalPerson(this.person.GenPerson.PrsDocumentNumber, this.$fun.getUserID()).then((r) => {
          if (r.status == 200) {
            this.editing = true;
            this.personEdit = r.data;
            if (this.personEdit != null) {
              if (this.personEdit.GenPerson != null) {
                this.person = { ...this.personEdit };
                // this.person.NtpBirthDate = this.$moment(this.personEdit.NtpBirthDate).format("DD-MM-YYYY");
                this.person.NtpBirthDate = this.personEdit.NtpBirthDate;
                this.person.GenPerson.TypePersonDocument = 1;
  
                if (this.person.GenPerson.Emails.length > 0) {
                  this.email = this.person.GenPerson.Emails[0];
                }
                if (this.person.GenPerson.Telephones.length > 0) {
                  this.phone = this.person.GenPerson.Telephones[0];
                }
                if (this.person.GenPerson.Addresses.length > 0) {
                  this.ZoneID = this.person.GenPerson.Addresses[0].ZonID;
                  this.address = this.person.GenPerson.Addresses[0];
                  this.DepartmentUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 2);
                  this.ProvinceUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 4);
                  this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
                }
                if (this.person.GenNaturalPersonDriver.length > 0) {
                  this.driver = this.person.GenNaturalPersonDriver[0];
                }
              } else if (this.propPerson.PrsDocumentNumber) this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
  
              if (this.typeperson == 1) this.$refs.textNtpName.focus();
              else this.$refs.textJrpName.focus();
            }
            this.editing = false;
          }
          this.$emit("search", this.person);
        });
      },
      clear() {
        this.ZoneID = 15;
        this.DepartmentUbigeo = "20";
        this.ProvinceUbigeo = "2001";
        this.phone = {
          TypePhone: 1,
          PpnNumberPhone: "",
          PpnIsDefault: 1,
        };
        this.address = {
          PadCodeUbigeo: "",
          PadAddress: "",
          PadAddressReference: "",
          PadStreet: "",
          PadDepartment: "",
          ZonID: 0,
          PadIsDefault: 1,
        };
        this.email = {
          PemEmail: "",
          PemIsDefault: 1,
        };
        this.driver = {
          NpdID: 0,
          NpdDocumentNumber: "",
          NpdDocumentInit: null,
          NpdDocumentEnd: null,
          TypeDocumentCategory: 0,
        };
  
        let doc;
        if (this.person.GenPerson.PrsDocumentNumber.length > 0) doc = this.person.GenPerson.PrsDocumentNumber;
        else doc = "";
        this.person = {
          NtpName: "",
          NtpPaternalSurname: "",
          NtpMaternalSurname: "",
          NtpID: 0,
          NtpBirthDate: this.$moment().format(this.$const.DateMomentFormat),
          TypeSex: 1,
          GenPerson: {
            TypePersonDocument: 1,
            PrsDocumentNumber: doc,
            NatID: 193,
          },
        };
      },
      save() {
    
        this.person.GenPerson.TypePerson = this.typeperson;
        this.person.GenPerson.TypePersonDocument = this.person.GenPerson.TypePersonDocument.DedValue;  
        // this.person.GenPerson.Telephones = "3232323";
        if (this.person.GenPerson.PrsDocumentNumber.trim().length < 6) {
          this.$fun.alert("Numero de Documento Incorrecto", "warning");
          return;
        }
        if (this.person.NtpName.trim().length < 1) {
          this.$fun.alert("Registre Nombre", "warning");
          return;
        }
        if (this.person.NtpPaternalSurname.trim().length < 1) {
          this.$fun.alert("Registre Apellido Paterno", "warning");
          return;
        }
        if (this.person.NtpMaternalSurname.trim().length < 1) {
          this.$fun.alert("Registre Apellido Materno", "warning");
          return;
        }
  
        this.person.GenPerson.PrsDocumentNumber = this.person.GenPerson.PrsDocumentNumber.trim();
        this.person.NtpName = this.person.NtpName.toUpperCase();
        this.person.NtpPaternalSurname = this.person.NtpPaternalSurname.toUpperCase();
        this.person.NtpMaternalSurname = this.person.NtpMaternalSurname.toUpperCase();
       
        // if (this.full) {
          if (!this.$fun.isValidDate(this.person.NtpBirthDate)) {
            this.$fun.alert("Fecha de Nacimiento Inválida", "warning");
            return;
          }
  
          if (this.address.PadAddress == null || this.address.PadAddress.trim().length < 2) {
            this.$fun.alert("Falta Dirección", "warning");
            return;
          }
          
          
          // this.person.GenPerson.Telephones = [this.phone];
          // this.person.GenPerson.Emails = [this.email];
          // this.address.ZonID = this.ZoneID;
          

          // this.address.PadCodeUbigeo = this.DistrictUbigeo;
          // this.address.PadAddress = this.address.PadAddress.toUpperCase();
          // this.person.GenPerson.Addresses = [this.address];
  
          // if (this.person.NtpID != 0) {
          //   if (this.phone.PrsID == 0 || this.phone.PrsID == null) this.person.GenPerson.Telephones = [];
          //   if (this.email.PrsID == 0 || this.email.PrsID == null) this.person.GenPerson.Emails = [];
          //   if (this.address.PrsID == 0 || this.address.PrsID == null) this.person.GenPerson.Addresses = [];
          // }
        // } else {
          console.log("phone", this.phone);
          console.log("email", this.email);
          console.log("PadAddress",  this.address.PadAddress);
          console.log("ZoneID", this.ZoneID);
          console.log("DistrictUbigeo", this.DistrictUbigeo);

          // this.address.ZonID = this.ZoneID;    
          // this.address.PadCodeUbigeo = this.DepartmentUbigeo + this.ProvinceUbigeo + this.DistrictUbigeo;
          // this.address.PadAddress = this.address.PadAddress.toUpperCase();

          this.person.GenPerson.Telephones = this.phonesDetail;
          this.person.GenPerson.Emails = this.emailsDetail;
          this.person.GenPerson.Addresses = this.addressDetail;
        // }
  
        let p = { ...this.person };
        console.log("Persona",p);
        if (this.full)
          p.NtpBirthDate =
            this.person.NtpBirthDate.substr(6, 4) + "-" + this.person.NtpBirthDate.substr(3, 2) + "-" + this.person.NtpBirthDate.substr(0, 2);
  
        // if (p.GenPerson.Emails[0] == null || p.GenPerson.Emails[0].PemID == null) p.GenPerson.Emails = null;
        // if (p.GenPerson.Addresses[0] == null || p.GenPerson.Addresses[0].PadID == null) p.GenPerson.Addresses = null;
        // if (p.GenPerson.Telephones[0] == null || p.GenPerson.Telephones[0].PpnID == null) p.GenPerson.Telephones = null;
  
        if (this.driver) {
          if (this.driver.NpdDocumentNumber.length > 0) {
            if (this.driver.TypeDocumentCategory == 0 || this.driver.TypeDocumentCategory == null) {
              this.$fun.alert("Seleccione Categoría", "warning");
              return;
            }
  
            if (!this.$fun.isValidDate(this.driver.NpdDocumentEnd)) {
              this.$fun.alert("Seleccione Fecha de Revalidación", "warning");
              return;
            }
            if (!this.$fun.isValidRangeDate(this.driver.NpdDocumentInit, this.driver.NpdDocumentEnd)) {
              this.$fun.alert("Verifique Fechas de Validez", "warning");
              return;
            }
            this.driver.SecStatus = 1;
            this.driver.NtpID = p.NtpID;
            this.driver.UsrCreateID = this.$fun.getUserID();
            this.driver.UsrUpdateID = this.$fun.getUserID();
            p.GenNaturalPersonDriver = [this.driver];
            // p.GenNaturalPersonDriver = this.driver;
          } else {
            if (this.driver.NpdID > 0) {
              this.driver.SecStatus = 0;
              this.driver.NtpID = p.NtpID;
              p.GenNaturalPersonDriver = [this.driver];
              // p.GenNaturalPersonDriver = this.driver;
            }
          }
        }
        console.log("Persona",p);
        Service.savenaturalperson(p, this.$fun.getUserID()).then((r) => {
          this.$emit("personSaved", this.person);
          this.$emit("save", this.person);
          this.person.GenPerson.PrsDocumentNumber = "";
          this.clear();
          this.$fun.alert("Guardado Correctamente", "success");
          this.close();
        });
      },
      close() {
        this.$refs.document.focus();
        this.$emit("toclose", 2);
        this.clear();
      },
    },
    computed:{
      // formTitle () {
      //   //return this.editedIndex === -1 ? 'Nuevo' : 'Editar'
      // },
    },
    watch: {
      propPerson() {
        if (this.propPerson != null) {
          if (this.propPerson.GenPerson != null) {
            this.person = { ...this.propPerson };
            // this.person.NtpBirthDate = this.$moment(this.propPerson.NtpBirthDate).format("DD-MM-YYYY");
            this.person.NtpBirthDate = this.propPerson.NtpBirthDate;
            this.person.GenPerson.TypePersonDocument = 1;
  
            if (this.person.GenPerson.Emails.length > 0) this.email = this.person.GenPerson.Emails[0];
            if (this.person.GenPerson.Telephones.length > 0) {
              this.phone = this.person.GenPerson.Telephones[0];
            }
            if (this.person.GenPerson.Addresses.length > 0) {
              this.address = this.person.GenPerson.Addresses[0];
              // this.DepartmentUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 2);
              // this.ProvinceUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 4);
              // this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
            }
            if (this.person.GenNaturalPersonDriver.length > 0) {
              this.driver = this.person.GenNaturalPersonDriver[0];
            }
          } else {
            this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
          }
        }
      },
      DepartmentUbigeo() {
        if (this.isInitializing) return;
        this.ProvinceUbigeo = 0;
        this.configProvince = {
          title: "Provincia",
          url: _sGeneric.getProvince(),
          params: {
            requestID: this.$fun.getUserID(),
            DepartmentUbigeo: this.DepartmentUbigeo,
            SearchText:"" 
          },
        };
      },
      ProvinceUbigeo() {
        if (this.isInitializing) return;
        this.DistrictUbigeo = 0;
        
        console.log("Privincia1111",this.DepartmentUbigeo.toString() + this.ProvinceUbigeo.toString());
        console.log("Privincia2222",this.ProvinceUbigeo);
        if (this.ProvinceUbigeo != 0){
          this.configDistrict = {
            title: "Distrito",
            url: _sGeneric.getDistrict(),
            params: {
              requestID: this.$fun.getUserID(),
              DistrictUbigeo: this.DepartmentUbigeo.toString() + this.ProvinceUbigeo.toString(),
              SearchText: "" 
            },
          };
        }
        //if (this.ProvinceUbigeo == "2001" && this.editing == false) this.DistrictUbigeo = "200114";
        //if( this.DepartmentUbigeo=='20') this.DistrictUbigeo='14'; else this.DistrictUbigeo='01'
        // if(this.editItemAddress){ 
        //   this.ProvinceUbigeo = this.address.PadCodeUbigeo.substr(2, 2);              
        // }
      },
      DistrictUbigeo(){
        // console.log("DistrictUbigeo3333",this.DistrictUbigeo);
        // if(this.editItemAddress){ this.DistrictUbigeo = this.address.PadCodeUbigeo.substr(4, 2); }
      },
      
    },
    created() {
      //if (this.full) {
        this.configNationality = {
          title: "Nacionalidad",
          url: _sGeneric.getNationality(),
          params: { requestID: this.$fun.getUserID() },
        };
        this.configZones = {
          title: "Zonas",
          url: _sGeneric.getZones(),
          params: { requestID: this.$fun.getUserID() },
        };
        this.configDepartment = {
          title: "Departamento",
          url: _sGeneric.getDepartment(),
          params: { requestID: this.$fun.getUserID() },
        };

        this.configProvince = {
          title: "Provincia",
          url: _sGeneric.getProvince(),
          params: { requestID: this.$fun.getUserID(), DepartmentUbigeo: 20 , SearchText:"" },
        };

        this.configDistrict = {
          title: "Distrito",
          url: _sGeneric.getDistrict(),
          params: { requestID: this.$fun.getUserID(), DistrictUbigeo: "2201" , SearchText:"" },
        };
        console.log("Distrito",this.configDistrict);
        
  
        this.DepartmentUbigeo = "20";
      //}
      if (this.propPerson != null)
        if (this.propPerson.GenPerson != null) {
          this.person = Object.assign(this.propPerson, {});
        } else this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
    },
    mounted(){
        console.log("mounted",this.person.GenPerson.TypePersonDocument);
		let recaptchaScript = document.createElement("script");
		recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
		document.head.appendChild(recaptchaScript);

		recaptchaScript.addEventListener("load", () => {
			$.connection.hub.url = this.$const.URL + "/signalr";
			var chat = $.connection.notification;
			chat.client.SendMessageTagfingerprint = group => {
        this.captured = true;
        this.scanning = false;
        this.statusOpen=false;
        setTimeout(() => {
                this.showModal = false;
            }, 1000);
			};

			$.connection.hub.start({ jsonp: true }).done(e => {
				chat.invoke("addToGroup", "fingerprintData");
			});
		});

    }
  };

  </script>
  <style scoped>
  .finger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #e0e0e0;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    margin: 5px;
  }
  
  .finger:hover {
    background-color: orange;
  }
  </style>

    
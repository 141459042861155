<template lang="">
    <v-card>
        <s-crud
            :config="config"
            title="Vacaciones"
            :filter="filter"
            add
            edit
            remove
            @save="save($event)"
        >
            <template v-slot:filter>
                <v-container>
                    <!-- <v-row>                        
                        <v-col>
                            <s-text
                                label="Codigo"
                                v-model="filter.ScfCode"
                            ></s-text>
                        </v-col>
                    </v-row> -->
                </v-container>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row >
                        <v-col cols="5" class="s-col-form">
                            <s-toolbar-person noCamera label="Documento*" noEdit v-model="props.item.PrsDocumentNumber" @returnPerson="returnPerson($event, props.item)" />
                        </v-col>

                        <v-col cols="7" class="s-col-form">
                            <s-text readonly label="Persona" v-model="props.item.NtpFullName"></s-text>
                        </v-col>
                        <v-col cols="4" class="s-col-form">
                            <s-date label="F. Inicio" v-model="props.item.VctDateInit" clearable></s-date>
                        </v-col>
                        <v-col cols="4" class="s-col-form">
                            <s-date label="F. Fin" v-model="props.item.VctDateEnd" clearable></s-date>
                        </v-col>
                        <v-col cols="4" class="s-col-form">
                            <s-text
                                label="Referencia*" 
                                v-model="props.item.VctReference"/>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>
    </v-card>
  </template>
  
  <script>
  
    import SText from '../../components/Utils/SText.vue'
    import ServiceVacations from "../../services/HumanResource/HmnVacations";
    import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
  
    export default {
      components: {
        SText,
        sToolbarPerson,
      },
      data() {
        return {
            filter:{},
            config: {
              service: ServiceVacations,
              model: {
                    VctID : "ID",
                    NtpID: "int",
                    NtpFullName: "string",
                    PrsDocumentNumber: "string",
                    VctDateInit: "date",
                    VctDateEnd: "date",
              },
              headers: [
                  { text: "ID", value: "VctID" },
                  { text: "Trabajador", value: "NtpFullName" },
                  { text: "Fecha Inicio", value: "VctDateInit" },
                  { text: "Fecha Fin", value: "VctDateEnd" },
                  { text: "Referencia", value: "VctReference" }
              ]
            }
        }
      },
      methods:{
        returnPerson(value, item) {
            item.NtpFullName = value.NtpFullName;
            item.PrsDocumentNumber = value.PrsDocumentNumber;
            item.NtpID = value.NtpID;
            item.PrsID = value.PrsID;
            item.WrkID = value.WkrID;
        },
        save(item){
              
            console.log(item);
            if(item.PrsDocumentNumber.length <= 0){
                this.$fun.alert("El campo Documento es Obligatorio","warning");
                return;
            }
            if(item.VctReference.length <= 0){
                this.$fun.alert("El campo Referencia es Obligatorio","warning");
                return;
            }
  
            item.save();
        }        
      }
    }
  </script>
  
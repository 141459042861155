<template lang="">
    <v-card>
        <s-crud
            :config="config"
            title="Dias Feriados"
            :filter="filter"
            add
            edit
            remove
            @save="saveHoliday($event)"
            >
            <template v-slot:filter>
                <v-container>
                    <v-row>                        
                        <v-col>
                            <s-date
                                label="Fecha"
                                clearable
                                v-model="filter.HldDate" />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot="props">
              <v-container>
                <v-row>
                  <v-col class="s-col-form" sm="3" md="3">
                    <s-date label="Fecha*" date v-model="props.item.HldDate" />
                  </v-col>
                  <v-col class="s-col-form" sm="3" md="3">
                    <s-text label="Descripción*" v-model="props.item.HldDescription" />
                  </v-col>
                </v-row>
              </v-container>
            </template>
        </s-crud>
    </v-card>
</template>
<script>
import SText from "../../../components/Utils/SText.vue";
import HolidayService from "../../../services/HumanResource/configuration/HmnHolidayService";
export default {
  components: {
    SText,
  },
  data() {
    return {
      itemsNumberGuide: [],
      filter: {
        HldDate: null,
      },
      config: {
        service: HolidayService,
        model: {
          HldId: "ID",
          HldDate: "date",
          HldDescription: "string",
        },
        headers: [
          { text: "ID", value: "HldId" },
          { text: "Día", value: "HldDate" },
          { text: "Descripción", value: "HldDescription" },
        ],
      },
    };
  },
  methods: {
    saveHoliday(item) {      
      item.save();
    },
  },
};
</script>

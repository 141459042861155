<template>
	<div>
		<v-row>
			<v-col class="pb-3" cols="12" lg="12" md="12" sm="12">
				<sale-program @rowSelected="rowSelected($event)"></sale-program>
			</v-col>
			<v-col class="pt-0" cols="12" lg="12" md="12" sm="12">
				<v-btn
					block
					color="primary"
					@click="dialog = true"
					>
					Resumen Semanal
				</v-btn>
			</v-col>
		</v-row>

		<v-dialog
			v-model="dialog"
			fullscreen
			hide-overlay
		>
		<v-card>
			<s-toolbar
				label="EMBARQUE"
				color="light-blue darken-3"
				dark
				close
				@close="dialog = false"
			>
			</s-toolbar>
			<sale-program-detail></sale-program-detail>
		</v-card>
		</v-dialog>
	</div>
</template>

<script>
import SaleProgram from './SaleProgram.vue';
import PackingBoat from "../PackingBoat/PackingBoat.vue";
import SaleProgramDetail from "./SaleProgramDetail.vue"
	export default {
  		components: { SaleProgram, PackingBoat, SaleProgramDetail },

		data() {
			return {
				dialog: false
			}
		},

		methods: {
			rowSelected(row) {
			}
		},
		
	}
</script>
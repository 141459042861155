<template>
    <div>
    <v-row>
      <v-col>
        <s-crud
          :single-row="false"
          add
          addNoFunction
          @add="addWorker()"
          searchInput
          @rowSelected="selectedWorker($event)"
          ref="gridWorker"
          title="Contratos"
          :config="config"
          :filter="filterWorker"
          @doubleClick="assignWorker($event)"
        >
          <template v-slot:filter>
            <v-container>
              <v-row justify="center"
                ><v-col lg="6"> <s-select-area v-model="filterWorker.AreID" label="Área"/></v-col
              ></v-row>
            </v-container>
          </template>
        </s-crud>
      </v-col>
      <v-col>
        <s-toolbar
          label="Cambiar Turno"
          remove
          :search="false"
          @removed="removeEvent($event)"
        >
          <slot>
          <v-btn
            x-small 
            color="info"
            @click="changeshift()"
            ><i class="far fa-stop-circle"></i><small>Cambiar</small>
          </v-btn>
          </slot>
        </s-toolbar>
        <v-card>
          <v-data-table
            dense
            @click:row="rowClick"
            selectable-key="isSelectable"
            v-model="selected"
            :headers="configPanel.headers"
            item-key="PrsDocumentNumber"
            :items="itemsCard"
          ></v-data-table>
        </v-card>
        <v-progress-circular 
          v-if="loading"
          indeterminate
          :size="50"
          color="primary"
        ></v-progress-circular>
      </v-col>

    </v-row>
    
    <!-- CAMBIAR TURNO MASIVO -->
    <v-dialog v-model="showChangeShift" v-if="showChangeShift" persistent max-width="450">
      <v-card>
        <v-card-title>Cambiar Turno </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <v-row style="padding-top:12px;">
              <v-col class="s-col-form">
                <s-select-definition
                    label="Turno"
                    v-model="itemHead.TypeTurno"
                    :def="1062"
                    ref="txtTurno"
                    clearable
                ></s-select-definition>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel()">Cancelar</v-btn>
          <v-btn color="accent" @click="save()">
            <span v-if="isLoading">
              <i class="fa fa-spinner fa-spin"></i>
            </span>
            <span v-else>
              Guardar
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  </template>
  
  <script>
  import _sChangeShiftMassService from "../../services/HumanResource/ChangeShiftMass";
  import _sHelper from "@/services/HelperService";
  import _sGenParam from "@/services/General/ParameterService";
  
  export default {
    components: { },
    data() {
      
      return {
        selected: [],
        showChangeShift: false,
        itemsCard: [],
        itemHead: {
          TypeTurno : 0,
        },
        selectionBefore: [],
        filterWorker: { AreID: 0 },
        isLoading: false,
        showDiaglog:false,
        config: {
          service: _sChangeShiftMassService,
          model: {
            PrsDocumentNumber: "ID",
            DateInitContrat: "date",
          },
          headers: [
            { text: "Documento", value: "PrsDocumentNumber", sortable: false },
            { text: "Empleado", value: "NtpFullName", sortable: true },
            { text: "Fecha Inicio Contrato", value: "DateInitContrat", sortable: true },
            { text: "Area", value: "TypeAreaName", sortable: true },
            { text: "Turno", value: "TypeTurnoName", sortable: true },
          ],
        },
        configPanel: {
          headers: [
            { text: "ID", value: "AgtID", sortable: false },
            { text: "Documento", value: "PrsDocumentNumber", sortable: false },
            { text: "Empleado", value: "NtpFullName", sortable: true },
          ],
        },
        loading: false,
      };
    },
    methods: {
      addWorker() {
        console.log(this.selectionBefore);
        this.selectionBefore.forEach((element) => {
          console.log(element);
          if (this.itemsCard.find((x) => x == element) == null) this.itemsCard.push(element);
        });
      },
      selectedWorker(items) {
        this.selectionBefore = items;
      },
      removeEvent(value) {
        if (this.selected.length == 1) {
          this.itemsCard = this.itemsCard.filter((x) => x != this.selected[0]);
        }
      },
      clear() {
        this.itemsCard = [];
      },
      rowClick: function(item, row) {
  
        if (true) {
          this.selected = [item];
          this.row = Object.assign({}, item);
          console.log("Row", item);
        } else {
          if (
            this.selected.find((x) => {
              return x == item;
            }) != null
          )
            this.selected = this.selected.filter((x) => {
              return x != item;
            });
          else {
            this.selected.push(item);
            this.row = Object.assign({}, item);
          }
        }
      },
      assignWorker(worker) {
        if (this.itemsCard.find((x) => x == worker) == null) this.itemsCard.push(worker);
      },
      changeshift() {
        if (this.itemsCard.length === 0) {
          this.$fun.alert("Por favor seleccione al menos un empleado", "warning");
        } else {
          this.showChangeShift = true;
        }
      },
      cancel() {
        this.showChangeShift = false;
      },
      save() {
          if (!this.itemHead.TypeTurno) {
              this.$fun.alert("Por favor seleccione un turno", "warning");
              return;
          }
          this.isLoading = true;
          const datosAEnviar = this.itemsCard.map(worker => {
              return {
                  ...worker,
                  TypeTurnoEdit: this.itemHead.TypeTurno,
                  UsrCreateID: this.$fun.getUserID()
              };
          });

          _sChangeShiftMassService.save(datosAEnviar)
              .then((response) => {
                  this.$fun.alert(response.data.message, "success");
                  this.itemsCard = [];
                  this.showChangeShift = false;
                  this.isLoading = false;
                  this.itemHead.TypeTurno = 0;
                  this.$refs.gridWorker.refresh();
              })
              .catch((error) => {
                  this.$fun.alert(error.response?.data?.message || "Error al procesar la solicitud", "error");
                  console.error('Error:', error);
                  this.isLoading = false;
              });
      },
    },
    watch: {},
  };
  </script>
  
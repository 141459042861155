<template>
  <div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <section>
      <v-file-input
        @click:clear="clear()"
        v-model="files"
        prepend-icon=""
        show-size
        small-chips
        color="primary"
        prepend-inner-icon="mdi-paperclip"
        dense
        hide-details
        outlined
      >
        <template v-slot:selection="{ index, text }">
          <v-chip v-if="index < 2" color="primary" dark label x-small>
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>

      <xlsx-read :file="files">
        <xlsx-json v-show="headers" @parsed="changeData($event)">
          <template #default="{collection}">
            <div>
              <v-data-table dense :headers="headers" :items="convertCollection(collection)" />
            </div>
          </template>
        </xlsx-json>
      </xlsx-read>
    </section>
    <v-row v-show="headers">
      <v-spacer></v-spacer>
      <v-col lg="6">
        <v-btn color="primary" small block @click="$emit('loaded', items)">Cargar Datos</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { XlsxRead, XlsxTable, XlsxSheets, XlsxJson, XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";

export default {
  components: {
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  props: { 
    value: null, 
    headers: null, 
    label: { type: String, default: "Seleccione Archivo" },
    validateRow: {
      type: Function, // La función de validación que se recibe como prop
      default: null, // Si no se pasa, la validación será opcional
    },
   },

  data() {
    return {
      items: [],
      file: null,
      files: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],
    };
  },

  methods: {
    clear() {
      this.files = [];
      this.items = [];
      this.$emit("clear");
      this.$emit("input", null);
    },
    changeData(data) {
      this.$emit("changeData", data);
      this.$emit("input", data);
    },
    convertCollection(collection) {
      let list = [];
      if (collection){
        
         // Si `validateRow` está definida, se usa para filtrar. Si no, se pasa todo.
        list = collection
          .filter((item) => (this.validateRow ? this.validateRow(item) : true)) // Filtrado opcional
          .map((item) => {
            let obj = {};
            this.headers.forEach((element) => {
              if (element.date) obj[element.value] = this.$moment(new Date((item[element.column] - (25567 + 1)) * 86400 * 1000)).format("YYYY-MM-DD");
              else obj[element.value] = item[element.column];
            });
            return obj;
          });
      }
      else list = [];
      this.items = list;
      return list;
    },
  },
};
</script>

import Service from "../Service";

const resource = "archivist/";

export default {
  save(par, requestID) {
    return Service.post(resource + "save", par, {
      params: { requestID: requestID },
    });
  },
  
  pagination(par, requestID) {
    return Service.post(resource + "pagination", par, {
      params: { requestID: requestID },
    });
  },
  SaveCabecera(parameters, requestID) {
    return Service.post(resource + "SaveCabecera", parameters, {
        params: { requestID: requestID },
    });
  },
  SaveDetail(parameters, requestID) {
    return Service.post(resource + "SaveDetail", parameters, {
        params: { requestID: requestID },
    });
  },
  updateStatuDetail(parameters, requestID) {
    return Service.post(resource + "updateStatuDetail", parameters, {
        params: { requestID: requestID },
    });
  },
  uploadinSap(parameters, requestID) {
    return Service.post(resource + "uploadinSap", parameters, {
        params: { requestID: requestID },
    });
  },
  DetailByMrcID(parameters, requestID) {
    return Service.post(resource + "DetailByMrcID", parameters, {
        params: { requestID: requestID },
    });
  },
};
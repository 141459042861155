import Service from '../Service';
import Vue from "vue";

const resource = "presentationConfig/"

export default {

    save(trp, requestID) {
        return Service.post(resource + 'save', trp, {
            params: { requestID }
        });
    },

    pagination(parameters, requestID) {        
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

    GetUrllist(){        
        return Vue.prototype.$http.defaults.baseURL + "/presentationConfig/list"; 
    },

    listPresentation(search,RequestID){    
        return Service.post(resource + "list",{},{
        params:{RequestID: RequestID , search : search}
      });  
    },
    
      

}
<template>
  <div>
    <!-- EDICION Y CREACION DE REQUERIMIENTOS EN VENTANA MODAL  -->
    <div v-if="dialog">
      <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
        <requirement-edit
          :value="RequirementEdit.ReqID"
          @close="dialog = false"
          :RequirementEdit="RequirementEdit"
          :TypeRequirement="TypeRequirement"
          :serviceEdit="ServiceEdit"
        ></requirement-edit>
      </v-dialog>
    </div>

    <!-- LISTADO DE REQUERIMEINTOS POR TIPO   -->
    <v-row>
      <v-col style="padding-bottom: 0px">
        <s-crud
          height="200"
          ref="grid"
          :view="selected.length > 0 ? (selected[0].ReqStatus != 5 && selected[0].ReqStatus != 3 ? true : false) : false"
          @rowSelected="rowSelected($event)"
          search-input=""
          add-no-function
          :filter="filterRequirement"
          @add="add()"
          :title="label"
          :edit="selected.length > 0 ? (selected[0].ReqStatus == 5 || selected[0].ReqStatus == 3 ? true : false) : false"
          add
          @edit="edit()"
          :remove="selected.length > 0 ? (selected[0].ReqStatus == 5 ? true : false) : false"
          excel
          pdf
          @pdf="pdf()"
          :config="config"
        >
          <template v-if="$fun.hasSpecialPermission('duplicate')" v-slot:options>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" small elevation="0" @click="duplicate">
                  <v-icon color="default" x-small>
                    fas fa-copy
                  </v-icon>
                </v-btn>
              </template>
              <span>Duplicar</span>
            </v-tooltip>
          </template>
          <template v-slot:filter>
            <v-container>
              <v-container>
                <v-row justify="center">
                  <v-col lg="2" class="s-col-form"
                    ><s-select-definition clearable label="Estados" v-model="filterRequirement.ReqStatus" :def="1128" :dgr="28"
                  /></v-col>
                  <v-col lg="2" class="s-col-form"><s-select-campaign label="Campaña" v-model="filterRequirement.CmpID" /> </v-col>
                  <v-col lg="2" class="s-col-form" v-if="$fun.isAdmin()"><s-select-area v-model="filterRequirement.AreID" label="Área"/></v-col>
                  <v-col lg="2" class="s-col-form">
                    <s-date v-model="filterRequirement.BeginDate" label="Fecha Inicio" />
                  </v-col>
                  <v-col lg="2" class="s-col-form"> <s-date v-model="filterRequirement.EndDate" label="Fecha Fin"/></v-col>
                </v-row>
              </v-container>
            </v-container>
          </template>
          <template v-slot:RqStatusName="{ row }">
            <v-chip
              x-small
              :color="
                row.ReqStatus == 1 ? 'success' : row.ReqStatus == 3 ? 'error' : row.ReqStatus == 4 ? 'primary' : row.ReqStatus == 5 ? 'warning' : ''
              "
            >
              {{ row.RqStatusName }}
            </v-chip>
          </template>
          <template v-slot:SecUpdate="{ row }">
					  {{ row.SecUpdate ? row.ReqDate : row.ReqDate }}
				  </template>
        </s-crud>
      </v-col>
    </v-row>

    <!--LISTADO DE   DETALLE DEL REQUERIMIENTO-->
    <v-col style="padding: 0px; margin-top: 17px">
      <s-toolbar
        :remove="itemsDetailSelect.length > 0 ? itemsDetailSelect[0].DerStatus == 1 : false"
        label="Detalle"
        @removed="removed(itemsDetailSelect)"
      ></s-toolbar>
      <!-- TABLA DETALLE DE REQUERIMIENTO -->
      <v-data-table
        dense
        :items="itemsDetail"
        show-select
        single-select
        v-model="itemsDetailSelect"
        :headers="headersDetail"
        item-key="DerID"
      >
        <template v-slot:item.DerStatusName="{ item }">
          <!-- <v-chip x-small :color="item.DerStatus == 9 ? 'error' : ''" v-if="item.DerStatus == 1 || item.DerStatus == 9">
            {{ item.DerStatusName }}
          </v-chip> -->
          <v-btn class="mx-2" x-small :color="item.DerStatus == 9 ? 'error' : ''" @click="click($event, {item})">
            {{ item.DerStatusName }}
          </v-btn>
        </template>
        <template v-slot:item.link="{ item }">
          <v-btn class="mx-2" x-small v-if="item.OrdDate != null" @click="orderView(item.OrdID)">
            <v-icon x-small>far fa-eye </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.OrdDate="{ item }">
          {{ $fun.formatDateView(item.OrdDate) }}
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialogApproval" v-if="dialogApproval" width="50%">
      <ApprovalLevelsDocument :DocEntry="DocEntry" :TypeObject="hasOrdID ? 2 : 1" />
    </v-dialog>
    <v-dialog v-model="dialogOrder" v-if="dialogOrder" width="100%">
      <OrderPurchaseEdit @download="pdfOrder($event)" :value="OrdID" @onClose="dialogOrder = false" @load="dialogOrder = true" :viewLoad="false" />
    </v-dialog>

    <v-dialog v-if="dialogPdf && selected.length > 0" v-model="dialogPdf"   width="70%">
			<requirement-pdf :referralGuideSelected="dataPrint" @close="dialogPdf = false"></requirement-pdf>
		</v-dialog>
	
  </div>
</template>

<script>
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
import _sRequirement from "../../../services/Logistics/RequirementService";
import _sRequirementDetail from "../../../services/Logistics/RequirementDetailService";
import RequirementEdit from "@/views/Logistics/Requirement/RequirementEdit";
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseService";

// importado para modal ver orden
import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit";
import RequirementPdf from "@/views/Logistics/Requirement/RequirementPdf.vue";

export default {
  name: "RequirementService",
  components: { RequirementEdit, ApprovalLevelsDocument, OrderPurchaseEdit,RequirementPdf },
  props: {
    TypeRequirement: { default: 1, type: Number },
    label: { default: "Requerimiento de Compra", type: String },
  },
  data: () => ({
    OrdID: null,
    dialogOrder: false,
    dialogPdf: false,
    dataPrint:[],
    dialogApproval: false,
    DocEntry: null,
    RequirementEdit: {},
    TypeCost: 0,
    centercost: 0,
    bslID: 0,
    dialog: false,
    hasOrdID: false,
    filterRequirement: {
      BeginDate: null,
      EndDate: null,
      TypeRequirement: 1,
      AreID: 0,
    },
    selected: [],
    itemsDetail: [],
    ServiceEdit: false,
    itemsDetailSelect: [],
    config: {
      model: {
        ReqDocumentNumber: "ID",
        ReqDate: "datetime",
        SecUpdate: "datetime",
        RqStatusName: "",
      },
      service: _sRequirement,
      headers: [
        { text: "ID", value: "ReqID", sortable: false, width: 40 },
        {
          text: "N° Requerimiento",
          value: "ReqDocumentNumber",
          sortable: false,
          width: 155,
        },
        
        { text: "Fecha", value: "ReqDate", sortable: false, width: 110 },
        { text: "Solicitante", value: "UsrName", sortable: false, width: 120 },
        {
          text: "Estado",
          value: "RqStatusName",
          sortable: false,
          width: 50,
          align: "center",
        },
        { text: "Fecha Mod", value: "SecUpdate", sortable: false, width: 140 },
        { text: "Área", value: "AreName", sortable: false },
        { text: "Comentario", value: "ReqObservation", sortable: false },
      ],
    },
    headersDetail: [
      { text: "Descripción", value: "DerDescription", sortable: false },
      { text: "Linea de Negocio", value: "BslName", sortable: false },
      { text: "Centro de Costo", value: "CceName", sortable: false },
      { text: "TCosto", value: "TypeCostName", sortable: false },
      { text: "Comentario", value: "DerObservation", sortable: false },
      { text: "Orden", value: "OrdID", sortable: false },
      //  { text: "SAP", value: "DocNumOutput", sortable: false },
      { text: "Atención", value: "OrdDate", sortable: false },
      { text: "Ver Orden", value: "link", sortable: false },
      { text: "Estado", value: "DerStatusName", sortable: false },
    ],
  }),
  methods: {
    removed(items) {
      this.$fun.alert("Seguro de Anular?", "question").then((r) => {
        if (r.value) {
          items.map((item) => {
            item.DerStatus = 9;
            return item;
          });
          _sRequirementDetail.save(items, this.$fun.getUserID()).then(() => {
            this.$fun.alert("Anulado Correctamente", "success");
            this.rowSelected(this.selected);
            this.itemsDetailSelect = [];
          });
        }
      });
    },
    click(e, { item }) {
      if (item.OrdID != null) {
        this.hasOrdID = item.OrdID;
        this.dialogApproval = true;
        this.DocEntry = item.OrdID;
      } else {
        this.hasOrdID = null;
        this.dialogApproval = true;
        this.DocEntry = item.ReqID;
      }
    },

    duplicate() {
      // if (item.OrdID != null) {
      //   console.log(e);
      //   this.dialogApproval = true;
      //   this.DocEntry = item.OrdID;
      // }
      console.log("Fila seleccionada",this.selected);
      //this.selected[0].ReqID= null;
      console.log("Fila Typo requer",this.TypeRequirement);
      this.RequirementEdit = this.selected.length > 0 ? this.selected[0] : null;
      this.RequirementEdit.items = this.itemsDetail;
      this.ServiceEdit = true;
      console.log("Fila seleccionada3",this.RequirementEdit);
      this.dialog = true;
    },

    orderView(item) {
      this.OrdID = item;
      this.dialogOrder = true;
    },

    rowSelected(item) {
      this.selected = item;
      if (item != null) {
        if (item.length > 0) {
          if (item[0].ReqID != null)
             console.log("Requ",item[0].ReqID );
            _sRequirementDetail.detail({ ReqID: item[0].ReqID }, this.$fun.getUserID()).then((r) => {
              this.itemsDetail = r.data;
            });
        }
      }
    },
    add() {
      this.RequirementEdit.ReqID = null;
      this.dialog = true;
    },
    edit() {
      this.RequirementEdit = this.selected.length > 0 ? this.selected[0] : null;
      this.RequirementEdit.items = this.itemsDetail;
      this.dialog = true;
    },

    pdfOrder(item) {
      _sOrderPurchase.PDF(item, this.$fun.getUserID()).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, "Orden_compra");
      });
    },

    pdf() {

      // _sRequirement.pdf(this.selected[0], this.$fun.getUserID()).then((r) => {
      //   this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, this.$fun.getUserID());
      // });
      _sRequirement.byID(this.selected[0],this.$fun.getUserID()).then((r) => {
          this.dataPrint = r.data;
          this.dialogPdf = true;
          console.log("data",this.dataPrint);
      });     
    },
  },
  watch: {
    dialog() {
      this.$refs.grid.refresh();
    },
  },
  created() {
    // SI ES USUARIO ADMNISTRADOR PUEDE HACER FILTROS POR AREA SINO SOLO PUEDE VER LOS DE SU AREA
    if (!this.$fun.isAdmin()) this.filterRequirement.AreID = this.$fun.getUserInfo().CtrContract.AreID;

    this.filterRequirement.TypeRequirement = this.TypeRequirement;
    if (this.TypeRequirement == 1) {
      this.headersDetail.splice(
        1,
        0,
        { text: "Und", value: "UnitMeasurement", sortable: false },
        { text: "Cant", value: "DerQuantity", sortable: false }
      );
    } else if (this.TypeRequirement == 2) {
      this.headersDetail.splice(
        1,
        0,
        { text: "Und", value: "UnitMeasurement", sortable: false },
        { text: "Cant", value: "DerQuantity", sortable: false }
      );
    }
  },
  mounted() {},
};
</script>

<template>
    <div>
        <v-card class="container">
            <div class="row col">
                <h1 class="h3">Armar Muñeco</h1>
            </div>
            <div class="row">
                <div class="col">
  <!-- @click:row="rowSelected"
                        item-key="SqhID" -->
                    <s-toolbar 
                        label="Materia ingresando a Proceso"
                        color="#E0F7F2"
                    >
                <!-- {{ 'Enviados ' + this.SumWeightNetProccess.toFixed(2) + ' kg |'}}-->  
                   {{ 'Enviados ' + this.SuLlpWeightOriginalNet.toFixed(2) + ' kg |'}}

                 <!--{{ 'Por C. ' + (this.SumWeightNetProccess - this.WeightSendLine).toFixed(2) + 'kg'}}-->  
                   {{ 'Por C. ' + (this.SuCantPorC).toFixed(2) + 'kg'}}
                </s-toolbar>
 <!-- v-model="selectedItem"  -->
                    <v-row>
                        <v-col cols="6">
                            <s-select-definition
                                :def="1173"
                                v-model="filterProcess.TypeCultive"
                                label="Cultivo"
                                @input="changeCultive($event)"
                            />
                        </v-col>
                        <v-col cols="6">
                            <s-select
                                label="Medio de Ingreso"
                                full
                                item-value="SplID"
                                item-text="SplName"
                                :items="samplingLocation"
                                v-model="splID"
                                @input="splIDFilter($event)"
                        ></s-select>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headerPalletGoing"
                        :items="palletsGoing"
                        
                        dense
                    > 
                        <template v-slot:item.DldQuantity="{ item }"> 
                            <s-text 
                                v-model.number="item.DldQuantity"
                                number
                                value="4"
                                :max="4"
                                :min="0"
                                :maxlength="2"
                            />
                        </template>
                        <template v-slot:item.Armed="{ item }">
                            <v-btn
                            color="success"
                           
                            :disabled="false"
                            icon
                            x-small
                            @click="armed(item)"
                          >
                            <v-icon>mdi-send</v-icon>
                          </v-btn>
                        </template>
                        
                    </v-data-table>
                </div>
                <!-- <div class="col-2"></div> -->
                <v-card class="col " color="warning"> 
                    <v-card-text>
                        <s-toolbar 
                            label="Muñecos transferidos"
                            color="#E0F7F2" 
                        >
                          <!--{{ this.WeightSendLine.toFixed(2)  + ' kg' }}-->  
                           {{ this.SuToAverageWeightJabaN.toFixed(2)  + ' kg' }}
                        </s-toolbar> 
                        <!-- @click:row="selectedItemd" -->
                        <v-data-table
                            :headers="headerDollArmed"
                            :items="detailsArmed"
                            v-model="armdedSelected"
                            dense
                            
                            key="LlpCorrelative"
                        >
                            <template v-slot:item.remove="{ item }">
                                <v-btn
                                    @click="clickDelete(item)"
                                    x-small
                                    block
                                    rounded
                                    color="error" >
                                    <v-icon style="font-size: 16px !important"
                                        >mdi-close</v-icon >
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-card class="pl-3 pt-3 pr-3 pb-3">
                            <v-row class="w-100"> 
                                <v-col cols="12">       
                                       <s-text
                                            label="Codigo - Jaba Base"
                                            disabled
                                            v-model="DlpCodBase"
                                            ref="txtDlpCodBase"
                                            number
                                            :min=0
                                        />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-card-actions>
                    <v-card-actions>
                        <v-card class="pl-3 pt-3 pr-3 pb-3">
                            <v-row class="w-100"> 
                                <v-col cols="12">       
                                       <s-select-definition
                                            :def="1425"
                                            label="Tipo de Envio"
                                            radio
                                            row
                                            v-model.number="typeSend"
                                        />
                                </v-col>
                            </v-row>
                            <v-row class="w-100"> 
                                <v-col>       
                                    <v-btn @click="openModal()">Enviar</v-btn>
                                    
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-card-actions>
                </v-card>
            </div>
            <!-- <div class="row">
                <div class="col">
                    <s-toolbar 
                        label="Muñecos Armados"
                        color="#E0F7F2"
                        remove
                    />
                    <div class="row pb-3 pl-3">
                        <div class="col-3">
                            <s-date 
                                label="Fecha Inicio"
                                v-model="filter.DateBegin"
                                @input="loadList()"
                            /> 
                        </div>
                        <div class="col-3">
                            <s-date 
                                label="Fecha Fin"
                                v-model="filter.DateEnd"
                                @input="loadList()"
                            /> 
                        </div>
                    </div>
                    <v-data-table
                        :headers="headerDoll"
                        :items="dollsArmed"
                        key="DldID"
                        dense
                    >

                    </v-data-table>
                </div>                    
            </div> -->
            
            
            <div class="row">
                <div class="col">
                    <template>
                        <LocationDoll :key="componentKey" ></LocationDoll>
                    </template>
                </div>
            </div>
        </v-card>
        <v-dialog
			v-model="dialogSend"
			v-if="dialogSend"
			persistent
            transition="dialog-bottom-transition"
			fullscreen
		>
            <v-card > 
                <s-toolbar
                    close
                    @close="ClosedialogSend()"
                />
                <v-card-text>
                    <v-row v-if="typeSend == 1">
                        <v-col>
                            <location-doll 
                                @emitLocation="emitLocation($event)"
                                armed
                            ></location-doll>                            
                        </v-col>
                    </v-row>
                    <v-row v-if="typeSend == 2">
                        <tables-line 
                            :filter="filterProcess"
                            :detailsArmed="detailsArmed[0]"
                            @emitTable="emitTable($event)"
                        ></tables-line>
                    </v-row>
                </v-card-text>
            </v-card>
		</v-dialog>
	
    </div>
</template>

<script>

    import _armedDoolService from '@/services/FrozenProduction/ArmedDollService'
    import _sParameter from "@/services/General/ParameterService.js";
    import _sSamplingLocationService from '@/services/FrozenProduction/SamplingLocationService.js';

    import LocationDoll from './LocationDoll.vue'
    import TablesLine from '@/components/FrozenProduction/Aconditionado/TablesLine.vue'

  

    export default {
        components: {
            LocationDoll,
            TablesLine,
        },
        data() {
            return  {
                palletsGoing : [],
                headerPalletGoing: [
                    { text: "#", value: "LlpID" },
                    { text: "N° Pallet", value: "LlpCorrelative" },
                    { text: "Destino", value: "TypeDestination" },
                    { text: "Seleccion", value: "TypeSelectionFrozenName" },
                    { text: "Calibre", value: "TypeFrozenCategoryName" },
                    { text: "Lote M.", value: "PrfYuliano" },
                    // { text: "Jabas recep.", value: "LpsQuantityJaba" },  
                    { text: "Stock", value: "StockJaba" }, 
                    { text: "N° Jabas", value: "DldQuantity" },
                    { text: "Armar", value: "Armed" },
                ],
                selectedItem: [],
                headerDollArmed: [
                    { text: "#", value: "LlpID" },
                    { text: "N° Pallet", value: "LlpCorrelative" },
                    { text: "Lote de materia prima", value: "PrfYuliano" },
                    { text: "Cantidad de Jabas", value: "DldQuantity" },
                    { text: "Eliminar", value: "remove" },
                ],
                armdedSelected: [],
                detailsArmed: [],
                headerDoll: [
                    { text: "#", value: "DlpID" },
                    { text: "N° Lote", value: "RcfID" },
                    { text: "Correlativo", value: "DlpCorrelative" },
                    { text: "Lote de materia prima", value: "DlpJuliano" },
                    { text: "Cantidad de Jabas", value: "DlpQuantity" },
                    { text: "Variedad", value: "VrtName" },
                    { text: "Lugar", value: "TypeSendName" },
                    { text: "Ubicacion", value: "DlpPosition" },
                    { text: "Tipo Cultivo", value: "TypeCropName" },
                ],
                dollsArmed: [],
                selectedDoll: [],
                maxJabas: 0,
                filter: {},
                filterProcess: {
                    TypeCultive: 0,
                },
                samplingLocation: [],
                splID:0,
                dialogSend: false,
                typeSend: 0, 
                ClpID: 0,//linea en proceso
                componentKey: 0,
                DlpCodBase: 0,
              //  SumWeightNetProccess: 0,
               // QuantityJaba: 0,
                WeightJaba: 0,
               // JabasSend: 0,
               // WeightSendLine: 0,
                SuLlpWeightOriginalNet:0,
                SuToAverageWeightJabaN:0,
                SuCantPorC:0
            }
        },
// selectedItemd: {},
        watch: {
            "filterProcess.TypeCultive"(){
                this.samplingLocation = [];
                this.SuLlpWeightOriginalNet=0;
                this.SuToAverageWeightJabaN=0;
                this.SuCantPorC=0;
               // this.SumWeightNetProccess = 0;
               // this.QuantityJaba = 0;
                this.WeightJaba = 0;
                //this.JabasSend = 0;
               // this.WeightSendLine = 0;
                _sSamplingLocationService
                .list( this.filterProcess , this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        this.samplingLocation = resp.data;
                    }
                })
            },
            
        },
        methods: { 
            ClosedialogSend(){
                this.dialogSend = false;
                this.forceRender();
            },
            changeCultive(cultive){
                this.palletsGoing = [];
            },
            forceRender() {                
                this.componentKey += 1;
            },
            clickDelete( item ){
                
                
                this.detailsArmed =
                    this.detailsArmed.filter(
                    (x) => x.LpsID != item.LpsID
                    );
            },
            emitLocation(obj){
                
                
                let column = obj.item.DedDescription
                let rows = obj.item2.DedDescription

                this.save( 1, rows, column, null );
                
            },
            emitTable( table ){
               
                this.ClpID = table.ClpID;
                this.save( 2, null, null, table );
            },
            openModal(){
                
                if(this.detailsArmed.length == 0){
                    this.$fun.alert("Agregar jabas para crear muñeco", "warning")
                    return;
                }
                // if(!this.DlpCodBase){
                //     this.$fun.alert("Agregar codigo Jaba", "warning")
                //     return;
                // }
                if(!this.typeSend){
                    this.$fun.alert("Seleccione tipo destino", "warning")
                    return;
                }                

                this.dialogSend = true;
            },
            splIDFilter(splID){
                 
                 this.palletsGoing = [];

                 this.splID = splID;

                this.loadPalletGoing( this.splID );
            },
            loadPalletGoing(splID){
                _armedDoolService.palletGoing({ splID }, this.$fun.getUserID())
                    .then((r) => {

                        if (r.status == 200) {
                            this.SuLlpWeightOriginalNet=0;
                            this.SuToAverageWeightJabaN=0;
                            this.SuCantPorC=0;
                            this.palletsGoing = r.data;
                            this.WeightJaba = 0;
                            this.palletsGoing.forEach( (r) => 
                            {
                                this.SuLlpWeightOriginalNet += r.LlpWeightOriginalNet;
                                this.SuToAverageWeightJabaN += r.ToAverageWeightJabaN;
                                this.SuCantPorC += r.CantPorC;
                            });
                            /*this.SumWeightNetProccess = 0;
                            this.QuantityJaba = 0;
                            this.WeightJaba = 0;
                            this.JabasSend = 0;
                            this.WeightSendLine = 0;
                            this.palletsGoing = r.data;
                            console.log("palletgoing",this.palletsGoing);
                            this.palletsGoing.forEach( (r) => 
                            {
                                this.SumWeightNetProccess += r.LpsWeightPalletNet;
                                this.QuantityJaba += r.LpsQuantityJaba;
                                this.JabasSend += r.BusyDolls;
                            });
                            if(this.SumWeightNetProccess > 0)
                            {
                                this.WeightJaba = this.SumWeightNetProccess / this.QuantityJaba;
                                this.WeightSendLine = this.WeightJaba * this.JabasSend;
                            }*/
                            this.getDlpCodBase();
                        }
                    });
            },
            getDlpCodBase()
            {
                _armedDoolService.getDlpCodBase({ TypeCultive: this.filterProcess.TypeCultive }, this.$fun.getUserID())
                    .then((r) => {                        
                        this.DlpCodBase = r.data.DlpCodBase;
                    });
            },
            remove(item){

            },
            armed(item){
                
                let sum = item.DldQuantity ?? 0;

                if ( item.DldQuantity == 0) {
                    this.$fun.alert("La cantidad de jabas debe ser mayor a 0", "warning")
                    return;
                }

                if ( item.DldQuantity > item.StockJaba) {
                    this.$fun.alert("La cantidad de jabas para armar debe ser menor o igual a las recepcionadas", "warning")
                    return;
                }
                
                if (this.detailsArmed.filter( d => d.LpsID == item.LpsID ).length > 0) {
                    this.$fun.alert("No se puede agregar de el mismo pallet", "warning")
                    return;
                }

                this.detailsArmed.forEach((d) => { sum += d.DldQuantity; });
                if (sum > this.maxJabas) {
                    this.$fun.alert("La cantidad de jabas debe ser menor o igual a " + this.maxJabas, "warning")
                    return;
                }

                this.detailsArmed.push({...item})
            },
            save(typeSend, row, col, table){

                this.detailsArmed.sort(function(a, b) {
                    return a.DldQuantity - b.DldQuantity;
                })
 
                let repreDoll = this.detailsArmed[this.detailsArmed.length - 1]
                if (!repreDoll) {
                    return;
                }
                let sum = 0;
                this.detailsArmed.forEach((d) => { sum += d.DldQuantity; });
                if(typeSend==2 && table.difProcess==1 && (table.DescripcionIQF===null || table.DescripcionIQF==="")){
                    this.$fun.alert("Campo observacón es obligatorio", "warning")
                    return;
                }
                console.log("descripcionIqf",table.DescripcionIQF);
                let doll = {
                    DlpQuantity: sum,
                    DlpJuliano: repreDoll.PrfYuliano,
                    FrzDollProccessDetails: this.detailsArmed,
                    TypeSend: typeSend,
                    VrtID: repreDoll.VrtID,
                    TypeCultive: repreDoll.TypeCultive,
                    TypeCrop: repreDoll.TypeCrop,
                    DlpRow: row,
                    DlpCol: col,
                    CttID: table.CttID,
                    DescripcionIQF: table.DescripcionIQF,
                    TypeSelectionFrozen: repreDoll.TypeSelectionFrozen,
                    TypeFrozenCategory: repreDoll.TypeFrozenCategory,
                    UsrCreateID: this.$fun.getUserID(),
                    ClpID: this.ClpID,
                    DlpCodBase : this.DlpCodBase,
                    AverageWeightJabaN : sum * this.WeightJaba,
                }
                console.log(doll);
                this.$fun.alert("¿Esta seguro de guardar?", "question").then((r) => {

                    if (r.value) {
                     
                        _armedDoolService.save( doll, this.$fun.getUserID())
                        .then((r) => {

                            if (r.status == 200) {
                                this.$fun.alert("Guardado correctamente", "success")
                                // this.palletsGoing = r.data;
                                
                                this.detailsArmed = [];
                                this.loadList();
                                this.dialogSend = false;
                                this.loadPalletGoing( this.splID );
                                this.forceRender();
                                this.DlpCodBase= '';
                                this.getDlpCodBase();
                            }
                        })    
                    } 
                })

               


            },
            loadList(){
                _armedDoolService.list(this.filter, this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {
                        this.dollsArmed = r.data;
                        
                    }
                });
            },
            
           
        },
        created(){
            _sParameter.search({ PrmName: "maxjabasmunecosacondicionado"}, this.$fun.getUserID()).then((r) => {
               
              this.maxJabas  = r.data.PrmValue ?? 0;
            });

           
        },
        mounted(){ 
            
          

        //    this.loadList();
        }
    }

</script>
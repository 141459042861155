<template>
	<div>
		<v-container>
			<v-row>
				<v-col lg="12" class="pb-1">
					<s-crud
						:config="config"
						:filter="filter"
						title="Programación de Acopio"
						add
						:edit="!disabled"
						searchInput
						ref="gridProgrammingStorage"
						@save="save($event)"
						@addEvent="CleanAll()"
						@rowSelected="rowSelected($event)"
						no-full
						height="auto"
						:single-row="false"
						:groupBy="'TptEnrollment'"
					>
						<template v-slot:options> 
							<v-tooltip bottom="" v-if="selectedHeader.PseID > 0">
								<template v-slot:activator="{ on }">
									<v-btn
									v-on="on"
									text
									black 
									@click="openDialogGuide()"
									small
									>
									<i class="mdi mdi-note-text"></i>
									</v-btn>
								</template>
								<span>Guía Remisión</span>
							</v-tooltip>
						</template>
						<!-- Filtros -->
						<template v-slot:filter>
							<v-container>
								<v-row justify="center" class="s-col-form">
									<v-col lg="2" v-if="filter.PseState == 1">
										<v-btn
											style="margin-top: 15px; margin-left: -10px;"
											small
											block
											color="primary"
											@click="duplicate()"
										>
											Duplicar registro
										</v-btn>
									</v-col>
									<v-col lg="2" v-if="filter.PseState == 1">
										<v-btn
											style="margin-top: 15px; margin-left: -10px;"
											small
											block
											color="warning"
											@click="remove()"
										>
											Eliminar
										</v-btn>
									</v-col>
									<v-col lg="2"
										><s-date
											label="Fecha Inicio"
											v-model="filter.cDateInitial"
										></s-date>
									</v-col>
									<v-col lg="2"
										><s-date
											label="Fecha Fin"
											v-model="filter.cDateFin"
										></s-date>
									</v-col>
									<v-col lg="3">
										<s-select-definition
											v-model="filter.PseState"
											:def="1215"
											label="Estado"
										/>
									</v-col>
								</v-row>
							</v-container>
						</template>

						<template scope="props">
							<v-card>
								<v-row>
									<div class="col-md-12 col-sm-12">
										<v-col cols="12" md="12">
											<v-row>
												<v-col lg="4" md="4" cols="12">
													<s-select-definition
														:def="1212"
														label="Tipo de Transporte"
														v-model="
															props.item
																.TypeTransport">
													</s-select-definition>
												</v-col>

												 <v-col lg="4" md="4" cols="12">
													<s-select-definition
													v-model="props.item.TypeService"
													:def="1171"
													label="Tipo Servicio"
													/>
												</v-col>

												 <v-col
													md="4"
													cols="12"
													:lg="props.item.TypeProcessingPlant == 2 ? 2 : 4">
													<s-select-definition
													v-model="props.item.TypeProcessingPlant"
													:def="1171"
													label="Planta Procesadora"
													/>
												</v-col>

												<v-col
													cols="12"
													md="4"
													lg="2"
													v-if="props.item.TypeProcessingPlant == 2"
												>
													<s-select-definition
													label="Empacadora"
													:def="1226"
													v-model="props.item.TypeBaler"
													/>
												</v-col>

												<v-col
													cols="12"
													md="4"
													style="display: flex;"
												>
													<s-select-producer
														style="width: inherit;"
														clearable
														:text="
															props.item.PrdName
														"
														return-object
														v-model="ObjProducer"
														label="Productor"
														@input="
															inputProducer(
																$event
															)
														"
													/>
												</v-col>

												<v-col lg="2" md="2" cols="12">
													<s-select-definition
														:def="1175"
														label="Destino"
														:value="2"
														v-model="TypeDestiny"
													>
													</s-select-definition>
													<!-- v-model="props.item.TypeDestiny" -->
												</v-col>

												<v-col lg="2" cols="12" md="2">
													<s-select-definition
														label="Tipo Carga"
														:def="1231"
														v-model="
															props.item
																.TypeCharge
														"
													/>
												</v-col>

												<v-col lg="4" cols="6" md="4">
													<s-date
														label="Fecha de Recojo"
														v-model="
															props.item
																.PseAproximateArrivaleDate
														"
													></s-date
												></v-col>
												<v-col lg="2" cols="6" md="2">
													<s-select-transport
														clearable
														label="Vehiculo"
														full
														return-object
														v-model="ObjTransport"
													>
														<!-- ObjTransport TptEnrollment :text="props.item.TptEnrollment"-->
													</s-select-transport></v-col
												>

												<v-col lg="2" cols="12" md="2">
													<s-text
														label="Tipo de Vehiculo"
														disabled
														v-model="
															TypeVehicleName
														"
													></s-text>
												</v-col>
												<v-col lg="4" cols="12" md="4">
													<s-text
														label="Chofer"
														disabled
														v-model="NameDrive"
													></s-text>
												</v-col>
												<v-col lg="4"   cols="12">
													<s-select-definition :def="1396" label="Tipo de Entrada de Mercancía" v-model="props.item.TypeEntryMerchandise">
													</s-select-definition>
												</v-col>
											</v-row>

											<v-row>
												<v-col lg="12" cols="12">
													<s-toolbar
														add
														@add="newItem()"
														:color="'#BAB6B5'"
														dark
														label="Nuevo Item"
													/>
													<v-divider
														horizontal
													></v-divider>
													<v-card
														rounded=""
														class="elevation-0"
													>
														<v-col
															style="padding-bottom:24px;"
														>
															<v-row
																justify="center"
															>
																<v-col
																	cols="12"
																	md="12"
																	lg="12"
																	v-if="PrdID > 0"
																>
																	<s-select-lot
																		:PrdID="PrdID"
																		FltName="FltNameOne"
																		:indicadorLot="0"
																		clearable
																		full
																		label="Lote"
																		return-object
																		v-model="objLot"
																		@input="inputLot($event)"
																/></v-col>
																<v-col
																	cols="12"
																	md="3"
																	lg="3"
																>
																	<s-select-definition
																		:def="
																			1176
																		"
																		label="Localidad"
																		returnObject
																		v-model="
																			objTypeLocation
																		"
																	>
																	</s-select-definition>
																</v-col>

																<!-- </v-row>

																		<v-row> -->
																<v-col
																	cols="12"
																	md="3"
																	lg="3"
																>
																	<s-select-definition
																		clearable
																		:def="1172"
																		label="Tipo Cultivo"
																		v-model="objTypeCrop"
																		return-object
																	/>
																	
																</v-col>

																<!-- <v-col
																	cols="12"
																	md="2"
																	lg="2"
																>
																	<s-select-definition
																		:def="
																			1173
																		"
																		label="Cultivo"
																		returnObject
																		v-model="
																			objTypeCultive
																		"
																	/>
																</v-col> -->

																<v-col
																	cols="12"
																	md="2"
																	lg="2"
																>
																	<s-select-variety
																		:cultiveID="objLot.TypeCultive"
																		label="Variedad"
																		v-model="VarietyID"
																		readonly
																	/>
																</v-col>
																<v-col
																	cols="12"
																	md="2"
																	lg="2"
																	><s-text
																		number
																		label="Cantidad Estimada"
																		v-model="
																			PsdQuantity
																		"
																		:min="0"
																	></s-text
																></v-col>
																<v-col
																	cols="12"
																	md="2"
																	lg="2"
																	><s-text
																		number
																		label="Cantidad Real"
																		v-model="
																			PsdQuantityReal
																		"
																		:min="0"
																	></s-text
																></v-col>
															</v-row>
														</v-col>
													</v-card>
												</v-col>

												<v-col lg="12" cols="12">
													<v-card>
														<v-col
															style="padding:0px"
														>
															<v-data-table
																item-key="Line"
																dense
																:items="
																	itemsDetails"
																:headers="
																	headersDetail"
																:items-per-page="
																	-1"
																hide-default-footer>
																<template
																	v-slot:item.Actions="{item}">
																	<v-btn
																		@click="
																			removeItem(item)"
																		x-small
																		color="error"
																		elevation="0"
																		block>
																		<v-icon
																			style="font-size: 16px !important"
																			>fas
																			fa-times</v-icon
																		>
																	</v-btn>
																</template>
															</v-data-table>
														</v-col>
													</v-card>
												</v-col>
											</v-row>
										</v-col>
									</div>
								</v-row>
							</v-card>
						</template>
					</s-crud>
				</v-col>
			</v-row>
			<v-dialog
				v-if="dialogGuideSelected"
				v-model="dialogGuideSelected" > 
					<lgs-referral-guide-edit
						:typeGuide="typeGuide"
						@closeDialogReferral="closeDialogReferral()"
						:referralGuideSelected="referralGuide"
					></lgs-referral-guide-edit>
			</v-dialog>
		</v-container>
	</div>
</template>

<script>
	//Service
	import _sProgrammingStorageService from "@/services/Technicalassistance/ProgrammingStorageService";
	import _sReceptionSurveillance from "@/services/Technicalassistance/ReceptionSurveillanceService";
	import _sParameter from "@/services/General/ParameterService.js";

	//import _sProducerService from "@/services/Technicalassistance/HarvestSchedule";
	import LgsReferralGuideEdit from '@/views/Logistics/ReferralGuide/LgsReferralGuideEdit.vue';

	//Componentes
	import SSelectTransport from "@/components/HarvestSchedule/SSelectTransport";
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	import SSelectProducer from "@/components/HarvestSchedule/SSelectProducer.vue";
	import SSelectLot from "@/components/HarvestSchedule/SSelectLot.vue";

	export default {
		components: {
			SSelectTransport,
			SSelectVariety,
			SSelectProducer,
			SSelectLot,
			LgsReferralGuideEdit,
		},

		data: () => ({
			dialogGuideSelected: false,
			referralGuide: {},
			typeGuide: 4,
			disabled: true,
			headersDetail: [
				{ text: "Acccion", value: "Actions", width: 50 },
				{ text: "Localidad", value: "TypeLocationName", width: 200 },
				/* { text: "Cultivo", value: "TypeCultiveName", width: 100 }, */
				{ text: "Tipo de Cultivo", value: "TypeCropName", width: 100 },
				{ text: "Variedad", value: "VrtName", width: 100 },
				{ text: "Cantidad Estimada", value: "PsdQuantity", width: 100 },
				{ text: "Cantidad Real", value: "PsdQuantityReal", width: 100 }
			],
			filter: { cDateInitial: null, cDateFin: null, PseState: 1 },
			config: {
				model: {
					PseID: "ID",
					PseAproximateArrivaleDate: "date"
				},
				service: _sProgrammingStorageService,
				headers: [
					{ text: "ID", value: "PseID", width: 10 },
					{
						text: "Tipo Transport",
						value: "TypeTransportName",
						width: 10
					},
					{ text: "Productor", value: "PrdName", width: 10 },
					{ text: "Tipo servicio", value: "TypeServiceName", width: 50 },
					{ text: "Planta Procesadora", value: "TypeProcessingPlantName", width: 50 },
					{ text: "Empacadora", value: "TypeBalerName", width: 50 },
					{
						text: "Fecha de Recojo",
						value: "PseAproximateArrivaleDate",
						width: 10
					},
					{ text: "ID Lote", value: "IDLots", width: 10 },
					{ text: "Tipo Carga", value: "TypeChargeName", width: 10 },
					{ text: "Cantidad Total", value: "PseQuantityTotal", width: 10 },
					{ text: "Placa", value: "TptEnrollment", width: 10 },
					{ text: "Destino", value: "TypeDestinyName", width: 50 }
				]
			},
			
			objLot: {},
			ObjProducer: {},
			ObjTransport: {},
			objTypeLocation: {},
			/* objTypeCultive: {},
			
			ObjVariety: {}, */
			objTypeCrop: {},//samir
			TypeDestiny: 2,
			PrdID: 0,

			itemsDetails: [],
			itemsDetailsRemoved: [],
			selectedHeader: {},
			PsdQuantity: 0,
			PsdQuantityReal : 0,
			NameDrive: "",
			TypeVehicleName: "",

			VarietyID: null,
			programmingStorageSelected : [],
			referralGuideItems : []
		}),

		watch: {
			ObjTransport() {
				if (this.selectedHeader.PseID !== 0) {
					if (this.ObjTransport !== null) {
						this.NameDrive = this.ObjTransport.Conductor;
						this.TypeVehicleName = this.ObjTransport.DedDescription;
					} else {
						this.NameDrive = null;
						this.TypeVehicleName = null;
					}
				}
			},
			ObjProducer(){
				if(this.ObjProducer == null){
					this.PrdID = 0
				}
				console.log(this.ObjProducer);
			},

			objLot(){
				console.log("dddddddddddddd",this.objLot);
				if(this.objLot == null){
					this.VarietyID = null
				}
			}
		},

		created() {},

		methods: {
			closeDialogReferral(){
				this.dialogGuideSelected = false;
			},

			validateGroupGuide()
			{
				var errors = {
					error : 0,
					message : ''
				};

				if(this.programmingStorageSelected.length > 0)
				{
					let PrdIDOriginal = this.programmingStorageSelected[0].PrdID;
					let VehPlateOriginal = this.programmingStorageSelected[0].TptEnrollment;
					this.programmingStorageSelected.forEach(element => {
						if(element.PrdID != PrdIDOriginal)
						{
							errors.error = 1;
							errors.message = "Productores diferentes";
						}
						if(element.TptEnrollment != VehPlateOriginal)
						{
							errors.error = 1;
							errors.message = "Vehículos diferentes"
						}
					});
				}

				return errors;
				
			},

			openDialogGuide()
			{
				//validamos que no tengo una guia la programacion
				var hasguia = 0;
				var hasguiaPseID = 0;
				this.programmingStorageSelected.forEach(element =>{
					if(element.PseStatusGuide > 0)
					{
						hasguia = hasguia + 1;
						hasguiaPseID = element.PseID;
					}
				})
				if(hasguia > 0)
				{
					this.$fun.alert("La programación ya tiene una guía generada - " + hasguiaPseID, "warning");
					return;
				}
				//
				let errors = this.validateGroupGuide();
				if(errors.error > 0)
				{
					this.$fun.alert(errors.message, "warning");
					return;
				}
				let objParam = {
					PrmName: "MOTIVOGUIAFRUTAACOPIO"
				};
					
				_sParameter
				.search(objParam, this.$fun.getUserID())
				.then((r) => {
					this.referralGuide.RegOtherMotive = r.data.PrmValue;
					
				});
				let detail = [];
				let counter = 0;
				this.referralGuideItems.forEach(element => {
					console.log('elementtt ', element);
					counter ++;
					detail.push({
						RgdLine: counter,
						DocEntry: element.PsdID,
						/* RgdDescription : element.TypeCultiveName + " " + element.TypeCropName + " Variedad: " 
							+ element.VrtName.toUpperCase()  */
						// + " Proveedor: " + element.PrdText
						RgdDescription : element.TypeCultiveName + " PARA INDUSTRIA, "  + element.VrtName.toUpperCase() + "," + element.PrdResponsibleName + " " + "FUNDO: " + element.FagName + ", " + element.PrdResponsibleDocumentNumber + ", " + element.FltCodSenasa + ", ID: " + element.FltID,
						RgdQuantity: element.PsdQuantityReal,
						RgdUnitMeasurement: "UND",
						TypeUnitMeasurement: 4,
						SecStatus: 1,	
						UsrCreateID: this.$fun.getUserID(),
						TypeDetail: 1
					});							
				});
				counter = 0;
				this.referralGuide.PrsDocumentNumberDriver = this.selectedHeader.NumDocDriver == null ? "" : this.selectedHeader.NumDocDriver.trim();
				this.referralGuide.NpdFullNameDriver = this.selectedHeader.TptDriverName == null ? "" : this.selectedHeader.TptDriverName.trim();
				this.referralGuide.RegAddressStartPoint = this.selectedHeader.FagAddress == null ? "" : this.selectedHeader.FagAddress.trim();
				this.referralGuide.NpdDocumentNumberDriver = this.selectedHeader.LicenseDriver;
				this.referralGuide.VehPlate = this.selectedHeader.TptEnrollment.trim();
				this.referralGuide.RegBrandTransport = this.selectedHeader.TypeBrandName;
				this.referralGuide.RegConstancyInscripcionTransport = this.selectedHeader.RegistrationConstancy;
				this.referralGuide.RegRucTransport = this.selectedHeader.VehRuc;
				this.referralGuide.RegBusinessNameTransport = this.selectedHeader.VehBusinessName;
				this.referralGuide.TypeAreGuide = 4;

				this.referralGuide.referralGuideItems = detail;
				this.dialogGuideSelected = true;
				
			},

			getDetailByRowSelected()
			{
				this.referralGuideItems = [];
				this.programmingStorageSelected.forEach(element =>{
					_sProgrammingStorageService
					.GetDetails(
						{ PseID: element.PseID },
						this.$fun.getUserID()
					)
					.then(r => {
						this.referralGuideItems.push(...r.data);
					});
				})
				
			},

			CleanAll() {

				this.objTypeLocation = {}
				/* this.objTypeCultive = {}*/
				this.objTypeCrop = {} 
				this.ObjVariety = {}

				this.ObjProducer = {};
				this.itemsDetails = [];
				this.itemsDetailsRemoved = [];

				this.ObjTransport = {};
				this.NameDrive = null;
				this.TypeVehicleName = null;
				this.PsdQuantity = 0;
				this.PsdQuantityReal = 0;
				this.selectedHeader = [];
				this.PrdID = 0
			},

			inputProducer(obj) {
				if (obj !== null) {
					if (obj.PrdID !== undefined) {
						this.PrdID = obj.PrdID
						console.log("inputProducer", obj.PrdID);
					}
				}
			},

			inputLot(obj){

					this.VarietyID = obj.VrtID
				
			},

			rowSelected(item) {
				console.log('rowselected', item);
				if (item.length > 0) {
					this.disabled = false;
					this.programmingStorageSelected = item;
					this.getDetailByRowSelected();
					this.selectedHeader = item[0];
					this.ObjTransport.VehPlate = this.selectedHeader.TptEnrollment;
					this.NameDrive = item[0].TptDriverName;
					this.TypeDestiny = item[0].TypeDestiny;

					_sProgrammingStorageService
						.GetDetails(
							{ PseID: this.selectedHeader.PseID },
							this.$fun.getUserID()
						)
						.then(r => {
							this.itemsDetails = r.data;
							this.orderDetails();
						});
				}

				//this.NameDrive = null;
				//this.TypeVehicleName = null;

				console.log(this.programmingStorageSelected);
			},

			/*********************************** Cabcera Principal ************************** */

			/*********************************** FIN Cabcera Principal ************************** */

			isValidatedHeader(val) {
				var isValidated = true;

				if (this.selectedHeader.PseState == 2) {
					this.$fun.alert(
						"Registro ya fue recepcionado, no se pude actualizar datos",
						"warning"
					);
					isValidated = false;
					return;
				}

				if (this.selectedHeader.PseState == 3) {
					this.$fun.alert(
						"Registro anulado, no se puede actualizar datos",
						"warning"
					);
					isValidated = false;
					return;
				}

				if (val.PrdID == undefined) {
					this.$fun.alert("Debe un Especificar un Productor", "warning");
					isValidated = false;
					return;
				}

				if (val.TptEnrollment == undefined) {
					this.$fun.alert("Debe un Especificar un Transport", "warning");
					isValidated = false;
					return;
				}

				// se esta validaadno , es para permitir que que 

				// if (val.PseAproximateArrivaleDate < this.$fun.getDate()) {
				// 	this.$fun.alert(
				// 		"Fecha de Recojo no puede ser menor a la fecha actual",
				// 		"warning"
				// 	);
				// 	isValidated = false;
				// 	return;
				// }

				if (this.itemsDetails.length == 0) {
					this.$fun.alert(
						"No existen detalles para esta Programación",
						"warning"
					);
					isValidated = false;
					return;
				}

				return isValidated;
			},

			isValidatedDetails(item) {
				var isValidated = true;

				if (this.selectedHeader.PseState == 2) {
					this.$fun.alert(
						"No se puede agregar Item a Registro, Ya fue Recepcionado",
						"warning"
					);
					isValidated = false;
					return;
				}

				if (this.selectedHeader.PseState == 3) {
					this.$fun.alert(
						"Registro anulado, no se pueden agregar item",
						"warning"
					);
					isValidated = false;
					return;
				}

				if(this.objLot == null || this.objLot.FltID == undefined){
					this.$fun.alert("Seleccione un Lote", "warning");
					isValidated = false;
					return;
				}

				if (
					this.objTypeLocation.DedValue == undefined ||
					this.objTypeLocation.DedValue == null
				) {
					this.$fun.alert("Debe un Especificar una Localidad", "warning");
					isValidated = false;
					return;
				}

				 if (
					this.objTypeCrop == undefined ||
					this.objTypeCrop.DedValue == undefined ||
					this.objTypeCrop == ""
				) {
					this.$fun.alert(
						"Debe un Especificar un Tipo de Cultivo",
						"warning"
					);
					isValidated = false;
					return;
				}  

				/* if (
					this.objTypeCultive.DedValue == undefined ||
					this.objTypeCultive == null
				) {
					this.$fun.alert("Debe un Especificar un Cultivo", "warning");
					isValidated = false;
					return;
				} */

				/* if (this.ObjVariety == null || this.ObjVariety.VrtID == undefined) {
					this.$fun.alert("Debe un Especificar una Variedad", "warning");
					isValidated = false;
					return;
				} */

				if (this.VarietyID == null || this.VarietyID == undefined) {
					this.$fun.alert("Debe un Especificar una Variedad", "warning");
					isValidated = false;
					return;
				}

				if (this.PsdQuantity == undefined) {
					this.$fun.alert("Debe un Especificar una Cantidad", "warning");
					isValidated = false;
					return;
				}

				if (this.PsdQuantity == 0) {
					this.$fun.alert("La Cantidad debe ser mayor a 0", "warning");
					isValidated = false;
					return;
				}

				return isValidated;
			},

			isValidatedRemoveItem() {
				var isvalid = true;
				if (this.selectedHeader.PseState == 2) {
					this.$fun.alert(
						"No se puede quitar Item a Registro, Ya fue Recepcionado",
						"warning"
					);
					isvalid = false;
					return;
				}

				if (this.selectedHeader.PseState == 3) {
					this.$fun.alert(
						"Registro anulado, no se pueden quitar item",
						"warning"
					);
					isvalid = false;
					return;
				}

				return isvalid;
			},

			isValidatedRemove() {
				var isvalid = true;

				if (
					this.selectedHeader.PseID == 0 ||
					this.selectedHeader.PseID == undefined
				) {
					this.$fun.alert("Seleccione registro", "warning");
					isvalid = false;
					return;
				}

				if (this.selectedHeader.PseState == 2) {
					this.$fun.alert(
						"No se puede eliminar, ya esta recepcionado",
						"warning"
					);
					isvalid = false;
					return;
				}

				if (this.selectedHeader.PseState == 3) {
					this.$fun.alert(
						"No se puede eliminar, Registro anulado",
						"warning"
					);
					isvalid = false;
					return;
				}
				return isvalid;
			},

			isValidatedDuplicate() {
				var isvalid = true;

				if (
					this.selectedHeader.PseID == 0 ||
					this.selectedHeader.PseID == undefined
				) {
					this.$fun.alert("Seleccione registro", "warning");
					isvalid = false;
					return;
				}

				if (this.selectedHeader.PseState == 3) {
					this.$fun.alert(
						"No se puede duplicar, registro esta anulado",
						"warning"
					);
					isvalid = false;
					return;
				}

				if (this.selectedHeader.PseState == 2) {
					this.$fun.alert(
						"No se puede duplicar, registro ya fue recepcionado",
						"warning"
					);
					isvalid = false;
					return;
				}

				return isvalid;
			},

			/*********************************** Detalles ************************** */

			orderDetails() {
				let i = 0;
				this.itemsDetails.map(e => {
					i++;
					e.Line = i;
				});
			},

			newItem() {
				if (!this.isValidatedDetails(item)) {
					return;
				}

				this.itemsDetails.Line = this.itemsDetails.length + 1;
				let item = {
					Line: this.itemsDetails.Line,
					FltID: this.objLot.FltID,
					TypeLocation: this.objTypeLocation.DedValue,
					TypeLocationName: this.objTypeLocation.DedDescription,
					/* TypeCultive: this.objTypeCultive.DedValue,
					TypeCultiveName: this.objTypeCultive.DedDescription,*/
					TypeCrop: this.objTypeCrop.DedValue,//samir
					TypeCropName: this.objTypeCrop.DedDescription, //samir
					VrtID: this.VarietyID,
					VrtName: this.objLot.VrtDescription,
					PsdQuantity: this.PsdQuantity,
					PsdQuantityReal: this.PsdQuantityReal,
					SecStatus: 1,
					UsrCreateID: this.$fun.getUserID()
				};

				var exist = false;
				this.itemsDetails.forEach(element => {
					/* if (this.objTypeCultive.DedValue !== element.TypeCultive) {
						exist = true;
						this.$fun.alert(
							"No se puede agregar diferente cultivo",
							"warning"
						);
					} */

					if (this.VarietyID == element.VrtID) {
						exist = true;
						this.$fun.alert(
							"No se puede agregar misma variedad",
							"warning"
						);
					}
				});

				if (exist == false) {
					this.itemsDetails.push(item);
				}
			},

			removeItem(item) {
				if (this.itemsDetails.length > 0) {
					if (item.PsdID > 0) {
						this.itemsDetailsRemoved.push(
							this.itemsDetails.filter(x => x.Line == item.Line)[0]
						);
						this.itemsDetailsRemoved.forEach(element => {
							element.SecStatus = 0;
						});
					}

					this.itemsDetails = this.itemsDetails.filter(
						x => x.Line != item.Line
					);
					this.orderDetails();
				}
			},
			/**************************************** ************************** */

			save(val) {
				val.SecStatus = 1;
				val.UsrCreateID = this.$fun.getUserID();
				val.PrdID = this.ObjProducer.PrdID;
				val.TypeDestiny = this.TypeDestiny;
				/* val.FagID = this.FagID;
					val.FltID = this.FltID; */
				val.TptEnrollment = this.ObjTransport.VehPlate;
				val.TptDriverName = this.ObjTransport.Conductor;
				val.NumDocDriver = this.ObjTransport.NumDocDriver;
				val.LicenseDriver = this.ObjTransport.LicenseDriver;
				
				 
				if(val.TptDriverName == undefined){
					this.$fun.alert("Error, al parecer no se ha cargado el conductor, vuelva a seleccionar el registro", "warning")
					return;
				}

				if (!this.isValidatedHeader(val)) {
					return;
				}

				val.TasProgrammingStorageDetail = [
					...this.itemsDetails,
					...this.itemsDetailsRemoved
				];
				 
				val.save();

				console.log("Guardar", val);
			},

			remove() {
				if (!this.isValidatedRemove()) {
					return;
				}

				this.$fun
					.alert("Seguro de Eliminar registro?", "question")
					.then(val => {
						if (val.value) {
							_sProgrammingStorageService
								.delete(
									{
										PseID: this.selectedHeader.PseID,
										UsrCreateID: this.$fun.getUserID()
									},
									this.$fun.getUserID()
								)
								.then(r => {
									if(r.status == 200){
										this.$fun.alert(
											"Eliminado Correctamente",
											"success"
										);
										this.selectedHeader.PseID = 0
										this.$refs.gridProgrammingStorage.refresh();
									}
									
								});
						}
					});
			},

			duplicate() {
				if (!this.isValidatedDuplicate()) {
					return;
				}

				this.$fun
					.alert(
						"Esta seguro de Duplicar el registro ? " +
							this.selectedHeader.PseID,
						"question"
					)
					.then(val => {
						if (val.value) {
							_sProgrammingStorageService
								.duplicate(
									{ PseID: this.selectedHeader.PseID },
									this.$fun.getUserID()
								)
								.then(r => {
									this.$fun.alert(
										"Registro duplicado correctamente",
										"success"
									);
									this.$refs.gridProgrammingStorage.refresh();
								});
						}
					});
			}

			//**************************** *************** Grilla de Detalle ********************************************************
		}
	};
</script>

<template>
	<div>
		<v-container>
			<v-row>
				<!-- Inicio Fundo/User -->
				<div class="col-md-12 col-sm-12">
					<v-col
						cols="12"
						md="12"
						style="margin-left: 0px; padding: unset; margin-top: -12px;"
					>
						<s-toolbar
							:save="lotProduction.AlbID == 0 || $fun.isAdmin()"
							@save="save($event)"
							:color="'#BAB6B5'"
							dark
							close
							@close="close()"
							label="Registro de daños"
						/>
					</v-col>
					<v-card dense>
						<v-row style="margin: auto" justify="center">
							<v-col cols="12">
								<v-row justify="center">
									<v-col cols="6" lg="1">
										<b><s-text
											v-model="lotProduction.RcfID"
											readonly
											label="Lote Prod."
										></s-text></b>
									</v-col>
									<v-col cols="6" lg="2">
										<s-text
											v-model="lotProduction.VrtName"
											readonly
											label="Variedad"
										></s-text>
									</v-col>
									<v-col cols="12" lg="3">
										<s-text
											v-model="lotProduction.PrdCardName"
											readonly
											label="Productor"
										></s-text>
									</v-col>
									<v-col cols="4" lg="2">
												<b><s-text
													v-model="lotProduction.WbbNetWeight"
													readonly
													label="Peso Referencial (KG)"
												></s-text></b>
									</v-col>
									<v-col cols="4" lg="2">
										<b><s-text
											v-model="lotProduction.AmountJabasWeigh"
											readonly
											label="Jabas"
										></s-text></b>
									</v-col>

									<v-col cols="4" lg="2">
										<b><s-text
											v-model="lotProduction.WbbWeightAverage"
											readonly
											label="Peso Prom."
										></s-text></b>
									</v-col>									
								</v-row>
							</v-col>
						</v-row>

						<v-row style="margin: auto;">
							<v-col cols="12" lg="5" md="5">
								<v-card dense>
									<v-card-text
										style="height: 40px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
									>
										<b>Datos Generales </b> <b style="color: red"> || {{lotProduction.ZliIgnore !== null ? 'En Base a Peso Guia' : 'En Base a Peso Recepción'}}</b>
									</v-card-text>

									<v-card-actions>
										<v-row justify="center">
											<!-- <v-col cols="6" lg="4">
												<b><s-text
													v-model="lotProduction.LdhWeightCalibrated + sumDiscartWeight"
													readonly
													label="Peso Exp + Descarte (KG)"
												></s-text></b>
											</v-col> -->
											<v-col cols="6" lg="6">
												<b>
													<s-text
														v-model="isNaN(sumWeightExportable) ? 0 : sumWeightExportable"
														label="Peso Exportable"
														decimal
														readonly
													></s-text>
												</b>
											</v-col>
											<v-col cols="6" lg="6">
												<b><s-text
													v-model="isNaN(sumDiscartWeight) ? 0 : sumDiscartWeight.toFixed(4)"  
													label="Descarte Total (Kg)"
													decimal
													readonly
												></s-text></b>
											</v-col>
											<v-col cols="6" lg="4">
												<b>	
													<!-- v-model="isNaN(sumDiscartWeightPorce) ? 0 : sumDiscartWeightPorce" 
													sumDiscartWeightPorce >= alert && sumDiscartWeightPorce < critical ? 'Alerta' : sumDiscartWeightPorce >= critical == 'Critico'
													sumDiscartWeightPorce >= alert && sumDiscartWeightPorce < critical ? 'yellow' : sumDiscartWeightPorce >= critical == 'error'
													-->
													<v-text-field
														label="Descarte Total (%)"
														readonly
														v-model="sumDiscartWeightPorce"
														:messages="sumDiscartWeightPorce >= alert && sumDiscartWeightPorce < critical ? 'Alerta' : sumDiscartWeightPorce >= critical ? 'Critico': ''"
													>
														<template v-slot:message={message}>
															<v-chip :color="sumDiscartWeightPorce >= alert && sumDiscartWeightPorce < critical ? 'yellow' : sumDiscartWeightPorce >= critical ? 'error':''" x-small>
																{{message}}
															</v-chip>
														</template>
													</v-text-field>
												
												</b>
											</v-col>

											<v-col cols="6" lg="4" class="pb-0 pt-4">
												<b>
													<v-text-field
														label="Promedio Jabas"
														readonly
														v-model="averageJabas"
													></v-text-field>
												</b>
											</v-col>
											<v-col cols="6" lg="4" class="pb-0 pt-4">
												<b><v-text-field
														label="Cant. Jbs Cal."
														readonly
														v-model="lotProduction.LdhQuantityJabas"
													></v-text-field></b>
											</v-col>

											<!-- <v-col cols="12" lg="4" class="pb-0 pt-4">
												<b><v-text-field
														label="Cant. KG Cal. Refer."
														readonly
														v-model="lotProduction.LdhQuantityJabas * averageJabas"
													></v-text-field></b>
													lotProduction.LdhWeightCalibrated
											</v-col> -->

											
										</v-row>
									</v-card-actions>
								</v-card>
							</v-col>
							<v-col cols="12" lg="7" md="7">
								<v-card :disabled="lotProduction.AlbID != 0">
									<v-card-text
										style=" -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
									>
									<!-- height: 40px; -->
										 <b>Pesos (KG)</b>
										
										
										
									</v-card-text>

									<v-card-actions>
										<v-row justify="center">
											<v-col cols="4" lg="3">
												
													<s-text
														v-model="item.LdhMatureDiscart"
														label="Maduro"
														decimal
													></s-text>
												
											</v-col>

											<v-col cols="4" lg="3">
												
													<s-text
														v-model="item.LdhOverweight"
														label="Sobrepeso"
														decimal
													></s-text>
												
											</v-col>

											<v-col cols="4" lg="3">
												
													<s-text
														v-model="item.LdhColor"
														label="Color 01"
														decimal
													></s-text>
												
											</v-col>

											<v-col cols="4" lg="3">
												
													<s-text
														v-model="item.LdhCaliber06"
														label="CAL 05"
														decimal
													></s-text>
												
											</v-col>
											<v-col cols="4" lg="3">
												
													<s-text
														v-model="item.LdhCaliber14"
														label="CAL 14"
														decimal
													></s-text>
												
											</v-col>
											<v-col cols="4" lg="3">
												
													<s-text
														v-model="item.LdhCaliber16"
														label="CAL 16"
														decimal
													></s-text>
												
											</v-col>
											<v-col cols="4" lg="3">
												
													<s-text
														v-model="item.LdhDamage"
														label="Desc. Daños"
														decimal
													></s-text>
												
											</v-col>
											<v-col cols="4" lg="3">
												
													<s-text
														v-model="item.LdhQuantityShows"
														:label="'Cant. M.'"
														number
														ref="LdhQuantityShows"
														:min="0"
														readonly
													></s-text>
												
											</v-col>
											 <v-col cols="6"  >
												<s-text label="Observaciones" v-model="item.LdhObservation"/>
											</v-col>
											<v-col cols="3"  >
												<s-text label="Jabas Descarte" v-model="item.LdhDiscartJaba" number :min="0"  />
											</v-col>
											<v-col cols="3"  >
												<s-text label="Jabas Muestreadas" v-model="item.LdhSampledJaba" number :min="0"  />
											</v-col>
										</v-row>
										 
									</v-card-actions>
								</v-card>
							</v-col>

							<v-col cols="12" lg="12" md="12">
								<v-card>
									<v-card-text
										style="height: 40px;  -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
									>
										<b>Daños</b>
										<b style="color: blue; font-size: 18px">, {{item.LdhQuantityShows}}</b>
									</v-card-text>
									<v-card-actions>
										<v-row>
											<v-col
												v-for="item in Object.keys(itemsTypeDamage)"
											>
												<h4>{{ item }}</h4>
												<v-divider></v-divider>
												<v-col class="pt-5 pr-0">
													<v-row
														v-for="d in itemsTypeDamage[item]"
													>
														<v-col
															cols="8"
															class="pb-0 pt-1 pr-0 pl-0"
														>
															<s-text
																:label="d.DdcDescription"
																number
																v-model="d.LddValue"
																:min="0"
																@input="Calculate()"
															/>
														</v-col>
														<v-col
															cols="4"
															class="pb-0 pt-1 pr-0 pl-0"
															v-if="d.LddValuePorcentaje > 0"
														>
															<s-text
																label="%"
																decimal
																v-model="d.LddValuePorcentaje"
																readonly
															/>
														</v-col>
													</v-row>
												</v-col>
											</v-col>
										</v-row>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>
		 <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
				<v-card color="primary" dark>
					<v-card-text v-if="messageProcessing.length == 0">
					Por favor espere
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
					<v-card-text v-else>
					<div class="text--white pt-4">
						Error al realizar operacion <br />
						{{ messageProcessing }}
					</div>
					</v-card-text>
				</v-card>
			</v-dialog>

	</div>
</template>

<script>
	import _sDiscartDamageCultiveService from "../../../services/FreshProduction/PrfDiscartDamageCultiveService";
	import _sLotDiscartDamageService from "../../../services/FreshProduction/PrfLotDiscartDamageService";
	import _sGenParam from "../../../services/General/ParameterService";

	export default {
		props: {
			lotProduction: null,
		},

		data() {
			return {
				itemsTypeDamage: [],
				discartDamageCultive: null,
				itemsDamaga: [],
				isEdition: false,
				BrxNumberJabas: 0,
				item: {
					
				},
				alert: 0,
				critical: 0,
				sumItemsDamage: 0,
				warningText: '',
				warningColor: '',
				averageJabas: 0,

				processing:false,
				error: false,
				messageProcessing: "",
			};
		},

		created() {

			this.loadParameter();

			_sLotDiscartDamageService
				.getDamageHeader(this.lotProduction.RcfID, this.$fun.getUserID())
				.then(r => {

					if(r.data == null){

					}else{
						this.item = r.data
					}
						
				});

			_sLotDiscartDamageService
				.getDamageList(this.lotProduction.TypeCultive, this.lotProduction.RcfID, this.$fun.getUserID())
				.then(r => {
					let itemsDamage = r.data;
					this.itemsTypeDamage = _.groupBy(itemsDamage, "TypeDamageName");

					for (let prop in this.itemsTypeDamage) {
						this.itemsTypeDamage[prop].map((i) => {
							i.RcfID = this.lotProduction.RcfID;
							i.LdhID = this.item.LdhID
							i.LddValuePorcentaje = ((i.LddValue / this.item.LdhQuantityShows) * 100).toFixed(2)
						});
					}

					console.log("daños", this.itemsTypeDamage);

					
				});

			this.Calculate()

		},

		computed: {
			sumDiscartWeight (){
				/* */
				return  parseFloat(this.item.LdhMatureDiscart) +  parseFloat(this.item.LdhOverweight) + parseFloat(this.item.LdhColor) + parseFloat(this.item.LdhCaliber06) + parseFloat(this.item.LdhCaliber14) + parseFloat(this.item.LdhCaliber16) + parseFloat(this.item.LdhDamage)
			},

			sumWeightExportable(){
				return (parseFloat(this.lotProduction.WbbNetWeight) - parseFloat(this.sumDiscartWeight)).toFixed(6)
			},
			sumDiscartWeightPorce(){
				var result = ((parseFloat(this.sumDiscartWeight) / parseFloat(this.lotProduction.WbbNetWeight)) * 100).toFixed(2)
				return result
			}

		},

		watch: {
			'item.LdhQuantityShows'(){
				for (let prop in this.itemsTypeDamage) {
					this.itemsTypeDamage[prop].map((i) => {
						i.LddValuePorcentaje = ((i.LddValue / this.item.LdhQuantityShows) * 100).toFixed(2)
						i.LdhID = this.item.LdhID
						
					});
				}
			},

			sumWeightExportable(){
				this.averageJabas = (parseFloat(this.sumWeightExportable)  / parseInt(this.lotProduction.LdhQuantityJabas)).toFixed(6);
			}

			

			/* sumDiscartWeight(){
				console.log("proce", this.sumDiscartWeightPorce, this.critical, this.alert);
				if(this.sumDiscartWeightPorce >= this.alert && this.sumDiscartWeightPorce < this.critical){
					this.warningText = 'Alerta' 
					this.warningColor = 'warning'
				}else if(this.sumDiscartWeightPorce >= this.critical){
					this.warningText = 'Critico'
					this.warningColor = 'error'
				}
			} */
		},

		methods: {


			loadParameter() {

				_sGenParam
					.search(
						{ PrmName: "calibradoAlertaAmarillaDescarte" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						this.alert = resp.data.PrmValue;
						console.log(this.alert);
					});

				_sGenParam
					.search(
						{ PrmName: "calibradoAlertaRojaDescarte" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						this.critical = resp.data.PrmValue;
					});

			},
			
			close() {
				this.$emit("close");
			},

			Calculate() {
				var sum  = 0
				for (let prop in this.itemsTypeDamage) {
					this.itemsTypeDamage[prop].map((i) => {
						if(i.LddValue == ''){
							i.LddValue = 0
						}
						sum += parseInt(i.LddValue)
					});
				}

				this.sumItemsDamage = sum
				this.item.LdhQuantityShows = sum
				
			},

			save() {

				if(this.item.LdhMatureDiscart.length == 0 || 
					this.item.LdhOverweight.length == 0 ||
					this.item.LdhColor.length == 0 ||
					this.item.LdhCaliber06.length == 0 ||
					this.item.LdhCaliber14.length == 0 ||
					this.item.LdhCaliber16.length == 0 ||
					this.item.LdhDamage.length == 0){
					this.$fun.alert("Error, ninungo de Pesos (KG), puede quedar vacio, completelos con 0", "warning")
					return;
				}

				if(this.lotProduction.ZliIgnore == null){
					if(this.item.LdhQuantityShows.length == 0 || this.item.LdhQuantityShows <= 0){
						this.$refs.LdhQuantityShows.focus()
						this.$fun.alert("No se ha calculado cantidad de muestra", "warning")
						return;
					}
				}

				/* if(this.sumWeightExportable < this.lotProduction.LdhWeightCalibrated )
				{ 
					this.$fun.alert("Error, el Peso exportable no puede ser inferior al Total de KG Calibrados, Por favor verifique. KG Calibrados = " +  this.lotProduction.LdhWeightCalibrated , "warning")
					return;
				} */

				/* if(this.lotProduction.LdhWeightCalibrated == 0){
					this.$fun.alert("Error, No se han distribuido KG x Calibre" , "warning")
					return;
				} */
				
	
				this.item.RcfID = this.lotProduction.RcfID
				this.item.LdhDiscartTotal = this.sumDiscartWeight.toFixed(4);
				this.item.LdhDiscartTotalPerce = this.sumDiscartWeightPorce;
				this.item.LdhWeightExportable = this.sumWeightExportable;
				this.item.LdhWeightAverageJaba = this.averageJabas

				this.item.SecStatus = 1
				this.item.UsrCreateID = this.$fun.getUserID();

				this.item.itemsDetails = [];
				for (let prop in this.itemsTypeDamage)
        			this.item.itemsDetails.push(...this.itemsTypeDamage[prop]);

				console.log("Guardar", this.item);
				this.$fun.alert("¿Seguro de Guardar?", "question").then(x => {
					
					if(x.value){
						this.processing = true;
						_sLotDiscartDamageService
							.save(this.item, this.$fun.getUserID())
							.then(resp => {
								if (resp.status == 200) {
									this.processing = false;
									this.$fun.alert("Guardado Correctamente", "success");
									this.$emit("close");
								}
							},
							(e) => {
								this.error = true;
								this.messageProcessing = e.response.data.Message;
								this.processing = true;
							});
					}

				})
				
			},
		},
		mounted(){
			this.item.LdhSampledJaba = 0;
			this.item.LdhDiscartJaba = 0;
		}
		
	};
</script>

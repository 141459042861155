<template>
	<div>
		<s-crud
			title="PERSONAL AUTORIZADO - CRITICIDAD"
			:config="config"
			:filter="filter"
            sortable
			add
			edit
			remove
			@save="save($event)"
			ref="gridUserCriticidad"      
            @rowSelected="rowSelected($event)" 
			searchInput
		>   
		<template v-if="isCrud" v-slot:options>
        <v-tooltip bottom>
          <template >
            <v-btn >
              <v-icon color="default" x-small>
                fas fa-copy
              </v-icon>
            </v-btn>
          </template>
          <span>Copiar a</span>
        </v-tooltip>
      </template>
			<template v-slot:filter>
				<v-container style="margin-bottom: 20px;">
                    <v-row justify="center">   
                      <v-col cols="4" class="s-col-form">
							<s-select-definition
								label="Cricticidad"
								:def="1150"
								v-model="filter.TypeCriticality"
								clearable
							></s-select-definition>
					  </v-col> 
					</v-row>
				</v-container>
			</template>

			<template scope="props" >
				<v-container >
					<v-row justify="center">                      
                       <v-col cols="2" class="s-col-form">
                        	<s-toolbar-person   noCamera readonly noEdit :defaultDocument="props.item.NumDocument" @returnPerson="returnPerson($event, props)" v-model="props.item.NumDocument" />
                        </v-col>

						<v-col cols="3" class="s-col-form">
							<s-text readonly label="Persona" v-model="props.item.NameComplet"></s-text>
							<s-text hidden v-model="props.item.NtpID"></s-text>
						</v-col>	

                        <v-col cols="3" class="s-col-form">
							<s-select-definition
								label="Cricticidad"
								:def="1150"
								v-model="props.item.TypeCriticality"
								clearable
							></s-select-definition>
                      </v-col>
					</v-row>

				</v-container>
			</template>

			<template v-slot:options>
			</template>

			<template v-slot:CritiStatus="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TypeCriticality==4?'info':row.TypeCriticality==3?'success':row.TypeCriticality==2?'warnig':row.TypeCriticality==1?'error':''"
				>
					{{ row.NameCriticality }}
				</v-chip>
			</template>

		</s-crud>

	</div>
</template>

<script>
	import _sUCritiService from '@/services/Security/UserCricicalService.js';  
	import SText from '@/components/Utils/SText.vue';
	import _sTaskService from '@/services/InformationTechnology/TaskService.js';
    import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
    import _sCttoService from '@/services/HumanResource/HmnContractService.js';
import { isUndefined } from 'lodash';

	export default {
  components: { SText,sToolbarPerson },
		data() {
			return {
                Position:[],
				itemSelected: null,
				filter: {
					PrsID: 0,
                    GpArt: -1,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0
				},
                
				config: {
					model: {
                        AcyID: "ID",
						Action: "Action",
						CritiStatus:"Integer"
					},
					service: _sUCritiService,
					headers: [
						{text: "ID", value: "AcyID"},
						{text: "DNI", value: "NumDocument"},
						{text: "Apellidos y Nombres", value: "NameComplet"},
						{text: "Cargo", value: "PstName"},
						{text: "Criticidad", value: "CritiStatus"},
	
						]
				},
				
			}
		},
        watch: {
            'filter.ItemsCod'() {
            this.refresh();
            },
        },
		methods: {
            returnPerson(value, props) {
				console.log(value,"dsds");
				props.item.NameComplet = value.NtpFullName;
				props.item.NumDocument = value.PrsDocumentNumber;
				props.item.NtpID = value.NtpID;
				props.item.PrsID = value.PrsID;
            },
			initialize()
			{
				this.getPositiom();
			},

			save(item) {				
			console.log("item save", item);
			  //if(item.SecStatus == null) item.SecStatus = 1
                  item.UsrCreateID=this.$fun.getUserID();
                _sUCritiService.save(item,this.$fun.getUserID()).then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Asignado Correctamente" , "info"); 
						this.$refs.gridUserCriticidad.refresh();
					}
				});

			},
            rowSelected(item)
			{
				if(item.length > 0)
				{
					this.itemSelected = item[0];
					//console.log("w",this.itemSelected)
					//this.item.NtpFullName = this.itemSelected.WorkerName;
				}
			}
		},

		created () {
			this.filter.AreID =  this.$fun.getUserInfo().CtrContract.AreID ?? 0;
			
			this.initialize();
		},
		mounted() {
			
			this.filter.PrsID =  this.$fun.getUserInfo().PrsID; 
					
			console.log( "USER INFO",this.$fun.getUserInfo()); 
		}
	}
</script>

<style>
	.scrollable {
		max-height: 450px; /* Ajusta según sea necesario */
		overflow-y: auto;  /* Habilita el scroll vertical */
	}
</style>
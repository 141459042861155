<template>
  <v-card v-if="arbolVisible">
    <v-card-title>
      <label>Seleccionar carpeta > archivos para compartir con usuario</label>
    </v-card-title>

    <v-card-text class="tree-container">
      <!-- Draggable TreeView -->
      <v-treeview
        :items="treeItems"
        activatable
        item-key="id"
        v-model="selectedItems"
        item-text="name"
        item-children="children"
        open-all
        @update:active="updateSelectedItems"
      >
        <template v-slot:prepend="{ item }" >
          <!-- Checkbox para cada carpeta y archivo -->
          <v-checkbox
            style="margin: 0 !important; padding: 0 !important; "
            v-if="item.type === 'folder'"
            v-model="item.checked"
            @change="handleFolderChange(item)"
            :indeterminate="isIndeterminate(item)"
          ></v-checkbox>
          <v-checkbox
            style="margin: 0 !important; padding: 0 !important;"
            v-else
            v-model="item.checked"
            @change="handleFileChange(item)"
          ></v-checkbox>
        </template>
      </v-treeview>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="success" dark depressed small @click="saveSharePermit">
        Compartir permisos
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
import _sFolderService from "@/services/InformationTechnology/SunDriveFileFolderService.js"; // Servicio de carpetas
import { onMounted } from "vue";

export default {
  data() {
    return {
      selectedItems: [], // Para almacenar los elementos seleccionados
      treeItems: [], // Para almacenar el árbol de carpetas
      foldersString: "", // String que almacenará los FdlID de los archivos seleccionados
      unselectedFoldersString:"",
      userID:0,
      arbolVisible:false
    };
  },
  methods: {
    // Cargar datos del árbol
    loadTreeViewData() {
      const foldersString = this.selectedFolders.join(",");
      let obj = {
        Folders: foldersString,
        FdpUserID:this.userID
      };

      _sFolderService
        .TicFileFolderDetaListShare(obj, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status === 200) {
            console.log("respuesta",resp.data.length);
            if(resp.data.length==0){
              this.$fun.alert("Carpeta vacia, para compartir carpeta no puede estar vacia", "warning");
            }
            const treeData = this.buildTree(resp.data);
            this.treeItems = treeData;
          } else {
            console.error("Error al cargar datos:", resp);
          }
        })
        .catch((error) => {
          console.error("Error al cargar el árbol de carpetas:", error);
        });
    },

    // Convertir la respuesta del servidor a una estructura de árbol
    buildTree(data) {
      const map = {};
      const roots = [];

      data.forEach((item) => {
        if (!map[item.FdrID]) {
          map[item.FdrID] = {
            id: item.FdrID,
            name: item.FdrNameFolder,
            children: [],
            type: 'folder',
            checked: false, // Estado de la carpeta
          };
        }

        if (item.FdlID) {
          const file = {
            id: item.FdlID,
            name: item.FdlNameArchive,
            parentId: item.FdrID,
            type: 'file',
           // checked: false, // Estado del archivo
           checked: item.FdpID > 0,
          };
          map[item.FdrID].children.push(file);
        }
      });

      Object.values(map).forEach((folder) => {
        if (folder.children.length > 0) {
          roots.push(folder);
        }
      });

      return roots;
    },

    // Manejar el cambio de selección de una carpeta
    handleFolderChange(folder) {
      if (folder.checked) {
        folder.children.forEach((file) => {
          file.checked = true;
        });
      } else {
        folder.children.forEach((file) => {
          file.checked = false;
        });
      }
      this.updateFoldersString();
    },

    // Manejar el cambio de selección de un archivo
    handleFileChange(file) {
      const folder = this.treeItems.find(item => item.id === file.parentId);
      if (folder) {
        // Verificamos si todos los archivos de la carpeta están seleccionados
        folder.checked = folder.children.every(child => child.checked);
      }
      this.updateFoldersString();
    },

    // Actualizar el string con los FdlID de los archivos seleccionados
    updateFoldersString() {
      const selectedFiles = [];
      const unselectedFiles = [];
      this.treeItems.forEach((folder) => {
        folder.children.forEach((file) => {
          if (file.checked) {
            selectedFiles.push(file.id);
          }else{
            unselectedFiles.push(file.id);
          }
        });
      });
      this.foldersString = selectedFiles.join(",");
      this.unselectedFoldersString = unselectedFiles.join(",");
    },

    // Método para comprobar si la carpeta está parcialmente seleccionada
    isIndeterminate(folder) {
      return folder.children.some(child => child.checked) && !folder.checked;
    },

    // Guardar los permisos de la carpeta seleccionada
    saveSharePermit() {
     /* if (!this.foldersString) {
        this.$fun.alert("Por favor seleccione al menos un archivo.", "warning");
        return;
      }*/
      //const foldersString = this.selectedFolders.join(",");
      let obj = {
        FdpUserID: this.userID,
        UsrCreateID: this.$fun.getUserID(),
        SecStatus:1,
        tFdlID: this.foldersString,
        fFdlID: this.unselectedFoldersString
      };
       console.log("this",obj);
      _sFolderService
        .SaveDetailPermissions(obj, this.$fun.getUserID())
        .then((resp) => {

        });
      this.arbolVisible=false;
      this.$fun.alert("Permisos agregados al usuario ", "success");
      console.log("Compartir permisos no seleccionados: " + this.unselectedFoldersString, "success");
      // Aquí puedes hacer la llamada al servicio para guardar los permisos
      // _sFolderService.saveSharePermissions(this.foldersString)
    },

    close() {
      this.$emit('close');
    },
  },
  props: {
    selectedFolders: {
      type: Array,
      required: true,
    },
    userID: {
      type: Number,
      required: true,
    },
  },
  watch: {
    userID(newValue) {
      this.userID=newValue;
      this.arbolVisible=true;
      this.loadTreeViewData();
      console.log('userID cambiado:', newValue);
    },
  }
};
</script>
<style scoped>

/* Establecer un max-height del 50% de la pantalla y hacer que sea scrollable */
.tree-container {
  max-height: 50vh; /* 50% de la altura de la ventana */
  overflow-y: auto; /* Hacer el contenedor scrollable */
}


</style>

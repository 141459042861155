import Service from "../../Service";
const resource = "humanresource/HmnHoliday/";

export default {
  save(cmp, requestID) {
    return Service.post(resource + "save", cmp, {
      params: { requestID: requestID },
    });
  },
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },
  list(requestID) {
    return Service.post(
      resource + "list",
      {},
      {
        params: { requestID: requestID },
      }
    );
  },
};

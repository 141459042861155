<template>
	<div>
		<s-crud
			title="GESTOR POR GRUPO ARTICULO"
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
			ref="gridGestorGroup"      
            @rowSelected="rowSelected($event)" 
			searchInput
		>
			<template v-slot:filter>
				<!--<v-container>
                    <v-col lg="3" class="s-col-text">
                    <s-articles-items  clearable label="Rubro de Articulos"  v-model="filter.GpArt" ></s-articles-items>
                    </v-col>

				</v-container>-->
			</template>

			<template scope="props">
				<v-container>
					<v-row justify="center">
						<v-col cols="4" class="s-col-form">
							<s-articles-items   label="Rubro de Articulos"  clearable v-model="props.item.GpArt"></s-articles-items>
						</v-col>
                        <v-col cols="6" class="s-col-form">
							<s-select 
								label="Responsable"
								:items="workerstic"
								autocomplete
								clearable
								item-text="WorkerName"
								item-value="NtpID"
								v-model="props.item.NtpID"
							></s-select>
						</v-col>
                      <!-- <v-col cols="4" class="s-col-form">
                        	<s-toolbar-person   noCamera :defaultDocument="props.item.PrsDocumentNumber" @returnPerson="returnPerson($event, props)" v-model="props.item.PrsDocumentNumber" />
                        </v-col>

						<v-col cols="4" class="s-col-form">
							<s-text readonly label="Persona" v-model="props.item.WorkerName"></s-text>
							<s-text hidden v-model="props.item.NtpID"></s-text>
						</v-col>	--> 

					</v-row>
				</v-container>
			</template>

			<template v-slot:options>
			</template>

			<template v-slot:TkpStatus="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TkpStatusColor"
				>
					{{ row.TkpStatusName }}
				</v-chip>
			</template>

		</s-crud>

	</div>
</template>

<script>
	import _sCttoService from '@/services/Logistics/LgsItemsGroupArticle.js';
	import sTextarea from '@/components/Utils/sTextarea.vue';
	import _sTaskService from '@/services/InformationTechnology/TaskService.js';
    import SArticlesItems from "@/components/Utils/Logistics/SArticlesItems.vue";
   // import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import { isUndefined } from 'lodash';

	export default {
  components: { sTextarea,SArticlesItems },
		data() {
			return {
				workerstic: [],
				itemSelected: null,
				filter: {
					PrsID: 0,
                    GpArt: -1,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0
				},
                
				config: {
					model: {
                        IgaID: "ID",
						Action: "Action",
					},
					service: _sCttoService,
					headers: [
						{text: "ID", value: "IgaID"},
						{text: "DNI", value: "PrsDocumentNumber"},
						{text: "Apellidos y Nombres", value: "WorkerName"},
						{text: "Grupo Articulo", value: "GpArtName"}

					]
				},
				
			}
		},
        watch: {
            'filter.ItemsCod'() {
            this.refresh();
            },
        },
		methods: {
			getTicWorkers()
			{
				let obj = {
					AreID: this.filter.AreID
				}
				_sTaskService
				.ticworkers(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						console.log('work', resp.data)
						this.workerstic = resp.data;
					}
				})
			},
           /* returnPerson(value, props) {
				console.log(value,"dsds");
				props.item.WorkerName = value.NtpFullName;
				props.item.NtpID = value.NtpID;
            },*/
			initialize()
			{
				this.getTicWorkers();
			},

			save(item) {				
				console.log("item save", item);
				if(item.SecStatus == null) item.SecStatus = 1
                  item.UsrCreateID=this.$fun.getUserID();
				_sCttoService.save(item,this.$fun.getUserID()).then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Asignado Correctamente" , "info"); 
						this.$refs.gridGestorGroup.refresh();
					}
				});

			},
            rowSelected(item)
			{
				if(item.length > 0)
				{
					this.itemSelected = item[0];
					//console.log("w",this.itemSelected)
					//this.item.NtpFullName = this.itemSelected.WorkerName;
				}
			}
		},

		created () {
			this.filter.AreID =  this.$fun.getUserInfo().CtrContract.AreID ?? 0;
			
			this.initialize();
		},
		mounted() {
			
			this.filter.PrsID =  this.$fun.getUserInfo().PrsID; 
					
			console.log( "USER INFO",this.$fun.getUserInfo()); 
		}
	}
</script>

<style>
	.scrollable {
		max-height: 450px; /* Ajusta según sea necesario */
		overflow-y: auto;  /* Habilita el scroll vertical */
	}
</style>
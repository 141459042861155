<template>
	<v-container>
		<div>
			<!-- :single-row="false" -->
			<s-crud
				:single-row="false"
				@rowSelected="rowSelected($event)"
				:filter="filter"
				:config="config"
				search-input
				add 
				@add="send()"
				no-full
				height="auto"
				title="Generación Pre-Packing"
				addNoFunction
                ref="crudprepacking"
				:rowDefault="false"
				@getFullData="getFullData($event)"
			>
				<template v-slot:filter>
					<v-container>
						
						<v-row style="margin-left: 5px" justify="center">
							<v-col cols="6" md="3" lg="3">
								<s-select-customer clearable label="Cliente" v-model="filter.UwoCustomerID"></s-select-customer>
							</v-col>
							<v-col cols="6" md="3" lg="3">
								<s-select-definition
									label="Destino"
									:def="1181"
									v-model="filter.TypeDestiny"
								></s-select-definition>
							</v-col>
							<!-- <v-col cols="6" md="3" lg="3">
								<s-select-definition
									label="Calibre"
									:def="1266"
									v-model="filter.TypeCaliber"
								></s-select-definition>
							</v-col>
							<v-col cols="6" md="3" lg="3">
								<s-select-variety
									clearable
									:cultiveID="TypeCultive"
									label="Variedad"
									full
									v-model="filter.VrtID"
								/>
							</v-col> -->
							<v-col cols="6" md="3" lg="3">
								<s-date
									label="Fecha Inicio"
									v-model="filter.cDateInitial"
								></s-date>
							</v-col>
							<v-col cols="6" md="3" lg="3">
								<s-date
									label="Fecha Fin"
									v-model="filter.cDateFin"
								></s-date>
							</v-col>
						</v-row>
						<v-row style="margin-left: 5px">
							<v-col cols="6" md="3" lg="3">
								<s-select-definition
									label="Planta Procesadora"
									:def="1230"
									v-model="filter.TypePlantProcessing"
								></s-select-definition>
							</v-col>
						</v-row>
					</v-container>
				</template>

				<template v-slot:PltCorrelative="{ row }">
					<v-chip color="info" x-small>
						{{ row.PltCorrelative }}
					</v-chip>
				</template>

				<template v-slot:PppNumberShipment="{ row }">
					<v-chip color="error" x-small>
						{{ row.PppNumberShipment }}
					</v-chip>
				</template>

				<template v-slot:TypeQualityPalletName={row}>
					<v-chip
						x-small
						dark
						:color="row.TypeQualityPalletName =='Process' ? 'error' : 'light-blue darken-2'">

						{{  row.TypeQualityPalletName}}

					</v-chip>
				</template>
			</s-crud>
		</div>

		<v-dialog persistent v-model="openModal" v-if="openModal" width="700">
			<v-card>
				<v-col>
					<v-row>
						<s-toolbar
							save
							@save="save({})"
							:color="'#BAB6B5'"
							dark
							label=""
							close
							@close="close()"
						/>
					</v-row>

					<v-row style="margin-left: 5px" justify="center">
						<v-col cols="12" md="6" lg="6">
							<s-text
								label="N° Orden de Venta"
								v-model="item.OrdID"
								ref="OrdID"
								number
							></s-text>
						</v-col>
						<v-col cols="12" md="6" lg="6">
							<s-text
								label="N° Pre Packing"
								v-model="item.PckPrePackingNumber"
								ref="PckPrePackingNumber"
								number
							></s-text>
						</v-col>
					</v-row>
				</v-col>
			</v-card>
		</v-dialog>

	</v-container>
</template>

<script>
	//Service
	import _sPackingShipment from "../../../services/FreshProduction/PackingShipmentService";
	import sSelectCustomer from "../../../components/Sale/SselectClientSap.vue";
	import SSelectVariety from '../../../components/HarvestSchedule/SSelectVariety.vue';
import _sVarietyService from '@/services/Technicalassistance/VarietyService';
	

	export default {
		components: { sSelectCustomer, SSelectVariety },

		data: () => ({
			openModal: false,
			singlerow: true,
			TypeCultive: 0,
			itemsSelected: [],
			filter: {
				cDateInitial: "",
				cDateFin: "",
				TypePlantProcessing : 1,
				 
				TypeDestiny: 1
			},
			config: {
				service: _sPackingShipment,
				model: {
					PltID: "ID",
					PltDate: "datetime",
					PppNumberShipment: "",
					PltCorrelative: "",
					TypeQualityPalletName: "",
				},
				headers: [
					{ text: "Pre Packing", value: "PppNumberShipment", width: 100 },
					{ text: "Nro. Pallet", value: "PltCorrelative", width: 100 },
					/* {
						text: "Producto Terminado",
						value: "UwoMaterialListID",
						width: 140
					}, */
					{ text: "Pedido", value: "OrdID", width: 100 },
					/* { text: "Fundo/Usuario", value: "FagName", width: 340 }, */
					{ text: "Cliente.", value: "UwoCustomerName", width: 240 },
					{ text: "Destino", value: "TypeDestinyName", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName"},
					{ text: "Variedad", value: "VrtDescription" },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 140 },
					{
						text: "Cantidad",
						value: "UwoMaterialListNumberBoxes",
						width: 140
					},
					{ text: "FPB", value: "PldQuantityBoxeName", width: 140 },
					{ text: "Calibre", value: "TypeCaliberName", width: 140 },
					
					//{ text: "Marca Caja", value: "TypeCropName", width: 140 },
					
					
					/* { text: "Peso", value: "PltWeightNet", width: 140 }, */
					{ text: "Fecha", value: "PltDate", width: 140 },
					{text:"calidad palet", value:"TypeQualityPalletName", width: 140 },
				]
			},

			item: {},
		}),

		watch: {
		},

		methods: {

			Initialize()
			{
				console.log("variety ", this.itemsSelected[0].VrtID);
				//_sVarietyService
			},

			getFullData(items)
			{
				if(items.data.length > 0)
				{
					console.log('resss ', items.data);
					/* _sVarietyService
					.list(items.data[0].VrtID, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							console.log('resp ',resp.data);
						}
					}) */
				}
			},

			rowSelected(item) {

				this.itemsSelected = item;
				console.log('x:',this.itemsSelected);

			},

			close() {
				this.openModal = false;
				
			},

			send() {

				if(this.itemsSelected.length == 0){
					this.$fun.alert("Error, no se han seleccionado registros", "warning")
					return;
				}

				this.item =  {}
				this.item.OrdID = this.itemsSelected[0].OrdID;
				this.openModal = true;
				

				
			},

			save(aaaa) {

				if(this.itemsSelected.length == 0) {
					this.$fun.alert("Error, no se han seleccionado registros", "warning")
					return;
				}

				if(this.item.OrdID.length  == 0){
					//this.$fun.alert("Ingrese Orden de Venta", "warning")
					this.$refs.OrdID.error("Ingrese Orden de Venta")
					return;
				}

				if(this.item.PckPrePackingNumber.length  == 0){
					//this.$fun.alert("Ingrese N° de Embarque", "warning")
					this.$refs.PckPrePackingNumber.error("Ingrese N° de Pre Packing")
					return;
				}


                this.item.UsrCreateID = this.$fun.getUserID()
				this.item.UwoCustomerID = this.itemsSelected[0].UwoCustomerID
				this.item.UwoCustomerName = this.itemsSelected[0].UwoCustomerName
				this.item.TypeDestiny = this.filter.TypeDestiny
				

				var sumBoxe = 0
				var exist = false

                this.itemsSelected.forEach((element, index) => {
                    console.log("element",element);

					if(this.item.UwoCustomerID !== element.UwoCustomerID){
						this.$fun.alert("Error, No se puede generar Pre-Packing con Clientes diferentes. Por favor verifique", "warning")
						exist = true
						
					}

                    element.OrdID = this.item.OrdID
                    element.PppNumberShipment = this.item.PckShipmentNumber
					element.PppPrePackingNumber = this.item.PckPrePackingNumber
                    element.UsrCreateID = this.$fun.getUserID()
					element.PppPositionNumber = index
					sumBoxe += element.UwoMaterialListNumberBoxes

                });

				if(exist == false){

					this.item.PckQuantityBoxe = sumBoxe
					this.item.itemsDetail = this.itemsSelected

					console.log("Guardar", this.item);
                     

					this.$fun.alert("¿Seguro de generar Pre-Packing, Recuerde que se procederan a actualizar los PT seleccionados con la nueva OV ? " + this.item.OrdID, "question").then(val => {
							if(val.value){
								_sPackingShipment
									.save(this.item, this.$fun.getUserID())
									.then(resp => {
										if(resp.status == 200){
											this.$fun.alert("Pre-Packing generado correctamente", "success")
											this.openModal = false;
											this.$refs.crudprepacking.refresh();
										}
									});
							}
						})
				}
			},
		},

		created() {
			this.item =  {}
		}
	};
</script>

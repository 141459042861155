import Service from "../Service";

const resource = "producer/";

export default {
    savenaturalperson(ntp, requestID) {
      return Service.post(resource + "naturalcreate", ntp, {
        params: { usrID: requestID },
      });
    },
    savejuridicperson(jrp, requestID) {
      return Service.post(resource + "createjuridic", jrp, {
        params: { usrID: requestID },
      });
    },
  //pdf
};

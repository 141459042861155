<template>
  <div>
    <!-- <v-row>
      <v-col>
        <s-toolbar label="Registro de Pallet en Proceso" dark color="#bbbcbd" save @save="save()" close @close="closeAll()"></s-toolbar>
      </v-col>
    </v-row> -->
    <v-row>
      
      
      
      <v-col>
        <s-toolbar label="Registro de Pallet en Proceso" dark color="#bbbcbd" save @save="save()" close @close="closeAll()"></s-toolbar>
        <v-card class="pt-5 elevation-0 pb-0" elevation="0">
          <v-row>
            
            <v-col cols="12" lg="12" class="pt-0">
              <h3 class="pl-5">REGISTRO DE PALLET PARA EL PROCESO</h3>
              <v-col style="padding-top: 30px">
                <v-row style="padding-bottom: 30px; padding-top: 10px">
                  <v-col cols="12" lg="12" md="12">
                    <s-scanner-qr
                      :config="configScann"
                      :autofocus="false"
                      return-object
                      @onValue="onValue($event)"
                      @onData="onData($event)"
                      :readonly="false"
                    ></s-scanner-qr>
                  </v-col>
                </v-row>
                <v-container style="padding-top: 5px">
                  <v-row>
                    <v-col class="pl-0 pt-0">
                      <s-toolbar
                        color="#bbbcbd"
                        dark
                        label="Pallet registrados"
                      >
                      </s-toolbar>
                      <v-data-table
                        item-key="PprID"
                        dense
                        :headers="headerDetailPallet"
                        :items="itemsDetailPallet"
                        hide-default-footer
                        :items-per-page="-1"
                      >
                        <template v-slot:item.Actions="{ item }">
                          <v-btn
                            @click="removeItem(item)"
                            x-small
                            block
                            rounded
                            color="error"
                          >
                            <v-icon style="font-size: 16px !important"
                              >mdi-close</v-icon
                            >
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" lg="12" class="pt-0 pl-5">
              <h3>REGISTRAR PROCESOS</h3>
              <v-col>
                <v-row>
                  <v-col cols="12" lg="6" md="6" class="pl-0 pb-0">
                    <s-select-definition
                      label="Tipo proceso"
                      v-model="itemsDataProccess.FprTypeProcess"
                      :def="1340"
                    >
                    </s-select-definition>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" class="pl-0 pb-0">
                    <s-text
                      readonly
                      v-model="itemsDataProccess.RcfID"
                      label="Lote materia prima"
                    >
                    </s-text>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" class="pl-0 pb-0">
                    <s-text
                      readonly
                      v-model="itemsDataProccess.FprMovedRawMaterial"
                      label="Materia Prima movida (KG)"
                    >
                    </s-text>
                  </v-col>
                  <!-- <v-col cols="6" class="pl-0 pb-0">
                    <s-text
                      disabled
                      v-model="itemsDataProccess.FpdMovedRawMaterialAcu"
                      label="Materia P movida acumulada"
                    >
                    </s-text>
                  </v-col> -->
                  <v-col cols="12" lg="6" md="6" class="pl-0 pb-0">
                    <s-text
                      readonly
                      v-model="itemsDataProccess.FprProcessRawMaterial"
                      label="Materia Prima procesada (KG)"
                    >
                    </s-text>
                  </v-col>
                  <!-- <v-col cols="6" class="pl-0 pb-0">
                    <s-text
                      disabled
                      v-model="itemsDataProccess.FpdProcessRawMaterialAcu"
                      label="Materia P procesada acumulada"
                    >
                    </s-text>
                  </v-col> -->
                </v-row>
                <!-- <v-row>
                  <v-col class="pt-6 pb-3"><v-divider></v-divider></v-col>
                </v-row>
                <v-row>
                  <h4>REGISTRO DE SALDO (DESCARTE)</h4>
                  <v-spacer></v-spacer>
                  <v-btn @click="addDiscard()" rounded fab x-small color="">
                    <i class="fas fa-plus"></i>
                  </v-btn>
                </v-row>
                <v-row style="margin:auto">
                  <v-col
                    :cols="isGreenProcess == true ? 12 : 12"
                    :lg="isGreenProcess==true ? 4: 6"
                    :md="isGreenProcess==true ? 4: 6"
                    class="pl-0 pb-0"
                  >
                    <s-select-definition
                      label="Tipo de saldo"
                      ref="refTypeDiscard"
                      return-object
                      @input="inputDiscard($event)"
                      :def="1344"
                    >
                    </s-select-definition>
                  </v-col>
                  <v-col
                    :cols="isGreenProcess == true ? 12 : 12"
                    :lg="isGreenProcess==true ? 4: 6"
                    :md="isGreenProcess==true ? 4: 6"
                    class="pl-0 pb-0"
                  >
                    <s-text
                      v-model="DrpDiscardWeight"
                      decimal
                      ref="refDiscardWeight"
                      label="Peso de descarte"
                    >
                    </s-text>
                  </v-col>
                  <v-col
                    v-if="isGreenProcess"
                    :cols="isGreenProcess == true ? 12 : 12"
                    :lg="isGreenProcess==true ? 4: 6"
                    :md="isGreenProcess==true ? 4: 6"
                    class="pl-0 pb-0"
                  >
                    <s-text number ref="refAmountJaba" v-model="DrpAmountJaba" label="Cant. Jabas">
                    </s-text>
                  </v-col>
                </v-row>
                <v-container>
                  <v-row style="padding-top: 15px">
                    <v-col class="pl-0 pt-0">
                      <v-data-table
                        :headers="headerlDiscard"
                        :items="itemsDataDiscard"
                        hide-default-footer
                        :items-per-page="-1"
                        dense
                        disable-sort
                      >
                        <template v-slot:item.Actions="{ item }">
                          <v-btn
                            @click="removeItemDiscard(item)"
                            x-small
                            block
                            rounded
                            color="error"
                          >
                            <v-icon style="font-size: 16px !important"
                              >mdi-close</v-icon
                            >
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container> -->
              </v-col>
            </v-col>
            
          </v-row>
          <!-- <v-row style="padding-top: 10%">
            <v-col cols="12" justify="center" class="pt-0">
              <v-row justify="center">
                <v-col cols="12" lg="4" md="6">
                  <v-btn @click="closeAll()" color="primary" width="100%"
                    >CANCELAR</v-btn
                  >
                </v-col>
                <v-col cols="12" lg="4" md="6">
                  <v-btn @click="save()" color="success" width="100%"
                    >GRABAR</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row> -->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SSelectDefinition from "../../../components/Utils/SSelectDefinition.vue";
import ProcessRegistration from "./ProcessRegistration.vue";
import _sMaturationTunnelPalletService from "@/services/FrozenProduction/MaturationTunnelPalletService.js"; // usando
import _sProcessRegistrationService from "@/services/FrozenProduction/ProcessRegistrationService";
import _sPalletProcessService from '@/services/FrozenProduction/PalletProcessService.js';

export default {
  components: {
    ProcessRegistration,
    SSelectDefinition,
  },
  props: {
    itemsEdit: null,
    isEdit: null,
    clickSave: false
  },
  data() {
    return {
      configScann: {},
      FprID: 0,
      DrpAmountJaba: 0,
      DrpDiscardWeight: 0,
      TypeDiscardName: "",
      detailRecivied : {},
      isGreenProcess: false,
      itemsDeleteDetail : [],
      itemsDeleteDiscard : [],
      TypeDiscard: 0,
      itemsDataProccess: {},
      headerlDiscard: [
        { text: "Acciones", value: "Actions", width: "100", align: "begin", sorteable: true},
        { text: "Tipo Descarte", value: "TypeDiscardName", width: "100", align: "begin", sorteable: true},
        { text: "Peso Descarte", value: "DrpDiscardWeight", width: "100", align: "begin", sorteable: true},
        { text: "Cant. Jabas", value: "DrpAmountJaba", width: "100", align: "begin", sorteable: true},
      ],
      MainCode: 0,
      PreviousWeight : 0,
      totalWeight : 0,
      totalDiscard : 0,
      MtpID: 0,
      itemsDataDiscard: [],
      itemsDetailPallet: [],
      headerDetailPallet: [
        { text: "Acciones", value: "Actions", width: "50", align: "begin", sorteable: true},
        { text: "Codigo", value: "MtpID", width: "100", align: "begin", sorteable: true},
        { text: "Linea Proceso", value: "TypeProcessName", width: "100", align: "begin", sorteable: true},
        { text: "N° Parihuela", value: "PprID", width: "100", align: "begin", sorteable: true},
        { text: "Peso Parihuela", value: "FpdWeightPallet", width: "100", align: "begin", sorteable: true},
        { text: "Cultivo", value: "TypeCropName", width: "100", align: "begin",sorteable: true},
        { text: "Tipo cultivo", value: "TypeCultiveName", width: "100", align: "begin", sorteable: true},
        { text: "Variedad", value: "VrtName", width: "100", align: "begin",sorteable: true},
        { text: "Cant. Jabas", value: "FpdAmountJabas", width: "100",align: "begin",sorteable: true},
      ],
    };
  },

  watch: {
    clickSave() {
      if(this.clickSave)
      {
        this.save();
      }
    }
  },

  methods: {
    rowSelected(rows) {
      console.log("fila seleccionada", rows);
    },

    orderDetails() {
				let i = 0;
				this.itemsDetailPallet.map(e => {
					i++;
					e.Line = i;
				});
			},

    removeItem(item) {
      console.log("Registro a eliminar", item);
      this.itemsDetailPallet = this.itemsDetailPallet.filter(
        (x) => x.Line != item.Line
      );

      item.SecStatus = 0;
      if (this.isEdit) this.itemsDeleteDetail.push(item);

      this.calculateWeights();
    },

    removeItemDiscard (item) {
      this.itemsDataDiscard = this.itemsDataDiscard.filter(
        (x)=> x.TypeDiscard != item.TypeDiscard
      );

      item.SecStatus = 0;
      if(this.isEdit) this.itemsDeleteDiscard.push(item);

      this.calculateWeights();

      /* if (this.itemsDetailPallet.length > 0) {
					if (item.PldID > 0) {
						this.itemRemoved.push(
							this.itemsDetailPallet.filter(x => x.PldLine == item.PldLine)[0],
						);
						this.itemRemoved.forEach(element => {
							element.SecStatus = 0;
						});
					}

					this.itemsDetail = this.itemsDetail.filter(
						x => x.PldLine != item.PldLine,
					);
					this.orderDetails();
				} */

    },

    inputDiscard(item) {
      let ProcessGreen = 0;
      this.TypeDiscard = item ? item.DedValue : "";
      this.TypeDiscardName = item ? item.DedDescription : "";
      ProcessGreen = item ? item.DedValue : 0;

      if (ProcessGreen == 1) {
        this.isGreenProcess = true;
      } else {
        this.isGreenProcess = false;
      }
    },


    calculateWeights (){

      // CALCULAMOS EL PESO DE TODOS LOS PALLET
      this.totalWeight = 0;
      this.itemsDetailPallet.forEach((e)=>{
        this.totalWeight += e.FpdWeightPallet;
      })

      // CALCULAMOS EL TOTAL DEL PESO DEL DESCARTE
      this.totalDiscard = 0;
      this.itemsDataDiscard.forEach((e)=>{
        this.totalDiscard += parseInt(e.DrpDiscardWeight);
      })

      this.itemsDataProccess.FprMovedRawMaterial = this.totalWeight; 
      this.itemsDataProccess.FprProcessRawMaterial = this.totalWeight - this.totalDiscard; 

    },

    addDiscard() {

      if(this.itemsDataDiscard.find(x=>x.TypeDiscard == this.TypeDiscard)){
        this.$fun.alert("Selecciona otro tipo de saldo", "warning");
        this.$refs.refTypeDiscard.focus();
        return;
      }

      if(this.isGreenProcess && this.DrpAmountJaba <= 0 ) {
        this.$refs.refAmountJaba.error('Debe ingresar cantidad de cajas');
      } else {

        if (this.DrpDiscardWeight > 0) {

          let itemsDiscard = {};
          itemsDiscard.FprID = this.FprID ? this.FprID : 0;
          itemsDiscard.TypeDiscard = this.TypeDiscard;
          itemsDiscard.DrpAmountJaba = this.DrpAmountJaba;
          itemsDiscard.TypeDiscardName = this.TypeDiscardName;
          itemsDiscard.Line =this.itemsDataDiscard.length + 1;
          itemsDiscard.SecStatus = 1;
          itemsDiscard.UsrCreateID = this.$fun.getUserID();
          itemsDiscard.UsrUpdateID = this.$fun.getUserID();

          itemsDiscard.DrpDiscardWeight = this.DrpDiscardWeight;
          console.log('Line', itemsDiscard)
          this.itemsDataDiscard.push(itemsDiscard);
          // this.calculateWeights();

          console.log('datos agregados en detalles', this.itemsDataDiscard)

          this.itemsDataProccess.FprProcessRawMaterial = this.totalWeight - this.totalDiscard; 
          
          itemsDiscard = {};
          this.DrpAmountJaba = 0;
          this.DrpDiscardWeight = 0;

        } else {
          this.$refs.refDiscardWeight.error('Debe ingresar un peso válido');
        }
        
      }

      

      
    },

    Validate() {
      let isValidate = false;
      if (this.itemsDetailPallet.length == 0) {
        this.$fun.alert("Debe ingresar por lo menos una parihuela", "warning");
        return isValidate;
      } else {
        /* if(this.itemsDataDiscard.length == 0) {
          this.$fun.alert("Debe ingresar por lo menos un tipo de saldo", "info");
          return isValidate;
        } else { */
          isValidate = true;
          return isValidate;
        //}
      }
    },

    closeAll(){
      // alert()
      this.$emit("close");

    },

    save() { 

      if (this.Validate()) {
        
        if(this.itemsDetailPallet.length == 0){
          this.itemsDataProccess.PprID = this.itemsDetailPallet[0].PprID;
        }
        else
        {
          let PpIDRepresentative = this.itemsDetailPallet[0].PprID;
          let max = this.itemsDetailPallet[0].PprQuantityJaba;
          this.itemsDetailPallet.forEach(element => {
            if(element.PprQuantityJaba > max){
              PpIDRepresentative = element.PprID;
              max = element.PprQuantityJaba;
            }
          });
          this.itemsDataProccess.PprID = PpIDRepresentative;
        }

        this.itemsDataProccess.FprID = this.FprID ? this.FprID : 0;
        this.itemsDataProccess.UsrUpdateID = this.$fun.getUserID();
        this.itemsDataProccess.VrtID = this.itemsDetailPallet[0].VrtID;
        this.itemsDataProccess.TypeCultive =
        this.itemsDetailPallet[0].TypeCultive;
        this.itemsDataProccess.TypeCrop = this.itemsDetailPallet[0].TypeCrop;
        this.itemsDataProccess.LlpID = this.itemsDetailPallet[0].LlpID;
        this.itemsDataProccess.MtpID = this.itemsDetailPallet[0].MtpID;

        this.itemsDataProccess.DataProcessRegistrationDetail = this.itemsDetailPallet;
        this.itemsDataProccess.DataDiscardReceptionProcess = this.itemsDataDiscard;

        this.itemsDataProccess.DataProcessRegistrationDetail.push(...this.itemsDeleteDetail)
        this.itemsDataProccess.DataDiscardReceptionProcess.push(...this.itemsDeleteDiscard)

        // let generalDiscard = [...this.itemsDeleteDiscard]
        // let generalDetail = [...this.itemsDeleteDetail]

        // this.itemsDataProccess.DataProcessRegistrationDetail.push(generalDetail);
        // this.itemsDataProccess.DataDiscardReceptionProcess.push(generalDiscard);

       
        console.log("Datos para guardar", this.itemsDataProccess);

        this.$fun.alert("¿Esta seguro de guardar?", "question").then((val) => {
          if (val.value) {
            _sProcessRegistrationService
              .save(this.itemsDataProccess, this.$fun.getUserID())
              .then((r) => {
                if (r.status == 200) {
                  this.closeAll();
                  this.$fun.alert("Registrado correctamente", "success");
                  this.itemsDetailPallet = [];
                  this.$emit("endSave");
                }
              });
          }
        });
      }
    },

    addItem(item) {
      item.Line = this.itemsDetailPallet.length + 1;
    },

    onValue(val) {
      console.log("datos", val);
      if (val == "Sin datos" || isNaN(val)) {
        this.$fun.alert("El QR Leido no contiene datos", "warning");
      } else {
        let obj = {
          PprID: val
        }
        _sPalletProcessService
        .search(obj, this.$fun.getUserID())
        .then(resp => {
          if(resp.status == 200){
            this.detailRecivied = resp.data;
            console.log("🚀 ~ file: RegistData.vue ~ line 527 ~ onValue ~ this.detailRecivied", this.detailRecivied)


            if(this.detailRecivied !== null){
              
              if(this.detailRecivied.PprInProcess == 1){
                this.$fun.alert("El pallet ya fue registrado en proceso");
                return;
              }
              if(this.detailRecivied.PprDisinfection == 0){
                this.$fun.alert("El pallet aún no ha sido desinfectado");
                return;
              }

              this.addItem(resp.data);
              this.getMainData();
            }
            

            
          }
        })
      }
    },
    onData(val) {},

    getMainData() {

      let dataProcessDetail = {};
      
      dataProcessDetail.FpdID = 0;
      dataProcessDetail.PprID = this.detailRecivied.PprID;
      dataProcessDetail.FpdWeightPallet = this.detailRecivied.PprWeightGross;
      dataProcessDetail.FpdAmountJabas = this.detailRecivied.PprQuantityJaba;
      dataProcessDetail.TypeSelectionFrozen = this.detailRecivied.TypeSelectionFrozen;
      dataProcessDetail.TypeFrozenCategory = this.detailRecivied.TypeFrozenCategory;
      dataProcessDetail.TypeCultive = this.detailRecivied.TypeCultive;
      dataProcessDetail.TypeCrop = this.detailRecivied.TypeCrop;
      dataProcessDetail.VrtID = this.detailRecivied.VrtID;
      dataProcessDetail.FpdStatus = 1;
      dataProcessDetail.TypeCultiveName = this.detailRecivied.TypeCultiveName;
      dataProcessDetail.TypeCropName = this.detailRecivied.TypeCropName;
      dataProcessDetail.VrtName = this.detailRecivied.VrtName;
      dataProcessDetail.SecStatus = 1;
      dataProcessDetail.Line = this.itemsDetailPallet.length + 1;
      dataProcessDetail.UsrCreateID = this.$fun.getUserID();
      dataProcessDetail.UsrUpdateID = this.$fun.getUserID();
      dataProcessDetail.TypeProcessName = this.detailRecivied.TypeProcessName;
      
      let exist = false;
      this.itemsDetailPallet.forEach(element => {
        if(element.PprID == this.detailRecivied.PprID){
          exist = true;
        }
      });

      if(exist){
        this.$fun.alert("El pallet ya fue ingresado", "warning");
        return;
      }

      this.itemsDetailPallet.push(dataProcessDetail);
      this.calculateWeights();

      if(this.detailRecivied.MtpWeight > this.PreviousWeight){
        this.PreviousWeight =  this.detailRecivied.PprWeightGross;
      }
            
    },

    getRegistProcess() {
      let obj = {
        FprID: this.itemsEdit.FprID,
      };

      _sProcessRegistrationService
        .getDetails(obj, this.$fun.getUserID())
        .then((r) => {
          if(r.data.DataProcessRegistrationDetail != null){
            this.FprID = r.data.FprID;
            console.log(r)
            this.itemsDataProccess = r.data;
            this.itemsDetailPallet = r.data.DataProcessRegistrationDetail;
            this.itemsDataDiscard = r.data.DataDiscardReceptionProcess;

            this.isEdit = true;

          } else {

            this.FprID = 0;
            this.itemsDataProccess = {};
            this.itemsDetailPallet = [];
            this.itemsDataDiscard = [];

          }

        });
    },

  },

  created() {
   
    if(this.isEdit){
      this.getRegistProcess();
    }
  },

};
</script>
<template>
  <div>
    <v-row>
      <v-col lg="12">
        <!-- <tunnel-maduration
          :isAdmin="true"
          @rowSelected="rowSelected($event)"
          :height="100">
        </tunnel-maduration> -->
        <process-registration  @rowSelected="rowSelected($event)">
        </process-registration>
        <!-- <process-registration-old @rowSelected="rowSelected($event)"></process-registration-old> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProcessRegistration from "./ProcessRegistration.vue";
import ProcessRegistrationOld from "./ProcessRegistrationOld.vue";

export default {
  components: {
    ProcessRegistration,
    ProcessRegistrationOld
  },
  data() {
    return {
    };
  },
  methods: {
    rowSelected(rows) {
      console.log('fila seleccionada', rows);
    },
  },
};
</script>

import Service from "../Service";

const resource = "Requirement/";

export default {
    goodservicelist(requestID) {
        return Service.post(resource + "goodservicelist", {}, {
            params: { requestID: requestID },
        });
    },

    validatequantitybypurchase(parameters, requestID) {
        return Service.post(resource + "validatequantitybypurchase", parameters, {
            params: { requestID: requestID },
        });
    },

    requirementpurchasebyuser(parameters, requestID) {
        return Service.post(resource + "requirementpurchasebyuser", parameters, {
            params: { requestID: requestID },
        });
    },

    save(cmp, requestID, AccID) {
        if (!AccID)
            return Service.post(resource + "save", cmp, {
                params: { requestID: requestID },
            });
        else return Service.post(resource + "saveT", cmp, {
            params: { requestID: requestID, AccID: AccID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: { requestID: requestID },
        });
    },

    AccountConceptService(conName, requestID) {
        return Service.post(
            resource + "account", {}, {
                params: { ConName: conName, requestID: requestID },
            }
        );
    },
    ConceptService(requestID) {
        return Service.post(
            resource + "concept", {}, {
                params: { requestID: requestID },
            }
        );
    },
    excel(dtr, requestID) {
        console.log(dtr);
        return Service.post(resource + "excel", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
    pdf(dtr, requestID) {
        console.log("Registro", dtr);
        return Service.post(resource + "pdf", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
    quote(dtr, requestID) {
        console.log("valores seleccionados", dtr);
        return Service.post(resource + "qoutepdf", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
    //pdf
};
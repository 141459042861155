<template>
    <v-card elevation="0">
      <s-toolbar v-if="visibleTitle" label="Datos Persona" />
        <v-card-text> 
          <v-row>
            <v-col class="s-col-form" sm="4" md="3" lg="4">
                <s-toolbar-person  :defaultDocument="item.PrsDocumentNumber" @returnPerson="returnPerson($event, item)" v-model="item.PrsDocumentNumber" />
            </v-col>
            <v-col  class="s-col-form" cols="8" sm="8" md="8" lg="8" >			          
              <s-text ref="textNtpName" label="Nombres y Apellidos Completos" v-model="item.NtpFullName" readonly />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1060" label="Planilla" v-model="item.TypePayroll"  />
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4" > 
              <s-select-area 	label="Area" v-model="item.AreID" :text="item.AreName" ></s-select-area>
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4"> 
              <s-select-area 	v-model="selectedCenterCost" :text="item.CceName" centercost :clearable="true" label="Centro de Costo"	/>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
                <!-- <s-select-definition :def="1039" label="Cargo" v-model="item.PstID" :clearable="true"/>-->
                <s-select-position 	v-model="item.PstID" :text="item.PstName" centercost :clearable="true" label="Cargo"	/>
                <!-- <s-select
                      label="Cargo"
                      :items="Position"
                      autocomplete
                      clearable
                      item-text="PstName"
                      item-value="PstID"
                      v-model="item.PstID"
                    ></s-select>-->
            </v-col> 
            <v-col class="s-col-form" sm="3" md="3" lg="4">
                <!-- <s-select-definition :def="1381" label="Cuadrilla" v-model="item.cuadrilla" />-->
                <s-select
                      label="Cuadrilla"
                      :items="quadrille"
                      autocomplete
                      clearable
                      item-text="GngName"
                      item-value="GngID"
                      v-model="item.GngID"
                    ></s-select>
                </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-business-line v-model="selectedBusinessLine"  :cceID="selectedCenterCost" :clearable="true" label="Línea de Negocio"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1030" label="Campaña" v-model="item.CmpID" />
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1482"  label="Categoria" v-model="item.typeCategory" />
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1069"  label="Tipo Contrato"  v-model="item.TypeContract" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1039"  :dgr="4" label="Tipo Trabajador"  v-model="item.TypeWorker" />
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1481"  label="Regimen laboral"  v-model="item.TypeLaborRegime" />
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1077"  label="Cat. Ocupacional"  v-model="item.TypeOccupationalCategory" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="3" md="3" lg="4" >
                <!--<s-select-definition :def="1376"  label="Profesion" v-model="item.profesion" />-->
                <s-select
                      label="Profesion"
                      :items="profession"
                      autocomplete
                      clearable
                      item-text="PrnDescription"
                      item-value="PrnID"
                      v-model="item.PrnID"
                    ></s-select>
                </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4" >
                <!-- <s-select-definition :def="1480"  label="Nivel Educativo" :clearable="true" v-model="item.TypeEducationLevel" /> -->
                <s-select
                  label="Nivel Educativo"
                  :items="EducationLevels"
                  autocomplete
                  
                  item-text="LveDescription"
                  item-value="LveID"
                  v-model="item.LveID"
                ></s-select>
            </v-col>
            <v-col class="s-col-form" sm="3" md="4">
              <s-select-definition :def="1071"  label="Estado civil"  v-model="item.TypeCivilStatus"  />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1045"  label="Situación"  v-model="item.TypeSituation" />
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1070"   label="Situación especial" v-model="item.TypeSpecialSituation"  />
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-text label="Sueldo/Jornal" number v-model="item.AgtSalary"> </s-text>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-date v-model="item.AgtAdmissionDate" label="Fecha ingreso" :clearable="true"/>
            </v-col>
            <v-col class="s-col-form pa-0 ma-0" sm="3" md="3" lg="2" >
              <v-checkbox  style="padding: 0; margin: 0;" :clearable="true" v-model="item.ceseActivo" label="Registrar Cese" />
            </v-col>
            <v-col class="s-col-form pa-0 ma-0"   xs="4" sm="4" md="4" v-if="this.$fun.getSecurity().IsLevelAdmin" lg="2">
              <s-switch label="Jornada Maxima" v-model="item.AgtMaximunDay"></s-switch>
            </v-col> -->
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-text label="N° de hijos" number v-model="item.AgtChildrenQuantity" :min="0" > </s-text>
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1059"   label="Situación especial" v-model="item.estadoTrabadr" />
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1278"   label="Turno"  v-model="item.TypeTurno" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-date v-model="item.AgtAdmissionDate" label="Fecha ingreso" :clearable="true"/>
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-date v-model="item.AgtBeginDate" label="Inicio contrato" :clearable="true"/>
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-date v-model="item.AgtEndDate" label="Fin contrato" :clearable="true"/>
            </v-col>
            <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition  :def="1051"   label="Motivo cese" :clearable="true" v-model="item.TypeTermination"  :disabled="!item.ceseActivo" />
            </v-col> -->
            <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1059"   label="Situación especial" v-model="item.estadoTrabadr" />
            </v-col> -->
          </v-row>
          <v-row>
            <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-date v-model="item.AgtEndDate" label="Fin contrato" :clearable="true"/>
            </v-col> -->
            <v-col class="s-col-form " sm="3" md="3" lg="2" >
              <v-checkbox  style="padding: 0; margin: 0;" :clearable="true" v-model="item.ceseActivo" label="Registrar Cese" />
            </v-col>
            <v-col class="s-col-form "   xs="4" sm="4" md="4" v-if="this.$fun.getSecurity().IsLevelAdmin" lg="2">
              <s-switch label="Jornada Máxima" v-model="item.AgtMaximunDay"></s-switch>
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4" v-if="item.ceseActivo">
              <s-select-definition  :def="1051"   label="Motivo cese" :clearable="true" v-model="item.TypeTermination"  :disabled="!item.ceseActivo" />
            </v-col>
            <v-col class="s-col-form" sm="3" md="3" lg="4" v-if="item.ceseActivo">
              <s-date v-model="item.AgtTerminationDate" label="Fecha cese"  :disabled="!item.ceseActivo" />
            </v-col>
            <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1278"   label="Turno" :clearable="true" v-model="item.TypeTurno" />
            </v-col> -->
          </v-row>
        </v-card-text>
      <!--<v-card-actions>
        <v-container>
          <v-row>
            <v-col class="s-col-form"><v-btn block text @click="close()">Cancelar</v-btn></v-col>
            <v-col class="s-col-form"><v-btn block color="primary" @click="save()">GUARDAR</v-btn></v-col>
          </v-row>
        </v-container>
      </v-card-actions>-->
    </v-card>
  </template>
  
<script>
  import SSelectArea from '../../../components/Utils/SSelectArea.vue';
  import _sQuadriService from '@/services/HumanResource/hmnGang.js';
  import _sProfService from '@/services/HumanResource/ProfessionsGen.js';
  import SSelectBusinessLine from "../../../components/Utils/SSelectBusinessLine.vue";
  import _sCttoService from '@/services/HumanResource/HmnContractService.js';
  import sToolbarPerson from "@/components/Utils/RecursosHm/sToolbarAgreementPerson";
  import sEducationLevel from "@/services/HumanResource/HmnEducationalLevel.js";
  import SText from "../SText.vue";
  import SSelectPosition from '../../../components/Utils/sSelectPosition.vue';
  export default {
    name: "sDatosLaborals",
    components: { 
      SText,
		  SSelectArea,
			SSelectBusinessLine,
      sToolbarPerson,
      SSelectPosition 
    },
    props: {
      visibleTitle: {
        type: Boolean,
        default: true,
      },
      full: {
        type: Boolean,
        default: false,
      },
      typeperson: {
        type: Number,
        default: 1,
      },
      addTypeSex: {
        type: Boolean,
        default: false,
      },
      addBirthDate: {
        type: Boolean,
        default: false,
      },
      addDriver: {
        type: Boolean,
        default: false,
      },
      datawork: { type: Object },
    },
    data() {
      return {
        dataWor:{},
        profession: [],
        EducationLevels: [],
        quadrille: [],
        Position:[],
        editing: false,
        item: {
          TypePayroll:  1,
          AreID: null,
          AreName:null,
          CceName:null,
          PstID: null,
          CmpID: 1,
          BslID: null,
          TypeWorker: 4,
          PrnID: null,
          GngID:null,
          TypeSituation: 2,
          AgtAdmissionDate: null,
          AgtBeginDate: null,
          AgtEndDate: null,
          LveID:null,
          TypeSpecialSituation:3,
          TypeTermination:null,
          TypeCivilStatus:2,
          AgtTerminationDate:null,
          TypeLaborRegime:1,
          ceseActivo: false,
          centercost:0,
          typeCategory:1,
          TypeContract:11,
          TypeTurno:1,
          TypeOccupationalCategory:4,
          AgtSalary:0,
          AgtChildrenQuantity:0,
          estadoTrabadr:null,
          PrsDocumentNumber:null,
        },
        selectedCenterCost: 0,      
        selectedBusinessLine: null,       
        menu3: false,
        config: {},
        dialog: false,
      };
    },
    methods: {
      initialize(){
        this.getProfession();
        this.getQuadrille();
        this.getEducationLevels();
        //this.getPositiom();
        console.log("dataWork",this.datawork);
        this.TypeEducationLevel = 1;
        // if(this.datawork && typeof this.datawork === 'object'){
        //   console.log("datwor", this.dataWor.AgreementCE.TypeCivilStatus);
        //   this.item.TypeCivilStatus = this.dataWor.AgreementCE.TypeCivilStatus || null;
        //   this.item.AgtBeginDate = this.dataWor.AgreementCE.AgtBeginDate || null;
        //   this.item.AgtEndDate = this.dataWor.AgreementCE.AgtEndDate || null;
        //   this.item.AgtChildrenQuantity = this.dataWor.AgreementCE.AgtChildrenQuantity || 0;
        //   this.item.AgtSalary =  0;
        //   this.item.TypeContract =  this.dataWor.AgreementCE.TypeContract || null;
        //   this.item.TypeEducationLevel =  this.dataWor.AgreementCE.TypeEducationLevel || null;
        //   this.item.TypeOccupationalCategory =  this.dataWor.AgreementCE.TypeOccupationalCategory || null;
        //   this.item.AgtAdmissionDate =  this.dataWor.AgreementCE.AgtAdmissionDate || null;
        //   this.item.AreID =  this.dataWor.AgreementCE.AreID || null;
        //   this.item.AreName= this.dataWor.AgreementCE.AreName || null;
        //   this.item.TypeSpecialSituation =  this.dataWor.AgreementCE.TypeSpecialSituation || null;
        //   this.item.TypeSituation =  this.dataWor.AgreementCE.TypeSituation || null;
        //   this.item.TypeTurno =  this.dataWor.AgreementCE.TypeTurno || null;
        //   this.item.TypeWorker =  this.dataWor.AgreementCE.TypeWorker || null;
        //   this.item.TypeTermination =  this.dataWor.AgreementCE.TypeTermination || null;
        //   this.item.AgtTerminationDate =  this.dataWor.AgreementCE.AgtTerminationDate || null;
        //   this.item.GngID =  this.dataWor.AgreementCE.GagID || null;
        //   this.item.CmpID =  this.dataWor.AgreementCE.CmpID || null;
        //   this.item.selectedCenterCost =  this.dataWor.AgreementCE.CceID || null;
        //   this.item.CceName =  this.dataWor.AgreementCE.CceName || null;
        //   this.item.PstID =  this.dataWor.AgreementCE.PstID || null; // cargo es tabla no definicion 
        //   this.item.TypeLaborRegime=this.dataWor.AgreementCE.TypeLaborRegime || null;
        //   this.item.PrnID=this.dataWor.AgreementCE.PrnID || null;
        //   this.item.TypePayroll=this.dataWor.AgreementCE.TypePayroll || null;
        //   this.item.typeCategory=this.dataWor.AgreementCE.typeCategory || null;
        // }
			},

      validateData(){
        let valid = true;
        console.log("Data Laboral",this.item);

        if(this.item.NtpID==null ){
          this.$fun.alert("Por favor seleccionar una Persona","warning");
          valid = false;  
        }

        if(this.item.AreID==null ){
          this.$fun.alert("El campo Area es obligatorio","warning");
          valid = false;  
        }

        if(this.selectedCenterCost == null ){
          this.$fun.alert("El campo Centro de Costo es obligatorio","warning");
          valid = false;  
        }
        
        if(this.selectedBusinessLine==null ){
          this.$fun.alert("El campo Linea de Negocio es obligatorio","warning");
          valid = false;  
        }

        if(this.item.PstID==null ){
          this.$fun.alert("El campo Cargo es obligatorio","warning");
          valid = false;  
        }


        if(this.item.LveID == null ){
          this.$fun.alert("El campo Nivel Educativo es obligatorio","warning");
          valid = false;  
        }

        if(this.item.AgtAdmissionDate == null ){
          this.$fun.alert("Fecha inválida del campo Fecha de Ingreso ","warning");
          valid = false;  
        }

        if(this.item.AgtBeginDate == null ){
          this.$fun.alert("Fecha inválida del campo Incio de Contrato ","warning");
          valid = false;  
        }

        if(this.item.AgtEndDate == null ){
          this.$fun.alert("Fecha inválida del campo Fin de Contrato ","warning");
          valid = false;  
        }

        return valid;
      },

      clear() {
      
      },
       
      close() {
        this.$refs.document.focus();
        this.$emit("toclose", 2);
        this.clear();
      },
      save(){

      },
      getProfession(){
        let obj = {
					prnStatus: 1
				}
				_sProfService
				.list(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						console.log('tag', resp.data)
						this.profession = resp.data;
					}
				})
			},
      getEducationLevels(){
        sEducationLevel.list(this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){
						console.log('tag', resp.data)
						this.EducationLevels = resp.data;
            this.TypeEducationLevel = 1;
					}
				})
      },
      getQuadrille(){
        let obj = {
					SecStatus: 1
				}
				_sQuadriService
				.list(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						console.log('tag', resp.data)
						this.quadrille = resp.data;
					}
				})
      },
      getPositiom(){
        let obj ={AreID:null, TypePosition:1 }
        _sCttoService
        .Positionlist(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						console.log('tag', resp.data)
						this.Position = resp.data;
					}
				})
      },
      getData() {
        if(this.validateData()){
          return {
            //AgtID : null , //this.AgtID,
            AgtChildrenQuantity: this.item.AgtChildrenQuantity,// Num Hijos
            AgtBeginDate: this.item.AgtBeginDate,
            AgtEndDate: this.item.AgtEndDate,
            AgtTerminationDate: this.item.ceseActivo ? this.item.AgtTerminationDate : null,
            AgtMaximunDay: this.item.AgtMaximunDay,
            AgtSalary: this.item.AgtSalary,
            AgtAdmissionDate: this.item.AgtAdmissionDate,
            AgtStatus: 1,
            //WkrID:null,
            TypePayroll: this.item.TypePayroll,
            AreID: this.item.AreID,
            CceID: this.selectedCenterCost,
            TypeCategory: this.item.typeCategory,
            CmpID: this.item.CmpID,
            GngID: this.item.GngID,
            BslID: this.selectedBusinessLine,
            TypeSituation: this.item.TypeSituation,
            TypeSpecialSituation: this.item.TypeSpecialSituation,
            TypeCivilStatus: this.item.TypeCivilStatus,
            TypeWorker: this.item.TypeWorker,
            TypeLaborRegime: this.item.TypeLaborRegime,
            TypeOccupationalCategory: this.item.TypeOccupationalCategory,
            LveID: this.item.LveID,
            PstID: this.item.PstID,
            PrnID:this.item.PrnID,
            TypeContract: this.item.TypeContract,          
            TypeTermination: this.item.ceseActivo ?  this.item.TypeTermination: null,
            TypeTurno:this.item.TypeTurno,
            AgtRenovation : 0,// Renovacion
            RqpID:0,//requerimiento de Personal
            //ceseActivo: this.item.ceseActivo,
            //AmsID:null,//Movimiento de Contrato
            SecStatus:1,
            UsrCreateID: this.$fun.getUserID(),
            UsrUpdateID: this.$fun.getUserID(),
            
            //Persona de Contrato
            NtpID: this.item.NtpID,
          };
        }
        else{
          return null;
        }
      }, 
      returnPerson(value, item) {
        console.log("Value",value);
        if(value!=null){
          item.NtpFullName = value.NtpFullName;
          item.NtpID = value.NtpID;
        }else{
          item.NtpFullName = null;
          item.NtpID = null;
        }
      },
    },
    watch: {
     
     
    },
  created() {
  if (this.datawork && typeof this.datawork === 'object') {
    this.dataWor = Object.assign({}, this.datawork);
  }
    this.initialize();
  },
  };
  </script>
  
<template>
    <div class="d-flex align-center flex-column">
        <v-card>
            <s-toolbar
                label="Impresion de Guia"
                dark
                :color="'#BAB6B5'"
                close
                @close="$emit('close')"
                class="mb-6"
            ></s-toolbar>
            <v-card-title style="font-size: small;">
				<v-row>
					<!-- <v-col>
						{{ "EVOLUCIÓN DE ETAPA FENOLOGICA" }}	
					</v-col> -->
					<!-- @click="print()" -->
					<v-col align="right">
						<v-btn 
							fab 	
							x-small
							color="info"
							@click="downloadPdf()"
						>	<v-icon medium>mdi-printer</v-icon>				
						</v-btn>
					</v-col>
				</v-row>	
			</v-card-title>
            
                <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="false"
                :filename="'Guia-' + this.referralGuideSelected.RegSerie + '-' + this.referralGuideSelected.RegNumber"
                :paginate-elements-by-height="1200"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px" 
                :manual-pagination="true"
				:margin="1"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">	
                <section slot="pdf-content"> 
                    <v-container style="margin:30px 0px 30px 0px;padding:0px;" class="bg-surface-variant">                    
                        <v-row style="margin: 5px 40px 5px 40px;;padding: 0px;" justify="center">
                            <v-col cols="7*" style="margin: 0px;padding: 0px;">                             
                                <img width='190px' src='../../../../public/static/logo_sp.png' 
                                     /> 
                                    <p style="margin:0px;padding:2px;font-family:Arial Black, Helvetica, sans-serif;font-size: 10px;font-weight:bold;text-align:justify;">SUNSHINE EXPORT S.A.C</p>
                                    <p style="margin:0px;padding:2px;font-family:Arial Black, Helvetica, sans-serif;font-size: 8px;font-weight:bold;text-align:justify;">CAR. TAMBOGRANDE - LAS LOMAS KM 1077 (CAR. TAMBOGRANDE - LAS LOMAS KM 1077+179M)</p>
                                    <p style="margin:0px;padding:2px;font-family:Arial Black, Helvetica, sans-serif;font-size: 8px;font-weight:bold;text-align:justify;">PIURA - PIURA - TAMBOGRANDE - Telf.: 073-368262</p>
                                    <p style="margin:0px;padding:2px;font-family:Arial, Helvetica, sans-serif;font-size: 8px;font-weight:bolder;">Car. Panamericana Sur Nro. 133 Lima - Cañete - Cerro Azul</p>
                                    <p style="margin:0px;padding:2px;font-family:Arial, Helvetica, sans-serif;font-size: 8px;font-weight:bolder;">Av. Cruz de Chalpón - Cuadra 03 Nro. S/N Cent Motupe (Calle Nueva S/N) Lambayeque - Lambayeque - Motupe</p>
                                    <p style="margin:0px;padding:3px;font-family:Arial, Helvetica, sans-serif;font-size: 8px;font-weight:bolder;"> Parcela T.15.8.22B Nro. S/N Sec. Hualtaco Piura - Piura - Tambogrande</p>
                                    <p style="margin:0px;padding:2px;font-family:Arial, Helvetica, sans-serif;font-size: 8px;font-weight:bolder;">Pro. Libertad Nro. S/N Centro Cívico (Centro Cívico) - Casma - Casma - Ancash</p>
                                    <p style="margin:0px;padding:2px;font-family:Arial, Helvetica, sans-serif;font-size: 8px;font-weight:bolder;">Av. Ramón Mujica Nro. 108 Dpto. 406 Urb. El Chipe Piura - Piura - Piura</p>
                                                                             
                            </v-col>
                            <v-col cols="auto" style="border: 2px solid black;border-radius: 10px;margin: 0px 10px 0px 0px;">                                
                                <p style="font-family:Arial Black, Helvetica, sans-serif;font-size: 16px;font-weight: bold;text-align: center;">R.U.C. N° 20329725431</p>
                                <p style="margin:0px;padding:5px;font-family:Arial Black, Helvetica, sans-serif;font-size: 12px;font-weight: bold;text-align: center;">GUÍA DE REMISIÓN </p>
                                <p style="margin:0px;padding:5px;font-family:Arial Black, Helvetica, sans-serif;font-size: 12px;font-weight: bold;text-align: center;">ELECTRÓNICA REMITENTE </p>
                                <p style="font-family:Arial Black, Helvetica, sans-serif;font-size: 12px;font-weight: bold;text-align: center;">{{this.referralGuideSelected.RegSerie }} -  {{ this.referralGuideSelected.RegNumber }}         </p>
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px 40px 5px 40px;">
                            <v-col cols="7" style="border: 2px solid black;border-radius: 10px;">                                
                                <v-row >
                                    <v-col cols="auto" style="margin: 0px;padding: 5px;">   
                                        <p style="font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;font-weight:bolder">Nombre o Razón Social:</p>                                    
                                        <p style="font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;font-weight:bolder">R.U.C:</p>                                    
                                        <p style="font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;font-weight:bolder">Dirección Punto de Partida:</p>                                    
                                        <p style="font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;font-weight:bolder">Dirección Punto de LLegada:</p>
                                    </v-col>
                                    <v-col cols="8*" style="margin: 0px;padding: 5px;">                                                  
                                        <p style="font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;font-weight:bolder">{{this.referralGuideSelected.RegBusinessNameDestination}}</p>
                                    
                                        <p style="font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;font-weight:bolder">{{this.referralGuideSelected.RegDocumentNumberDestination}}
                                        </p>                                    
                                        <p style="font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;font-weight:bolder">{{this.referralGuideSelected.RegAddressStartPoint}}
                                        </p>    
                                        <p style="margin: 0px;border:0px;font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;font-weight:bolder" >{{this.referralGuideSelected.RegAddressArrivalPoint}}</p>
                                    </v-col>                                                        
                                </v-row>
                            </v-col>
                            <v-col cols="3*" style="margin:0px 10px 0px 5px ;border: 2px solid black;border-radius: 10px;">                                
                                <v-row>
                                    <v-col>
                                        <p style="font-family:Arial , Helvetica, sans-serif;font-size: 9px;margin:0px;padding: 5px;font-weight:bolder">
                                            Fecha de Emisión:</p>                                
                                        <p style="font-family:Arial , Helvetica, sans-serif;font-size: 9px;margin:0px;padding: 5px;font-weight:bolder">
                                            Fecha de Traslado:</p>                                    
                                        <p style="font-family:Arial , Helvetica, sans-serif;font-size: 9px;margin:0px;padding: 5px;font-weight:bolder">
                                            Motivo de Traslado:</p>
                                    </v-col>                        
                                    <v-col cols="1*">
                                        <p style="font-weight:bolder;font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;">{{ moment(this.referralGuideSelected.RegDate).format('DD/MM/YYYY')}}</p>
                                    
                                        <p style="font-weight:bolder;font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;">{{moment(this.referralGuideSelected.RegDateDeliveryTransport).format('DD/MM/YYYY')}}</p>
                                    
                                        <p style="font-weight:bolder;margin: 0px;border:0px;font-family:Arial , Helvetica, sans-serif;font-size: 9px;padding: 5px;">{{ this.referralGuideSelected.TypeTransferReasonCodeName }} - {{ this.referralGuideSelected.RegOtherMotive }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col> 
                        </v-row>
                        <v-row style="margin: 0px 40px 0px 40px;">
                            <v-col cols="12" style="padding:0px;">                        
                                <table style="border: solid 2px black;border-radius: 10px;width: 99% !important;" cellspacing="0" cellpadding="0"  >
                                    <thead>
                                        <tr>              
                                            <th style="width: 550px;border-bottom: black 2px solid;"></th>
                                            <th style="width: 100px;border-bottom: black 2px solid;font-family:Arial Black, Helvetica, sans-serif;font-size: 9px;font-weight:bold;line-height:20px;">Cantidad</th>
                                            <th style="width: 100px;border-bottom: black 2px solid;font-family:Arial Black, Helvetica, sans-serif;font-size: 9px;font-weight:bold;line-height:20px;">Unidad</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in referralGuideSelected.referralGuideItems" :key="index">
                                            <td style="text-align: left;font-family:Arial black, Helvetica, sans-serif;font-size: 9px; padding: 5px; font-weight:700"><span>{{ item.RgdDescription}}</span></td>
                                            <td style="text-align: center;font-family:Arial, Helvetica, sans-serif;font-size: 9px;line-height:20px;padding: 5px;font-weight:bolder">{{ item.RgdQuantity }}</td>
                                            <td style="text-align: center;font-family:Arial, Helvetica, sans-serif;font-size: 9px;line-height:20px;padding: 5px;font-weight:bolder">{{ item.RgdUnitMeasurement}}</td>                
                                        </tr>                            
                                        <tr>
                                            <td>
                                                <p style="margin:0px;padding:5px;font-size: 10px;text-align:justify;margin-left: 3px;">
                                                    <strong style="font-family:Arial Black, Helvetica, sans-serif;">Peso Neto Carga :</strong> {{ this.referralGuideSelected.RegNetWeightCargo }} {{ this.referralGuideSelected.RegUnitMeasurementCargo }}</p>
                                                <p style="margin:0px;padding:5px;font-size: 10px;text-align:justify;margin-left: 3px;">
                                                    <strong style="font-family:Arial Black, Helvetica, sans-serif;">Peso Bruto Carga:</strong> {{this.referralGuideSelected.RegGrossWeightCargo }} {{ this.referralGuideSelected.RegUnitMeasurementCargo }}</p>
                                                <p style="padding:5px;font-size: 10px;text-align:justify;margin-left: 3px;margin-bottom: 50px;">
                                                    <strong style="font-family:Arial Black, Helvetica , sans-serif;">Observaciones:</strong> {{ this.referralGuideSelected.RegObservation }}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </v-col>
                        </v-row>
                        <v-row style="margin: 5px 40px 5px 40px;">
                            <v-col style="border: 2px solid black;border-radius: 10px;margin: 0px 5px 0px 0px;">
                                <p style="padding: 2px;font-family:Arial black, Helvetica, sans-serif;font-size: 10px;font-weight:bold;text-align:justify;">DATOS UNIDAD TRANSPORTE / CONDUCTOR</p>
                                <p style="padding: 2px;font-family:Arial, Helvetica, sans-serif;font-size: 9px;text-align:justify;font-weight:bolder">Vehículo Marca y Placa N°: {{this.referralGuideSelected.RegBrandTransport }} {{this.referralGuideSelected.RegPlateTransport}} // {{ this.referralGuideSelected.LgsReferralGuideVehicles[0]?.RgvPlate ?? "" }} </p>
                                <p style="padding: 2px;font-family:Arial, Helvetica, sans-serif;font-size: 9px;text-align:justify;font-weight:bolder">Constancia de inscripción N°: {{this.referralGuideSelected.RegConstancyInscripcionTransport}}</p>
                                <p style="padding: 2px;font-family:Arial, Helvetica, sans-serif;font-size: 9px;text-align:justify;font-weight:bolder">Chofer: {{this.referralGuideSelected.RegNamesDriver }} {{this.referralGuideSelected.RegLastNameDriver}}</p>
                                <p style="padding: 2px;font-family:Arial, Helvetica, sans-serif;font-size: 9px;text-align:justify;font-weight:bolder">Licencia de Conducir N°: {{this.referralGuideSelected.RegDriverLicenceTransport}}</p>
                            </v-col>
                            <v-col style="border: 2px solid black;border-radius: 10px;margin: 0px 5px 0px 0px;">
                                <p style="padding: 2px;font-family:Arial black, Helvetica, sans-serif;font-size: 10px;font-weight:bold;text-align:justify;">DATOS DEL TRANSPORTISTA</p>
                                <p style="padding: 2px;font-family:Arial, Helvetica, sans-serif;font-size: 9px;text-align:justify;font-weight:bolder">Nombre o Razón Social: {{ this.referralGuideSelected.RegBusinessNameTransport}}</p>
                                <p style="padding: 2px;font-family:Arial, Helvetica, sans-serif;font-size: 9px;text-align:justify;font-weight:bolder">R.U.C. N°: {{this.referralGuideSelected.RegRucTransport}}</p>
                            </v-col>                              
                        </v-row>                   
                        <v-row style="margin: 5px 40px 5px 40px;" v-if="referralGuideSelected.urlSunat != ''">
                            <a :href="referralGuideSelected.urlSunat" cols="3" >
                                <qr-code
                                    v-if="referralGuideSelected.urlSunat != ''"
                                    :text="referralGuideSelected.urlSunat"
                                    error-level="H"
                                    
                                    :size="160"
                                >
                                </qr-code
                                >
                            </a>
                            <v-col cols="6" style="font-family:Arial, Helvetica, sans-serif;font-size: 9px;text-align:justify;font-weight:bolder">
                                <span>REPRESENTACION  IMPRESA  DE  LA  GUIA  DE  REMISION</span><br>
                                <span>ELECTRONICA</span><br>
                                <span>www.docele.pe/intercambio</span><br>
                                <span>Autorizado mediante RS Nro: 034-005-0006136/SUNAT</span>
                            </v-col>
                        </v-row>
            </v-container>
                
                </section>
                </vue-html2pdf>
                
        </v-card>
    </div>
</template>
<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import _sReferral from "@/services/Logistics/LgsReferralGuideService.js";
    import qrCode from "vue-qr-generator";

    export default{ 
       
        props:{
            referralGuideSelected: {
				type: Object,
				default: null,
			},
        },
        components:{
            VueHtml2pdf,
            qrCode
        },
        data(){
            return{
                urlData: "",
            }
        },
        methods:{ 
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
            onProgress(progress) {
				this.progress = progress;
				console.log(`PDF generation progress: ${progress}%`)
			},
			startPagination() {
				console.log(`PDF has started pagination`)
			},

			hasPaginated () {
				console.log(`PDF has been paginated`)
			},

			beforeDownload ({ html2pdf, options, pdfContent }) {
				console.log(`On Before PDF Generation`)
					 html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
							const totalPages = pdf.internal.getNumberOfPages()
							for (let i = 1; i <= totalPages; i++) {
								pdf.setPage(i)
								pdf.setFontSize(10)
								pdf.setTextColor(150)
								pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
							} 
						}).save()
				},
        },
        mounted(){
            console.log('print', this.referralGuideSelected.urlSunat);
            
            // _sReferral.urlSunat(this.referralGuideSelected, this.$fun.getUserID()).then((r) => {
					
			// 		if (r.status == 200) {
			// 			console.log('ressss', r.data);
			// 			this.urlData = r.data; 
			// 		}
			// 	})
        }
    }
</script>
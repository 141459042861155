<template>
	<div>

		<v-container>
			<v-row>
				<v-col lg="12" class="pt-2 pb-1">
					<s-crud
						height="auto"
						searchInput
						title="Programaciónes Recepcionadas/Pendientes"
						:config="config"
						:filter="filterSchedule"
						no-full
                        :showGroupBy="true"
					>
						<template v-slot:filter>
							<v-container>
								<v-row justify="center" class="s-col-form">
									<v-col lg="3"
										><s-date
											label="Fecha Inicio"
											v-model="filterSchedule.HvsBeginDate"
										></s-date>
									</v-col>
									<v-col lg="3"
										><s-date
											label="Fecha Fin"
											v-model="filterSchedule.HvsEndDate"
										></s-date>
									</v-col>
									<v-col lg="3"
										><s-select-definition
											label="Cultivo"
											:def="1173"
											v-model="filterSchedule.TypeCultive"
											clearable
										/>
									</v-col>
									<v-col lg="3"
										><s-select-definition
											label="Tipo Cultivo"
											:def="1172"
											v-model="filterSchedule.TypeCrop"
											clearable
										/>
									</v-col>
								
								</v-row>
								<v-row justify="center" class="s-col-form">
									<v-col lg="4">
										<!-- <s-select-definition 
											label="Tipo certificacion"
											clearable
											v-model="filterSchedule.TypeCertification"
											:def="1159">
										</s-select-definition> -->
										<s-select-type-certification
											clearable
											label="Certificacion"
											full
											v-model="filterSchedule.TcrID"
										></s-select-type-certification>
									</v-col>
									<v-col lg="4">
										<s-select-definition
											label="Planta procesadora"
											clearable
											v-model="filterSchedule.TypeProcessingPlant"
											:def="1230">
										</s-select-definition>	
									</v-col>
									<v-col lg="4"
										><s-select-definition
											label="Destino"
											:def="1175"
											v-model="filterSchedule.TypeDestination"
											clearable
										/>
									</v-col>
								</v-row>
							</v-container>
						</template>
                        <template v-slot:HvsState="{ row }">
							<v-chip
								x-small
								:color="row.HvsState == 1 ? 'info' : 'error'"
								>{{
									row.HvsState == 1
										? "Recepcionado"
										: "Sin Recepcionar"
								}}</v-chip
							>
						</template>

					</s-crud>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
	
	import _sHarvest from "@/services/Technicalassistance/HarvestSchedule";
	import sSelectTypeCertification from "@/components/Utils/Certification/sSelectTypeCertification.vue";

	export default {
		components: {
			
			sSelectTypeCertification,
		},
		props: {},
		data: () => ({
			filterSchedule: { BeginDate: null, EndDate: null, Earning: 2 },
			config: {
				model: {
					HvsID: "ID",
					HvsDate: "date",
					HvsStatusText: "",
					HvsStatus: "",
					HvsState: "",
				},
				service: _sHarvest,
				headers: [
					{ text: "N° Prog.", value: "HvsID", width: 20 },


					{ text: "Fecha", value: "HvsDate" },
					{ text: "Cod.Senasa", value: "FagCodeSenasa", width: 100 },
					{ text: "Fundo", value: "FagName", width: 280 },
					{ text: "Lote", value: "FltName", width: 80 },
					{ text: "Productor", value: "PrdText", width: 450 },
					{ text: "Tipo Servicio", value: "TypeServiceText" },
					{ text: "Procedencia", value: "TypeOriginText", width: 150 },
					{ text: "Cultivo", value: "TypeCultiveText" },
					{ text: "Variedad", value: "VrtDescription" },
					{ text: "Tipo Cultivo", value: "TypeCropText" },
					{ text: "Cant. Jabas", value: "HvsQuantity" },
					
					{ text: "Destino", value: "TypeDestinationText", width: 100 },
					{ text: "Planta Procesadora", value: "TypeProcessingPlantText" },
					{ text: "Empacadora", value: "TypeBalerText" },
                    { text: "", value: "HvsState" },
				],
			},
		}),
		methods: {

		},
		created() {},
	};
</script>

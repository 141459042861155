<template>
	<div>
		<s-crud
			title="Roles"
			:config="config"
			add
			@save="save($event)"
			edit
			remove
		>
			<template scope="props">
				<v-container>
					<v-row>
						<v-col
							class="s-col-form"
						>
							<s-select-definition
								label="Tipo Cultivo"
								:def="1173"
								v-model="props.item.TypeCultive"
							></s-select-definition>
						</v-col>
						<v-col
							class="s-col-form"
						>
							<s-text
								label="Nombre Rol"
								v-model="props.item.CrlNameRol"
							></s-text>
						</v-col>
						<v-col
							class="s-col-form"
						>
							<s-text
								label="Objetivo mínimo"
								v-model="props.item.CrlMinimunTarget"
								decimal
							></s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col
							class="s-col-form"
						>
							<s-select-definition
								label="Uni. Medida"
								:def="1436"
								v-model="props.item.TypeUnitMeasurement"
							></s-select-definition>
						</v-col>
						<v-col
							class="s-col-form"
						>
							<s-select-definition
								label="Uni. Tiempo"
								:def="1437"
								v-model="props.item.TypeTimeMeasurement"
							></s-select-definition>
						</v-col>
						<v-col class="s-col-form">
							<s-select-definition
								label="Tipo Congelamiento"
								:def="1439"
								v-model="props.item.TypeFreezing"
							></s-select-definition>
							<!-- <s-select                                      

                                        :items="ProductionCategory"
                                        v-model="props.item.CecID"  
                                        item-value="CecID"
                                        item-text="CecDescription"
                                        label="Salida de Congelamiento"
                                                                      
                                        ref="txtCecID"
                                    >
                                    </s-select> -->
						</v-col>
						<v-col class="s-col-form">
							<s-select-definition
								label="Mano de obra"
								:def="1511"
								v-model="props.item.TypeLabour"
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:parameter="{ row }">
				<v-btn 
					x-small 
					color="success" 
					outlined
					@click="ClickSetting(row)"
				>
					<i class="fas fa-cogs"></i>
				</v-btn>
			</template>
		</s-crud>

		<v-dialog
			v-model="dialogSetting"
			v-if="dialogSetting"
		>
			<setting-general
				:rowSelect="rowSelect"
			></setting-general>
		</v-dialog>
	</div>
</template>

<script>
	import _sConditioningRoleService from "@/services/FrozenProduction/FrzConditioningRoleService.js"
	import _sProductionCategory from '../../../services/FrozenProduction/ProductionCategory.js';
	import SettingGeneral from './SettingGeneral.vue';

	export default {
		components: {
			SettingGeneral,
		},

		data() {
			return {
				dialogSetting : false,
				// ProductionCategory: [],
				rowSelect: {},
				config: {
					service: _sConditioningRoleService,
					model : {
						CrlID : "ID",
						parameter : "string",
					},
					headers : [
						{text: "ID", value:"CrlID"},
						{text: "Cultivo", value:"TypeCultiveName"},
						{text: "Rol", value:"CrlNameRol"},
						{text: "Congelamiento", value:"TypeFreezingName"},
						{text: "Mano de obra", value:"TypeLabourName"},
						{text: "Objetivo Minimo", value:"CrlMinimunTarget"},
						{text: "Unidades", value:"CrlUnit"},
						{text: "Parametros", value:"parameter"}
					]
				}
			}
		},

		methods: {
			save(item) {
				item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();
				item.save();
			},
			// inputProdCategory(typeCultive){                
            //     _sProductionCategory.list(this.$fun.getUserID()).then(r =>{
            //         if(r.status == 200)
            //         {
                        
            //             this.ProductionCategory = r.data;                    
            //         }
            //     });   
            // },
			ClickSetting(row)
			{
				this.rowSelect = row;
				this.dialogSetting = true;
			}
		},
		// created(){
        //     this.inputProdCategory(0);
        // }
	}
</script>

<style scoped>

</style>
<template>
	<v-card class="m-0 p-0">
		<s-text label="Pallet Nro: " v-model="nroPallet" hidden></s-text>

		<v-col cols="12" lg="12" md="12" style="display: contents;" >
			<template style="margin-top: 10px;">
				<s-crud
					:filter="filter"
					:config="configLot"
					add
					close
					addNoFunction
					no-full
					:title="label"
					@rowSelected="rowSelected($event)"
					@add="SupplyPallet($event)"
					@close="close()"
					ref="gridCreatePallet"
					height="200px"
					searchInput
				>
				<template v-slot:filter>
					<v-container>
						<v-row>
							<v-col cols="3">
								<s-select-definition
									v-model="filter.TypeProcessingPlant"
									:def="1230"							 									
									label="Planta Procesadora">
								</s-select-definition>
							</v-col>
							<v-col cols="3">
								<s-select-definition
									v-model="filter.TypeCultive"
									:def="1173"		
									 					 
									label="Tipo Cultive">
								</s-select-definition>
							</v-col>
							<v-col cols="3">
								<s-select-campaign-area
									label="Campaña"
									v-model="filter.CmaId"
									typeArea="2"
									:typeCultive="(TypeCultive).toString()"
								></s-select-campaign-area>
							</v-col>
							<v-col cols="3">
								<s-select-definition
									v-model="TypePalletMate"
									:def="1363"		
									 disabled					 
									label="Tipo Parihuela">
								</s-select-definition>
							</v-col>
						</v-row>
					</v-container>
				</template>
					<template v-if="TypeFrozenCategory==9" v-slot:TypeFrozenCategory="{ row }">
						<s-select-definition
							:def="1326"
							v-model="TypeFrozenCategoryNewAll"
						>
						</s-select-definition>
					</template>

					<template v-slot:LppQuantityJaba="{ row }">
						 
						<s-text
							:disabled="row.TypeCultive != TypeCultive"
							number
							v-model="row.LppQuantityJaba"
							@input="inputJaba(row.LppQuantityJaba)"
							:min="0">
						</s-text>
					</template>

					<template v-slot:AmountJabasWeighSupply="{ row }">
						<v-chip small :color="'info'">
							{{ row.AmountJabasWeighSupply }}
						</v-chip>
					</template>

					<template v-slot:AmountJabasWeighDifference="{ row }">
						<v-chip small :color="'warning'">
							{{ row.AmountJabasWeighDifference }}
						</v-chip>
					</template>

					<template v-slot:AmountJabasWeighSupplyPaLDifference="{ row }">
						 
						<v-chip small :color="'success'">
							{{
								(row.AmountJabasSupplyI - row.AmountJabasWeighSupplyParihuela)
							}}
						</v-chip>
					</template>
 
					<template v-slot:KilosToReduce="{ row }">
						<s-text
						:disabled="row.TypeCultive != TypeCultive"
						 decimal
						 v-model="row.KilosToReduce"
						 @input="inputWeight(row.KilosToReduce)">
						</s-text>
					</template>

					<template v-slot:TypeAlertName="{ row }">
						<v-chip x-small :color="row.TypeAlertColor">
							{{
								row.TypeAlertName
							}}
						</v-chip>
					</template>

					<!-- <template v-slot:weightAverage="{ row }">
						{{ AverageWeightPallet }}
					</template> -->

					<template v-slot:WeightSupplyCalibrated="{ row }">
						<v-chip small color="info">
							{{
								row.WeightSupplyCalibrated
							}}
						</v-chip>
					</template>

					<template v-slot:WeightSupplyEarning="{ row }">
						 
						<v-chip v-if="filter.IsDiscart == 1" small color="error">
							<!-- {{
								(row.WbbNetWeight - row.WeightPalletAll).toFixed(2)
							}} -->
							<!-- {{row.WeightSupplyCalibratedAll}}
							{{row.WeightPalletAll}} -->
							{{
								  
								(row.WeightSupplyCalibratedAll - row.WeightPalletAll).toFixed(2)
							}}  
						</v-chip>
						<v-chip v-if="filter.IsDiscart == 2" small color="error">
							{{
								(row.DmgDiscartWeight).toFixed(2)
							}}
						</v-chip>
					</template>
				</s-crud>
			</template>
		</v-col>

		<v-col cols="12" lg="12" md="12">
			<template>
				<s-toolbar
					label="Detalle de Pallet"
					dark
					:color="'#BAB6B5'"
					class="mb-6"
				></s-toolbar>
			

				<v-data-table
					item-key="LpsID"
					dense
					:items="itemsSupplyPallet"
					:headers="headersSupplyPallet"
					v-model="selectedSupplyPallet"
					@click:row="rowClick"
					style="margin-top: -20px;"
					disable-sort>
					<template v-slot:item.Actions="{ item }">
						<v-btn
							@click="clickAnulate(item)"
							x-small
							color="error"
							elevation="0"
							block
							outlined>
							<v-icon style="font-size: 16px !important">
								fas fa-times
							</v-icon>
						</v-btn>
					</template>

				

				</v-data-table>
				<p>
					Cantidad de Jabas: <b>{{ this.sumJaba() }}</b>
				</p>
			</template>
		</v-col>
	</v-card>
</template>

<script>
	import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
	import _sLotProdSelPalletService from "../../../services/FrozenProduction/FrzLotProductionSelectionPalletService";
	import _sGenParamService from "../../../services/General/ParameterService";

	export default {
		props: {
			nroPallet: {
				type: Number,
				default: 0,
			},
			TypeFrozenCategory: {
				type: Number,
				default: 0,
			},
			TypeFrozenCategoryName: {
				type: String,
				default: "",
			},
			TypeCultive: {
				type: Number,
				default: 0,
			},
			TypePalletMate: {
				type: Number,
				default: 0,
			},
		},
		components: {},
		data: () => ({
			RemainingWeight: 0,
			AverageWeightPallet : null,
			// reducedAmount:0,
			headersSupplyPallet: [
				{ text: "Anular", value: "Actions", width: 20 },
				{ text: "Nro. Lote Prod.", value: "RcfID", width: 20 },
				{ text: "Categoría", value: "TypeFrozenCategoryName", width: 20 },
				{ text: "N° Jabas", value: "LpsQuantityJaba", width: 20 },
				{ text: "Peso Neto pallet", value : "LpsWeightPalletNet", width: 60},
				{ text : "Peso Bruto Pallet", value : "LpsWeightPalletGross", width: 60}, 
				{ text: "Cultivo", value: "TypeCultiveName", width: 80 },
				{ text: "Variedad", value: "VrtName", width: 100},
				{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
			],

			filter: { 
				TypeShip : 2,
				TypeProcessingPlant: 0,
				TypeCultive: 0,
				IsDiscart: 1
			},
			configLot: {
				service: _sLotProdCalibratedService,
				model: {
					RcfID: "ID",
					AmountJabasWeigh: "",
					TypeFrozenCategory: "",
					LppQuantityJaba: "",
					TypeDestiny: "",
					AmountJabasWeighSupply: "",
					AmountJabasWeighDifference: "",
					AmountJabasWeighSupplyPaLDifference: "",
					TypeAlertName:"",
					WeightSupplyCalibrated:"",
					WeightSupplyEarning:"",
					KilosToReduce:"",
				},
				headers: [
				
					{text: "Categoria", value: "TypeFrozenCategory"},
					{ text: "N° Jabas", value: "LppQuantityJaba", width: 100 },
					{
						text: "Jabas Restante (Aprox)",
						value: "AmountJabasWeighSupplyPaLDifference",
						width: 100,
						align: "center"
					},
					{ text:"Peso Kilos (KG)", value:"KilosToReduce", width: 100},
					{ text: "KG Restantes.", value: "WeightSupplyEarning", width: 100 },

					{ text: "Peso Neto (Kg)", value: "WbbNetWeight", width: 100 },
					{ text: "Nro. Lote Produccion", value: "RcfID", width: 10 },
					{ text: "Kg Promedio Jaba", value: "AverageWeightJaba", width: 100 },
					{ text: "KG Cal.", value: "WeightSupplyCalibrated", width: 100 },
					{ text: "Productor", value: "PrdCardName", width: 150 },
					{ text: "Fundo", value: "FagName", width: 200 },
					{ text: "Lote", value: "FltName", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
					{ text: "Variedad", value: "VrtName", width: 100 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
					{ text: "Cantidad Fruta", value: "AmountJabasWeigh", width: 30 },
					{
						text: "Cant. Abast. CAL",
						value: "AmountJabasWeighSupply",
						width: 50,
					},
					{
						text: "Restante por Abast.",
						value: "AmountJabasWeighDifference",
						width: 30,
					},
					{ text: "Alerta", value: "TypeAlertName", width: 100 },
					{ text: "Cod. Senasa", value: "FltCodSenasa", width: 100 },
					
					
				],
			},

			items: {},
			label : "",
			itemsSupplyPallet: [],
			TypeFrozenCategoryNewAll : 0,
			selectedSupplyPallet: [],
			LppSupplyJaba: 0,
			jabaMax: 0,
			typecultivepre: 0,
		}),


		created() {

			if(this.TypeFrozenCategoryName == "DESCARTE")
			{
				this.filter.IsDiscart = 1;
			}


			_sGenParamService
			.search(
				{ PrmName: "pesoPromedioJabaMaxCongelado" },
				this.$fun.getUserID(),
			)
			.then(resp => {
				this.AverageWeightPallet = resp.data.PrmValue;
			});



			this.label = 'Armado de Parihuela ' + this.TypeFrozenCategoryName;
			this.initialize();
			this.loadParameter();
			console.log("TypeFrozenCategory ", this.TypeFrozenCategory, this.TypeFrozenCategoryName);
		},
        
		methods: {
			inputWeight(val){
				if(val !== undefined){
					this.LpsWeightPallet = val;
				}
			},
			
			loadParameter() {
				_sGenParamService
					.search(
						{ PrmName: "FrozenCalibradoArmadoParihJabas" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						if (resp.status == 200) {
							this.jabaMax = resp.data.PrmValue;
						}
					});
			},

			close() {
				this.$emit("close", this.typecultivepre);
			},

			rowSelected(val) {
				if (val.length > 0) {
					this.items = val[0];
				}
			},

			rowClick: function(item, row) {
				this.selectedSupplyPallet = [item];
			},

			inputJaba(val) {
				if (val !== undefined) {
					this.LppSupplyJaba = val;
				}
			},
			/* inputCaliber(val) {
      if (val !== undefined) {
        this.TypeCaliber = val;
      }
    }, */

			sumJaba() {
				var total = 0;
				this.itemsSupplyPallet.forEach(element => {
					total += element.LpsQuantityJaba;
				});
				return total;
			},

			sumWeighPallet (){
				var totalWeight = 0;
			},

			initialize() {
				let obj = this.$fun.getUserInfo();
				_sLotProdSelPalletService
					.getlotprodsupplypallet({ TypeFrozenCategory: this.TypeFrozenCategory, PrsID: obj.PrsID }, this.$fun.getUserID())
					.then(r => {
						this.itemsSupplyPallet = r.data;
						this.sumJaba();
						if(this.itemsSupplyPallet.length > 0){ 
							this.typecultivepre = this.itemsSupplyPallet[0].TypeCultive;
						}
					});
			},

			isValidated(quantity) {

				var isValidated = true;
				var isDifference = 0;
				isDifference = this.jabaMax - this.sumJaba();

				if (quantity > isDifference) {
					this.$fun.alert(
						"Esta superando la cantidad maximo de llenado de Pallet ==> Max = " +
							this.jabaMax,
					);
					isValidated = false;
					return;
				}

				if (this.LppSupplyJaba <= 0) {
					this.$fun.alert("La Cantidad debe ser mayor a 0", "warning");
					isValidated = false;
					return;
				}

				if (this.LpsWeightPallet <= 0) {
					this.$fun.alert("El peso debe ser mayor a 0", "warning");
					isValidated = false;
					return;
				}

				return isValidated;
			},

			SupplyPallet(val) {

				console.log('datos que viajan para guardarrrrrr', val);
				console.log('cantidad jabas ', this.LpsWeightPallet);

				let pesoToValue = (val.LppQuantityJaba * this.AverageWeightPallet)
			
				/* if( this.LpsWeightPallet > pesoToValue) {
					this.$fun.alert("El peso registrado excedio el peso promedio del pallet", "warning");
					return;
				} */
				
				
				if (val == null) {
					this.$fun.alert("No existen item", "warning");
					return;
				}
				
				if (!this.isValidated(this.LppSupplyJaba)) {
					return;
				}

				let obj = this.$fun.getUserInfo();
				val.LlpID = null;
				val.LppQuantityJaba = this.LppSupplyJaba;
				val.TypeFrozenCategory = this.TypeFrozenCategory; //this.TypeCaliber;
				val.TypeFrozenCategoryNew = this.TypeFrozenCategoryNewAll;
				val.TypePallet = this.nroPallet;
				val.LpsWeightPalletNet = this.LpsWeightPallet;
				val.LpsWeightPalletGross = this.LpsWeightPallet;
				val.LppState = 1;
				val.SecStatus = 1;
				val.UsrCreateID = this.$fun.getUserID();
				val.PrsID = obj.PrsID;
				val.TypePalletMate = this.TypePalletMate;
				debugger;
				let exist = false;
				this.itemsSupplyPallet.forEach(element => {
					
					if(this.TypeFrozenCategory != 9 && element.RcfID == val.RcfID){
						exist = true
						this.$fun.alert("Error, El Lote de Producción ya fue agregado, elimine anterior registro y vuelva agregarlo", "warning")
						return
					}
				});

				if(exist == false){
					this.$fun
						.alert("Seguro de Abastecer el Pallet ?", "question")
						.then(res => {
							if (res.value) {
								val.LpsQuantityJaba = val.LppQuantityJaba;
								val.LpsState = val.LppState;
								_sLotProdSelPalletService
									.save(val, this.$fun.getUserID())
									.then(r => {
										if (r.status == 200) {
											this.$fun.alert("Pallet abastecido", "success");
											this.initialize();
											this.$refs.gridCreatePallet.refresh();
											this.$emit("sendData", val);
										}
									});
							}
						});
				}
			},

			clickAnulate(val) {
				val.UsrCreateID = this.$fun.getUserID();

				this.$fun
					.alert("Seguro de anular registro ?", "question")
					.then(res => {
						if (res.value) {
							val.SecStatus = 0;
							val.LlpID = null;
							_sLotProdSelPalletService
								.save(val, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert("Registro anulado", "success");
										this.initialize();
										this.$refs.gridCreatePallet.refresh();
									}
								});
						}
					});
			},
		},

		mounted(){
			console.log("this.typecultive", this.TypeCultive);
			console.log("this.typecultivepre", this.typecultivepre);
			console.log("this.TypeFrozenCategory", this.TypeFrozenCategory);

			this.filter.TypeCultive = this.TypeCultive;
			 
		}
	};
</script>

<style></style>

<template>
  <div>
    <!-- trabajadores -->
    <v-row>
      <v-col>
        <s-crud
          :single-row="false"
          :add="false"
          @add="addWorker()"
          noEdit
          saveList
          addNoFunction
          searchInput
          :save="conectadoBiometric"
          @save="AsignarReloj()"
          @rowSelected="selectedWorker($event)"
          ref="gridWorker"
          title="Trabajadores"
          :config="configWorker"
          :filter="filterWorker"
          @doubleClick="assignWorker($event)"
        >
          <template v-slot:filter>
            <v-container>
              <v-row justify="center"
                ><v-col lg="6">
                  <s-select-area
                    v-model="filterWorker.AreID"
                    label="Área" /></v-col
              ></v-row>
            </v-container>
          </template>
        </s-crud>
      </v-col>
      <!-- ------------------------- -->
      <v-col>
        <s-crud
          searchInput
          title="Trabajadores asignados al equipo biométrico"
          :config="configBiometricWorker"
          :filter="filterBiometricWorker"
          ref="gridBiometricWorker"
          :remove = "conectadoBiometric"
        >
          <template v-slot:filter>
            <v-container>
              <v-row>
                <v-col
                  ><s-select-generic
                    itemtext="BieTxtDisplay"
                    itemvalue="BieID"
                    id="BieID"
                    v-model="filterBiometricWorker.BieID"
                    :config="configSelectBiometric"
                    @change="selectedBiometric($event, filterBiometricWorker.BieID)"
                    :disabled="conectadoBiometric || useConection"
                  ></s-select-generic
                ></v-col>
                <v-col>
                  <v-row style="display: flex; align-items: center; justify-content: center;">
                    <v-col style="width: 100%;">
                      <s-select-generic
                        itemtext="BieIP"
                        itemvalue="BieID"
                        id="BieIP"
                        v-model="filterBiometricWorker.BieID"
                        :config="configSelectBiometric"
                        disabled="disabled"
                      ></s-select-generic>
                    </v-col>
                    <v-col>
                      <v-btn v-show="!conectadoBiometric && !useConection" :disabled="useConection" style="height: 25px; background-color: #1fbc07 !important; color: #fff !important;"  @click="fn_connectFingerPrint">Conectar</v-btn>

                      <v-btn v-show="conectadoBiometric && !useConection" :disabled="useConection" style="height: 25px; background-color: #ff0000 !important; color: #fff !important;"  @click="fn_disconnectFingerPrint">Desconectar</v-btn>

                      <v-btn v-show="useConection" style="height: 25px;">Conectando...</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:AsgAction="{ row }">
            <v-btn :disabled="!conectadoBiometric" x-small style="margin-right: 10px;" color="primary" @click="AsgFingerPrint(row)">
              <i class='fas fa-sync-alt'></i>
            </v-btn>
          </template>
        </s-crud>
      </v-col>
    </v-row>

    
    <!-- ----------------------------------- -->
  </div>
</template>

<script>
import WorkerService from "../../../services/HumanResource/WorkerService";
import _sHelper from "@/services/HelperService";
import _sGenParam from "@/services/General/ParameterService";

import _sBiometricEquipmentService from "../../../services/HumanResource/BiometricEquipmentService";
import _sBiometricWorkerService from "../../../services/HumanResource/BiometricWorkerService";

export default {
  components: {},
  data() {
    return {
      conectadoBiometric: false,
      useConection: false,
      dialogFingerPrint: false,
      loadingFingerPrint: false,
      fingerPrint: false,
      listFingerPrint: {
        huella0: false,
        huella1: false,
        huella2: false,
        huella3: false,
        huella4: false,
        huella5: false,
        huella6: true,
        huella7: true,
        huella8: false,
        huella9: false
      },

      dataIP: "",
      dataPort: 4370,

      Name: "",
      Password: "",
      Privilege: 0,
      Enabled: true,

      data:{
        MachineNumber: 1,
        EnrollNumber: "0",

        FingerIndex: null,
        Flag: 0,
        TemplateData:"",
      },

      BiometricsSelected: [],
      WorkersSelected: [],
      BiometricsWorkerSelected: [],
      itemsWorker: [],
      itemsBiometric: [],
      itemsBiometricWorker: [],
      selectionBefore: [],
      filterBiometric: {},
      biometricId: 0,
      filterWorker: {
        AreID: 0,
      },
      filterBiometricWorker: {
        BieID: 0,
      },
      configWorker: {
        service: WorkerService,
        model: {
          WkrID: "ID",
        },
        headers: [
          { text: "ID", value: "WkrID", sortable: false },
          { text: "Trabajador", value: "WkrName", sortable: true },
          { text: "Area", value: "AreName", sortable: true },
        ],
        listWorkersHeaders: [
          { text: "Trabajador", value: "WkrName", sortable: true },
          { text: "Area", value: "AreName", sortable: true },
        ],
      },
      configBiometricWorker: {
        service: _sBiometricWorkerService,
        model: {
          BiwID: "ID",
          AreID: "int",
          AsgAction: ""
        },
        headers: [
          //{ text: "Equipo", value: "BieEquipment" },
          { text: "ID", value: "WkrID", sortable: false },
          { text: "Trabajador", value: "BiwPersonName" },
          {
            text: "Txt de la Pantalla",
            value: "BiwBiometricDisplay",
            width: 50,
          },
          { text: "Sincronizar", value: "AsgAction", sortable: false , align: "center" },
        ],
      },
      configSelectBiometric: {
        url: _sBiometricEquipmentService.list(),
        params: {},
        title: "Biométrico",
      },
    };
  },
  methods: {
    fn_loadingFingerPrint(){
      this.dialogFingerPrint = true;
      this.loadingFingerPrint = true;
      this.fingerPrint = false;

      setTimeout(() => {
        this.loadingFingerPrint = false;
        this.fingerPrint = true;
      }, 2000);
    },
    fn_connectFingerPrint(){
      console.log("IP : ", this.dataIP);
      this.useConection = true;
      WorkerService.connectFingerPrint(this.dataIP, this.dataPort, this.$fun.getUserID()).then((response)=>{ 
          console.log("Respuesta : ",response.data.success);
          this.conectadoBiometric = response.data.success;
          this.useConection = false;
      });
    },
    fn_disconnectFingerPrint(){
      WorkerService.disconnectFingerPrint(this.$fun.getUserID()).then((response)=>{ 
          console.log("Respuesta : ",response);
          this.conectadoBiometric = false;
      });
    },
    fn_addFingerPrint(){
      WorkerService.addFingerPrint(this.data, this.$fun.getUserID()).then((response)=>{ 
          console.log("Respuesta : ",response);
      });
    },
    addWorker() {
      this.selectionBefore.forEach((element) => {
        if (this.itemsWorker.find((x) => x == element) == null)
          this.itemsWorker.push(element);
      });
    },
    addBiometrics() {
      this.BiometricsSelected = this.itemBiometric;
    },
    selectedWorker(items) {
      this.itemsWorker = items;
    },
    selectedBiometric(items, newBieID) {
      this.itemsBiometric = items;

      const selectedBiometric = this.itemsBiometric.find(item => item.BieID === newBieID);
      if (selectedBiometric && selectedBiometric.BieIP) {
        this.dataIP = selectedBiometric.BieIP;
      }
    },
    async AsgFingerPrint(row){
      console.log("Row : ",row);

      this.data.EnrollNumber = String(row.WkrID);

      const r = await _sBiometricWorkerService.listFingerPrintUser(row.NtpID, this.$fun.getUserID());
            
      if (r.status == 200) {
        console.log(`Respuesta : ${row.NtpID}`, r.data);
        
        // Esperar a que todos los registros de huellas se procesen
        for (const fingerprint of r.data) {
          this.data.FingerIndex = fingerprint.WfpTypeFinger;
          this.data.TemplateData = fingerprint.NtpValue;

          const fingerPrintResponse = await WorkerService.addFingerPrint(this.data, this.$fun.getUserID());
          console.log("Respuesta : ", fingerPrintResponse.data.success);
        }

        this.$fun.alert(`${r.data.length == 1 ? "Huella" : "Huellas"} sincronizadas correctamente`, "success");
      }
    },
    async AsignarReloj() {      
      const usersRegister = this.itemsWorker.map((x) => x);
      
      // Solo obtener el wkrID de itemsWorker y el bieID de itemsBiometric
      const workers_Id = this.itemsWorker.map((x) => x.WkrID);
      // Sacar el BieId de la configuración de Biometric
      const biometric_Id = this.filterBiometricWorker.BieID;
      
      // Antes de enviar, verificar si hay datos en workers_Id y biometric_Id
      if (workers_Id.length > 0 && biometric_Id != 0) {
        await _sBiometricWorkerService
          .addBiometricWorker(workers_Id, biometric_Id, this.$fun.getUserID())
          .then((rsp) => {
            console.log("Respuesta", rsp);              
            this.$refs.gridBiometricWorker.refresh();
          });
      }

      // Procesar cada usuario
      for (const user of usersRegister) {
        this.data.EnrollNumber = String(user.WkrID);
        this.Name = user.WkrName;

        try {
          const response = await WorkerService.addUserFingerPrint(
            this.data.MachineNumber,
            this.data.EnrollNumber,
            this.Name,
            this.Password,
            this.Privilege,
            this.Enabled,
            this.$fun.getUserID()
          );
          
          if (response.data.success) {
            console.log("Usuario procesado exitosamente:", this.Name);
            
            // Realizar la solicitud de prueba
            const r = await _sBiometricWorkerService.listFingerPrintUser(user.NtpID, this.$fun.getUserID());
            
            if (r.status == 200) {
              console.log(`Respuesta : ${user.NtpID}`, r.data);
              
              // Esperar a que todos los registros de huellas se procesen
              for (const fingerprint of r.data) {
                this.data.FingerIndex = fingerprint.WfpTypeFinger;
                this.data.TemplateData = fingerprint.NtpValue;

                console.log

                const fingerPrintResponse = await WorkerService.addFingerPrint(this.data, this.$fun.getUserID());
                console.log("Respuesta : ", fingerPrintResponse.data.success);
              }
            }
          } else {
            console.error("Error al procesar usuario:", this.Name);
            this.$fun.alert("Ocurrio un error al procesar el usuario " + this.Name, "warning");
            break; // Detener el ciclo si no es exitoso
          }
        } catch (error) {
          console.error("Error en la solicitud:", error);
          this.$fun.alert("Ocurrio un error", "warning");
          break; // Detener el ciclo si ocurre un error
        }
      }

      this.$fun.alert(`${usersRegister.length == 1 ? "Usuario" : "Usuarios"} registrado correctamente`, "success");
    },
    listWorkersByBiometric(BieID) {
      _sBiometricWorkerService
        .listWorkersByBiometric(BieID, this.$fun.getUserID())
        .then((rsp) => {
          this.itemsBiometricWorker = rsp.data;
        });
    },
    assignWorker(worker) {
      if (this.itemsWorker.find((x) => x == worker) == null)
        this.itemsWorker.push(worker);
    },
  },
  created() {
  }
};
</script>

<style scoped>
.cardED {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.2s;
  margin-bottom: 20px;
}

.cardED::after {
  content: "Cargando...";
  height: fit-content;
  width: 100%;
  color: #242424;
  font-weight: bold;
  position: absolute;
  z-index: 2;
  bottom: -5%;
  text-align: center;
  animation: 2s dots  infinite;
}

.cardED::before {
  content: "";
  height: 0.1em;
  width: 80%;
  border-radius: 10px;
  background-color: rgb(4, 255, 0);
  position: absolute;
  z-index: 2;
  bottom: 7%;
  animation: 4s grow  infinite;
  align-self: flex-start;
  margin-left: 10%;
  /* filter: drop-shadow(0px 0px 10px #3fef4e) drop-shadow(0px 0px 10px #3fef4e); */
}

/* .cardED svg {
  filter: drop-shadow(0px 0px 10px #3fef4e) drop-shadow(0px 0px 50px #3fefef);
} */

path {
  stroke-dasharray: 90;
  animation: loop 4s infinite;
}

@keyframes loop {
  0% {
    stroke-dashoffset: 50;
    stroke-dasharray: 90;
  }

  50% {
    stroke-dashoffset: 100;
    ;
        stroke-dasharray: 50;
  }

  100% {
    stroke-dashoffset: 50;
    stroke-dasharray: 90;
  }
}

@keyframes dots {
  0% {
    content: "Cargando."
  }

  25% {
    content: "Cargando.."
  }

  50% {
    content: "Cargando..."
  }

  75% {
    content: "Cargando"
  }

  100% {
    content: "Cargando."
  }
}

@keyframes grow {
  0% {
    width: 0%;
  }

  50% {
    width: 80%;
  }

  100% {
    width: 0%;
  }
}
</style>
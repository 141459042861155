import Service from "../Service";

const resource = "MngAuthExEpp/";

export default {
  save(par, requestID) {
    return Service.post(resource + "save", par, {
      params: { requestID: requestID },
    });
  },
  
  pagination(par, requestID) {
    return Service.post(resource + "pagination", par, {
      params: { requestID: requestID },
    });
  },
  List(parameters, requestID) {
    return Service.post(resource + "List", parameters, {
        params: { requestID: requestID },
    });
  },
  ListPDF(parameters, requestID) {
    return Service.post(resource + "ListPDF", parameters, {
        params: { requestID: requestID },
    });
  },

}
let children = [];
//SST
children.push({
    path: "/Recursos_Humanos/RestriccionAccesos",
    name: "HMN_ACCESS_RESTRICTION",
    component: () =>
        import ("../views/HumanResource/AccessRestriction/AccessRestriction.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos  Humanos", disabled: true },
            {
                text: "Restricción de Accesos",
                href: "/Recursos_Humanos/RestriccionAccesos",
            },
        ],
    },
});

children.push({
    path: "/Recursos_Humanos/Salud_Ocupacional/Historias",
    name: "MEDICAL_RECORD",
    component: () =>
        import ("../views/HumanResource/OccupationalHealth/HistoryRecord.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos  Humanos", disabled: true },
            { text: "Salud Ocupacional", disabled: true },
            {
                text: "Historias Médicas",
                href: "/Recursos_Humanos/Salud_Ocupacional/Historias",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/fotocheck",
    name: "FOTOCHECK",
    component: () =>
        import ("../views/HumanResource/FotoCheck.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Fotocheck", href: "/recursoshumanos/fotocheck" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/cargo",
    name: "GEN_POSITION",
    component: () =>
        import ("../views/General/Position.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Cargo", href: "/recursoshumanos/cargo" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/organigrama",
    name: "GEN_ORGANIZATION",
    component: () =>
        import ("../views/General/Organization.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            {
                text: "Organigrama",
                href: "/recursoshumanos/organigrama",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/programacionaccesos",
    name: "MNG_ACCESSPROGRAMMING",
    component: () =>
        import ("../views/HumanResource/AccessProgramming.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            {
                text: "Programación de Accesos",
                href: "/recursoshumanos/programacionaccesos",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/amonestacion",
    name: "HMN_ADMONITION",
    component: () =>
        import ("../views/HumanResource/Admonition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Amonestaciones", href: "/recursoshumanos/amonestacion" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/equipo_biometrico",
    name: "HMN_BIOMETRIC_EQUIPMENT",
    component: () =>
        import ("../views/HumanResource/BiometricEquipment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Equipo Biometrico", href: "/recursoshumanos/equipo_biometrico" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/bienestarsocial/asignacionfamiliar",
    name: "HMN_HOUSEHOLDALLOWANCE",
    component: () =>
        import ("../views/HumanResource/HouseHoldAllowance.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Bienestar Social", disabled: true },
            {
                text: "Asignación Familiar",
                href: "/recursoshumanos/bienestarsocial/asignacionfamiliar",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/bienestarsocial/contingeciaslaborales",
    name: "HMN_WORKERALLOWANCE",
    component: () =>
        import ("../views/HumanResource/WorkerAllowance.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Bienestar Social", disabled: true },
            {
                text: "Contingencias Laborales",
                href: "/recursoshumanos/bienestarsocial/contingeciaslaborales",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/contrataciones/contrato",
    name: "CONTRCNS_CONTTO",
    component: () =>
        import ("../views/HumanResource/contract.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Contrataciones", disabled: true },
            {
                text: "Contrato",
                href: "/recursoshumanos/contrataciones/contrato",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/bienestarsocial/reportesasignacionfamiliar",
    name: "HMN_HHA_REPORT",
    component: () =>
        import ("../views/HumanResource/ReportHouseHoldWorker.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Bienestar Social", disabled: true },
            {
                text: "Reportes de Asignación Familiar",
                href: "/recursoshumanos/bienestarsocial/reportesasignacionfamiliar",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/reportes",
    name: "HMN_REPORT_POSITION",
    component: () =>
        import ("../views/HumanResource/ReportHumanResource.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Reportes", href: "/recursoshumanos/reportes" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/sst/induccion",
    name: "SST_INDUCTION",
    component: () =>
        import ("../views/Management/Instruction.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "SST", disabled: true },
            {
                text: "Inducción de Personal",
                href: "/recursoshumanos/sst/induccion",
            },
        ],
    },
});

children.push({
    path: "/recursoshumanos/sst/induccion_list",
    name: "SST_INDUCTION_LIST",
    component: () =>
        import ("../views/Management/Instruction/InstructionList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "SST", disabled: true },
            {
                text: "Inducción de Personal",
                href: "/recursoshumanos/sst/induccion_list",
            },
        ],
    },
});

children.push({
    path: "/recursoshumanos/mantenimiento/profesiones",
    name: "PFNSIONS",
    component: () =>
        import ("../views/HumanResource/Profession.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Profesiones", disabled: true },
            {
                text: "Profesiones",
                href: "/recursoshumanos/mantenimiento/profesiones",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/mantenimiento/cuadrillas",
    name: "CDRILLS",
    component: () =>
        import ("../views/HumanResource/hmnGang.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Cuadrillas", disabled: true },
            {
                text: "Profesiones",
                href: "/recursoshumanos/mantenimiento/cuadrillas",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/AsientoScire",
    name: "HMN_JOURNALENTRY",
    component: () =>
        import ("../views/HumanResource/JournalEntrySCIRE.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            {
                text: "Asiento SCIRE SAP",
                href: "/recursoshumanos/asientosciresap",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/utilidades",
    name: "UTILITY_SCHEDULING",
    component: () =>
        import ("../views/Management/UtilityScheduling.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            {
                text: "Programación de Utilidades",
                href: "/recursoshumanos/utilidades",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/vaccine",
    name: "HMN_VACCINE",
    component: () =>
        import ("../views/HumanResource/Vaccine/Vaccine.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            {
                text: "Registro de Vacunas",
                href: "/recursoshumanos/Vacunas",
            },

        ],
    },
});

/// ------- Gestion Humnana
children.push({
    path: "/recursoshumanos/gestionhumana/programaciones",
    name: "HMN_INDUCTION_SCHEDULING",
    component: () =>
        import ("../views/HumanResource/HumanManagement/InstructionScheduling.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "GESTIÍON HUMANA", disabled: true },
            {
                text: "Inducción de Personal",
                href: "/recursoshumanos/gestionhumana/programaciones",
            },
        ],
    },
});
children.push({
    path: "/administracion/temas",
    name: "MNG_INDUCTION_SUBJECT",
    component: () =>
        import ("../views/Management/InstructionSubject.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Administración", disabled: true },
            { text: "Temas", href: "/administracion/temas" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/gestionhumana/capacitaciones",
    name: "HMN_INDUCTION",
    component: () =>
        import ("../views/HumanResource/HumanManagement/Instruction.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "GESTIÍON HUMANA", disabled: true },
            {
                text: "Inducción de Personal",
                href: "/recursoshumanos/gestionhumana/capacitaciones",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/preregistro",
    name: "HMN_PRE_REGISTRATION",
    component: () =>
        import ("../views/HumanResource/PreRegistration/PreRegistration.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Pre Registro", disabled: true },
            {
                text: "Pre Registro del Personal",
                href: "/recursoshumanos/preregistro",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/asignarbus",
    name: "HMN_ASSIGN_BUS_PERSON",
    component: () =>
        import ("../views/HumanResource/BusControl/AssignBus.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Asignar Bus", disabled: true },
            {
                text: "Asignar Bus",
                href: "/recursoshumanos/asignarbus",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/motivoamonestacion",
    name: "HMN_REASON_FOR_LIVELIHOOD",
    component: () =>
        import ("../views/HumanResource/Admonition/ReasonForLivelihood.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Motivo de Amonestacion", disabled: true },
            {
                text: "Motivo de Amonestacion",
                href: "/recursoshumanos/motivoamonestacion",
            },
        ],
    },
});


children.push({
    path: "/recursoshumanos/manejobuss",
    name: "HMN_BUSS",
    component: () =>
        import ("../views/HumanResource/BusControl/Buss.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Manejo Buss", disabled: true },
            {
                text: "Manejo Buss",
                href: "/recursoshumanos/manejobuss",
            },
        ],
    },
});

children.push({
    path: "/recursoshumanos/preregistrotopico",
    name: "HMN_PRE_REGISTRATION_TOPIC",
    component: () =>
        import ("../views/HumanResource/PreRegistration/PreRegistrationTopic.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Pre Registro Topico", disabled: true },
            {
                text: "Pre Registro del Personal",
                href: "/recursoshumanos/preregistrotopico",
            },
        ],
    },
});

children.push({
    path: "/recursoshumanos/primerllamado",
    name: "RECRUITMENT_CALL",
    component: () =>
        import ("../views/HumanResource/Recruitment/RecruitmentCall.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Mensaje de Texto", href: "/recursoshumanos/primerllamado" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/feriados",
    name: "HMN_HOLIDAYS",
    component: () =>
        import ("../views/HumanResource/Configuration/Holiday.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Configuración", disabled: true },
            {
                text: "Feriados",
                href: "/recursoshumanos/feriados",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/niveleducativo",
    name: "HMN_EDUCATIONAL_LEVEL",
    component: () =>
        import ("../views/HumanResource/EducationalLevel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Nivel Educativo", href: "/recursoshumanos/niveleducativo" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/vacaciones",
    name: "HMN_VACATIONS",
    component: () =>
        import ("../views/HumanResource/Vacations.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Vacaciones", href: "/recursoshumanos/vacaciones" },
        ],
    },
});

//asignacion de trabajadores a equipo biometrico
children.push({
  path: "/recursoshumanos/AsignacionBiometrico",
  name: "HMN_BIOMETRIC_WORKER",
  component: () =>
      import ("../views/HumanResource/AccessRestriction/BiometricWorker.vue"),
  meta: {
      authenticated: true,
      breadcrumb: [
          { text: "Inicio", href: "/#/" },
          { text: "Recursos Humanos", disabled: true },
          { text: "Trabajador-Biométrico", href: "/recursoshumano/AsignacionBiometrico" },
      ],
  },
});
//fin asignacion de trabajadores a equipo biometrico
children.push({
    path: "/recursoshumanos/cambiarturnomasivo",
    name: "HMN_CHANGE_SHIFT",
    component: () =>
        import ("../views/HumanResource/ChangeShiftMass.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Cambiar Turno Masivo", href: "/recursoshumanos/cambiarturnomasivo" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/sst/config_epps",
    name: "SST_CONFGEPPS",
    component: () =>
        import ("../views/Management/Instruction/config_epps.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Config. Epps", disabled: true },
            {
                text: "Config. Epps",
                href: "/recursoshumanos/sst/config_epps",
            },
        ],
    },
});
export { children }
<template>
  <div style="padding-top:-2px;margin-top:-2px;">
    <v-label><b>Área</b></v-label>
    <v-select
      :return-object="returnObject"
      :item-value="itemvalue"
      :item-text="itemtext"
      :disabled="disabled"
      :readonly="readonly"
      :items="items"
      :clearable="clearable"
      dense
      outlined
      v-model="localValue" 
      @input="updateValue"
      hide-details="false"
    />
  </div>
</template>
<!-- Usa v-model aquí -->
<script>
export default {
  props: {
    value: null,
    itemtext: {
      type: String,
      default: "AreName",
    },
    itemvalue: {
      type: String,
      default: "AreID",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value, // Local value para el v-model
      items: [],
    };
  },
  watch: {
    value(newValue) {
      this.localValue = newValue; // Sincroniza localValue con value
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    updateValue(value) {
      this.localValue = value; // Actualiza el valor local
      this.$emit("input", value); // Emite el nuevo valor al padre
    },
    load() {
      this.items = this.$fun.getSecurity().AreasAllow;
      this.localValue = this.value; // Inicializa localValue con value
    },
  },
};
</script>

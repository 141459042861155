<template>
    <v-card elevation="0">
      <s-toolbar v-if="visibleTitle" label="Datos Persona" />
      <v-card-text> 
        <v-row>
          <v-col class="s-col-form" sm="3" md="3">
            <s-select-definition :def="1036" label="Tipo Documento" v-model="person.GenPerson.TypePersonDocument" />
          </v-col>
          <v-col class="s-col-form" sm="3" md="3">
            <s-text
              @keyupEnter="txtDocument()"
              label="Documento*"
              number
              @blur="search()"
              ref="document"
              :autofocus="person.GenPerson.PrsDocumentNumber.length == 0"
              v-model="person.GenPerson.PrsDocumentNumber"
            />
          </v-col>
          <v-col class="s-col-form" sm="6" md="6" v-if="typeperson == 1">
            <s-text
              ref="textNtpName"
              label="Nombre*"
              @keyupEnter="save()"
              v-model="person.NtpName"
              :autofocus="person.GenPerson.PrsDocumentNumber.length > 0"
            />
          </v-col>
          <v-col class="s-col-form" sm="6" md="6" v-if="typeperson == 2">
            <s-text ref="textJrpName" label="Nombre Juridico*" v-model="person.JrpName" :autofocus="person.GenPerson.PrsDocumentNumber.length > 0" />
          </v-col>
        </v-row>
  
        <v-row v-if="typeperson == 1">
          <v-col class="s-col-form" sm="6" md="6">
            <s-text label="Apellido Paterno*" v-model="person.NtpPaternalSurname" @keyupEnter="save()" />
          </v-col>
          <v-col class="s-col-form" sm="6" md="6">
            <s-text label="Apellido Materno*" @keyupEnter="save()" v-model="person.NtpMaternalSurname" />
          </v-col>
        </v-row>
  
        <v-row>
          <v-col class="s-col-form" sm="3" md="3" v-if="addBirthDate || full">
            <!-- <s-date v-if="!full" v-model="person.NtpBirthDate" label=" Fecha de Nacimiento" />
            <s-text
              placeholder="DD-MM-YYYY"
              :readonly="false"
              v-else
              v-mask="'##-##-####'"
              @keyupEnter="save()"
              v-model="person.NtpBirthDate"
              label="Fecha Nacimiento"
            /> -->
  
            <s-date @keyupEnter="save()" v-model="person.NtpBirthDate" label="Fecha Nacimiento" />
          </v-col>
          <v-col class="s-col-form" md="3" v-show="addTypeSex || full">
            <s-select-definition :def="1037" label="Género" v-model="person.TypeSex" />
          </v-col>
  
          <v-col class="s-col-form" v-if="full">
            <s-text v-model="phone.PpnNumberPhone" @keyupEnter="save()" type="tel" number label=" Teléfono" />
          </v-col>
          <v-col class="s-col-form" v-if="full">
            <s-text v-model="phone.PpnNumberPhone2" @keyupEnter="save()" type="tel" number label=" Teléfono2" />
          </v-col>
        </v-row>
  
        <v-row v-if="full">
          <v-col class="s-col-form" sm="3" md="3" v-if="full">
            <s-text label="Email" @keyupEnter="save()" v-model="email.PemEmail" />
          </v-col>
          <v-col class="s-col-form" sm="3" md="3">
            <s-select-generic label="Departamento " itemvalue="GenHelper" v-model="DepartmentUbigeo" autocomplete full :config="configDepartment" />
          </v-col>
          <v-col class="s-col-form" sm="3" md="3">
            <s-select-generic label="Provincia" itemvalue="GenHelper" v-model="ProvinceUbigeo" autocomplete full :config="configProvince" />
          </v-col>
          <v-col class="s-col-form" sm="3" md="3">
            <s-select-generic
              itemvalue="GenHelper"
              v-model="DistrictUbigeo"
              label="Distrito"
              autocomplete
              @updateData="updateDataDistrict()"
              full
              :config="configDistrict"
            />
          </v-col>
        </v-row>
        <v-row v-if="full">
          <v-col class="s-col-form" sm="3" md="3">
            <s-text @keyupEnter="save()" v-model="address.PadAddress" label=" Dirección" />
          </v-col>
          <v-col class="s-col-form" sm="3" md="3">
            <s-text @keyupEnter="save()" v-model="address.PadAddressReference" label="Referencia" />
          </v-col>
          <v-col class="s-col-form" sm="3" md="3">
            <s-select-generic label="Nacionalidad" itemvalue="GenID" v-model="person.GenPerson.NatID" autocomplete full :config="configNationality" />
          </v-col>
          <v-col class="s-col-form" sm="3" md="3">
            <s-select-generic label="Zona" itemvalue="GenID" v-model="ZoneID" autocomplete full :config="configZones" />
          </v-col>
        </v-row>
  
       <!-- <v-row v-if="typeperson == 1 && (full || addDriver)">
          <v-col class="s-col-form" sm="3" md="3">
            <s-text @keyupEnter="save()" v-model="driver.NpdDocumentNumber" label="Brevete" />
          </v-col>
          <v-col class="s-col-form" sm="3" md="3">
            <s-select-definition :def="1139" label="Categoría" v-model="driver.TypeDocumentCategory" />
          </v-col>
          <v-col class="s-col-form" sm="3" md="3">
            <s-date v-model="driver.NpdDocumentInit" label="Emisión" />
          </v-col>
          <v-col class="s-col-form" sm="3" md="3">
            <s-date v-model="driver.NpdDocumentEnd" label="Revalidación" />
          </v-col>
        </v-row>-->
      </v-card-text>
      <!--<v-card-actions>
        <v-container>
          <v-row>
            <v-col class="s-col-form"><v-btn block text @click="close()">Cancelar</v-btn></v-col>
            <v-col class="s-col-form"><v-btn block color="primary" @click="save()">GUARDAR</v-btn></v-col>
          </v-row>
        </v-container>
      </v-card-actions> -->
      <!-- Modal para mostrar la alerta -->
      <v-dialog v-model="dialog" max-width="400px">
            <v-card>
            <v-card-title class="headline">Trabajador Encontrado</v-card-title>
            <v-card-text>
              <div>
                <p><strong>Nombre:</strong> {{ person.NtpName }} {{ person.NtpPaternalSurname }} {{ person.NtpMaternalSurname }}</p>
                <p><strong>Estado:</strong> El trabajador ya existe en la base de datos.</p>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="green" text @click="closeModal">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </v-card>
</template>
  
  <script>
  import Service from "@/services/General/PersonService";
  import _sGeneric from "@/services/General/GenericService";
  import SText from "../SText.vue";
  export default {
    name: "sAddPersonRm",
    components: { SText },
    props: {
      propPerson: {
        type: Object,
        default: null,
      },
      visibleTitle: {
        type: Boolean,
        default: true,
      },
      full: {
        type: Boolean,
        default: false,
      },
      typeperson: {
        type: Number,
        default: 1,
      },
      addTypeSex: {
        type: Boolean,
        default: false,
      },
      addBirthDate: {
        type: Boolean,
        default: false,
      },
      addDriver: {
        type: Boolean,
        default: false,
      },
      dataPers: { type: Object },
    },
    data() {
      return {
        dataPer:{},
        editing: false,
        configNationality: null,
        configZones: null,
        configDepartment: null,
        DepartmentUbigeo: "99",
        ProvinceUbigeo: "00",
        DistrictUbigeo: "",
        item: 0,
        ZoneID: 15,
        itemsNationality: [],
        menu3: false,
        config: {},
        dialog: false,
        phone: {
          TypePhone: 1,
          PpnNumberPhone: "99999999",
          PpnNumberPhone2: "99999999",
          PpnIsDefault: 1,
        },
        address: {
          PadCodeUbigeo: "",
          PadAddress: "...",
          PadAddressReference: "",
          PadStreet: "",
          PadDepartment: "",
          ZonID: 0,
          PadIsDefault: 1,
        },
        email: {
          PemEmail: "abc.@hotmail.com",
          PemIsDefault: 1,
        },
        person: {
          NtpName: "",
          NtpPaternalSurname: "",
          NtpMaternalSurname: "",
          NtpID: 0,
          NtpBirthDate: null,
          TypeSex: 1,
          GenPerson: {
            TypePersonDocument: 0,
            PrsDocumentNumber: "",
            NatID: 193,
          },
          GenNaturalPersonDriver: null,
        },
        driver: {
          NpdID: 0,
          NpdDocumentNumber: "",
          NtpID: 0,
          NpdDocumentInit: null,
          NpdDocumentEnd: null,
          TypeDocumentCategory: 0,
        },
      };
    },
    methods: {
      initialize()
			{
        if(this.dataPers && typeof this.dataPers === 'object'){
        console.log("dataPer", this.dataPer);
       /* this.email = this.dataPer.GenPerson.Emails[0];
        this.phone = this.dataPer.GenPerson.Telephones[0];
        this.address=this.dataPer.GenPerson.Addresses[0];
        this.person.NtpBirthDate =this.dataPer.NtpBirthDate;
        this.person.NtpName =this.dataPer.NtpName;
        this.person.NtpPaternalSurname =this.dataPer.NtpPaternalSurname;
        this.person.NtpMaternalSurname=this.dataPer.NtpMaternalSurname;
        this.person.NtpID=this.dataPer.NtpID;
        this.person.TypeSex=this.dataPer.TypeSex;*/
        this.person.GenPerson.PrsDocumentNumber=this.dataPer.GenPerson.PrsDocumentNumber;
        this.search();
      }
			},
       getData() {
    return {
      person: this.person,
      email: this.email,
      phone: this.phone,
      address: this.address,
      driver: this.driver,
      ZoneID: this.ZoneID,
      DepartmentUbigeo: this.DepartmentUbigeo,
      ProvinceUbigeo: this.ProvinceUbigeo,
      DistrictUbigeo: this.DistrictUbigeo,
    };},
      updateDataDistrict() {
        if (this.person != null)
          if (this.person.GenPerson != null)
            if (this.person.GenPerson.Addresses != null)
              if (this.person.GenPerson.Addresses.length > 0) {
                this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
              }
      },
      txtDocument() {
        if (this.typeperson == 1) this.$refs.textNtpName.focus();
        else this.$refs.textJrpName.focus();
      },
      search() {
        this.clear();
        Service.getNaturalPerson(this.person.GenPerson.PrsDocumentNumber, this.$fun.getUserID()).then((r) => {
          if (r.status == 200) {
          //  this.dialog = true;
            this.editing = true;
            this.personEdit = r.data;
            if (this.personEdit != null) {
              if (this.personEdit.GenPerson != null) {
                this.person = { ...this.personEdit };
                // this.person.NtpBirthDate = this.$moment(this.personEdit.NtpBirthDate).format("DD-MM-YYYY");
                this.person.NtpBirthDate = this.personEdit.NtpBirthDate;
                this.person.GenPerson.TypePersonDocument = 1;
  
                if (this.person.GenPerson.Emails.length > 0) {
                  this.email = this.person.GenPerson.Emails[0];
                }
                if (this.person.GenPerson.Telephones.length > 0) {
                  this.phone = this.person.GenPerson.Telephones[0];
                }
                if (this.person.GenPerson.Addresses.length > 0) {
                  this.ZoneID = this.person.GenPerson.Addresses[0].ZonID;
                  this.address = this.person.GenPerson.Addresses[0];
                  this.DepartmentUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 2);
                  this.ProvinceUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 4);
                  this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
                }
                if (this.person.GenNaturalPersonDriver.length > 0) {
                  this.driver = this.person.GenNaturalPersonDriver[0];
                }
              } else if (this.propPerson.PrsDocumentNumber) this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
  
              if (this.typeperson == 1) this.$refs.textNtpName.focus();
              else this.$refs.textJrpName.focus();
            }
            this.editing = false;
          }
          this.$emit("search", this.person);
          
        });
      },
      clear() {
        this.ZoneID = 15;
        this.DepartmentUbigeo = "20";
        this.ProvinceUbigeo = "2001";
        this.phone = {
          TypePhone: 1,
          PpnNumberPhone: "",
          PpnIsDefault: 1,
        };
        this.address = {
          PadCodeUbigeo: "",
          PadAddress: "",
          PadAddressReference: "",
          PadStreet: "",
          PadDepartment: "",
          ZonID: 0,
          PadIsDefault: 1,
        };
        this.email = {
          PemEmail: "",
          PemIsDefault: 1,
        };
        this.driver = {
          NpdID: 0,
          NpdDocumentNumber: "",
          NpdDocumentInit: null,
          NpdDocumentEnd: null,
          TypeDocumentCategory: 0,
        };
  
        let doc;
        if (this.person.GenPerson.PrsDocumentNumber.length > 0) doc = this.person.GenPerson.PrsDocumentNumber;
        else doc = "";
        this.person = {
          NtpName: "",
          NtpPaternalSurname: "",
          NtpMaternalSurname: "",
          NtpID: 0,
          NtpBirthDate: this.$moment().format(this.$const.DateMomentFormat),
          TypeSex: 1,
          GenPerson: {
            TypePersonDocument: 1,
            PrsDocumentNumber: doc,
            NatID: 193,
          },
        };
      },
      save() {
        this.person.GenPerson.TypePerson = this.typeperson;
  
        if (this.person.GenPerson.PrsDocumentNumber.trim().length < 6) {
          this.$fun.alert("Numero de Documento Incorrecto", "warning");
          return;
        }
        if (this.person.NtpName.trim().length < 1) {
          this.$fun.alert("Registre Nombre", "warning");
          return;
        }
        if (this.person.NtpPaternalSurname.trim().length < 1) {
          this.$fun.alert("Registre Apellido Paterno", "warning");
          return;
        }
        if (this.person.NtpMaternalSurname.trim().length < 1) {
          this.$fun.alert("Registre Apellido Materno", "warning");
          return;
        }
  
        this.person.GenPerson.PrsDocumentNumber = this.person.GenPerson.PrsDocumentNumber.trim();
        this.person.NtpName = this.person.NtpName.toUpperCase();
        this.person.NtpPaternalSurname = this.person.NtpPaternalSurname.toUpperCase();
        this.person.NtpMaternalSurname = this.person.NtpMaternalSurname.toUpperCase();
  
        if (this.full) {
          if (!this.$fun.isValidDate(this.person.NtpBirthDate)) {
            this.$fun.alert("Fecha de Nacimiento Inválida", "warning");
            return;
          }
  
          if (this.address.PadAddress == null || this.address.PadAddress.trim().length < 2) {
            this.$fun.alert("Falta Dirección", "warning");
            return;
          }
  
          this.person.GenPerson.Telephones = [this.phone];
          this.person.GenPerson.Emails = [this.email];
          this.address.ZonID = this.ZoneID;
  
          this.address.PadCodeUbigeo = this.DistrictUbigeo;
          this.address.PadAddress = this.address.PadAddress.toUpperCase();
          this.person.GenPerson.Addresses = [this.address];
  
          if (this.person.NtpID != 0) {
            if (this.phone.PrsID == 0 || this.phone.PrsID == null) this.person.GenPerson.Telephones = [];
            if (this.email.PrsID == 0 || this.email.PrsID == null) this.person.GenPerson.Emails = [];
            if (this.address.PrsID == 0 || this.address.PrsID == null) this.person.GenPerson.Addresses = [];
          }
        } else {
          this.person.GenPerson.Telephones = [this.phone];
          this.person.GenPerson.Emails = [this.email];
          this.person.GenPerson.Addresses = [this.address];
        }
  
        let p = { ...this.person };
        // if (this.full)
        //   p.NtpBirthDate =
        //     this.person.NtpBirthDate.substr(6, 4) + "-" + this.person.NtpBirthDate.substr(3, 2) + "-" + this.person.NtpBirthDate.substr(0, 2);
  
        if (p.GenPerson.Emails[0] == null || p.GenPerson.Emails[0].PemID == null) p.GenPerson.Emails = null;
        if (p.GenPerson.Addresses[0] == null || p.GenPerson.Addresses[0].PadID == null) p.GenPerson.Addresses = null;
        if (p.GenPerson.Telephones[0] == null || p.GenPerson.Telephones[0].PpnID == null) p.GenPerson.Telephones = null;
  
        if (this.full || this.driver) {
          if (this.driver.NpdDocumentNumber.length > 0) {
            if (this.driver.TypeDocumentCategory == 0 || this.driver.TypeDocumentCategory == null) {
              this.$fun.alert("Seleccione Categoría", "warning");
              return;
            }
  
            if (!this.$fun.isValidDate(this.driver.NpdDocumentEnd)) {
              this.$fun.alert("Seleccione Fecha de Revalidación", "warning");
              return;
            }
            if (!this.$fun.isValidRangeDate(this.driver.NpdDocumentInit, this.driver.NpdDocumentEnd)) {
              this.$fun.alert("Verifique Fechas de Validez", "warning");
              return;
            }
            this.driver.SecStatus = 1;
            this.driver.NtpID = p.NtpID;
            p.GenNaturalPersonDriver = [this.driver];
            // p.GenNaturalPersonDriver = this.driver;
          } else {
            if (this.driver.NpdID > 0) {
              this.driver.SecStatus = 0;
              this.driver.NtpID = p.NtpID;
              p.GenNaturalPersonDriver = [this.driver];
              // p.GenNaturalPersonDriver = this.driver;
            }
          }
        }
        
        Service.savenaturalperson(p, this.$fun.getUserID()).then((r) => {
          this.$emit("personSaved", this.person);
          this.$emit("save", this.person);
          this.person.GenPerson.PrsDocumentNumber = "";
          this.clear();
          this.$fun.alert("Guardado Correctamente", "success");
          this.close();
        });
      },
      close() {
        this.$refs.document.focus();
        this.$emit("toclose", 2);
        this.clear();
      },
    },
    watch: {
      dataPers(newData) {
        if (newData && typeof newData === 'object') {
          this.dataPer = { ...newData };
          this.initialize();  // Llamar a initialize solo después de que los datos se hayan actualizado
        }
      },
      'person.GenPerson.PrsDocumentNumber': function(newVal) {
        this.$emit('update:dni', newVal);
        console.log("newvalue",newVal);
      },
      propPerson() {
        if (this.propPerson != null) {
          if (this.propPerson.GenPerson != null) {
            this.person = { ...this.propPerson };
            // this.person.NtpBirthDate = this.$moment(this.propPerson.NtpBirthDate).format("DD-MM-YYYY");
            this.person.NtpBirthDate = this.propPerson.NtpBirthDate;
            this.person.GenPerson.TypePersonDocument = 1;
  
            if (this.person.GenPerson.Emails.length > 0) this.email = this.person.GenPerson.Emails[0];
            if (this.person.GenPerson.Telephones.length > 0) {
              this.phone = this.person.GenPerson.Telephones[0];
            }
            if (this.person.GenPerson.Addresses.length > 0) {
              this.address = this.person.GenPerson.Addresses[0];
              this.DepartmentUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 2);
              this.ProvinceUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 4);
              this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
            }
            if (this.person.GenNaturalPersonDriver.length > 0) {
              this.driver = this.person.GenNaturalPersonDriver[0];
            }
          } else {
            this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
          }
        }
        if (this.dataPers && typeof this.dataPers === 'object') {
          this.dataPer = Object.assign({}, this.dataPers);
          this.initialize();
        }
        
      },
      DepartmentUbigeo() {
        this.ProvinceUbigeo = 0;
        this.configProvince = {
          title: "Provincia",
          url: _sGeneric.getProvince(),
          params: {
            requestID: this.$fun.getUserID(),
            DepartmentUbigeo: this.DepartmentUbigeo,
            SearchText:"",
          },
        };
      },
      ProvinceUbigeo() {
        this.DistrictUbigeo = 0;
  
        if (this.ProvinceUbigeo != 0)
          this.configDistrict = {
            title: "Distrito",
            url: _sGeneric.getDistrict(),
            params: {
              requestID: this.$fun.getUserID(),
              DistrictUbigeo: this.DepartmentUbigeo+''+this.ProvinceUbigeo,
              SearchText:"",
            },
          };
        if (this.ProvinceUbigeo == "2001" && this.editing == false) this.DistrictUbigeo = "200114";
        //if( this.DepartmentUbigeo=='20') this.DistrictUbigeo='14'; else this.DistrictUbigeo='01'
      },
    },
    created() {
      if (this.full) {
        this.configNationality = {
          title: "Nacionalidad",
          url: _sGeneric.getNationality(),
          params: { requestID: this.$fun.getUserID() },
        };
        this.configZones = {
          title: "Zonas",
          url: _sGeneric.getZones(),
          params: { requestID: this.$fun.getUserID() },
        };
        this.configDepartment = {
          title: "Departamento",
          url: _sGeneric.getDepartment(),
          params: { requestID: this.$fun.getUserID() },
        };
  
        this.configDistrict = {
          title: "Distrito",
          url: _sGeneric.getDistrict(),
          params: { requestID: this.$fun.getUserID(), DistrictUbigeo: "0" , SearchText:"",},
        };
        this.configProvince = {
          title: "Provincia",
          url: _sGeneric.getProvince(),
          params: { requestID: this.$fun.getUserID(), DepartmentUbigeo: "0",SearchText:"", },
        };
  
        this.DepartmentUbigeo = "20";
      }
        if (this.dataPers && typeof this.dataPers === 'object') {
          this.dataPer = Object.assign({}, this.dataPers);
        }
          this.initialize();
        

    },
  };
  </script>
  
<template>
	<div>
		<s-crud
			title="Marcas por Clientes"
            ref="gridCustomerBrand"
			:config="config"  
            :filter="filter"       
			add			
			edit
            add-no-function     	
			search-input
            @rowSelected="rowSelected($event)"
            @add="add()"
            @edit="edit()"
		>       
            <template v-slot:CcbState="{ row }">
				<v-chip x-small=""  :color="row.CcbState == 1 ? 'success': 'error'" >
					{{ row.CcbState == 1 ? 'Activo': 'Inactivo' }}
				</v-chip>
        	</template>

            <template v-slot:NumberBrans="{ row }">
				<v-chip x-small=""  :color="row.NumberBrans > 0 ? 'warning': 'error'" >
					{{ row.NumberBrans }}
				</v-chip>
        	</template> 
		</s-crud> 
        <v-dialog max-width="auto" v-model="openDialog" persistent v-if="openDialog" width="900">
            <customer-brand @save="save()" @close="close()" :selectGroup="selectGroup" v-model="CbdID" :CcbID="cliente"></customer-brand>
        </v-dialog> 
	</div>
</template>



<script>
	import _sFrzChamberCustomerBrandService from "@/services/FrozenProduction/FrzChamberCustomerBrandService.js";
    import CustomerBrand from "./FrzChamberCustomerBrandDetail.vue"
    
 
	export default {
        components: { CustomerBrand },
		data: () =>({

            config: {
                model: {
                    CcbID: "ID",
                    CcbState:"",
                    NumberBrans:""
                },
                service: _sFrzChamberCustomerBrandService,
                headers: [
                    {text: "ID", value: "CcbID"},
                    {text: "Descripcion", value: "CumNombre"},
                    {text: "Estado", value: "CcbState"},
                    {text: "N° Marcas", value: "NumberBrans"}
                ]
            },
            filter: { CumNombre: ''},
            openDialog: false,
            CbdID: 0,
            selectGroup: [],
            Cliente: 0,

		}),
 
		methods: {

            add()
            {              
                this.cliente = 0;
                this.selectGroup = [];
                this.openDialog = true;
                console.log('agregar');
            },
            edit(){                             
                this.openDialog = true;
            },
            close(){                
                this.openDialog = false;
                this.CbdID = '';
                this.selectGroup = [];
                this.$refs.gridCustomerBrand.refresh();
            },
            save(){
                this.$refs.gridCustomerBrand.refresh();
            },
            
            rowSelected(item){
                console.log('load',item);
                // this.cliente = 0;
                this.selectGroup = item;
                this.CbdID = this.selectGroup.length > 0 ? this.selectGroup[0].CcbID : 0;
                
                if (item[0].CcbID){
                    this.cliente = item[0].CcbID;
                    console.log('cliente',this.cliente);
                }
            },
        },
            
	}
</script>
import Service from "../Service";

const resource = "requirementpermit/";

export default {
    byUser(obj, requestID) {
        return Service.post(
            resource + "byuser", obj, {
                params: { requestID: requestID },
            }
        );
    },
    users(obj, requestID) {
        return Service.post(
            resource + "user", obj, {
                params: { requestID: requestID },
            }
        );
    },
    pagination(dtr, requestID) {
        return Service.post(
            resource + "Pagination",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },
    save(dtr, requestID) {
        return Service.post(
            resource + "save",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },
    copyallPermit(usr, usrs, requestID) {
        return Service.post(resource + "copyallPermit", usrs, {
          params: { usrID: usr.UsrID, requestID: requestID },
        });
    },
};
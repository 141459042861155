<template  scope="props">
    <v-container>
		<s-toolbar close   
							no-border  
							label="Induccion - Carga Masivos" 
							@close="$emit('EndProcess', null)"
							dark
							color="#2955c8"							
						>
						</s-toolbar>
		<v-tabs v-model="currentItem">
			<v-tab href="#DataLoaded"> Datos Cargados</v-tab>
			<v-tab href="#DataDeleted"> Datos Eliminados</v-tab>
		</v-tabs>

		<v-tabs-items v-model="currentItem">
				<v-tab-item :value="'DataLoaded'"> 
                   <v-row style="height :'700px'" >
						<v-col >
													
							<v-card >			
								<v-col class="s-col-form">
									<v-row>
									<v-col >
										<s-import-excel
											@changeData="changeFile($event)"
											:headers = "headers"
											@loaded	="saveMassive($event)"
											:validateRow="validatePerson"						
										></s-import-excel>
									</v-col>
									</v-row>
								</v-col>
							</v-card>														
						</v-col>
        			</v-row>
                </v-tab-item>

				<v-tab-item :value="'DataDeleted'"> 
					<template>
						<div>
						<!--<v-data-table dense :headers="headers" :items="rowsRemoved" />-->
						<v-data-table 
							:headers="headerDetail" 
							:items="rowsRemoved" 
							color="success"
							no-data-text="Sin Datos"
							>
							<template v-slot:[`item.DNI`]="{ item }">
								<v-chip 
									small 
									:color="Date.parse(item.FECHA_RITSS) ? '#B71C1C' : ''" 
									:text-color="Date.parse(item.FECHA_RITSS) ? 'white' : ''" 
									>
									{{ item.DNI }}
								</v-chip>
							</template>
							<template v-slot:[`item.FECHA_RITSS`]="{ item }">
								<v-chip 
									small 
									:color="!Date.parse(item.FECHA_RITSS) ? '#B71C1C' : ''"
									:text-color="!Date.parse(item.FECHA_RITSS) ? 'white' : ''" 
									>
									{{ item.FECHA_RITSS }}
								</v-chip>
							</template>
           				 </v-data-table>
						</div>
          			</template>
                </v-tab-item>
		</v-tabs-items>

	
	
        
	</v-container>
</template>

<script>
	
    import _sInstruction from '@/services/Management/InstructionListService.js';
    import _sDefinition from "@/services/General/DefinitionService.js";
	import _sPerson from '@/services/General/PersonService';


	export default {
		components: {
			
		},

		props: {
			chamberSelected: null
		},

		data() {
            
			return {
				areVisit:0,
				CmpID: 0,
				TypeInstruction: 0,
				motivoVisit:"",
				EncargadoName:"",
				DocEn:"",
				IDEnc:0,
				config: {
					model: {
						//PltID: "ID",
						//CocChange: ""
                        //TypeCompany
					},
					service: _sInstruction,
					headers: [
						
					]
				},
				filter:{
				},
				pltID: 0,
				areaId:0,
				PersonNoSave: false,
				dialogChange: false,
				modelDetail:{
					DNI : "ID",
					APELLIDOS_NOMBRES: "string",
					FECHA_RITSS_SISTEMA: "date",
					FECHA_RITSS: "date",
					OBSERVACION: "string"
				},
				headerDetail:[
					{ text: "DNI", 					value: "DNI", 					width: 60 },
					{ text: "APELLIDOS_NOMBRES", 	value: "APELLIDOS_NOMBRES", 	width: 60 },
					{ text: "FECHA_RITSS_SISTEMA", 	value: "FECHA_RITSS_SISTEMA", 	width: 60 },
					{ text: "FECHA_RITSS", 			value: "FECHA_RITSS", 			width: 60 },
					{ text: "OBSERVACION", 			value: "OBSERVACION", 			width: 60 },					
					
				],
				itemsDataDetail: [],
                headers: [
					{
						column	: 	"DNI",
						value	: 	"DNI",
						text 	: 	"DNI",
					},
					{
						column	: 	"APELLIDOS_NOMBRES",
						value	: 	"APELLIDOS_NOMBRES",
						text 	: 	"APELLIDOS_NOMBRES"
					},		
					{
						column	: 	"FECHA_RITSS_SISTEMA",
						value	: 	"FECHA_RITSS_SISTEMA",
						text 	: 	"FECHA_RITSS_SISTEMA",
						date	: 	true
					},
					{
						column	: 	"FECHA_RITSS",
						value	: 	"FECHA_RITSS",
						text 	: 	"FECHA_RITSS",
						date	: 	true
					},		
					{
						column	: 	"OBSERVACION",
						value	: 	"OBSERVACION",
						text 	: 	"OBSERVACION"
					},
					
				
				 ],
                inductions: [],
                documents: [],
                itemsCompany: [],
				person: [],
				rowsRemoved:[],
				currentItem:null,
				collection: [{ a: 1, b: 2 }],
			}
		},

		methods: {
            
            saveMassive(items){
                let rpta=  "";
                let collection = [];
				let instr;
                console.log("Items" , items);
				for (const element of items) {
				//items.forEach(element => {		
					_sInstruction.search(element.DNI,element.FECHA_RITSS_SISTEMA,element.FECHA_RITSS, this.$fun.getUserID()).then((r) => {
						//console.log("pruebaa busqueda11" ,r.data);
						let data = r.data;
						let per = this.person.find((pers) =>  (pers.GenPerson.PrsDocumentNumber).toString() === (element.DNI).toString() );
						if(per) {
							collection.push({
							InsID			: data ? data.InsID : 0,
							CmpID 			: 0,
							InsDate			: element.FECHA_RITSS,
							InsObservation	: element.OBSERVACION,
							SecStatus 		: 1,
							TypeInstruction	: 0,//1
							UsrCreateID		: this.$fun.getUserID(),
							UsrUpdateID		: this.$fun.getUserID(),
							MngInstructionAttendance: data ? null: [{
								IasDocumentNumber 	:  per.GenPerson.PrsDocumentNumber,
								NtpFullName			: `${per.NtpPaternalSurname} ${per.NtpMaternalSurname} ${per.NtpName}`,
								NtpID 				:  per.NtpID,
								WkrID 				:  per.WkrID,
								CtrID 				:  per.CtrID,
								AreID 				:  0,
								TypeWorker 			:  0,
								UsrCreateID			:  this.$fun.getUserID()
							}]
							})
						};
					
					});														
				
					
				}
			
				console.log("Seguro de Guardar",collection);
				console.log("Filas eliminadas", this.rowsRemoved);
                this.$fun.alert("Seguro de Guardar?", "question").then((val) => {
					if (val.value) {	
						console.log("Seguro de Guardar",collection);
						
						   _sInstruction.massiveSave(collection,this.$fun.getUserID()).then((r) => {
						 	if(r.status == 200) {
						 	this.$fun.alert("Agregados Correctamente", "success");
						 	this.clear();
						 	this.$emit('EndProcess', r.status);		
						 	this.$emit('EndProcess', collection);
						 	} 
						 	else { 
						 	this.$fun.alert("Ocurrio un error a realizar la carga", "error");
						 	this.$emit('EndProcess', null);								
						 	}	
							
						 	})
					}
				})		
            },
			removeTransportista(row){
				console.log("Fila eliminada",row);
			},
            changeFile(data){        
				this.documents = data;
				console.log('documento', this.documents)	       
				console.log("Datos anterirors person" , this.person)	
				this.person = this.getPerson(this.documents);
				console.log("Datos posteriores person" , this.person)	
				//this.inputData(this.person);
            },
			
			validatePerson(row){
				let per = this.person.find(x=>x.GenPerson.PrsDocumentNumber==row.DNI)
			
				return per ;
			},

			
			getPerson(documents){
				let pers = [];
				this.rowsRemoved = [];
				documents.forEach(element => {
					_sPerson
						.getNaturalPerson(element.DNI, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								//console.log('sunexpert ', resp.data);
								if(resp.data != null &&  Date.parse(element.FECHA_RITSS)){
									let data = resp.data;
									
									pers.push(data);									
								}
								else{
									element.FECHA_RITSS 		= this.$moment(new Date((element.FECHA_RITSS - (25567 + 1)) * 86400 * 1000)).format("YYYY-MM-DD");
									element.FECHA_RITSS_SISTEMA = this.$moment(new Date((element.FECHA_RITSS_SISTEMA - (25567 + 1)) * 86400 * 1000)).format("YYYY-MM-DD");									
									this.rowsRemoved.push(element);

									//  // Aquí comprobamos si un elemento con el mismo DNI ya está en `rowsRemoved`
									// const existe = this.rowsRemoved.some(item => item.DNI === element.DNI);
									
									// if (!existe) {
									// 	this.rowsRemoved.push(element);
									// 	console.log("La nueva colección de Datos no Cargados es: ", this.rowsRemoved);
									// } else {
									// 	console.log(element.DNI + " ya existe en la colección de locaciones.");
									// }
									
								}
							}
						});
				});
				return pers;
			},

			clear(){
				this.person = [];
				this.documents = [];
				this.rowsRemoved = [];
			},			            			

		},

		
	}
</script>

<template>
    <div>
      <v-row>
        <v-col >
          <s-crud
            title="Impresion de Etiquetas"
            @save="save($event)"
            :config="config"
            add
            :filter="filter"
            edit
            remove
            ref="gridPrintLabel"
            @rowSelected="rowSelected($event)" 
            searchInput
          >
          <!--<template v-slot:HvsState="{ row }">
							<v-chip
								x-small
								:color="row.HvsState == 1 ? 'info' : 'error'"
								>{{
									row.HvsState == 1
										? "Recepcionado"
										: "Sin Recepcionar"
								}}</v-chip
							>
						</template>-->
         
          <template v-slot:filter>
					<v-container>
						<v-row style="margin-left: 5px" justify="center">

							<v-col lg="3" >
								<s-select-definition
									clearable
									:def="1230"
									label="Planta Procesadora"
									v-model="filter.TypeProcessingPlant"
								/>
							</v-col>

                            <v-col lg="3" >
                                <s-select-definition :def="1181" label="Destino" v-model="filter.TypeDestiny"></s-select-definition>
                            </v-col>

                            <v-col lg="3" >
                                <s-select-definition :def="1269" label="Línea Proceso" v-model="filter.TypeLineProcess"></s-select-definition>
                            </v-col>

							<!--<v-col cols="6" md="3" lg="2">
								<s-date
									label="Fecha Inicio"
									v-model="filter.cDateInitial"
								></s-date>
							</v-col>
							<v-col cols="6" md="3" lg="2">
								<s-date
									label="Fecha Fin"
									v-model="filter.cDateFin"
								></s-date>
							</v-col>-->
							
						</v-row>
					</v-container>
				</template>
            <template
								v-slot:RcfCode="{ row }"
							>
								<v-chip
									style="margin: 0px"
									small
									:color="''"
								>
									LP-{{ row.RcfID + "-" + row.RcfCode }}
								</v-chip>
						</template>
            <template scope="props">
              <v-container v-if="props.item != null">
                <v-row>
                  <!--<v-col lg="6" class="s-col-form">
                    <s-select-supervice full label="Empacadora" :position="1" return-object v-model="objEmpaquetadora" >
                    </s-select-supervice>
                  </v-col>
                 <v-col lg="3" class="s-col-form" >
                    <s-select-turn label="Turno" v-model="props.item.TrnID">
  
                    </s-select-turn> 
                     <s-select-definition label="Turno" :def="1278"></s-select-definition> 
                   </v-col>-->
                  <v-col lg="3" class="s-col-form" >
                    <s-text number label="Cantidad" v-model="props.item.Quantity"></s-text>
                  </v-col>
                  
                </v-row>
              </v-container>
            </template>
          </s-crud>
        </v-col>
      </v-row>
    </div>
  </template>

<script>
import _sListPrintLabeL from "@/services/FreshProduction/PrfListPrintLabelService";
import SSelectSupervice from '../../../components/HarvestSchedule/SSelectSupervice.vue';
import sSelectTurn from '@/components/FreshProduction/Turn/sSelectTurn';
export default {
  components: {SSelectSupervice, sSelectTurn},
  data: () => ({
    filter: {
        TypeDestiny: 1,
        TypeProcessingPlant: 1,
        TypeLineProcess: 1
	},
    config: {
      model: {
        RcfID: "ID",
        SpDate: "datetime",
        LppWeightDifference: "decimal",
        RcfCode: "string"
      },
      service: _sListPrintLabeL,
      headers: [
            {   text: "ID"              , value: "RcfID"                , width: 50     },
            {   text: "F. Abast."       , value: "SpdDate"              , width: 50     },
            {   text: "Lote Produccion" , value: "RcfCode"              , width: 50     },
            {   text: "Procedencia"     , value: "TypeZonLocationName"  , width: 50    },
            {   text: "Certificación"   , value: "TcDescription"        , width: 50    },
            {   text: "Tipo Cultivo"    , value: "TypeCropName"         , width: 50    },
            {   text: "Variedad"        , value: "VrtName"              , width: 50    },
            {   text: "Peso"            , value: "PldWeight"            , width: 50    },
          //  {   text: "KG Disponibles"  , value: "LppWeightDifference"  , width: 60     },
        ],
      selected: {},
    },
    
  }),
  methods: {
    Initialize() {},
    save(item){
      let printLabel ={
        mensaje :"prueba 1"
      }
      console.log("item",item);
        _sListPrintLabeL.printLabel(item,this.$fun.getUserID()).then((x)=>{
          console.log("x",x);
        });

      //item.WkrID=objEmpaquetadora.NtpSuperviceID
      //if(this.validationForm(item))
     // item.save();
    },
    validationForm(){
      return true;
    },
    rowSelected(item){
        console.log("fila seleccionado",item);
       
    }
  },
};
</script>
import Service from "../Service";

const resource = "greenpallet/";

export default {

    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },
 
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    getSelectionPallet(obj, requestID) {
        return Service.post(resource + "getpalletsselection", obj, {
            params: { requestID: requestID },
        });
    },
    sendPalletMaturation(obj, requestID) {
        return Service.post(resource + "sendpalletselection", obj, {
            params: { requestID: requestID },
        });
    },

    GetDetailQR(LlpID, requestID) {
        return Service.post(resource + "getdetailqrselection", "", {
            params: { LlpID: LlpID, requestID: requestID },
        });
    },

    updateQuantitysJaba(obj, requestID) {
        return Service.post(resource + "updatequantitysjaba", obj, {
            params: { requestID: requestID },
        });
    }
}
<template
  ><div>
      <v-label ><b>{{label}}</b></v-label>
    <s-select-generic
      autocomplete  class="text"
      itemtext="GenDescription"
      itemvalue="GenID"
      id="GenDescription"
      :value="value"
      @input="input($event)"
      @change="change($event)"
      :config="config"
      :full="full"
      :text="text" :clearable=clearable :noDefault=noDefault
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sGenericService from "./../../services/General/GenericService";
export default {
  name: "SelectPosition",
  components: {},
  props: {
     clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    noDefault: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
 
    value(value) {
      this.item = value;
    },
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    change(item) {
      this.$emit("change", item);
    },
    },
    data: () => ({
        item: 0,
        val:'',
        items: [],
        config: {},
    }),
  
    created() {
        this.config = {
            url:_sGenericService.getUrlPosition(),
            title: this.label,
                params: {search:'',requestID:this.$fun.getUserID() }
        }
       

    }

  }

</script>

import Service from "../Service";
import Vue from "vue";
const resource = "/ProgrammingStorage/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    GetDetails(obj, requestID) {

        if (obj == null) {
            obj.cProgrammingStorageID = 0;
        }
        return Service.post(resource + "GetRecord", obj, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    duplicate(obj, requestID) {
        return Service.post(resource + "duplicate", obj, {
            params: { requestID: requestID },
        });
    },

    delete(obj, requestID) {
        return Service.post(resource + "delete", obj, {
            params: { requestID: requestID },
        });
    },

};
import Service from "../Service";
const resource = "slesaleprogramdetail/";

export default {

    list(SpgID, requestID) {
        return Service.post(resource + "list", {}, {
            params: { SpgID, requestID },
        });
    },

}
<template>
	<div>
		<v-row justify="center">
			<v-col align="center" cols="12" lg="8" md="12" v-if="cameraIcon == false">
				<i
					class="fas fa-camera"
					style="font-size: 35px; cursor: pointer"
					@click="dialogCamera = true"
				></i>
			</v-col>
			<v-col  cols="12" lg="8" md="12">
			
				<!-- <s-text
					label="Scannear QR"
					ref="textQR"
					v-model="param.CodeID"
					:autofocus="autofocus"
					@keyupEnter="toggleSaveData(param.CodeID)"
					:readonly="readonly"
					@input="input($event)"
				>
				</s-text> -->
				<v-text-field
					:label="label"
					ref="textQR"
					v-model="param.CodeID"
					:autofocus="autofocus"
					@keyupEnter="toggleSaveData(param.CodeID)"
					:readonly="readonly"
					@input="input($event)"
					append-icon="mdi-send"					 
					@click:append="toggleSaveData(param.CodeID)"
					variant="outlined"
					v-on:keyup.enter="toggleSaveData(param.CodeID)"
					 
					:prepend-icon="'mdi-camera-account'"
					@click:prepend="dialogCamera = true"
				>
				</v-text-field> 
				<!-- :append-icon="cameraIcon ? '': 'mdi-send'"		 -->
			</v-col>
		</v-row>

		<!-- MODAL PARA CAMARA @keypress="validation($event)"-->
		<v-dialog v-if="dialogCamera" v-model="dialogCamera" max-width="700">
			<v-card>
				<v-col>
					<qrcode-stream @decode="onDecode" @init="onInit" />
				</v-col>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { QrcodeStream } from "vue-qrcode-reader";

	export default {
		name: "sScannerQr",
		components: { QrcodeStream },
		props: {
			value: { default: 0 },
			autofocus: {
				type: Boolean,
				default: true
			},
			isValidation: {
				type: Number,
				default: 0
			},
			noCamera: {
				type: Boolean,
				default: false
			},

			config: { type: Object, default: null },
			readonly: { type: Boolean, default: true },
			clearInput: { type: Boolean, default: false },
			cameraIcon: { type: Boolean, default: false },
			label: { type: String, default: 'Scannear QR' },
		},

		data: () => ({
			respon: {},
			cuter: false,
		}),
		created() {},
		watch: {
			clearInput()
			{
				console.log('limpieza', this.clearInput);
				if(this.clearInput)
				{
					/* this.param.CodeID = ''; */
				}
				
			}
		},
		methods: {
			onDecode(result) {
				this.param.CodeID = result;
				this.respon = result;
				
				var obj = this.param.CodeID.split(",", 8);				
				this.toggleSaveData(obj[0]);
				this.param.CodeID = obj[0];				
				this.dialogCamera = false;

				
			},

			input(item)
			{
				this.$emit("input", item);
				if(item.length > 10)
				{
					this.cuter = true;
					/* this.param.CodeID = item;
					this.respon = item;

					var obj = this.param.CodeID.split(",", 8);
					this.toggleSaveData(obj[0]);
					this.param.CodeID = obj[0];
					this.dialogCamera = false;
					this.param.CodeID = ''; */
				}
			},

			async onInit(promise) {
				try {
					await promise;
				} catch (error) {
					if (error.name === "NotAllowedError") {
						this.error =
							"ERROR: you need to grant camera access permisson";
					} else if (error.name === "NotFoundError") {
						this.error = "ERROR: no camera on this device";
					} else if (error.name === "NotSupportedError") {
						this.error =
							"ERROR: secure context required (HTTPS, localhost)";
					} else if (error.name === "NotReadableError") {
						this.error = "ERROR: is the camera already in use?";
					} else if (error.name === "OverconstrainedError") {
						this.error = "ERROR: installed cameras are not suitable";
					} else if (error.name === "StreamApiNotSupportedError") {
						this.error =
							"ERROR: Stream API is not supported in this browser";
					}
				}
			},

			clickCamera() {
				this.dialogCamera = true;
			},

			validation(evt) {
				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57)) {
					evt.preventDefault();
				} else {
					return true;
				}
			},

			focus() {
				this.$refs.textQR.focus();
			},

			toggleSaveData(value) {
				if(this.cuter)
				{
					let oldValue = value;
					let valueArray = oldValue.split(",", 8);
					value = valueArray[0];
				}

				
				if (value == null) return;

				if (value !== null) {
					if (this.config.service == undefined) {						
						this.$emit("onValue", value);
						this.$emit("onData", this.respon);
						/* this.param.CodeID = ''; */
					} else {
						this.config.service
							.getDataQR({ CodeID: value }, this.$fun.getUserID())
							.then(response => {
								if (response.status == 200) {
									if (response.data != null) {
										console.log('ingresa aca retur');
										this.$emit("returnObj", response.data);
									}
								}
							});
					}
					//
				}
				this.param.CodeID = "";
				this.$emit('keyupEnter')
			}
		},
		data: () => ({
			dialogCamera: false,
			color: "success",
			param: { CodeID: "" }
		})
	};
</script>
<style scoped>
	.error {
		font-weight: bold;
		color: red;
	}
</style>

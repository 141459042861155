<template>
  <div>

    <regist-data></regist-data>
    
  </div>
</template>

<script>
import _sProcessRegistrationService from "@/services/FrozenProduction/ProcessRegistrationService";
import RegistData from "@/views/FrozenProduction/Process/RegistData";

export default {
  components: {
    RegistData,
  },
  data: () => ({
    isEditProc : false,
    itemsData: {},
    ModalRegist: false,
    ftmDescription: "",
    dedValue: 0,
    filter: { BeginDate: null, endDate: null, FprTypeProcess: null},
    config: {
      service: _sProcessRegistrationService,
      model: {
        FprID: "ID",
        FprDate:"date",
        edited : "",
        // FrpDateBegin: "date",
        // FrpDateEnd: "date",
      },
      headers: [
        { text: "Editar", value : "edited", width: "10%", sorteable: true},
        {
          text: "ID",
          value: "FprID",
          width: "5%",
          align: "begin",
          sorteable: true,
        },
        { text: "Fecha", value: "FprDate", width: "10%", sorteable: true },
        { text: "Hora Inicio", value: "FprHourBegin", width: "10%", sorteable: true },
        { text: "Hora Fin", value: "FprHourEnd", width: "10%", sorteable: true },
        { text: "Materia P Movida", value: "FprMovedRawMaterial", width: "10%", sorteable: true },
        { text: "Materia P Movida Acu", value: "FprMovedRawMaterialAcu", width: "10%", sorteable: true },
        { text: "Materia P Procesada", value: "FprProcessRawMaterial", width: "10%", sorteable: true },
        { text: "Materia P Procesada Acu", value: "FprProcessRawMaterialAcu", width: "10%", sorteable: true },
        {
          text: "Tipo de proceso",
          value: "TypeProcessName",
          width: "15%",
          sorteable: false,
        },
        {
          text: "Variedad",
          value: "VrtName",
          width: "5%",
          align: "center",
          sorteable: false,
        },

        {
          text: "Cultivo",
          value: "TypeCropName",
          width: "5%",
          align: "center",
          sorteable: false,
        },
        {
          text: "Tipo cultivo",
          value: "TypeCultiveName",
          width: "5%",
          align: "center",
          sorteable: false,
        },
      ],
    },
    clickSave: false,
  }),

  methods: {
    endSave()
    {
      this.clickSave = false;
      this.$refs.dataProcess.refresh();
    },

    save()
    {
      this.clickSave = true;
    },

    rowSelected(items) {},

    openModal() {
      console.log("quiero abrir el modal");
      this.ModalRegist = true;
      this.isEditProc = false;
      console.log(this.ModalRegist);
    },

    closeModel() {
      this.$refs.dataProcess.refresh();
      this.ModalRegist = false;
      
      // this.$refs.crudemb.refresh();
    },

    editRegist(item){
      console.log('Registro a editar', item);
      this.itemsData = item;
      this.isEditProc = true;
      this.ModalRegist = true;
    },
    

    saveDed(item) {},
  },
};
</script>
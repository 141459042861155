import Service from "../Service";
const resource = "slesaleprogram/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

    list(SpgWeek, requestID) {
        return Service.post(resource + "list", {}, {
            params: { SpgWeek, requestID },
        });
    },

    save(Prw, requestID) {
        return Service.post(resource + "save", Prw, {
            params: { requestID }
        });
    },


}
import Service from "../Service";
import Vue from "vue";
const resource = "albaransap/"

export default {

    save(obj, requestID) {
        return Service.post(resource + "Save", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(obj, requestID) {
        return Service.post(resource + "pagination", obj, {
            params: { requestID: requestID }
        });
    },

    GetAlbaran(obj, requestID) {
        return Service.post(resource + "GetAlbaran", obj, {
            params: { requestID: requestID },
        });
    },

    GetCaliber(obj, requestID) {
        return Service.post(resource + "GetCaliber", obj, {
            params: { requestID: requestID },
        });
    },

    anulateAlbaran(obj, requestID) {
        return Service.post(resource + "AnulateSapAlbaran", obj, {
            params: { requestID: requestID },
        });
    },
    
    loadsapalbaran(alb, UsrID, requestID) {
        return Service.post(resource + 'loadsapalbaran', alb, {
            params: { UssUserID: UsrID, requestID }
        });
    },
}
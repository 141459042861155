<template>
	<div>
        <v-dialog v-model="showEdit" fullscreen persistent v-if="showEdit">
			<contract-add ref="add" :objEdit="item" @close="closeEdit()" v-if="showAdd"/>
			<!-- <contract-edit ref="edit" :objEdit="item" @close="closeEdit()" v-if="showEdit" /> -->
			<!-- <v-card>
				<v-toolbar
					dense
					color="primary"
					height="40"
					dark
					label="Nuevo Contrato">
					<h3>Creación de Contrato</h3>
					<v-spacer></v-spacer> -->
					<!--<div v-if="item">
						<v-btn
							v-if="item.HvsStatus == 1"
							fab
							class="elevation-0 mr-3"
							small
							color="primary"
							dark
							@click="$refs.edit.save()"
							><v-icon>mdi-content-save</v-icon></v-btn
						>
					</div>
					<v-btn
						v-else
						fab
						class="elevation-0 mr-3"
						small
						color="primary"
						dark
						@click="$refs.edit.save()"
						><v-icon>mdi-content-save</v-icon></v-btn
					>-->
					<!-- <v-btn
						fab
						class="elevation-0"
						small
						color="primary"
						dark
						@click="showEdit = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-row>
					<v-col cols="12" sm="12" lg="12">
					 -->
						<!-- <v-row>
							<v-col cols="12" sm="12" lg="12"> -->
							<!-- <ContractEdit
								ref="edit"
								:objEdit="item"
								@close="closeEdit()"
							/> -->
							<!-- </v-col>
						</v-row> -->
				
				<!-- </v-col>
			</v-row>
			</v-card> -->
		</v-dialog>

		<s-crud
		    edit
			title="CONTRATO"
			:config="config"
			:filter="filter"
            @add="addContract()"
			add-no-function
			add
			@edit="editHvs($event)"
			@save="save($event)"
			ref="gridAgreement"
            @rowSelected="rowSelected($event)" 
			searchInput
		>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col>
							<s-date
								label="Fecha Inicio" 
								v-model="filter.AgtBeginDate" 
							></s-date>
						</v-col>
						<v-col>
							<s-date
								label="Fecha Fin" 
								v-model="filter.AgtEndDate" 
							></s-date>
						</v-col>
                        <v-col>
							<s-select-definition
								label="Estado"
								:def="1479"
								v-model="filter.AgtStatus"
								clearable
							></s-select-definition>
						</v-col>
                        <v-col>
							<s-select-definition
								label="Cat. Ocupacional"
								:def="1039"
                                :dgr="4"
								v-model="filter.TypeOccupationalCategory"
								clearable
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:options>
			</template>

			<template v-slot:Status="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.CtrStatus==1?'success':'warning'"
				>
					{{ row.HmnEstado }}
				</v-chip>
			</template>
			<template v-slot:DisProDep="{ row }">
			
				{{ row.DisName }} - {{ row.ProName }} - {{ row.DepName }}
			
			</template>
		</s-crud>

	</div>
</template>

<script>
	import _sContractService from '@/services/HumanResource/HmnContractService.js';
	import sTextarea from '@/components/Utils/sTextarea.vue';
    import ContractAdd from "@/views/HumanResource/ContractAdd.vue";
	import ContractEdit from "@/views/HumanResource/ContractEdit.vue";

	export default {
  	components: { sTextarea, ContractAdd, ContractEdit},
		data() {
			return {
                showEdit: false,
				filter: {
					PrsID: 0,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0,
					//AgtStatus: 1,
					//TypeOccupationalCategory:NULL,
				},
				config: {
					model: {
						AgtID: "ID",
						NtpBirthDate: "date",
						Action: "Action",
                        AgtStatus:"",
						DisProDep:""
					},
					service: _sContractService,
					headers: [
						{text: "ID"									, value: "AgtID"},
						{text: "N° Doc"								, value: "PrsDocumentNumber"},
						{text: "Nombres y Apellidos Completos"		, value: "NtpFullName"},
						{text: "Fec. Nacimiento"					, value: "NtpBirthDate"},
						{text: "Dirección"							, value: "PadAddress"},
						{text: "Referencia"							, value: "PadAddressReference"},
						{text: "Distrito-Provincia-Departamento"	, value: "DisProDep"		 },
						// {text: "Telefono"							, value: "PpnNumberPhone"},
						// {text: "Correo"								, value: "PemEmail"		 },
						// {text: "Cat. Ocupacional"					, value: "TIPOTRAJADOR"	 },
                        // {text: "Estado"								, value: "AgtStatus"	 }
					]
				},
				
			}
		},
        watch : {
			filter(){
				console.log(this.filter)
			}
		},
		methods: {
			initialize()
			{
			},

			save(item) {
				
			},
			rowSelected(item) {
				if(item !== null){
					if(item.length > 0){
						console.log('ssss ', item);
					}					
				}					
			},
			addContract(){
				this.showAdd=true;
				this.showEdit=true;
			},
            editHvs(item) {
				this.showEdit = true;
				this.item = item;
			},
			closeEdit() {
				this.showEdit = false;
				this.$refs.gridAgreement.refresh();
			},
		},

		created () {
			this.filter.AreID =  this.$fun.getUserInfo().CtrContract.AreID ?? 0;
			
			this.initialize();
		},
		mounted() {
			
			this.filter.PrsID =  this.$fun.getUserInfo().PrsID; 
					
			console.log( "USER INFO",this.$fun.getUserInfo()); 
		}
	}
</script>

<style>
	.scrollable {
		max-height: 450px; /* Ajusta según sea necesario */
		overflow-y: auto;  /* Habilita el scroll vertical */
	}
</style>
<template>
	<div>
		<s-crud
			title="Configuración Epps"
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
			ref="gridConfigEpps"
            @rowSelected="rowSelected($event)" 
			searchInput
		>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col class="s-col-form ma-3" lg="4" md="4" sm="6" xs="12">
                            <s-select-position
                            label="Cargo"
                            v-model="filter.PstID"
                            ></s-select-position>
                        </v-col> 
					</v-row>
				</v-container>
			</template>

			<template scope="props">
				<v-container>
					<v-row justify="center">
                        <v-col class="s-col-form" lg="4" md="4" sm="6" xs="12">
                            <!-- <s-text v-if="Type == 3" :maxlength="100" autofocus label="Descripción" ref="DerDescription" v-model="props.item.ArtName"  /> -->
                            <s-article-search   v-model="article"  :placeholder="props.item.ArtName"  />
                        </v-col>
                        <v-col class="s-col-form" lg="4" md="4" sm="6" xs="12">
                            <s-select-position
                            label="Cargo"
                            v-model="props.item.PstID"
                            :text="props.item.PstName"
                            ></s-select-position>
                        </v-col>
					
					</v-row>
				</v-container>
			</template>

			<template v-slot:options>
			</template>

		</s-crud>

	</div>
</template>

<script>
	import _sCttoService from '@/services/HumanResource/HmnConfigSegurityEqService.js';
    import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";
import { isUndefined } from 'lodash';

	export default {
        components: { sArticleSearch,  },
		data() {
			return {
                ArtCode:null,
                article: null,
				selected : {},
				filter: {
					PrsID: 0,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0
				},
				config: {
					model: {
                        CseID: "ID",
						Action: "Action",
					},
					service: _sCttoService,
					headers: [
						{text: "ID", value: "CseID"},
						{text: "Cargo", value: "PstName"},
						{text: "Articulo", value: "ArtName"}
					]
				},
				
			}
		},
        watch : {
			filter(){
				console.log(this.filter)
			},
            article() {
                if (this.article && this.article.ArtCode) {
                    this.ArtCode = this.article.ArtCode;
                    console.log("articleCode", this.ArtCode);   
                }
           },
		},
		methods: {
			initialize()
			{
			},
			save(item) {
				let obj = {
					CseID: item.CseID,
					ArtCode:this.ArtCode,
					PstID: item.PstID,
					SecStatus: 1,
					UsrCreateID: this.$fun.getUserID(),
				}
				_sCttoService.save(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Agreado correctamente", "success");
						this.$refs.gridConfigEpps.refresh();  
					}
				});
			},
            rowSelected(items)
			{
				if(items.length > 0)
				{
					this.selected = items[0];
                    this.article=null;
                    this.ArtCode=items[0].ArtCode;
                    console.log("rowCode",this.ArtCode);
				}
			}
		},
	}
</script>
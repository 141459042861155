<template>
  <div>
  <v-row>
    <v-col>
      <s-crud
        :single-row="false"
        add
        addNoFunction
        @add="addWorker()"
        searchInput
        @rowSelected="selectedWorker($event)"
        ref="gridWorker"
        title="Trabajadores"
        :config="config"
        :filter="filterWorker"
        @doubleClick="assignWorker($event)"
      >
        <template v-slot:filter>
          <v-container>
            <v-row justify="center"
              ><v-col lg="6"> <s-select-area v-model="filterWorker.AreID" label="Área"/></v-col
            ></v-row>
          </v-container>
        </template>
      </s-crud>
    </v-col>
    <v-col>
      <s-toolbar
        label="Impresión Fotocheck"
        remove
        :search="false"
        print
        @print="printEvent()"
        
        @removed="removeEvent($event)"
      ></s-toolbar>
      <v-card>
        <v-data-table
          dense
          @click:row="rowClick"
          selectable-key="isSelectable"
          v-model="selected"
          :headers="configPanel.headers"
          item-key="PrsDocumentNumber"
          :items="itemsCard"
        ></v-data-table>
      </v-card>
      <v-progress-circular 
        v-if="loading"
        indeterminate
        :size="50"
        color="primary"
      ></v-progress-circular>
    </v-col>

    <v-col cols="12">
      <img  height="200" alt="Image preview..."  :src="personImage" >
    </v-col>
  </v-row>
  
  <v-dialog v-model="showDiaglog" max-width="80%" persistent>
    <template v-if = "showDiaglog">
      <fotoCheckPdf 
              :itemsCard="itemsCard" 
              :listImage="listImage" 
              @close="close" 
          />
    </template>
  </v-dialog>
  </div>
</template>

<script>
import _sCardService from "../../services/HumanResource/CardService";
import _sHelper from "@/services/HelperService";
import fotoCheckPdf from "../HumanResource/FotoCheckPdf.vue";
import _sGenParam from "@/services/General/ParameterService";

export default {

  components: { fotoCheckPdf },
  data() {
    return {
      selected: [],
      itemsCard: [],
      selectionBefore: [],
      filterWorker: { AreID: 0 },
      listImage:[],
      personImage: null,
      showDiaglog:false,
      config: {
        service: _sCardService,
        model: {
          PrsDocumentNumber: "ID",
        },
        headers: [
          { text: "Documento", value: "PrsDocumentNumber", sortable: false },
          { text: "Trabajador", value: "WorkerName", sortable: true },
          { text: "Versión", value: "CrdVersion", sortable: true },
          { text: "Código", value: "CrdCode", sortable: true },
          { text: "Area", value: "AreName", sortable: true },
        ],
      },
      configPanel: {
        headers: [
          { text: "ID", value: "CrdID", sortable: false },
          { text: "Documento", value: "PrsDocumentNumber", sortable: false },
          { text: "Trabajador", value: "WorkerName", sortable: true },
        ],
      },
      loading: false,
      fotocheckPdf: 0,
    };
  },
  methods: {
    addWorker() {
      console.log(this.selectionBefore);
      this.selectionBefore.forEach((element) => {
        console.log(element);
        if (this.itemsCard.find((x) => x == element) == null) this.itemsCard.push(element);
      });
    },
    selectedWorker(items) {
      this.selectionBefore = items;
    },

    getParam(){
      _sGenParam
					.search(
						{ PrmName: "paramPdfFotoCheck" },
						this.$fun.getUserID()
					)
					.then((resp) => {
						if (resp.status == 200) {
							this.fotocheckPdf = resp.data.PrmValue;
							console.log("this.milimetrosSpacing", this.fotocheckPdf)
						}
					});

    },
    
    printEvent() {

     console.log("Param",this.fotocheckPdf);

      if(this.fotocheckPdf == 1){
        console.log("Items",this.itemsCard);
        this.loading=true;
        this.itemsCard.forEach((x) => {
          this.getImages(x.PrsDocumentNumber);
          console.log("Listado IMG",this.listImage);
        });
      }
      else{
        const preview = document.querySelector('img');
      
        const reader = new FileReader();
        reader.addEventListener("load", function () {
          // convierte la imagen a una cadena en base64
          preview.src = reader.result;
        }, false);

        
        this.$fun.alert('Seguro de Imprimir?','question').then((r)=>{
          if(r.value)
          _sCardService.save(this.itemsCard).then((data) => {
            console.log('aaaaaaaa ', data.data);
            //reader.readAsDataURL(data);
            this.$fun.downloadFile(data.data, this.$const.TypeFile.PDF, "Fotocheck");
            this.itemsCard = [];
            this.$refs.gridWorker.refresh();
          });
        })
      }
    },
    removeEvent(value) {
      if (this.selected.length == 1) {
        this.itemsCard = this.itemsCard.filter((x) => x != this.selected[0]);
      }
    },
    clear() {
      this.itemsCard = [];
    },
    rowClick: function(item, row) {

      if (true) {
        this.selected = [item];
        this.row = Object.assign({}, item);
        console.log("Row", item);
        this.getImage(item.PrsDocumentNumber);
      } else {
        if (
          this.selected.find((x) => {
            return x == item;
          }) != null
        )
          this.selected = this.selected.filter((x) => {
            return x != item;
          });
        else {
          this.selected.push(item);
          this.row = Object.assign({}, item);
        }
      }
    },
    assignWorker(worker) {
      if (this.itemsCard.find((x) => x == worker) == null) this.itemsCard.push(worker);
    },
    getImage(document) {
      _sHelper.getPhotoWorker(document, this.$fun.getUserID()).then((rsp) => {       
        const blob = new Blob([rsp.data], {
          type: "image/jpg",
        });

        let image = blob;
        var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
          this.personImage = reader.result;
          return this.personImage;
        };
      });
      this.getQR();
    },
    getImages(document) {
      _sHelper.getPhotoWorker(document, this.$fun.getUserID()).then((rsp) => {
        console.log("Rpta",rsp);
        if(rsp.status === 200){
          const blob = new Blob([rsp.data], {
            type: "image/jpg",
          });

          let image = blob;
          var reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onloadend = () => {
            this.personImage = reader.result;
            this.listImage.push({dni: document , image: this.personImage});
            if(this.itemsCard.length === this.listImage.length) {
              this.loading=false;
              this.showDiaglog=true;
            }
          }
        }else{
          console.error("No se pudo cargar la img");
        }
      });
    },

    getQR(){
      console.log("ItemCard",this.itemsCard);
      // _sCardService.HmnQrCodeConvert(this.itemsCard[0].CrdCode).then((x)=>{
      //     console.log("Data",x.data);
      // })
    },
    close(){
      this.showDiaglog = false;
      this.listImage = [];
      console.log("Cerrar",this.listImage);
    }
    
  },
  created() {
    this.getImage('');
    this.getParam();
  },
  watch: {},
};
</script>

<template>
	<div>
		<!-- <v-btn
			@click="sendMail()"
		>
			enviar
		</v-btn> -->
		<s-crud
			title="PROFESIONES"
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
			ref="gridprofession"
			@rowSelected="rowSelected($event)" 
			searchInput
		>
			<!--<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col>
							<s-date
								label="Fecha Inicio" 
								v-model="filter.DateBegin" 
							></s-date>
						</v-col>
						<v-col>
							<s-date
								label="Fecha Fin" 
								v-model="filter.DateEnd" 
							></s-date>
						</v-col>
						<v-col >
							<s-select
								label="Responsable"
								:items="workerstic"
								autocomplete
								clearable
								item-text="WorkerName"
								item-value="PrsID"
								v-model="filter.PrsID"
							></s-select>
						</v-col>
						<v-col>
							<s-select-definition
								label="Prioridad"
								:def="1392"
								v-model="filter.TkpPriority"
								clearable
							></s-select-definition>
						</v-col>
						<v-col>
							<s-select-definition
								label="Estado"
								:def="1393"
								v-model="filter.TkpStatus"
								clearable
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>-->

			<template scope="props">
				<v-container>
					<v-row justify="center">
						<!--<v-col >
							<s-select
								label="Asignado a"
								:items="workerstic"
								autocomplete
								clearable
								v-model="props.item.PrsID"
								item-text="WorkerName"
								item-value="PrsID"
							></s-select>
						</v-col>
						<v-col>
							<s-select-definition
								label="Prioridad"
								:def="1392"
								v-model="props.item.TkpPriority"
							></s-select-definition>
						</v-col>-->
						<!--<v-col>
							<s-text
								label="Titulo"
								v-model="props.item.TkpTitle"
							></s-text>
						</v-col>
						<v-col>
							<s-select-definition
								label="Estado"
								:def="1393"
								v-model="props.item.TkpStatus"
								clearable
							></s-select-definition>
						</v-col>-->
						<v-col>
							<s-textarea
								label="Descripción"
								v-model="props.item.PrnDescription"
							></s-textarea>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:options>
			</template>
			<template v-slot:PrnStatus="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.PrnStatus==1?'success':'warning'"
				>
					{{ row.PrnStatus==1?'HABILITADO':'DESHABILITADO' }}
				</v-chip>
			</template>

			<!--<template v-slot:TkpPriority="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TkpPriorityColor"
				>
					{{ row.TkpPriorityName }}
				</v-chip>
			</template>-->
		</s-crud>

		<!--<v-dialog
			v-model="dialogTransfer"
			width="500"
		>
			<v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-select
								label="Transferir a"
								:items="workerstic"
								autocomplete
								clearable
								item-text="WorkerName"
								item-value="PrsID"
								v-model="datatransfer.PrsIDTraslate"
							></s-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-textarea
								label="Motivo"
								v-model="datatransfer.TkpReasonTraslate"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								color="primary"
								@click="sendTransfer()"
							>
								{{"Transferir"}}
							</v-btn>
						</v-col>
						<v-col>
							<v-btn
								color="warning"
								@click="cancelTransferTask()"
							>
								{{"Cancelar"}}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>-->

		<!--<v-dialog
			v-model="dialogPaused"
			width="500"
		>
			<v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-textarea
								label="Motivo"
								v-model="dataPaused.TkpReasonTraslate"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								color="primary"
								@click="sendPaused()"
							>
								{{selected.TkpPaused == 0 ? "Pausar" : "Reanudar"}}
							</v-btn>
						</v-col>
						<v-col>
							<v-btn
								color="warning"
								@click="cancelPausedTask()"
							>
								{{"Cancelar"}}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>-->
	</div>
</template>

<script>
	import _sTaskService from '@/services/HumanResource/ProfessionsGen.js';
	import sTextarea from '@/components/Utils/sTextarea.vue';
import { isUndefined } from 'lodash';

	export default {
  components: { sTextarea },
		data() {
			return {
				datatransfer: {},
				dataPaused: {},
				dialogTransfer: false,
				dialogPaused: false,
				selected : {},
				workerstic: [],
				filter: {
					PrsID: 0,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0
				},
				config: {
					model: {
						PrnID: "ID",
						Action: "Action",
						PrnStatus: "integer",
					},
					service: _sTaskService,
					headers: [
						{text: "ID", value: "PrnID"},
						{text: "Descripción", value: "PrnDescription"},
						{text: "Estado", value: "PrnStatus"},


					]
				},
				
			}
		},
		watch : {
			filter(){
				console.log(this.filter)
			}
		},
		methods: {
		
			initialize()
			{
			
			},

			save(item) {
				this.$fun.alert("Seguro que desea Guardar", "question")
				.then(r => {
					if(r.value)
					{
						 item.UsrCreateID=this.$fun.getUserID();
						_sTaskService
						.save(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Actualizado correctamente", "success");
								this.$refs.gridprofession.refresh();
							}
						})
					}
				})
			},
			rowSelected(items)
			{
				if(items.length > 0)
				{
					this.selected = items[0];
				}
			}
		}
	}
</script>

<style scoped>

</style>
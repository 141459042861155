<template>
	<div>
		<s-crud
			title="PERSONAL AUTORIZADO - SALIDA DE EQUIPO "
			:config="config"
			:filter="filter"
            sortable
			add
			edit
			remove
			@save="save($event)"
			ref="gridExitEppAuth"      
            @rowSelected="rowSelected($event)" 
			searchInput
		>   
		<template v-slot:options>
                <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" small elevation="0" @click="viewdialogPdf">
                    <v-icon color="default" x-small>
                        fas fa-eye
                    </v-icon>
                    </v-btn>
                </template>
                <span>Asignar Usuarios</span>
                </v-tooltip>
            </template>
			<template v-slot:filter>
				<v-container style="margin-bottom: 20px;">
                    <v-row justify="center">   
                      <v-col cols="4" class="s-col-form">
                        <s-select
								label="Cargo"
								:items="Position"
								autocomplete
								clearable
								item-text="PstName"
								item-value="PstID"
								v-model="filter.PstID"
						></s-select>
                      </v-col>
                      <v-col cols="4" class="s-col-form">
							<s-select-definition
								label="Equipo"
								:def="1163"
								v-model="filter.TypeEquipment"
								clearable
							></s-select-definition>
					  </v-col> 
                      <v-col cols="4" class="s-col-form">
                        <s-date v-model="filter.MngBeginDate" label="Fecha Vigente" :clearable="true"/>
                      </v-col>
					</v-row>
				</v-container>
			</template>

			<template scope="props" >
				<v-container >
					<v-row justify="center">                      
                        <v-col cols="2" class="s-col-form">
                        	<s-toolbar-person   noCamera :defaultDocument="props.item.PrsDocumentNumber" @returnPerson="returnPerson($event, props)" v-model="props.item.PrsDocumentNumber" />
                        </v-col>

						<v-col cols="4" class="s-col-form">
							<s-text readonly label="Persona" v-model="props.item.NtpFullName"></s-text>
							<s-text hidden v-model="props.item.NtpID"></s-text>
						</v-col>	
                        <v-col cols="2" class="s-col-form">
                        <s-date v-model="props.item.MngBeginDate" label="Fecha Inicio" :clearable="true"/>
                        </v-col>
						
                        <v-col cols="2" class="s-col-form">
							<v-checkbox style=""   v-model="props.item.permanent"  :true-value=1 :false-value=0  label="Permanente" @change="handlePermanentChange"  class="ma-0 pa-0">
							</v-checkbox> 
						</v-col>
                        <v-col cols="2" class="s-col-form">
                        <s-date v-model="props.item.MngEndDate" label="Fecha Fin" :clearable="true"  ref="fechaFin" />
                        </v-col>

					</v-row>
                    <v-row justify="center">  
						<v-col cols="2" class="s-col-form">
                        <s-select
								label="Cargo"
								:items="Position"
								autocomplete
								clearable
								item-text="PstName"
								item-value="PstID"
								v-model="props.item.PstID"
						></s-select>
                      </v-col>   
                        <v-col cols="3" class="s-col-form">
							<s-select-definition
								label="Equipo"
								:def="1163"
								v-model="props.item.TypeEquipment"
								clearable
							></s-select-definition>
						</v-col> 
                        <v-col cols="2" class="s-col-form">
							<s-select-definition
								label="Marca"
								:def="1164"
								v-model="props.item.TypeBrand"
								clearable
							></s-select-definition>
						</v-col>                  
                        <v-col cols="3" class="s-col-form">
							<s-select-definition
								label="Modelo"
								:def="1490"
								v-model="props.item.TypeModel"
								clearable
								:add="$fun.isAdmin()"
							></s-select-definition>
						</v-col> 
                        <v-col cols="2" class="s-col-form">
                            <s-text label="Numero de serie"  v-model="props.item.NumSeries" :clearable="true"> </s-text>
                        </v-col>
					</v-row>
				</v-container>
			</template>


			<template v-slot:MngFechafin="{ row }">
				<v-chip 
					x-small 
					black
					 :color="row.permanent==0  ? '' : 'info'"
				>
				{{ row.permanent==0 ? moment(row.MngEndDate).format('YYYY-MM-DD') : 'Permanente' }}
				</v-chip>
			</template>

		</s-crud>
		<v-dialog v-model="showDialogAdd" max-width="500">
      <v-card>
        <v-card-title>
          <label>
            CONSOLIDADO DE PERSONAL CON SALIDA DE EQUIPO<strong></strong></label
          >
        </v-card-title>
         
		<v-col style="padding-top:0px;">
                        <v-row justify="center">
							<v-col lg="3"  style="padding-bottom:0px">
                                <s-select label="Año" :items="years" outlined hide-details dense v-model="year"></s-select>
                            </v-col>
                            <v-col lg="3" style="padding-bottom:0px">
                                <!--  <s-select label="Periodo" dense hide-details outlined :items="itemsWeek" v-model="week"></s-select>-->
                                <s-select small-chips attach="" multiple label="N° Semana" dense hide-details outlined :items="itemsWeek"  v-model="week"></s-select>
                            </v-col>
                            <v-col lg=3>
                            <s-date label="Fecha Inicio" v-model='BeginDate' disabled
                            />  </v-col>
                            <v-col lg=3> 
                              <s-date label="Fecha Fin" v-model='EndDate' disabled></s-date>
						    </v-col>
                        </v-row>
                </v-col>
        <v-card-actions>
          <v-btn color="default" small text @click="showDialogAdd = false;">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" dark depressed small @click="GenerarPDF()">GENERAR PDF</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
	<v-dialog v-if="dialogPdf" v-model="dialogPdf"   width="70%">
			<exitPerEquipment-pdf :refPerondalExitEpp="dataPrint" @close="dialogPdf = false"></exitPerEquipment-pdf>
	</v-dialog>
	</div>
</template>

<script>
	import _sAExitService from '@/services/Management/AuthorizedExitEpp.js'; 
	import SText from '@/components/Utils/SText.vue';
	import _sTaskService from '@/services/InformationTechnology/TaskService.js';
    import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
    import _sCttoService from '@/services/HumanResource/HmnContractService.js';
	import exitPerEquipmentPdf from "@/views/Management/exitPerEquipmentPdf.vue"; 
    import { isUndefined } from 'lodash';

	export default {
  components: { SText,sToolbarPerson,exitPerEquipmentPdf },
		data() {
			return {
				itemCheckBox:0,
                Position:[],
				itemSelected: null,
				showDialogAdd:false,
				filter: {
					PrsID: 0,
                    GpArt: -1,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0
				},
				dialogPdf: false,
				dataPrint:[],
				week: null,
				month:null,
				year:null,
				BeginDate:null,
				EndDate:null,
				itemsWeek: [],
				config: {
					model: {
                        AtqID: "ID",
						MngFechafin:"",
						Action: "Action",
					},
					service: _sAExitService,
					headers: [
						{text: "ID", value: "AtqID"},
						{text: "DNI", value: "PrsDocumentNumber"},
						{text: "Apellidos y Nombres", value: "NtpFullName"},
						{text: "Cargo", value: "PstName"},
						{text: "Equipo", value: "NameTypeEquipment"},
						{text: "Marca", value: "NameTypeBrand"},
						{text: "Modelo", value: "NameTypeModel"},
						{text: "Numero de serie", value: "NumSeries"},
						{text: "Fecha Inicio", value: "MngBeginDate"},
						{text: "Fecha Fin", value: "MngFechafin"},

					]
				},
				
			}
		},
        watch: {
            'filter.ItemsCod'() {
            this.refresh();
            },
			week(){
			if (this.week == 1) {

			this.BeginDate = this.$moment(this.year+'0101').isoWeek(this.itemsWeek[0]).startOf("isoweek").format('YYYY-MM-DD');
			this.EndDate = this.$moment(this.year+'0101').isoWeek(this.itemsWeek[0]).endOf("isoweek").format('YYYY-MM-DD');

			if(this.year!=this.$moment(this.year+'0101').isoWeek(this.itemsWeek[0]).startOf("isoweek").format('YYYY')){
				this.BeginDate=this.$moment(this.year+'0108').isoWeek(this.itemsWeek[0]).startOf("isoweek").format('YYYY-MM-DD');
				this.EndDate = this.$moment(this.year+'0108').isoWeek(this.itemsWeek[0]).endOf("isoweek").format('YYYY-MM-DD');

			}
			} 
			else if (this.week > 1) {
			console.log("this.itemsWeek[this.itemsWeek.length - 1]", this.itemsWeek[this.itemsWeek.length - 1])
			console.log("this.itemsWeek[0]", this.itemsWeek[0])
			if (this.itemsWeek[this.itemsWeek.length - 1].value > this.itemsWeek[0].value) {
				
				console.log("1 if")
				// let min = Math.min(...this.itemsWeek);
				let min = this.week;
			console.log("  min[0]", min)

				this.BeginDate = this.$moment(this.year+'0101').isoWeek(min).startOf("isoweek").format('YYYY-MM-DD');
				this.EndDate = this.$moment(this.year+'0101').isoWeek(min).endOf("isoweek").format('YYYY-MM-DD');
			
			} else {
				console.log("2 if")
				let max = Math.max(...this.itemsWeek);

				this.BeginDate = this.$moment(this.year+'0101').isoWeek(this.itemsWeek[this.itemsWeek.length - 1]).startOf("isoweek").format('YYYY-MM-DD');
				this.EndDate = this.$moment(this.year+'0101').isoWeek(max).endOf("isoweek").format('YYYY-MM-DD');
					
				if(this.year!=this.BeginDate.year){
					this.BeginDate=this.$moment(this.year+'0108').isoWeek(this.itemsWeek[this.itemsWeek.length - 1]).startOf("isoweek").format('YYYY-MM-DD');
					this.EndDate = this.$moment(this.year+'0108').isoWeek(max).endOf("isoweek").format('YYYY-MM-DD');

				}
			}

			if (this.week.length > 1) {

				if ((this.week.find((x) => { return x == (this.week[this.week.length - 1] - 1) }) == null) &&
					(this.week.find((x) => { return x == (this.week[this.week.length - 1] + 1) }) == null)) {
					this.week = this.week.filter((x) => { return x != this.week[this.week.length - 1] })
				}
			}
			}
			else{
			this.EndDate = this.$moment().add('days', -1).format("YYYY-MM-DD");
			this.BeginDate = this.$moment().format("YYYY-MM-DD");
			}
			},
		},
		methods: {
			viewdialogPdf() {
				this.showDialogAdd = true;
			},
			GenerarPDF(){
				let obj ={MngBeginDate:this.BeginDate, MngEndDate:this.EndDate }
                _sAExitService
                .ListPDF(obj, this.$fun.getUserID())
                        .then(resp => {
                            if(resp.status == 200){
                                this.dataPrint = resp.data;
					            this.dataPrint.Observacion="Semana "+this.week;
								this.dialogPdf = true;
								console.log("data",this.dataPrint);
                            }
                        })
			},
            getPositiom(){
                let obj ={AreID:null, TypePosition:1 }
                _sCttoService
                .Positionlist(obj, this.$fun.getUserID())
                        .then(resp => {
                            if(resp.status == 200){
                                this.Position = resp.data;
                            }
                        })
            },
			handlePermanentChange(){
				if (this.itemCheckBox) {
				
				}
			},
            returnPerson(value, props) {
				console.log(value,"dsds");
				props.item.NtpFullName = value.NtpFullName;
				props.item.NtpID = value.NtpID;
            },
			initialize()
			{
				this.getPositiom();
			},

			save(item) {				
				if (!item.PrsDocumentNumber) {
					this.$fun.alert("El número de documento es obligatorio.", "error");
					return;
				}
				if (!item.NtpFullName) {
					this.$fun.alert("El nombre completo es obligatorio.", "error");
					return;
				}
				if (!item.PstID) {
					this.$fun.alert("El cargo es obligatorio.", "error");
					return;
				}
				if (!item.TypeEquipment) {
					this.$fun.alert("El equipo es obligatorio.", "error");
					return;
				}
				if (!item.TypeBrand) {
					this.$fun.alert("La marca es obligatoria.", "error");
					return;
				}
				if (!item.TypeModel) {
					this.$fun.alert("El modelo es obligatorio.", "error");
					return;
				}
				if (!item.NumSeries) {
					this.$fun.alert("El número de serie es obligatorio.", "error");
					return;
				}
				// Verificación de fechas: Si el campo "permanent" está activado, la fecha de fin puede ser opcional.
				if (!item.permanent && !item.MngEndDate) {
					this.$fun.alert("La fecha de fin es obligatoria.", "error");
					return;
				}
				// Si el campo "permanent" está activado, se omite la validación de "MngEndDate"
				if (!item.MngBeginDate) {
					this.$fun.alert("La fecha de inicio es obligatoria", "error");
					return;
				}
				if (item.MngBeginDate>item.MngEndDate) {
					this.$fun.alert("La fecha de inicio no puede ser mayor a la fecha fin", "error");
					return;
				}
                if (item.SecStatus === null) item.SecStatus = 1;
				if(item.SecStatus == null) item.SecStatus = 1
                  item.UsrCreateID=this.$fun.getUserID();
                _sAExitService.save(item,this.$fun.getUserID()).then(resp => {
					if(resp.status == 200)
					{   

						console.log("respuesta",resp.data);
						console.log("respuesta",resp.data.AtqID);
                        if(resp.data.AtqID==0){
							this.$fun.alert("Usuario Existente ha sido Actualizado" , "info"); 
						}else if (resp.data.AtqID==-1){
						    this.$fun.alert("Numero de serie ya existente" , "error"); 
						}else{

								this.$fun.alert("Asignado Correctamente" , "info"); 
						}
							
						}	
						this.$refs.gridExitEppAuth.refresh();
					
				});

			},
            rowSelected(item)
			{
				if(item.length > 0)
				{
					this.itemSelected = item[0];
					//console.log("w",this.itemSelected)
					//this.item.NtpFullName = this.itemSelected.WorkerName;
				}
			}
		},

		created () {
	     	this.filter.AreID =  this.$fun.getUserInfo().CtrContract.AreID ?? 0;
			for (let i = 1; i <= 53; i++) {
            this.itemsWeek.push({
                text: i,
                value: i,
            });
            }
			this.years = this.$fun.getTop5LastYears();
			this.year = this.$moment().year();
			this.initialize();
		},
		mounted() {
			
			this.filter.PrsID =  this.$fun.getUserInfo().PrsID; 
					
			console.log( "USER INFO",this.$fun.getUserInfo()); 
		},


	}
</script>

<style>
	.scrollable {
		max-height: 450px; /* Ajusta según sea necesario */
		overflow-y: auto;  /* Habilita el scroll vertical */
	}
</style>
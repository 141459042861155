<template>
    <div>
        <v-container>
            <br>
            <v-row>
                <!-- <v-col hidden>
                    <s-text
                        label="Linea"
                        v-model="ClpID"
                    ></s-text>
                </v-col> -->
                <!-- <v-col cols="4" lg="4" md="6" sm="12" hidden class="s-col-form">
                    <s-select hidden
                        label="Linea"
                        v-model="PdcID"
                        
                    ></s-select>
                </v-col> -->
                <!-- <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">	
                    <s-select
                        :items="ExitCategorys"
                        item-value="CecID"
                        item-text="CecDescription"
                        label="Salida de Tipo de Congelamiento"
                        v-model="line.CecID"
                        :categoryID="line.PdcID"
                        ref="txtCec"
                        @input="inputProdLines($event)"
                    />							
                </v-col> -->
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">	
                    <s-select-definition
                        label="Tipo corte"
                        :def="1463"
                        :dgr="lineaProcess.CecID==3? 77:0"
                        v-model="line.TypeCut"
                    ></s-select-definition>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form" hidden>
                    <s-select-definition
                        label="Estado"
                        v-model="line.ClpState"
                        :def="1154"
                    ></s-select-definition>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-client
                        clearable
                        label="Cliente"
                        v-model="line.CumID"
                        ref="txtCumID"
                        full>
                    </s-select-client>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Tipo de producto"
                        v-model="line.TypeAccordance"
                        :def="1385"
                    ></s-select-definition>
                </v-col>
            </v-row>
            <v-row>
                <!-- <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Cultivo"
                        :def="1173"
                        v-model="line.TypeCultive"
                    ></s-select-definition>
                </v-col> -->
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Tipo Cultivo"
                        :def="1172"
                        v-model="line.TypeCrop"
                    ></s-select-definition>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <label  aria-hidden="true" class="v-label theme--light"><b>Hora inicio de periodo</b></label>
                    <div class="relojito">
                      <datetime valueZone="America/Lima"  type="time" v-model="PprHour">
                      </datetime>
					</div>
                </v-col>
                <!-- <v-col cols="4" lg="4" md="6" sm="6" >
                    <v-btn
                         :color="itemProccessRol[0].PchID>0?'#3fce3f':'#df325c'"
                        @click="openDialogPersonRol"
                        
                    >
                        <v-icon style="color: white;" left>mdi-account-group</v-icon>
                        <span style="color: white;">{{ titlePersonRol }}</span>
                    </v-btn>
                </v-col> -->

                <!-- <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Periodo"
                        v-model="line.PchPeriodo"
                        :def="1444"
                        disabled
                    ></s-select-definition>
                </v-col> -->
                <v-col v-if="line.TypeAccordance == 2" cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-textarea label="Motivo de No Conformidad" v-model="line.ReasonNonConformity"  ></s-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-btn  
                        v-if="showbuton1"
                        color="primary"
                        @click="endLineProcess()">
                        Agregar Turno
                    </v-btn> 
                    <v-btn  
                        color="primary"
                        @click="openDialogPersonRol()"
                        
                        >
                        Iniciar Periodo
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="pt-3 pr-3 pl-3">
                <v-col class="pl-0 pt-0">
                    <v-data-table
                        dense
                        :headers="headerCliente"
                        :items="itemsDataCli"
                        hide-default-footer
                        disable-sort
                    >
                    <!-- item-key="PchID"
                    v-model="selected"
                show-select -->
                        <template v-slot:item.Actions="{ item }">
                            <v-btn
                                x-small
                                color="error" 
                                @click="FinalizarPeriodo(item)"
                                v-if="item.PchState != 2"
                            >
                            <!-- @click="clickDeleteItemGP(item)" -->
                            Terminar
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>


        <v-dialog
            v-if="TerminarProcessJabas"
            v-model="TerminarProcessJabas"
            persistent
            width="600"
        >
            <c-jabas-process-end
                @CloseGenerateCutProcess="CloseGenerateCutProcess()"
                @refreshConf="refreshConf()"
                :ClpID="ClpID"
            >
            <!-- :tablesActiveTotal="tablesActiveTotal" -->
            </c-jabas-process-end>
        </v-dialog>

        <v-dialog v-model="dialogPersonRol" persistent width="550" max-heigth="500">
    <v-card>
        <!-- Título del modal -->
        <v-card-title style="margin-bottom: 5px; background-color: #0f88b2; color: white; ">
            <span class="headline">AGREGAR CANTIDAD DE PERSONAS POR ROL:</span>
        </v-card-title>

        <!-- Contenido desplazable (scrollable) -->
        <v-card-text class="scrollable-content">
            <v-row>
                <v-col>
                    <!-- Campo de búsqueda para filtrar por nombre de rol -->
                    <v-text-field
                        v-model="filterRoleName"
                        label="Buscar por Nombre de Rol"
                        dense
                    ></v-text-field>
                    <v-data-table
                        v-model:items-per-page="itemsPerPage"
                        :headers="rolProcessHeaders"
                        :items="filteredRoles" 
                        item-key="CrlID"
                        dense
                        :page="page"
                        :items-per-page="10"
                        @update:page="updatePage"
                    >
                        <template v-slot:item="{ item, index }">
                            <tr>
                                <td class="col-2 col-sm-1 col-md-1 col-lg-1">{{ (page - 1) * 10 + index + 1 }}</td>
                                <td class="col-8 col-sm-6 col-md-4 col-lg-3">{{ item.CrlNameRol }}</td>
                                <td class="col-2 col-sm-1 col-md-1 col-lg-1">
                                    <v-text-field style="width:60px;"
                                        v-model="item.PhrQuantity"
                                        type="number"
                                        dense
                                        single-line
                                        hide-details
                                        outlined
                                    ></v-text-field>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- Pie de página -->
        <v-card-actions>
            <v-btn color="red" text @click="closeDialogPersonRol">Cerrar</v-btn>
            <v-btn color="primary" text  @click="startLineProcess()">Iniciar Periodo</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>

    </div>
</template>

<script>

    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"; 
    import _FrzCtnSendKgProcessService from "@/services/FrozenProduction/FrzCtnSendKgLinesProcess.js"; 
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";

    import cJabasProcessEnd from "./CJabasProcessEnd.vue";
import { initial } from "lodash";

  
  export default {
    components: { SSelectClient,cJabasProcessEnd},
    props: {
        lineaProcess: {
            type: Object,
            required: true,
        },
       
    },
    data: () => ({
        dialogPersonRol: false, 
        filterRoleName: '',
        line: {
           
        },
        // ExitCategorys:[],
        headerCliente: [
            { text:"#", value :"Line"},
            { text:"Periodo", value :"PchPeriodo"},
            { text:"ID Trazabilidad", value :"TraceabilityID"},
            { text: "Cliente", value: "CumNombre"},
            { text: "Tunel Cong.", value:"CecDescription"},
            { text: "Tipo Corte", value: "TypeCutName"},
            { text: "Cultivo", value: "TypeCultiveName"},
            { text: "Tipo Cultivo", value: "TypeCropName"},
            { text: "Conformidad", value: "TypeAccordanceName"},
            { text: "Hora Inicio", value: "PchDateStart1"},
            { text: "Hora Fin", value: "PchDateEnd1"},
            { text: "Peso Kg Inicio", value: "SAverageWeightJabaN"},
            { text: "Transfer Kg Recibido ↑", value: "PesoRecibido"},
            { text: "Transfer Kg Enviado ↓", value: "PesoEnviado"},
            { text: "Peso Kg Fin", value: "PesoFin"},
            { text: "", value: "Actions" ,width:"70"},
        ],
        
        itemsDataCli:[],
        itemProccessRol:[],
        itemsPerPage: 10,
        page:1,
        selected: [],
        showbuton1 : false,
        TerminarProcessJabas : false,
        tablesActiveTotal: 0,
        PprHour: "",
        rolProcessHeaders: [
        { text: '#', value: 'counter' },
        { text: 'Nombre Rol', value: 'CrlNameRol' },
        { text: 'Cantidad', value: 'PhrQuantity' },
        // { text: 'Observación', value: 'PhrObservation' },
      ],
      itemProccessRol: [],
        // ClpID:0
        
    }),
    // watch: {
    //     selected(newValue, oldValue)
    //     {
    
    //         this.showbuton = false;
    //         let countHour = 0;
    //         newValue.forEach(element => {
    //             if(element.PchDateEnd != null)
    //             {
    //                 countHour ++;
    //             }
    //         });
    //         if(countHour > 0 || newValue.length == 0)
    //         {
    //             this.showbuton = false;
    //         }
    //         else{
    //             this.showbuton = true;
    //         }
    //     }
    // },
    computed: {
        filteredRoles() {
            // Filtrar los roles de acuerdo al nombre
            return this.itemProccessRol.filter(role => {
                return role.CrlNameRol.toLowerCase().includes(this.filterRoleName.toLowerCase());
            });
        }
    },
    methods: {
        // inputProdLines(PdcID){   
            // _sFrzCtnLinesProcessService.listExitcatByCat( { PdcID }, this.$fun.getUserID()).then(r =>{
            //     if(r.status == 200)
            //     {
            //         this.ExitCategorys = r.data;         
            
            //     }
            // });   
        // },
        updatePage(newPage) {
           this.page = newPage;
        },
        startLineProcess() {
            
            // if(!this.line.CecID){
            //     this.$fun.alert("Ingrese Tipo de Congelamiento", "warning");
            //     this.$refs.txtCec.focus();
            //     return;
            // }
            if(!this.line.CumID){
                this.$fun.alert("Ingrese Cliente", "warning");
                return;
            }
            if(this.line.TypeAccordance == 1)
            {
                this.line.ReasonNonConformity = '';
            }
            this.$fun.alert("Esta seguro de Iniciar?", "question").then((r) => { // activar o inactivar linea 
            if (r.value) {

                let item ={
                    // ClpID : this.ClpID,
                    // CecID : this.line.CecID,
                    ClpID : this.lineaProcess.ClpID,
                    CecID : this.lineaProcess.CecID,
                    CumID : this.line.CumID,
                    TypeCut : this.line.TypeCut,
                    TypeCultive : this.lineaProcess.TypeCultive,
                    TypeCrop : this.line.TypeCrop,
                    // TrnID      : this.TrnID,
                    UsrCreateID : this.$fun.getUserID(),
                    UsrUpdateID : this.$fun.getUserID(),
                    PchDateStart : this.PprHour,
                    PchPeriodo : 1,
                    TypeAccordance: this.line.TypeAccordance,
                    ReasonNonConformity: this.line.ReasonNonConformity
               }
           
            _sFrzCtnLinesProcessService
                .linesStartProcess(item, this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {
                        console.log("idPCH",r.data.PchID);
                        console.log("idPCH",r.data);
                        this.lineaProcess.PchID=r.data.PchID;
                        this.guardarCantidad();
                    }
                });
                
            }
            });
		},
        openDialogPersonRol() {
            this.dialogPersonRol = true;
        },

        closeDialogPersonRol() {
            this.dialogPersonRol = false;
        },
        listConfHour(){
            
            
            let obj ={
                ClpID : this.lineaProcess.ClpID,
            }
            
            _sFrzCtnLinesProcessService.listConfHours(obj, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    r.data.forEach((el, index) => {
                        el.Line = index + 1;
                    });
                    
                    this.itemsDataCli = r.data;
                    
                    // if(this.itemsDataCli.length <= 0 ){
                    //     this.showbuton1 = true;
                    // }
                }   
            })
        },
        listRolProcess() {
        let obj = {
            CecID: this.lineaProcess.CecID,
            PchID: this.lineaProcess.PchID,
            TypeCultive: this.lineaProcess.TypeCultive,
            TrnID: this.lineaProcess.TrnID,
        };

        _FrzCtnSendKgProcessService.listRolProcesConfig(obj, this.$fun.getUserID()).then((r) => {
            if (r.status == 200) {
            this.itemProccessRol = r.data;
            // Preparamos los datos para que todos tengan un modelo de cantidad
            this.itemProccessRol.forEach((item) => {
                if (!item.PhrQuantity) {
                item.PhrQuantity = 0;
                }
            });
            }
        });
        },
        guardarCantidad() {
            const itemsToSave = this.itemProccessRol.filter((item) => item.PhrQuantity > 0);

            if (itemsToSave.length > 0) {
                console.log("itemsToSave", itemsToSave);

                for (let i = 0; i < itemsToSave.length; i++) {
                    let item = itemsToSave[i];
                    console.log('Procesando item', item);
                    let obj = {
                        PhrID: 0, 
                        PchID: this.lineaProcess.PchID, 
                        CrlID: item.CrlID,
                        PhrQuantity: item.PhrQuantity,  
                        PhrObservation: '',
                        SecStatus: 1,
                        UsrCreateID: this.$fun.getUserID(),
                        TrnID: this.lineaProcess.TrnID
                    };

                    _FrzCtnSendKgProcessService.RolProcesConfigsave(obj, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            console.log("Item registrado correctamente", item);
                        } else {
                            console.error("Error al guardar el item", item);
                        }
                    });
                }

                // Luego de procesar todos los elementos
                this.$fun.alert("Todos los items fueron procesados", "success");
                this.$emit("close", this.line);
                this.dialogPersonRol = false;
                this.modelDialog = false; // verificar si es necesario
                this.listConfHour();
                this.refreshlines();
            } else {
                this.$fun.alert("No hay cantidades mayores a 0 para guardar", "warning");
            }
        },

        endLineProcess(){
            
            if(!this.line.CecID){
                this.$fun.alert("Ingrese Tipo de Congelamiento", "warning");
                this.$refs.txtCec.focus();
                return;
            }
            if(!this.line.CumID){
                this.$fun.alert("Ingrese Cliente", "warning");
                // this.$refs.txtCumID.focus();
                return;
            }
            
            this.$fun.alert("Esta seguro de Finalizar?", "question").then((r) => { // activar o inactivar linea 
                if (r.value) {
                    let item ={
                        //PchID : this.itemsDataCli[0].PchID,
                        ClpID : this.lineaProcess.ClpID,
                        CecID : this.lineaProcess.CecID,
                        CumID : this.line.CumID,
                        TypeCut : this.line.TypeCut,
                        TypeCultive : this.line.TypeCultive,
                        TypeCrop : this.line.TypeCrop,
                        UsrCreateID : this.$fun.getUserID(),
                        UsrUpdateID : this.$fun.getUserID()
                    }
                    
                    _sFrzCtnLinesProcessService
                    .linesProcessEnd(item, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success");
                            this.$emit("close", this.line);
                            this.modelDialog = false; 
                            this.listConfHour();
                        }
                    });
                }
            });
        },
        FinalizarPeriodo(item){
            
            
            this.TerminarProcessJabas = true;
            // this.tablesActiveTotal = [];
            this.ClpID = item.ClpID;
            // this.DtbID = 
            

                // _sFrzCtnLinesProcessService
                // .listTablesByLines({ClpID},this.$fun.getUserID())
                // .then(r => {
                //     if(r.status == 200){
                //         this.tablesActiveTotal.push(...r.data)
                //     }
                // }) 
            
        },
        CloseGenerateCutProcess()
        {   
            this.TerminarProcessJabas = false;
        },
        refreshConf(){
            
            this.listConfHour();
        },
        refreshlines()
        {
            this.$emit("refreshlines");
        },
      
        
        
    },
    created () {
        this.listConfHour();
        this.listRolProcess();
        this.PprHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
        console.log("lineadatos",this.lineaProcess);
	},
    mounted(){
        
       
    },

  };

</script>
<style scoped>
    /* Hacer que el contenido de la tabla sea desplazable */
    .scrollable-content {
        max-height: 600px; /* Ajusta la altura máxima según lo necesites */
        overflow-y: auto;
    }

    /* Ajuste adicional para asegurarse de que el contenido se vea bien dentro del modal */
    .v-dialog .v-card {
        max-height: 90vh; /* Limita la altura del modal si es necesario */
        display: flex;
        flex-direction: column;
    }
    .v-data-table td,
    .v-data-table th {
        border: 1px solid #ccc; /* Borde para celdas */
    }
</style>
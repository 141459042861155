<template>
    <div>
     
  <s-toolbar  label="Punto de Vigilancia"></s-toolbar>
      <v-card  elevation="5">
        <v-col>
         
           <s-select-definition @returnObject=returnObject($event) radio :def=1116 v-model=pvs></s-select-definition>
     
        </v-col>

        


        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn text @click="cancel()">Atras</v-btn>
          <v-btn color="primary" @click="start()">Iniciar</v-btn>
        </v-card-actions>
      </v-card>



    </div>
</template>
<script>


export default {
  name: 'TimeRealReserve',
  components: {  }, 
props:{MltID:{
  type:Number,default:null
}},

  data: () => ({pvs:'1',dialog:true,item:null
  }),
  methods:{
    returnObject(item){
      
        this.item=   item;
    },
      start(){
        if(this.item!=null)
          this.$emit('start',this.item);
          else this.$fun.alert('Seleccione punto de Vigilancia','warning');
        //  this.$router.push({ name: 'ACCESS_CONTROL_DETAIL', params: { pv: this.pvs } });
      }
      ,cancel(){
         this.$emit('cancel');
      }
  },
  mounted(){console.log(this.$route);

                }



  
}
</script>
<template>
    <v-card elevation="0">
      <s-toolbar v-if="visibleTitle" label="Datos Asegurados" />
      <v-card-text>
        <v-row>
          <!-- Contenedor para SCTR Pensión -->
          <v-col class="s-col-form" sm="6" md="6">
            <!-- <v-row>
                <v-col style="margin-bottom: 3%;">
                  <s-select-definition :def="1485" label="SCTR Pensión" v-model="item.sctrPension" :clearable="true"/>
                </v-col>
            </v-row> -->
            <h5>DATOS SISTEMA PENSIONARIO</h5>
            <v-card class="pa-3" style="border-radius: 12px; border: 1px solid #ccc;">
              <v-row>
                <v-col>
                  <s-select-definition :def="1499" label="Régimen pensionario" v-model="item.TypePensionRegimen"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                    <s-date v-model="item.PsnBeginDate" label="Inicio Aportación" />
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                    <s-text label="C.U.S.P.P"  v-model="item.CUSPP" :clearable="true"> </s-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
  
          <!-- Contenedor para SCTR Salud -->
          <v-col class="s-col-form" sm="6" md="6">
            <!-- <v-row>
                <v-col style="margin-bottom: 3%;">
                  <s-select-definition :def="1485" label="SCTR Salud" v-model="item.sctrSalud" :clearable="true"/>
                </v-col>
            </v-row> -->
            <h5>DATOS SISTEMA SALUD</h5>
            <v-card class="pa-3" style="border-radius: 12px; border: 1px solid #ccc;">
              <v-row>
                <v-col>
                  <s-select-definition :def="1509" label="Seguro" v-model="item.TypeHealthRegimen"/>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col >
                    <s-text label="Autogenerado"  v-model="item.autogenerado" :clearable="true"> </s-text>
                </v-col>
              </v-row> -->
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
  
      <!--<v-card-actions>
        <v-container>
          <v-row>
            <v-col class="s-col-form">
              <v-btn block text @click="close()">Cancelar</v-btn>
            </v-col>
            <v-col class="s-col-form">
              <v-btn block color="primary" @click="save()">GUARDAR</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>-->
    </v-card>
  </template>
  
  
  <script>
  import SText from "../SText.vue";
  export default {
    name: "sDatosAsegurados",
    components: { SText},
    props: {
      visibleTitle: {
        type: Boolean,
        default: true,
      },
      full: {
        type: Boolean,
        default: false,
      },
      typeperson: {
        type: Number,
        default: 1,
      },
      addTypeSex: {
        type: Boolean,
        default: false,
      },
      addBirthDate: {
        type: Boolean,
        default: false,
      },
      addDriver: {
        type: Boolean,
        default: false,
      },
      datawork: { type: Object },
    },
    data() {
      return {
        dataWor:{},
        editing: false,
        item: {
            TypePensionRegimen:6,
            CUSPP:"",
            PsnBeginDate: this.$fun.getDate(""),
            TypeHealthRegimen: 1
		    },
        selectedCenterCost: 0,      
        selectedBusinessLine: null,       
        menu3: false,
        config: {},
        dialog: false,
      };
    },
    methods: {
      initialize()
			{
      // if(this.datawork && typeof this.datawork === 'object'){
      //   this.item.sctrSalud= this.dataWor.HealthCE.TypeHealthSystem || null;
      //   this.item.sctrPension= this.dataWor.PensionCE.typePensionSystem || null;
      //   this.item.regPensionario= this.dataWor.PensionCE.typePensionRegimen || null;
      //   this.item.seguro= this.dataWor.HealthCE.TypeHealtRegimen || null;
      //   this.item.autogenerado= this.dataWor.HealthCE.Autogenerado || null;
      //   this.item.cuspp= this.dataWor.PensionCE.CUSPP || null;
      //   this.item.inaportacion= this.dataWor.PensionCE.PsnBeginDate || null;
      //   //this.item.cargo =  this.dataWor.AgreementCE.PstID || null;
      // }
			},
      clear() {
      
      },
       
      close() {
        this.$refs.document.focus();
        this.$emit("toclose", 2);
        this.clear();
      },
      save(){

      },
      getData() {
        if(this.validateData()){
          return {
              HmnPension:{
                CUSPP: this.item.CUSPP,
                PsnBeginDate: this.item.PsnBeginDate,
                TypePensionRegimen: this.item.TypePensionRegimen,             
                UsrCreateID: this.$fun.getUserID(),
                UsrUpdateID: this.$fun.getUserID(),
              },
              HmnHealth:{
                HthScireID: 1 ,//this.item.TypeHealthRegimen,
                TypeHealthRegimen: this.item.TypeHealthRegimen,            
                UsrCreateID: this.$fun.getUserID(),
                UsrUpdateID: this.$fun.getUserID(),
              }  
          };
        }
        else{
          return null;
        }
      },

      validateData(){
        let valid = true;
        console.log("Data Asegurados",this.item);

        if(this.item.CUSPP==null || this.item.CUSPP == "" ){
          this.$fun.alert("El campo C.U.S.P.P es obligatorio","warning");
          valid = false;  
        }
        if(!this.$fun.isValidDate(this.item.PsnBeginDate)){
          this.$fun.alert("Fecha no Valida del Campo Inicio de Aportación ","warning");
          valid = false;  
        }
        
        return valid;
      },
    },
    watch: {
     
     
    },
    created() {
    if (this.datawork && typeof this.datawork === 'object') {
      this.dataWor = Object.assign({}, this.datawork);
    }
      this.initialize();
    },
  };
  </script>
  
<template>
  <div>
    <v-card >
        <s-toolbar 	height="40"
					dark :label="'Plantillas' "/>
              <v-col style="padding-bottom: 24px">
                <v-row justify="center">
                  <v-col class="s-col-form" lg="12">
                    <s-text v-if="Type == 3" :maxlength="100" autofocus label="Descripción" ref="DerDescription" v-model="item.DerDescription" />
                    <s-article-search v-else :readonly="editItem" v-model="article" />
                  </v-col>
                </v-row>
              </v-col>
     </v-card>
    </div>
</template>
<script>
import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";
export default {
    name: "RequirementService",
  components: { sArticleSearch },
  computed: {

  },
  data: () => ({
    editItem: false,
    item: {
    DerDescription: "",
    }
  })
}
</script>
import Service from '../Service';
import Vue from "vue";
const resource = "Tank/"

export default {

    /* GetLayetteFormed(obj, requestID) {
        return Service.post(resource + 'GetLayetteFormed', obj, {
            params: { requestID: requestID }
        });
    }, */
    GetTankDetail(TnkID, requestID) {
        return Service.post(resource + "GetTankDetail", "", {
            params: { TnkID: TnkID, requestID: requestID },
        });
    },

    GetTank(typeDestinyName ,requestID) {
        return Service.post(resource + "GetTank", {}, {
            params: { typeDestinyName, requestID: requestID },
        });
    },

    /* GetLayetteFormed() {
        return Vue.prototype.$http.defaults.baseURL + "/" + resource + "GetLayetteFormed";
    }, */

    GetLayetteFormed(search, requestID) {
        return Service.post(resource + "GetLayetteFormed", "", {
            params: { search: search, requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },
}
<template>
  <v-card elevation="0">
    <s-toolbar v-if="visibleTitle" label="Nueva Persona" /> 
    <v-card-text>
      <v-row>
        <v-col cols="12" class="s-col-form" sm="6" md="6">
          <s-select-definition :def="1036" label="Tipo Documento"  v-model="person.GenPerson.TypePersonDocument" />
        </v-col>
        
          <v-col  cols="12" class="s-col-form" sm="6" md="6">
            <s-text
              ref="document"
              label="Nro. Documento"
              @keyupEnter="txtDocument()"            
              number
              @blur="search()"            
              :autofocus="person.GenPerson.PrsDocumentNumber.length == 0"
              v-model="person.GenPerson.PrsDocumentNumber"                     
            />          
          </v-col>
          
           <!-- <v-card-actions>
            <v-container>
              <v-row>
                <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">                           
                                
                  <v-btn v-if="person.GenPerson.TypePersonDocument ==1" class="my-1"  small dark color="info" @click="reniec()">RENIEC 
                    <v-icon right dark> mdi-cloud-search </v-icon>
                  </v-btn>
                  
                  <v-btn v-if="person.GenPerson.TypePersonDocument ==3"  class="my-1" small dark color="info" @click="sunat()">SUNAT 
                    <v-icon right dark> mdi-cloud-search </v-icon>
                  </v-btn>                  
                </v-col>
                
              </v-row>
            </v-container>
          </v-card-actions> -->       
      </v-row> 

      <v-row>           
        <v-col cols="12" class="s-col-form" sm="12" md="12" v-if="typeperson == 1">
          <s-text
            ref="textNtpName"
            label="Nombre"
            @keyupEnter="save()"
            v-model="person.NtpName"
            :autofocus="person.GenPerson.PrsDocumentNumber.length > 0"
            v-if="person.GenPerson.TypePersonDocument !=3"                       
          />
        </v-col>
        <v-col cols="12"  class="s-col-form" sm="12" md="12" v-if="typeperson == 2">
          <s-text 
          ref="textJrpName" 
          label="Nombre Juridico" 
          @keyupEnter="save()"
          v-model="person.JrpName" 
          :autofocus="person.GenPerson.PrsDocumentNumber.length > 0"
          v-if="person.GenPerson.TypePersonDocument ==3"  
            />
        </v-col>
      </v-row>

      <v-row v-if="typeperson == 1">
        <v-col class="s-col-form" sm="6" md="6">
          <s-text label="Apellido Paterno" v-model="person.NtpPaternalSurname" v-if="person.GenPerson.TypePersonDocument !=3" @keyupEnter="save()"  />
        </v-col>
        <v-col class="s-col-form" sm="6" md="6">
          <s-text label="Apellido Materno" @keyupEnter="save()" v-model="person.NtpMaternalSurname" v-if="person.GenPerson.TypePersonDocument !=3" />
        </v-col>
      </v-row>

      <v-row v-if="typeperson == 2">
        <v-col cols="12" class="s-col-form" sm="12" md="12">
          <s-text label="Nombre Comercial" v-model="person.JrpBusinessName" v-if="person.GenPerson.TypePersonDocument ==3"   @keyupEnter="save()"/>
        </v-col>
        </v-row>

        <!-- <v-row>
        <v-col cols="12" class="s-col-form" sm="12" md="12">
          <s-text label="Dirección" v-model="person.PadAddress" @keyupEnter="save()"/>
        </v-col>          
      </v-row> -->

      <v-row>
        <v-col cols="12" class="s-col-form" sm="6" md="6" v-if="addBirthDate || full">         
          <s-date @keyupEnter="save()" v-model="person.NtpBirthDate" label="Fecha Nacimiento" v-if="person.GenPerson.TypePersonDocument !=3" />
        </v-col>
        <v-col cols="12" class="s-col-form" sm="6" md="6" v-show="addTypeSex || full">
          <s-select-definition :def="1037" label="Género" v-model="person.TypeSex" v-if="person.GenPerson.TypePersonDocument !=3" />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="s-col-form" v-if="full">
          <s-text v-model="phone.PpnNumberPhone" @keyupEnter="save()" type="tel" number label=" Teléfono" />
        </v-col>
        <v-col class="s-col-form" v-if="full">
          <s-text label="Email" @keyupEnter="save()" v-model="email.PemEmail" />
        </v-col>
      </v-row>

      <v-row v-if="full">
        <v-col class="s-col-form" sm="6" md="6">
          <s-select-generic label="Departamento " itemvalue="GenHelper" v-model="DepartmentUbigeo" autocomplete full :config="configDepartment" />
        </v-col>
        <v-col class="s-col-form" sm="3" md="3">
          <s-select-generic label="Provincia" itemvalue="GenHelper" v-model="ProvinceUbigeo" autocomplete full :config="configProvince" />
        </v-col>        
        <v-col class="s-col-form" sm="3" md="3">
          <s-select-generic
            itemvalue="GenHelper"
            v-model="DistrictUbigeo"
            label="Distrito"
            autocomplete
            @updateData="updateDataDistrict()"
            full
            :config="configDistrict"
          />
        </v-col>
      </v-row>

      <v-row v-if="full">
        <!-- <v-col class="s-col-form" sm="3" md="3">
          <s-text @keyupEnter="save()" v-model="address.PadAddress" label=" Dirección" />
        </v-col>  -->       
        <v-col class="s-col-form" sm="3" md="3">
          <s-text @keyupEnter="save()" v-model="address.PadAddressReference" label="Referencia" />
        </v-col>
        <v-col class="s-col-form" sm="3" md="3">
          <s-select-generic label="Nacionalidad" itemvalue="GenID" v-model="person.GenPerson.NatID" autocomplete full :config="configNationality" />
        </v-col>
        <v-col class="s-col-form" sm="3" md="3">
          <s-select-generic label="Zona" itemvalue="GenID" v-model="ZoneID" autocomplete full :config="configZones" />
        </v-col>
      </v-row>

    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row>  
          
          <v-col class="s-col-form" ><v-btn block text  @click="close()" >Cancelar</v-btn></v-col>
          <v-col class="s-col-form"><v-btn block color="primary"  @click="save()">GUARDAR</v-btn></v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import Service from "@/services/Technicalassistance/TypePersonSave";
import Service2 from "@/services/General/PersonService";
import _sGeneric from "@/services/General/GenericService";


export default {
  name: "sAddPerson",
  components: {  },
  props: {
    propPerson  : { type: Object, default: null },
    visibleTitle: { type: Boolean, default: true },
    full        : { type: Boolean, default: false },
    typeperson  : { type: Number, default: 1 },
    addTypeSex  : { type: Boolean, default: false },
    addBirthDate: { type: Boolean, default: false },
    parentID: { type: Number, default: 0 },
    cleanDoc: {type: Number, default: 0}
  },
  data() {
    return {
      editing: false,
      configNationality: null,
      configZones: null,
      configDepartment: null,
      DepartmentUbigeo: "99",
      ProvinceUbigeo: "00",
      DistrictUbigeo: "",
      item: 0,
      ZoneID: 15,
      itemsNationality: [],
      menu3: false,
      config: {},
      dialog: false,
      phone: {
        TypePhone: 1,
        PpnNumberPhone: "99999999",
        PpnIsDefault: 1,
      },
      address: {
        PadCodeUbigeo: "",
        PadAddress: "...",
        PadAddressReference: "",
        PadStreet: "",
        PadDepartment: "",
        ZonID: 0,
        PadIsDefault: 1,
      },
      email: {
        PemEmail: "abc.@hotmail.com",
        PemIsDefault: 1,
      },
      person: {
        NtpName: "",
        NtpPaternalSurname: "",
        NtpMaternalSurname: "",
        JrpName:"",
        JrpBusinessName:"",
        
        NtpID: 0,
        JrdID: 0 ,
        NtpBirthDate: null,
        TypeSex: 1,
        GenPerson: {
          TypePersonDocument: 0,
          PrsDocumentNumber: "",
          NatID: 193,
        },
        GenNaturalPersonDriver: null,
      },      
    };
  },
  methods: {
    updateDataDistrict() {
      if (this.person != null)
        if (this.person.GenPerson != null)
          if (this.person.GenPerson.Addresses != null)
            if (this.person.GenPerson.Addresses.length > 0) {
              this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
            }
    },
    txtDocument() {
      if (this.typeperson == 1) this.$refs.textNtpName.focus();
      else this.$refs.textJrpName.focus();
    },
    search() {
      this.clear();

      Service2.getNaturalPerson(this.person.GenPerson.PrsDocumentNumber, this.$fun.getUserID()).then((r) => {
        if (r.status == 200) {
          this.editing = true;
          this.personEdit = r.data;
          if (this.personEdit != null) {
            if (this.personEdit.GenPerson != null) {
              this.person = { ...this.personEdit };
              // this.person.NtpBirthDate = this.$moment(this.personEdit.NtpBirthDate).format("DD-MM-YYYY");
              this.person.NtpBirthDate = this.personEdit.NtpBirthDate;
              this.person.GenPerson.TypePersonDocument = 1;

              if (this.person.GenPerson.Emails.length > 0) {
                this.email = this.person.GenPerson.Emails[0];
              }
              if (this.person.GenPerson.Telephones.length > 0) {
                this.phone = this.person.GenPerson.Telephones[0];
              }
              if (this.person.GenPerson.Addresses.length > 0) {
                this.ZoneID = this.person.GenPerson.Addresses[0].ZonID;
                this.address = this.person.GenPerson.Addresses[0];
                this.DepartmentUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 2);
                this.ProvinceUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 4);
                this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
              }s.driver = this.person.GenNaturalPersonDriver[0];
              
            } else if (this.propPerson.PrsDocumentNumber) this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;

            if (this.typeperson == 1) this.$refs.textNtpName.focus();
            else this.$refs.textJrpName.focus();
          }
          this.editing = false;
        }
        this.$emit("search", this.person);
      });
    },
    clear() {
      this.ZoneID = 15;
      this.DepartmentUbigeo = "20";
      this.ProvinceUbigeo = "2001";
      this.phone = {
        TypePhone: 1,
        PpnNumberPhone: "",
        PpnIsDefault: 1,
      };
      this.address = {
        PadCodeUbigeo: "",
        PadAddress: "",
        PadAddressReference: "",
        PadStreet: "",
        PadDepartment: "",
        ZonID: 0,
        PadIsDefault: 1,
      };
      this.email = {
        PemEmail: "",
        PemIsDefault: 1,
      };

      let doc;
      if (this.person.GenPerson.PrsDocumentNumber.length > 0) doc = this.person.GenPerson.PrsDocumentNumber;
      else doc = "";
      this.person = {
        NtpName: "",
        NtpPaternalSurname: "",
        NtpMaternalSurname: "",
        NtpID: 0,
        NtpBirthDate: this.$moment().format(this.$const.DateMomentFormat),
        TypeSex: 1,
        GenPerson: {
          TypePersonDocument: 1,
          PrsDocumentNumber: doc,
          NatID: 193,
        },
      };
    },
    save() {
      this.person.GenPerson.TypePerson = this.typeperson;

      if(this.typeperson === 1){
        if (this.person.GenPerson.PrsDocumentNumber.trim().length < 6) 
        {
          this.$fun.alert("Numero de Documento Incorrecto", "warning");
          return;
        }
        if (this.person.NtpName.trim().length < 1) {
          this.$fun.alert("Registre Nombre", "warning");
          return;
        }
        if (this.person.NtpPaternalSurname.trim().length < 1) {
          this.$fun.alert("Registre Apellido Paterno", "warning");
          return;
        }
        if (this.person.NtpMaternalSurname.trim().length < 1) {
          this.$fun.alert("Registre Apellido Materno", "warning");
          return;
        }
        

        this.person.GenPerson.PrsDocumentNumber = this.person.GenPerson.PrsDocumentNumber.trim();
        /* this.person.JrpName = this.person.JrpName.toUpperCase();
        this.person.JrpBusinessName = this.person.JrpBusinessName.toUpperCase(); */
        //this.person.NtpMaternalSurname = this.person.NtpMaternalSurname.toUpperCase();

        if (this.full) {
          /* if (!this.$fun.isValidDate(this.person.NtpBirthDate)) {
            this.$fun.alert("Fecha de Nacimiento Inválida", "warning");
            return;
          } */

          if (this.address.PadAddress == null || this.address.PadAddress.trim().length < 2) {
            this.$fun.alert("Ingrese la Dirección", "warning");
            return;
          }

          /* this.person.GenPerson.Telephones = [this.phone];
          this.person.GenPerson.Emails = [this.email];
          this.address.ZonID = this.ZoneID;

          this.address.PadCodeUbigeo = this.DistrictUbigeo; */
         // this.address.PadAddress = this.address.PadAddress.toUpperCase();
          this.person.GenPerson.Addresses = [this.address];

         /*  if (this.person.NtpID != 0) {
            if (this.phone.PrsID == 0 || this.phone.PrsID == null) this.person.GenPerson.Telephones = [];
            if (this.email.PrsID == 0 || this.email.PrsID == null) this.person.GenPerson.Emails = [];
            if (this.address.PrsID == 0 || this.address.PrsID == null) this.person.GenPerson.Addresses = [];
          } */
        } else {
          /* this.person.GenPerson.Telephones = [this.phone];
          this.person.GenPerson.Emails = [this.email];
          this.person.GenPerson.Addresses = [this.address]; */
        }

        let p = { ...this.person };
        // if (this.full)
        //   p.NtpBirthDate =
        //     this.person.NtpBirthDate.substr(6, 4) + "-" + this.person.NtpBirthDate.substr(3, 2) + "-" + this.person.NtpBirthDate.substr(0, 2);

       /*  if (p.GenPerson.Emails[0] == null || p.GenPerson.Emails[0].PemID == null) p.GenPerson.Emails = null;
        if (p.GenPerson.Addresses[0] == null || p.GenPerson.Addresses[0].PadID == null) p.GenPerson.Addresses = null;
        if (p.GenPerson.Telephones[0] == null || p.GenPerson.Telephones[0].PpnID == null) p.GenPerson.Telephones = null; */

        
        Service.savenaturalperson(p, this.$fun.getUserID()).then(() => {
          this.$emit("personSaved", this.person);
          this.$emit("save", this.person);
          this.person.GenPerson.PrsDocumentNumber = "";
          this.clear();
          this.$fun.alert("Guardado Correctamente", "success");
          this.close();
        });
      }else{
        

        this.person.GenPerson.PrsDocumentNumber = this.person.GenPerson.PrsDocumentNumber.trim();
        this.person.NtpName = this.person.NtpName.toUpperCase();
        this.person.NtpPaternalSurname = this.person.NtpPaternalSurname.toUpperCase();
        this.person.NtpMaternalSurname = this.person.NtpMaternalSurname.toUpperCase();

        if (this.full) {
          if (!this.$fun.isValidDate(this.person.NtpBirthDate)) {
            this.$fun.alert("Fecha de Nacimiento Inválida", "warning");
            return;
          }

          if (this.address.PadAddress == null || this.address.PadAddress.trim().length < 2) {
            this.$fun.alert("Ingrese la Dirección", "warning");
            return;
          }

          this.person.GenPerson.Telephones = [this.phone];
          this.person.GenPerson.Emails = [this.email];
          this.address.ZonID = this.ZoneID;

          this.address.PadCodeUbigeo = this.DistrictUbigeo;
         // this.address.PadAddress = this.address.PadAddress.toUpperCase();
          this.person.GenPerson.Addresses = [this.address];

          if (this.person.NtpID != 0) {
            if (this.phone.PrsID == 0 || this.phone.PrsID == null) this.person.GenPerson.Telephones = [];
            if (this.email.PrsID == 0 || this.email.PrsID == null) this.person.GenPerson.Emails = [];
            if (this.address.PrsID == 0 || this.address.PrsID == null) this.person.GenPerson.Addresses = [];
          }
        } else {
          this.person.GenPerson.Telephones = [this.phone];
          this.person.GenPerson.Emails = [this.email];
          this.person.GenPerson.Addresses = [this.address];
        }

        let p = { ...this.person };
        // if (this.full)
        //   p.NtpBirthDate =
        //     this.person.NtpBirthDate.substr(6, 4) + "-" + this.person.NtpBirthDate.substr(3, 2) + "-" + this.person.NtpBirthDate.substr(0, 2);

        if (p.GenPerson.Emails[0] == null || p.GenPerson.Emails[0].PemID == null) p.GenPerson.Emails = null;
        if (p.GenPerson.Addresses[0] == null || p.GenPerson.Addresses[0].PadID == null) p.GenPerson.Addresses = null;
        if (p.GenPerson.Telephones[0] == null || p.GenPerson.Telephones[0].PpnID == null) p.GenPerson.Telephones = null;
      
        Service.savejuridicperson(p, this.$fun.getUserID()).then(() => {
          console.log('this.personaaaaaaaaaaaaaaaaaaa', this.person)
          this.$emit("personSaved" , this.person);
          this.$emit("save" , this.person);
          this.person.GenPerson.PrsDocumentNumber = "";
          this.clear();
          this.$fun.alert("Guardado persona juridica", "success");
          this.close();
        }); 
      }

      
    },
   
    close() {
      this.$refs.document.focus();
      this.$emit("toclose", 2);
      this.person.GenPerson.PrsDocumentNumber = '';
      this.clear();
    },
  },
  watch: {
    propPerson() {
      if(this.person.GenPerson.PrsDocumentNumber.trim().length === 11){
        this.typeperson = 2;
        this.person.GenPerson.TypePersonDocument = 3;
      }

      if (this.propPerson != null) {
        if (this.propPerson.GenPerson != null) {
          this.person = { ...this.propPerson };
          // this.person.NtpBirthDate = this.$moment(this.propPerson.NtpBirthDate).format("DD-MM-YYYY");
          this.person.NtpBirthDate = this.propPerson.NtpBirthDate;
          this.person.GenPerson.TypePersonDocument = 1;

          if (this.person.GenPerson.Emails.length > 0) this.email = this.person.GenPerson.Emails[0];
          if (this.person.GenPerson.Telephones.length > 0) {
            this.phone = this.person.GenPerson.Telephones[0];
          }
          if (this.person.GenPerson.Addresses.length > 0) {
            this.address = this.person.GenPerson.Addresses[0];
            this.DepartmentUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 2);
            this.ProvinceUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 4);
            this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
          }
        } else {
          this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
        }
      }
    },
    DepartmentUbigeo() {
      this.ProvinceUbigeo = 0;
      this.configProvince = {
        title: "Provincia",
        url: _sGeneric.getProvince(),
        params: {
          requestID: this.$fun.getUserID(),
          DepartmentUbigeo: this.DepartmentUbigeo,
        },
      };
    },
    ProvinceUbigeo() {
      this.DistrictUbigeo = 0;

      if (this.ProvinceUbigeo != 0)
        this.configDistrict = {
          title: "Distrito",
          url: _sGeneric.getDistrict(),
          params: {
            requestID: this.$fun.getUserID(),
            DistrictUbigeo: this.ProvinceUbigeo,
          },
        };
      if (this.ProvinceUbigeo == "2001" && this.editing == false) this.DistrictUbigeo = "200114";
      //if( this.DepartmentUbigeo=='20') this.DistrictUbigeo='14'; else this.DistrictUbigeo='01'
    },
  },
  created() {
    if (this.full) {
      this.configNationality = {
        title: "Nacionalidad",
        url: _sGeneric.getNationality(),
        params: { requestID: this.$fun.getUserID() },
      };
      this.configZones = {
        title: "Zonas",
        url: _sGeneric.getZones(),
        params: { requestID: this.$fun.getUserID() },
      };
      this.configDepartment = {
        title: "Departamento",
        url: _sGeneric.getDepartment(),
        params: { requestID: this.$fun.getUserID() },
      };
      this.configDistrict = {
        title: "Distrito",
        url: _sGeneric.getDistrict(),
        params: { requestID: this.$fun.getUserID(), DistrictUbigeo: "0" },
      };
      this.configProvince = {
        title: "Provincia",
        url: _sGeneric.getProvince(),
        params: { requestID: this.$fun.getUserID(), DepartmentUbigeo: "0" },
      };
      this.DepartmentUbigeo = "20";
    }
    if (this.propPerson != null)
      if (this.propPerson.GenPerson != null) {
        this.person = Object.assign(this.propPerson, {});
      } else this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
    
    if(this.person.GenPerson.PrsDocumentNumber.trim().length === 11){
      this.typeperson = 2;
      this.person.GenPerson.TypePersonDocument = 3;
    }
  },
};
</script>

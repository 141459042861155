import Service from "../Service";
import Vue from "vue";
const resource = "/worker/";

export default { 
  searchworker() {
    return Vue.prototype.$http.defaults.baseURL +'/worker/workerce'
   },
   pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },
  connectFingerPrint(dataIP, dataPort, requestID) {
      return Service.post(resource + "connectFingerPrint", {}, {
          params: { dataIP: dataIP, dataPort: dataPort, requestID: requestID }
      });
  },
  // addFingerPrint(MachineNumber, EnrollNumber, FingerIndex, Flag, TemplateData, requestID) {
  //     return Service.post(resource + "addFingerPrint", {}, {
  //         params: { MachineNumber: MachineNumber, EnrollNumber: EnrollNumber, FingerIndex: FingerIndex, Flag: Flag, TemplateData: TemplateData, requestID: requestID }
  //     });
  // },

  addFingerPrint(data, requestID) {
    return Service.post(resource + "addFingerPrint", data, {
      params: { requestID: requestID },
    });
  },
  disconnectFingerPrint(requestID) {
      return Service.post(resource + "disconnectFingerPrint", {}, {
          params: { requestID: requestID }
      });
  },
  deleteUserFingerPrint(MachineNumber, EnrollNumber, requestID) {
      return Service.post(resource + "deleteUserFingerPrint", {}, {
          params: { MachineNumber: MachineNumber, EnrollNumber: EnrollNumber, requestID: requestID }
      });
  },
  addUserFingerPrint(MachineNumber, EnrollNumber, Name, Password, Privilege, Enabled, requestID) {
    return Service.post(resource + "addUserFingerPrint", {}, {
        params: { MachineNumber: MachineNumber, EnrollNumber: EnrollNumber, Name: Name, Password: Password, Privilege: Privilege, Enabled: Enabled, requestID: requestID }
    });
},

  save(obj, requestID) {
    return Service.post(resource + "save", obj, {
      params: { requestID: requestID },
    });
  }

};

import Service from "../Service";

const resource = "instruction/";

export default {
    get(ins, requestID) {
        return Service.post(resource, ins, {
            params: { requestID: requestID },
        });
    },
    pagination(dtr, requestID) {
        console.log("pagination",dtr);
        return Service.post(resource + "paginationIns", dtr, {
            params: { requestID: requestID },
        });
    },

    
    save(ins, requestID) {
        return Service.post(resource + "save", ins, {
            params: { requestID: requestID },
        });
    },
    
    massiveSave(list,requestID) {
        console.log(list);   
        return Service.post( resource+"saveMassive",list ,{
            params: { requestID: requestID },
        });
    },

    search(PrsDocumentNumber,InsDate,xxInsDate, requestID) {
        console.log("Busqueda11",PrsDocumentNumber,InsDate); 
        return Service.post(resource + "search" ,{}, {
            params: { 
                requestID: requestID ,
                PrsDocumentNumber: PrsDocumentNumber,
                InsDate: InsDate,
                xxInsDate: xxInsDate
            },
        });
    },

};
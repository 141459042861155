<template>
	<div>
		<v-row>
			<v-col >
				<s-crud
                    title="Peso Con Deshidratacion"
                    :config="config"
                    :filter="filter"
                    add
                    edit
                    remove
                    @save="save($event)"
                    ref="gridAFPHidro"
                    @rowSelected="rowSelected($event)" 
                    searchInput
		        >
                    <template v-slot:filter>
                        <v-container>
                            <v-row>
                                <!--<v-col lg="3">
                                    <s-date
                                        label="Fecha Inicio"
                                        v-model="filter.PrvBeginDate"
                                    ></s-date>
                                </v-col>
                                <v-col lg="3">
                                    <s-date
                                        label="Fecha Fin"
                                        v-model="filter.PrvEndDate"
                                    ></s-date>
                                </v-col>-->
                                <v-col cols="6" md="3" lg="3" >											
                                    <s-text
                                        label="Peso"
                                        v-model="filter.NumInSale"
                                        decimal
                                    ></s-text>
                                </v-col>						            			
                                <v-col cols="6" md="3" lg="3" >											
                                    <s-text
                                        label="Peso con Hidratacion"
                                        v-model="filter.NumInSaleHidro"
                                        decimal
                                    ></s-text>
                                </v-col>
                            </v-row>
                            
                        </v-container>
                    </template>
			
                    
                    <template scope="props">
                        <v-container>
                            <v-row >						
                                <v-col cols="6" md="3" lg="3" >											
                                    <s-text
                                        label="Peso"
                                        v-model="props.item.NumInSale"
                                        decimal
                                    ></s-text>
                                </v-col>						            			
                                <v-col cols="6" md="3" lg="3" >											
                                    <s-text
                                        label="Peso con Hidratacion"
                                        v-model="props.item.NumInSaleHidro"
                                        decimal
                                    ></s-text>
                                </v-col>
                            </v-row >	
                        </v-container>
                    </template>

				</s-crud>
			</v-col>
		</v-row>
		
	</div>
	
</template>
<script>
	import _ArmedFinishedProductHydration from '@/services/FreshProduction/ConfigFresh/ArmedFinishedProductHydrationService.js';

	export default {
    components: { 

    },
    props: { },
		data() {
			return {
				filter: {
					NumInSale   :"" ,
                    NumInSaleHidro:"",
                    //PrvCompany
				},
				config: {
                    title: "Peso Con Deshidratacion",
					model: {
						FphID: "ID",
                        NumInSale: "decimal",
                        NumInSaleHidro: "decimal"
					},
					service: _ArmedFinishedProductHydration,
					headers: [
						{ text: "ID",                    value: "FphID"          },											
						{ text: "Peso",                  value: "NumInSale"      },					
						{ text: "Peso con Hidratacion",  value: "NumInSaleHidro" },						
					]
				},
				
			}
		},
		watch : {
			filter(){
				console.log(this.filter)
			}
		},
		methods: {
			initialize()
			{
				
			},

			save(item) {			
                if(item.NumInSaleHidro === "" || item.NumInSale === "") {
                    this.$fun.alert("Los Campos de pesos es Obligatorio","warning");
                    return;     
                }

                console.log("Item",item);	
				item.save();
			
			},

			
			rowSelected(items){
               
				if(items.length > 0){ this.selected = items[0]; }
                console.log("Item", this.selected );	
			},

			
			
		}
		
	};
</script>

<template>
	<div>
		<v-card>
			<v-col>
				<v-row>
					<v-col cols="12" lg="12" md="12" sm="12">
						<s-select-definition
							full
							label="Seleccione Semana"
							:def="1310"
							v-model="SpwWeek"
						>
						</s-select-definition>
					</v-col>
					<v-col cols="12" lg="12" md="12" sm="12">
						<v-data-table
							:headers="headers"
							:items="items"
						>
						</v-data-table>
					</v-col>
				</v-row>
			</v-col>
		</v-card>
	</div>
</template>

<script>
	import _sDefinition from "@/services/General/DefinitionService.js";
	import _sSleSaleProgramService from "@/services/Sale/SleSaleProgramService.js";
	export default {
		data() {
			return {
				headers: [
					{text: "Cliente", value:"SpgCustomerName"}
				],
				items: [],
				SpwWeek: 0,
			}
		},

		watch: {
			SpwWeek() {
				this.getResumenByWeek(this.SpwWeek);
			}
		},

		methods: {
			getResumenByWeek(SpwWeek) {
				_sSleSaleProgramService.list(SpwWeek,  this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.items =  resp.data;
						console.log('estos items ', this.items);
					}
				})
			}
		},

		created () {	
			_sDefinition.definitiongroup(1266, 0, this.$fun.getUserID())
			.then(resp => {
				if(resp.status == 200){
					let data = resp.data;
					data.forEach(element => {
						let obj = {
							text: element.DedDescription,
							value: element.DedAbbreviation
						};
						this.headers.push(obj);
					})
					
				}
			})
			
			//this.getResumenByWeek(this.SpwWeek);
		},
	}
</script>
<template>
	<v-card class="m-0 p-0">
		<s-text label="Pallet Nro: " v-model="nroPallet" hidden></s-text>

		<v-col cols="12" lg="12" md="12" style="display: contents;">
			<template style="margin-top: 10px;">
				<s-crud
					:filter="filter"
					:config="configLot"
					add
					close
					addNoFunction
					no-full
					title="Armado de Parihuela"
					@rowSelected="rowSelected($event)"
					@add="showPosition($event)"
					@close="close()"
					ref="gridCreatePallet"
					height="auto"
					btnsearch
					searchInput
				>
				<template v-slot:filter>
					<v-container>
						<v-row>
							<v-col cols="4">
								<s-select-definition
									v-model="filter.TypeProcessingPlant"
									:def="1230"							 									
									label="Planta Procesadora">
								</s-select-definition>
							</v-col>
							<v-col cols="4">
								<s-select-definition
									v-model="filter.TypeCultive"
									:def="1173"		
									readonly					 
									label="Tipo Cultive">
								</s-select-definition>
								 
							</v-col>
							<v-col cols="4">
								<s-select-campaign-area
									label="Campaña"
									v-model="filter.CmaId"
									typeArea="3"
									:typeCultive="(TypeCultive).toString()"
								></s-select-campaign-area>
							</v-col>
						</v-row>
					</v-container>
				</template>
					<!-- showPosition @add="SupplyPallet($event)" :dgr="34" :dgr="34"  itemPalletOne.TypePalletHelper -->
					<template v-slot:TypeCaliber="{ row }">
						 
						<s-select-definition
							:def="1266"
							v-model="TypeCaliberAll"
							readonly
							v-if="itemPalletOne.TypeCaliberName == 'CAL MIXTO' ? false: true"
						>
						</s-select-definition>
					</template>
					 <template v-slot:TypeCaliberForMixed="{ row }">
					 
						<s-select-definition
							:def="1266"
							v-model="TypeCaliberForMixed"
							:readonly="itemPalletOne.TypeCaliberName == 'CAL MIXTO' ? false: true"
							v-if="itemPalletOne.TypeCaliberName == 'CAL MIXTO' ? true: false"
						>
						</s-select-definition>
					</template>
					<template v-slot:LppQuantityJaba="{ row }">
					 
						<s-text
							:disabled="row.TypeCultive != TypeCultive"
							number
							v-model="row.LppQuantityJaba"
							@input="inputJaba(row.LppQuantityJaba)"
							:min="0"
						>
						</s-text>
					</template>

					<template v-slot:AmountJabasWeighSupply="{ row }">
						<v-chip small :color="'info'">
							{{ row.AmountJabasWeighSupply }}
						</v-chip>
					</template>

					<template v-slot:AmountJabasWeighDifference="{ row }">
						<v-chip small :color="row.AmountJabasWeighDifference <= 0 ? 'error' : 'warning'">
							{{ row.AmountJabasWeighDifference }}
						</v-chip>
					</template>

					<!-- <template v-slot:AmountJabasWeighSupplyPaLDifference="{ row }">
						<v-chip small :color="row.AmountJabasSupplyI - row.AmountJabasWeighSupplyParihuela <= 0 ? 'error': 'success'">
							{{ row.AmountJabasWeighSupplyParihuela
							}}
						</v-chip>
					</template> -->

					<template v-slot:TypeAlertName="{ row }">
						<v-chip x-small :color="row.TypeAlertColor">
							{{
								row.TypeAlertName
							}}
						</v-chip>
					</template>

					<template v-slot:WeightSupplyCalibrated="{ row }">
						<v-chip small color="info">
							{{
								row.WeightSupplyCalibrated
							}}
						</v-chip>
					</template>

					<template v-slot:WeightSupplyEarning="{ row }">
						<v-chip small color="error">
							{{
								(row.WbbNetWeight - row.WeightSupplyCalibrated).toFixed(2)
							}}
						</v-chip>
					</template>

					<template v-slot:RcfID="{ row }">
						 {{row.RcfID}} - {{row.RcfCode}}
					</template>
				</s-crud>
			</template>
		</v-col>

		<v-col cols="12" lg="12" md="12">
			<template>
				<s-toolbar
					label="Detalle de Pallet"
					dark
					:color="'#BAB6B5'"
					class="mb-6"
				></s-toolbar>

				<v-data-table
					item-key="LppID"
					dense
					:items="itemsSupplyPallet"
					:headers="headersSupplyPallet"
					v-model="selectedSupplyPallet"
					@click:row="rowClick"
					style="margin-top: -20px;"
					disable-sort
				>
					<template v-slot:item.Actions="{ item }">
						<v-btn
							@click="clickAnulate(item)"
							x-small
							color="error"
							elevation="0"
							block
							outlined
						>
							<v-icon style="font-size: 16px !important"
								>fas fa-times</v-icon
							>
						</v-btn>
					</template>
					<template v-slot:item.RcfID="{ item }">
						 {{item.RcfID}} - {{item.RcfCode}}
					</template>
				</v-data-table>
				<p>
					Cantidad de Jabas: <b>{{ this.sumJaba() }}</b>
				</p>
			</template>
		</v-col>

		<v-dialog persistent v-model="openPosition" v-if="openPosition" width="500">
			<v-card>
				<v-col>
					<v-row>
						<h2 class="mt-6 ml-3">Posición Jabas</h2>
						<v-spacer></v-spacer>
						<v-btn
							class="mt-3 mr-3"
							@click="openPosition = false"
							small
							fab
							color="error"
							><i style="font-size:16px;" class="fas fa-times"></i
						></v-btn>
					</v-row>

					<v-row>
						<v-col class="pb-0" cols="6">
							<s-text label="Posición Inicio" number :min="1" v-model="LppPositionInitial"></s-text>
						</v-col>

						<v-col class="pb-0"  cols="6">
							<s-text label="Posición Fin" number v-model="LppPositionFin"> </s-text>
						</v-col>
					</v-row>
					<v-col>
						<v-row justify="center" class="pt-5">
							<v-spacer></v-spacer>
							<v-btn class="ml-3" dark color="primary" @click="SupplyPallet(itemSave)"
								>Guardar</v-btn
							>
						</v-row>
					</v-col>
				</v-col>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
	import _sGenParamService from "../../../services/General/ParameterService";

	export default {
		props: {
			nroPallet: {
				type: Number,
				default: 0,
			},
			typeCaliber: {
				type: Number,
				default: 0,
			},
			typeCrop: {
				type: Number,
				default: 0,
			},
			itemPalletOne: null,
			TypeCultive: {
				type: Number,
				default: 0,
			}
		},
		components: {},
		data () {
			return {
			headersSupplyPallet: [
				{ text: "Anular", value: "Actions", width: 20 },
				{ text: "Nro. Lote Prod.", value: "RcfID", width: 20 },
				{ text: "Calibre", value: "TypeCaliberName", width: 20 },
				{ text: "Cantidad Jaba", value: "LppQuantityJaba", width: 20 },
				{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
				{ text: "Variedad", value: "VrtName", width: 100 },
				{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
				{ text: "Posición", value: "LppPosition", width: 50 },
			],

			filter: {
				TypeProcessingPlant: 0,
				TypeCultive: 0
			},
			configLot: {
				service: _sLotProdCalibratedService,
				model: {
					RcfID: "ID",
					AmountJabasWeigh: "",
					TypeCaliber: "",
					LppQuantityJaba: "",
					TypeDestiny: "",
					AmountJabasWeighSupply: "",
					AmountJabasWeighDifference: "",
					AmountJabasWeighSupplyPaLDifference: "",
					TypeAlertName:"",
					WeightSupplyCalibrated:"",
					WeightSupplyEarning:"",
					TypeCaliberForMixed:""
				},
				headers: [
					this.itemPalletOne.TypeCaliberName == 'CAL MIXTO' ? {} : { text: "Calibre", value: "TypeCaliber", width: 100 },
					this.itemPalletOne.TypeCaliberName == 'CAL MIXTO' ? { text: "Calibre Mixto", value: "TypeCaliberForMixed", width: 100 }: {},
					{ text: "Cantidad", value: "LppQuantityJaba", width: 100 },
					{
						text: "Restante (Aprox)",
						value: "AmountJabasWeighDifference",
						width: 100,
					},
					{ text: "Nro. Lote Produccion", value: "RcfID", width: 10 },
					{ text: "Localidad", value:"TypeZonLocationName" , width: 100},  
					{ text: "Productor", value: "PrdCardName", width: 150 },
					{ text: "Fundo", value: "FagName", width: 200 },
					// { text: "Lote", value: "FltName", width: 100 },
					{ text:"Certificaiones", value:"TypeCertificationName", width: 150},
					{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
					{ text: "Variedad", value: "VrtName", width: 100 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
					{ text: "Cantidad Fruta", value: "AmountJabasWeigh", width: 30 },
					{
						text: "Cant. Abast. CAL",
						value: "AmountJabasWeighSupply",
						width: 50,
					},
					{
						text: "Restante por Abast.",
						value: "JabasRestant",
						width: 30,
					},
					{ text: "Alerta", value: "TypeAlertName", width: 100 },
					// { text: "Cod. Senasa", value: "FltCodSenasa", width: 100 },
					{ text: "Peso Neto (Kg)", value: "WbbNetWeight", width: 100 },
					// { text: "KG Cal.", value: "WeightSupplyCalibrated", width: 100 },
					// { text: "KG Restantes.", value: "WeightSupplyEarning", width: 100 },
				],
			},

			items: {},
			itemsSupplyPallet: [],
			selectedSupplyPallet: [],
			LppSupplyJaba: 0,
			/* TypeCaliber: 0, */
			JabaMargenExtraPercent: 0,

			jabaMax: 0,
			openPosition:false,
			itemSave:{},
			LppPositionInitial: 1,
			LppPositionFin: 0,
			TypeCaliberAll: 0,
			TypeCaliberForMixed: 0
			}
		},

		created() {
			this.TypeCaliberAll = this.typeCaliber
			this.filter.TypeCrop = this.typeCrop
			this.initialize();
			this.loadParameter();

			console.log("recibiendo el detalle del pallet", this.itemPalletOne);
		},

		watch: {
			LppPositionInitial(){
				if(this.LppPositionInitial >= 1){
					this.LppPositionFin = parseInt(this.LppPositionInitial - 1) + parseInt(this.LppSupplyJaba)
				}
				
			}
		},

		methods: {

			loadParameter() {
				_sGenParamService
					.search(
						{ PrmName: "CalibradoArmadoParihuelaJabasMax" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						if (resp.status == 200) {
							this.jabaMax = resp.data.PrmValue;
						}
					});


				_sGenParamService
					.search(
						{ PrmName: "calibrateJabaMargenExtraPercentF"},
						this.$fun.getUserID(),
					)
					.then(resp => {
						if (resp.status == 200) {
							this.JabaMargenExtraPercent = resp.data.PrmValue;
						}
					});


			},

			close() {
				this.$emit("close");
			},

			rowSelected(val) {
				if (val.length > 0) {
					this.items = val[0];
				}
			},

			rowClick: function(item, row) {
				this.selectedSupplyPallet = [item];
			},

			inputJaba(val) {
				if (val !== undefined) {
					this.LppSupplyJaba = val;
					this.LppPositionFin = parseInt(this.LppPositionInitial - 1) + parseInt(this.LppSupplyJaba)
				}
			},
			/* inputCaliber(val) {
      if (val !== undefined) {
        this.TypeCaliber = val;
      }
    }, */

			sumJaba() {
				var total = 0;
				this.itemsSupplyPallet.forEach(element => {
					total += element.LppQuantityJaba;
				});
				console.log(total);
				return total;
			},

			initialize() {

				let mixed = null;
				if(this.itemPalletOne.TypePalletHelper == 'mixto'){
					mixed = 1
				}else{
					mixed = null;
				}

				let obj = this.$fun.getUserInfo();
				_sLotProdCalibratedService
					.GetPallet({ 
							TypePallet: this.nroPallet, 
							PrsID: obj.PrsID, 
							TypeCrop: this.typeCrop, 
							LppMixed: mixed,
							CptID: this.itemPalletOne.CptID 
						}, this.$fun.getUserID())
						.then(r => {
							this.itemsSupplyPallet = r.data;
							console.log("palleteeeeeeeee",this.itemsSupplyPallet);
							this.sumJaba();
						});
			},

			isValidated(quantity) {
				var isValidated = true;
				var isDifference = 0;
				isDifference = this.jabaMax - this.sumJaba();

				if (quantity > isDifference) {
					this.$fun.alert(
						"Esta superando la cantidad maximo de llenado de Pallet ==> Max = " +
							this.jabaMax,
					);
					isValidated = false;
					return;
				}

				if (this.LppSupplyJaba <= 0) {
					this.$fun.alert("La Cantidad debe ser mayor a 0", "warning");
					isValidated = false;
					return;
				}

				return isValidated;
			},

			showPosition(item){
				if(this.LppSupplyJaba < this.jabaMax ){

					if (!this.isValidated(this.LppSupplyJaba)) {
						return;
					}
					
					// calcular porcentaje de jabas extra para guardar
					let porcentJaba = (this.JabaMargenExtraPercent * item.AmountJabasWeighSupply) / 100;
					porcentJaba = porcentJaba + this.items.AmountJabasWeighDifference;
					if(this.LppSupplyJaba > porcentJaba ) {
						this.$fun.alert("Las cantidad de jabas supera el porcentaje permitido","warning")
						return;
					}


					if (this.LppSupplyJaba > item.AmountJabasWeighSupply) {
						this.$fun.alert(
							"Error, Cantidad de jabas supera la cantidad de jabas que esta pasando por calibrado",
							"warning",
						);
						return;
					}

					this.itemSave = item
					this.openPosition = true

					console.log("probando",this.itemSave);
				}else{
					// calcular porcentaje de jabas extra para guardar
					let porcentJaba = (this.JabaMargenExtraPercent * item.AmountJabasWeighSupply) / 100;
					porcentJaba = porcentJaba + this.items.AmountJabasWeighDifference;
					if(this.LppSupplyJaba > porcentJaba ) {
						this.$fun.alert("Las cantidad de jabas supera el porcentaje permitido","warning")
						return;
					}
					this.SupplyPallet(item)
				}
				
				
			},

			SupplyPallet(val) {

				console.log(val);
				//return;
				if(this.openPosition){

					if(this.LppPositionFin <= 0 || this.LppPositionInitial <= 0){
						this.$fun.alert("Error, Ingrese Posicion de Jabas", "warning");
						return;
					}

					if(this.LppPositionFin > this.jabaMax){
						this.$fun.alert("Error, Posición Final no esta dentro de las jabas permitidas", "warning");
						return;
					}
					if(this.LppPositionFin < this.LppPositionInitial){
						this.$fun.alert("Error, Posición Final no puede ser menor a Posicon Inicial", "warning");
						return;
					}

					
				}else{
					this.LppPositionInitial = 1
					this.LppPositionFin = this.jabaMax
				}



				if (val == null) {
					this.$fun.alert("No existen item", "warning");
					return;
				}

				if (!this.isValidated(this.LppSupplyJaba)) {
					return;
				}

				if (this.LppSupplyJaba > val.AmountJabasWeighSupply) {
					this.$fun.alert(
						"Error, Cantidad de jabas supera la cantidad de jabas que esta pasando por calibrado",
						"warning",
					);
					return;
				}

				var exist = false;

				this.itemsSupplyPallet.forEach(element => {

					// if (this.items.RcfID == element.RcfID) {
					// 	exist = true;
					// 	this.$fun.alert(
					// 		"Lote de Produccion ya fue agregado",
					// 		"warning"
					// 	);
					// }

					if (this.items.TypeCrop !== element.TypeCrop) {
						exist = true;
						this.$fun.alert(
							"No se puede agregar diferente Tipo de cultivo",
							"warning"
						);
					}

					if (this.items.TypeCultive !== element.TypeCultive) {
						exist = true;
						this.$fun.alert(
							"No se puede agregar diferente cultivo",
							"warning"
						);
					}

					if (this.items.VrtID !== element.VrtID) {
						exist = true;
						this.$fun.alert(
							"No se puede agregar diferente variedad",
							"warning"
						);
					}
				});


				if(exist == false){

					let obj = this.$fun.getUserInfo();
					console.log("info usuario",obj);

					if(this.TypeCaliberForMixed == 0) {this.TypeCaliberForMixed = null}

					val.LppQuantityJaba = this.LppSupplyJaba;
					val.TypeCaliber = this.TypeCaliberAll; //this.TypeCaliber;
					val.TypePallet = this.TypeCaliberAll;
					val.TypeCaliberForMixed = this.TypeCaliberForMixed
					val.LppState = 1;
					val.LppPositionInitial = this.LppPositionInitial;
					val.LppPositionFin = this.LppPositionFin;
					val.SecStatus = 1;
					val.UsrCreateID = this.$fun.getUserID();
					val.PrsID = obj.PrsID

					//samir
					val.CptID = this.itemPalletOne.CptID;
					console.log("this.itemPalletOne", this.itemPalletOne);


					if(this.itemPalletOne.TypePalletHelper == 'mixto'){
						val.LppMixed = 1
					}else{
						val.LppMixed =  null
					}
					

					console.log("Guardar", val);

					this.$fun
						.alert("Seguro de Abastecer el Pallet ?", "question")
						.then(res => {
							if (res.value) {
								_sLotProdCalibratedService
									.save(val, this.$fun.getUserID())
									.then(r => {
										if (r.status == 200) {
											this.$fun.alert("Pallet abastecido", "success");
											this.initialize();
											this.$refs.gridCreatePallet.refresh();
											this.openPosition = false
										}
									});
							}
						});

				}

				
			},

			clickAnulate(val) {
				console.log("anulate", val);
				val.UsrCreateID = this.$fun.getUserID();

				this.$fun
					.alert("Seguro de anular registro ?", "question")
					.then(res => {
						if (res.value) {
							_sLotProdCalibratedService
								.save(val, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert("Registro anulado", "success");
										this.initialize();
										this.$refs.gridCreatePallet.refresh();
									}
								});
						}
					});
			},
		},
		mounted(){
			 
			this.filter.TypeCultive = this.TypeCultive;
			 
		}
	};
</script>

<style></style>

import Service from "../Service";

const resource = "processregistration/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    getDetails(obj, requestID) {
        return Service.post(resource + "getDetails", obj, {
            params: { requestID: requestID },
        });
    },


    discarsearch(obj, requestID) {
        return Service.post(resource + "discarsearch", obj, {
            params: { requestID: requestID },
        });
    },


};
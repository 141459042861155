import Service from "../Service";
import Vue from "vue";
const resource = "technicalVisit/";

export default {
  save(ded, requestID) {
    return Service.post(resource + "save", ded, {
      params: { requestID: requestID },
    });
  },
  pagination(parameters, requestID) {
    return Service.post(resource + "paginationSNA", parameters, {
      params: {
        requestID: requestID,
      },
    });
  },
  listadoInsumosNoPermitidos(tvID){
    return Service.post(resource + "listadoInsumosNoPermitidos", {}, {
      params: {TvID: tvID}
    })
  },
  GetUrllistadoInsumosNoPermitidos(){
    return Vue.prototype.$http.defaults.baseURL+"/technicalVisit/listadoInsumosNoPermitidos";
   },
 };

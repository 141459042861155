<template>
  <div id="app" ref="SunDrive">
    <!-- Contenedor principal -->
    <div class="container">
      <div :class="TypeFileDrive==1?'success':'warning'">
        <h1 class="title">SunDrive - <strong>{{ TypeFileDrive==1?'Mi Unidad':'Unidades Compartida' }}</strong></h1>
      </div>

      <div class="content">
        <!-- Selección de unidad -->
        <template>
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="12">
              <s-select-definition
                v-model="TypeFileDrive"
                label="Seleccionar Unidad"
                :def="1493"
                @input="inputDrive($event)"
              ></s-select-definition>
            </v-col>
          </v-row>
        </template>

        <!-- Título para las carpetas con botón de agregar -->
        <template>
          <v-row>
            <v-col cols="5" class="d-flex justify-space-between align-center">
              <h2>Carpetas</h2>
              <v-tooltip bottom="" v-if="$fun.getSecurity().IsAllowAdd && TypeFileDrive==1">
                <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" small @click="openModal" elevation="0">
                    <v-icon style="font-size:18px; color: grey;">mdi-plus-circle</v-icon></v-btn>
                </template>
                <span>Agregar</span>
              </v-tooltip>
              <v-tooltip bottom="" v-if="$fun.getSecurity().IsAllowAdd && TypeFileDrive==1">
                <template v-slot:activator="{ on }">
                    <v-btn  text small v-on="on" @click="shareSelectedFolders"> <v-icon
                        style="font-size:18px; color: grey;">mdi-share-circle</v-icon></v-btn>
                  </template>
                  <span>Compartir</span>
              </v-tooltip>
              <v-tooltip bottom="" v-if="$fun.getSecurity().IsAllowDelete && TypeFileDrive==1">
                <template v-slot:activator="{ on }">
                  <v-btn text small v-on="on" @click="DeleteSelection"> <v-icon
                      style="font-size:18px; color: grey;">mdi-minus-circle</v-icon></v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
              <s-text v-model="folderSearch"  searchIcon @input="filterFolders"></s-text>
            </v-col>
          </v-row>
        </template>

        <!-- Lista de carpetas con paginación -->
        <template>
          <v-row>
            <v-col
              v-for="folder in paginatedFolders"
              :key="folder.FdrID"
              cols="12" sm="4" md="4"
              @click="toggleSelect(folder.FdrID)" 
              @dblclick="openFolderModal(folder)" 
            >
              <v-card
                class="pa-1"
                outlined
                :class="{'selected': isSelected(folder.FdrID)}" 
              >
                <!-- Diseño horizontal de la carpeta -->
                <v-row class="d-flex align-center">
                  <v-col cols="4" class="d-flex justify-center">
                    <v-img 
                      lazy-src="../../../assets/Archivos1.png"
                      max-height="50"
                      max-width="50"
                      src="../../../assets/Archivos1.png"
                      class="d-flex justify-center align-center"
                    />
                  </v-col>

                  <v-col cols="8">
                    <v-card-title class="text-left">{{ folder.FdrNameFolder }}</v-card-title>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <!-- Mostrar un mensaje si no hay carpetas -->
            <v-col v-if="filteredFolders.length === 0" cols="12" class="text-center">
              <v-alert type="grey lighten-1" bordered>
                <v-icon>mdi-folder-minus</v-icon> No hay carpetas disponibles
              </v-alert>
            </v-col>
          </v-row>

          <!-- Paginación -->
          <v-pagination
            v-if="filteredFolders.length > 0"
            v-model="page"
            :length="totalPages"
            :total-visible="7"
            @input="getFileFolder"
            class="mt-3"
            color="grey"
          ></v-pagination>
        </template>
<!-- Modal para agregar carpeta -->
        <v-dialog v-model="modalVisible" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="headline">Crear nueva carpeta</span>
            </v-card-title>

            <v-card-text>
              <s-text autofocus v-model="FdrNameFolder" label="Nombre de la carpeta"></s-text>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" text @click="closeModal">Cancelar</v-btn>
              <v-btn color="primary" text @click="createFolder">Crear</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
       <!-- Modal detail de carpeta -->
        
       <v-dialog v-model="modalDetail" max-width="58%" persistent>
      <v-card>
        <v-card-title>
          <v-col lg="9" md="9">
            <span class="headline">
              <v-icon>mdi-chevron-right</v-icon> Contenido de carpeta <strong>{{ this.nameFolderModal }}</strong>
            </span>
          </v-col>
          <v-col lg="3" md="3">
            <v-tooltip bottom="" v-if="$fun.getSecurity().IsAllowAdd ">
                <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" small @click="addinline" elevation="0">
                    <v-icon style="font-size:18px; color: grey;">mdi-plus-circle</v-icon></v-btn>
                </template>
                <span>Agregar</span>
              </v-tooltip>
            <v-tooltip bottom="" v-if="$fun.getSecurity().IsAllowDelete">
                <template v-slot:activator="{ on }">
                  <v-btn text small v-on="on" @click="DeleteSelectionDetail"> <v-icon
                      style="font-size:18px; color: grey;">mdi-minus-circle</v-icon></v-btn>
                </template>
                <span>Eliminar</span>
            </v-tooltip>
            <v-tooltip bottom="" v-if="$fun.getSecurity().IsAllowAdd">
                <template v-slot:activator="{ on }">
                  <v-btn text small v-on="on" @click="ShareSelectionDetail"> <v-icon
                      style="font-size:18px; color: grey;">mdi-account-switch</v-icon></v-btn>
                </template>
                <span>Compartir</span>
            </v-tooltip>
          </v-col>

        </v-card-title>

        <v-card-text style="margin-top: 2px;">
          <v-row v-if="showUploadFields"> <!-- Solo mostrar si showUploadFields es verdadero -->
            <v-col lg="4" md="4" sm="12">
              <v-file-input
                :disabled="!isEditable"
                label="Cargar Archivo nuevo"
                v-model="Attach1"
                hide-details
              />
            </v-col>
            <v-col lg="4" md="4" sm="12">
              <s-select-definition
                v-model="TypeArchive"
                clearable
                label="Tipo de archivo"
                :def="1494"
              ></s-select-definition>
            </v-col>
            <v-col lg="2" md="2" sm="12" style="margin-top: 7px;">
              <v-btn @click="SubirArchivo" color="primary" class="justify-center align-center">
                <v-icon>mdi-file-upload</v-icon> <!-- Icono de compartir -->
              </v-btn>
            </v-col>
          </v-row>

          <!-- Lista de archivos con paginación -->
          <v-row>
            <v-col>
              <s-text v-model="archivoSearch" searchIcon @input="filterArchives"></s-text>
            </v-col>
          </v-row>
          <!--scroll desde aqui hacia abajo desde aqui hacia arria que no sea scroll-->
          <v-row style="max-height: 50vh; overflow-y: auto; padding-right: 10px;">
            <v-col
              v-for="archivo in paginatedFoldersDetail"
              :key="archivo.FdlID"
              cols="12" sm="3" md="3"
              @click="toggleSelectDet(archivo)" 
              @dblclick="openDetInfo(archivo)" 
            >
              <v-card
                class="pa-4"
                outlined
                :class="{'selected': isSelectedDet(archivo.FdlID)}"
                style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
              >
              <v-btn
                  icon
                  @click="downloadArchivo(archivo.FdlNameArchive)"
                  color="success"
                  class="download-btn"
                  style="position: absolute; top: 10px; right: 10px;"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              <!--<img :src="`../../../assets/${archivo.TypeArchive}.png`" height="50" width="50" alt="Tipo de archivo">
                Imagen encima del texto sin espacio innecesario -->
              <v-img v-if="archivo.TypeArchive==1"
                  lazy-src="../../../assets/doc.png"
                  max-height="60"
                  max-width="60"
                  src="../../../assets/doc.png"
                  class="d-flex justify-center align-center"
                  style="margin-bottom: 0;" 
                /> 
                <v-img v-if="archivo.TypeArchive==2"
                  lazy-src="../../../assets/picture.png"
                  max-height="60"
                  max-width="60"
                  src="../../../assets/picture.png"
                  class="d-flex justify-center align-center"
                  style="margin-bottom: 0;" 
                /> 
                <v-img v-if="archivo.TypeArchive==3"
                  lazy-src="../../../assets/video.png"
                  max-height="60"
                  max-width="60"
                  src="../../../assets/video.png"
                  class="d-flex justify-center align-center"
                  style="margin-bottom: 0;" 
                /> 
                <v-img v-if="archivo.TypeArchive==4"
                  lazy-src="../../../assets/audio.png"
                  max-height="60"
                  max-width="60"
                  src="../../../assets/audio.png"
                  class="d-flex justify-center align-center"
                  style="margin-bottom: 0;" 
                /> 
                <v-img v-if="archivo.TypeArchive==5"
                  lazy-src="../../../assets/otro.png"
                  max-height="60"
                  max-width="60"
                  src="../../../assets/otro.png"
                  class="d-flex justify-center align-center"
                  style="margin-bottom: 0;" 
                /> 
                <v-card-title class="text-center" style="font-size: 12px; height: 40px; margin-top: 0; padding-top: 0; padding-bottom: 0;">
                  {{ archivo.FdlNameArchive }}
                </v-card-title>
                 <!-- Botón de descarga en la parte inferior derecha del card -->
                
              </v-card>
            </v-col>

            <!-- Mostrar un mensaje si no hay archivos -->
            <v-col v-if="filteredFoldersDetail.length === 0" cols="12" class="text-center">
              <v-alert type="grey lighten-1" bordered>
                <v-icon>mdi-folder-minus</v-icon> No hay archivos disponibles
              </v-alert>
            </v-col>
          </v-row>

          <!-- Paginación debajo de los archivos -->
        <!-- <v-pagination
            v-if="filteredFoldersDetail.length > 0"
            v-model="pag"
            :length="totalDetPages"
            :total-visible="7"
            @input="getFileFolderDetail"
            class="mt-3"
            color="grey"
          ></v-pagination>-->
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="modalDetailClose">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <!-- compartir permisos por ramas -->
     <v-dialog v-model="showDialogShare" max-width="70%">
      <v-card>
        <v-card-title>
          <label>
            Seleccionar usuario a compartir carpeta<strong></strong></label>
        </v-card-title>

        <v-card-text>
          <v-row>
           <v-col sm="12" md="6">
            <s-user @input="changeUserSelected" :isCrud="false"  />
           </v-col>
           <v-col sm="12" md="6" :disabled="user == {}">
             <!--aqui quiero llamar el optiondrive ya que quieor que este dentro de este modal  @updateSelectedFolders="updateSelectedFolders"  -->
             <s-option 
              :key="dialogKey"
              :selectedFolders="selectedFolders"
              :userID="userID"
             
            />
          </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="default " small text @click="showDialogShare=false">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <!--<v-btn color="success" dark depressed small @click="saveSharePermit()">Compartir permisos </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogDetInfo" max-width="30%" v-if="showDialogDetInfo">
      <v-card>
        <v-card-title>
          <label>
            Detalle de archivo 
          </label>
        </v-card-title>

        <v-card-text>
          <!-- Aquí comenzamos a crear la tabla -->
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left"><strong>Campo</strong></th>
                <th class="text-left"><strong>Detalle</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>Nombre de archivo:</strong></td>
                <td>{{ archivoInfo.FdlNameArchive }}</td>
              </tr>
              <tr>
                <td><strong>Fecha de Creación:</strong></td>
                <td>{{$fun.formatDateTimeShortView(archivoInfo.SecCreate) }}</td>
              </tr>
              <tr>
                <td><strong>Usuario de creación:</strong></td>
                <td>{{ archivoInfo.UsrCreateName }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <!-- Fin de la tabla -->
        </v-card-text>

        <v-card-actions>
          <v-btn color="default " small text @click="showDialogDetInfo=false">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <!--<v-btn color="success" dark depressed small @click="saveSharePermit()">Compartir permisos </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
     <!-- compartir permisos por usuario -->
     <v-dialog v-model="showDialogShareUsr" max-width="40%">
      <v-card>
        <v-card-title>
          <label>
            Seleccionar usuario a compartir carpeta<strong></strong></label>
        </v-card-title>

        <v-card-text>
          <v-row>
           <v-col sm="12" md="12">
            <s-user @input="changeUsrSelect" :isCrud="false"  />
           </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="default " small text @click="showDialogShareUsr=false">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" dark depressed small @click="saveSharePermitUsr()">Compartir permisos </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
  import _sFolderService from '@/services/InformationTechnology/SunDriveFileFolderService.js';
  import _sGeneral from "@/services/HelperService.js";
  import sUser from "@/views/Security/UserOption/User.vue";
  import sOption from "@/views/InformationTechnology/SunDrive/driveOption.vue"; 
  export default {
    components: { sUser,sOption },
    data() {
      return {
        TypeFileDrive: null, // Variable para almacenar la unidad seleccionada
        TypeArchive:null,
        folders: [], // Lista de carpetas
        filteredFolders: [], // Carpetas filtradas
        foldersDetail: [], // Lista de carpetas
        filteredFoldersDetail: [], // Carpetas filtradas
        showUploadFields: false,
        showDialogShare: false,
        showDialogShareUsr:false,
        archivoSearch :"",
        folderSearch: "", // Texto para la búsqueda de carpetas
        FdlNameArchive:"",
        dialogKey: 0, 
        item: {},
        element: {},
        modalVisible: false, // Controla la visibilidad del modal
        modalDetail:false,
        showDialogDetInfo:false,
        FdrNameFolder: "", // Variable para almacenar el nombre de la nueva carpeta
        page: 1, // Página actual para la paginación
        pag: 1, // Página actual para la paginación detail
        itemsPerPage: 9, // Número de elementos por página
        itemsDetPage: 8, // Número de elementos por página
        selectedFolders: [], // Carpets seleccionadas
        selectedArchives: [], // srchivos seleccionadas
        nameFolderModal:"",
        idfolderOpen : null,
        Attach1: null,
        items:{},
        user:{},
        userID:0,
        usrID:0
      };
    },
    computed: {
      // Número total de páginas para la paginación
      totalPages() {
        return Math.ceil(this.filteredFolders.length / this.itemsPerPage);
      },
      // Las carpetas mostradas en la página actual
      paginatedFolders() {
        const start = (this.page - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.filteredFolders.slice(start, end);
      },
      //  totalDetPages() {
      //   return Math.ceil(this.filteredFoldersDetail.length / this.itemsDetPage);
      // },
     paginatedFoldersDetail() {
        //const start = (this.pag - 1) * this.itemsDetPage;
        //const end = start + this.itemsDetPage;
        return this.filteredFoldersDetail;//.slice(start, end);
      },
    },
    watch: {
      // Observar cambios en folderSearch
      folderSearch(newValue) {
        this.filterFolders(newValue);
      },
      archivoSearch(newValue) {
        this.filterArchives(newValue);
      },
      showDialogShare(newValue) {
      if (newValue) {
        // Si el modal se abre, restablecemos los datos de s-option
        this.resetSOptionData();
      }
    }
    },
    methods: {
      onDialogChange(value) {
      if (!value) {
        // Si el modal se cierra, restablecer los datos de s-option
        this.resetSOptionData();
      }
     },
    // Restablecer datos de s-option al cerrar el modal
    resetSOptionData() {
      this.dialogKey += 1; // Cambiar la key para forzar la recarga
    },
      changeUserSelected(user) {
      this.user = user;
      this.userID=this.user[0].UsrID;
      console.log("user",this.userID);
     },
     changeUsrSelect(usr){
      this.usrID=usr[0].UsrID;
     },
      openFolderModal(folder){
        this.getFileFolderDetail(folder.FdrID);
        console.log("datfolder: ",folder);
        this.nameFolderModal=folder.FdrNameFolder;
        this.idfolderOpen=folder.FdrID;
        this.pag = 1; 
        this.modalDetail = true;
      },
      
      addinline() {
      this.showUploadFields = !this.showUploadFields; // Cambiar visibilidad de los elementos de carga
      },
      mounted() {
        // Al cargar la página, ejecutar getFileFolder
        this.getFileFolder();
      },
      inputDrive() {
        // Cada vez que se cambia la unidad, ejecutar getFileFolder
        this.selectedArchives=[];
        this.selectedFolders=[];
        this.getFileFolder();
      },
      openModal() {
        this.modalVisible = true;
      },
      closeModal() {
        this.modalVisible = false;
        this.FdrNameFolder = ""; // Limpiar el campo de texto
      },
      modalDetailClose(){
        this.modalDetail=false;
        this.selectedArchives=[];
       // this.getFileFolder();
       // this.$refs.SunDrive.refresh();
      },
  
      getFileFolder() {
        if (this.TypeFileDrive !== null) {
          let obj = {
            TypeFileDrive: this.TypeFileDrive,
            UsrCreateID: this.$fun.getUserID()
          };
          _sFolderService
            .TicFileFolderList(obj, this.$fun.getUserID())
            .then((resp) => {
              if (resp.status == 200) {
                this.folders = resp.data; // Almacenar las carpetas en la variable
                this.filteredFolders = this.folders; // Inicialmente, mostrar todas las carpetas
                 // Recalcular la paginación
                // this.totalPages = Math.ceil(this.filteredFolders.length / this.itemsPerPage);
                console.log('Carpetas obtenidas:', this.folders);
              }
            })
            .catch((err) => {
              console.error('Error al obtener las carpetas:', err);
            });
        }
      },
      
      getFileFolderDetail(FdrIDe) {
          let obj = {
            TypeFileDrive: this.TypeFileDrive,
            FdrID: FdrIDe,
            UsrCreateID: this.$fun.getUserID()
          };
          _sFolderService
            .TicFileFolderDetailList(obj, this.$fun.getUserID())
            .then((resp) => {
              if (resp.status == 200) {
                this.foldersDetail = resp.data; // Almacenar los archivos en variables  en la variable
                this.filteredFoldersDetail = this.foldersDetail; // Inicialmente, mostrar todas los archivos
                console.log('Archivos obtenidas:', this.foldersDetail);
                // Recálculo de la paginación para los archivos
               //this.totalDetPages = Math.ceil(this.filteredFoldersDetail.length / this.itemsDetPage); 
               console.log('Total Det Pages:', this.totalDetPages);
              }
            })
            .catch((err) => {
              console.error('Error al obtener las carpetas:', err);
            });
      },
      /*SubirArchivo() {
      console.log("Archivo subido:", this.Attach1);
      this.$fun.alert("Esta seguro de cargar archivo?", "question")
				.then(val => {
					if (val.value) {

						this.items.UsrCreateID = this.$fun.getUserID();
						this.items.MrcAdjunto1 = this.Attach1 != null ? this.Attach1.name : "";
						// Crea un FormData para subir los archivos
						let files = [];
						if (this.Attach1) files.push(this.Attach1);
						var formData = new FormData();
						let i = 0;
						files.forEach((element) => {
							formData.append(i.toString(), element);
							i++;
						});
            console.log("forndata",formData);
						// Primero sube los archivos
						_sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 3)
							.then(response => {
								if (response.status === 200) {
                  this.element.FdrID = this.idfolderOpen;
                  this.element.FdlNameArchive = this.Attach1.name;
                  this.element.UsrCreateID = this.$fun.getUserID();
                  this.element.SecStatus = 1;
                  this.element.TypeArchive = this.TypeArchive;
                 _sFolderService.saveDetail(this.element, this.$fun.getUserID())
                .then(resp => {
                  if (resp.status == 200) {
                    this.showUploadFields = false;
                    this.getFileFolderDetail(this.idfolderOpen);
                   //this.modalDetail=false;
                    this.$fun.alert("Archivo subido correctamente", "success");
                  }
                });
                }
              });
            }
          });      
      },*/
      SubirArchivo() {
      console.log("Archivo subido:", this.Attach1);
      this.$fun.alert("Esta seguro de cargar archivo?", "question")
				.then(val => {
					if (val.value) {
            
						this.items.UsrCreateID = this.$fun.getUserID();
						this.items.MrcAdjunto1 = this.Attach1 != null ? this.Attach1.name : "";
						// Crea un FormData para subir los archivos
						let files = [];
						if (this.Attach1) files.push(this.Attach1);
						var formData = new FormData();
						let i = 0;
						files.forEach((element) => {
							formData.append(i.toString(), element);
							i++;
						});
            console.log("forndata",formData);
						// Primero sube los archivos
                  this.element.FdrID = this.idfolderOpen;
                  this.element.FdlNameArchive = this.Attach1.name;
                  this.element.UsrCreateID = this.$fun.getUserID();
                  this.element.SecStatus = 1;
                  this.element.TypeArchive = this.TypeArchive;
            _sFolderService.saveDetail(this.element, this.$fun.getUserID())
            .then(resp => {
            if (resp.status == 200) {
              console.log("es igual",resp.data );
              if(resp.data.FdlID==-5){
                this.$fun.alert("Ya existe un archivo con este mismo nombre", "error");
                return;
              }
						_sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 3)
						.then(response => {
								if (response.status === 200) {
                    this.Attach1=null;
                    this.showUploadFields = false;
                    this.getFileFolderDetail(this.idfolderOpen);
                   //this.modalDetail=false;
                    this.$fun.alert("Archivo subido correctamente", "success");
                  }
                });
                }
              });
            }
          });      
      },
      filterFolders(searchText) {
          if (searchText.trim() !== "") {
            // Filtrar las carpetas si se introduce un texto
            this.filteredFolders = this.folders.filter(folder =>
              folder.FdrNameFolder.toLowerCase().includes(searchText.toLowerCase())
            );
          } else {
            // Si no hay texto en el campo de búsqueda, mostrar todas las carpetas
            this.filteredFolders = this.folders;
          }
        },
      filterArchives(archivoSearch) {
        if (archivoSearch.trim() !== "") {
          this.filteredFoldersDetail = this.foldersDetail.filter(archivo => {
            return archivo.FdlNameArchive.toLowerCase().includes(archivoSearch.toLowerCase());
          });
        } else {
          this.filteredFoldersDetail = this.foldersDetail;
        }
      },
      createFolder() {
        this.item.FdrNameFolder = this.FdrNameFolder;
        this.item.TypeFileDrive = this.TypeFileDrive;
        this.item.UsrCreateID = this.$fun.getUserID();
        this.item.SecStatus = 1;
        if (this.FdrNameFolder.trim() !== "") {
          console.log("Nueva carpeta creada:", this.FdrNameFolder);
          this.$fun.alert("¿Seguro de crear carpeta ?", "question").then(val => {
            if (val.value) {
              _sFolderService
                .save(this.item, this.$fun.getUserID())
                .then(resp => {
                  if (resp.status == 200) {
                    this.$fun.alert("Generado correctamente", "success");
                    this.getFileFolder();
                  }
                });
            }
          });
          this.closeModal();
        } else {
          this.$fun.alert("Por favor, ingrese un nombre válido para la carpeta.", "warning");
        }
      },
      // Función para alternar la selección de una carpeta
      toggleSelect(folderId) {
        const index = this.selectedFolders.indexOf(folderId);
        if (index === -1) {
          this.selectedFolders.push(folderId); // Seleccionar la carpeta
        } else {
          this.selectedFolders.splice(index, 1); // Deseleccionar la carpeta
        }
      },
      toggleSelectDet(archivo){
        if(archivo.UsrCreateID==this.$fun.getUserID() || this.TypeFileDrive==1){
          const index = this.selectedArchives.indexOf(archivo.FdlID);
          if (index === -1) {
            this.selectedArchives.push(archivo.FdlID); // Seleccionar la carpeta
          } else {
            this.selectedArchives.splice(index, 1); // Deseleccionar la carpeta
          }
        }
      },
      openDetInfo(archivo){
        this.archivoInfo=archivo;
        console.log("arc",archivo);
        this.showDialogDetInfo=true;
      },
      // Verificar si una carpeta está seleccionada
      isSelected(folderId) {
        return this.selectedFolders.includes(folderId);
      },
      // Verificar si un archivo está seleccionada
      isSelectedDet(archivoId) {
        return this.selectedArchives.includes(archivoId);
      },
      // Función para manejar el botón de "Share"
      shareSelectedFolders() {
        this.showDialogShare=true;
        console.log("Carpetas seleccionadas:", this.selectedFolders);
        // Aquí puedes realizar la acción de compartir las carpetas seleccionadas
        if (this.selectedFolders.length === 0) {
          this.$fun.alert("No has seleccionado ninguna carpeta", "warning");
        } else {
          // Puedes realizar el procesamiento necesario para compartir
        }
      },
      async DeleteSelection() {
        if (this.selectedFolders.length === 0) {
          this.$fun.alert("No has seleccionado ninguna carpeta", "warning");
          return;
        }
        console.log("tada", this.selectedFolders);
        console.log("tada", this.selectedFolders.length);
        const val = await this.$fun.alert("¿Seguro de eliminar carpeta ?", "question");
        // Usar un ciclo asincrónico
        for (let i = 0; i < this.selectedFolders.length; i++) {
          const folderID = this.selectedFolders[i];
          this.item.FdrID = folderID;
          this.item.UsrUpdateID = this.$fun.getUserID();
          this.item.SecStatus = 0;

          // Mostrar la alerta de confirmación antes de proceder
          
          if (val.value) {
            try {
              const resp = await _sFolderService.save(this.item, this.$fun.getUserID());
              if (resp.status === 200) {
                // Solo mostrar la alerta final una vez que todas las carpetas hayan sido procesadas
                if (this.selectedFolders.length - 1 === i) {
                  this.$fun.alert("Generado correctamente", "success");
                  this.getFileFolder();  // Recargar los elementos después de todas las eliminaciones
                }
              }
            } catch (error) {
              console.error("Error al eliminar la carpeta", error);
            }
          }
        }
      },
      async DeleteSelectionDetail(){
        if (this.selectedArchives.length === 0) {
          this.$fun.alert("No has seleccionado ningun archivo", "warning");
          return;
        }
        console.log("arch", this.selectedArchives);
        console.log("archt", this.selectedArchives.length);
        const val = await this.$fun.alert("¿Seguro de eliminar archivo ?", "question");
        // Usar un ciclo asincrónico
        for (let i = 0; i < this.selectedArchives.length; i++) {
          const archivoID = this.selectedArchives[i];
          this.item.FdlID = archivoID;
          this.item.UsrUpdateID = this.$fun.getUserID();
          this.item.SecStatus = 0;

          // Mostrar la alerta de confirmación antes de proceder
          
          if (val.value) {
            try {
              const resp = await _sFolderService.saveDetail(this.item, this.$fun.getUserID());
              if (resp.status === 200) {
                // Solo mostrar la alerta final una vez que todas las carpetas hayan sido procesadas
                if (this.selectedArchives.length - 1 === i) {
                  this.$fun.alert("Generado correctamente", "success");
                  this.getFileFolderDetail(this.idfolderOpen); // Recargar los elementos después de todas las eliminaciones
                }
              }
            } catch (error) {
              console.error("Error al eliminar la carpeta", error);
            }
          }
        }
      },
      async saveSharePermitUsr(){
        const val = await this.$fun.alert("¿Seguro de eliminar archivo ?", "question");
        // Usar un ciclo asincrónico
        for (let i = 0; i < this.selectedArchives.length; i++) {
          const archivoID = this.selectedArchives[i];
          this.item.FdlID = archivoID;
          this.item.FdpUserID =this.usrID 
          this.item.UsrCreateID = this.$fun.getUserID();
          this.item.SecStatus = 1;

          // Mostrar la alerta de confirmación antes de proceder
          
          if (val.value) {
            try {
              const resp = await _sFolderService.saveDetPermiss(this.item, this.$fun.getUserID());
              if (resp.status === 200) {
                  this.$fun.alert("Permiso generado correctamente", "success");
              }
            } catch (error) {
              console.error("Error al eliminar la carpeta", error);
            }
          }
        } 
      },
      ShareSelectionDetail(){
        this.showDialogShareUsr=true;
        if (this.selectedArchives.length === 0) {
          this.$fun.alert("No has seleccionado ningun archivo", "warning");
          return;
        }
        console.log("arch", this.selectedArchives);
      },
      isEditable() {
				return (
					(this.orderLocal.OrdStatus == 3 &&
					this.$fun.getUserID() == this.orderLocal.UsrCreateID) ||
					this.orderLocal.OrdID == null
				);
			},
      downloadArchivo(archivoName) {
        _sGeneral.download(archivoName, this.$fun.getUserID()).then((r) => {
        this.$fun.download(r.data, archivoName);
      });
     },
    },
  };
</script>

<style scoped>
/* Estilos globales */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}

.container {
  padding: 20px;
  text-align: center;
}

.title {
  color: #f4f4f4; /* Gris claro */
  font-size: 48px;
  margin-left: 10px;
}

.content {
  background-color: white;
  padding: 12px;
  border-radius: 8px;
  min-height: 200px;
}

.conten {
  background-color: yellowgreen;
  padding: 1px;
  border-radius: 8px;
  min-height: 1px;
  text-align: left;
}
.conte {
  background-color: orange;
  padding: 1px;
  border-radius: 8px;
  min-height: 1px;
  text-align: left;
}
/* Estilos adicionales */
.v-row {
  margin-bottom: 20px;
}

.v-card {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.v-card.selected {
  background-color: #dcdcdc; /* Cambia el color de fondo cuando está seleccionado */
}

.v-card-title {
  font-size: 18px;
  font-weight: bold;
}

.v-icon {
  margin-right: 10px;
}

.v-img {
  max-height: 50px;
  max-width: 50px;
}

.v-pagination .v-pagination__item--active {
  background-color: #888 !important;
  color: white !important;
}
.custom-title {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
}
</style>

<template>
	<v-card>
		<v-container>
			<v-row>
				<h2 class="mt-6 ml-3">Imprimir Etiquetas</h2>
				<v-spacer> </v-spacer>
				<v-btn
					@click="$emit('close')"
					class="mt-3 mr-3"
					small
					fab
					color="error"
					><i style="font-size: 16px" class="fas fa-times"></i
				></v-btn>
			</v-row>
			<v-row>
				<v-col cols="12">
					<!--<v-data-table
                        item-key="TrpID"
                        :items="itemsPresentation"
                        :headers="headers"
                        :items-per-page="-1"
                        hide-default-footer
                        dense
						v-model="selected"
                        @click:row="rowClick"
                        
                    > 
						<template v-slot:item.TypeBoxPresentationText="{item}">
							<v-chip
								:color="item.Presentation == 1 ? 'primary' : 'success'"
							>
								{{item.TypeTurnPresentationName}}
							</v-chip>
                        </template>

                        <!!-- <template v-slot:item.TypePresentationPackingColor="{item}">
                            <v-chip
                                :color="item.TypePresentationPackingColor"
                                x-small
                            ></v-chip>
                        </template> ->-

                    </v-data-table>-->
				</v-col>

				<v-col>
					<s-text
						label="Número Etiquetas"
						numberatedLabel
						autofocus
						v-model="quantityLabel"
						:min="0"
					>
					</s-text>
				</v-col>
			</v-row>
			<v-row>
                <v-col>
                    <b>Presione generar y espere 3 Seg....</b>
					<v-btn color="error" block @click="generatedLabel()">
						<v-icon class="mr-3"> mdi-printer</v-icon>Generar
						Etiquetas</v-btn
					>
				</v-col>
				<v-col>
                    <b>Luego presione Imprimir</b>
					<v-btn color="primary" block @click="printLabelAll()">
						<v-icon class="mr-3"> mdi-printer</v-icon>Imprimir
						Etiquetas</v-btn
					>
				</v-col>
			</v-row>


            <v-row justify="center" cols="12" style="display:none">
				<v-col cols="10" v-for="i in itemLabel">
					<qr-code
								:text="
									item.PrsDocumentNumber +
									valParamCaracterLabel +
									positionPresentation +
									valParamCaracterLabel +
									i.xNumber
								"
								error-level="H"
								:size="130"
							>
							</qr-code>
					<!-- <v-row>
						<v-col cols="10">
							<qr-code
								:text="
									item.PrsDocumentNumber +
									valParamCaracterLabel +
									positionPresentation +
									valParamCaracterLabel +
									i.xNumber
								"
								error-level="H"
								:size="130"
							>
							</qr-code>
						</v-col>
						<v-col cols="2">
							<h1>{{positionPresentation}}</h1>
						</v-col>
					</v-row>
					<v-row>
						<h1>{{item.PrsDocumentNumber + '-' + positionPresentation + '-' + i.xNumber}}</h1>
					</v-row>
					<br /> -->
				</v-col>
			</v-row>

		</v-container>
	</v-card>
</template>

<script>
	import _sPrfTagPresentationSupervisorService from "../../../services/FreshProduction/PrfTagPresentationSupervisorService";
    import _sGenParam from "../../../services/General/ParameterService";
    import qrCode from "vue-qr-generator";
    import _sTurnWorKerervice from "../../../services/FreshProduction/PrfAssignTurnWorKerDetailService";
	import _TagBalerService from "@/services/FreshProduction/TagBalerService";

	export default {
        props:{
            item: null,
            typeArea: null
        },
        components :{
            qrCode
        },
		data() {
			return {
				itemsPresentation: [],
				quantityLabel: 1,
                headers: [
                
                    /* {text: "Cliente", value: "CustomerName"}, */
                    {text: "Presentación", value: "TypeBoxPresentationText"},
                   /*  {text: "Tipo", value: "TypePresentationPackingName"},
                    {text: "Cultivo", value: "TypeCultiveText"},
                    {text: "Variedad", value: "VrtDescription"},
                    {text: "Empaque", value: "TypeAreaName"},
                    {text: "Color QR", value: "TypePresentationPackingColor"}, */

                ],
                selected: [],
                itemLabel: [],
                valParamCaracterLabel: "",
				TrpID: 0,
				TagBaler: {},
				TypePresentationPacking: 0,
				TbrNumberEnd: 0,
				positionPresentation: 0,
				previewQr: [],
				milimetrosSpacing: 0,
                /* 
                TypePresentationPackingColor: "", */
			};
		},

        created() {
            console.log("recibiendo",this.item, this.typeArea);
            this.loadDataInitial()
			this.getDataLastLabelByPerson();
            this.loadParameter()
        },
		methods: {

            loadParameter() {
				_sGenParam
					.search(
						{ PrmName: "CaracterEtiquetaCajas" },
						this.$fun.getUserID()
					)
					.then((resp) => {
						if (resp.status == 200) {
							this.valParamCaracterLabel = resp.data.PrmValue;
						}
					});
			},

			loadDataInitial() {
				
				/*_sPrfTagPresentationSupervisorService
					.list(this.typeArea, this.$fun.getUserID())
					.then((resp) => {
						if (resp.status == 200) {
                            var objTmp;
							objTmp = resp.data;
							console.log('respon data ', resp.data);

                            this.itemsPresentation  = objTmp.filter(x => x.TypeArea == this.typeArea);
							this.itemsPresentation[0].Presentation = 1;
							this.itemsPresentation[1].Presentation = 2;
							console.log("itemsPresentation", this.itemsPresentation); 
                            /* if(this.itemsPresentation.length >0 ){
                                this.TypePresentationPackingColor = this.itemsPresentation[0].TypePresentationPackingColor
                            }

                            console.log("itemsPresentation", this.itemsPresentation); 
						}
					});*/ 
			},

			getDataLastLabelByPerson()
			{
				let obj = {
					TrpID : this.TrpID,
					PrsID : this.item.PrsID
				}
				_TagBalerService
				.search(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{	
						console.log("Data",resp.data);
						if(resp.data != null)
						{
							this.TbrNumberEnd = resp.data.TbrNumberEnd;
						}
						
					}
				})
			},
	

            rowClick: function(item, row) {
                this.itemLabel = [];
				if (item !== undefined) {
					this.positionPresentation = (row.index + 1);
					this.selected = [item];
					this.TrpID = item.TrpID;
					this.TypePresentationPacking = this.selected[0].TypePresentationPacking
					this.getDataLastLabelByPerson();
					/* this.TypePresentationPacking = this.selected[0].TypePresentationPacking
					this.TypePresentationPackingColor = this.selected[0].TypePresentationPackingColor */
                    
				}
			},

            generatedLabel(){
                /*if(this.selected.length == 0) {
                    this.$fun.alert("Seleccione una Presentación", "warning")
                    return
                }*/

                if(this.quantityLabel <=0){
                    this.$fun.alert("Ingrese cantidad de etiquetas a generar", "warning")
                    return
                }
				this.getDataLastLabelByPerson();
                this.printLabel()
                
                //console.log(this.selected, this.item);
            },

			printLabel() {
				this.itemLabel = [];
				this.previewQr = [];
				
				//let initital = parseInt(this.item.AdwNumberLabel) + 1;
				let initital = parseInt(this.TbrNumberEnd) + 1;
				console.log('inital ', this.TbrNumberEnd)
				let end =
					parseInt(this.TbrNumberEnd) +
					parseInt(this.quantityLabel);
				
				for (var i = initital; i <= end; i++) {
					let obj = { xNumber: i };
					this.itemLabel.push(obj);
                    let previewQrObject = {
						//positionPresentation : this.positionPresentation,
						dat : this.item.PrsDocumentNumber /* +
									this.valParamCaracterLabel +
									this.positionPresentation +
									this.valParamCaracterLabel +
									i */
					}
					this.previewQr.push(previewQrObject);
				}
				console.log('sssssssssssssss ', this.previewQr);
			},

			printLabelAll() {
				console.log("Milimetros",this.milimetrosSpacing);
				if (this.quantityLabel <= 0) {
					this.$fun.alert(
						"La cantidad de etiquetas debe ser mayor a 0",
						"warning"
					);
					return;
				}
				var imgAll =  []
                imgAll = document.querySelectorAll("img");

                if(imgAll.length > 0){

                    let obj = {
                        AdwID: 0,
                        AgdID: this.item.AgdID,
                        AdwNumberLabel: this.quantityLabel,
                        SecStatus: 1,
                        UsrCreateID: this.$fun.getUserID(),
                    };
                    /* _sTurnWorKerervice
                        .saveCountLabel(obj, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert("se procedecio a imprimir", "success");
                                
                                this.quantityLabel = 0;
                                this.itemLabel = [];
                                this.$emit('close')
                            }
                        }); */

                    var Pagelink = "about:Empacadora";
                    var pwa = window.open(Pagelink, "_new");
                    pwa.document.open();



                    pwa.document.write(
                        "<html><head><scri" +
                            "pt>function step1(){\n" +
                            "setTimeout('step2()', 1);}\n" +
                            "function step2(){window.print(); window.close()}\n" +
                            "</scri" +
                            "pt><style media='print'>  @media print  { h1 {page-break-before: always;} } body { margin: 0; padding: 0; } .qr-container { width: "+ this.milimetrosSpacing +"mm; display: flex; justify-content: space-around; margin: 0 ;padding: 0;  align-items: center; page-break-inside: avoid;   }" +
							"   .qr-page { display: flex;  flex-direction: column; justify-content: space-around; height: 100vh;   }" +
							"   .qr-item { text-align: center; width: 30%;  display: flex;  flex-direction: column; justify-content: center; align-items: center; }" +
							"   .qr-item img { margin-left:-20px ; width: 18mm; height: 19mm; }" +
							"   .qr-item p { margin-bottom:-25px; margin-top:-45px;  font-size: 16.5px; font-family: Arial, sans-serif;  transform: rotate(90deg);  margin-left:65px }" +
							"   .page-break { page-break-after: always; }" +
							"</style></head><body onload='step1()'> "
                            
                    );

					var key = -1;
					var keyborder = "";
					var inline = "";
/* margin-bottom:30px;margin-bottom:10px;  style='display:flex;flex-wrap: wrap;'*/
					let qrPerRow = 3; // Cantidad de QR por fila
                    imgAll.forEach((element, index) => {
						if (index % qrPerRow === 0) {
							if (index > 0) {
								// Añade un salto de página al terminar cada grupo
								pwa.document.write("<div class='page-break'></div>");
							}
							// Crea un nuevo contenedor para el grupo de QR
							pwa.document.write("<div class='qr-container'>");
						}

						// Agrega cada QR al contenedor
						pwa.document.write(
							"<div class='qr-item'>" +
							"<img alt='Scan me!' src='" + element.currentSrc + "' />" +
							//"<p style='font-size: 12px; margin-top: 5px;'>" + this.previewQr[index].dat + "</p>" +
							"<p>" + this.previewQr[index].dat + "</p>" +
							//"<span  style='margin-bottom:-25px; margin-top:-25px;font-size:18px; font-family:Arial; transform:rotate(90deg); margin-left:-85px'>"+this.previewQr[index].dat +"</span>"+
							"</div>"
						);

						// // Cierra el contenedor del grupo al final de cada 3 QR
						// if ((index + 1) % qrPerRow === 0 || index === imgAll.length - 1) {
						// 	pwa.document.write("</div>");
						// }
						// Cierra la fila y rellena con contenedores vacíos al final si es necesario
						if ((index + 1) % qrPerRow === 0 || index === imgAll.length - 1) {
							const remaining = qrPerRow - ((index + 1) % qrPerRow || qrPerRow);
							for (let i = 0; i < remaining; i++) {
								pwa.document.write(
									`<div class='qr-item'>
										<img alt='Scan me!' style='visibility: hidden;' />
										<p style='visibility: hidden; '>a </p>
									</div>`
								);
							}
							pwa.document.write("</div>");
						}
						// key++;
						// if (key % 3 == 0) {
						// 	keyborder = "<h1></h1>";
							 
						// }
						

							
						// 	/*if (key == 13) {
						// 	keyborder = 6;
						// } margin-right:0.3mm;  22->25*/
                        // pwa.document.write(
						// 	keyborder + 
                        //     "<div style='display:inline-block;; margin-bottom:1mm;margin-top:1mm;margin-right:0.0mm;  padding-right:12mm;" + 
						// 	";'> <div  style='margin-right:auto;padding:0px;  text-align: center;'>" + 
						// 	"<div style='margin:auto;padding-left:" + this.milimetrosSpacing + "mm;display:flex; '>" + 
						// 	"<img style='width:19mm;height:20mm;' alt='Scan me!' src='" +
                        //         element.currentSrc +
                        //         "' />"+
						// 		//<span style='font-size:40px; font-family:Arial;'>"+this.previewQr[index].positionPresentation+"</span>
						// 		"<span  style='margin-bottom:-25px; margin-top:-25px;font-size:18px; font-family:Arial; transform:rotate(90deg); margin-left:-85px'>"+this.previewQr[index].dat +"</span>"+
						// 		//"<span  style='margin-bottom:-25px;font-size:10px; font-family:Arial; transform:rotate(90deg); margin-left:-85px'>" +
                        //        // this.previewQr[index].dat +
                        //         "</span></div>"
                        // );			
						// pwa.document.write(
						// 	"</div></div>"
						// );
						// /* justify-content: center;*/
						
						// keyborder = "";
                    });

					
                    pwa.document.write("</body></html>");
                    pwa.document.close();
                }

				this.TagBaler.PrsID = this.item.PrsID;
				this.TagBaler.TrpID = this.TrpID;
				this.TagBaler.TbrQuantityPrint = this.quantityLabel;
				//this.TagBaler.TypeArea = this.selected[0].TypeArea;
				this.TagBaler.TbrStatus = 1;
				this.TagBaler.SecStatus = 1;
				this.TagBaler.UsrCreateID = this.$fun.getUserID();
				this.TagBaler.UsrUpdateID = this.$fun.getUserID();

				console.log("item save ", this.TagBaler);
				_TagBalerService
				.save(this.TagBaler, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						console.log('guardados');
					}	
				})

				//this.showModalPrint = false;
			},
		},
		mounted(){

			console.log("this.milimetrosSpacing mounted", this.milimetrosSpacing)

			_sGenParam
					.search(
						{ PrmName: "milimetrosinterlineadodeqr" },
						this.$fun.getUserID()
					)
					.then((resp) => {
						if (resp.status == 200) {
							this.milimetrosSpacing = resp.data.PrmValue;
							console.log("this.milimetrosSpacing", this.milimetrosSpacing)
						}
					});

			
		},
	};
</script>

<style>
</style>
<template>
  <div>
    <v-row>
      <v-col lg="12">
          <visit-sna :isAdmin="true"   @rowSelected="rowSelected($event)"  :height="100">></visit-sna>
      </v-col>
         
     
    </v-row>
  </div>
</template>

<script>
import VisitSna from './VisitSNA.vue';
export default {
  components: {
    VisitSna
    },

   data() {
    return {
      parentID: 0,
      tvId:0,     
    };
  },

  methods: {
    rowSelected(rows) {
      
    },
   
  },
};
</script>

import Service from "../Service";

const resource = "HmnChangeShiftMass/";

export default {

    save(list) {
        return Service.post(resource + "save", list, {
            responseType: "json",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    

    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },

};
<template>
  <div>
    <!--CIRCUITO DE APRBACIONES MODAL-->
    <div v-if="dialogCircuit">
      <v-dialog v-model="dialogCircuit" width="80%">
        <s-toolbar color="primary" dark label="Circuitos Asociados a la Operación" @close="dialogCircuit = false" close></s-toolbar>
        <v-card>
          <v-container>
            <v-row>
              <v-col lg="7">
                <v-data-table
                  dense
                  item-key="AccID"
                  :items="circuits"
                  height="320"
                  :headers="[
                    { value: 'AccID', text: 'ID' },
                    { value: 'AccName', text: 'Circuito' },
                  ]"
                  @dblclick:row="createCircuit"
                  v-model="selectedCircuit"
                  @click:row="rowClickCircuit"
                />
              </v-col>
              <v-col lg="5">
                <s-crud v-if="this.filterLevels.AccID != null" no-toolbar :config="configLevels" :filter="filterLevels" title="Niveles" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <v-card>
      <s-toolbar
        @save="save()"
        :save="isEditable"
        :approve="optionApproved"
        close
        @close="$emit('close')"
        :transactionApproved="transactionApproved"
        :label="'Requerimiento  ' + (requirement.ReqID == null ? '' : requirement.ReqDocumentNumber + ' - ' + requirement.UsrName)"
      />
      <v-divider></v-divider>
      <v-col style="padding-top: 18px">
        <v-row justify="center">
          <v-col lg="4" cols="12" style="padding-top: 0px; padding-bottom: 0px"
            ><s-text disabled label="Usuario" :value="requirement.ReqID == null ? user.NtpFullName : requirement.UsrName"></s-text>
          </v-col>
          <v-col lg="4" cols="12" style="padding-top: 0px; padding-bottom: 0px">
            <s-select-area disabled label="Área" :text="user.CtrContract.AreName" v-model="requirement.AreID" />
          </v-col>
          <v-col lg="2" cols="12" style="padding-top: 0px; padding-bottom: 0px"
            ><s-date disabled label="Fecha" :value="requirement.ReqDate" />
          </v-col>
          
          <v-col lg="2" cols="12" style="padding-top: 0px; padding-bottom: 0px" v-if="isEditable">
            <s-select-definition :dgr="27" :def="1128" label="Estado" :disabled="!isEditable" v-model="ReqStatus" />
          </v-col>
          
        </v-row>
        <v-row>
          <v-col lg="4" v-if="isEditable">
            <s-toolbar
              color=""
              label="Nuevo Item"
              :add="isEditable"
              :edit="isEditable"
              :remove="isEditable"
              @add="add()"
              @edit="edit()"
              @removed="removeItem()"
              @save="add()"
              @clear="clearForm()"
              :clear="isEditable"
            />
            <v-divider></v-divider>
            <v-card rounded="" class="elevation-0">
              <v-col style="padding-bottom: 24px">
                <v-row justify="center">
                  <v-col class="s-col-form" lg="12">
                    <s-text v-if="Type == 3" :maxlength="100" autofocus label="Descripción" ref="DerDescription" v-model="item.DerDescription" />
                    <s-article-search v-else :readonly="editItem" v-model="article" />
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col lg="12" class="s-col-form">
                    <s-select-position 
                            v-if="article && article.U_SUN_RUBRO=='0003' && Type==1"
                            disabled
                            label="Cargo"
                            v-model="Position"
                            :text="PstNam"
                            ref="refPosition"
                             @change="inputPosition($event)"
                            ></s-select-position>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="Type != 3">
                  <v-col class="s-col-form" lg="6" cols="12">
                    <s-text label="Cantidad" decimal ref="DerQuantity" v-model="item.DerQuantity" />
                  </v-col>
                  <v-col class="s-col-form" lg="3" cols="12">
                    <s-text label="Stock" disabled ref="" v-if="article" v-model="article.ArtStock" />
                  </v-col>
                  <v-col class="s-col-form" lg="3" cols="12">
                    <s-text label="Unidad" disabled v-if="article" v-model="article.UnitOfMeasuarement" />
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col lg="6" class="s-col-form" cols="12">
                    <s-select
                      label="Centro de Costos"
                      item-value="CceID"
                      item-text="CceName"
                      return-object
                      v-model="item.CceObject"
                      :items="permit.CceList"
                    />
                  </v-col>
                  <v-col lg="6" class="s-col-form" cols="12">
                    <s-select
                      label="Linea De Negocio"
                      item-value="BslID"
                      item-text="BslName"
                      return-object
                      v-model="item.BslObject"
                      :items="permit.BslList"
                    />
                  </v-col>
                  <v-col lg="6" class="s-col-form" cols="12">
                    <s-select
                      label="Tipo de Costo"
                      item-value="TypeCost"
                      item-text="TypeCostName"
                      return-object
                      v-model="item.TypeCostObject"
                      :items="permit.TypeCostList"
                    />
                  </v-col>
                  <v-col lg="6" class="s-col-form" cols="12">
                    <s-select-project clearable label="Proyecto" return-object v-model="item.PrjObject" />
                  </v-col>

                  <v-col lg="12" class="s-col-form" cols="12">
                    <s-select-definition
                      :def="1156"
                      add
                      v-if="isVisibleEspecial"
                      v-model="item.TypeMaintenanceObject"
                      return-object
                      label="Tipo de Mantenimiento"
                    ></s-select-definition>
                  </v-col>
                  <v-col lg="6" class="s-col-form" cols="12">
                    <s-select-companysituation
                      v-if="isVisibleEspecial"
                      clearable
                      label="Ubicación"
                      no-default
                      return-object
                      v-model="item.SitObject"
                    />
                  </v-col>
                  <v-col lg="6" class="s-col-form" cols="12">
                    <s-select-company-system
                      clearable
                      v-model="item.SisObject"
                      v-if="item.SitObject && isVisibleEspecial"
                      :SitID="item.SitObject.GenID"
                      return-object
                      label="Sistema"
                    />
                  </v-col>
                  <v-col lg="12" class="s-col-form">
                    <s-select-companycomponent
                      clearable
                      :SisID="item.SisObject.GenID"
                      v-if="item.SisObject && item.SitObject && isVisibleEspecial"
                      label="Componente"
                      return-object
                      v-model="item.ComObject"
                    />
                  </v-col>
                  <v-col lg="6" v-if="Type == 3" class="s-col-form">
                    <s-select item-value="ConName" clearable item-text="ConName" label="Concepto Servicio" v-model="ConName" :items="itemsConcept" />
                  </v-col>
                  <v-col lg="6" v-if="Type == 3" class="s-col-form">
                    <s-select
                      label="Cuenta Contable"
                      clearable
                      item-value="CsaAccount"
                      item-text="CsaDescription"
                      v-model="AcoID"
                      :items="itemsAccount"
                    />
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col lg="12" class="s-col-form">
                    <s-text label="Comentario" v-model="item.DerObservation" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <s-textarea label="Comentario General" v-model="requirement.ReqObservation"></s-textarea>
                  </v-col>
                </v-row>
                <v-row v-if="Type == 3">
                  <v-col lg="12" class="s-col-form">
                    <s-import-excel
                        @changeData="changeFile($event)"
                      ></s-import-excel>
                  </v-col>
                </v-row>
                <!--almacen-->
                <!-- <v-row v-if="TypeRequirement == 2">
                  <v-col>
                    <req-purchase-by-user
                      @selectOrderPurchase="selectOrderPurchase($event)"
                    ></req-purchase-by-user>
                  </v-col>
                </v-row> -->
                <!--fin almacen-->
              </v-col>
            </v-card>
          </v-col>

          <v-col :lg="!isEditable ? 12 : 8">
            <s-toolbar label="Detalle" load>             

            </s-toolbar>
            <v-card rounded="" class="elevation-0">
              <v-col style="padding: 0px">
                <v-data-table
                  mobile-breakpoint="0"
                  item-key="DerNumLine"
                  dense
                  :items="items"
                  :height="!isEditable ? '290' : $vuetify.breakpoint.xs ? 'auto' : '272'"
                  :headers="Type==1?headersDetailTwo:headersDetail"
                  class="elevation-0"
                  v-model="selected"
                  :items-per-page="-1"
                  @click:row="rowClick"
                >
                </v-data-table>
              </v-col>
              <v-col lg="12" class="pt-0" v-if="transactionApproved != null">
                <h3 v-if="transactionApproved.AtsStatus == 3" style="color: var(--v-error-base)" class="text-center">
                  Desaprobado: {{ transactionApproved.AtsObservation }}
                </h3>
                <h3 v-if="transactionApproved.AtsStatus == 2" style="color: var(--v-success-base)" class="text-center">Aprobado</h3>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-card>

    <v-dialog
      v-model="dialogRequerimentByUser"
      
    >
      <v-card>
        <req-purchase-by-user
          @selectOrderPurchase="selectOrderPurchase($event)"
          @closeDialogRequirementByUser="closeDialogRequirementByUser($event)"
        ></req-purchase-by-user>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _sRequirement from "../../../services/Logistics/RequirementService";
import _sRequirementPermit from "../../../services/Logistics/PermitService";
import _sApprovalTransaction from "../../../services/Security/ApprovalTransactionService";
import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import ReqPurchaseByUser from "./ReqPurchaseByUser.vue";
import _sCSecEqService from '@/services/HumanResource/HmnConfigSegurityEqService.js';

export default {
  name: "RequirementService",
  components: { sArticleSearch, ReqPurchaseByUser },
  computed: {
    user() {
      return this.$fun.getUserInfo();
    },
    isEditable() {
      return this.requirement.ReqStatus == 5 || this.requirement.ReqID == null || (this.requirement.ReqStatus == 3 && this.isUserCreated == true) || this.serviceEdit;
    },
    isVisibleEspecial() {
      return this.$fun.getUserInfo().CtrContract.AreID == this.$const.ID_AREA_MANTENIMIENTO;
    },
  },
  props: {
    value: { default: 1, type: Number },
    TypeRequirement: { default: 1, type: Number },
    serviceEdit: { default: false, type: Boolean },
    RequirementEdit : null,
  },
  created() {
    this.getGoodServiceList();
    _sRequirementPermit.byUser({ UsrID: this.$fun.getUserID() }, this.$fun.getUserID()).then((r) => {
      this.permit = r.data;
      this.permit.CceList = [...new Map(this.permit.map((item) => [item["CceID"], item])).values()];
      if (this.permit.CceList.length > 0) this.item.CceObject = this.permit.CceList[0];
    });
    this.Type = this.TypeRequirement;
    if (this.value != null) {
      this.viewLoad = false;
      _sRequirement.byID({ ReqID: this.value }, this.$fun.getUserID()).then((r) => {
        if(!this.serviceEdit){
        this.requirement = r.data;
        this.ReqStatus = this.requirement.ReqStatus;
        this.items = this.requirement.Detail;
        if (this.requirement.UsrCreateID == this.$fun.getUserID()) this.isUserCreated = true;
        else this.isUserCreated = false;
        this.transactionApproved = this.serviceEdit ? null : this.requirement.Transaction;
        console.log("Requerimiento: " + this.requirement.Transaction);
        if (this.requirement.Transaction != null)
          if (this.requirement.Transaction.UsersApproved.length > 0)
            if (this.requirement.Transaction.UsersApproved.find((x) => x.UsrID == this.$fun.getUserID()))
              if (this.requirement.ReqStatus == 4) this.optionApproved = true;
        this.Type = this.requirement.TypeRequirement;
        this.viewLoad = true;
        }
        else{
          console.log("fecha",this.requirement)
          this.items =  r.data.Detail.map(item => ({
            ...item, // Copia las demás propiedades
            ReqAttentionDate : null // Cambia el campo `value` a null
          }));
          // this.items.forEach(item => {
          //   item.ReqAttentionDate = null;
          //   item.DerID = null;
          // });
          if (r.data.UsrCreateID == this.$fun.getUserID()) this.isUserCreated = true;
          else this.isUserCreated = false;
          //this.transactionApproved = this.serviceEdit ? null : this.requirement.Transaction;
        }
      });
      
      
    }
   
 
  },
  data: () => ({
    isError : {
      error : 0,
      message : ""
    },
    dialogRequerimentByUser : false,
    permit: [],
    editItem: false,
    Position:null,
    PstNam:null,
    PstArray:[],
    selectedCircuit: [],
    filterLevels: {},
    configLevels: {
      model: {
        AccID: "int",
        AlvID: "ID",
        SecStatus: "boleam",
        PstID: "int",
        PstName: "string",
      },
      service: _sApprovalLevel,
      headers: [
        { text: "Cargo", value: "PstName" },
        { text: "Nivel", value: "TypeLevelText" },
      ],
    },
    viewLoad: true,
    circuits: [],
    dialogCircuit: false,
    Type: 0,
    isUserCreated: true,
    transactionApproved: null,
    dialogDissaproved: false,
    ReqStatus: 0,
    optionApproved: false,
    itemsConcept: [],
    itemsAccount: [],
    SitID: 0,
    SisID: 0,
    ComID: 0,
    ConName: null,
    AcoID: null,
    itemsRemoved: [],
    article: null,
    selected: [],
    typeCostObject: null,
    requirement: {
      AreID: 0,
      ReqDate: null,
      CmpID: null,
      UsrID: null,
      ReqID: null,
      ReqCommitted: null,
      ReqObservation: "",
      ReqStatus: 0,
    },
    item: {
      DerNumLine: 0,
      BslName: "",
      CceName: "",
      PrjName: "",
      DerDescription: "",
      BslObject: 0,
      CceObject: 0,
      TypeCostObject: 0,
      DerObservation: "",
      PrjObject: 0,
      SisObject: null,
      SitObject: null,
      ComObject: null,
      DerQuantity: "",
    },
    TypeCost: 0,
    centercost: 0,
    bslID: 0,
    dialog: false,
    filterRequirement: { BeginDate: null, EndDate: null },
    items: [],
    //service: Service,
    headersDetail: [
      { text: "Code", value: "ArtCode" },
      { text: "Descripción", value: "DerDescription" },
      { text: "T.Costo", value: "TypeCostName" },
      { text: "Centro de Costo", value: "CceName",  },
      { text: "Linea de Negocio", value: "BslName", sortable: false,  },
      { text: "Proyecto", value: "PrjName", sortable: false,  },
      { text: "T.Mantenimiento", value: "TypeMaintenanceName", sortable: false },
      { text: "Ubicación", value: "SitName", sortable: false,  },
      { text: "Sistema", value: "SisName", sortable: false,  },
      { text: "Componente", value: "ComName", sortable: false,  },
      { text: "Comentario", value: "DerObservation", sortable: false,  },
    ],
    headersDetailTwo: [
      { text: "Code", value: "ArtCode" },
      { text: "Descripción", value: "DerDescription" },
      { text: "T.Costo", value: "TypeCostName" },
      { text: "Centro de Costo", value: "CceName",  },
      { text: "Linea de Negocio", value: "BslName", sortable: false,  },
      { text: "Proyecto", value: "PrjName", sortable: false,  },
      { text: "T.Mantenimiento", value: "TypeMaintenanceName", sortable: false },
      { text: "Ubicación", value: "SitName", sortable: false,  },
      { text: "Sistema", value: "SisName", sortable: false,  },
      { text: "Componente", value: "ComName", sortable: false,  },
      { text: "Comentario", value: "DerObservation", sortable: false,  },
      { text: "Cargo", value: "PstNam", sortable: false,  },
    ],
    quantityEnter: 0,
    itemSelectedOrderPurchase: null,
    requirementPurchaseID: [],
    itemsRequirementsByUser : [],
    goodServiceList : [],
    formSubmitted: false,
  }),
  methods: {

    getGoodServiceList()
    {
      _sRequirement
      .goodservicelist(this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200)
        {
          this.goodServiceList = resp.data;
        }
      })
    },
    getListPstArticulo(ArtCode,PstID)
			{
				let obj = {
					ArtCode: ArtCode,
          PstID:PstID
				}
				_sCSecEqService
				.List(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						console.log('data', resp.data.length);
            if(resp.data.length==0){
              this.$fun.alert("ha este cargo no le corresponde el EPP seleccionado, verificar", "warning");
              this.Position=null;
            }
						
					}
				})
			},
    openRequirementByUser()
    {
      this.dialogRequerimentByUser = true;
    },

    closeDialogRequirementByUser(itemsRequirementsByUser)
    {
      this.itemsRequirementsByUser = itemsRequirementsByUser;
      
      this.dialogRequerimentByUser = false;
    },

    selectOrderPurchase(item)
    {
      
      this.itemSelectedOrderPurchase = item;
    },

    createCircuit(item) {
      if (this.items.length < 1) {
        this.$fun.alert("No se ha registrado ningún detalle", "warning");
        return;
      }
      // if(this.itemSelectedOrderPurchase == null && this.TypeRequirement == 2)
      // {
      //   this.$fun.alert("Seleccione el requerimiento a atender", "warning");
      //   return;
      // }
      this.requirement.TypeRequirement = this.Type;
      this.requirement.UsrID = this.$fun.getUserID();
      this.requirement.UsrCreateID = this.$fun.getUserID();
      this.requirement.UsrUpdateID = this.$fun.getUserID();
      this.requirement.ReqStatus = this.ReqStatus;
      this.requirement.Details = [...this.items, ...this.itemsRemoved];

      this.$fun.alert("Seguro de seguir con: " + this.selectedCircuit[0].AccName, "question").then((r) => {
        if (r.value) {
          _sRequirement.save(this.requirement, this.$fun.getUserID(), this.selectedCircuit[0].AccID).then((j) => {
            this.$fun.alert("Guardado Correctamente", "success");
            this.serviceEdit = false;
            this.$emit("close");
          });
        }
      });
    },

    removeItem() {
      if (this.items.length > 0) {
        if (this.selected[0].ReqID > 0) {
          this.selected[0].SecStatus = 0;
          this.itemsRemoved.push(this.selected[0]);
        }
        if(this.serviceEdit){
          this.selected[0].SecStatus = 0;
          this.itemsRemoved.push(this.selected[0]);
        }
        
        this.items = this.items.filter((x) => x != this.selected[0]);
        this.selected = this.items.length > 0 ? [this.items[0]] : [];
      }
    },

    clearForm() {
      this.editItem = false;
      this.ConName = null;
      this.AcoID = null;
      this.item.DerDescription = "";
      this.item.DerObservation = "";
      this.item.DerQuantity = "";
      this.item.DerNumLine = 0;
      this.item.PstID=null;
      if (this.Type == 3) this.$refs.DerDescription.focus();
      else this.article = null;
    },
    
    edit() {
      console.log("edit",this.selected[0]);
      if (this.selected.length > 0) {
        this.editItem = true;
        let row = this.selected[0];
        this.item.TypeCostObject = { TypeCost: row.TypeCost, TypeCostName: row.TypeCostName };
        this.item.PrjObject = { GenHelper: row.PrjCode, GenDescription: row.PrjName };
        this.item.CceObject = { CceID: row.CceID, CceName: row.CceName };
        this.item.BslObject = { BslID: row.BslID, BslName: row.BslName };
        this.ConName = row.ConName;
        this.$nextTick().then((r) => {
          this.AcoID = row.ReqAccount;
        });
        if (row.SitID) this.item.SitObject = { GenID: parseInt(row.SitID), GenDescription: row.SitName };
        if (row.SitID) this.item.SisObject = { GenID: parseInt(row.SisID), GenDescription: row.SisName };
        if (row.SisID) this.item.ComObject = { GenID: parseInt(row.ComID), GenDescription: row.ComName };
        this.item.DerDescription = row.DerDescription;
        this.item.DerObservation = row.DerObservation;
        this.item.DerNumLine = row.DerNumLine;
        if (row.TypeMaintenance)
          this.item.TypeMaintenanceObject = { DedValue: parseInt(row.TypeMaintenance), DedDescription: row.TypeMaintenanceName };

        this.item.DerID = row.DerID;
        this.article = {
          ArtCode: row.ArtCode,
          ArtName: row.DerDescription,
          ArtStock: row.ArtStock,
          UnitOfMeasuarement: row.UnitMeasurement,
          WhsCode: row.WhsID,
          WhsName: row.WhsName,
        };
        this.item.DerQuantity = row.DerQuantity;
        this.item.UsrUpdateID = this.$fun.getUserID();
      }
    },

    async add() {
      console.log("itemssss",this.item);
          
      if (!this.item.SitObject) {
        this.item.SisObject = null;
        this.item.ComObject = null;
      }
      if (!this.item.SisObject) this.item.ComObject = null;
      if (
        this.items.find(
          (x) =>
            x.DerDescription == this.item.DerDescription &&
            x.TypeCost == this.item.TypeCostObject.TypeCost &&
            //x.ArtCode == this.article.ArtCode &&
            x.PrjCode == this.item.PrjObject.GenHelper &&
            x.BslID == this.item.BslObject.BslID &&
            x.CceID == this.item.CceObject.CceID
        ) &&
        this.item.DerNumLine == 0
      ) {
        this.$fun.alert("Descripción ya fue agregada", "warning");
        return;
      }
      if (this.item.DerDescription.length <= 1) {
        this.$fun.alert("Complete Descripción", "warning");
        return;
      }
      if (this.AcoID == null && this.TypeRequirement == 3) {
        this.$fun.alert("Seleccione cuenta Contable", "warning");
        return;
      }

      if (this.item.CceObject == null || this.item.CceObject == 0) {
        this.$fun.alert("Seleccione Centro de Costo", "warning");
        return;
      }
      if (this.item.BslObject == null || this.item.BslObject == 0) {
        this.$fun.alert("Seleccione Linea de Negocio", "warning");
        return;
      }
      if (this.Type != 3)
        if (this.item.DerQuantity <= 0 || this.item.DerQuantity.toString().trim() == "") {
          this.$fun.alert("Cantidad debe ser mayor a Cero", "warning");
          this.$refs.DerQuantity.focus();
          return;
        }
      if (this.TypeRequirement == 2 && this.article.ArtStock < this.item.DerQuantity) {
        this.$fun.alert("No hay stock suficiente", "warning");
        return;
      }
      if(this.Type == 1 && this.item.PstID==null && this.article.U_SUN_RUBRO=="0003"){
        this.$fun.alert("Seleccione Cargo", "warning");
        return;
      }
      if(this.Type==1 && this.article.U_SUN_RUBRO=='0003'){
        this.PstArray= this.PstArray.filter(item => item.GenID ==this.Position);
        console.log("itemsFilt",this.PstArray[0].GenDescription);
      }
      if (this.item.DerNumLine == 0) {
        let item = {
          DerDescription: this.item.DerDescription,
          TypeCostName: this.item.TypeCostObject.TypeCostName,
          TypeCost: this.item.TypeCostObject.TypeCost,
          CceName: this.item.CceObject.CceName,
          CceID: this.item.CceObject.CceID,
          BslName: this.item.BslObject.BslName,
          BslID: this.item.BslObject.BslID,
          PrjName: this.item.PrjObject.GenDescription,
          PrjCode: this.item.PrjObject.GenHelper,
          DerObservation: this.item.DerObservation,
          SitName: this.item.SitObject ? this.item.SitObject.GenDescription : null,
          SitID: this.item.SitObject ? this.item.SitObject.GenID : null,
          SisID: this.item.SisObject ? this.item.SisObject.GenID : null,
          SisName: this.item.SisObject ? this.item.SisObject.GenDescription : null,
          ComID: this.item.ComObject ? this.item.ComObject.GenID : null,
          ComName: this.item.ComObject ? this.item.ComObject.GenDescription : null,
          ReqAccount: this.AcoID,
          ConName: this.ConName,
          TypeMaintenance: this.item.TypeMaintenanceObject ? this.item.TypeMaintenanceObject.DedValue : null,
          TypeMaintenanceName: this.item.TypeMaintenanceObject ? this.item.TypeMaintenanceObject.DedDescription : null,
          UsrCreateID:this.$fun.getUserID(),
          UsrUpdateID:this.$fun.getUserID(),
          PstID: this.item.PstID,
          PstNam:this.Type==1 && this.article.U_SUN_RUBRO=='0003'?this.PstArray[0].GenDescription:""
        };
        if (this.Type != 3) {
          item.ArtCode = this.article.ArtCode;
          item.DerDescription = this.article.ArtName;
          item.DerQuantity = this.item.DerQuantity;
          item.UnitMeasurement = this.article.UnitOfMeasuarement;
          if (this.Type == 2) {
            item.WhsID = this.article.WhsCode;
            item.WhsName = this.article.WhsName;
            item.ArtStock = this.article.ArtStock;
          }
        }
        this.items.push(item);
        this.selected = [item];
      } else {
        this.items[this.item.DerNumLine - 1].DerDescription = this.item.DerDescription;
        this.items[this.item.DerNumLine - 1].TypeCostName = this.item.TypeCostObject.TypeCostName;
        this.items[this.item.DerNumLine - 1].TypeCost = this.item.TypeCostObject.TypeCost;
        if (this.item.TypeMaintenanceObject) {
          this.items[this.item.DerNumLine - 1].TypeMaintenanceName = this.item.TypeMaintenanceObject.DedDescription;
          this.items[this.item.DerNumLine - 1].TypeMaintenance = this.item.TypeMaintenanceObject.DedValue;
        }
        this.items[this.item.DerNumLine - 1].CceName = this.item.CceObject.CceName;
        this.items[this.item.DerNumLine - 1].CceID = this.item.CceObject.CceID;
        this.items[this.item.DerNumLine - 1].BslName = this.item.BslObject.BslName;
        this.items[this.item.DerNumLine - 1].BslID = this.item.BslObject.BslID;
        this.items[this.item.DerNumLine - 1].PrjName = this.item.PrjObject.GenDescription;
        this.items[this.item.DerNumLine - 1].PrjCode = this.item.PrjObject.GenHelper;
        this.items[this.item.DerNumLine - 1].DerObservation = this.item.DerObservation;
        this.items[this.item.DerNumLine - 1].DerQuantity = this.item.DerQuantity;
        this.items[this.item.DerNumLine - 1].UnitMeasurement = this.article.UnitOfMeasuarement;
        if (this.item.SitObject) {
          this.items[this.item.DerNumLine - 1].SitName = this.item.SitObject.GenDescription;
          this.items[this.item.DerNumLine - 1].SitID = this.item.SitObject.GenID;
        }
        if (this.item.SisObject) {
          this.items[this.item.DerNumLine - 1].SisID = this.item.SisObject.GenID;
          this.items[this.item.DerNumLine - 1].SisName = this.item.SisObject.GenDescription;
        }
        if (this.item.ComObject) {
          this.items[this.item.DerNumLine - 1].ComID = this.item.ComObject ? this.item.ComObject.GenID : null;
          this.items[this.item.DerNumLine - 1].ComName = this.item.ComObject ? this.item.ComObject.GenDescription : null;
        }
        this.items[this.item.DerNumLine - 1].ReqAccount = this.AcoID;//agregado por ato
        this.items[this.item.DerNumLine - 1].ConName = this.ConName;
        this.items[this.item.DerNumLine - 1].PstID = this.item.PstID;// cargo
        if (this.Type != 3) {
          this.items[this.item.DerNumLine - 1].ArtCode = this.article.ArtCode;
          this.items[this.item.DerNumLine - 1].DerDescription = this.article.ArtName;
          this.items[this.item.DerNumLine - 1].DerQuantity = this.item.DerQuantity;
          this.items[this.item.DerNumLine - 1].UnitMeasurement = this.article.UnitOfMeasuarement;
          if (this.Type == 2) {
            this.items[this.item.DerNumLine - 1].WhsID = this.article.WhsCode;
            this.items[this.item.DerNumLine - 1].WhsName = this.article.WhsName;
            // this.items[this.item.DerNumLine - 1].ArtStock = this.article.ArtStock;
          }
        }
        
        this.selected = [this.items[this.item.DerNumLine - 1]];
        this.selected[0].UsrUpdateID = this.$fun.getUserID();
        console.log("Detalleitem",this.selected);
      }
      for (let i = 0; i < this.items.length; i++) this.items[i].DerNumLine = i + 1;
      
      this.clearForm();
    },

    rowClick: function (item, row) {
      this.selected = [item];
    },
    rowClickCircuit: function (item, row) {
      this.selectedCircuit = [item];
      this.filterLevels = { AccID: item.AccID };
    },
    inputPosition(item){
      this.PstArray=item;
    },
    save() {

      if(this.formSubmitted) return;
      
      this.$fun.alert("Seguro de Guardar?", "question").then((r) => {
        if (r.value) {
          if (this.items.length < 1) {
            this.$fun.alert("No se ha registrado ningún detalle", "warning");
            return;
          }
          if (this.ReqStatus == null) {
            this.$fun.alert("Seleccione Estado", "warning");
            return;
          }

          if (this.ReqStatus == 3) {
            this.ReqStatus = 4;
          }
          this.formSubmitted = true;
          this.requirement.TypeRequirement = this.Type;
          this.requirement.UsrID = this.$fun.getUserID();
          this.requirement.UsrCreateID = this.$fun.getUserID();
          this.requirement.UsrUpdateID = this.$fun.getUserID();
          this.requirement.ReqStatus = this.ReqStatus;
          this.requirement.SecStatus = 1;
          //this.requirement.ReqStatus = 4;
          
          this.requirement.Details = [...this.items, ...this.itemsRemoved];
           if(this.serviceEdit){this.requirement.ReqID =null;}
           console.log("requirement",this.requirement);
          _sRequirement.save(this.requirement, this.$fun.getUserID(), 0).then((r) => {
            if (r.data.length > 1) {
              this.formSubmitted = false;
              this.circuits = r.data;
              this.dialogCircuit = true;
            } else {
              this.$fun.alert("Guardado Correctamente", "success");
              this.$emit("close");
            }
          });
        }
      });
    },
   changeFile(details){

    let id = 1;
    details.forEach(element => {
      let item = {};
      item.DerDescription = element.DESCRIPCIÓN;
      item.DerNumLine = this.items.length + 1;
      item.CceID = element.COD_CENTROCOSTO;
      item.CceName = element.AREA_SOLICITANTE;
      item.TypeCostName = element.TIPO_COSTO;
      item.TypeCost = 1;
      item.PrjName = element.PROYECTO;
      item.BslID = element.COD_LINEA_NEGOCIO;
      item.BslName = element.LINEA_NEGOCIO;
      item.PrjCode = element.COD_PROYECTO;
      item.ConName = element.CONCEPTO;
      item.DerObservation = element.COMENTARIO;
      item.ReqAccount = element.CUENTA_CONTABLE.toString();
      item.AcoID = item.ReqAccount;
      this.items.push(item);
      
    });
   },
             
  },
  watch: {
    "item.CceObject"() {
      this.permit.BslFull = this.permit.filter((x) => x.CceID == this.item.CceObject.CceID);
      this.permit.BslList = [...new Map(this.permit.BslFull.map((item) => [item["BslID"], item])).values()];
      if (this.permit.BslList.length > 0) this.item.BslObject = this.permit.BslList[0];
    },
    "item.BslObject"() {
      this.permit.TypeCostList = [...new Map(this.permit.BslFull.map((item) => [item["TypeCost"], item])).values()];
      this.item.TypeCostObject = this.permit.TypeCostList[0];
    },
    Type() {
      if (this.Type == 1) {
        this.headersDetail.splice(
          1,
          0,
          { text: "Und", value: "UnitMeasurement", sortable: false },
          { text: "Cant", value: "DerQuantity", sortable: false, align: "right" }
        );
      } else if (this.Type == 3 || this.Type == 2) {
        if (this.Type == 2)
          this.headersDetail.splice(
            1,
            0,
            { text: "Und", value: "UnitMeasurement", sortable: false },
            { text: "Cant", value: "DerQuantity", sortable: false, align: "right" }
          );

        if (this.Type == 3) {
          _sRequirement.ConceptService(this.$fun.getUserID()).then((r) => {
            this.itemsConcept = r.data;
          });
          this.headersDetail.push(
            { text: "Concepto", value: "ConName", sortable: false, width: 200 },
            { text: "Cuenta Contable", value: "ReqAccount", sortable: false, width: 200 }
          );
        }
      }
    },
    ConName() {
      if (this.ConName != null)
        _sRequirement.AccountConceptService(this.ConName, this.$fun.getUserID()).then((ar) => {
          this.itemsAccount = ar.data;
        });
      else {
        this.itemsAccount = [];
      }
    },
    article() {
      this.$nextTick().then(() => {
        if (this.article) {
          console.log("ARt",this.article);
          this.Position=null;
          this.$refs.DerQuantity.focus();
          this.item.DerDescription = this.article.ArtName;
          if (this.TypeRequirement == 2)
            if (this.article.ArtStock < 0.1) {
              // SI ES REQUERIMIENTO DE ALMACEN
              this.article = null;
              this.$fun.alert("No hay Stock", "warning");
              this.item.DerDescription = "";
            }
        }
      });
    },
    Position(){
      console.log("seleccionaste Posicion", this.Position+" ");
      if(this.article.ArtCode!==null && this.Position !=null && this.article.U_SUN_RUBRO=="0003"){
        this.item.PstID=this.Position;
        this.getListPstArticulo(this.article.ArtCode, this.Position);
      }
    }
  },

};
</script>

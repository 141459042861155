<template>
	<v-card class="m-0 p-0">
		<v-col cols="12" lg="12" md="12" style="display: contents;">
			<v-container>
				<v-row>
					<s-toolbar
						print 
						@print="printQR"
						dark
						:color="'#BAB6B5'"
					>
						<v-tooltip bottom="">
							<template v-slot:activator="{ on }">
								<v-btn 
									x-small 
									v-on="on" 
									@click="cancelPallet()"
									color="error"
									v-if="$fun.isAdmin()"
								> 
									<i class="far fa-stop-circle"></i>Cancelar Pallet
								</v-btn>
								<!-- <v-btn
									v-if="$fun.isAdmin()"
									x-small 
									color="error" 
									@click="cancelLot()"
									v-on="on" 
								>	
									<i class="far fa-stop-circle"></i>Cancelar Lote
								</v-btn> -->
							</template>
							<span>Cancelar Pallet</span></v-tooltip
							>
					</s-toolbar>
				</v-row>
				<v-row style="margin-left: 5px">
					
					<v-col cols="12" md="3" lg="3">
						<s-select-definition
							:def="1318"
							v-model="filter.TypeSelectionFrozen"
							label="Tipo Selección"
							clearable
							@change="loadData()"
						>
						</s-select-definition>
					</v-col>
					<v-col cols="12" md="3" lg="3">
						<s-select-definition
							:def="1422"
							v-model="filter.StatusMaduration"
							label="Lugar Pallet"
							clearable
							@change="loadData()"
						>
						</s-select-definition>
					</v-col>
					<v-col cols="6" md="3" lg="3">
						<s-date
							label="Fecha Inicio"
							v-model="filter.cDateInitial"
							@input="loadData()"
						></s-date>
					</v-col>
					<v-col cols="6" md="3" lg="3">
						<s-date
							label="Fecha Fin"
							v-model="filter.cDateFin"
							@input="loadData()"
						></s-date>
					</v-col>
					<v-col cols="12" md="3" lg="3">
						<s-select-definition
							:def="1173"
							v-model="filter.TypeCultive"
							label="Cultivo"
							clearable
							@change="loadData()"
						>
						</s-select-definition>
					</v-col>
				</v-row>
			</v-container>
			<template style="margin-top: 10px;">
				<v-data-table
					:items="items"
					:headers="headers"
					item-key="LlpID"
					print
					addNoFunction
					no-full
					search-input
					title="Impresion QR Selección"
					show-select
					v-model="selected"
					:search="search"
				>
					<template v-slot:top>
						<v-text-field
						v-model="search"
						label="Search"
						class="mx-4"
						></v-text-field>
					</template>
				 
					<template v-slot:item.StatusMaduration="{ item }">
						<v-chip style="margin:0px" x-small :color="item.StatusMaduration == 1 ? 'success' : 'warning'">
						{{ item.StatusMaduration == 1 ? "Seleccion" :  "En Maduracion"}}
						</v-chip>
					</template>
					<template v-slot:item.SecCreate="{ item }">
						  {{ moment(item.SecCreate).format('DD/MM/YYYY') }}
				 	</template>
				</v-data-table>
			</template>
		</v-col>
		
		<v-row justify="center" cols="12" style="display: none">
			<v-col cols="12" v-for="item in selected">
				<qr-code
					:text="
						item.LlpID +
							',' +
							item.LlpQuantityJaba +
							',' +
							item.TypeFrozenCategoryName +
							',' +
							((item.TypeSelectionFrozenName == null ) ? 'DESCARTE' : item.TypeSelectionFrozenName ) +
							',' +
							item.TypeCropName +
							',' +
							item.TypeCultiveName +
							',' +
							item.VrtName + 
							',' +
							item.LlpWeightOriginalNet
					"
					error-level="H"
					:size="360"
					hidden
				>
				</qr-code>
				<br />
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import _sLotProdCalibratedPrintQRService from "../../../services/FreshProduction/PrfLotProductionCalibratedQRService";
	import _sLotProdSelPalletService from "../../../services/FrozenProduction/FrzLotProductionSelectionPalletSendService";

	import qrCode from "vue-qr-generator";
	import moment from 'moment'
	export default {
		props: {},
		components: { qrCode },
		data: () => ({
			search: "",
			filter: {
				 
			},
			
			headers: [
				{ text: "Estado", value: "StatusMaduration" },
				{ text: "Fecha Armado.", value: "SecCreate" , width: 100 },
				{ text: "ID PALLET SUNEXPERT", value: "LlpName", width: 100 },
				{ text: "N° PALLET", value: "LlpCorrelative", width: 100 },
				{ text: "Cant. Jaba", value: "LlpQuantityJaba", width: 100 },
				{ text: "Tipo Selección", value: "TypeSelectionFrozenName", width: 100 },
				{ text: "Categoría", value: "TypeFrozenCategoryName", width: 100 },
				{ text: "Peso Neto", value: "LlpWeightOriginalNet", width: 100 },
				{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
				{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
				{ text: "Variedad", value: "VrtName", width: 100 },
			],			 
			selected: [],
			openDialog: true,
			itemsDetail : [],
			qrcodestring: "",
			items: [],
			LlpIDsArray: [],
			LlpIDsArrayString : ""
		}),

		watch:{
			selected(){
				this.rowSelected(this.selected);
			}
		},
		methods: {
			cancelPallet()
			{
				this.LlpIDsArray = [];
				this.selected.forEach(element => {
					this.LlpIDsArray.push(element.LlpID);
				})
				this.LlpIDsArrayString = this.LlpIDsArray.join(",");
				this.$fun.alert("¿Seguro de cancelar ingresos?", "question")
				.then(r => {
					if(r.value)
					{
						let obj = {
							LlpIDs : this.LlpIDsArrayString
						}
						_sLotProdSelPalletService
						.cancelmaturation(obj, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Cancelados correctamente", "success");
							}
						})
					}
				})
				
			},

			loadData(){
				_sLotProdSelPalletService.paginationlist(this.filter, this.$fun.getUserID())
				.then((r) => {
					this.items = r.data;
				})
			},
			click(val) {
				this.filter.TypeDestiny = val.DedValue;
				this.openDialog = false;
			},

			rowSelected(val) {
				if (val !== undefined) {
					this.selected = val;
					console.log("this.selected", this.selected)
					if(this.selected.length > 0) {
						/* let obj = {
							LlpQuantityJaba: this.selected[0].LlpQuantityJaba,
							TypeFrozenCategoryName: this.selected[0].TypeFrozenCategoryName,
							TypeCultiveName: this.selected[0].TypeCultiveName,
							VrtName: this.selected[0].VrtName,
							TypeSelectionFrozenName: this.selected[0].TypeSelectionFrozenName,
							TypeSelectionFrozen: this.selected[0].TypeSelectionFrozen,
						}
						this.qrcodestring = this.selected[0].LlpID + ', -'+JSON.stringify(obj); */
						
						_sLotProdSelPalletService.GetDetailQR(this.selected[0].LlpID, this.$fun.getUserID()).then(resp => {
							if (resp.status == 200) {
								this.itemsDetail = resp.data;
								console.log("itemsDetail", this.itemsDetail);
							}
						});
					}
					
				}
			},

			
			printQR() {
				var imgAll = document.querySelector("img");
				console.log('imgAll ', imgAll);
				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  body{font-family: arial;} table, th, td {border: 1px solid black;border-collapse: collapse;padding:2px;font-size:9pt;}" + 
						"  @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);
				
				/* imgAll.forEach(element => { */
					pwa.document.write(
						"<div class='col col-4' style='margin: 20px 20px 20px 20px; position:absolute;'><img alt='Scan me!' style='display: block;'  src='" +
							imgAll.currentSrc +
							"' />",
					);
					pwa.document.write("<b style='font-size: 50px; z-index: 2;transform: rotateZ(0deg);margin-top: 5px;position: absolute;width: 133px;border-radius: 20px;'>" + 
				((this.selected[0].TypeSelectionFrozenName === null) ? 'DESCARTE' : this.selected[0].TypeSelectionFrozenName ) + "</b>"); 


					pwa.document.write("</div><div style='margin: 30px 0px 0px 410px;'><table colspan='1' border='1'>");
					pwa.document.write(
						"<tr><td colspan='2' style='text-align:center;'><b><img  width='200px'src='https://www.sunshineperu.com/assets/img/logo.svg'alt='User Image'ref='userImage'/></b></td>" + 
								// "<td align='center'><b> " + this.selected[0].TypeCultiveName + "-" + this.selected[0].VrtName +
								// " <br> "  + this.selected[0].TypeCropName +
								// " </b></td>" + 
								"</tr>"+
								 "<tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AP-FR-213<br>Paleticket de identificación de pallet</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VERSION:04" +
								"<br>F. creación:20/09/2021" +
								"<br>F. última revisión:27/09/2024</td></tr>" +		

							"<tr><td>Materia Prima</td><td>" + this.selected[0].TypeCultiveName + "</td></tr>"+
							"<tr><td>Variedad</td><td>" + this.selected[0].VrtName + "</td></tr>"+
							"<tr><td>Tipo de Cultivo</td><td>" + this.selected[0].TypeCropName + "</td></tr>"+
							"<tr><td>N° Pallet</td><td>" + this.selected[0].LlpCorrelative + "</td></tr>" +
							"<tr><td>Peso Calibrado (Kg)</td><td>" + this.selected[0].LlpWeightOriginalNet + " KG.</td></tr>"+
							"<tr><td>Estado de Madurez</td><td></td></tr>" +
							"<tr><td>Tamaño</td><td>" + this.selected[0].TypeFrozenCategoryName + " </td></tr>" + 
							"<tr><td>Tipo Selección</td><td>" +  	
							((this.selected[0].TypeSelectionFrozenName === null) ? 'DESCARTE ' : this.selected[0].TypeSelectionFrozenName) + "</td></tr>" +
										
							"<tr><td>ID pallet sunexpert</td><td>" + this.selected[0].LlpID + "</td></tr>" +			
							"<tr><td>°Brix de Recepcion</td><td>" + "" + "</td></tr>" +			
							
							// "<tr><td>Turno</td><td>RECEPCION|MADURACION</td></tr>" +
							"<tr><td>%LMF</td><td></td></tr>" +

							"<tr><td>Hora</td><td>"+ (new Date(this.selected[0].SecCreate).getHours()) + ":"+
								""+ (new Date(this.selected[0].SecCreate).getMinutes()) + "</td></tr>" +
							"<tr><td>TUNNEL-SEMANA-BATCH</td><td></td></tr>" +
							"<tr><td>Turno</td><td></td></tr>" +
							"<tr><td>Fecha a.Proceso</td><td></td></tr>" +
							"<tr><td>Peso a Proceso</td><td></td></tr>" +
							"<tr><td>Tipo de Proceso</td><td></td></tr>" +
							"<tr><td>N° de dias en planta</td><td></td></tr>" +
							"<tr><td>Tipo de Desinfección</td><td>I&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;| II&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;| III</td></tr>"
					);
					pwa.document.write("</table></div>");

					this.itemsDetail.forEach(detail => {
							
							pwa.document.write("<div style='margin: 30px 0px 0px 410px;' ><table border='1'>");

							pwa.document.write(
								"<tr><td>Lote de Materia Prima: </td><td>" + detail.PrfYuliano + "</td></tr>" +
								"<tr><td>N° de Jabas: </td><td>" + detail.LlpQuantityJaba +"</td></tr>"
							);

							pwa.document.write(
								// "<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
								/* "<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + "</td></tr>" + */
								"<tr><td>Tipo de Proveedor: </td><td>" + detail.ZonName + " - " + detail.TypeZonLocationName + "</td></tr>"
							);

							// pwa.document.write(
							// 	" </td><td> Peso Neto: </td><td>" +
							// 	detail.WpbWeightNet +
							// 	" </td></tr>",
							// );

							pwa.document.write(
								// "<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>" +
								"<tr><td>Fecha Recepción</td><td>" + detail.RcfDateReception + "</td></tr>" +
								"<tr><td>Fecha Calibrado</td><td>" + detail.RcfDateCalibrated + "</td></tr>" 
								 
							);

							if (this.selected[0].TypeCultiveName == "PALTO") {
								pwa.document.write(								 
									"<tr><td>% De Materia Seca</td><td>" + detail.DryMaterial + "</td></tr>"  
								);
							}

							pwa.document.write("</table></div>");
						});

				/* }); */

				
				pwa.document.write("</div></body></html>");
				pwa.document.close();
			},
		},
		mounted(){
			this.loadData();
		}
	};
</script>

<style></style>
